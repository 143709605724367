export const reversalDataArray = ({ dropDowns }) => [
  {
    id: "reversed",
    name: "reversed",
    type: "text",
    label: "Reversed",
    tag: "select",
    options: [
      { label: "true", value: "true" },
      { label: "false", value: "false" },
    ],
  },
  {
    id: "reversalDocNumber",
    name: "reversalDocNumber",
    type: "text",
    label: "Reversal Document Number",
  },
  {
    id: "Reversal",
    name: "Reversal",
    type: "text",
    label: "Reversal",
    tag: "select",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
];
export const statusDataArray = ({ dropDowns }) => [
  {
    id: "statusId",
    name: "statusId",
    type: "text",
    label: "Status",
    tag: "select",
    options: dropDowns?.statuses || [],
  },
];

export const adminDataArray = ({ dropDowns }) => [
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    disabled: true,
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    disabled: true,
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    disabled: true,
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "Modified On",
    disabled: true,
  },
];


