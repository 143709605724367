import React from "react";
import { ReactComponent as ReactOrderOverviewCard } from "../../../Assets/NewIconsForCards/ShipDocOverview.svg";
import { ReactComponent as SearchIcon } from "../../../Assets/NewIconsForCards/SearchIcon.svg";
import Card from "../../Components/Card";
import { useNavigate } from "react-router-dom";


const Arr = [
  {
    title: "Order Overview",
    text: "",
    Icon: ReactOrderOverviewCard,
    link: "/OrderOverview",
  },
  {
    title: "Order Workbench",
    text: "",
    Icon: SearchIcon,
    link: "/OrderWorkbench",
  },
 
  
];

export default function OrderDashOverview() {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {Arr.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}

