exports.Company = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    length: 10,
    key: "true",
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "string",
    value: "",
    label: "Universal Descriptor",
  },
  {
    id: "name",
    name: "name",
    type: "string",
    value: "",
    label: "Name",
  },
  {
    id: "name1",
    name: "name1",
    type: "string",
    value: "",
    label: "Name 1",
  },
  {
    id: "streetAddress",
    name: "streetAddress",
    type: "string",
    value: "",
    label: "Street Address",
  },
  {
    id: "streetAddress1",
    name: "streetAddress1",
    type: "string",
    value: "",
    label: "Street Address",
  },
  {
    id: "city",
    name: "city",
    type: "string",
    value: "",
    label: "City",
  },
  {
    id: "state",
    name: "state",
    type: "string",
    value: "",
    label: "State",
  },
  {
    id: "regionId",
    name: "regionId",
    type: "string",
    value: "",
    label: "Region Id",
  },
  {
    id: "postalCode",
    name: "postalCode",
    type: "string",
    value: "",
    label: "PostalCode",
  },
  {
    id: "countryId",
    name: "countryId",
    type: "string",
    value: "",
    label: "Country Id",
  },
  {
    id: "taxIdNumber",
    name: "taxIdNumber",
    type: "string",
    value: "",
    label: "Tax Id Number",
  },
  {
    id: "salesTaxId",
    name: "salesTaxId",
    type: "string",
    value: "",
    label: "Sales Tax Id",
  },
  {
    id: "currencyId",
    name: "currencyId",
    type: "string",
    value: "",
    label: "Currency",
  },
  {
    id: "chartsAccountId",
    name: "chartsAccountId",
    type: "string",
    value: "",
    label: "Charts Account Id",
  },
  {
    id: "nameLocalizedText",
    name: "nameLocalizedText",
    type: "text",
    value: "",
    label: "nameLocalizedText",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
];
