/** @format */

import React, { useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import Button from "react-bootstrap/Button";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";

function PrintScaleTicket(props) {
  const [shipDoc, setShipDoc] = useState();
  const [serviceError, setServiceError] = useState([]);
  const [serviceSuccess, setServiceSuccess] = useState([]);

  const [
    _printShipDoc,
    _printShipDocData,
    _setPrintShipDocData,
    _errorPrintShipDoc,
    _setErrorPrintShipDoc,
    _successPrintShipDoc,
    _setSuccessPrintShipDoc,
    _loadingPriceShipDoc,
  ] = useFetch(GetFullURL("PrintScaleTicket"), { method: "POST" });

  const [
    _printScaleTicketLocalService,
    _printScaleTicketLocalServiceData,
    _setPrintScaleTicketLocalService,
    _errorPrintScaleTicketLocalService,
    _setErrorPrintScaleTicketLocalService,
    _successPrintScaleTicketLocalService,
    _setSuccessPrintScaleTicketLocalService,
    _loadingPrintScaleTicketLocalService,
  ] = useFetch("http://localhost:8089/PrintPDFNewLOAD", {
    method: "POST",
    headers: {},
  });

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessPrintShipDoc([]);
        _setSuccessPrintScaleTicketLocalService([]);
        break;
      case "error":
        _setErrorPrintShipDoc([]);
        _setErrorPrintScaleTicketLocalService([]);
        break;
      default:
        break;
    }
  }

  const operationLoading = _loadingPriceShipDoc;
  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card2">
                  <div className="cardHeader">
                    <h2>
                      <label htmlFor="ShipDocd">BOL Number</label>
                    </h2>
                  </div>
                  <div className="cardBody">
                    <div className="cardInput orderInput">
                      <input
                        placeholder={"BOL Number"}
                        type="text"
                        value={shipDoc ?? ""}
                        onChange={(e) => {
                          let val = e.target.value;
                          setShipDoc(val);
                        }}
                        className="cardInput"
                        style={{
                          borderRadius: 8,
                          outline: "none !important",
                          height: 70,
                          fontSize: 16,
                        }}
                      />
                    </div>
                    <div className="cardButton cardorder">
                      <Button
                        className="w-100"
                        onClick={() => {
                          _printShipDoc(
                            { data: { Id: shipDoc } },
                            ({ data: ShipDocBytes }) => {
                              _printScaleTicketLocalService(
                                {
                                  data: {
                                    ShipDocBytes: ShipDocBytes,
                                    ShipDoc: shipDoc,
                                  },
                                },
                                (response) => {
                                  if (response.messageType === "Success") {
                                    setServiceSuccess((current) => {
                                      return [...current, response.message];
                                    });
                                  }
                                  if (response.messageType === "Error") {
                                    setServiceError((current) => {
                                      return [...current, response.message];
                                    });
                                  }
                                }
                              );
                            }
                          );
                        }}
                      >
                        Print Scale Ticket
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <MessageLog
                errorMessages={[..._errorPrintShipDoc, ...serviceError]}
                successMessages={[..._successPrintShipDoc, ...serviceSuccess]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrintScaleTicket;
