export const ValidateUser = (_userData, _createErrorMessage) => {
  if (_userData.password === "") {
    _createErrorMessage("84");
    return false;
  }

  if (
    (_userData.singleTerminalUser === "true" ||
      _userData.singleTerminalUser === true) &&
    (!_userData.defaultLocationId || _userData.defaultLocationId === "select")
  ) {
    _createErrorMessage("125");
    return false;
  }

  return true;
};
