export const itemsGridFields = ({ dropDowns }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    label: "item Num",
    disabled: true,
  },
  {
    id: "accountId",
    name: "accountId",
    label: "Account",
    tag: "select",
    options: dropDowns?.accounts || [],
  },
  {
    id: "postingCode",
    name: "postingCode",
    label: "Posting Code",
  },
  {
    id: "amount",
    name: "amount",
    label: "Amount",
  },
  {
    id: "currencyId",
    name: "currencyId",
    label: "Currency",
    tag: "select",
    options: dropDowns?.currencies || [],
  },
  {
    id: "reference",
    name: "reference",
    label: "Reference",
  },
  {
    id: "DrCr",
    name: "DrCr",
    label: "DrCr",
    tag: "select",
    options: [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ],
  },
];


