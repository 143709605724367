import React, { useState, useEffect } from "react";
import { FieldsReducer } from "src/CommonComponents/Reducer/Index";
import { mainArray } from "./Fields";
import { useFetch } from "src/hooks/useFetch";
import { GetFullURL } from "src/CommonComponents/GlobalConfFiles/URLs";
import Button from "react-bootstrap/Button";
import { useMessage } from "src/hooks/UseMessage";
import { useNavigate } from "react-router-dom";
import MessageLog from "src/CommonComponents/Messages/MessageLog";
import { LoadingScreen } from "src/CommonComponents/LoadingScreen/index";
import Card from "src/CommonComponents/Card";
import additionalinfo from "src/Assets/info2.svg";
import LineChart from "src/CommonComponents/Charts/LineChart/LineChart";
import * as _ from "underscore";

export default function BOLProcessTimeChart() {
  let navigate = useNavigate();
  const [reportParam, setReportParam] = useState();
  const [reportData, setReportData] = useState({});
  const [showReport, setShowReport] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [xaxisName, setXaxisName] = useState("");
  const [yaxisName, setYaxisName] = useState("");
  const [chartName, setChartName] = useState("");
  const [numberOfCharts, setNumberOfCharts] = useState(0);
  const [chartIndex, setChartIndex] = useState(0);
  const [filteredCharts, setFilteredCharts] = useState([{}]);
  const [counter, setCounter] = useState(0);
  const [date, setDate] = useState("");

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    //If the value is select, clear value
    if (value === "SELECT" || value === "select") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    setReportParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const [
    _getChartData,
    _chartData,
    ,
    _errorChartData,
    _setErrorChartData,
    _successChartData,
    _setSuccessChartData,
    _loadingChartData,
  ] = useFetch(GetFullURL("BOLProcessTimeChart"), { method: "POST" });

  const [
    _getGeneralDD,
    _GeneralDDData,
    ,
    _errorGeneralDD,
    _setErrorGeneralDD,
    _successGeneralDD,
    _setSuccessGeneralDD,
    _loadingGeneralDD,
  ] = useFetch(GetFullURL("GetGeneralDropDown"), { method: "POST" });

  const operationLoading = _loadingChartData || _loadingGeneralDD;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessChartData([]);
        _setSuccessGeneralDD([]);
        break;
      case "error":
        _setErorMessages([]);
        _setErrorChartData([]);
        _setErrorGeneralDD([]);
        break;
      default:
        break;
    }
  }

  //this function splits array to chunks of certain dates
  const splitEntries = (data) => {
    let chunkSize = data.amountOfChunk;
    setNumberOfCharts(chunkSize);
    let x = 0;
    const res = [];
    for (let i = 0; i < chunkSize; i++) {
      for (let j = 0; j < data.processTimeItems.length; j++) {
        if (
          data.processTimeItems[j].shipDocDate ===
          data.filteredItems[i].shipDocDate
        ) {
          if (x === 0) {
            x = 1;
            const chunk = data.processTimeItems.filter(
              (x) => x.shipDocDate === data.filteredItems[i].shipDocDate
            );
            res.push(chunk);
          }
        }
      }
      x = 0;
    }

    setFilteredCharts(res);

    if (res.length != 0) {
      let id = res[0]?.map((x) => x.id);
      let time = res[0]?.map((x) => x.differenceInTime);
      let value = res[0]?.map((x) => x.chartValue);
      let bolDate = res[0]?.map((x) => x.shipDocDate);
      setReportData({
        ids: id,
        differenceInTime: time,
        chartValue: value,
        dates: bolDate,
      });

      setCounter(res[0].length);
      let date = bolDate[0];
      setDate(date.slice(0, 10));
      setShowReport(true);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(filteredCharts[0])) {
      let id = filteredCharts[chartIndex].map((x) => x.id);
      let time = filteredCharts[chartIndex].map((x) => x.differenceInTime);
      let value = filteredCharts[chartIndex].map((x) => x.chartValue);
      let bolDate = filteredCharts[chartIndex].map((x) => x.shipDocDate);
      setReportData({
        ids: id,
        differenceInTime: time,
        chartValue: value,
        dates: bolDate,
      });

      setCounter(filteredCharts[chartIndex].length);
      let date = bolDate[0];
      setDate(date.slice(0, 10));
      setShowReport(true);
    }
  }, [chartIndex]);

  useEffect(() => {
    _getGeneralDD({
      data: { locations: true },
    });
  }, []);

  const fieldsState = {
    dropDowns: _GeneralDDData || [],
  };

  const mainFieldsToRender = mainArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    return newFieldObject;
  };

  const runReport = () => {
    setChartIndex(0);

    if (
      reportParam?.locationId === null ||
      reportParam?.locationId === "" ||
      reportParam?.locationId === undefined
    ) {
      _createErrorMessage("121");
      return;
    }

    _getChartData(
      {
        data: {
          LocationId: reportParam.locationId,
        },
      },
      ({ data }) => {
        if (data.length == 0) {
          _createErrorMessage("90");
          return;
        }
        splitEntries(data);
      }
    );
  };

  const style = {
    marginLeft: "30px",
  };
console.log("filteredCharts",filteredCharts)
  if (showReport) {
    return (
      <div>
        {operationLoading && <LoadingScreen />}
        <h1 style={style}>
          {" "}
          Date : {date} , Number of BOLs: {counter}
        </h1>
        <LineChart
          name={"BOL Process Time in min"}
          {...reportData}
          yaxisName={yaxisName}
        />
        <div className="column" style={style}>
          {" "}
          <div className="row mt-3">
            <Button
              onClick={() => {
                if (chartIndex >= numberOfCharts - 1) {
                  return;
                }

                setChartIndex(chartIndex + 1);
                setShowReport(false);
                setCounter(filteredCharts[chartIndex].length);
              }}
              style={{
                width: "10%",
                background: "#3e8e41",
                borderColor: "#3e8e41",
              }}
            >
              Next
            </Button>
          </div>
          <div className="row mt-3">
            <Button
              onClick={() => {
                if (chartIndex <= 0) {
                  return;
                }
                setChartIndex(chartIndex - 1);
                setShowReport(false);
                setCounter(filteredCharts[chartIndex].length);
              }}
              style={{
                width: "10%",
                background: "#D4AC0D",
                borderColor: "#D4AC0D",
              }}
            >
              
              Previous 
            </Button>
          </div>
          <div className="row mt-3">
            <Button
              onClick={() => {
                setShowReport(false);
              }}
              style={{
                width: "10%",
                background: "#cd6155",
                borderColor: "#cd6155",
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container-fluid">
        {operationLoading && <LoadingScreen />}
        <div className="row mt-2">
          <div className="col-xl-3 col-lg-4 col-sm-12 col-xs-12">
            <Card
              cardHeaderProps={{
                cardlogo: additionalinfo,
                headerLabel: "BOL Process Time",
              }}
              cardBody={mainFieldsToRender.map((field, index) => (
                <FieldsReducer
                  field={mergedFieldData(field, reportParam)}
                  key={index}
                />
              ))}
            />
            <div className="row ml-3 mt-3">
              <div className="col-6">
                <Button
                  style={{
                    width: "50%",
                  }}
                  onClick={() => {
                    runReport();
                  }}
                >
                  Run
                </Button>
              </div>
              <div className="col-6">
                <Button
                  onClick={() => {
                    navigate(`/DashboardHome`);
                  }}
                  style={{
                    width: "50%",
                    background: "#DA1212",
                    borderColor: "#DA1212",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-3">
            <MessageLog
              errorMessages={[
                ..._errorGeneralDD,
                ..._errorChartData,
                ..._errorMessages,
              ]}
              successMessages={[]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
          </div>
        </div>
      </div>
    );
  }
}
