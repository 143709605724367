import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput";
import OverviewButtons from "./Components/OverviewButtons";
import { useFetch } from "../../hooks/useFetch";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../CommonComponents/CircleButton";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import cardlogo from "../../Assets/cardLogo.svg";
import { useMessage } from "../../hooks/UseMessage";

function Index() {
  let navigate = useNavigate();

  const [financialDoc, setFinancialDoc] = useState();

  const [showFinancialDocList, setShowFinancialDocList] = useState(false);

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();


  const [
    _getFinancialDocList,
    _financialDocList,
    ,
    ,
    ,
    ,
    ,
    _loadingFinancialDocList,
  ] = useFetch(GetFullURL("FinancialDocList"), { method: "POST" });

  const [_getFinancialDocExists, , , , , , , _loadingFinancialDocExist] =
    useFetch(GetFullURL("FinancialDocExists"), { method: "POST" });


  const operationLoading =
    _loadingFinancialDocList ||
    _loadingFinancialDocExist ;

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../Assets/icon/FinancialDocument.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Financial Document Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setFinancialDoc}
                    document={financialDoc}
                    placeholder="Financial Document id here..."
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`Financial Documents`}
                    clickHandler={() => {
                      _getFinancialDocList({}, () => {
                        setShowFinancialDocList(true);
                      });
                    }}
                  />
                  <OverviewButtons
                    financialDoc={financialDoc}
                    navigate={navigate}
                    _getFinancialDocExists={_getFinancialDocExists}
                    _createErrorMessage={_createErrorMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setFinancialDoc}
              dataList={_financialDocList}
              showListModal={showFinancialDocList}
              setShowModal={setShowFinancialDocList}
              title="List Of Inventory Documents"
              columns={[
                { field: "id", headerName: "Shipping Doc ID", width: 250 },
                { field: "companyId", headerName: "Company", width: 350 },
                { field: "financialDocTypeId", headerName: "Financial Document Type", width: 250 },
                { field: "financialDocDate", headerName: "Financial Document Date", width: 200 },
              ]}
            />
          </div>
        </div>
        <MessageLog
          errorMessages={[..._errorMessages,]}
          successMessages={[]}
          warningMessages={[]}
          clearMessages={clearMessages}
        ></MessageLog>
      </div>
    </section>
  );
}

export default Index;
