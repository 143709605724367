import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import * as _ from "underscore";
import { PartnerContext } from "../context";
import PartnerRoleSelect from "./PartnerRoleSelect";
import PartnerRelationsGrid from "./Grid/PartnerRelationsGrid";
import PartnerList from "./PartnerList";
import { useSelector } from "react-redux";
function GridMain() {
  const [showNewPartnerAssignmentModal, setShowNewPartnerAssignmentModal] =
    useState(false);

  const [partnerRole, setPartnerRole] = useState();

  const {
    partnerId,
    _partnerData,
    _partnerDD,
    operation,
    _setPartnerData,
    _partnersList,
    _getPartnersList,
    _createErrorMessage,
  } = useContext(PartnerContext);

  let data = _partnerData?.partnerRelationsComm ?? [];

  function addPartnerRole(addedPartnerId) {
    //read full partner data
    const selectedPartner = _partnersList.find((x) => x.id === addedPartnerId);
    const result = data.find(
      (x) =>
        x.relatedPartnerId === addedPartnerId && x.partnerRole === partnerRole
    );
    if (result != null) {
      _createErrorMessage("53");

      setShowNewPartnerAssignmentModal(false);
      return;
    }

    let maxAssignmentNumber;
    let assignmetNumbers = [];

    data.forEach((arrayItem) => {
      assignmetNumbers.push(arrayItem.assignmentId);
    });
    maxAssignmentNumber = _.max(assignmetNumbers);
    if (maxAssignmentNumber === -Infinity) {
      maxAssignmentNumber = 1;
    } else {
      maxAssignmentNumber = maxAssignmentNumber + 1;
    }

    //Add the max assignment number and assigned partner ID
    let newPartnerAssignmentObject = {
      partnerId: partnerId,
      assignmentId: maxAssignmentNumber,
      partnerRole: partnerRole,
      relatedPartnerId: addedPartnerId,
      name: selectedPartner.name,
      code: selectedPartner.code,
      contactName: selectedPartner.contactName,
      locationId: selectedPartner.locationId,
      streetAddress: selectedPartner.streetAddress,
      streetAddress1: selectedPartner.streetAddress1,
      city: selectedPartner.city,
      regionId: selectedPartner.regionId,
      postalCode: selectedPartner.postalCode,
      countryId: selectedPartner.CountryId,
    };

    updatePartnerRelations("Append", newPartnerAssignmentObject);
    setShowNewPartnerAssignmentModal(false);
  }

  const updatePartnerRelations = (action, Data) => {
    let currentPartnerRelations = _partnerData?.partnerRelationsComm || [];
    switch (action) {
      case "Append":
        _setPartnerData((prev) => ({
          ...prev,
          partnerRelationsComm: [...currentPartnerRelations, Data],
        }));
        break;
      case "Delete":
        _setPartnerData((prev) => ({
          ...prev,
          partnerRelationsComm: currentPartnerRelations?.filter(
            (partner) => partner.assignmentId != Data
          ),
        }));
        break;
      default:
    }
  };
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="selectWrapper">
            <PartnerRoleSelect
              operation={operation}
              options={_partnerDD?.partnerRoles}
              partnerRole={partnerRole}
              setPartnerRole={setPartnerRole}
            />
            <Button
              variant="primary"
              disabled={operation === "Display"}
              onClick={() => {
                if (!partnerRole) {
                  _createErrorMessage("52");
                  return;
                }
                _getPartnersList(
                  { data: { partnerTypeId: partnerRole } },
                  ({ data: partners }) => {
                    setShowNewPartnerAssignmentModal(true);
                  }
                );
              }}
            >
              Add
            </Button>
          </div>
        </div>
        <PartnerRelationsGrid updatePartnerRelations={updatePartnerRelations} />
      </div>
      <PartnerList
        setShowNewPartnerAssignmentModal={setShowNewPartnerAssignmentModal}
        showNewPartnerAssignmentModal={showNewPartnerAssignmentModal}
        addPartnerRole={addPartnerRole}
        partnerList={_partnersList}
      />
    </div>
  );
}

export default GridMain;
