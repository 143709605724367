import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "./Scss/Input.scss";
import "toastr/build/toastr.min.css";
import "./Scss/Partner.scss";
import "./Scss/Overview.scss";
import "./Scss/Login.scss";
import "./Scss/Cards.scss";
import "./Scss/Common.scss";
//import "./Scss/header.scss";
//remove file after a while
import "bootstrap-icons/font/bootstrap-icons.css";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import Navigations from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux/store";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  //Ktelfah 5/1/2022, removed strict mode becuase itr use cuase a onMount useEffect
  //to run twice in developement only and that can be confusing
  //  <React.StrictMode>
  <Provider store={store}>
    <Router>
      <Navigations />
    </Router>
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
