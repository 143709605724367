import React, { useEffect } from "react";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import TerminalHomeRoutes from "./TerminalHomeRoutes";
import PartnerRoutes from "./PartnerRoutes";
import ShipDocRoutes from "./ShipDocRoutes";
import TerminalActivityRoutes from "./TerminalActivityRoutes";
import SplashScreen from "../Screens/splash";
import { newUserData, LogOut } from "../redux/Features/UserSlice";
import { NewStationContent } from "../redux/Features/StationSlice";
import { NewShipDocDDContent } from "../redux/Features/DropDownsSlice";
import Header from "../CommonComponents/Header/Header";

import { GetFullURL } from "../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../hooks/useFetch";
import { useMessage } from "../hooks/UseMessage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import MessageLog from "../CommonComponents/Messages/MessageLog";
import { useLocation } from "react-router-dom";
const Navigations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let token = localStorage.getItem("token");
  let id = localStorage.getItem("id");

  const [
    _getStation,
    ,
    ,
    _errorStation,
    _setErrorStation,
    ,
    ,
    _loadingStation,
  ] = useFetch(GetFullURL("GetStation"), { method: "POST" });

  const [_getUser, , , _errorUser, _setErrorUser, , , _loadingUser] = useFetch(
    GetFullURL("Getuser"),
    { method: "POST" },
    true
  );
  const [
    _getShipDocDD,
    _shipDocDDData,
    ,
    _errorShipDocDD,
    setErrorShipDocDD,
    ,
    ,
    _loadingtShipDocDD,
  ] = useFetch(GetFullURL("ShippingDocDropDowns"), { method: "POST" }, true);

  const location = useLocation();
  const [_createErrorMessage, _errorMessages, _setErorMessages, , , , , , ,] =
    useMessage();
  //onMount (refresh) is the token is present then re- read the user
  useEffect(() => {
    if (token && id) {
      _getUser(
        {
          data: {
            Id: id,
          },
        },
        ({ data: userData }) => {
          if (userData == null) {
            dispatch(LogOut());
            return;
          }
          let userObj = { user: userData, token: token };

          dispatch(newUserData(userObj));
          if (userData.stationId) {
            _getStation(
              {
                data: {
                  stationId: userData.stationId,
                },
              },
              ({ data: stationInfo }) => {
                dispatch(NewStationContent(stationInfo));
              },
              true
            );
          }

          if (userData.defaultUrl) {
            if (location.pathname === "/Login") {
              navigate(userData.defaultUrl);
            } else {
              if (location.pathname) {
                navigate(location.pathname);
              }
            }
          } else {
            if (location.pathname === "/Login") {
              navigate("/Home");
            } else {
              if (location.pathname) {
                navigate(location.pathname);
              } else {
                navigate("/Home");
              }
            }
          }
        }
      );

      _getShipDocDD({}, ({ data: ddInfo }) => {


        dispatch(NewShipDocDDContent(ddInfo));
      });
    } else {
      //if get user fails then re-direct to login
      if (location.pathname != "/Login") {
        navigate("/Login");
      }
    }
  }, [token, id]);

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErrorStation([]);
        _setErrorUser([]);

        break;
      case "warning":
        break;
      default:
        break;
    }
  }
  const operationLoading =
    _loadingUser || _loadingtShipDocDD || _loadingStation;
  return (
    <>
      {operationLoading ? (
        <SplashScreen />
      ) : (
        <>
          <Header />
          <PublicRoutes />
          <PrivateRoutes />
          <TerminalHomeRoutes />
          <PartnerRoutes />
          <ShipDocRoutes />
          <TerminalActivityRoutes />
        </>
      )}

      <MessageLog
        errorMessages={[..._errorUser, ..._errorStation, ..._errorMessages]}
        successMessages={[]}
        warningMessages={[]}
        clearMessages={clearMessages}
      ></MessageLog>
    </>
  );
};

export default Navigations;
