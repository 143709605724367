/** @format */

import React, { useContext } from "react";
import {
  checkinDataArray,
  inWeightDataArray,
  outWeightDataArray,
  loadStatusDataArray,
  signatureStatusDataArray,
  extSystemSyncStatusDataArray,
  otherStatusDataArray,
} from "./Fields";
import { ShipDocContext } from "../Contex";
import { FieldsReducer } from "./../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";
import Card from "src/CommonComponents/Card";
export default function StatusTab(props) {
  const { onChange, operation, dropDowns, _shipDocData, _setShipDocData } =
    useContext(ShipDocContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _shipDocData, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _shipDocData,
    dropDowns,
  };

  const checkinfieldsToRender = checkinDataArray(fieldsState);

  const inWeightfieldsToRender = inWeightDataArray(fieldsState);

  const outWeightFieldsToRender = outWeightDataArray(fieldsState);

  const loadStatusFieldToRender = loadStatusDataArray(fieldsState);

  const signatureStatusFieldToRender = signatureStatusDataArray(fieldsState);

  const extSystemSyncStatusFieldToRender =
    extSystemSyncStatusDataArray(fieldsState);
  const otherStatusFieldToRender = otherStatusDataArray(fieldsState);
  return (
    <div className="row">
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card mb-4" style={{ minHeight: 400 }}>
          <div className="cardHeader">
            <h2>Checkin</h2>
          </div>
          <div className="cardBody">
            {checkinfieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
            <Button
              variant="primary"
              // active="true"
              data-op="UpdateCheckin"
              disabled={operation === "Display"}
              onClick={() => {
                _setShipDocData((prevState) => {
                  if (prevState.checkedStatus === "X") {
                    return {
                      ...prevState,
                      ...{
                        checkedStatus: "",
                        checkedStDT: null,
                        checkedEnDT: null,
                      },
                    };
                  } else {
                    return {
                      ...prevState,
                      ...{
                        checkedStatus: "X",
                        checkedStDT: new Date(),
                        checkedEnDT: new Date(),
                      },
                    };
                  }
                });
              }}
            >
              Flip Status
            </Button>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card mb-4" style={{ minHeight: 400 }}>
          <div className="cardHeader">
            <h2>In Weight</h2>
          </div>
          <div className="cardBody">
            {inWeightfieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
            <Button
              variant="primary"
              // active="true"
              data-op="UpdateCheckin"
              disabled={operation === "Display"}
              onClick={() => {
                _setShipDocData((prevState) => {
                  if (prevState.inWeightStatus === "X") {
                    return {
                      ...prevState,
                      ...{
                        inWeightStatus: "",
                        inWeightStDT: null,
                        inWeightEnDT: null,
                      },
                    };
                  } else {
                    return {
                      ...prevState,
                      ...{
                        inWeightStatus: "X",
                        inWeightStDT: new Date(),
                        inWeightEnDT: new Date(),
                      },
                    };
                  }
                });
              }}
            >
              Flip Status
            </Button>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card mb-4" style={{ minHeight: 400 }}>
          <div className="cardHeader">
            <h2>Out Weight</h2>
          </div>
          <div className="cardBody">
            {outWeightFieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
            <Button
              variant="primary"
              // active="true"
              data-op="UpdateCheckin"
              disabled={operation === "Display"}
              onClick={() => {
                _setShipDocData((prevState) => {
                  if (prevState.outWeightStatus === "X") {
                    return {
                      ...prevState,
                      ...{
                        outWeightStatus: "",
                        outWeightStDT: null,
                        outWeightEnDT: null,
                      },
                    };
                  } else {
                    return {
                      ...prevState,
                      ...{
                        outWeightStatus: "X",
                        outWeightStDT: new Date(),
                        outWeightEnDT: new Date(),
                      },
                    };
                  }
                });
              }}
            >
              Flip Status
            </Button>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card mb-4" style={{ minHeight: 400 }}>
          <div className="cardHeader">
            <h2>Load Status</h2>
          </div>
          <div className="cardBody">
            {loadStatusFieldToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
            <Button
              variant="primary"
              // active="true"
              data-op="UpdateCheckin"
              disabled={operation === "Display"}
              onClick={() => {
                _setShipDocData((prevState) => {
                  if (prevState.loadStatus === "X") {
                    return {
                      ...prevState,
                      ...{
                        loadStatus: "",
                        loadStDT: null,
                        loadEnDT: null,
                      },
                    };
                  } else {
                    return {
                      ...prevState,
                      ...{
                        loadStatus: "X",
                        loadStDT: new Date(),
                        loadEnDT: new Date(),
                      },
                    };
                  }
                });
              }}
            >
              Flip Status
            </Button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <div className="card mb-4" style={{ minHeight: 400 }}>
            <div className="cardHeader">
              <h2>Signature Status</h2>
            </div>
            <div className="cardBody">
              {signatureStatusFieldToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
              <Button
                variant="primary"
                // active="true"
                data-op="UpdateCheckin"
                disabled={operation === "Display"}
                onClick={() => {
                  _setShipDocData((prevState) => {
                    if (prevState.signatureStatus === "X") {
                      return {
                        ...prevState,
                        ...{
                          signatureStatus: "",
                          signatureDT: null,
                        },
                      };
                    } else {
                      return {
                        ...prevState,
                        ...{
                          signatureStatus: "X",
                          signatureDT: new Date(),
                        },
                      };
                    }
                  });
                }}
              >
                Flip Status
              </Button>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <div className="card mb-4" style={{ minHeight: 400 }}>
            <div className="cardHeader">
              <h2>Ext System Sync Status</h2>
            </div>
            <div className="cardBody">
              {extSystemSyncStatusFieldToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
              <Button
                variant="primary"
                // active="true"
                data-op="UpdateCheckin"
                disabled={operation === "Display"}
                onClick={() => {
                  _setShipDocData((prevState) => {
                    if (prevState.extSystemSyncStatus === "X") {
                      return {
                        ...prevState,
                        ...{
                          extSystemSyncStatus: "",
                          extSystemSyncStDT: null,
                          extSystemSyncEnDT: null,
                        },
                      };
                    } else {
                      return {
                        ...prevState,
                        ...{
                          extSystemSyncStatus: "X",
                          extSystemSyncStDT: new Date(),
                          extSystemSyncEnDT: new Date(),
                        },
                      };
                    }
                  });
                }}
              >
                Flip Status
              </Button>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 col-xs-12">
          <div className="card mb-4" style={{ minHeight: 400 }}>
            <div className="cardHeader">
              <h2>Other Status</h2>
            </div>
            <div className="cardBody">
              {otherStatusFieldToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
