export const mainDataArray = ({ dropDowns }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Id",
    key: true,
    displayOnly: true,
    length: 50,
  },
  {
    id: "labTestTypeId",
    name: "labTestTypeId",
    type: "text",
    label: "Test Type",
    tag: "select",
    options: dropDowns?.labTestTypes || [],
  },
  {
    id: "testRequestId",
    name: "testRequestId",
    type: "text",
    label: "Test Request",
  },
  {
    id: "testRequestClass",
    name: "testRequestClass",
    type: "text",
    label: "Test Request Class",
  },
  {
    id: "productGroup",
    name: "productGroup",
    type: "text",
    label: "Product Group",
    tag: "select",
    options: dropDowns?.productGroups || [],
  },
];
export const timeDataArray = ({ dropDowns }) => [
  {
    id: "validFrom",
    name: "validFrom",
    type: "datetime",
    label: "Valid From",
  },
  {
    id: "validTo",
    name: "validTo",
    type: "datetime",
    label: "Valid To",
  },
  {
    id: "testDate",
    name: "testDate",
    type: "datetime",
    label: "Test Date",
  },
];
export const valuesArray = ({ dropDowns }) => [
  {
    id: "value",
    name: "value",
    type: "number",
    label: "Value",
  },
  {
    id: "additionalValue",
    name: "additionalValue",
    type: "number",
    label: "Additional Value",
  },
  {
    id: "unit",
    name: "unit",
    type: "text",
    label: "Unit",
    tag: "select",
    options: dropDowns?.weightUnits || [],
  },
];
export const enhancedDDInputArray = ({}) => [
  {
    id: "testMethodId",
    name: "testMethodId",
    type: "text",
    label: "Test Method Id",
    tag: "enhancedInput",
  },
  {
    id: "materialId",
    name: "materialId",
    type: "text",
    label: "Material",
    tag: "enhancedInput",
  },
  
];


