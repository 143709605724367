import React from "react";

export default function OverviewInput({ setDocument, document, placeholder }) {
  return (
    <input
      placeholder={placeholder}
      type="text"
      value={document ?? ""}
      onChange={(e) => {
        let val = e.target.value;
        setDocument(val);
      }}
      className="form-control"
      style={{
        borderRadius: 8,
        outline: "none !important",
        padding: "0px 16px",
        height: 50,
        fontSize: 16,
      }}
    />
  );
}
