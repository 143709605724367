export const itemGridFields = ({ dropDowns }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    label: "item Num",
    disabled: true,
  },

  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    tag: "select",
    options: dropDowns?.materials || [],
  },

  {
    id: "quantity",
    name: "quantity",
    label: "Quantity",
  },
  {
    id: "unitId",
    name: "unitId",
    label: "Unit",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "quantity1",
    name: "quantity1",
    label: "Add Quantity ",
  },
  {
    id: "unit1Id",
    name: "unit1Id",
    label: "Unit",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
];
