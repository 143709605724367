export const ValidateTakingWeight = (
  shipDocData,
  weight,
  requestedWeightType,
  _createErrorMessage
) => {
  if (weight === undefined) {
    _createErrorMessage("56");
    return false;
  }
  if (weight <= 10000) {
    _createErrorMessage("56");
    return false;
  }

  //If trying to take out might, then in must have been taken
  if (requestedWeightType.current === `OUT`) {
    if (shipDocData.inWeight <= 0) {
      _createErrorMessage("57");
      return false;
    }
  }
  return true;
};
