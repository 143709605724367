export const stationArray = ({ dropDowns }) => [
  {
    id: "CurrentStation",
    name: "CurrentStation",
    type: "text",
    label: "CurrentStation",
    length: 24,
    tag: "select",
    options: dropDowns?.stations || [],
  },
  {
    id: "fieldProcessId",
    name: "fieldProcessId",
    type: "text",
    label: "Field Process",
    length: 12,
    tag: "select",
    options: dropDowns?.fieldProcesses || [],
  },
  {
    id: "terminalSetupId",
    name: "terminalSetupId",
    type: "text",
    label: "Terminal Setup",
  },
  {
    id: "loadingStationId",
    name: "loadingStationId",
    type: "text",
    label: "Loading Station",
    length: 24,
    tag: "select",
    options: dropDowns?.stations || [],
  },
  {
    id: "scaleStationId",
    name: "scaleStationId",
    type: "text",
    label: "Scale Station",
    length: 24,
    tag: "select",
    options: dropDowns?.stations || [],
  },
];
export const printerArray = ({ dropDowns }) => [
  {
    id: "printerId",
    name: "printerId",
    type: "text",
    label: "Printer",
    length: 12,
  },
  {
    id: "scaleTicketRelevant",
    name: "scaleTicketRelevant",
    tag: "checkbox",
    type: "checkbox",
    label: "Scale Ticket Relevant",
  },
  {
    id: "printShipDocRelevant",
    name: "printShipDocRelevant",
    tag: "checkbox",
    type: "checkbox",
    label: "Print Ship Doc Relevant",
  },
  {
    id: "shipDocPrintOptions",
    name: "shipDocPrintOptions",
    type: "text",
    label: "Ship Doc Print Options",
    length: 3,
  },
];

export const loaderArray = ({ dropDowns }) => [
  {
    id: "loadAreaId",
    type: "text",
    name: "loadAreaId",
    label: "Load Dock",
    tag: "select",
    options: dropDowns?.loadAreas || [],
  },

  {
    id: "loadBayId",
    type: "text",
    name: "loadBayId",
    label: "Load Bay ",
    tag: "select",
    options: dropDowns?.loadBays || [],
  },
  {
    id: "loadArmId",
    name: "loadArmId",
    label: "Load Arm",
    tag: "select",
    options: dropDowns?.loadArms || [],
  },
  {
    id: "LoaderName",
    name: "LoaderName",
    label: "LoaderName",
    type: "text",
    length: 50,
  },
];
