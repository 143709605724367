import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetFullURL } from "./../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import { useMessage } from "../../hooks/UseMessage";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput"
import OverviewButtons from "./Components/OverviewButtons";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../CommonComponents/CircleButton";
import MessageLog from "../../CommonComponents/Messages/MessageLog";

function MaterialOverview() {
  let navigate = useNavigate();

  const [material, setMaterial] = useState();
  const [showMaterialList, setShowMaterialList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [_getMaterialList, _materialList, , , , , , _loadingtMaterialList] =
    useFetch(GetFullURL("MaterialList"), { method: "POST" });

  const [_getMaterialExists, , , , , , , _loadingtMaterialExist] = useFetch(
    GetFullURL("MaterialExists"),
    { method: "POST" }
  );

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading = _loadingtMaterialExist || _loadingtMaterialList;
  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                    <img src={require("../../Assets/icon/Material.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Material Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setMaterial}
                    document={material}
                    placeholder="Material id here..."
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of Materials`}
                    clickHandler={() => {
                      _getMaterialList({}, () => {
                        setShowMaterialList(true);
                      });
                    }}
                  />
                  <OverviewButtons
                    material={material}
                    navigate={navigate}
                    _getMaterialExists={_getMaterialExists}
                    _createErrorMessage={_createErrorMessage}
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages]}
              successMessages={[]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
            <div className="cardAlert">
              <ListModal
                setId={setMaterial}
                dataList={_materialList}
                showListModal={showMaterialList}
                setShowModal={setShowMaterialList}
                title="List Of Materials"
                columns={[
                  { field: "id", headerName: "Material ID", width: 100 },
                  { field: "code", headerName: "Code", width: 250 },
                  { field: "name", headerName: "Name", width: 350 },
                  { field: "materialTypeId", headerName: "Material Type", width: 150}
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MaterialOverview;
