/** @format */

import React, { useContext } from "react";
import { mainDataArray, statusArray} from "./Fields";
import { InvoiceContext } from "../Contex";
import { FieldsReducer } from "./../../../../CommonComponents/Reducer/Index";

export default function AdminTab(props) {
  const { onChange, operation, dropDowns, _invoice } =
    useContext(InvoiceContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _invoice, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dropDowns,
  };
  
  const mainDataArrayFields = mainDataArray(fieldsState);
  const statusArrayFields = statusArray(fieldsState);
  return (
    <div className="row">
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card">
          <div className="cardHeader">
            <h2>Admin</h2>
          </div>
          <div className="cardBody">
            {mainDataArrayFields.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card">
          <div className="cardHeader">
            <h2>Status</h2>
          </div>
          <div className="cardBody">
            {statusArrayFields.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
