import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userData",
  initialState: { User: {} },
  reducers: {
    newUserData: (state, action) => {
      state.User = {
        ...action.payload,
        token: action.payload.token,
        isAuthenticated: true,
      };
    },
    LogOut: (state, action) => {
      state.User = {};
      localStorage.clear();
    },
  },
});

// Action creators are generated for each case reducer function
export const { newUserData, LogOut } = userSlice.actions;

export default userSlice.reducer;
