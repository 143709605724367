export const adminArray1 = ({ dropDowns }) => [
  {
    id: "statusId",
    name: "statusId",
    type: "text",
    label: "Overall Status",
    tag: "select",
    options: dropDowns?.shipDocStatuses || [],
  },
  {
    id: "dataOriginId",
    name: "dataOriginId",
    type: "text",
    label: "Data Origin",
    tag: "select",
    options: dropDowns?.dataOrigins || [],
  },
  {
    id: "voidStatusId",
    name: "voidStatusId",
    type: "text",
    label: "Void Status",
    tag: "select",
    options: dropDowns?.shipDocVoidStatuses || [],
  },
  {
    id: "isReversal",
    name: "isReversal",
    type: "checkbox",
    label: "Reversal",
    tag: "checkbox",
  },
  {
    id: "erpPostingBlock",
    name: "erpPostingBlock",
    label: "ERP Posting Block",
    tag: "checkbox",
    type: "checkbox",
  },

  {
    id: "isRetrun",
    name: "isRetrun",
    label: "Retrun",
    tag: "checkbox",
    type: "checkbox",
  },
  {
    id: "isCrude",
    name: "isCrude",
    label: "Crude Order",
    tag: "checkbox",
    type: "checkbox",
  },
];
