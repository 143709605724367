import React, { useState, useEffect } from "react";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../../hooks/useFetch";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { mainArray, tankArray } from "./Fields";
import { useNavigate } from "react-router-dom";
import { FieldsReducer } from "../../../CommonComponents/Reducer/Index";
import { DataGrid } from "@mui/x-data-grid";
import "react-tabs/style/react-tabs.css";
import { useMessage } from "../../../hooks/UseMessage";
import TankField from "../../../CommonComponents/EnhancedFields/Tank/TankField";
import TankList from "../../../CommonComponents/DropDowns/Tank/TankList";

function TankPhysicalWorkbench(props) {
  let navigate = useNavigate();
  const [tankPhysicalSearchParam, setTankPhysicalSearchParam] = useState();
  const [tankPhysicalSearch, setTankPhysicalSearch] = useState([]);
  const [searchView, setSearchView] = useState(false);
  const [searchFiltersView, setSearchFiltersView] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErrorMessages] = useMessage();
  const [selectionModel, setSelectionModel] = useState([]);
  const [tankPhysicalButtonCreate, setTankPhysicalButtonCreate] =
    useState(false);
  const [tankPhysicalActionButtons, setTankPhysicalActionButtons] =
    useState(false);
  const [showTankList, setShowTankList] = useState(false);
  const [tankFieldName, setTankFieldName] = useState();
  const [initCreateButton, setInitCreateButton] = useState(false);

  const [
    _tankPhysicalSearch,
    _tankPhysicalSearchData,
    ,
    _errorTankPhysicalSearch,
    _setErrorTankPhysicalSearch,
    _successTankPhysicalSearch,
    _setSuccessTankPhysicalSearch,
    _loadingTankPhysicalSearch,
  ] = useFetch(GetFullURL("GetFileteredTankPhysicals"), { method: "POST" });

  const [
    _getTankList,
    _tankList,
    ,
    _errorTankList,
    _setErrorTankList,
    _successTankList,
    _setSuccessTankList,
    _loadingTankList,
  ] = useFetch(GetFullURL("TankList"), { method: "POST" });

  const [
    _forceDeleteTankPhysical,
    ,
    _setForceDeleteTankPhysical,
    _errorForceDeleteTankPhysical,
    _setForceErrorDeleteTankPhysical,
    _successForceDeleteTankPhysical,
    _setForceSuccessDeleteTankPhysical,
    _loadingForceDeleteTankPhysical,
  ] = useFetch(GetFullURL("ForceDeleteTankPhysical"), { method: "POST" });

  const operationLoading =
    _loadingTankPhysicalSearch ||
    _loadingTankList ||
    _loadingForceDeleteTankPhysical;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _successTankPhysicalSearch([]);
        _successTankList([]);
        _successForceDeleteTankPhysical([]);
        break;
      case "error":
        _setErrorTankList([]);
        _setErrorTankPhysicalSearch([]);
        _setForceErrorDeleteTankPhysical([]);
        _setErrorMessages([]);
        break;
      default:
        break;
    }
  }

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;

    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = true) : (newValue = false);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    setTankPhysicalSearchParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const fieldsState = {
    dropDowns: [],
  };

  const mainFieldsToRender = mainArray(fieldsState);
  const tankArrayToRender = tankArray(fieldsState);

  var tankField = tankArrayToRender.find(({ id }) => id === "tankId");

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = {
      ...field,
      dataSource: tankPhysicalSearchParam,
      onChange,
    };

    return newFieldObject;
  };
  const columns = [
    { field: "id", headerName: "Tank Physical ID", width: 150 },
    { field: "physicalDocTypeId", headerName: "PhysicalDocTypeId", width: 250 },
    { field: "tankId", headerName: "Tank", width: 190 },
    { field: "date", headerName: "Date", width: 190 },
    { field: "materialDescriptor", headerName: "Material", width: 350 },
    {
      field: "physicalInventoryQty",
      headerName: "Physical Inventory Qty",
      width: 350,
    },
    {
      field: "physicalInventoryUnitId",
      headerName: "Physical Inventory Unit",
      width: 350,
    },
    { field: "temperature", headerName: "Temperature", width: 150 },
    { field: "temperatureUnitId", headerName: "Temperature Unit", width: 150 },
    { field: "density", headerName: "Density", width: 100 },
    { field: "densityUnitId", headerName: "Density Unit", width: 100 },
    { field: "gravity", headerName: "Gravity", width: 250 },
    { field: "specificGravity", headerName: "Specific Gravity", width: 250 },
    { field: "forDelete", headerName: "For Delete", width: 100 },
    { field: "createdBy", headerName: "Created By", width: 350 },
    { field: "createdOn", headerName: "Created On", width: 350 },
    { field: "modifiedBy", headerName: "Modified By", width: 350 },
    { field: "modifiedOn", headerName: "Modified On", width: 350 },
  ];

  let tankPhysical;
  useEffect(() => {
    if (Array.isArray(_tankPhysicalSearchData)) {
      tankPhysical = _tankPhysicalSearchData.find(
        (x) => x.id === selectionModel[0]
      );
    }

    if (selectionModel.length > 1) {
      setTankPhysicalActionButtons(false);
      _createErrorMessage("112");
      return;
    }

    if (selectionModel[0] != undefined) {
      setTankPhysicalActionButtons(true);
      setTankPhysicalButtonCreate(false);
    } else {
      setTankPhysicalActionButtons(false);
      setTankPhysicalButtonCreate(true);
    }
  }, [selectionModel]);

  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            {searchFiltersView ? (
              " "
            ) : (
              <div className="col-12">
                <div className="card mb-4">
                  <div className="cardHeader">
                    <h2>Tank Physicals Workbench</h2>
                  </div>
                  <div className="cardBody">
                    {mainFieldsToRender.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field, tankPhysicalSearchParam)}
                        key={index}
                      />
                    ))}
                    <TankField
                      field={mergedFieldData(tankField)}
                      setShowList={setShowTankList}
                      setTankFieldName={setTankFieldName}
                      _getTankList={_getTankList}
                    />
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{
                          width: "32%",
                        }}
                        onClick={() => {
                          _tankPhysicalSearch(
                            {
                              data: {
                                TankId: tankPhysicalSearchParam?.tankId,
                                Date: tankPhysicalSearchParam?.date,
                              },
                            },
                            ({ data }) => {
                              console.log(data);
                              if (data != null) {
                                if (data.length != 0) {
                                  setTankPhysicalSearch(data);
                                  setSearchView(true);
                                  setSearchFiltersView(true);
                                  setInitCreateButton(false);
                                } else {
                                  _createErrorMessage("111");
                                  setInitCreateButton(true);
                                }
                              }
                            }
                          );
                        }}
                      >
                        Execute
                      </Button>
                      <Button
                        onClick={() => {
                          let navigationNextUrl = `/TankPhysicalOverview`;
                          navigate(navigationNextUrl);
                        }}
                        style={{
                          width: "32%",
                          background: "#DA1212",
                          borderColor: "#DA1212",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    {initCreateButton ? (
                      <Button
                        onClick={() => {
                          let navigationNextUrl = `/TankPhysical/Create`;
                          window.open(navigationNextUrl);
                        }}
                        style={{
                          width: "150px",
                          background: "#229954",
                          borderColor: "#229954",
                          marginTop: "15px",
                        }}
                      >
                        Create Tank Physical
                      </Button>
                    ) : (
                      " "
                    )}
                  </div>
                </div>
              </div>
            )}
            {searchView ? (
              <div>
                <div
                  className="custom-table w-100"
                  style={{ height: 600, overflow: "auto" }}
                >
                  <DataGrid
                    rows={_tankPhysicalSearchData || []}
                    columns={columns}
                    pageSize={100}
                    checkboxSelection
                    disableSelectionOnClick
                    rowsPerPageOptions={[14]}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                  />
                </div>
                <div className="d-flex flex-row">
                  <div className="p-2">
                    <Button
                      onClick={() => {
                        if (
                          tankPhysicalSearchParam?.tankId ||
                          tankPhysicalSearchParam?.date
                        ) {
                          tankPhysicalSearchParam.tankId = null;
                          tankPhysicalSearchParam.date = null;
                        }

                        setSearchView(false);
                        setSearchFiltersView(false);
                      }}
                      style={{
                        width: "150px",
                        background: "#DA1212",
                        borderColor: "#DA1212",
                      }}
                    >
                      Reset Filter
                    </Button>
                    <Button
                      onClick={() => {
                        _tankPhysicalSearch(
                          {
                            data: {
                              TankId: tankPhysicalSearchParam?.tankId,
                              Date: tankPhysicalSearchParam?.date,
                            },
                          },
                          ({ data }) => {
                            if (data != null) {
                              if (data.length != 0) {
                                setTankPhysicalSearch(data);
                                setSearchView(true);
                                setSearchFiltersView(true);
                              } else {
                                _createErrorMessage("111");
                              }
                            }
                          }
                        );
                      }}
                      style={{
                        width: "150px",
                        background: "#3498DB",
                        borderColor: "#3498DB",
                        marginLeft: "10px",
                      }}
                    >
                      Refresh
                    </Button>
                  </div>
                  <div className="p-2">
                    {tankPhysicalButtonCreate ? (
                      <Button
                        onClick={() => {
                          let navigationNextUrl = `/TankPhysical/Create`;
                          window.open(navigationNextUrl);
                        }}
                        style={{
                          width: "150px",
                          background: "#229954",
                          borderColor: "#229954",
                        }}
                      >
                        Create Tank Physical
                      </Button>
                    ) : (
                      ""
                    )}
                    {tankPhysicalActionButtons ? (
                      <div>
                        <Button
                          onClick={() => {
                            let id = selectionModel[0];
                            let navigationNextUrl = `/TankPhysical/${id}/Display`;
                            window.open(navigationNextUrl);
                          }}
                          style={{
                            width: "150px",
                            background: "#3498DB",
                            borderColor: "#3498DB",
                          }}
                        >
                          Display Tank Physical
                        </Button>

                        <Button
                          onClick={() => {
                            let id = selectionModel[0];
                            let navigationNextUrl = `/TankPhysical/${id}/Change`;
                            window.open(navigationNextUrl);
                          }}
                          style={{
                            width: "150px",
                            background: "#3498DB",
                            borderColor: "#3498DB",
                            marginLeft: "15px",
                          }}
                        >
                          Change Tank Physical
                        </Button>

                        <Button
                          onClick={() => {
                            let id = selectionModel[0];
                            _forceDeleteTankPhysical(
                              { data: { Id: id } },
                              () => {
                                setSearchView(false);
                                setSearchFiltersView(false);

                                _tankPhysicalSearch(
                                  {
                                    data: {
                                      TankId: tankPhysicalSearchParam?.tankId,
                                      Date: tankPhysicalSearchParam?.date,
                                    },
                                  },
                                  ({ data }) => {
                                    if (data != null) {
                                      if (data.length != 0) {
                                        setTankPhysicalSearch(data);
                                        setSearchView(true);
                                        setSearchFiltersView(true);
                                      } else {
                                        _createErrorMessage("111");
                                      }
                                    }
                                  }
                                );
                              }
                            );
                          }}
                          style={{
                            width: "150px",
                            background: "#DA1212",
                            borderColor: "#DA1212",
                            marginLeft: "15px",
                          }}
                        >
                          Delete Tank Physical
                        </Button>
                      </div>
                    ) : (
                      " "
                    )}
                  </div>
                </div>
              </div>
            ) : (
              " "
            )}
            <div className="col-12 mt-4">
              <MessageLog
                errorMessages={[
                  ..._errorTankPhysicalSearch,
                  ..._errorMessages,
                  ..._errorTankList,
                  ..._errorForceDeleteTankPhysical,
                ]}
                successMessages={[
                  ..._successTankPhysicalSearch,
                  ..._successTankList,
                  ..._successForceDeleteTankPhysical,
                ]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
      <TankList
        _tankList={_tankList}
        showTankList={showTankList}
        setShowTankList={setShowTankList}
        tankFieldName={tankFieldName}
        onChange={onChange}
      />
    </>
  );
}

export default TankPhysicalWorkbench;
