import React, { useContext } from "react";
import { FinancialDocContext } from "../FinancialDocMain/Contex";

export default function TopPanel() {
  const { operation, financialDocId } = useContext(FinancialDocContext);

  var financialDocInfo;
  switch (operation) {
    case "Display":
      financialDocInfo = `${operation} Inventory Document: ${financialDocId}`;
      break;
    case "Change":
      financialDocInfo = `${operation} Inventory Document: ${financialDocId}`;
      break;
    case "Create":
      financialDocInfo = `${operation} New Inventory Document:`;
      break;
    default:
      financialDocInfo = `Inventory Doc Info`;
      break;
  }
  return (
    <h2
      className="heading"
      style={{ marginLeft: 44, position: "relative", top: 8 }}
    >
      {financialDocInfo}
    </h2>
  );
}
