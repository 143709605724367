export const ValidateShipDocAfterAPI = (shipDocData, _createErrorMessage) => {
  if (shipDocData.loadStDT != null) {
    _createErrorMessage("3");
    return false;
  }
  if (shipDocData.cutoffWeight <= 0) {
    _createErrorMessage("15");
    return false;
  }

  return true;
};

export const ValidateCokeSubmit = (shipDocData, _createErrorMessage) => {
  if (!shipDocData.carrierId) {
    _createErrorMessage("5");
    return false;
  }
  if (!shipDocData.vehicleId) {
    _createErrorMessage("4");
    return false;
  }
  if (!shipDocData.trailerId) {
    _createErrorMessage("6");
    return false;
  }
  if (!shipDocData.driverId) {
    _createErrorMessage("7");
    return false;
  }

  if (!shipDocData.shiptoId) {
    _createErrorMessage("28");
    return false;
  }

  return true;
};
export const ValidateSulfurSubmit = (shipDocData, _createErrorMessage) => {
  if (!shipDocData.carrierId) {
    _createErrorMessage("5");
    return false;
  }
  if (!shipDocData.vehicleId) {
    _createErrorMessage("4");
    return false;
  }
  if (!shipDocData.trailerId) {
    _createErrorMessage("6");
    return false;
  }
  if (!shipDocData.driverId) {
    _createErrorMessage("7");
    return false;
  }
  if (!shipDocData.railcar) {
    _createErrorMessage("29");
    return false;
  }

  return true;
};
export const ValidateSAPOrderSubmit = (shipDocData, _createErrorMessage) => {
  if (!shipDocData.carrierId) {
    _createErrorMessage("5");
    return false;
  }
  if (!shipDocData.vehicleId) {
    _createErrorMessage("4");
    return false;
  }
  if (!shipDocData.trailerId) {
    _createErrorMessage("6");
    return false;
  }

  if (!shipDocData.shiptoId) {
    _createErrorMessage("103");
    return false;
  }
  if (!shipDocData.temperature || shipDocData.temperature == 0) {
    _createErrorMessage("103");
    return false;
  }

  if (shipDocData.temperature > 0 && shipDocData.temperature < 1000) { }else{
    _createErrorMessage("148");
    return false;

  }
  if (!shipDocData.netWeight || shipDocData.netWeight <= 10000) {
    _createErrorMessage("227", 10000);
    return false;
  }

  if (!shipDocData.inWeight || shipDocData.inWeight == 0) {
    _createErrorMessage("131");
    return false;
  }

  if (!shipDocData.outWeight || shipDocData.outWeight == 0) {
    _createErrorMessage("132");
    return false;
  }


 //Check for Glendale
 if(shipDocData.locationId === '4001' && shipDocData.outWeight >= 80000 ){
  _createErrorMessage("135");
  return false;
 } 

  let tankId = shipDocData?.shippingDocItemsComm[0].tankId;

  if (!tankId || tankId == "" || tankId == "select" || tankId == "SELECT") {
    _createErrorMessage("54");
    return false;
  }

  return true;
};

export const ValidateSAPOrderSubmitV1 = (shipDocData, _createErrorMessage) => {
  if (!shipDocData.carrierId) {
    _createErrorMessage("5");
    return false;
  }
  if (!shipDocData.vehicleId) {
    _createErrorMessage("4");
    return false;
  }
  if (!shipDocData.trailerId) {
    _createErrorMessage("6");
    return false;
  }

  if (!shipDocData.shiptoId) {
    _createErrorMessage("103");
    return false;
  }

  if (!shipDocData.netWeight || shipDocData.netWeight <= 10000) {
    _createErrorMessage("227", 10000);
    return false;
  }

  if (!shipDocData.inWeight || shipDocData.inWeight == 0) {
    _createErrorMessage("131");
    return false;
  }
  if (!shipDocData.outWeight || shipDocData.outWeight == 0) {
    _createErrorMessage("132");
    return false;
  }

  let tankId = shipDocData?.shippingDocItemsComm[0].tankId;

  if (!tankId || tankId == "" || tankId == "select" || tankId == "SELECT") {
    _createErrorMessage("54");
    return false;
  }

  return true;
};