import React from "react";
import GridHeader from "./GridHeader";
import GridBody from "./GridBodyEditable";
function UniversalEditableGrid({
  dropDowns,
  fields,
  headerData,
  itemData,
  operation,
  handleButtonClickGrid,
  onChange,
  ActionColumn,
  itemKey = "itemNumber",
}) {
  if (!fields) {
    return null;
  }
  let fieldsforHeader = fields({});

  return (
    <div>
      <div style={{ overflow: "auto", marginTop: 10 }}>
        <table className="table table-striped table-bordered text-nowrap custom-table">
          <GridHeader fields={fieldsforHeader} ActionColumn={ActionColumn} />
          <GridBody
            fields={fields}
            headerData={headerData}
            itemData={itemData}
            dropDowns={dropDowns}
            handleButtonClickGrid={handleButtonClickGrid}
            operation={operation}
            onChange={onChange}
            ActionColumn={ActionColumn}
            itemKey={itemKey}
          />
        </table>
      </div>
    </div>
  );
}

export default UniversalEditableGrid;
