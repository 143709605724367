import React, { useRef, useEffect, useContext } from "react";
import SignaturePad from "react-signature-canvas";
import { ShipDocContext } from "../../../ShippingDocument/ShippingDcoumentMain/Contex";
import Button from '@mui/material/Button';
import "./Signature.css";

function Index() {
  const { _setShipDocData, _shipDocData, operation } =
    useContext(ShipDocContext);

  const canvasRef = useRef();

  const generateUrl = () => {
    if (!canvasRef.current.isEmpty()) {
      let sigUrl = canvasRef.current.getCanvas().toDataURL("image/png");
      _setShipDocData((prev) => ({
        ...prev,
        signatureData: sigUrl,
        signatureDT: new Date(),
      }));
    }
  };
  useEffect(() => {
    if (canvasRef.current) {
      if (_shipDocData?.signatureData) {
        canvasRef.current.fromDataURL(_shipDocData?.signatureData);
      }
      if (operation === "Display") {
        //  Making canvas not to draw sign manually if operation is display-
        canvasRef.current.off();
      }
    }
  }, []);

  const clearCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.clear();
      _setShipDocData((prev) => ({
        ...prev,
        signatureData: "",
        signatureDT: "",
      }));
    }
  };

  return (
    <React.Fragment>
      <SignaturePad
        penColor="blue"
        canvasProps={{ className: "signaturePad" }}
        ref={canvasRef}
        onEnd={generateUrl}
      />
      {operation !== "Display" && (
        <Button
          color="primary"
          variant="contained"
          style={{ height: "fit-content" }}
          onClick={clearCanvas}
        >
          Clear
        </Button>
      )}
    </React.Fragment>
  );
}

export default Index;
