import React, { useContext, useState, useEffect } from "react";
import RenderFields from "./RenderFields";
import { Modal } from "react-bootstrap";
import { TableDataContext } from "../Contexts/TableDataContext";
export default function Create({
  name,
  mainButtonFM,
  show,
  handleClose,
  error,
  validationErrors,
}) {
  const { tableOperationN } = useContext(TableDataContext);
  function mainButtonStatus() {
    if (!mainButtonFM) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {}, [tableOperationN]);

  function setModalTitle() {
    switch (tableOperationN) {
      case "dis":
        return "Display:- " + name;
      case "cre":
        return "Create:- " + name;
      case "cha":
        return "Change:- " + name;
      case "cpy":
        return "Copy:- " + name;
      case "del":
        return "Delete:- " + name;
      default:
        return name;
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      onBackdropClick={handleClose}
      dialogClassName="dialogClassName"
      scrollable={true}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{setModalTitle()}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form>
          <RenderFields />
        </form>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        {validationErrors && typeof validationErrors === "object" && (
          <>
            {Object.keys(validationErrors).map((field, index) => (
              <div className="alert alert-danger" role="alert" key={index}>
                {validationErrors[field]}
              </div>
            ))}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleClose}
          style={{
            borderRadius: 10,
          }}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={mainButtonFM}
          disabled={mainButtonStatus()}
          data-dismiss="modal"
          style={{
            borderRadius: 10,
          }}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
