import React, { useState, useEffect, useRef } from "react";
import Pluralize from "pluralize";
import DetailsPopup from "./Components/DetailsPopup";
import DeletModal from "./Components/DeleteModal";
import List from "./Components/List";
import Log from "./Components/Log";
import backendCall from "./Services/BackendCall";
import { CommonFields } from "./ConfigFiles/CommonTableFields";
import { URLs } from "./../../CommonComponents/GlobalConfFiles/URLs";
import tableFields from "./ConfigFiles/TableFields";
import { TableAttributes } from "./ConfigFiles/TableAttributes";
import { TableDropDowns } from "./ConfigFiles/TableDropDowns";
import { tableenums } from "./ConfigFiles/TableEnums";
import { localizedFields } from "./ConfigFiles/Localization";
import { TableDataContext } from "./Contexts/TableDataContext";
import * as _ from "underscore.string";
import "../../Scss/Settings.scss";

export default function TableMaintenanceGenerator({ componentName, typeType }) {
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState();
  const [success, setSuccess] = useState([]);
  const [showDetMod, setShowDetMod] = useState(false);
  const [showDelMod, setShowDelMod] = useState(false);
  const [tableFieldsN, setTableFieldsN] = useState([]);
  const [tableRecordsN, setTableRecordsN] = useState([]);
  const [tableOperationN, setTableOperatioN] = useState("");
  const [tableDropDowns, setTableDropDowns] = useState({});
  const [tableRecordN, setTableRecordN] = useState({});
  const handleCloseDetMod = () => setShowDetMod(false);
  const handleCloseDelMod = () => setShowDelMod(false);
  const getAllRecords = useRef(false);
  var allTableFields;
  var modalFnPointer;
  var totalMessage;
  var baseURL;
  var baseURLdd;

  const baseURLConf = `${URLs.Host}${URLs.Config}`;
  const baseURLSys = `${URLs.Host}${URLs.System}`;
  const baseURLUI = `${URLs.Host}${URLs.UI_Config}`;
  const baseURLMasterData = `${URLs.Host}${URLs.MasterData}`;

  switch (typeType) {
    case "Config":
      baseURL = baseURLConf;
      break;
    case "System":
      baseURL = baseURLSys;
      break;
    case "UI_Config":
      baseURL = baseURLUI;
      break;
    case "Master":
      baseURL = baseURLMasterData;
      break;
    default:
      baseURL = baseURLConf;
  }

  useEffect(() => {
    try {
      setTableRecordsN([]);
      setErrors([]);
      setSuccess([]);
      componentInitialValidation();
      //Get list of enum drop-downs
      var enumsArray = [];
      enumsArray = tableenums[componentName];
      //Get list of table drop-downs
      var dropDownTableArray = [];
      dropDownTableArray = TableDropDowns[componentName];

      setTableFields(
        tableFields[componentName],
        CommonFields,
        TableAttributes[componentName]
      );

      prepTableFields();
      setTableFieldsN(allTableFields);
      getAllComponentEntries();
      getDropDowns(dropDownTableArray);
      getEnums(enumsArray);
    } catch (e) {
      setErrors((prev) => {
        if (e.message != null) {
          return [...prev, e.message];
        } else {
          return [...prev, e];
        }
      });
    }
  }, [componentName]);

  useEffect(() => {
    if (getAllRecords.current) {
      getAllComponentEntries();
      getAllRecords.current = !getAllRecords.current;
    }
  }, [getAllRecords.current]);

  function componentInitialValidation() {
    // check base endpoint URL, there 2 possible options
    if (baseURL == null || baseURL == "") {
      var Message = `End-Point URL is Not Found for:  ${componentName}`;
    }

    setErrors((current) => {
      return [...current, Message];
    });
  }

  function setTableFields(tableFields = [], commonFields, tableAttributes) {
    //check if table fields have been configured in file
    if (tableFields == null) {
      setErrors((prev) => {
        return [
          ...prev,
          `Table Fieds are Not Configured for:  ${componentName}`,
        ];
      });
    }
    //Check if we need to include the common fields to the table fields
    var includeCommonFields;
    if (tableAttributes == null) {
      //if there are no table attributes or the attribute is null,  by default in include common fields
      includeCommonFields = "true";
    } else if (tableAttributes.IncludeCommonFields == null) {
      includeCommonFields = "true";
    } else if (tableAttributes.IncludeCommonFields == "true") {
      includeCommonFields = "true";
    } else if (tableAttributes.IncludeCommonFields == "false") {
      includeCommonFields = "false";
    }

    if (includeCommonFields == "false") {
      //combine table fields with common fields for all tables
      allTableFields = [...tableFields];
    } else {
      allTableFields = [...tableFields, ...commonFields];
    }
  }
  function prepTableFields() {
    allTableFields.map((arrayItem) => {
      var lable = localizedFields.En[arrayItem.id];
      if (lable) {
        arrayItem.label = lable;
      }
      if (arrayItem.ddid) {
        arrayItem.dropdownkey = arrayItem.ddid;
      } else {
        arrayItem.dropdownkey = arrayItem.id;
      }

      return arrayItem;
    });
  }
  function checkCallResult(response, URL, resource) {
    if (response == null) {
      throw "No Response From Server using URL:-" + URL;
    }

    if (!response.status) {
      totalMessage =
        response.message +
        " ," +
        `Check if Backend is Available at URL:-${URL}`;
      throw totalMessage;
    }
    switch (response.status) {
      case 404:
        totalMessage =
          `Table:-${resource} Not Found` + "," + response.statusText;
        throw (totalMessage = totalMessage);
      case 401:
        totalMessage = "Not Authorized Access" + "," + response.statusText;
        throw (totalMessage = totalMessage);
    }
  }
  function handleBackendMessages(messages) {
    //the backend returns an array of messages, this function exracts the
    //messages and updates the local status for messages
    var backendErrors = [];
    var backendSuccess = [];

    if (messages != null) {
      messages.forEach((current) => {
        switch (current.messageType) {
          case "E":
            backendErrors.push(current.messageText);
            break;
          case "S":
            backendSuccess.push(current.messageText);
            break;
          default:
        }
      });
    }

    if (backendErrors.length != null) {
      setErrors((current) => {
        return [...current, ...backendErrors];
      });
    }

    if (backendSuccess != null) {
      setSuccess((current) => {
        return [...current, ...backendSuccess];
      });
    }
  }

  async function getAllComponentEntries() {
    var pluralComponentName = Pluralize(componentName);
    var endPoint = "GetAll" + pluralComponentName;

    var fullURL = baseURL + endPoint;
    var operationType = "All";
    try {
      var response = await backendCall(fullURL, operationType);
      checkCallResult(response, fullURL, componentName);
      var result = await response.json();

      handleBackendMessages(result.messages);

      switch (response.status) {
        case 200:
          setSuccess((prev) => {
            return [
              ...prev,
              `Contacted Back-end Sucessfully for All Entries:  ${componentName}`,
            ];
          });
          setTableRecordsN(result.data);
          break;

        default:
          //Adding Front-end Message when backend fails
          setErrors((current) => {
            return [
              ...current,
              `Backend Error While Trying to Read All Component Entries ${pluralComponentName}`,
            ];
          });

          totalMessage = response.statusText + "," + result.Message;
          setErrors((current) => {
            return [...current, totalMessage];
          });
      }
    } catch (e) {
      setErrors((prev) => {
        if (e.message != null) {
          return [...prev, e.message];
        } else {
          return [...prev, e];
        }
      });
    }
  }

  function initialize(e) {
    let op = e.target.dataset.op;
    setTableOperatioN(op);
    setTableRecordN({});
    setShowDetMod(true);
  }

  async function getDropDowns(dd) {

    if (dd != null) {
      await dd.forEach(async (table) => {
        var tableAttributes;
        //Try with table name from drop-down 1st
        tableAttributes = TableAttributes[table];
        if (!tableAttributes) {
          //Try by capatlizing 1st letter of table name

          var capTable = _.capitalize(table);
          tableAttributes = TableAttributes[capTable];
        }

        if (tableAttributes != null) {
          switch (tableAttributes.Type) {
            case "Config":
              baseURLdd = baseURLConf;
              break;
            case "System":
              baseURLdd = baseURLSys;
              break;
            case "UI_Config":
              baseURLdd = baseURLUI;
              break;
            default:
              baseURLdd = baseURL;
              break;
          }
        } else {
          baseURLdd = baseURL;
        }
        var pluralComponentName = Pluralize(table);
        var endPoint = "GetAll" + pluralComponentName;
        var fullURL = baseURLdd + endPoint;
        var dropdownkey = table + "Id";
        var operationType = "All";
        try {
          var response = await backendCall(fullURL, operationType);

          checkCallResult(response, fullURL, table);
          var result = await response.json();
          handleBackendMessages(result.messages);
          switch (response.status) {
            case 200:
              setSuccess((prev) => {
                return [
                  ...prev,
                  "Contacted Back-end Sucessfully for All Drop-downs:  " +
                    table,
                ];
              });

              let ddcomponent = { [dropdownkey]: result.data };
              setTableDropDowns((current) => {
                return { ...current, ...ddcomponent };
              });
              break;

            default:
              throw response.statusText;
          }
        } catch (e) {
          setErrors((prev) => {
            if (e.message != null) {
              return [...prev, e.message];
            } else {
              return [...prev, e];
            }
          });
        }
      });
    }
  }

  async function getEnums(enums) {
    if (enums != null) {
      await enums.forEach(async (enumId) => {
        var endPoint = "GetEnumOptions";
        var fullURL = baseURLSys + endPoint;
        var dropdownkey = enumId;
        var operationType = "GET";
        try {
          var response = await backendCall(fullURL, operationType, enumId);
          checkCallResult(response, fullURL, enumId);
          var result = await response.json();
          handleBackendMessages(result.messages);
          switch (response.status) {
            case 200:
              setSuccess((prev) => {
                return [
                  ...prev,
                  "Contacted Back-end Sucessfully for Enum Values of:  " +
                    enumId,
                ];
              });

              let ddcomponent = { [dropdownkey]: result.data };
              setTableDropDowns((current) => {
                return { ...current, ...ddcomponent };
              });

              break;

            default:
              throw response.statusText;
          }
        } catch (e) {
          setErrors((prev) => {
            if (e.message != null) {
              return [...prev, e.message, "Enum Values Error"];
            } else {
              return [...prev, e];
            }
          });
        }
      });
    }
  }

  async function readRec(id) {
    try {
      let rawComponentName =
        componentName === "Component"
          ? "ComponentDetail"
          : componentName === "View"
          ? "ViewDetail"
          : componentName;
      // var endPoint = "Get" + componentName;
      var endPoint = "Get" + rawComponentName;
      var fullURL = baseURL + endPoint;
      var operationType = "GET";
      var response = await backendCall(fullURL, operationType, id);
      checkCallResult(response, fullURL, componentName);
      var result = await response.json();
      handleBackendMessages(result.messages);
      switch (response.status) {
        case 200:
          //if record read sucessfully,
          setSuccess((current) => {
            return [
              ...current,
              "Contacted Back-end Sucessfully for a Single Record",
            ];
          });
          setTableRecordN(result.data);
          break;
        default:
          setErrors((current) => {
            return [...current, `Backend Error While Reading Record ID ${id}`];
          });

          totalMessage = response.statusText + "," + result.Message;
          setErrors((current) => {
            return [...current, totalMessage];
          });
      }
    } catch (e) {
      setErrors((prev) => {
        if (e.message != null) {
          return [...prev, e.message];
        } else {
          return [...prev, e];
        }
      });
    }
  }
  async function copyRecord(id, newIdformate) {
    try {
      let rawComponentName =
        componentName === "Component"
          ? "ComponentDetail"
          : componentName === "View"
          ? "ViewDetail"
          : componentName;
      // var endPoint = "Get" + componentName;
      var endPoint = "Get" + rawComponentName;
      var fullURL = baseURL + endPoint;
      var operationType = "GET";
      var response = await backendCall(fullURL, operationType, id);
      checkCallResult(response, fullURL, componentName);
      var result = await response.json();
      handleBackendMessages(result.messages);
      switch (response.status) {
        case 200:
          //if record read sucessfully,
          setSuccess((current) => {
            return [
              ...current,
              "Contacted Back-end Sucessfully for a Single Record",
            ];
          });
          setTableRecordN({ ...result.data, ...newIdformate });
          break;
        default:
          setErrors((current) => {
            return [...current, `Backend Error While Reading Record ID ${id}`];
          });

          totalMessage = response.statusText + "," + result.Message;
          setErrors((current) => {
            return [...current, totalMessage];
          });
      }
    } catch (e) {
      setErrors((prev) => {
        if (e.message != null) {
          return [...prev, e.message];
        } else {
          return [...prev, e];
        }
      });
    }
  }
  async function createRec() {
    try {
      setErrors([]);
      setSuccess([]);
      setError(null);
      var endPoint = "Create" + componentName;
      var fullURL = baseURL + endPoint;
      var operationType = "Create";
      var response = await backendCall(fullURL, operationType, tableRecordN);
      var result = await response.json();
      checkCallResult(response, fullURL, componentName);
      handleBackendMessages(result.messages);
      switch (response.status) {
        case 200:
          setTableRecordsN((current) => {
            return [...current, result.data];
          });
          handleCloseDetMod("false");
          setSuccess((current) => {
            return [
              ...current,
              `Contacted Back-end Sucessfully for Record Creation: ${tableRecordN.id}`,
            ];
          });
          break;
        default:
          setErrors((current) => {
            return [
              ...current,
              `Backend Error While Creating Record: ${tableRecordN.id}`,
            ];
          });

          totalMessage = response.statusText + "," + result.Message;
          setErrors((current) => {
            return [...current, totalMessage];
          });
      }
    } catch (e) {
      setErrors((prev) => {
        if (e.message != null) {
          return [...prev, e.message];
        } else {
          return [...prev, e];
        }
      });
    }
  }

  async function delRec(e) {
    try {
      let id = e.target.dataset.id;

      let tableKey = "";

      //Lookup the key definition of this table, KK
      tableKey = tableFieldsN.find(({ id }) => id === "id");

      //if the id is numeric then formate it to numeric
      switch (tableKey.type) {
        case "text":
          //if the ID is text then formate into text for creation/copy purposes
          break;
        case "number":
          //if the ID is number then formate into number for creation/copy purposes

          id = parseInt(id);
        default:
          id = parseInt(id);
      }

      var endPoint = "Delete" + componentName;
      var fullURL = baseURL + endPoint;
      var operationType = "Delete";
      var response = await backendCall(fullURL, operationType, id);
      checkCallResult(response, fullURL, componentName);
      var result = await response.json();
      handleBackendMessages(result.messages);
      switch (response.status) {
        case 200:
          let records = tableRecordsN;
          const deleteIndex = tableRecordsN.findIndex((x) => x.id == id);
          if (deleteIndex > -1) {
            records.splice(deleteIndex, 1);
          }

          setTableRecordsN(records);
          setShowDelMod(false);
          setSuccess((current) => {
            return [
              ...current,
              `Contacted Back-end Sucessfully for Record Deletion ${id}`,
            ];
          });
          break;
        default:
          //in case of failure close modal and transfer error messages
          setShowDelMod(false);

          setErrors((current) => {
            return [...current, `Backend Error While Deleting Record ID ${id}`];
          });

          totalMessage = response.statusText + "," + result.Message;
          setErrors((current) => {
            return [...current, totalMessage];
          });
      }
    } catch (error) {
      if (error.message != null) {
        setErrors((current) => {
          return [...current, error.message];
        });
      } else {
        setErrors((current) => {
          return [...current, error];
        });
      }
    }
  }

  async function updateRec() {
    try {
      setErrors([]);
      setSuccess([]);
      setError(null);
      var endPoint = "Update" + componentName;
      var fullURL = baseURL + endPoint;
      var operationType = "Update";
      var response = await backendCall(fullURL, operationType, tableRecordN);
      checkCallResult(response, fullURL, componentName);
      var result = await response.json();

      handleBackendMessages(result.messages);
      switch (response.status) {
        case 200:
          let records = tableRecordsN;

          let modifiedRecords = records.map((current) => {
            if (current.id == result.data.id) {
              return result.data;
            } else {
              return current;
            }
          });

          setTableRecordsN(modifiedRecords);
          setShowDetMod(false);
          setSuccess((current) => {
            return [
              ...current,
              `Contacted Back-end Sucessfully for Record Update ${tableRecordN.id}`,
            ];
          });

          break;

        default:
          setErrors((current) => {
            return [
              ...current,
              `Backend Error While Updating Record ID ${tableRecordN.id}`,
            ];
          });

          totalMessage = response.statusText + "," + result.Message;
          setErrors((current) => {
            return [...current, totalMessage];
          });
      }
    } catch (error) {
      setErrors((current) => {
        return [...current, error];
      });
    }

    getAllRecords.current = true;
  }
  async function handleButtonClickList(e) {
    let id = e.target.dataset.id;
    let op = e.target.dataset.op;

    let tableKey = "";
    let newIdformate = "";
    //Lookup the key definition of this table
    tableKey = tableFieldsN.find(({ id }) => id === "id");

    //if the id is numeric then formate it to numeric
    switch (tableKey.type) {
      case "text":
        //if the ID is text then formate into text for creation/copy purposes
        newIdformate = { id: "" };

        break;
      case "number":
        //if the ID is number then formate into number for creation/copy purposes
        newIdformate = { id: 0 };
        id = parseInt(id);

      default:
        id = parseInt(id);
        newIdformate = { id: 0 };
    }

    setTableOperatioN(op);
    // eslint-disable-next-line default-case
    switch (op) {
      case "cre":
        break;
      case "cha":
        await readRec(id);
        setShowDetMod(true);
        break;
      case "del":
        //1-Set all data for deleted record, pass it to delete modal
        tableRecordsN.forEach((rec) => {
          if (rec.id === id) {
            setTableRecordN(rec);
          }
        });
        setShowDelMod(true);
        break;
      case "cpy":
        await copyRecord(id, newIdformate);
        setShowDetMod(true);
        break;
      case "dis":
        await readRec(id);
        setShowDetMod(true);
        break;
    }
  }

  function clearLog(e) {
    let type = e.target.dataset.type;
    // eslint-disable-next-line default-case
    switch (type) {
      case "success":
        setSuccess([]);
        break;
      case "error":
        setErrors([]);
        break;
      case "log":
        break;
    }
  }

  //This provides the function for modal
  // eslint-disable-next-line default-case

  switch (tableOperationN) {
    case "cre":
      modalFnPointer = createRec;
      break;
    case "dis":
      break;
    case "cha":
      modalFnPointer = updateRec;
      break;
    case "cpy":
      modalFnPointer = createRec;
      break;
    case "del":
      modalFnPointer = delRec;
      break;
  }

  const contextState = {
    tableFieldsN,
    tableRecordsN,
    tableOperationN,
    tableRecordN,
    setTableRecordN,
    tableDropDowns,
  };

  return (
    <TableDataContext.Provider value={contextState}>
      <div className="TableMaintenanceContainer">
        <div className="TableMaintenanceHeader">
          <h3>Table Name: {componentName}</h3>
          <h3>Category: {typeType}</h3>
        </div>

        <div className="TableMaintenanceMainButtons">
          <button
            type="button"
            className="Button ButtonCreate"
            data-op="cre"
            onClick={initialize}
          >
            Create
          </button>
        </div>
        <div className="TableMaintenanceMainGrid">
          <List
            handleButtonClickList={handleButtonClickList}
            listType={"complex"}
          />
        </div>
      </div>
      <div className="TableMaintenanceFooter">
        <h2>Number of Entries: {tableRecordsN.length}</h2>
      </div>

      {showDetMod === true ? (
        <DetailsPopup
          name={componentName}
          mainButtonFM={modalFnPointer}
          show={showDetMod}
          handleClose={() => {
            setError(null);
            handleCloseDetMod();
          }}
          error={error}
        />
      ) : null}

      {showDelMod === true ? (
        <DeletModal
          mainButtonFM={modalFnPointer}
          show={showDelMod}
          name={componentName}
          handleClose={handleCloseDelMod}
        />
      ) : null}

      <div className="TableMaintenanceLog">
        <Log errors={errors} success={success} clearButtonFM={clearLog} />
      </div>
    </TableDataContext.Provider>
  );
}
