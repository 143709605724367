import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { DataGrid } from "@mui/x-data-grid";

export default function DropDownListMUI({
  setId,
  dataList,
  showListModal,
  setShowModal,
  columns,
  title,
}) {
  function handleOnSelect(selectedId) {
    setId(selectedId[0]);
    setShowModal(false);
  }

  return (
    <Modal
      scrollable="true"
      show={showListModal}
      size="xl"
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w modal-dialog-scrollable"
      aria-labelledby="example-custom-modal-styling-title"
      className="partnerListModal"
    >
      <Modal.Header
        style={{
          background: "#d4e1f5",
        }}
      >
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{
            fontWeight: 700,
          }}
        >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {dataList?.length && (
          <div style={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={dataList || []}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[14]}
              onSelectionModelChange={(selectedId) =>
                handleOnSelect(selectedId)
              }
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="offset-11 col-1">
          <Button
            variant="danger"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
