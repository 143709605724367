import React, { useContext, useState } from "react";
import { mainDataArray ,  dateDataArray , amountDataArray} from "./Fields";
import { FinancialDocContext } from "../Contex";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";

export default function MainTab() {
  const {
    onChange,
    operation,
    dropDowns,
    _financialDocData,
  } = useContext(FinancialDocContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _financialDocData, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _financialDocData,
    dropDowns,
  };

  const mainDataFieldsToRender      = mainDataArray(fieldsState);
  const dateDataArrayFieldsToRender = dateDataArray(fieldsState);
  const amountDataArrayToRender     = amountDataArray(fieldsState);


  return (
   <>
      <div className="row">
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Main Data</h2>
            </div>
            <div className="cardBody">
              {mainDataFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Date</h2>
            </div>
            <div className="cardBody">
              {dateDataArrayFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Amount</h2>
            </div>
            <div className="cardBody">
              {amountDataArrayToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
      </div>
      </div>
  </>
   
  );
}
