export const mainDataArray = ({ dropDowns, dataSource, location }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Tank Physical ID",
    disabled: true,
    dispplayOnly: true,
  },
  {
    id: "physicalDocTypeId",
    name: "physicalDocTypeId",
    tag: "select",
    options: dropDowns?.physicalDocTypes || [],
    type: "text",
    label: "Physical Doc Type",
  },
  {
    id: "date",
    name: "date",
    label: "Date",
    type: "datetime",
  },
  {
    id: "tankId",
    name: "tankId",
    label: "Tank",
    type: "text",
    tag: "enhancedInput",
    options: tankDropdown(dropDowns, dataSource, location),
  },
  {
    id: "locationId",
    name: "locationId",
    label: "Location",
    type: "text",
    tag: "select",
    options: dropDowns?.locations || [],
  },
];
export const invArray = ({ dropDowns }) => [
  {
    id: "physicalInventoryQty",
    name: "physicalInventoryQty",
    label: "Physical Inventory Quantity",
    type: "number",
  },
  {
    id: "physicalInventoryUnitId",
    name: "physicalInventoryUnitId",
    label: "Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    type: "text",
    tag: "enhancedInput",
  },
];
export const readingsArray = ({ dropDowns }) => [
  {
    id: "temperature",
    name: "temperature",
    label: "Temperature",
    type: "number",
  },
  {
    id: "temperatureUnitId",
    name: "temperatureUnitId",
    label: "Temperature Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.tempUnits || [],
  },
  {
    id: "density",
    name: "density",
    label: "Density",
    type: "number",
  },
  {
    id: "densityUnitId",
    name: "densityUnitId",
    label: "Density Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.densityUnits || [],
  },
  {
    id: "gravity",
    name: "gravity",
    label: "Gravity",
    type: "number",
  },
  {
    id: "specificGravity",
    name: "specificGravity",
    label: "Specific Gravity",
    type: "number",
  },
];

const tankDropdown = (dropDowns, dataSource, location) => {
  //filtering tanks based on item location if available, then header location
  if (location) {
    let tanks = dropDowns?.tanks?.filter((tank) => {
      return tank?.filter1 === dataSource.locationId;
    });
    return tanks;
  }
};
