import React from "react";
import Modal from "react-bootstrap/Modal";
import PartnerAssigmentMain from "./PartnerAssignment/Index";

export default function PartnerAssigmentModal({
  showNewPartnerAssignmentModal,
  setShowNewPartnerAssignmentModal,
  addPartnertoOrder,
}) {
  return (
    <div>
      <Modal
        show={showNewPartnerAssignmentModal}
        backdrop="static"
        onHide={() => setShowNewPartnerAssignmentModal(false)}
        dialogClassName="modal-90w modal-dialog-scrollable"
        aria-labelledby="example-custom-modal-styling-title"
        className="partnerListModal"
      >
        <Modal.Header
          style={{
            background: "#d4e1f5",
          }}
        >
          <Modal.Title id="example-custom-modal-styling-title">
            Add New Partner Assigment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PartnerAssigmentMain
            setShowNewPartnerAssignmentModal={setShowNewPartnerAssignmentModal}
            addPartnertoOrder={addPartnertoOrder}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
