import React from "react";
import Button from '@mui/material/Paper';
import { ValidateMaterialAdminFuncInput } from "./ValidateMaterialAdminFuncInput";
const btnsArray = [{ operation: "Delete" }, { operation: "Cancel" }];

export default function OverviewButtons({
  material,
  navigate,
  _materialExist,
  _createErrorMessage,
  _deleteMaterial,
}) {
  const onClick = (operation) => {
    if (operation === "Cancel") {
      let navigationNextUrl = `/MaterialOverview`;
      navigate(navigationNextUrl);
      return;
    }

    let validationMaterialResult = ValidateMaterialAdminFuncInput(
      material,
      _createErrorMessage
    );

    if (!validationMaterialResult) {
      return;
    }

    _materialExist({ data: { Id: material } }, ({ data: isExist }) => {
      if (!isExist) {
        _createErrorMessage("36");
        return;
      }
      _deleteMaterial({ data: { Id: material } });
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ operation, noValidation, navPath }, i) => (
        <div style={{ width: "48%", marginBottom: 15 }}>
          <Button
            className="btn btn-primary w-100"
            key={operation}
            onClick={() => onClick(operation, noValidation, navPath)}
          >
            {operation}
          </Button>
        </div>
      ))}
    </div>
  );
}
