export const mainArray = () => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Order Num",
    disabled: true,
  },
  {
    id: "ref1",
    name: "ref1",
    type: "text",
    label: "Lease",
    disabled: true,
  },

];

