
export const ValidateAssigment = (localData, _createErrorMessage) => {

  if (typeof localData.partnerRoleId === "undefined") {
    _createErrorMessage("31");
    return false;
  }

  if (typeof localData.partnerId === "undefined") {
    _createErrorMessage("31");
    return false;
  }

  if (localData.partnerRoleId == null || localData.partnerRoleId == "select") {
    _createErrorMessage("31");
    return false;
  }
  if (localData.partnerId == "") {
    _createErrorMessage("31");
    return false;
  }
  
  return true;
};
