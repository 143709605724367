export const checkinDataArray = () => [
  {
    id: "checkedStatus",
    name: "checkedStatus",
    type: "text",
    label: "Checked Status",
    length: 1,
    key: true,
  },
  {
    id: "checkedStDT",
    name: "checkedStDT",
    type: "datetime",
    label: "Checkin Start DT",
  },
  {
    id: "checkedEnDT",
    name: "checkedEnDT",
    type: "datetime",
    label: "Checkin End DT",
  },
];

export const inWeightDataArray = () => [
  {
    id: "inWeightStatus",
    name: "inWeightStatus",
    type: "text",
    label: "In Weight Status",
    length: 1,
    key: true,
  },
  {
    id: "inWeightStDT",
    name: "inWeightStDT",
    type: "datetime",
    label: "InWeight Start DT",
  },
  {
    id: "inWeightEnDT",
    name: "inWeightEnDT",
    type: "datetime",
    label: "InWeight End DT",
  },
];

export const outWeightDataArray = () => [
  {
    id: "outWeightStatus",
    name: "outWeightStatus",
    type: "text",
    label: "Out Weight Status",
    length: 1,
    key: true,
  },
  {
    id: "outWeightStDT",
    name: "outWeightStDT",
    type: "datetime",
    label: "Out Weight St DT",
  },
  {
    id: "outWeightEnDT",
    name: "outWeightEnDT",
    type: "datetime",
    label: "Out Weight En DT",
  },
];

export const loadStatusDataArray = () => [
  {
    id: "loadStatus",
    name: "loadStatus",
    type: "text",
    label: "Load Status",
    length: 1,
    key: true,
  },
  {
    id: "loadStDT",
    name: "loadStDT",
    type: "datetime",
    label: "Load St DT",
  },
  {
    id: "loadEnDT",
    name: "loadEnDT",
    type: "datetime",
    label: "Load En DT",
  },
];

export const signatureStatusDataArray = () => [
  {
    id: "signatureStatus",
    name: "signatureStatus",
    type: "text",
    label: "Signature Status",
    length: 1,
    key: true,
  },
  {
    id: "signatureDT",
    name: "signatureDT",
    type: "datetime",
    label: "Signature DT",
  },
];

export const extSystemSyncStatusDataArray = () => [
  {
    id: "extSystemSyncStatus",
    name: "extSystemSyncStatus",
    type: "text",
    label: "Ext System Sync Status",
    length: 1,
    key: true,
  },
  {
    id: "extSystemSyncStDT",
    name: "extSystemSyncStDT",
    type: "datetime",
    label: "Ext SystemS ync St DT",
  },
  {
    id: "extSystemSyncEnDT",
    name: "extSystemSyncEnDT",
    type: "datetime",
    label: "Ext System Sync En DT",
  },
];
export const otherStatusDataArray = () => [
  {
    id: "loadUnloadinProcess",
    name: "loadUnloadinProcess",
    tag: "checkbox",
    type: "checkbox",
    label: "Loading Unloading Status",
  },
  {
    id: "terminalActivityinProcess",
    name: "terminalActivityinProcess",
    tag: "checkbox",
    type: "checkbox",
    label: "Terminal Activity in Process",
  },
  {
    id: "signatureReady",
    name: "signatureReady",
    tag: "checkbox",
    type: "checkbox",
    label: "Signature Ready",
  },
];
