/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";
import { useMessage } from "../../../hooks/UseMessage";
import OverviewInput from "../../../CommonComponents/InitialInput/OverviewInput";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import ListModal from "../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../../CommonComponents/CircleButton";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import OverviewButtons from "./Components/OverviewButtons";
import cardlogo from "../../../Assets/cardLogo.svg"

export default function AdminFunctionOverview(props) {

  let navigate = useNavigate();
  const [logisticStationParam, setLogisticStationParam] = useState();
  const [showLogisticStationsList, setShowLogisticStationsList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  
  const [
    _deleteLogisticStation,
    ,
    _setDeleteLogisticStationData,
    _errorDeleteLogisticStationData,
    _setErrorDeleteLogisticStationData,
    _successDeleteLogisticStationData,
    _setSuccessDeleteLogisticStationData,
    _loadingDeleteLogisticStationData,
  ] = useFetch(GetFullURL("DeleteLogisticStation"), { method: "POST" });

  const [_getLogisticStationsList, _logisticStationsList, , , , , , _loadingLogisticStationsList] =
    useFetch(GetFullURL("GetAllLogisticStationsForDelete"), { method: "POST" });

  const [_logisticStationExist, , , , , , , _loadingLogisticStationExist] = useFetch(GetFullURL("LogisticStationExist"),{ method: "POST" });

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessDeleteLogisticStationData([]);
      
        break;
      case "error":
        _setErorMessages([]);
        _setErrorDeleteLogisticStationData([]);
        
        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading =  _loadingLogisticStationsList  || _loadingLogisticStationExist ;

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../../Assets/icon/Admin.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  LogisticStation Admin Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setLogisticStationParam}
                    document={logisticStationParam}
                    logisticStationRadius={true}
                    placeholder={`Enter or Choose Id`}
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of LogisticStations`}
                    clickHandler={() => {
                      _getLogisticStationsList({},() => {setShowLogisticStationsList(true);});                  
                    }}
                  />
                  <OverviewButtons
                    logisticStation={logisticStationParam}
                    navigate={navigate}
                    _logisticStationExist={_logisticStationExist}
                    _createErrorMessage={_createErrorMessage}
                    _deleteLogisticStation={_deleteLogisticStation}
              
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages, ..._errorDeleteLogisticStationData ]}
              successMessages={[..._successDeleteLogisticStationData]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
             <div className="cardAlert">
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setLogisticStationParam}
              dataList={_logisticStationsList}
              showListModal={showLogisticStationsList}
              setShowModal={setShowLogisticStationsList}
              title="List Of LogisticStations"
              columns={[
                { field: "id", headerName: "LogisticStation ID", width: 100 },
                { field: "logisticStationTypeId", headerName: "LogisticStation Type", width: 250 },
                { field: "name", headerName: "Name", width: 350 },
                ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

