/** @format */

import React, { useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput";
import OverviewButtons from "./Components/OverviewButtons";
import { GetFullURL } from "./../../CommonComponents/GlobalConfFiles/URLs";
import { useNavigate } from "react-router-dom";
import CircleButton from "../../CommonComponents/CircleButton";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import cardlogo from "../../Assets/cardLogo.svg";
import { useMessage } from "../../hooks/UseMessage";

export default function Index(props) {

  let navigate = useNavigate();

  const [tank, setTank] = useState();
  const [showTankList, setShowTankList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [_getTankList, _tankList, , , , , , _loadingTankList] = useFetch(GetFullURL("TankList"),{ method: "POST" });

  const [_getTankExists, , , , , , , _loadingtTankExist] = useFetch(GetFullURL("TankExists"),{ method: "POST" });

  const operationLoading = _loadingTankList || _loadingtTankExist;

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }

  return (
    <section className="heroCard heroPartner">
    {operationLoading && <LoadingScreen />}
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="cardHeader">
              <h2>
                <span>
                <img src={require("../../Assets/icon/tank.png")} className="img-fluid cardLogo" alt="" />
                </span>
                Tank Overview
              </h2>
            </div>
            <div className="cardBody">
              <div className="cardInput">
                <OverviewInput
                  setDocument={setTank}
                  document={tank}
                  placeholder="Tank id here..."
                />
              </div>
              <div className="cardButton">
                <CircleButton
               buttonText={`List of Tank`}
               clickHandler={() => {
                 _getTankList({}, () => {
                   setShowTankList(true);
                 });
               }}
                />
                <OverviewButtons
                 tank={tank}
                 navigate={navigate}
                 _getTankExists={_getTankExists}
                 _createErrorMessage={_createErrorMessage}
                />
              </div>
            </div>
          </div>
          <MessageLog
            errorMessages={[..._errorMessages]}
            successMessages={[]}
            warningMessages={[]}
            clearMessages={clearMessages}
          ></MessageLog>
          <div className="cardAlert">
            <ListModal
              setId={setTank}
              dataList={_tankList}
              showListModal={showTankList}
              setShowModal={setShowTankList}
              title="List Of Tanks"
              columns={[
                { field: "id", headerName: "Tank ID", width: 100 },
                { field: "code", headerName: "Code", width: 250 },
                { field: "universalDescriptor", headerName: "Name", width: 350 },
                {field: "tankFarmId",headerName: "Tank Farm",width: 150,},
                {field: "tankGroupId",headerName: "Tank Group",width: 150,},
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

