
export const ValidatePartnerInput = (partner, _createErrorMessage) => {

  if (!partner) {
    _createErrorMessage("33");
    return false;
  }

  return true;
};
