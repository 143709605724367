import React from "react";
import Card from "../../../../Home/Components/Card";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AccountIcon } from "../../../../Assets/NewIconsForCards/AccountIcon.svg";
import { ReactComponent as ReactAdminFunctionCard } from "../../../../Assets/NewIconsForCards/UserSettingsIcon.svg";

const Arr = [
  {
    title: "Account General",
    text: "",
    Icon: AccountIcon,
    link: "/AccountGeneral",
  },
  {
    title: "Admin Function",
    text: "",
    Icon: ReactAdminFunctionCard,
    link: "/AccountAdmin",
  },
];

export default function AccountOverview() {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {Arr.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
