import React, { useContext } from "react";
import { TankPhysicalContext } from "../TankPhysicalMain/context";

export default function TopPanel() {
  const { operation, tankPhysicalId } = useContext(TankPhysicalContext);

  var tankPhysicalInfo;
  switch (operation) {
    case "Display":
      tankPhysicalInfo = `${operation} Tank Physical: ${tankPhysicalId}`;
      break;
    case "Change":
      tankPhysicalInfo = `${operation} Tank Physical ${tankPhysicalId}`;
      break;
    case "Create":
      tankPhysicalInfo = `${operation} New Tank Physical`;
      break;
    default:
      tankPhysicalInfo = `Tank Physical Info`;
      break;
  }
  return (
    <h2 className="heading" style={{ position: "relative", top: 8 }}>
      {tankPhysicalInfo}
    </h2>
  );
}
