/** @format */

import React, { useState } from "react";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import { mainArray } from "./Fields";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { useNavigate } from "react-router-dom";
import { FieldsReducer } from "../../CommonComponents/Reducer/Index";
import { useMessage } from "../../hooks/UseMessage";
import { DataGrid } from "@mui/x-data-grid";

export default function AppLog(props) {
  let navigate = useNavigate();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [logData, setLogData] = useState([]);
  const [logParam, setLogParam] = useState();
  const [logView, setLogView] = useState(false);
  const [validateClearLog, setValidateClearLog] = useState(false);

  const [
    _requestLog,
    _logData,
    ,
    _errorLog,
    _setErrorLog,
    _successLog,
    _setSuccessLog,
    _loadingLog,
  ] = useFetch(GetFullURL("AppLog"), { method: "POST" });

  const [
    _clearLog,
    ,
    ,
    _errorClearLog,
    _setErrorClearLog,
    _successClearLog,
    _setSuccessClearLog,
    _loadingClearLog,
  ] = useFetch(GetFullURL("ClearAppLog"), { method: "POST" });

  const operationLoading = _loadingLog;

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setLogParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessLog([]);
        _setSuccessClearLog([]);
        break;
      case "error":
        _setErorMessages([]);
        _setErrorClearLog([]);
        _setErrorLog([]);
        break;
      default:
        break;
    }
  }

  const fieldsState = {
    dropDowns: [],
  };

  const mainFieldsToRender = mainArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    return newFieldObject;
  };

  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}

          <div className="row">
            <div className="col-12">
              {logView ? (
                ""
              ) : (
                <div className="card mb-4">
                  <div className="cardHeader">
                    <h2>All Messages</h2>
                  </div>
                  <div className="cardBody">
                    {mainFieldsToRender.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field, logParam)}
                        key={index}
                      />
                    ))}
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{
                          width: "32%",
                        }}
                        onClick={() => {
                          _requestLog(
                            {
                              data: {
                                Origin:         logParam?.origin,
                                ErrorSource:    logParam?.errorSource,
                                DocNumber:      logParam?.docNumber,
                                DocCategory:    logParam?.docCategory,
                                MessageNumber:  logParam?.messageNumber,
                                MessageText:    logParam?.messageText,
                                JobId:          logParam?.jobId,
                                User:           logParam?.user,
                                DateTime:       logParam?.dateTime,
                              },
                            },
                            ({ data }) => {
                              if (data.length != 0) {
                                setLogData(data);
                                setLogView(true);
                                setValidateClearLog(false);
                              } else {
                                _createErrorMessage("89");
                              }
                            }
                          );
                        }}
                      >
                        Get Log
                      </Button>
                      <Button
                        onClick={() => {
                          let navigationNextUrl = `/ReportsHome`;
                          navigate(navigationNextUrl);
                        }}
                        style={{
                          width: "32%",
                          background: "#DA1212",
                          borderColor: "#DA1212",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {logView ? (
              <div className="col-12">
                <div className="mt-0">
                  <div
                    className="custom-table w-100"
                    style={{ height: 700, overflow: "auto" }}
                  >
                    <DataGrid
                      rows={logData || []}
                      columns={[
                        { field: "id", headerName: "ID", width: 100 },
                        { field: "origin", headerName: "Origin", width: 100 },
                        {
                          field: "errorSource",
                          headerName: "Error Source",
                          width: 110,
                        },
                        {
                          field: "docNumber",
                          headerName: "Doc Number",
                          width: 110,
                        },
                        {
                          field: "docCategory",
                          headerName: "Doc Category",
                          width: 120,
                        },
                        { field: "messageNumber", headerName: "#", width: 20 },
                        {
                          field: "messageText",
                          headerName: "Message Text",
                          minWidth: 3000,
                          flex: 1,
                        },
                        { field: "createdBy", headerName: "User", width: 100 },
                        { field: "createdOn", headerName: "DT", width: 190 },
                      ]}
                      pageSize={100}
                      rowsPerPageOptions={[9]}
                      onSelectionModelChange={""}
                    />
                  </div>
                </div>
                <div className="row m-1">
                  <Button
                    onClick={() => {
                     if(
                      logParam?.origin ||
                      logParam?.errorSource ||
                      logParam?.docNumber ||
                      logParam?.docCategory ||
                      logParam?.messageNumber ||
                      logParam?.messageText ||
                      logParam?.jobId ||
                      logParam?.user ||
                      logParam?.dateTime
                     ){
                      logParam.origin = null;
                      logParam.errorSource = null;
                      logParam.docNumber = null;
                      logParam.docCategory = null;
                      logParam.messageNumber = 0;
                      logParam.messageText = null;
                      logParam.jobId = 0 ;
                      logParam.user = null;
                      logParam.dateTime = null;
                     }
                     setLogView(false);
                    }}
                    style={{
                      width: "150px",
                      background: "#DA1212",
                      borderColor: "#DA1212",
                    }}
                  >
                    Reset Filter
                  </Button>
                  <Button
                    onClick={() => {
                      if(
                        logParam?.origin ||
                        logParam?.errorSource ||
                        logParam?.docNumber ||
                        logParam?.docCategory ||
                        logParam?.messageNumber ||
                        logParam?.messageText ||
                        logParam?.jobId ||
                        logParam?.user ||
                        logParam?.dateTime
                       ){
                        logParam.origin = null;
                        logParam.errorSource = null;
                        logParam.docNumber = null;
                        logParam.docCategory = null;
                        logParam.messageNumber = 0;
                        logParam.messageText = null;
                        logParam.jobId = 0 ;
                        logParam.user = null;
                        logParam.dateTime = null;
                       }
                      setValidateClearLog(true);
                      setLogView(false);
                    }}
                    style={{
                      marginLeft: "20px",
                      width: "150px",
                      background: "#DA1212",
                      borderColor: "#DA1212",
                    }}
                  >
                    Clear Log
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
            {validateClearLog ? (
              <div class="d-flex flex-row justify-content-center">
                <div class="p-2">
                  <h2>Are you sure?</h2>
                </div>
                <div class="p-2">
                  <Button
                    onClick={() => {
                      setLogView(false);
                      _clearLog();
                      setValidateClearLog(false);
                    }}
                    style={{
                      marginLeft: "20px",
                      width: "150px",
                      background: "#2ECC71",
                      borderColor: "#2ECC71",
                    }}
                  >
                    Proceed
                  </Button>
                  <Button
                    onClick={() => {
                      setValidateClearLog(false);
                      setLogView(true);
                    }}
                    style={{
                      marginLeft: "20px",
                      width: "150px",
                      background: "#DA1212",
                      borderColor: "#DA1212",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-12 mt-4">
              <MessageLog
                errorMessages={[
                  ..._errorLog,
                  ..._errorMessages,
                  ..._errorClearLog,
                ]}
                successMessages={[..._successLog, ..._successClearLog]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
