export const ValidateDriverPIN = (shipDocData, _createErrorMessage) => {
  if (
    typeof shipDocData.pin === "undefined" ||
    shipDocData.pin == 0 ||
    shipDocData.pin == null
  ) {
    _createErrorMessage("17");
    return false;
  }
  return true;
};

export const ValidateConfirmChecking = (shipDocData, _createErrorMessage) => {
  if (!shipDocData.carrierId) {
    _createErrorMessage("5");
    return false;
  }

  if (!shipDocData.vehicleId) {
    _createErrorMessage("4");
    return false;
  }
  if (!shipDocData.trailerId) {
    _createErrorMessage("6");
    return false;
  }

  return true;
};
