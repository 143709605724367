/** @format */

import React, { useContext, useState } from "react";
import { mainDataArray, unitsArray, readingsArray } from "./Fields";
import { TankContext } from "../context";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import { ReactComponent as Tank } from "../../../../Assets/SVGs/StorageTankType1.svg";
import MaterialField from "../../../../CommonComponents/EnhancedFields/Material/MaterialField";
import MaterialList from "../../../../CommonComponents/DropDowns/Material/MaterialList";

export default function MainTab() {
  const [showMaterialList, setShowMaterialList] = useState(false);
  const [materialFieldName, setMaterialFieldName] = useState();

  const {
    onChange,
    operation,
    dropDowns,
    _tankData,
    _getMaterialList,
    _materialList,
  } = useContext(TankContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _tankData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _tankData,
    dropDowns,
  };

  const computeFillPrscent = () => {
    let fillPercent = 0;
    fillPercent = (_tankData?.volume * 100) / _tankData?.capacity;
    return `${Math.max(isNaN(fillPercent) ? 0 : fillPercent)
      .toFixed(2)
      .toString()}%`;
  };

  let x = 0;
  x = computeFillPrscent();

  const fieldsToRender = mainDataArray(fieldsState);
  const unitFieldsRender = unitsArray(fieldsState);
  const readingsArrayToRender = readingsArray(fieldsState);

  var materialField = fieldsToRender.find(({ id }) => id === "materialId");

  return (
    <div className="row">
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
        <div className="card">
          <div className="cardHeader">
            <h2>Main Data</h2>
          </div>
          <div className="cardBody">
            {fieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
            <MaterialField
              field={mergedFieldData(materialField)}
              setShowMaterialList={setShowMaterialList}
              setMaterialFieldName={setMaterialFieldName}
              _getMaterialList={_getMaterialList}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 610 }}>
          <div className="cardHeader">
            <h2>Readings Data</h2>
          </div>
          <div className="cardBody">
            {readingsArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
        <div className="card">
          <div className="cardHeader">
            <h2>Units</h2>
          </div>
          <div className="cardBody">
            {unitFieldsRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
        <div className="card" style={{ height: "max-content" }}>
          <div className="cardHeader">
            <h2>Graphical </h2>
          </div>
          <div className="cardBody">
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "40Px",
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  zIndex: "5",
                }}
              >
                {x}
              </div>
              <Tank style={{ zIndex: "5" }} />
              <div
                style={{
                  padding: "10px",
                  backgroundColor: "red",
                  height: `calc(${x} - 5px)`,
                  position: "absolute",
                  bottom: "10px",
                  // left: "18%",
                  width: "235px",
                  zIndex: "0",
                  // borderRadius: "19px",
                }}
              >
                {/* <div
                style={{
                  fontSize: "40Px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {x}
              </div> */}
              </div>
            </div>
          </div>
        </div>
        {/*<Icon fill="blue  " />, */}
      </div>
      <MaterialList
        _materialList={_materialList}
        showMaterialList={showMaterialList}
        setShowMaterialList={setShowMaterialList}
        materialFieldName={materialFieldName}
        onChange={onChange}
      />
    </div>
  );
}
