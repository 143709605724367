export const shippingDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "shippingName",
    name: "shippingName",
    type: "text",
    label: "Shipping Name",
  },
  {
    id: "stccCode",
    name: "stccCode",
    type: "string",
    length: 4,
    label: "STCC Code",
  },
  {
    id: "dangerousGoodsClasses",
    name: "dangerousGoodsClasses",
    tag: "select",
    options: dropDowns?.dangerousGoodsClasses || [],
    label: "Dangerous Goods Class",
  },
  {
    id: "packingGroup",
    name: "packingGroup",
    type: "text",
    length: 3,
    label: "Packing Group",
  },
  {
    id: "unnaCode",
    name: "unnaCode",
    type: "text",
    label: "UNNA Code",
  },
];
