/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";
import OverviewInput from "../../../CommonComponents/InitialInput/OverviewInput";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import ListModal from "../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../../CommonComponents/CircleButton";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import OverviewButtons from "./Components/OverviewButtons";
import cardlogo from "../../../Assets/cardLogo.svg";
import { useMessage } from "../../../hooks/UseMessage";


export default function AdminFunctionOverview(props) {

  let navigate = useNavigate();
  const [testMethodParam, setTestMethodParam] = useState();
  const [showTestMethodList, setShowTestMethodList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [
    _deleteTestMethod,
    ,
    _setDeleteTestMethodData,
    _errorDeleteTestMethodData,
    _setErrorDeleteTestMethodData,
    _successDeleteTestMethodData,
    _setSuccessDeleteTestMethodData,
    _loadingDeleteTestMethodData,
  ] = useFetch(GetFullURL("DeleteTestMethod"), { method: "POST" });

  const [_getTestMethodsList, _testMethodList, , , , , , _loadingtTestMethodList] =
    useFetch(GetFullURL("GetAllTestMethodsForDelete"), { method: "POST" });

  const [_testMethodExist, , , , , , , _loadingTestMethodExist] = useFetch(GetFullURL("TestMethodExists"),{ method: "POST" });


  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessDeleteTestMethodData([]);
        break;
      case "error":
        _setErorMessages([]);
        _setErrorDeleteTestMethodData([]);
        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading =  _loadingtTestMethodList  || _loadingTestMethodExist;

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                    <img src={cardlogo} alt="" />
                  </span>
                  TestMethod Admin Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setTestMethodParam}
                    document={testMethodParam}
                    testMethodRadius={true}
                    placeholder={`Enter or Choose Id`}
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of TestMethods`}
                    clickHandler={() => {
                      _getTestMethodsList({},() => {setShowTestMethodList(true);});                  
                    }}
                  />
                  <OverviewButtons
                    testMethod={testMethodParam}
                    navigate={navigate}
                    _testMethodExist={_testMethodExist}
                    _createErrorMessage={_createErrorMessage}
                    _deleteTestMethod={_deleteTestMethod}
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages, ..._errorDeleteTestMethodData]}
              successMessages={[..._successDeleteTestMethodData]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
             <div className="cardAlert">
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setTestMethodParam}
              dataList={_testMethodList}
              showListModal={showTestMethodList}
              setShowModal={setShowTestMethodList}
              title="List Of TestMethods"
              columns={[
                { field: "id", headerName: "Test Method ID", width: 250 },
                { field: "testMethodClassId", headerName: "Type", width: 250 },
                { field: "createdBy", headerName: "Created By", width: 350 },
                ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

