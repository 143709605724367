export const mainDataArray = ({ dropDowns }) => [
  {
    id: "transportationModeId",
    name: "transportationModeId",
    type: "text",
    label: "Transportation Mode Id",
    tag: "select",
    options: dropDowns?.transportationModes || [],
  },
  {
    id: "vehicleId",
    name: "vehicleId",
    type: "text",
    label: "Vehicle Id",
  },
  {
    id: "trailerId",
    name: "trailerId",
    type: "text",
    label: "Trailer Id",
  },

];
