import React, { useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "react-bootstrap/Modal";
import { MaterialContext } from "../MaterialMain/context";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
export default function ConversionListDropDown({
  currentMaterialConversion,
  materialId,
  _conversionList,
  showConversionList,
  setShowConversionList,
}) {
  const { _setMaterialData, _createErrorMessage } = useContext(MaterialContext);

  function handleOnSelect(selectedIds, row) {
    //Get the selected ID
    let selectedId = selectedIds[0];

    const found = currentMaterialConversion.find((row) => {
      if (row.unitConversionId === selectedId) {
        return true;
      }
      return false;
    });

    if (found) {
      _createErrorMessage("48");
      return;
    }

    //Get selected Data
    const selectedRowData = _conversionList.filter((row) => {
      if (row.id === selectedId) {
        return true;
      }
      return false;
    });

    //Create material conversion object
    let newUnitConversionObject = {
      materialId: materialId,
      unitConversionId: selectedId,
      fromUnit: selectedRowData[0].fromUnit,
      toUnit: selectedRowData[0].toUnit,
      denominator: selectedRowData[0].denominator,
      numerator: selectedRowData[0].numerator,
      factor: selectedRowData[0].factor,
      nameLocalizedText: selectedRowData.nameLocalizedText,
      createdBy: null,
      createdOn: null,
      modifiedBy: null,
      modifiedOn: null,
    };

    _setMaterialData((current) => ({
      ...current,
      conversions: Array.isArray(current.conversions)
        ? [...current.conversions, newUnitConversionObject]
        : [newUnitConversionObject],
    }));
    setShowConversionList(false);
  }

  const columns = [
    { field: "id", headerName: "Id", width: 350 },
    { field: "fromUnit", headerName: "From Unit", width: 120 },
    { field: "toUnit", headerName: "ToUnit", width: 120 },
    { field: "factor", headerName: "Factor", width: 120 },
    { field: "denominator", headerName: "Denominator", width: 150 },
    { field: "numerator", headerName: "Numerator", width: 150 },
    { field: "nameLocalizedText", headerName: "Text", width: 250 },
  ];
  return (
    <Modal
      show={showConversionList}
      size="xl"
      onHide={() => setShowConversionList(false)}
      dialogClassName="modal-90w modal-dialog-scrollable"
      aria-labelledby="example-custom-modal-styling-title"
      className="partner-list-modal"
    >
      <Modal.Header>
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{
            fontWeight: 700,
          }}
        >
          List of Unit Convesrions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {_conversionList?.length && (
          <div style={{ height: "50vh", width: "100%" }}>
            <DataGrid
              rows={_conversionList || []}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onSelectionModelChange={(selectedId) =>
                handleOnSelect(selectedId)
              }
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="offset-11 col-1">
          <Button
            variant="danger"
            onClick={() => {
              setShowConversionList(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
