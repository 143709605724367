import React, { useState, useEffect } from "react";
import { ReactComponent as ReactNewDocCard } from "../../../../Assets/NewIconsForCards/NewDocIcon.svg";
import { ReactComponent as ReactEditDocCard } from "../../../../Assets/NewIconsForCards/EditDocIcon.svg";
import Card from "../../../Components/Card";
import { useUser } from "../../../../hooks/useUser";
const CardsArray = [
  {
    title: "New Sulfur BOL",
    text: "",
    Icon: ReactNewDocCard,
    link: "/TerminalActivity/CheckinV3/SULFUR_ELDO",
    roles: ["Admin", "SuperAdmin", "ELCKSULLoader", "Loader"],
  },
  {
    title: "Existing Sulfur BOL",
    text: "",
    Icon: ReactEditDocCard,
    link: "/TerminalActivity/CheckinV4/SULFUR_ELDO",
    roles: ["Admin", "SuperAdmin", "ELCKSULLoader"],
  },
];

export default function ELSulfurHome() {
  const [newCards, setNewCards] = useState([]);
  const [_user, ,] = useUser();
  useEffect(() => {
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card.roles.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);

  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {newCards.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
