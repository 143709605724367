export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Vehicle ID",
    disabled: true,
  },
  {
    id: "vehicleTypeId",
    name: "vehicleTypeId",
    tag: "select",
    options: dropDowns?.vehicleTypes || [],
    type: "text",
    label: "Vehicle Type",
  },
  {
    id: "compartmentCount",
    name: "compartmentCount",
    type: "number",
    label: "Compartment Count",
  },
  {
    id: "fleet",
    name: "fleet",
    type: "text",
    label: "Fleet",
    tag: "select",
    options: dropDowns?.fleets || [],
  },
];
export const infoDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "make",
    name: "make",
    type: "text",
    label: "Make",
  },
  {
    id: "color",
    name: "color",
    type: "text",
    label: "Color",
  },
  {
    id: "licensePlate",
    name: "licensePlate",
    type: "text",
    label: "LicensePlate",
  },
  {
    id: "vIN",
    name: "vIN",
    type: "text",
    label: "VIN",
  },
];
export const homeBaseDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "homeBaseId",
    name: "homeBaseId",
    type: "text",
    label: "Home Base",
    tag: "select",
    options: dropDowns?.homeBases || [],
  },
];

