import React, { useState, useEffect } from "react";
import { useFetch } from "./../../../hooks/useFetch";
import { ShipDocContext } from "./Contex";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import ShipDocHeader from "./Header/Index";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import HomeTab from "./Main/index";
import StatusTab from "./Status/index";
import Items from "./../ShippingDcoumentMain/Items/Items";
import Outbound from "./../ShippingDcoumentMain/Outbound/Outbound";
import SignitureTab from "./Signature/Index";
import Admin from "./Admin/Index";
import WeightMesuarmentTab from "./WeightMesuarment/WeightMesuarmentTab";
import TerminalTab from "./Terminal/TerminalTab";
import TabPannel from "../../../Business Processes/Components/TabPannel";
import { useParams } from "react-router-dom";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";

const UpdatedHomeComponent = () => (
  <React.Fragment>
    <HomeTab />
    <Items />
  </React.Fragment>
);

function Index(props) {
  const [value, setValue] = useState(0);
  const [operation, setOperation] = useState("");
  const [shipDocId, setShipDocId] = useState("");
  const [frontendError, setFrontendError] = useState([]);
  const [displayMainTab, setDisplayMainTab] = useState(false);
  const params = useParams();
  const [
    _getShipDocData,
    _shipDocData,
    _setShipDocData,
    _errorShipDocData,
    _setErrorShipDocData,
    _successShipDocData,
    _setSuccessShipDocData,
    _loadingShipDocData,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" });

  const [
    _getPartnerList,
    _partnerList,
    _setPartnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    _successPartnerList,
    _setSuccessPartnerList,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("PartnerList"), { method: "POST" });

  const [
    _getTankList,
    _tankList,
    ,
    _errorTankList,
    _setErrorTankList,
    _successTankList,
    _setSuccessTankList,
    _loadingTankList,
  ] = useFetch(GetFullURL("TankList"), { method: "POST" });

  const [
    _getShipDocDD,
    _shipDocDD,
    ,
    _errorShipDocDD,
    _setErrorShipDocDD,
    _successShipDocDD,
    _setSuccessShipDocDD,
    _loadingShipDocDD,
  ] = useFetch(GetFullURL("ShippingDocDropDowns"), { method: "POST" });

  const [
    _getMaterialList,
    _materialList,
    _setMaterialList,
    _errorMaterialList,
    _SetErrorMaterialList,
    _successMaterialList,
    _setSuccessMaterialList,
    _loadingMaterialList,
  ] = useFetch(GetFullURL("MaterialList"), { method: "POST" });

  //data after saving is applied to document in the save call via a callback, so need for data here
  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" });

  const handleChange = (event, newValue) => setValue(newValue);

  useEffect(() => {
    //when componen mounts,set ID and operation
    if (params.op) {
      setOperation(params.op);
    }
    if (params.id) {
      setShipDocId(params.id);
    }
  }, []);

  useEffect(() => {
    if (value === 0) {
      setDisplayMainTab(false);
    }
  }, [value]);

  useEffect(() => {
    // if (operation === "Create" && shipDocId) {
    //   _getCreateShipDocRefOrder(
    //     { data: { Id: shipDocId, SaveImmediately: false } },
    //     ({ data: copiedShipDoc }) => {
    //       _getShipDocDD({ data: { locationId: copiedShipDoc.locationId } });
    //       _setShipDocData((prevState) => {
    //         return { ...prevState, ...copiedShipDoc };
    //       });
    //     }
    //   );
    // } else {
    if (shipDocId) {
      _getShipDocData({ data: { id: shipDocId } }, ({ data: shipDocData }) => {
        //        _getShipDocDD({ data: { locationId: shipDocData.locationId } });
        _getShipDocDD({ data: { locationId: "" } });
      });
    } else {
      _getShipDocDD({ data: { locationId: "" } });
    }
  }, [shipDocId]);

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;

    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        //if checkbox then pass the value as true or false assuming that field is defined as bool in backend
        checked ? (newValue = true) : (newValue = false);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    _setShipDocData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  //Use the context and pass the data, dropdowns
  const contextState = {
    onChange,
    operation,
    dropDowns: _shipDocDD || [],
    shipDocId,
    _shipDocData,
    _setShipDocData,
    _getPartnerList,
    _partnerList,
    _materialList,
    _getMaterialList,
    _getTankList,
    _tankList,
    displayMainTab,
    setDisplayMainTab,
    setFrontendError,
  };

  const operationLoading =
    _loadingShipDocData ||
    _loadingPartnerList ||
    _loadingTankList ||
    _loadingShipDocDD ||
    _loadingMaterialList ||
    _loadingSaveShipDoc;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessShipDocData([]);
        _setSuccessShipDocData([]);
        _setSuccessShipDocDD([]);
        _setSuccessMaterialList([]);
        _setSuccessPartnerList([]);
        _setSuccessTankList([]);
        _setSuccessSaveShipDoc([]);
        break;
      case "error":
        _setErrorShipDocData([]);
        _setErrorShipDocDD([]);
        _SetErrorMaterialList([]);
        _setErrorSaveShipDoc([]);
        _setErrorPartnerList([]);
        _setErrorTankList([]);
        setFrontendError([]);
        break;
      default:
        break;
    }
  }
  return (
    <ShipDocContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <ShipDocHeader
        _loadingSaveShipDoc={_loadingSaveShipDoc}
        _saveShipDoc={_saveShipDoc}
      />
      <TabPannel
        value={value}
        handleChange={handleChange}
        labels={[
          "Home",
          "Mesuarments",
          "Terminal",
          "Status",
          "Items",
          "Outbound",
          "Signature",
          "Admin",
          "Log",
        ]}
        components={[
          UpdatedHomeComponent,
          WeightMesuarmentTab,
          TerminalTab,
          StatusTab,
          Items,
          Outbound,
          SignitureTab,
          Admin,
        ]}
      />
      <MessageLog
        errorMessages={[
          ..._errorShipDocDD,
          ..._errorShipDocData,
          ..._errorMaterialList,
          ..._errorSaveShipDoc,
          ..._errorPartnerList,
          ..._errorTankList,
          ...frontendError,
        ]}
        successMessages={[
          ..._successShipDocDD,
          ..._successShipDocData,
          ..._successMaterialList,
          ..._successSaveShipDoc,
          ..._successPartnerList,
          ..._successTankList,
        ]}
        clearMessages={clearMessages}
      ></MessageLog>
    </ShipDocContext.Provider>
  );
}

export default Index;
