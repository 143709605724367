import { issueMessage } from "../../../../../utils/issueMessage";

export const ValidateShipDocAfterAPI = (shipDocData, language) => {
  let errorMessages = [];
  let successMessages = [];
  let warningMessages = [];

  if (shipDocData.loadStDT != null) {
    let { messageText } = issueMessage(language, "3");
    warningMessages.push(messageText);
  }
  if (shipDocData.cutoffWeight <= 0) {
    let { messageText } = issueMessage(language, "15");
    errorMessages.push(messageText);
  }
  return { errorMessages, successMessages, warningMessages };
};

export const ValidateDriverPIN = (shipDocData, _createErrorMessage) => {
  if (
    typeof shipDocData.pin === "undefined" ||
    shipDocData.pin == 0 ||
    shipDocData.pin == null
  ) {
    _createErrorMessage("17");
    return false;
  }

  return true;
};

export const ValidateConfirmChecking = (shipDocData, _createErrorMessage) => {
  if (shipDocData.driverId == "") {
    _createErrorMessage("22");
    return false;
  }
  if (typeof shipDocData.driverId == "undefined") {
    _createErrorMessage("22");
    return false;
  }

  return true;
};

export const ValidateSetup = (setupItems, _createErrorMessage) => {
let firstItem = setupItems[0];

  if (firstItem == null || undefined){    
    _createErrorMessage("47");
    return false;
  }


  return true;
};

