import React, { useContext, useState ,useEffect } from "react";
import UniversalEditableGrid from "../../../../CommonComponents/Grid/EditableGrid/UniversalEditableGrid";
import { itemsGridFields,itemsGridTransferTypeFields } from "./Fields";
import ItemDetails from "./ItemsDetails/Index";
import { InventoryDocContext } from "../Contex";
import Button from "react-bootstrap/Button";
import * as _ from "underscore";

export default function Index() {
  const {
    _inventoryDocData,
    operation,
    _setInventoryDocData,
    dropDowns,
    onChange,
  } = useContext(InventoryDocContext);

  const [showItemsDetailsModal, setShowItemsDetailsModal] = useState(false);
  const [itemOperation, setItemOperation] = useState();
  const [item, setItem] = useState({});
  const [transferType, setTransferType] = useState(false);

  let currentItemsGridFields;
  switch (_inventoryDocData.inventoryDocTypeId) {
    case "Transfer":
      currentItemsGridFields = itemsGridTransferTypeFields;
      break;
    default:
      currentItemsGridFields = itemsGridFields;
  }


  const addNewItemClick = () => {
    let maxItemNumber = 0;
    let itemNumbers = [];

    if (_inventoryDocData.inventoryDocItemsComm != null) {
      _inventoryDocData.inventoryDocItemsComm.forEach((arrayItem) => {
        itemNumbers.push(arrayItem.itemNumber);
      });
      maxItemNumber = _.max(itemNumbers);
    }

    if (maxItemNumber === -Infinity || maxItemNumber === 0) {
      maxItemNumber = 10;
    } else {
      maxItemNumber = maxItemNumber + 10;
    }

    let newItemObject = {
      itemNumber: maxItemNumber,
    };
    setItemOperation("Create");
    setShowItemsDetailsModal(true);
    setItem({ ...newItemObject });
  };
  //Grid functions handler
  function handleButtonClickGrid(e) {
    let selectedItemNumber = e.target.dataset.id;
    let gridButtonOperation = e.target.dataset.op;
    let result;
    let currentItems = _inventoryDocData.inventoryDocItemsComm;

    setItemOperation(gridButtonOperation);
    switch (gridButtonOperation) {
      case "Display":
      case "Change":
        result = _inventoryDocData.inventoryDocItemsComm.find(
          ({ itemNumber }) => +itemNumber === +selectedItemNumber
        );
        setItem(result);
        setShowItemsDetailsModal(true);
        break;
      case "Delete":
        currentItems = currentItems.filter(
          (x) => +x.itemNumber !== +selectedItemNumber
        );
        _setInventoryDocData((current) => {
          return { ...current, inventoryDocItemsComm: currentItems };
        });
        break;

      default:
    }
  }
  //Acccpte chnages from item details window
  const handleAccept = () => {
    if (itemOperation === "Create") {
      _setInventoryDocData((current) => {
        let items = current.inventoryDocItemsComm || [];

        items.push(item);
        return { ...current, inventoryDocItemsComm: items };
      });
    } else {
      _setInventoryDocData((current) => {
        let items = current.inventoryDocItemsComm;

        var index = items.findIndex((x) => x.itemNumber === item.itemNumber);
        if (index >= 0) {
          items[index] = item;
        }
        return { ...current, inventoryDocItemsComm: items };
      });
    }
  };
  //Update main state, update item data
  const onChangeItem = (e) => {
    
    let item;
    let fieldTypeItem;
    const {
      target: { value, name },
    } = e;

    item = e.target.dataset.item;
    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeItem = e.target.dataset.type;

    let newValue;
    switch (fieldTypeItem) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    let newItems = _inventoryDocData.inventoryDocItemsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.itemNumber == item) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setInventoryDocData((prevState) => {
      return { ...prevState, inventoryDocItemsComm: newItems };
    });
  };

  if (showItemsDetailsModal) {
    return (
      <div className="mt-4">
        <ItemDetails
          setShowItemsDetailsModal={setShowItemsDetailsModal}
          itemOperation={itemOperation}
          item={item}
          setItem={setItem}
          handleAccept={handleAccept}
        />
      </div>
    );
  }
  return (
    <div>
          <Button
        variant="primary"
        active="true"
        data-op="Create"
        disabled={operation === "Display"}
        onClick={addNewItemClick}
      >
        Add New Item
      </Button>
      <UniversalEditableGrid
        fields={currentItemsGridFields}
        headerData={_inventoryDocData}
        itemData={_inventoryDocData?.inventoryDocItemsComm ?? []}
        dropDowns={dropDowns}
        operation={operation}
        onChange={onChangeItem}
        handleButtonClickGrid={handleButtonClickGrid}
      />
    </div>
  );
}

