import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { TestMethodContext } from "../context";
import { MuiButon } from "../../../../CommonComponents/MuiButton";

export default function TestMethodHeader({
  _loadingSaveData,
  _saveTestMethod,
}) {
  const {
    _testMethodData,
    operation,
    setOperation,
    setTestMethodId,
    _setTestMethodData,
  } = useContext(TestMethodContext);

  const handleSave = () => {
    _saveTestMethod(
      { data: _testMethodData },
      ({ data: NewTestMethodData, success: callStatus }) => {
        //After successfull creation set the operation to change

        if (callStatus) {
          if (operation === "Create") {
          }
          setOperation("Change");
        }
        _setTestMethodData(NewTestMethodData);
        setTestMethodId(NewTestMethodData.id);
      }
    );
  };

  return (
    <Paper className="py-2 px-2 d-flex align-items-center justify-space-between customPaper">
      <TopPanel />
      <MuiButon
        title="Save"
        loading={_loadingSaveData}
        onClick={handleSave}
        disabled={operation === "Display"}
      />
    </Paper>
  );
}
