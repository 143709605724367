import React, { useState, useEffect } from "react";
import { useFetch } from "./../../../hooks/useFetch";
import { TankContext } from "./context";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen";
import TankOperationHeader from "./Header/index";
import MessageLog from "../Components/MessageLog";
import MainTab from "./Main/Index";
import AdminTab from "./Admin/Index";
import Tabpannel from "../../../Business Processes/Components/TabPannel";
import { useParams } from "react-router-dom";
import { GetFullURL } from "./../../../CommonComponents/GlobalConfFiles/URLs";

export default function Index(props) {
  const [value, setValue] = useState(0);
  const [operation, setOperation] = useState("");
  const [tankId, setTankId] = useState("");
  const [frontendError, setFrontendError] = useState([]);
  const params = useParams();

  const [
    _getTankData,
    _tankData,
    _setTankData,
    _errorTankData,
    _setErrorTankData,
    _successTankData,
    _setSuccessTankData,
    _loadingTankData,
  ] = useFetch(GetFullURL("GetTank"), { method: "POST" });

  const [
    _getTankDD,
    _tankDD,
    _setTankDD,
    _errorTankDD,
    _setErrorTankDD,
    _successTankDD,
    _setSuccessTankDD,
    _loadingTankDD,
  ] = useFetch(GetFullURL("TankDropDowns"), { method: "POST" });

  const [
    _saveTankData,
    _tankSaveData,
    _setTankSaveData,
    _errorSaveTankData,
    _setErrorSaveTankData,
    _successSaveTankData,
    _setSuccessSaveTankData,
    _loadingSaveTankData,
  ] = useFetch(GetFullURL("TankDropDowns"), { method: "POST" });

  const [
    _saveTank,
    ,
    ,
    _errorSaveTank,
    _setErrorSaveTank,
    _successSaveTank,
    _setSuccessSaveTank,
    _loadingSaveTank,
  ] = useFetch(GetFullURL("SaveTank"), { method: "POST" });

  const [
    _getMaterialList,
    _materialList,
    _setMaterialList,
    _errorMaterialList,
    _SetErrorMaterialList,
    _successMaterialList,
    _setSuccessMaterialList,
    _loadingMaterialList,
  ] = useFetch(GetFullURL("MaterialList"), { method: "POST" });

  useEffect(() => {
    //when componen mounts,set ID and operation
    if (params.op) {
      setOperation(params.op);
    }
    if (params.id) {
      setTankId(params.id);
    }
  }, []);

  useEffect(() => {
    _getTankDD();
    if (tankId) _getTankData({ data: { id: tankId } });
  }, [tankId]);

  const handleChange = (event, newValue) => setValue(newValue);

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;
    let newValue;

    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = "true") : (newValue = "false");
        break;

      default:
        newValue = value;
        break;
    }

    if (newValue === "select" || newValue === "SELECT") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    _setTankData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const contextState = {
    onChange,
    operation,
    dropDowns: _tankDD || [],
    tankId,
    _tankData,
    _setTankData,
    _saveTank,
    frontendError,
    setOperation,
    setFrontendError,
    _getMaterialList,
    _materialList,
  };

  const operationLoading =
    _loadingTankData ||
    _loadingTankDD ||
    _loadingSaveTankData ||
    _loadingSaveTank ||
    _loadingMaterialList;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveTankData([]);
        _setSuccessTankData([]);
        _setSuccessTankDD([]);
        _setSuccessMaterialList([]);
        break;
      case "error":
        _setSuccessSaveTank([]);
        _setErrorSaveTank([]);
        _setErrorTankData([]);
        _setErrorTankDD([]);
        _setErrorSaveTankData([]);
        _SetErrorMaterialList([]);
        break;
      default:
        break;
    }
  }

  return (
    <TankContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <TankOperationHeader
        _loadingSaveTanklData={_loadingSaveTankData}
        _saveMaterialData={_saveTank}
      />
      <Tabpannel
        value={value}
        handleChange={handleChange}
        labels={["Main", "Admin", "Log"]}
        components={[MainTab, AdminTab]}
      />
      <MessageLog
        errorMessages={[
          ..._errorSaveTank,
          ..._errorTankDD,
          ..._errorTankData,
          ..._errorSaveTankData,
          ..._errorMaterialList,
        ]}
        successMessages={[
          ..._successSaveTank,
          ..._successTankDD,
          ..._successTankData,
          ..._successSaveTankData,
          ..._successMaterialList,
        ]}
        clearMessages={clearMessages}
      ></MessageLog>
    </TankContext.Provider>
  );
}
