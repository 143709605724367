export const mainArray = ( {dropDowns}) => [
    {
        id: "locationId",
        name: "locationId",
        type: "text",
        label: "Location Id",
        options: dropDowns?.locations || [],
      tag: "select",
    },
    {
      id: "shipDocDate",
      name: "shipDocDate",
      type: "datetime",
      label: "Ship Doc Date",

    },
    {
      id: "pin",
      name: "pin",
      type: "number",
      label: "PIN",
    }
  ];
  export const partnerArray = ( {}) => [
    {
      id: "shiptoId",
      name: "shiptoId",
      label: "Shipto",
    },
    {
      id: "soldtoId",
      name: "soldtoId",
      label: "Soldto",
    },
  ];
