import React from "react";
import { ReactComponent as AdminFunctionIcon } from "../../../Assets/NewIconsForCards/UserSettingsIcon.svg";
import { ReactComponent as LeaseIcon } from "../../../Assets/NewIconsForCards/LeaseIcon.svg";
import Card from "../../Components/Card";
import { useNavigate } from "react-router-dom";

const Arr = [
  {
    title: "Lease General",
    text: "",
    Icon: LeaseIcon,
    link: "/LeaseGeneral",
  },
  {
    title: "Admin Function",
    text: "",
    Icon: AdminFunctionIcon,
    link: "/LeaseAdmin",
  },
];

export default function LeaseOverview(props) {
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {Arr.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
