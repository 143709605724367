/** @format */

import React, { useContext, useState, useEffect} from "react";
import { mainDataArray, additionalInputArray, referenceInputArray,quantityArray} from "./Fields";
import { FinancialDocContext } from "../../Contex";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";

function Index({
  setShowItemsDetailsModal,
  itemOperation,
  item,
  setItem,
  handleAccept,
}) {
  const {
    _financialDocData,
    dropDowns,
  } = useContext(FinancialDocContext);




  // useEffect(() => {
  //   if (_financialDocData.financialDocTypeId === 'Transfer') {
  //     setTransferType(true);
  //   }else {
  //     setTransferType(false);
  //   }
  // }, [_financialDocData.financialDocTypeId]);

  const onChangeItem = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setItem((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: item,
      onChange: onChangeItem,
    };
    switch (itemOperation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: item,
    dropDowns,
  };

  const mainFieldsToRender           = mainDataArray(fieldsState);
  const additionalInputArrayToRender = additionalInputArray(fieldsState);
  const referenceInputArrayToRender  = referenceInputArray(fieldsState);
  const quantityArrayToRender        = quantityArray(fieldsState);
  


  return (
    <div className="row">
      <div className="col-lg-3 col-sm-12 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Main Data</h2>
          </div>
          <div className="cardBody">
            {mainFieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Account</h2>
          </div>
          <div className="cardBody">
             <div className="cardBody">
            {additionalInputArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Reference</h2>
          </div>
          <div className="cardBody">
             <div className="cardBody">
            {referenceInputArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Quantities </h2>
          </div>
          <div className="cardBody">
            {quantityArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
        className="my-4"
      >
        <Button
          className="me-4"
          style={{ background: "#DA1212", borderColor: "#DA1212" }}
          onClick={() => {
            setShowItemsDetailsModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          active="true"
          onClick={() => {
            handleAccept();
            setShowItemsDetailsModal(false);
          }}
        >
          Accept
        </Button>
      </div>
    </div>
  );
}

export default Index;
