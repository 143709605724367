import React, { useContext } from "react";
import { LogisticStationContext } from "../LogisticStationMain/context";

export default function TopPanel() {
  const { operation, logisticStationId } = useContext(LogisticStationContext);

  var logisticStationInfo;
  switch (operation) {
    case "Display":
      logisticStationInfo = `${operation} LogisticStation: ${logisticStationId}`;
      break;
    case "Change":
      logisticStationInfo = `${operation} LogisticStation ${logisticStationId}`;
      break;
    case "Create":
      logisticStationInfo = `${operation} New LogisticStation`;
      break;
    default:
      logisticStationInfo = `LogisticStation Info`;
      break;
  }
  return (
    <h2 className="heading" style={{ position: "relative", top: 8 }}>
      {logisticStationInfo}
    </h2>
  );
}
