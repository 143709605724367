import React, { useState, useEffect } from "react";
import { BillOfMaterialContext } from "./Contex";
import MessageLog from "../Components/MessageLog";
import HomeTab from "./Main/index";
import Items from "./../BillOfMaterialMain/Items/Index";
import BillOfMaterialOperationHeader from "./Header/Index";
import AdminTab from "./Admin/Index";
import { useFetch } from "./../../../hooks/useFetch";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Tabpannel from "../../../Business Processes/Components/TabPannel";
import { useParams } from "react-router-dom";

const UpdatedHomeComponent = () => (
  <React.Fragment>
    <HomeTab />
    <Items />
  </React.Fragment>
);

export default function Index(props) {
  //read URL parameters
  const params = useParams();
  //Partner ID
  let id = params.id;

  //Operation
  let operation = params.op;

  //Make sure that ID is numeric
  const billOfMaterialId = id;
  //Do the following when page loads

  const [value, setValue] = useState(0);

  const [frondendError, setFrontendError] = useState([]);
  const [frontendWarning, setFrontendWarning] = useState([]);

  const [
    _getBillOfMaterialData,
    _billOfMaterialData,
    _setBillOfMaterialData,
    _errorBillOfMaterialData,
    _setErrorBillOfMaterialData,
    _successBillOfMaterialData,
    _setSuccessBillOfMaterialData,
    _loadingBillOfMaterialData,
  ] = useFetch(GetFullURL("GetBillOfMaterial"), { method: "POST" });

  const [
    _getBillOfMaterialDD,
    _billOfMaterialDD,
    ,
    _errorBillOfMaterialDD,
    _setErrorBillOfMaterialDD,
    _successBillOfMaterialDD,
    _setSuccessBillOfMaterialDD,
    _loadingtBillOfMaterialDD,
  ] = useFetch(GetFullURL("BillOfMaterialDropDowns"), { method: "POST" });

  const [
    _getMaterialList,
    _materialList,
    _setMaterialList,
    _errorMaterialList,
    _SetErrorMaterialList,
    _successMaterialList,
    _setSuccessMaterialList,
    _loadingMaterialList,
  ] = useFetch(GetFullURL("MaterialList"), { method: "POST" });

  const [
    _saveBillOfMaterialData,
    _billOfMaterialSaveData,
    _setBillOfMaterialSaveData,
    _errorSaveBillOfMaterialData,
    _setErrorSaveBillOfMaterialData,
    _successSaveBillOfMaterialData,
    _setSuccessSaveBillOfMaterialData,
    _loadingSaveBillOfMaterialData,
  ] = useFetch(GetFullURL("SaveBillOfMaterial"), { method: "POST" });


  useEffect(() => {
    _getBillOfMaterialDD();
    _getMaterialList();
    if (billOfMaterialId) {
      _getBillOfMaterialData({ data: { id: billOfMaterialId } });
    }
  }, [billOfMaterialId]);

  const onChange = (e) => {
    let item;
    let fieldTypeItem;
    let fieldType;
    
    const {
      target: { value, name },
    } = e;
    let newValue;
    if (e.target.dataset) {
      item = e.target.dataset.item;
      //Get field type from  from custom attribute, this used for select mainly
      fieldTypeItem = e.target.dataset.type;
    }

    if (fieldTypeItem) {
      fieldType = fieldTypeItem;
    } else {
      fieldType = e.target.type;
    }

    switch (fieldType) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    if (item) {
      _setBillOfMaterialData((prevState) => {
        let newItems = prevState.orderItemsComm.map((i) => {
          //Did not use === becuase '5' equals to 5
          if (i.itemNumber == item) {
            return { ...i, ...newValueObject };
          }
          return i;
        });
        return { ...prevState, orderItemsComm: newItems };
      });
    } else {
      _setBillOfMaterialData((prevState) => {
        return { ...prevState, ...newValueObject };
      });
    }
  };

  const handleChange = (event, newValue) => setValue(newValue);



  //Use the context and pass the data, dropdowns
  const contextState = {
    onChange,
    operation,
    dropDowns: _billOfMaterialDD || [],
    billOfMaterialId,
    _billOfMaterialData,
    _setBillOfMaterialData,
    _saveBillOfMaterialData,
    _materialList,
    _getMaterialList,
    setFrontendError,
  };


  const operationLoading =
    _loadingBillOfMaterialData       ||
    _loadingMaterialList             ||
    _loadingtBillOfMaterialDD        ||
    _loadingSaveBillOfMaterialData   ;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveBillOfMaterialData([]);
        _setSuccessBillOfMaterialData([]);
        _setSuccessBillOfMaterialDD([]);
        _setSuccessMaterialList([]);
        break;
      case "error":
        _setErrorBillOfMaterialData([]);
        _setErrorBillOfMaterialDD([]);
        _SetErrorMaterialList([]);
        _setErrorSaveBillOfMaterialData([]);
        setFrontendError([]);
        break;
      case "warning":
        setFrontendWarning([]);
      default:
        break;
    }
  }
  return (
    <BillOfMaterialContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <BillOfMaterialOperationHeader
        _loadingSaveBillOfMateriallData={_loadingSaveBillOfMaterialData}
      />
      <Tabpannel
        value={value}
        handleChange={handleChange}
        labels={["Home","Items","Admin","Log"]}
        components={[
          UpdatedHomeComponent,
          Items,
          AdminTab,
          () => (
            <MessageLog
              errorMessages={[
                ..._errorBillOfMaterialDD,
                ..._errorBillOfMaterialData,
                ..._errorMaterialList,
                ..._errorSaveBillOfMaterialData,
              ]}
              successMessages={[
                ..._successBillOfMaterialDD,
                ..._successBillOfMaterialData,
                ..._successMaterialList,
                ..._successSaveBillOfMaterialData,
              ]}
              warningMessages={[...frontendWarning]}
              clearMessages={clearMessages}
            ></MessageLog>
          ),
        ]}
      />
    </BillOfMaterialContext.Provider>
  );
}


