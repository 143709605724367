import React, { useState, useEffect } from "react";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import HomeTab from "./Main/index";
import AdminTab from "./Admin/index";
import { TestMethodContext } from "./context";
import { useFetch } from "./../../../hooks/useFetch";
import { useMessage } from "./../../../hooks/UseMessage";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen";
import { GetFullURL } from "./../../../CommonComponents/GlobalConfFiles/URLs";
import TabPannel from "../../../Business Processes/Components/TabPannel";
import { useParams } from "react-router-dom";
import TestMethodHeader from "./Header/index";

const TestMethodMain = (props) => {
  const [value, setValue] = React.useState(0);
  const [operation, setOperation] = useState("");
  const [testMethodId, setTestMethodId] = useState("");
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const params = useParams();

  const [
    _getTestMethodData,
    _testMethodData,
    _setTestMethodData,
    _errorTestMethodData,
    _setErrorTestMethodData,
    _successTestMethodData,
    _setSuccessTestMethodData,
    _loadingTestMethodData,
  ] = useFetch(GetFullURL("GetTestMethod"), { method: "POST" });

  const [
    _getTestMethodDD,
    _testMethodDD,
    ,
    _errorTestMethodDD,
    _setErrorTestMethodDD,
    _successTestMethodDD,
    _setSuccessTestMethodDD,
    _loadingtTestMethodDD,
  ] = useFetch(GetFullURL("TestMethodDropdowns"), { method: "POST" });

  const [
    _getTestMethodsList,
    _testMethodsList,
    _setTestMethodList,
    _errorTestMethodList,
    _SetErrorTestMethodList,
    _successTestMethodList,
    _setSuccessTestMethodList,
    _loadingTestMethodList,
  ] = useFetch(GetFullURL("TestMethodList"), { method: "POST" });

  const [
    _saveTestMethod,
    ,
    ,
    _errorSaveTestMethod,
    _setErrorSaveTestMethod,
    _successSaveTestMethod,
    _setSuccessSaveTestMethod,
    _loadingSaveData,
  ] = useFetch(GetFullURL("SaveTestMethod"), { method: "POST" });

  useEffect(() => {
    if (params.op) {
      setOperation(params.op);
    }

    if (params.id) {
      setTestMethodId(params.id);
    }
  }, []);

  useEffect(() => {
    _getTestMethodDD();
    _getTestMethodsList();
    if (testMethodId) _getTestMethodData({ data: { id: testMethodId } });
  }, [testMethodId]);

  const handleChange = (event, newValue) => setValue(newValue);

  const onChange = (e) => {
    const {
      target: { value, name , checked },
    } = e;
    let newValue;

    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = "true") : (newValue = "false");
        break;

      default:
        newValue = value;
        break;
    }

    if (newValue === "SELECT" || newValue === "select") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };

    _setTestMethodData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const contextState = {
    onChange,
    operation,
    dropDowns: _testMethodDD || [],
    testMethodId,
    _testMethodData,
    _testMethodDD,
    _setTestMethodData,
    _testMethodsList,
    setOperation,
    setTestMethodId,
    _createErrorMessage,
    _getTestMethodsList,
    _testMethodsList
  };

  const operationLoading =
    _loadingTestMethodData ||
    _loadingTestMethodList ||
    _loadingtTestMethodDD ||
    _loadingSaveData;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveTestMethod([]);
        _setSuccessTestMethodData([]);
        _setSuccessTestMethodDD([]);
        _setSuccessTestMethodList([]);
        break;
      case "error":
        _setErrorTestMethodData([]);
        _setErrorTestMethodDD([]);
        _SetErrorTestMethodList([]);
        _setErrorSaveTestMethod([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }

  return (
    <TestMethodContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <TestMethodHeader
        _loadingSaveData={_loadingSaveData}
        _saveTestMethod={_saveTestMethod}
      />
      <TabPannel
        value={value}
        handleChange={handleChange}
        key="test"
        labels={[
          "Home",
          "Admin",
          "Log",
        ]}
        components={[
          HomeTab,
          AdminTab,
        ]}
      />
      <MessageLog
        errorMessages={[
          ..._errorTestMethodDD,
          ..._errorTestMethodData,
          ..._errorTestMethodList,
          ..._errorSaveTestMethod,
          ..._errorMessages,
        ]}
        successMessages={[
          ..._successTestMethodDD,
          ..._successTestMethodData,
          ..._successTestMethodList,
          ..._successSaveTestMethod,
        ]}
        clearMessages={clearMessages}
      />
    </TestMethodContext.Provider>
  );
};

export default TestMethodMain;
