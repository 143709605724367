import React, { useState } from "react";
import { FieldsReducer } from "src/CommonComponents/Reducer/Index";
import { mainArray } from "./Fields";
import { useFetch } from "src/hooks/useFetch";
import { GetFullURL } from "src/CommonComponents/GlobalConfFiles/URLs";
import Button from "react-bootstrap/Button";
import { useMessage } from "src/hooks/UseMessage";
import { useNavigate } from "react-router-dom";
import MessageLog from "src/CommonComponents/Messages/MessageLog";
import { LoadingScreen } from "src/CommonComponents/LoadingScreen/index";
import { useDD } from "src/hooks/UseDD";
import Card from "src/CommonComponents/Card";
import additionalinfo from "src/Assets/info2.svg";
import ColumnChart from "src/CommonComponents/Charts/ColumnChart/ColumnChart";

export default function GlendaleChart(props) {
  let navigate = useNavigate();
  const [reportParam, setReportParam] = useState();
  const [reportData, setReportData] = useState({});
  const [showReport, setShowReport] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [_dd] = useDD();
  const [xaxisName, setXaxisName] = useState("");
  const [yaxisName, setYaxisName] = useState("");
  const [chartName, setChartName] = useState("");

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    //If the value is select, clear value
    if (value === "SELECT" || value === "select") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    setReportParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const [
    _getShipDocsVolumeV1,
    _shipDocsVolumeV1Data,
    ,
    _errorShipDocsVolumeV1,
    _setErrorShipDocsVolumeV1,
    _successShipDocsVolumeV1,
    _setSuccessShipDocsVolumeV1,
    _loadingShipDocsVolumeV1,
  ] = useFetch(GetFullURL("ShipDocsVolumeV1"), { method: "POST" });

  const reports = [
    { value: "BOL_NUMBER", label: "BOLs Per Day" },
    { value: "BOL_WEIGHT", label: "BOL Weight Per Day" },
  ];
  const fieldsState = {
    dropDowns: _dd.shipDoc || [],
    reportDD: reports,
  };

  const mainFieldsToRender = mainArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    return newFieldObject;
  };

  console.log("METRIVS", _shipDocsVolumeV1Data);
  const runReport = () => {
    if (!reportParam) {
      _createErrorMessage("116");
      return;
    }
    // temp fix
    if (!reportParam.fromLoadStD) {
      reportParam.fromLoadStD = "2020-06-27";
    }
    if (!reportParam.toLoadStD) {
      reportParam.toLoadStD = "2029-06-27";
    }

    _getShipDocsVolumeV1(
      {
        data: {
          ReportId: reportParam?.reportId,
          LocationId: "4001",
          FromLoadStD: reportParam?.fromLoadStD,
          ToLoadStD: reportParam?.toLoadStD,
        },
      },
      ({ data }) => {
        if (data.length == 0) {
          _createErrorMessage("90");
          return;
        }
        let dataValues = [];
        switch (reportParam.reportId) {
          case "BOL_WEIGHT":
            dataValues = data?.map((x) => x.totalWeight);
            setXaxisName("Dates");
            setYaxisName("Amount of Weight , UoM: " + reportData.unit[0]);
            setChartName("Weight");
            break;
          default:
            dataValues = data?.map((x) => x.numberShipDocs);
            setXaxisName("Dates");
            setYaxisName("Amount of Documents");
            setChartName("Documents");
        }
        let dates = data?.map((x) => x.date);
        let UoM = data?.map((x) => x.weightUnit);

        setReportData({
          labels: dates,
          values: dataValues,
          unit: UoM,
        });
        setShowReport(true);
      }
    );
  };

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        _setErrorShipDocsVolumeV1([]);

        break;
      case "warning":
        break;
      default:
        break;
    }
  }
  const operationLoading = _loadingShipDocsVolumeV1;

  if (showReport) {
    return (
      <div className="container-fluid">
        {operationLoading && <LoadingScreen />}
        <div className="row">
          <ColumnChart name={chartName} {...reportData} yaxisName={yaxisName} />
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <Button
              onClick={() => {
                setShowReport(false);
                setXaxisName("");
                setYaxisName("");
              }}
              style={{
                background: "#27AE60",
                color: "black",
              }}
            >
              Back to Selection
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container-fluid">
        {operationLoading && <LoadingScreen />}

        <div className="row mt-2">
          <div className="col-xl-3 col-lg-4 col-sm-12 col-xs-12">
            <Card
              cardHeaderProps={{
                cardlogo: additionalinfo,
                headerLabel: "BOL Metrics",
              }}
              cardBody={mainFieldsToRender.map((field, index) => (
                <FieldsReducer
                  field={mergedFieldData(field, reportParam)}
                  key={index}
                />
              ))}
            />
            <div className="row ml-3 mt-3">
              <div className="col-6">
                <Button
                  style={{
                    width: "50%",
                  }}
                  onClick={runReport}
                >
                  Run
                </Button>
              </div>
              <div className="col-6">
                <Button
                  onClick={() => {
                    navigate(`/TerminalActivity/GLNAdminHome`);
                  }}
                  style={{
                    width: "50%",
                    background: "#DA1212",
                    borderColor: "#DA1212",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-3">
            <MessageLog
              errorMessages={[..._errorShipDocsVolumeV1, ..._errorMessages]}
              successMessages={[]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
          </div>
        </div>
      </div>
    );
  }
}
