import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import { useMessage } from "../../hooks/UseMessage";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput";
import OverviewButtons from "./Components/OverviewButtons";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../CommonComponents/CircleButton";
import MessageLog from "../../CommonComponents/Messages/MessageLog";

function TankPhysicalOverview() {
  let navigate = useNavigate();

  const [tankPhysical, setTankPhysical] = useState();
  const [showTankPhysicalList, setShowTankPhysicalList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [
    _getTankPhysicalList,
    _tankPhysicalList,
    ,
    ,
    ,
    ,
    ,
    _loadingtTankPhysicalList,
  ] = useFetch(GetFullURL("TankPhysicalList"), { method: "POST" });

  const [_getTankPhysicalExists, , , , , , , _loadingtTankPhysicalExist] =
    useFetch(GetFullURL("TankPhysicalExists"), { method: "POST" });

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading =
    _loadingtTankPhysicalExist || _loadingtTankPhysicalList;
  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>Tank Physical Overview</h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setTankPhysical}
                    document={tankPhysical}
                    placeholder="Tank Physical id here..."
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of Tank Physicals`}
                    clickHandler={() => {
                      _getTankPhysicalList({}, () => {
                        setShowTankPhysicalList(true);
                      });
                    }}
                  />
                  <OverviewButtons
                    tankPhysical={tankPhysical}
                    navigate={navigate}
                    _getTankPhysicalExists={_getTankPhysicalExists}
                    _createErrorMessage={_createErrorMessage}
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages]}
              successMessages={[]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
            <div className="cardAlert">
              <ListModal
                setId={setTankPhysical}
                dataList={_tankPhysicalList}
                showListModal={showTankPhysicalList}
                setShowModal={setShowTankPhysicalList}
                title="List Of Tank Physicals"
                columns={[
                  { field: "id", headerName: "TankPhysical ID", width: 150 },
                  {
                    field: "physicalDocTypeId",
                    headerName: "Physical Doc Type",
                    width: 250,
                  },
                  { field: "date", headerName: "Date", width: 350 },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TankPhysicalOverview;
