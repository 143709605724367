// class Button {
//   constructor(text, onClick) {
//     this.text = text;
//     this.onClick = onClick;
//   }
// }
// const _button = (...params) => new Button(...params);

//Replaced the above code with just a JS funtion below
function _button(text, onClick, style) {
  return { text: text, onClick, style };
}

export const numpadArray = [
  _button(1),
  _button(2),
  _button(3),
  _button(4),
  _button(5),
  _button(6),
  _button(7),
  _button(8),
  _button(9),
  _button("Clear", ({ setInputValue }) => setInputValue("")),
  _button(0),
  _button("Ok"),
  _button("A-Z", ({ toggleKeyboardType }) => toggleKeyboardType()),
  _button("<-", ({ setInputValue, inputValue = "", type }) => {
    let newValueBackSpace;
    if (type == "number") {
      newValueBackSpace = Number(inputValue.toString().slice(0, -1));
    } else {
      newValueBackSpace = inputValue.substring(0, inputValue?.length - 1);
    }
    setInputValue(newValueBackSpace);
  }),

  _button("-"),
];

export const keyboardArray = [
  _button("A"),
  _button("B"),
  _button("C"),
  _button("D"),
  _button("E"),
  _button("F"),
  _button("<-", ({ setInputValue, inputValue = "" }) => {
    setInputValue(inputValue.substring(0, inputValue?.length - 1));
  }),
  _button("G"),
  _button("H"),
  _button("I"),
  _button("J"),
  _button("K"),
  _button("L"),
  _button("1-9", ({ toggleKeyboardType }) => toggleKeyboardType()),
  _button("M"),
  _button("N"),
  _button("O"),
  _button("P"),
  _button("Q"),
  _button("R"),
  _button("-"),
  _button("S"),
  _button("T"),
  _button("U"),
  _button("V"),
  _button("W"),
  _button("X"),
  _button("Clear", ({ setInputValue }) => setInputValue("")),
  _button("Y"),
  _button("Z"),
  _button(""),
  _button(""),
  _button(""),
  _button(""),
  _button("Ok"),
];
