/** @format */

import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { useFetch } from "../../../../hooks/useFetch";
import { useMessage } from "../../../../hooks/UseMessage";
import { GetFullURL } from "../../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import { LoadingContext } from "../../Context";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import Cookies from "js-cookie";
import ListModal from "../../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import KeyboardInput from "../../../../CommonComponents/keybaords/KeyboardButtonInput/index";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

function SignatureReady(props) {
  const [usedStation, setUsedStation] = useState();
  const [order, setOrder] = useState();
  const [pin, setPin] = useState();
  const [cookie, setCookie] = useState();
  const [showOrderList, setShowOrderList] = useState(false);

  const userState = useSelector((state) => state.User.user);

  const language = userState.preferredLanguage;
  const userStation = userState.stationId;

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  let navigate = useNavigate();
  const [
    _getShipDocReadySignature,
    _shipDocReadySignatureData,
    _setShipDocReadySignatureData,
    _errorShipDocReadySignatureData,
    _setErrorShipDocReadySignatureData,
    _successShipDocReadySignatureData,
    _setSuccessShipDocReadySignatureData,
    _loadingtShipDocReadySignatureData,
  ] = useFetch(GetFullURL("SignatureReady"), { method: "POST" });

  const [
    _getStationData,
    _stationData,
    ,
    _errorStationData,
    _setErrorStationData,
    _successStationData,
    _setSuccessStationData,
    _loadingStationData,
  ] = useFetch(GetFullURL("GetStation"), { method: "POST" });

  const operationLoading =
    _loadingtShipDocReadySignatureData || _loadingStationData;

  let storedCookie;

  useEffect(() => {
    let usedStation = "";
    storedCookie = Cookies.get("station");

    if (!userStation) {
      if (!storedCookie) {
        _createErrorMessage("1");
        return;
      }
    }
    usedStation = storedCookie ? storedCookie : userStation;

    setUsedStation(usedStation);
    _getStationData({ data: { stationId: usedStation } });
  }, []);

  const headerStyle = {
    fontWeight: 600,
  };

  function HandleShipDocDropDown(shipDocId) {
    if (shipDocId) {
      let navigationNextUrl = `/TerminalActivity/ShipDocSignature/${shipDocId}`;
      navigate(navigationNextUrl);
    }
  }
  //Use the context and pass the data, dropdowns
  const contextState = {
    _shipDocReadySignatureData,
    _stationData,
    station: cookie,
  };

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessShipDocReadySignatureData([]);
        _setSuccessStationData([]);
        break;
      case "error":
        _setErrorShipDocReadySignatureData([]);
        _setErrorStationData([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }
  return (
    <div className="container-fluid">
      <LoadingContext.Provider value={contextState}>
        {operationLoading && <LoadingScreen />}
        <Button
          onClick={() => {
            _getShipDocReadySignature(
              { data: { locationId: _stationData.locationId } },
              ({ data }) => {
                if (data.length === 0) {
                  _createErrorMessage("27");
                  return;
                }
                setShowOrderList(true);
              }
            );
          }}
          color="primary"
          variant="outlined"
        >
          Get BOLs Ready for Signature
        </Button>
        <div className="heroCard heroPartner" style={{ height: "auto" }}>
          <div className="card card2">
            <div className="cardHeader">
              <h2>BOL Signature</h2>
            </div>
            <div className="cardBody">
              <div className="cardInput orderInput">
                <KeyboardInput
                  value={pin ?? ""}
                  setValue={setPin}
                  placeholder="Type Driver PIN here..."
                  onChange={setPin}
                />
              </div>
              <div className="cardButton cardorder">
                <Button
                  className="w-100"
                  onClick={() => {
                    if (!pin) {
                      _createErrorMessage("17");
                      return;
                    }

                    _getShipDocReadySignature(
                      {
                        data: {
                          locationId: _stationData.locationId,
                          pin: pin,
                        },
                      },
                      ({ data: shipDcoHeaders }) => {
                        let numberEntries = shipDcoHeaders.length;

                        if (numberEntries == 1) {
                          let shipDocHeader = shipDcoHeaders[0];
                          let navigationNextUrl = `/TerminalActivity/ShipDocSignature/${shipDocHeader.id}`;
                          navigate(navigationNextUrl);
                        }

                        if (numberEntries == 0) {
                          _createErrorMessage("24");
                          return;
                        }

                        if (numberEntries > 1) {
                          _createErrorMessage("25");
                          return;
                        }
                      }
                    );
                  }}
                >
                  Proceed to Signature
                </Button>
              </div>
            </div>
            <MessageLog
              errorMessages={[
                ..._errorStationData,
                ..._errorMessages,
                ..._errorShipDocReadySignatureData,
              ]}
              successMessages={[
                ..._successStationData,
                ..._successShipDocReadySignatureData,
              ]}
              clearMessages={clearMessages}
            ></MessageLog>
          </div>
        </div>
      </LoadingContext.Provider>
      <ListModal
        setId={HandleShipDocDropDown}
        dataList={_shipDocReadySignatureData}
        showListModal={showOrderList}
        setShowModal={setShowOrderList}
        title="List Of BOL Awaiting Signature"
        columns={[
          { field: "id", headerName: "BOL Number", width: 150 },
          { field: "driverDescriptor", headerName: "Driver", width: 300 },
          { field: "materialDescriptor", headerName: "Material", width: 350 },
          { field: "startDT", headerName: "In Date/Time", width: 200 },
          { field: "inWeight", headerName: "In Weight", width: 150 },
          { field: "outWeight", headerName: "Out Weight", width: 150 },
        ]}
      />
    </div>
  );
}

export default SignatureReady;
