/** @format */

import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { useFetch } from "../../../../../hooks/useFetch";
import { useStation } from "../../../../../hooks/UseStation";
import { useMessage } from "../../../../../hooks/UseMessage";
import { weightArray, driverPinArray, leftPanelArray } from "./Fields";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../../CommonComponents/GlobalConfFiles/URLs";
import { useParams } from "react-router-dom";
import {
  ValidateConfirmChecking,
  ValidateDriverPIN,
  ValidateSetup,
} from "./ValidateShipDoc";
import ListModal from "../../../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
export default function CheckinV2(props) {
  //This checking is used for ELDO Coke
  const params = useParams();
  const fieldProcessId = params.fp;
  const navigate = useNavigate();

  const [frontendWarning, setFrontendWarning] = useState([]);
  const [serviceError, setServiceError] = useState([]);
  const [allowWeightEntry, setAllowWeightEntry] = useState(false);
  const [proceedToLoading, setProceedToLoading] = useState(false);
  const [serviceSuccess, setServiceSuccess] = useState([]);
  const [shipDoc, setShipDoc] = useState({});
  const [continueChecking, setContinueChecking] = useState(false);
  const [showPartnerList, setShowPartnerList] = useState(false);
  const [filteredPartnerList, setfilteredPartnerList] = useState([]);
  const [
    _getLoadInitialize,
    ,
    ,
    _errorLoadInitialize,
    _setErrorLoadInitialize,
    ,
    ,
    _loadingLoadInitialize,
  ] = useFetch(GetServiceFullURL("InitializeLoad"), {
    method: "POST",
    headers: {},
  });

  const [
    _deterSetup,
    _deterSetupData,
    ,
    _errorDeterSetup,
    _setErrorDeterSetup,
    ,
    ,
    _loadingSetupDeter,
  ] = useFetch(GetFullURL("DetermineSetup"), { method: "POST" }, true);

  const [
    _getPartnerList,
    _partnerList,
    _setParnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    ,
    ,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("GetPartnerPerPINandType"), { method: "POST" });

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" });

  const [
    _stationInfo,
    ,
    ,
    _getStationOperation,
    _stationOperation,
    _stationMessages,
    _setStationMessages,
  ] = useStation();
  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
    _createSuccessMessage,
    _successMessages,
    _setSuccessMessages,
  ] = useMessage();

  useEffect(() => {
    //This one 1st things we do, determine the terminal setup based on:
    //locationId, FieldProcessId and MoT
    if (!_stationInfo?.id) {
      return;
    }
    _deterSetup(
      {
        data: {
          LocationId: _stationInfo.locationId,
          FieldProcessId: params.fp,
          TransportationModeId: "TR",
        },
      },
      ({ data: termSetupd }) => {
        if (!termSetupd) {
          _createErrorMessage("97", params.fp, _stationInfo.locationId);
        }
      }
    );
  }, [_stationInfo]);

  useEffect(() => {
    let setupData = _deterSetupData.setupData;

    if (!setupData) {
      return;
    }

    setShipDoc((prevState) => {
      return {
        ...prevState,
        weightUnitId: _stationInfo.preactUnitId,
        preactWeight: _stationInfo.preactWeight,
        locationId: _stationInfo.locationId,
        currentStation: _stationInfo.id,
        fieldProcessId: fieldProcessId,
        shipDocTypeId: "TBOL",
        referenceDoc: setupData.id,
        referenceDocTypeId: setupData.orderTypeId,
        referenceDocCategoryId: setupData.orderCategoryId,
        incotermId: setupData.incotermId,
        incotermExtension: setupData.incotermExtension,
        transportationModeId: setupData.transportationModeId,
        businessObjectId: "TBOL",
        dataOriginId: "Terminal",
      };
    });
  }, [_deterSetupData]);

  useEffect(() => {
    //if the driver has been supplied, then continue with checking
    //process and enter max weight
    if (shipDoc.driverDescriptor) {
      setAllowWeightEntry(true);
    }

    //if weight is more than 1000lb then we need to show continue checking button
    if (shipDoc.maxWeight > 10000) {
      setProceedToLoading(true);
    }
  }, [shipDoc.driverDescriptor, shipDoc.maxWeight]);

  useEffect(() => {
    //This use effect is filter the list based on locationId,
    //if the result of filter is empty arrau issue and error message and close partner list modal
    let newPartnerList = [];
    if (!Array.isArray(_partnerList)) {
      return;
    }

    if (_partnerList.length === 0) {
      return;
    }
    // filter the list based on location id and update the state
    newPartnerList = _partnerList.filter(
      (x) => x.locationId === _stationInfo.locationId
    );

    if (newPartnerList.length === 0) {
      _createErrorMessage("18");
    }

    if (newPartnerList.length === 1) {
      setShipDoc((current) => {
        return {
          ...current,
          driverDescriptor: newPartnerList[0].partnerDescriptor,
          driverId: newPartnerList[0].id,
        };
      });
      setContinueChecking(true);
      return;
    }

    if (newPartnerList.length > 1) {
      setfilteredPartnerList(newPartnerList);
      setShowPartnerList(true);
      return;
    }
  }, [_partnerList]);

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }

    const newValueObject = { [name]: newValue };
    setShipDoc((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const fieldsState = {
    dataSource: shipDoc,
    materiaDataSource: _deterSetupData.setupData?.orderItemsComm?.[0],
    onChange,
  };

  const leftPanelFields = leftPanelArray(fieldsState);
  const weightFields = weightArray(fieldsState);
  const driverFields = driverPinArray(fieldsState);

  const mergedFieldData = (field) => {
    return { ...field };
  };

  const operationLoading =
    _loadingSaveShipDoc || _loadingPartnerList || _loadingLoadInitialize;

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveShipDoc([]);
        setServiceSuccess([]);
        break;
      case "error":
        _setErrorSaveShipDoc([]);
        setServiceError([]);
        _setErrorLoadInitialize([]);
        _setErrorPartnerList([]);
        _setStationMessages([]);
        _setErorMessages([]);
        break;
      case "warning":
        setFrontendWarning([]);
        break;
      default:
        break;
    }
  }

  function validatePIN() {
    let validationPinResult = ValidateDriverPIN(shipDoc, _createErrorMessage);

    if (!validationPinResult) {
      return;
    }
    //When pressing the validate pin, clear the driver info
    setShipDoc((current) => {
      return {
        ...current,
        driverDescriptor: "",
        driverId: "",
      };
    });
    _getPartnerList(
      {
        data: {
          partnerTypeId: "DRIVER",
          Pin: shipDoc.pin,
        },
      },
      ({ data: driverList }) => {
        if (driverList.length === 0) {
          _createErrorMessage("18");
          return;
        }
      }
    );
  }

  function confirmCheckin() {
    let shipDocData = shipDoc;
    //This is sent to service in order help with simultaion mode
    let emulationBeginningWeightType = "EMPTY";

    //Calculate cut-off weight to be supplied to service
    shipDocData.cutoffWeight = shipDocData.maxWeight - shipDocData.preactWeight;

    const setupItems = _deterSetupData.setupData.orderItemsComm || [];

    let validationConfirmResult = ValidateConfirmChecking(
      shipDocData,
      _createErrorMessage
    );

    if (!validationConfirmResult) {
      return;
    }

    //validate terminal setup
    let validationTSResult = ValidateSetup(setupItems, _createErrorMessage);

    if (!validationTSResult) {
      return;
    }

    let newShipDocItems = [];
    let newShipDocItem = {};

    setupItems.forEach((setupItem) => {
      //Set shipdoc item
      newShipDocItem.itemNumber = setupItem.itemNumber;
      //Set shipDoc ref item
      newShipDocItem.ReferenceItem = setupItem.itemNumber;
      //Set material id
      newShipDocItem.materialId = setupItem.materialId;
      //Set planned qty, no need for planne
      newShipDocItem.PlannedQuantity = setupItem.Quantity;
      newShipDocItem.PlannedUnitId = setupItem.UnitId;
      newShipDocItems.push(newShipDocItem);
    });

    //load start date/end (loadEnDT, loadStDT) is set initially at check-in and finally at signature
    let shipDataforSave = {
      ...shipDocData,
      checkedStatus: "X",
      checkedStDT: moment().format(),
      checkedEnDT: moment().format(),
      statusId: "CHECKINSHDOC",
      shipDocDate: moment().format(),
      //This date is set at checking and at signature stage
      startDT: moment().format(),
      terminalActivityinProcess: true,
      weightUnitId: "LB",
      currentStation: _stationInfo.id,
      shippingDocItemsComm: newShipDocItems,
    };

    //Add Item Data
    _saveShipDoc({ data: shipDataforSave }, ({ data: newShipDocData }) => {
      if (newShipDocData.id == null) {
        return;
      }

      setShipDoc(newShipDocData);

      _getLoadInitialize(
        {
          data: {
            shipDoc: newShipDocData.id,
            midWeight: newShipDocData.midWeight,
            cutoffWeight: newShipDocData.cutoffWeight,
            scaleMode: "Loading",
            emulationBeginningWeightType,
          },
        },
        (reponse) => {
          if (reponse.success) {
            navigate(`/TerminalActivity/LoadingMT/${newShipDocData.id}`);
          } else {
            setServiceError((current) => {
              return [...current, reponse.message];
            });
            return;
          }
        }
      );
    });
  }

  const handelListSelect = (id) => {
    let partner = _partnerList.find((x) => x.id === id);

    if (partner) {
      setShipDoc((current) => {
        return {
          ...current,
          driverDescriptor: partner.partnerDescriptor,
          driverId: partner.id,
        };
      });
      setContinueChecking(true);
    }
  };
  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-lg-3 col-sm-12 col-xs-12" id="leftPanel">
              <div className="card" style={{ maxWidth: "100%" }}>
                <div className="cardHeader">
                  <h2>Check-in</h2>
                </div>
                <div className="cardBody">
                  <div className="cardInput">
                    {leftPanelFields.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field)}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 col-xs-12" id="rightPanel">
              <div className="card card2" style={{ maxWidth: 980, margin: 0 }}>
                <div className="cardBody pt-3">
                  <div className="cardInput cardInput2">
                    {driverFields.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field)}
                        key={index}
                      />
                    ))}
                  </div>
                  <div className="cardButton">
                    <Button
                      className="w-100 mb-3"
                      style={{ maxWidth: "250px" }}
                      onClick={validatePIN}
                    >
                      Validate Driver PIN
                    </Button>
                  </div>
                  <div className="cardInput cardInput2">
                    {allowWeightEntry ? (
                      <div className="CR">
                        <div className="cardInput">
                          {weightFields.map((field, index) => (
                            <FieldsReducer
                              field={mergedFieldData(field)}
                              key={index}
                            />
                          ))}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="cardButton">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px 0px",
                      }}
                    >
                      {proceedToLoading ? (
                        <div className="ProceedToLoading me-3">
                          {" "}
                          <Button
                            className="w-auto"
                            style={{
                              maxWidth: "250px",
                              background: "#08bd55",
                              borderColor: "#08bd55",
                            }}
                            onClick={confirmCheckin}
                          >
                            Confim Checkin
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                      <Button
                        className="w-auto"
                        style={{
                          background: "#DA1212",
                          borderColor: "#DA1212",
                        }}
                        onClick={() => {
                          navigate("/ELDOScaleOperations");
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <ListModal
                setId={handelListSelect}
                dataList={filteredPartnerList}
                showListModal={showPartnerList}
                setShowModal={setShowPartnerList}
                title="List of Drivers"
                columns={[
                  { field: "id", headerName: "Driver ID", width: 150 },
                  {
                    field: "name",
                    headerName: "Driver Name",
                    width: 250,
                  },
                ]}
              />
            </div>

            <div className="col-12 mt-3">
              <MessageLog
                errorMessages={[
                  ..._errorPartnerList,
                  ..._errorSaveShipDoc,
                  ...serviceError,
                  ..._errorLoadInitialize,
                  ..._stationMessages,
                  ..._errorMessages,
                ]}
                successMessages={[..._successSaveShipDoc, ...serviceSuccess]}
                warningMessages={[...frontendWarning]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
