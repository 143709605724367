exports.InvoiceType = [
    {
      id: "id",
      name: "id",
      type: "text",
      value: "",
      label: "Id",
      key: "true",
      length: 50
    },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      value: "",
      label: "UniversalDescriptor",
      length: 50,
    },
    {
        id: "businessObjectId",
        name: "businessObjectId",
        type: "text",
        value: "",
        label: "Business Object Id",
        length: 12,
        ddid: "businessObjectId",
      },
      {
        id: "defaultGroupId",
        name: "defaultGroupId",
        type: "text",
        value: "",
        label: "Default Group Id",
        length: 12,
        ddid: "defaultGroupId",
      },
      {
        id: "numberRangeOptions",
        name: "numberRangeOptions",
        type: "number",
        value: "",
        label: "NumberRangeOptions",
        ddid: "DocTypeNumberRangeOptions",
      },
      {
        id: "numberRangeId",
        name: "numberRangeId",
        type: "text",
        value: "",
        label: "Invoice Number Range",
        length: 12,
        ddid: "invoiceNumberRangeId",
      },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
  ];
  