export const partnerRelationGridFields = [
  {
    id: "assignmentId",
    label: "id",
  },

  {
    id: "relatedPartnerId",
    label: "Partner ID",
  },

  {
    id: "code",
    label: "Code",
  },
  {
    id: "partnerRole",
    label: "Role",
  },
  {
    id: "name",
    label: "Partner Name ",
  },
  {
    id: "streetAddress",
    label: "St Address",
  },
  {
    id: "city",
    label: "City",
  },
  {
    id: "state",
    label: "State",
  },
];
