export const driverArray = ({ materiaDataSource, dataSource, onChange }) => [
  {
    id: "pin",
    name: "pin",
    type: "number",
    label: "Driver PIN",
    tag: "inputKeyboard",
    placeHolder: "Type Max Driver PIN Here...",
    dataSource,
    onChange,
  },
];

export const sideBarArray = ({
  materiaDataSource,
  dataSource,
  onChange,
  weightTypeOptions,
}) => [
  {
    id: "materialDescriptor",
    name: "materialDescriptor",
    type: "text",
    label: "Material",
    dataSource: materiaDataSource,
    disabled: true,
  },
  {
    id: "driverDescriptor",
    name: "driverDescriptor",
    type: "text",
    label: "Driver",
    dataSource,
    onChange,
    disabled: true,
  },
];
