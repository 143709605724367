import React, { useState,useEffect } from "react";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../../hooks/UseMessage";

export default function GlobalAppData(props) {
  let navigate = useNavigate();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [
    _requestData,
    _data,
    ,
    _errorData,
    _setErrorData,
    _successData,
    _setSuccessData,
    _loadingData,
  ] = useFetch(GetFullURL("GetGlobalAppData"), { method: "POST" });


  const operationLoading = _loadingData;

  useEffect(()=>{
    _requestData();
  },[])

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessData([]);
        break;
      case "error":
        _setErorMessages([]);
        _setErrorData([]);
        break;
      default:
        break;
    }
  }

  let tablesInCache = [];
  function dispalayArray(value) {
  
    if(value != undefined){
      tablesInCache += ` ${value} ,`
    }
  }
  _data.tablesInCache?.forEach(dispalayArray);

  console.log("DATA",_data)
 const fontStyle = {
  fontSize: "24px",
  fontFamily: "Verdana",

 }
 const h1Style = {
  fontWeight: "500"
 }

  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                  <div className="cardHeader">
                    <h2>Global App Data</h2>
                  </div>
                  <div className="cardBody">
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{
                          width: "32%",
                        }}
                        onClick={() => {
                          _requestData();
                        }}
                      >
                        Refresh
                      </Button>
                      <Button
                        onClick={() => {
                          let navigationNextUrl = `/BasicSystem`;
                          navigate(navigationNextUrl);
                        }}
                        style={{
                          width: "32%",
                          background: "#DA1212",
                          borderColor: "#DA1212",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
                </div>
                <h1 style={h1Style}>Number Failed ERP Calls : <span style={fontStyle}>{_data.numberFailedERPCalls}</span> </h1>
                <h1 style={h1Style}>Tables in Cahce : <span style={fontStyle}>{tablesInCache}</span> </h1>
            <div className="col-12 mt-4">
               <MessageLog
                errorMessages={[..._errorData,..._errorMessages]}
                successMessages={[..._successData]}
                clearMessages={clearMessages}
                ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
