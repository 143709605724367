import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function RenderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const TabPannel = ({ value, handleChange, labels = [], components = [] }) => {
  return (
    <React.Fragment>
      {/* tabs header navigation */}
      <section className="heroTabs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                className="tabsColor"
              >
                {labels.map((label, index) => (
                  <Tab label={label} key={index} />
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      {/* tabs contents */}
      <section className="heroCard" style={{ height: "auto", paddingTop: 30 }}>
        <div className="container-fluid">
          {components.map((Component, index) => (
            <RenderTab value={value} index={index} key={index}>
              <Component />
            </RenderTab>
          ))}
        </div>
      </section>
    </React.Fragment>
  );
};

export default TabPannel;
