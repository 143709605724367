import React from "react";
export default function tableRecordButtons({
  tableRecord,
  handleButtonClickList,
}) {
  return (
    <td
      className={"GridColumnContent"}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <button
        type="button"
        className="Button ButtonDisplay"
        data-toggle="modal"
        data-backdrop="static"
        data-op="dis"
        data-id={tableRecord.id}
        onClick={handleButtonClickList}
        style={{
          marginRight: 5,
        }}
      ></button>
      <button
        type="button"
        className="Button ButtonChange"
        data-toggle="modal"
        data-backdrop="static"
        data-op="cha"
        data-id={tableRecord.id}
        onClick={handleButtonClickList}
        style={{
          marginRight: 5,
        }}
      ></button>
      <button
        type="button"
        className="Button ButtonCopy"
        data-toggle="modal"
        data-backdrop="static"
        data-op="cpy"
        data-id={tableRecord.id}
        onClick={handleButtonClickList}
        style={{
          marginRight: 5,
        }}
      ></button>
      <button
        type="button"
        className="Button ButtonDelete"
        data-toggle="modal"
        data-backdrop="static"
        data-op="del"
        data-id={tableRecord.id}
        onClick={handleButtonClickList}
      ></button>
    </td>
  );
}
