export const countryFieldsArray = ({ dropDowns }) => [
  {
    id: "countryId",
    name: "countryId",
    type: "text",
    label: "Country",
    tag: "select",
    options: dropDowns?.countries || [],
  },
];


export const USAFieldsArray = ({ dropDowns, dataSource }) => [
  {
    id: "stateId",
    name: "stateId",
    type: "text",
    label: "State",
    tag: "select",
    options: dropDowns?.states || [],

  },
  {
    id: "city",
    name: "city",
    type: "text",
    label: "City",
  },
  {
    id: "streetAddress",
    name: "streetAddress",
    type: "text",
    label: "Street Address",
  },
  {
    id: "streetAddress1",
    name: "streetAddress1",
    type: "text",
    label: "Street Address 1",
  },
  {
    id: "postalCode",
    name: "postalCode",
    type: "text",
    label: "Postal Code",
  },
  {
    id: "splcCode",
    name: "splcCode",
    type: "text",
    label: "Splc Code",
  },
];

export const internationalFieldsArray = ({ dropDowns, dataSource }) => [
  {
    id: "city",
    name: "city",
    type: "text",
    label: "City",
  },
  {
    id: "regionId",
    name: "regionId",
    type: "text",
    label: "Region",
    tag: "select",
    options:
      dropDowns?.regions?.filter(
        (option) => option?.filter1 === dataSource?.["countryId"]
      ) || [],
  },
  {
    id: "streetAddress",
    name: "streetAddress",
    type: "text",
    label: "Street Address",
  },
  {
    id: "streetAddress1",
    name: "streetAddress1",
    type: "text",
    label: "Street Address 1",
  },
  {
    id: "postalCode",
    name: "postalCode",
    type: "text",
    label: "Postal Code",
  },
];
