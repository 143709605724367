export const mainDataArray = ({ dropDowns }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Inventory Document ID",
    key: true,
    disabled: true,
  },
  {
    id: "companyId",
    name: "companyId",
    type: "text",
    label: "Company",
    tag: "select",
    options: dropDowns?.companies || [],
  },

  {
    id: "financialDocTypeId",
    name: "financialDocTypeId",
    type: "text",
    label: "Financial Document Type",
    tag: "select",
    options: dropDowns?.financialDocTypes || [],
  },
];

export const dateDataArray = ({ dropDowns }) => [
  {
    id: "financialDocDate",
    name: "financialDocDate",
    type: "datetime",
    label: "Financial Document Date",
  },
  {
    id: "postingDate",
    name: "postingDate",
    type: "datetime",
    label: "Posting Date",
  },
];
export const amountDataArray = ({ dropDowns }) => [
  {
    id: "amount",
    name: "amount",
    type: "number",
    label: "Amount",
  },
  {
    id: "currencyId",
    name: "currencyId",
    label: "Currency",
    tag: "select",
    options: dropDowns?.currencies || [],
  },
];



