import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { BillOfMaterialContext } from "../Contex";
import { MuiButon } from "../../../../CommonComponents/MuiButton";



export default function BillOfMaterialOperationHeader({
  _loadingSaveBillOfMaterialData,
}) {
  const {
    operation,
    _setBillOfMaterialData,
    _billOfMaterialData,
    _saveBillOfMaterialData,
  } = useContext(BillOfMaterialContext);
  

  const handleSubmit = () => {
    _saveBillOfMaterialData(
      { data: _billOfMaterialData },
      ({ data: newBillOfMaterialData }) => {
        _setBillOfMaterialData(newBillOfMaterialData);
      }
    );
  };

  return (
    <Paper
    className="py-2 px-2 d-flex align-items-center justify-space-between customPaper"

  >
    <TopPanel />
    <MuiButon
      title="Save"
      loading={_loadingSaveBillOfMaterialData}
      onClick={handleSubmit}
      disabled={operation === "Display"}
    />
  </Paper>
  );
}
