import { Button, CircularProgress } from "@mui/material";
import React from "react";


export const MuiButon = ({ loading, title = "Add btn Text", ...props }) => {

  return (
    <Button
      size="large"
      variant="contained"
      {...props}
      style={{
        borderRadius: 10,
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      }}
    
    >
      {loading ? <CircularProgress size={18} /> : <span>{title}</span>}
    </Button>
  );
};
