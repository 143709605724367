import React from "react";
import Button from '@mui/material/Button';
import buttonarrow from "../../Assets/arrow.svg";
export default function index({ clickHandler, buttonText }) {
  return (
    <Button onClick={clickHandler} className="btn btn-success w-100 my-4">
      {buttonText}
      <span className="arrow">
        <img src={buttonarrow} alt="" />
      </span>
    </Button>
  );
}
