exports.OrderStatus = [
    {
      id: "id",
      name: "id",
      type: "text",
      value: "",
      label: "Id",
      key: "true",
      length: 12,
    },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      value: "",
      label: "Universal Descriptor",
      length: 50,
    },
    {
      id: "statusTypeId",
      name: "statusTypeId",
      type: "text",
      value: "",
      label: "Status Type Id",
      length: 12,
    },
    {
      id: "statusUseId",
      name: "statusUseId",
      type: "text",
      value: "",
      label: "Status Use Id",
      length: 12,
    },
    {
      id: "statusClassId",
      name: "statusClassId",
      type: "text",
      value: "",
      label: "Status Class Id",
      length: 12,
    },
    {
      id: "isValidationRelevant",
      name: "isValidationRelevant",
      type: "boolean",
      value: "",
      label: "Is Validation Relevant",
    },
    {
      id: "isActualizationRelevant",
      name: "isActualizationRelevant",
      type: "boolean",
      value: "",
      label: "Is Actualization Relevant",
    },
    {
      id: "isOutputRelevant",
      name: "isOutputRelevant",
      type: "boolean",
      value: "",
      label: "Is Output Relevant",
    },
  
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "datetime",
      label: "ModifiedOn",
      displayonly: "true",
      value: " ",
    },
  ];
  