/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";
import { useMessage } from "../../../hooks/UseMessage";
import OverviewInput from "../../../CommonComponents/InitialInput/OverviewInput";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import ListModal from "../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../../CommonComponents/CircleButton";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import OverviewButtons from "./Components/OverviewButtons";
import cardlogo from "../../../Assets/cardLogo.svg";

export default function AdminFunctionOverview(props) {
  let navigate = useNavigate();
  const [tankPhysicalParam, setTankPhysicalParam] = useState();
  const [showTankPhysicalsList, setShowTankPhysicalsList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [
    _deleteTankPhysical,
    ,
    _setDeleteTankPhysicalData,
    _errorDeleteTankPhysicalData,
    _setErrorDeleteTankPhysicalData,
    _successDeleteTankPhysicalData,
    _setSuccessDeleteTankPhysicalData,
    _loadingDeleteTankPhysicalData,
  ] = useFetch(GetFullURL("DeleteTankPhysical"), { method: "POST" });

  const [
    _getTankPhysicalsList,
    _tankPhysicalsList,
    ,
    ,
    ,
    ,
    ,
    _loadingTankPhysicalsList,
  ] = useFetch(GetFullURL("GetAllTankPhysicalsForDelete"), { method: "POST" });

  const [_tankPhysicalExist, , , , , , , _loadingTankPhysicalExist] = useFetch(
    GetFullURL("TankPhysicalExists"),
    { method: "POST" }
  );

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessDeleteTankPhysicalData([]);

        break;
      case "error":
        _setErorMessages([]);
        _setErrorDeleteTankPhysicalData([]);

        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading =
    _loadingTankPhysicalsList || _loadingTankPhysicalExist;

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>TankPhysical Admin Overview</h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setTankPhysicalParam}
                    document={tankPhysicalParam}
                    tankPhysicalRadius={true}
                    placeholder={`Enter or Choose Id`}
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of TankPhysicals`}
                    clickHandler={() => {
                      _getTankPhysicalsList({}, () => {
                        setShowTankPhysicalsList(true);
                      });
                    }}
                  />
                  <OverviewButtons
                    tankPhysical={tankPhysicalParam}
                    navigate={navigate}
                    _tankPhysicalExist={_tankPhysicalExist}
                    _createErrorMessage={_createErrorMessage}
                    _deleteTankPhysical={_deleteTankPhysical}
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[
                ..._errorMessages,
                ..._errorDeleteTankPhysicalData,
              ]}
              successMessages={[..._successDeleteTankPhysicalData]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
            <div className="cardAlert"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setTankPhysicalParam}
              dataList={_tankPhysicalsList}
              showListModal={showTankPhysicalsList}
              setShowModal={setShowTankPhysicalsList}
              title="List Of TankPhysicals"
              columns={[
                { field: "id", headerName: "TankPhysical ID", width: 150 },
                {
                  field: "physicalDocTypeId",
                  headerName: "Physical Doc Type",
                  width: 250,
                },
                { field: "date", headerName: "Date", width: 350 },
              ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
