/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";
import { useMessage } from "../../../hooks/UseMessage";
import OverviewInput from "../../../CommonComponents/InitialInput/OverviewInput";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import ListModal from "../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../../CommonComponents/CircleButton";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import OverviewButtons from "./Components/OverviewButtons";
import cardlogo from "../../../Assets/cardLogo.svg";

export default function AdminFunctionOverview(props) {

  let navigate = useNavigate();
  const [tankParam, setTankParam] = useState();
  const [showTanksList, setShowTanksList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  
  const [
    _deleteTank,
    ,
    _setDeleteTankData,
    _errorDeleteTankData,
    _setErrorDeleteTankData,
    _successDeleteTankData,
    _setSuccessDeleteTankData,
    _loadingDeleteTankData,
  ] = useFetch(GetFullURL("DeleteTank"), { method: "POST" });

  const [_getTanksList, _TanksList, , , , , , _loadingTanksList] =
    useFetch(GetFullURL("GetAllTanksForDelete"), { method: "POST" });

  const [_tankExist, , , , , , , _loadingTankExist] = useFetch(GetFullURL("TankExists"),{ method: "POST" });

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessDeleteTankData([]);
      
        break;
      case "error":
        _setErorMessages([]);
        _setErrorDeleteTankData([]);
        
        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading =  _loadingTanksList  || _loadingTankExist ;

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../../Assets/icon/Admin.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Tank Admin Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setTankParam}
                    document={tankParam}
                    placeholder={`Enter or Choose Id`}
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of Tanks`}
                    clickHandler={() => {
                      _getTanksList({},() => {setShowTanksList(true);});                  
                    }}
                  />
                  <OverviewButtons
                    tank={tankParam}
                    navigate={navigate}
                    _tankExist={_tankExist}
                    _createErrorMessage={_createErrorMessage}
                    _deleteTank={_deleteTank}
              
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages, ..._errorDeleteTankData ]}
              successMessages={[..._successDeleteTankData]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
             <div className="cardAlert">
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setTankParam}
              dataList={_TanksList}
              showListModal={showTanksList}
              setShowModal={setShowTanksList}
              title="List Of Tanks"
              columns={[
                { field: "id", headerName: "Tank ID", width: 100 },
                { field: "code", headerName: "Code", width: 250 },
                { field: "universalDescriptor", headerName: "Name", width: 350 },
                {field: "tankFarmId",headerName: "Tank Farm",width: 150,},
                {field: "tankGroupId",headerName: "Tank Group",width: 150,},
                ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

