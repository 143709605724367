import React, { useState, useEffect } from "react";
import Card from "../../Components/Card";
import { ReactComponent as ReactShipDocOverviewCard } from "../../../Assets/NewIconsForCards/ShipDocOverview.svg";
import { ReactComponent as SearchIcon } from "../../../Assets/NewIconsForCards/SearchIcon.svg";
import { useUser } from "../../../hooks/useUser";

const CardsArray = [
  {
    title: "Bill-Of-Lading Overview",
    text: "",
    Icon: ReactShipDocOverviewCard,
    link: "/ShipDocOverview",
    roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
  },

  {
    title: "Bill-Of-Lading Workbench",
    text: "",
    Icon: SearchIcon,
    link: "/ShipDocWorkbench",
    roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
  },
  {
    title: "Bill-Of-Lading Workbench New",
    text: "",
    Icon: SearchIcon,
    link: "/ShipDocWorkbenchN",
    roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
  },
];

export default function MasterData() {
  const [_user, ,] = useUser();
  const [newCards, setNewCards] = useState([]);
  useEffect(() => {
    if (!_user.roles) {
      return CardsArray;
    }
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card?.roles?.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {newCards.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
