import React from "react";
import Card from "../../../Components/Card";
import { useNavigate } from "react-router-dom";
import { ReactComponent as VehicleIcon } from "../../../../Assets/NewIconsForCards/VehicleIcon.svg";
import { ReactComponent as ReactAdminFunctionCard } from "../../../../Assets/NewIconsForCards/UserSettingsIcon.svg";



const Arr = [
    {
        title: "Vehicle General",
        text: "",
        Icon: VehicleIcon,
        link: "/VehicleGeneral",
      },
      {
        title: "Admin Function",
        text: "",
        Icon: ReactAdminFunctionCard,
        link: "/VehicleAdmin",
      },
];

export default function VehicleOverview() {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {Arr.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
