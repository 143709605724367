export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Material ID",
    key: true,
  },
  {
    id: "name",
    name: "name",
    type: "text",
    label: "Name",
  },
  {
    id: "code",
    name: "code",
    type: "text",
    label: "Code",
  },
  {
    id: "materialTypeId",
    name: "materialTypeId",
    tag: "select",
    options: dropDowns?.materialTypes || [],
    type: "text ",
    label: "Material Type",
  },
  {
    id: "materialGroupId",
    name: "materialGroupId",
    tag: "select",
    options: dropDowns?.materialGroups || [],
    type: "text ",
    label: "Material Group",
  },
  {
    id: "water",
    name: "water",
    type: "checkbox",
    tag: "checkbox",
    value: "",
    label: "Water",
  },
];

export const unitsArray = ({ dropDowns, dataSource }) => [
  {
    id: "baseUnitId",
    name: "baseUnitId",
    tag: "select",
    label: "Base Unit",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "salesUnitId",
    name: "salesUnitId",
    tag: "select",
    label: "Sales Unit",
    options: dropDowns?.qtyUnits || [],
  },
];
