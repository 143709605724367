export const mainArray = ({ dropDowns }) => [
  {
    id: "partnerTypeId",
    name: "partnerTypeId",
    type: "text",
    label: "Partner Type",
    options: dropDowns?.partnerTypes || [],
    tag: "select",
  },
  {
    id: "partnerName",
    name: "partnerName",
    type: "text",
    label: "Partner Name 1",
  },
  {
    id: "partnerName1",
    name: "partnerName1",
    type: "text",
    label: "Partner Name 2",
  },
  {
    id: "partnerName2",
    name: "partnerName2",
    type: "text",
    label: "Partner Name 3",
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    label: "Location",
    options: dropDowns?.locations || [],
    tag: "select",
  },
  {
    id: "pin",
    name: "pin",
    type: "number",
    label: "PIN",
  },
  {
    id: "city",
    name: "city",
    type: "text",
    label: "City",
  },
  {
    id: "stateId",
    name: "stateId",
    type: "text",
    label: "State",
    options: dropDowns?.states || [],
    tag: "select",
  },
];

