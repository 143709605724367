import React, { useState, useEffect } from "react";
import { OrderContext } from "./Contex";
import MessageLog from "./../../../CommonComponents/Messages/MessageLog";
import HomeTab from "./Main/index";
import Items from "./../OrderMain/Items/Items";
import Admin from "./../OrderMain/Admin/Index";
import Partners from "./Partners/Index";
import Outbound from "./Outbound/Outbound";
import OrderHeader from "./Header/Index";

import { useFetch } from "./../../../hooks/useFetch";
import { useMessage } from "./../../../hooks/UseMessage";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Tabpannel from "../../../Business Processes/Components/TabPannel";
import { useParams } from "react-router-dom";
import _ from "underscore";

const HomeTabWithItems = () => (
  <React.Fragment>
    <HomeTab />
    <Items />
  </React.Fragment>
);

function Index(props) {
  const [value, setValue] = useState(0);
  const [operation, setOperation] = useState("");
  const [orderId, setOrderId] = useState("");
  const params = useParams();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [
    _getOrderData,
    _orderData,
    _setOrderData,
    _errorOrderData,
    _setErrorOrderData,
    _successOrderData,
    _setSuccessOrderData,
    _loadingOrderData,
  ] = useFetch(GetFullURL("GetOrder"), { method: "POST" });
  
  const [
    _getPartnerList,
    _partnerList,
    ,
    _errorPartnerList,
    _setErrorPartnerList,
    _successPartnetList,
    _setSuccessPartnerList,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("PartnerList"), { method: "POST" });

  const [
    _getLeaseList,
    _leaseList,
    ,
    _errorLeaseList,
    _setErrorLeaseList,
    _successLeaseList,
    _setSuccessLeaseList,
    _loadingLeaseList,
  ] = useFetch(GetFullURL("LeaseList"), { method: "POST" });

  const [
    _getOrderDD,
    _orderDD,
    ,
    _errorOrderDD,
    _setErrorOrderDD,
    _successOrderDD,
    _setSuccessOrderDD,
    _loadingtOrderDD,
  ] = useFetch(GetFullURL("OrderDropDowns"), { method: "POST" });

  const [
    _getMaterialList,
    _materialList,
    ,
    _errorMaterialList,
    _SetErrorMaterialList,
    _successMaterialList,
    _setSuccessMaterialList,
    _loadingMaterialList,
  ] = useFetch(GetFullURL("MaterialList"), { method: "POST" });

  //data after saving is applied to document in the save call via a callback, so need for data here
  const [
    _saveOrder,
    ,
    ,
    _errorSaveOrder,
    _setErrorSaveOrder,
    _successSaveOrder,
    _setSuccessSaveOrder,
    _loadingSaveOrder,
  ] = useFetch(GetFullURL("SaveOrder"), { method: "POST" });

  useEffect(() => {
    //when componen mounts,set ID and operation
    if (params.op) {
      setOperation(params.op);
    }
    if (params.id) {
      setOrderId(params.id);
    }
  }, []);

  useEffect(() => {
    _getOrderDD();
    _getMaterialList();
    if (orderId) {
      _getOrderData({ data: { id: orderId } });
    }
  }, [orderId]);

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;

    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    _setOrderData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const handleChange = (event, newValue) => setValue(newValue);

  //Use the context and pass the data, dropdowns
  const contextState = {
    onChange,
    operation,
    dropDowns: _orderDD || [],
    orderId,
    _orderData,
    _setOrderData,
    _getPartnerList,
    _partnerList,
    _materialList,
    _getMaterialList,
    _createErrorMessage,
    _leaseList,
    _getLeaseList,
  };

  const operationLoading =
    _loadingOrderData     ||
    _loadingMaterialList  ||
    _loadingtOrderDD      ||
    _loadingPartnerList   ||
    _loadingSaveOrder     || 
    _loadingLeaseList;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveOrder([]);
        _setSuccessOrderData([]);
        _setSuccessOrderDD([]);
        _setSuccessMaterialList([]);
        _setSuccessPartnerList([]);
        _setSuccessLeaseList([]);
        break;
      case "error":
        _setErrorOrderData([]);
        _setErrorOrderDD([]);
        _SetErrorMaterialList([]);
        _setErrorSaveOrder([]);
        _setErrorPartnerList([]);
        _setErorMessages([]);
        _setErrorLeaseList([]);
        break;
      case "warning":
      default:
        break;
    }
  }
  return (
    <OrderContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <OrderHeader
        _loadingSaveOrder={_loadingSaveOrder}
        _saveOrder={_saveOrder}
      />
      <Tabpannel
        value={value}
        handleChange={handleChange}
        labels={["Home", "Items","Outbound", "Partners", "Admin", "Log"]}
        components={[HomeTabWithItems, Items,Outbound, Partners, Admin]}
      />
      <MessageLog
        errorMessages={[
          ..._errorOrderDD,
          ..._errorOrderData,
          ..._errorMaterialList,
          ..._errorSaveOrder,
          ..._errorPartnerList,
          ..._errorMessages,
          ..._errorLeaseList,
        ]}
        successMessages={[
          ..._successOrderDD,
          ..._successOrderData,
          ..._successMaterialList,
          ..._successSaveOrder,
          ..._successPartnetList,
          ..._successLeaseList,
        ]}
        warningMessages={[]}
        clearMessages={clearMessages}
      ></MessageLog>
    </OrderContext.Provider>
  );
}

export default Index;
