export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "capacity",
    name: "capacity",
    label: "Capacity",
    type: "number",
  },
  {
    id: "capacityUnitId",
    name: "capacityUnitId",
    label: "Capacity Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "length",
    name: "length",
    label: "length",
    type: "number",
  },
  {
    id: "lengthUnitId",
    name: "lengthUnitId",
    label: "Length Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.lengthUnits || [],
  },
  {
    id: "height",
    name: "height",
    label: "Height",
    type: "number",
  },
  {
    id: "heightUnitId",
    name: "heightUnitId",
    label: "Height Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.lengthUnits || [],
  },
];

export const weightDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "tareWeight",
    name: "tareWeight",
    label: "Tare Weight",
    type: "number",
  },
  {
    id: "tareWeightUnitId",
    name: "tareWeightUnitId",
    label: "Tare Weight Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "grossWeight",
    name: "grossWeight",
    label: "Gross Weight",
    type: "number",
  },
  {
    id: "grossWeightUnitId",
    name: "grossWeightUnitId",
    label: "Gross Weight Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
];
export const latLongDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "latitude",
    name: "latitude",
    label: "Latitude",
    type: "number",
  },
  {
    id: "longitude",
    name: "longitude",
    label: "Longitude",
    type: "number",
  },
];
