exports.MaterialNumberRange = [
    {
      id: "id",
      name: "id",
      type: "text",
      value: "",
      label: "Id",
      key: "true",
      length: 12,
    },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      value: "",
      label: "UniversalDescriptor",
      length: 50,
    },
     {
      id: "fromNumber",
      name: "fromNumber",
      type: "number",
      value: "",
      label: "FromNumber",
      
    },
    {
        id: "toNumber",
        name: "toNumber",
        type: "number",
        value: "",
        label: "To Number",
        
      },
      {
        id: "currentNumber",
        name: "currentNumber",
        type: "number",
        value: "",
        label: "Current Number",
        
      },
      {
        id: "prefix",
        name: "prefix",
        type: "text",
        value: "",
        label: "Prefix",
        length: 50,
      },
      {
        id: "isExternal",
        name: "isExternal",
        type: "boolean",
        value: "",
        label: "Is External",
      },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "datetime",
      label: "ModifiedOn",
      displayonly: "true",
      value: " ",
    },
];
  