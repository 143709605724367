/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";
import OverviewInput from "../../../CommonComponents/InitialInput/OverviewInput";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import ListModal from "../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../../CommonComponents/CircleButton";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import OverviewButtons from "./Components/OverviewButtons";
import cardlogo from "../../../Assets/cardLogo.svg";
import { useMessage } from "../../../hooks/UseMessage";


export default function AdminFunctionOverview(props) {

  let navigate = useNavigate();
  const [labTestParam, setLabTestParam] = useState();
  const [showLabTestList, setShowLabTestList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [
    _deleteLabTest,
    ,
    _setDeleteLabTestData,
    _errorDeleteLabTestData,
    _setErrorDeleteLabTestData,
    _successDeleteLabTestData,
    _setSuccessDeleteLabTestData,
    _loadingDeleteLabTestData,
  ] = useFetch(GetFullURL("DeleteLabTest"), { method: "POST" });

  const [_getLabTestsList, _labTestList, , , , , , _loadingtLabTestList] =
    useFetch(GetFullURL("GetAllLabTestsForDelete"), { method: "POST" });

  const [_labTestExist, , , , , , , _loadingLabTestExist] = useFetch(GetFullURL("LabTestExists"),{ method: "POST" });


  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessDeleteLabTestData([]);
        break;
      case "error":
        _setErorMessages([]);
        _setErrorDeleteLabTestData([]);
        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading =  _loadingtLabTestList  || _loadingLabTestExist;

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                    <img src={cardlogo} alt="" />
                  </span>
                  LabTest Admin Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setLabTestParam}
                    document={labTestParam}
                    labTestRadius={true}
                    placeholder={`Enter or Choose Id`}
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of LabTests`}
                    clickHandler={() => {
                      _getLabTestsList({},() => {setShowLabTestList(true);});                  
                    }}
                  />
                  <OverviewButtons
                    labTest={labTestParam}
                    navigate={navigate}
                    _labTestExist={_labTestExist}
                    _createErrorMessage={_createErrorMessage}
                    _deleteLabTest={_deleteLabTest}
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages, ..._errorDeleteLabTestData]}
              successMessages={[..._successDeleteLabTestData]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
             <div className="cardAlert">
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setLabTestParam}
              dataList={_labTestList}
              showListModal={showLabTestList}
              setShowModal={setShowLabTestList}
              title="List Of LabTests"
              columns={[
                { field: "id", headerName: "Lab Test Id", width: 250 },
                { field: "productGroup", headerName: "ProductGroup", width: 250 },
                { field: "testDate", headerName: "Test Date", width: 350 },
                { field: "testMethodId", headerName: "Test Method", width: 350 },
                ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

