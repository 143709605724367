import { createSlice } from "@reduxjs/toolkit";

export const stationSlice = createSlice({
  name: "stationData",
  initialState: { Station: {} },
  reducers: {
    NewStationContent: (state, action) => {
      state.Station = {
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { NewStationContent } = stationSlice.actions;

export default stationSlice.reducer;
