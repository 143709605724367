export const ountboundFieldsArray = ({ dropDowns, dataSource }) => [
  {
    id: "emailAddress",
    name: "emailAddress",
    type: "text",
    label: "Email Address",
  },
  {
    id: "emailShipDoc",
    name: "emailShipDoc",
    type: "checkbox",
    label: "Enable Send BOL via Email",
    tag: "checkbox",
  },

];
