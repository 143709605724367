/** @format */
import React, { useState, useEffect, useRef } from "react";
import Button from '@mui/material/Button';
import { useFetch } from "../../../../../hooks/useFetch";
import { useStation } from "../../../../../hooks/UseStation";
import { useMessage } from "../../../../../hooks/UseMessage";
import { useUser } from "../../../../../hooks/useUser";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { LoadingContext } from "../../../Context";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import ListModal from "../../../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import KeyboardInput from "../../../../../CommonComponents/keybaords/KeyboardButtonInput/index";
import cardlogo from "../../../../../Assets/cardLogo.svg";
import { useNavigate } from "react-router-dom";
import { sideBarArray } from "./Fields";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";
function CheckinV4(props) {
  //used for Sulfur, exsiting BOL
  const [showOrderList, setShowOrderList] = useState(false);
  const [serviceError, setServiceError] = useState([]);
  const [localState, setLocalState] = useState({});
  const [pageData, setPageData] = useState({});
  const weightTypeOptions = useRef([]);
  let navigate = useNavigate();

  const [
    _stationInfo,
    _getTerminalSetup,
    _terminalSetup,
    _getStationOperation,
    _stationOperation,
    _messages,
    _setMessages,
  ] = useStation();
  const [_user] = useUser();

  let disableKeyboad = false;
  if (_user.disableKeyboard === "true" || _user.disableKeyboard === "True") {
    disableKeyboad = true;
  }

  const [
    _getShipDocInOutWeight,
    _shipDocInOutWeightData,
    ,
    _errorShipDocInOutWeight,
    _setErrorShipDocInOutWeight,
    _successShipDocInOutWeight,
    _setSuccessShipDocInOutWeight,
    _loadingShipDocInOutWeight,
  ] = useFetch(GetFullURL("InOutWeight"), { method: "POST" });

  const [
    _getLoadInitialize,
    _loadLoadInitializeData,
    _setLoadLoadInitializeData,
    _errorLoadInitialize,
    _setErrorLoadInitialize,
    ,
    ,
    _loadingLoadInitialize,
  ] = useFetch(GetServiceFullURL("InitializeLoad"), {
    method: "POST",
    headers: {},
  });

  const operationLoading = _loadingShipDocInOutWeight || _loadingLoadInitialize;

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  //Mount useEffect, cookie logic, requested weight type select, get station data
  useEffect(() => {
    weightTypeOptions.current.push(
      { value: "AUTO", label: "Auto Detemined" },
      { value: "IN", label: "In Weight" },
      { value: "OUT", label: "Out Weight" }
    );

    setLocalState({ weightType: "AUTO" });
  }, []);

  function HandleShipDocDropDownSelect(shipDocId) {
    let emulationBeginningWeightType;
    if (shipDocId) {
      const result = _shipDocInOutWeightData.find(({ id }) => id === shipDocId);
      //The required weight type be measured is determined automatically based on if the in-weight is taken
      //however, the user can choose a type type, this passed to the service and to the next page
      switch (localState.weightType) {
        case "IN":
          emulationBeginningWeightType = "EMPTY";
          break;
        case "OUT":
          emulationBeginningWeightType = "FULL";
          break;
        default:
          result.inWeightStatus === "X"
            ? (emulationBeginningWeightType = "FULL")
            : (emulationBeginningWeightType = "EMPTY");
      }

      _getLoadInitialize(
        {
          data: {
            shipDoc: shipDocId,
            midWeight: 0,
            cutoffWeight: 0,
            scaleMode: "Scaling",
            emulationBeginningWeightType,
          },
        },
        (reponse) => {
          if (reponse.success) {
            navigate(
              `/TerminalActivity/InOutWeightMT/${shipDocId}/${localState.weightType}/`
            );
          } else {
            setServiceError((current) => {
              return [...current, reponse.message];
            });
            return;
          }
        }
      );
    }
  }
  //Use the context and pass the data, dropdowns
  const contextState = {
    _shipDocInOutWeightData,
    _stationInfo,
    station: _stationInfo.id,
  };

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessShipDocInOutWeight([]);
        break;
      case "error":
        _setErrorShipDocInOutWeight([]);
        _setErorMessages([]);
        setServiceError([]);
        break;
      default:
        break;
    }
  }

  const ProceedTakeWeight = () => {
    let emulationBeginningWeightType;
    if (!pageData.pin) {
      _createErrorMessage("17");
      return;
    }

    _getShipDocInOutWeight(
      {
        data: {
          locationId: _stationInfo.locationId,
          pin: pageData.pin,
          inweightStatus: "X",
          outweightStatus: "",
        },
      },
      ({ data: relevantShipDocs }) => {
        let numberEntries = relevantShipDocs.length;

        if (numberEntries === 1) {
          let relevantShipDoc = relevantShipDocs[0];
          //The required weight type be measured is determined automatically based on if the in-weight is taken
          //however, the user can choose a type type, this passed to the service and to the next page

          switch (localState.weightType) {
            case "IN":
              emulationBeginningWeightType = "EMPTY";
              break;
            case "OUT":
              emulationBeginningWeightType = "FULL";
              break;
            default:
              relevantShipDoc.inWeightStatus === "X"
                ? (emulationBeginningWeightType = "FULL")
                : (emulationBeginningWeightType = "EMPTY");
          }

          _getLoadInitialize(
            {
              data: {
                shipDoc: relevantShipDoc.id,
                midWeight: 0,
                cutoffWeight: 0,
                scaleMode: "Scaling",
                emulationBeginningWeightType,
              },
            },
            (reponse) => {
              if (reponse.success) {
                navigate(
                  `/TerminalActivity/InOutWeightMT/${relevantShipDoc.id}/${localState.weightType}/`
                );
              } else {
                setServiceError((current) => {
                  return [...current, reponse.message];
                });
                return;
              }
            }
          );
        }

        if (numberEntries == 0) {
          _createErrorMessage("24");
          return;
        }

        if (numberEntries > 1) {
          _createErrorMessage("25");
          return;
        }
      }
    );
  };
  const getRelevantShipDoc = () => {
    _getShipDocInOutWeight(
      {
        data: {
          locationId: _stationInfo.locationId,
          inweightStatus: "X",
          outweightStatus: "",
        },
      },
      ({ data }) => {
        if (data.length === 0) {
          _createErrorMessage("27");
          return;
        }
        setShowOrderList(true);
      }
    );
  };
  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }

    const newValueObject = { [name]: newValue };
    setLocalState((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };
  const fieldsState = {
    dataSource: localState,
    weightTypeOptions: weightTypeOptions.current,
    onChange,
  };
  const mergedFieldData = (field) => {
    return { ...field };
  };

  const leftPanelFields = sideBarArray(fieldsState);

  const onChangeKeyboard = (e) => {
    let value = e?.target?.value;
    let name = e?.target?.name;

    const newValueObject = { [name]: value };
    setPageData((current) => {
      return { ...current, ...newValueObject };
    });
  };

  return (
    <section>
      <div className="container-fluid ">
        <div className="col-12">
          <LoadingContext.Provider value={contextState}>
            {operationLoading && <LoadingScreen />}

            <Button
              onClick={getRelevantShipDoc}
              color="primary"
              style={{
                backgroundColor: "#29abe2",
                borderColor: "#29abe2",
                color: "#fff",
                marginTop: 10,
                padding: 10,
              }}
            >
              Get BOLs Ready for Scale
            </Button>
            <div
              className="heroCard heroPartner"
              style={{ height: "auto", alignItems: "flex-start" }}
            >
              <div className="card" style={{ maxWidth: 450 }}>
                <div className="cardBody">
                  <div className="cardInput">
                    {leftPanelFields.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field)}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="card card2">
                <div className="cardHeader">
                  <h2>
                    <span>
                      <img src={cardlogo} alt="" />
                    </span>
                    Check-in, Existing BOL
                  </h2>
                </div>
                <div className="cardBody">
                  <div className="cardInput">
                    <div className="orderInput">
                      <KeyboardInput
                        value={pageData.pin ?? ""}
                        placeholder="Driver PIN here..."
                        onChange={onChangeKeyboard}
                        disableKeyboard={disableKeyboad}
                        name={"pin"}
                      />
                    </div>
                  </div>
                  <div className="cardButton cardorder">
                    <Button className="w-100" onClick={ProceedTakeWeight}>
                      Proceed to Take Weight
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[
                ..._errorMessages,
                ..._errorShipDocInOutWeight,
                ...serviceError,
              ]}
              successMessages={[..._successShipDocInOutWeight]}
              clearMessages={clearMessages}
            ></MessageLog>
            <ListModal
              setId={HandleShipDocDropDownSelect}
              dataList={_shipDocInOutWeightData}
              showListModal={showOrderList}
              setShowModal={setShowOrderList}
              title="List Of BOL for OutWeight"
              columns={[
                { field: "id", headerName: "BOL ID", width: 250 },
                { field: "driverDescriptor", headerName: "Driver", width: 250 },
                {
                  field: "materialDescriptor",
                  headerName: "Material",
                  width: 250,
                },
                { field: "startDT", headerName: "Start DT", width: 350 },
                { field: "inWeight", headerName: "In-Weight", width: 200 },
                { field: "outWeight", headerName: "Out-Weight", width: 250 },
              ]}
            />
          </LoadingContext.Provider>
        </div>
      </div>
    </section>
  );
}

export default CheckinV4;
