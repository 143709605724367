import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useFetch } from "../../../../../hooks/useFetch";
import { useMessage } from "../../../../../hooks/UseMessage";
import { useUser } from "../../../../../hooks/useUser";
import { useStation } from "../../../../../hooks/UseStation";
import { useDD } from "../../../../../hooks/UseDD";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../../CommonComponents/GlobalConfFiles/URLs";
import { ReactComponent as OverLoadAlram } from "../../../../../Assets/SVGs/Alrams/AnimatedAlram1.svg";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import HeaderV2 from "../../Header/HeaderV2";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { FlipCounter } from "../../../../../CommonComponents/FlipCounter/FlipCounter";
import TruckAnimation from "../../../../../CommonComponents/TruckAnimation/index";
import { useParams } from "react-router-dom";
import Sidebar from "./SideBar";
import { Col, Row } from "react-bootstrap";
import HeaderV5 from "../../Header/HeaderV5";
function ScaleLoadingV2(props) {
  //This page was done to use the WebSerial API, it conatins code to read from 2 scales in was done initailly for the glendale additional
  //scale at each load rack in April 2023, but then decided not use the web API and continue using the service because the web serial api is not stable yet
  const navigate = useNavigate();
  const params = useParams();
  const shipDocId = params.shipDocId;
  const fieldOperation = "SCALE_LOADINGV1";
  const [loading, setLoading] = useState(false);
  const [controllerPort, setControllerPort] = useState(false);
  const [leftScalePort, setLeftScalePort] = useState(false);
  const [rightScalePort, setRightScalePort] = useState(false);
  const [issuedAlrams, setIssuedAlrams] = useState([]);

  //state to indicate loading operation is done
  const [loadingFinished, setLoadingFinished] = useState(false);
  const [controllerData, setControllerData] = useState({
    A4: "",
    A5: "",
    A0: "",
    A10: "",
  });
  const initialMeterLoadedQty = useRef(0);
  const controllerWrite = useRef();
  const controllerRead = useRef();
  const controllerComPort = useRef();
  //needed to use useRef in order to pass the current weight to updateReading API Call
  // the "scaleMeasurements" did not contain the correct value, it only conatins the initial value once the useEffect is run
  const currentScaleReading = useRef(0);
  const currentMeterReading = useRef(0);
  const [scaleMeasurements, setScaleMeasurements] = useState({
    weight: 0,
    weightUnitId: "LB",
  });

  //hooks
  const [_user, ,] = useUser();

  let serviceSimulation = false;

  if (
    _user.serviceSimulation === "true" ||
    _user.serviceSimulation === "True"
  ) {
    serviceSimulation = true;
  }
  const [
    _stationInfo,
    ,
    ,
    _getStationOperation,
    _stationOperation,
    _stationMessages,
    _setStationMessages,
  ] = useStation();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
    _createSuccessMessage,
    _successMessages,
    _setSuccessMessages,
  ] = useMessage();

  let scaleApiInterval = 1000;
  let meterApiInterval = 2000;
  let scaleTestIncrement = 1000;
  let meterTestIncrement = 1000;

  if (_stationInfo.scaleApiInterval > 0) {
    scaleApiInterval = _stationInfo.scaleApiInterval;
  }

  if (_stationInfo.meterApiInterval > 0) {
    meterApiInterval = _stationInfo.meterApiInterval;
  }

  if (_stationInfo.scaleTestIncrement > 0) {
    scaleTestIncrement = _stationInfo.scaleTestIncrement;
  }
  if (_stationInfo.meterTestIncrement > 0) {
    meterTestIncrement = _stationInfo.meterTestIncrement;
  }
  const [_dd] = useDD();
  const [
    _startLoad,
    _startLoadData,
    _setStartLoadData,
    _startLoadError,
    _setStartLoadError,
    ,
    ,
    _loadingStartLoad,
  ] = useFetch(GetServiceFullURL("StartLoadingController"), {
    method: "GET",
    headers: {},
  });

  const [
    _stopLoad,
    _stopLoadData,
    _setStopLoadData,
    _stopLoadError,
    _setStopLoadError,
    ,
    ,
    _loadingStopLoad,
  ] = useFetch(GetServiceFullURL("StopLoadingController"), {
    method: "GET",
    headers: {},
  });

  class LineBreakTransformer {
    constructor() {
      this.chunks = "";
    }

    transform(chunk1, controller) {
      // Append new chunks to existing chunks.
      this.chunks += chunk1;
      // For each line breaks in chunks, send the parsed lines out.
      const lines = this.chunks.split("\r\n");
      this.chunks = lines.pop();
      lines.forEach((line) => controller.enqueue(line));
    }

    flush(controller) {
      // When the stream is closed, flush any remaining chunks out.
      controller.enqueue(this.chunks);
    }
  }

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    _successShipDoc,
    _setSuccessShipDoc,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" });

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" }, true);

  const [_updateFieldReadings, , , , , , , ,] = useFetch(
    GetFullURL("UpdateFieldReadings"),
    { method: "POST" }
  );

  function controllerSimulation() {
    if (!serviceSimulation) {
      return;
    }

    setControllerData((x) => {
      let newQty = x.A0 + meterTestIncrement;
      return { ...x, A0: newQty };
    });
  }
  function scaleSimulation() {
    if (!serviceSimulation) {
      return;
    }

    if (loading) {
      setScaleMeasurements((x) => {
        let newQty = x.weight + scaleTestIncrement;
        return { ...x, weight: newQty };
      });
    } else {
      if (_shipDocData.outWeightStatus === "X") {
        setScaleMeasurements((x) => {
          return { ...x, weight: _shipDocData.outWeight };
        });
      } else if (_shipDocData.intWeightStatus === "X") {
        setScaleMeasurements((x) => {
          return { ...x, weight: _shipDocData.inWeight };
        });
      } else if (scaleMeasurements.weight == 0) {
        setScaleMeasurements((x) => {
          return { ...x, weight: 25000 };
        });
      }
    }
  }
  async function closeSerial() {
    //Could not to get to work becuase of exception while trying run the close method
    try {
      controllerRead.current.releaseLock();
      controllerWrite.current.releaseLock();
      await controllerComPort.current.close();
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    //on mount read shipDoc and set current operation ID status based on the current field process id e.g  "SAP_Order" and
    //the specific operation/fields operation in the scaleloading v1
    _getShipDoc({ data: { Id: shipDocId } }, ({ data: shipDocData }) => {
      _getStationOperation(fieldOperation, shipDocData.fieldProcessId);
      initialMeterLoadedQty.current = shipDocData.currentMeterReading;
    });

    //on mount connect to controller com port
    let controllerReadingTimer = null;
    setupControllerComm(controllerReadingTimer);

    return () => {
      clearInterval(controllerReadingTimer);
      if (controllerWrite.current) {
        controllerWrite.current.write("STOP\n");
      }

      //      closeSerial();
    };
  }, []);
  ///
  useEffect(() => {
    //setup the scale comm port once the sale is picked or changed
    //
    let leftScaleReadingTimer = null;
    let rightScaleReadingTimer = null;

    if (!_shipDocData.scaleId) {
      return;
    }

    if (serviceSimulation) {
      clearInterval(leftScaleReadingTimer);
      clearInterval(rightScaleReadingTimer);
    }

    if (_shipDocData.scaleId === "LeftScale") {
      setupLeftScaleComm(leftScaleReadingTimer);
    } else {
      setupRightScaleComm(rightScaleReadingTimer);
    }

    return () => {
      clearInterval(leftScaleReadingTimer);
      clearInterval(rightScaleReadingTimer);
    };
  }, [_shipDocData.scaleId, serviceSimulation]);

  useEffect(() => {
    //Setting the max weight from station one time
    if (_shipDocData.cutoffWeight == 0) {
      _setShipDocData((current) => {
        return { ...current, cutoffWeight: parseInt(_stationInfo.maxWeight) };
      });
    }
  });

  useEffect(() => {
    //This useEffect handles the controller data in simulation mode
    if (!serviceSimulation) {
      return;
    }
    let contrllerDataTimer = null;

    contrllerDataTimer = setInterval(() => {
      controllerSimulation();
    }, meterApiInterval);

    //controller simulation data needs to be stopped if the stop loading
    //button is hit, so if loading = false and simulation mode is true then
    //clear interval

    if (!loading) {
      clearInterval(contrllerDataTimer);
    }

    return () => {
      clearInterval(contrllerDataTimer);
    };
  }, [loading, serviceSimulation]);

  useEffect(() => {
    //This useEffect handles the scale simulation
    if (!serviceSimulation) {
      return;
    }

    let scaleServiceTimer = null;

    if (serviceSimulation) {
      scaleServiceTimer = setInterval(() => {
        scaleSimulation();
      }, scaleApiInterval);
    }

    return () => {
      clearInterval(scaleServiceTimer);
    };
  }, [loading, serviceSimulation]);

  useEffect(() => {
    currentScaleReading.current = scaleMeasurements.weight;
    calculateFillPercent();
    //check for auto cut-off
    if (scaleMeasurements.weight >= _shipDocData.cutoffWeight) {
      if (loading) {
        issueStopCommand();
        upDateShipDocStopLoad();
        let foundAlram = issuedAlrams.find((x) => x === 1);
        if (!foundAlram) {
          setIssuedAlrams((current) => [...current, 1]);
        }
      }
    }
  }, [scaleMeasurements.weight]);

  useEffect(() => {
    currentMeterReading.current = controllerData.A0;
  }, [controllerData.A0]);

  useEffect(() => {
    if (!loadingFinished) {
      return;
    }

    //Creating the next URL
    let nextUrlattribute1 = "";
    let nextUrlattribute2 = "";
    let nextUrlattribute3 = "";

    if (_stationOperation.nextUrlAttribute1) {
      try {
        nextUrlattribute1 = eval(_stationOperation.nextUrlAttribute1);
      } catch (error) {
        nextUrlattribute1 = _stationOperation.nextUrlAttribute1;
      }
    }

    if (_stationOperation.nextUrlAttribute2) {
      try {
        nextUrlattribute2 = eval(_stationOperation.nextUrlAttribute2);
      } catch (error) {
        nextUrlattribute2 = _stationOperation.nextUrlAttribute2;
      }
    }

    if (_stationOperation.nextUrlAttribute3) {
      try {
        nextUrlattribute3 = eval(_stationOperation.nextUrlAttribute3);
      } catch (error) {
        nextUrlattribute3 = _stationOperation.current.nextUrlAttribute3;
      }
    }

    navigate(
      `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
    );
  }, [loadingFinished]);

  function UpdateScaleReading() {
    //Update the backend with currebt scale reading is greater that ZERO, this should
    //account for checking the com port connection
    if (currentScaleReading.current > 0) {
      _updateFieldReadings(
        {
          data: {
            ShipDocId: shipDocId,
            ReadingType: "SCALE",
            CurrentReading: currentScaleReading.current,
            ReadingUnitId: scaleMeasurements.weightUnitId,
          },
        },
        () => {}
      );
    }
  }

  function UpdateMeterReading() {
    if (currentMeterReading.current > 0) {
      _updateFieldReadings(
        {
          data: {
            ShipDocId: shipDocId,
            ReadingType: "METER",
            CurrentReading: currentMeterReading.current,
            ReadingUnitId: "LB",
          },
        },
        () => {}
      );
    }
  }
  useEffect(() => {
    let fieldScaleReadingsTimer = null;
    let fieldMeterReadingsTimer = null;

    fieldScaleReadingsTimer = setInterval(() => {
      UpdateScaleReading();
    }, 2000);

    fieldMeterReadingsTimer = setInterval(() => {
      UpdateMeterReading();
    }, 2000);
    return () => {
      clearInterval(fieldScaleReadingsTimer);
      clearInterval(fieldMeterReadingsTimer);
    };
  }, []);

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessShipDoc([]);
        _setSuccessSaveShipDoc([]);
        _setSuccessMessages([]);
        break;
      case "error":
        _setErrorSaveShipDoc([]);
        _setErorMessages([]);
        _setErrorShipDoc([]);
        _setStartLoadError([]);
        _setStopLoadError([]);
        break;
      default:
        break;
    }
  }

  const operationLoading =
    _loadingShipDoc ||
    _loadingSaveShipDoc ||
    _loadingStartLoad ||
    _loadingStopLoad;

  function issueStopCommand() {
    //For simulatio  mode just set the loading flag to false
    if (serviceSimulation) {
      setLoading(false);
      return;
    }

    //when not is simulation is if the port is avaialbe, if not issue message because can not send the STOP command
    if (!controllerWrite.current) {
      _createErrorMessage("139");
      return;
    }

    //last case issue stop command
    controllerWrite.current.write("STOP\n");
    setLoading(false);
  }

  function issueStartCommand() {
    if (!serviceSimulation) {
      _setStartLoadError([]);
      if (!controllerWrite.current) {
        _createErrorMessage("141");
        return;
      }
      controllerWrite.current.write("START\n");
      setLoading(!loading);
    } else {
      setLoading(!loading);
    }
  }

  const onClickStartStop = () => {
    if (!loading) {
      //When attampting to START
      setIssuedAlrams([]);
      //Before stating check if shipDoc is populated
      if (shipDocId == null || shipDocId == "") {
        _createErrorMessage("16");
        return;
      }

      if (!_shipDocData.scaleId) {
        _createErrorMessage("137");
        return;
      }
      //Before starting check in cutt-off weight is populated
      if (
        _shipDocData.cutoffWeight == null ||
        _shipDocData.cutoffWeight === 0 ||
        _shipDocData.cutoffWeight == ""
      ) {
        _createErrorMessage("15");
        return;
      }

      if (scaleMeasurements.weight >= _shipDocData.cutoffWeight) {
        _createErrorMessage("85");
        return;
      }

      if (_shipDocData.inWeightStatus === "") {
        _createErrorMessage("71");
        return;
      }

      if (_shipDocData.inWeight <= 10000) {
        _createErrorMessage("128", 10000);
        return true;
      }
      issueStartCommand();
      upDateShipDocStartLoad();
    } else {
      //When attampting to STOP
      issueStopCommand();
      upDateShipDocStopLoad();
    }
  };

  const calculateFillPercent = () => {
    let percentLoaded = 0;
    let plannedQty = 0;
    let loadedQty = 0;
    let currentWeight = 0;

    //if max/cuttoff weight value is 0,  set % to zero because we can not divid by 0
    if (_shipDocData.cutoffWeight === 0 || !_shipDocData.cutoffWeight) {
      return 0;
    }
    if (_shipDocData.inWeight == 0) {
      return percentLoaded.toFixed(2);
    }

    if (loading) {
      currentWeight = scaleMeasurements.weight;
    } else {
      currentWeight = _shipDocData.currentScaleWeight;
    }

    plannedQty = _shipDocData.cutoffWeight - _shipDocData.inWeight;
    loadedQty = currentWeight - _shipDocData.inWeight;

    percentLoaded = (loadedQty * 100) / plannedQty;
    return percentLoaded.toFixed(2);
  };
  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    //Other way of getting value
    //let x = e.target.getAttribute("data-item");
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    //if item is populated then this is an update from item and hence need to search
    //for a particular item and update it

    _setShipDocData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const onChangeItem = (e) => {
    let item;
    let fieldTypeItem;
    const {
      target: { value, name },
    } = e;

    item = e.target.dataset.item;
    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeItem = e.target.dataset.type;

    let newValue;
    switch (fieldTypeItem) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    let newItems = _shipDocData.shippingDocItemsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.itemNumber == item) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setShipDocData((prevState) => {
      return { ...prevState, shippingDocItemsComm: newItems };
    });
  };

  const upDateShipDocStartLoad = () => {
    if (_shipDocData.id != null) {
      _shipDocData.loadStDT = moment().format();
      _shipDocData.loadUnloadinProcess = true;
      _shipDocData.statusId = "LOADING";
      _setErrorSaveShipDoc([]);
      _setSuccessSaveShipDoc([]);
      _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {
        _setShipDocData(newShipDocData);
      });
    }
  };

  const upDateShipDocStopLoad = () => {
    if (_shipDocData.id != null) {
      _shipDocData.LoadEnDT = moment().format();
      _shipDocData.LoadStatus = "X";
      _shipDocData.loadUnloadinProcess = false;
      _shipDocData.statusId = "LOADEDSHDOC";
      _shipDocData.currentMeterReading = controllerData.A0;
      _shipDocData.meterUnitId = "LB";
      _shipDocData.rawTemperature = controllerData.A4;

      _shipDocData.currentScaleWeight = scaleMeasurements.weight;
      _shipDocData.scaleUnitId = scaleMeasurements.weightUnitId;
      _shipDocData.printerId = _stationInfo.printerId;

      _shipDocData.shippingDocItemsComm.forEach((item, index) => {
        item.currentMeterReading = controllerData.A0;
        item.meterUnitId = "LB";
      });
      _setErrorSaveShipDoc([]);
      _setSuccessSaveShipDoc([]);
      _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {
        _setShipDocData(newShipDocData);
      });
    }
  };

  const takeWeightButtonStatus = () => {
    // //if loading in process, we can take the weight
    if (loading) {
      return true;
    }
    return false;
  };

  const updateShipDocInWeight = (shipDocData) => {
    shipDocData.inWeightStatus = "X";
    shipDocData.inWeightStDT = moment().format();
    shipDocData.inWeightEnDT = moment().format();

    shipDocData.inWeight = scaleMeasurements.weight;
    shipDocData.currentScaleWeight = scaleMeasurements.weight;
    shipDocData.scaleUnitId = scaleMeasurements.weightUnitId;
    shipDocData.weightUnitId = "LB";
    shipDocData.currentMeterReading = controllerData.A0;
    shipDocData.meterUnitId = "LB";

    shipDocData.statusId = "INWGHTSHDOC";
    //Update station info
    shipDocData.currentStation = _stationInfo.id;
    shipDocData.loadingStationId = _stationInfo.id;
    shipDocData.scaleStationId = _stationInfo.id;

    shipDocData.fieldDataPointId = "SCALE";
    shipDocData.printerId = _stationInfo.printerId;
    shipDocData.signatureReady = false;

    return shipDocData;
  };

  const updateShipDocOutWeight = (shipDocData) => {
    shipDocData.outWeightStatus = "X";
    shipDocData.outWeightStDT = moment().format();
    shipDocData.outWeightEnDT = moment().format();

    shipDocData.outWeight = scaleMeasurements.weight;
    shipDocData.currentScaleWeight = scaleMeasurements.weight;
    shipDocData.scaleUnitId = scaleMeasurements.weightUnitId;
    shipDocData.weightUnitId = "LB";
    shipDocData.currentMeterReading = controllerData.A0;
    shipDocData.meterUnitId = "LB";

    shipDocData.statusId = "LOADEDSHDOC";
    shipDocData.currentStation = _stationInfo.id;
    shipDocData.loadingStationId = _stationInfo.id;
    shipDocData.scaleStationId = _stationInfo.id;

    shipDocData.fieldDataPointId = "SCALE";

    shipDocData.printerId = _stationInfo.printerId;
    shipDocData.signatureReady = true;
    shipDocData.loadUnloadinProcess = false;
    shipDocData.loadEnDT = moment().format();
    shipDocData.loadStatus = "X";
    shipDocData.rawTemperature = controllerData.A4;

    shipDocData.shippingDocItemsComm.forEach((item, index) => {
      item.currentMeterReading = controllerData.A0;
      item.meterUnitId = "LB";
    });
    return shipDocData;
  };

  const takeOutWeight = () => {
    if (
      scaleMeasurements.weight === 0 ||
      scaleMeasurements.weight === undefined
    ) {
      _createErrorMessage("86");
      return;
    }

    if (loading) {
      _createErrorMessage("129");
      return;
    }

    if (scaleMeasurements.weight < 10000) {
      _createErrorMessage("130");
      return;
    }

    let changedShipDocData = _shipDocData;

    if (
      changedShipDocData.inWeight === 0 ||
      changedShipDocData.inWeight === undefined
    ) {
      _createErrorMessage("61");
      return;
    }

    let netWeight = scaleMeasurements.weight - changedShipDocData.inWeight;

    if (netWeight <= 10000) {
      _createErrorMessage("127", netWeight, 10000);
      return;
    }
    changedShipDocData = updateShipDocOutWeight(changedShipDocData);
    issueStopCommand();
    _setErrorSaveShipDoc([]);
    _setSuccessSaveShipDoc([]);
    _saveShipDoc({ data: changedShipDocData }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
      setLoadingFinished(true);
    });
  };
  const takeInWeight = () => {
    if (
      scaleMeasurements.weight === 0 ||
      scaleMeasurements.weight === undefined
    ) {
      _createErrorMessage("86");
      return;
    }

    if (_shipDocData?.outWeight > 0) {
      _createErrorMessage("133");
      return;
    }
    if (loading) {
      _createErrorMessage("129");
      return;
    }

    if (scaleMeasurements.weight < 10000) {
      _createErrorMessage("130");
      return;
    }
    if (scaleMeasurements.weight > 35000) {
      _createErrorMessage("136");
      return;
    }

    if (_shipDocData.loadStDT) {
      _createErrorMessage("134");
      return;
    }
    let changedShipDocData = _shipDocData;

    changedShipDocData = updateShipDocInWeight(changedShipDocData);
    _setErrorSaveShipDoc([]);
    _setSuccessSaveShipDoc([]);
    _saveShipDoc({ data: changedShipDocData }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
    });
  };

  const inweightButtonColor = () => {
    if (_shipDocData.inWeightStatus === "X") {
      return "#DA1212";
    }

    return "#08bd55";
  };
  const outweightButtonColor = () => {
    if (_shipDocData.outWeightStatus === "X") {
      return "#DA1212";
    }
    return "#08bd55";
  };

  const stopStartButtonColor = () => {
    if (loading) {
      return "#ec0505";
    } else {
      return "#08bd55";
    }
  };
  function sleep(milliseconds) {
    const start = Date.now();
    while (Date.now() - start < milliseconds);
  }

  function parseScaleReading(value) {
    var newValue = value.replace(/[A-Za-z]/g, "");
    var currentWeight = parseInt(newValue);
    setScaleMeasurements((prev) => {
      return { ...prev, weight: currentWeight };
    });
  }

  async function setupControllerComm(controllerReadingTimer) {
    try {
      const filter = {
        usbProductId: 67,
        usbVendorId: 9025,
      };
      // const filter = {
      //   usbProductId: 8963,
      //   usbVendorId: 1659,
      // };

      const ports = await navigator.serial.getPorts({ filters: [filter] });
      let port = ports[0];
      if (!port) {
        port = await navigator.serial.requestPort({ filters: [filter] });
      }
      //Get info of port received via filter and compare it to requsted filter
      let currentFilter = port.getInfo();
      if (
        filter.usbProductId != currentFilter.usbProductId ||
        filter.usbVendorId != currentFilter.usbVendorId
      ) {
        return;
      }

      try {
        await port.open({ baudRate: 9600 });
      } catch (error) {
        _createErrorMessage("138", error.message);
        return;
      }
      controllerComPort.current = port;
      //Set the com port status to connected
      setControllerPort(true);

      // eslint-disable-next-line no-undef
      const textEncoder = new TextEncoderStream();
      textEncoder.readable.pipeTo(port.writable);
      const writer = textEncoder.writable.getWriter();
      controllerWrite.current = writer;

      // eslint-disable-next-line no-undef
      const textDecoder = new TextDecoderStream();
      port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable
        .pipeThrough(new TransformStream(new LineBreakTransformer()))
        .getReader();

      //Expose controller reader via useRef
      controllerRead.current = reader;
      sleep(2000);
      controllerReadingTimer = setInterval(async () => {
        const { value, done } = await reader.read();
        try {
          var newValue = value.replace(/'/g, '"');
          var jsonValue = JSON.parse(newValue);
          if (jsonValue) {
            setControllerData(jsonValue);
          }
        } catch (error) {
          // Did not catch the parsing error because initially the data is distorted
        }
      }, 100);
    } catch (error) {
      _createErrorMessage("140", error.message);
    }
  }
  async function setupLeftScaleComm(leftScaleReadingTimer) {
    try {
      const filter = {
        usbProductId: 67,
        usbVendorId: 9025,
      };
      const ports = await navigator.serial.getPorts({ filters: [filter] });
      let port = ports[0];
      if (!port) {
        port = await navigator.serial.requestPort({ filters: [filter] });
      }
      //Get info of port received via filter and compare it to requsted filter
      let currentFilter = port.getInfo();
      if (
        filter.usbProductId != currentFilter.usbProductId ||
        filter.usbVendorId != currentFilter.usbVendorId
      ) {
        return;
      }

      try {
        await port.open({ baudRate: 9600 });
      } catch (error) {
        _createErrorMessage("143", error.message);
      }

      //Set the com port status to connected
      setLeftScalePort(true);
      // eslint-disable-next-line no-undef
      const textDecoder = new TextDecoderStream();
      port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable
        .pipeThrough(new TransformStream(new LineBreakTransformer()))
        .getReader();
      sleep(2000);
      leftScaleReadingTimer = setInterval(async () => {
        const { value, done } = await reader.read();
        try {
          if (_shipDocData.scaleId === "leftScale" && value != null) {
            parseScaleReading(value);
          }
        } catch (error) {
          // Did not catch the parsing error because initially the data is distorted
        }
      }, 100);
    } catch (error) {
      _createErrorMessage("140", error.message);
    }
  }
  async function setupRightScaleComm(rightScaleReadingTimer) {
    try {
      const filter = {
        usbProductId: 9155,
        usbVendorId: 1659,
      };
      const ports = await navigator.serial.getPorts({ filters: [filter] });
      let port = ports[0];
      if (!port) {
        port = await navigator.serial.requestPort({ filters: [filter] });
      }
      //Get info of port received via filter and compare it to requsted filter
      let currentFilter = port.getInfo();
      if (
        filter.usbProductId != currentFilter.usbProductId ||
        filter.usbVendorId != currentFilter.usbVendorId
      ) {
        return;
      }

      try {
        await port.open({ baudRate: 9600 });
      } catch (error) {
        _createErrorMessage("142", error.message);
        return;
      }
      //Set the com port status to connected
      setRightScalePort(true);
      // eslint-disable-next-line no-undef
      const textDecoder = new TextDecoderStream();
      port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable
        .pipeThrough(new TransformStream(new LineBreakTransformer()))
        .getReader();
      sleep(2000);
      rightScaleReadingTimer = setInterval(async () => {
        const { value, done } = await reader.read();
        try {
          if (_shipDocData.scaleId === "rightScale" && value != null) {
            parseScaleReading(value);
          }
        } catch (error) {
          // Did not catch the parsing error because initially the data is distorted
        }
      }, 100);
    } catch (error) {
      _createErrorMessage("140", error.message);
    }
  }

  return (
    <div className="container-fluid">
      {operationLoading && <LoadingScreen />}
      <Row>
        <Col xs={12}>
          <HeaderV5
            _shipDocData={_shipDocData}
            weightType={null}
            controllerPort={controllerPort}
            leftScalePort={leftScalePort}
            rightScalePort={rightScalePort}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <h3 style={{ fontSize: "40px" }}>Scale Loading</h3>
          <Sidebar
            _shipDocDDData={_dd.shipDoc}
            onChangeItem={onChangeItem}
            onChange={onChange}
            _shipDocData={_shipDocData}
            _stationInfo={_stationInfo}
            controllerData={controllerData}
          />
          {issuedAlrams.map((item, index) => {
            switch (item) {
              case 1:
                return (
                  <div className="row d-flex align-items-center justify-content-center">
                    <OverLoadAlram />
                    <h3> OverLoad</h3>
                  </div>
                );

              default:
              // code block
            }
          })}
        </Col>

        <Col xs={6}>
          <Row>
            <Col xs={8}>
              <FlipCounter
                number={scaleMeasurements.weight}
                className="mb-4"
                className2="mt-4"
                backgroundColorClassName="bg-info"
                //                   title="Scale"
              />
            </Col>
            <Col xs={2}>
              <button
                disabled={takeWeightButtonStatus()}
                onClick={takeInWeight}
                style={{
                  height: "8rem",
                  width: "100%",
                  fontSize: 20,
                  padding: 5,
                  fontWeight: "bold",
                  background: inweightButtonColor(),
                  borderColor: "#08bd55",
                }}
              >
                Take In Weight
              </button>
            </Col>
            <Col xs={2}>
              <button
                disabled={takeWeightButtonStatus()}
                onClick={takeOutWeight}
                style={{
                  height: "8rem",
                  width: "100%",
                  fontSize: 20,
                  padding: 5,
                  fontWeight: "bold",
                  background: outweightButtonColor(),
                  borderColor: "#08bd55",
                }}
              >
                Take Out Weight
              </button>
            </Col>
          </Row>
          <Row>
            <div className="mx-3" style={{ width: "85%" }}>
              <TruckAnimation
                compartCount={1}
                activeIndex={0}
                fillPerCent={calculateFillPercent()}
              />
            </div>
          </Row>
          <Row
            style={{
              position: "relative",
              marginTop: "30px",
            }}
          >
            <Col
              xs={12}
              style={{
                position: "absolute",
                left: "50%",
              }}
            >
              <button
                onClick={onClickStartStop}
                style={{
                  width: "40%",
                  height: "6rem",
                  fontSize: 50,
                  fontWeight: "bold",
                  background: stopStartButtonColor(),
                }}
              >
                {loading ? "STOP" : "START"}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>

      <MessageLog
        errorMessages={[
          ..._errorShipDoc,
          ..._errorMessages,
          ..._errorSaveShipDoc,
          ..._startLoadError,
          ..._stopLoadError,
        ]}
        successMessages={[..._successShipDoc, ..._successMessages]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}

export default ScaleLoadingV2;
