/** @format */
import { useState, useEffect, useRef } from "react";
import { useFetch } from "../../hooks/useFetch";
import { mainArray, infoArray, dateArray, defaultsArray } from "./Fields";
import { FieldsReducer } from "../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import "./index.scss";
import mainInfo from "../../Assets/info1.svg";
import additionalinfo from "../../Assets/info2.svg";
import dateinfo from "../../Assets/dateinfo.svg";
import { LogOut } from "../../redux/Features/UserSlice";
import { useDispatch } from "react-redux";
import { useUser } from "../../hooks/useUser";
import { useMessage } from "../../hooks/UseMessage";
import { ValidateUser } from "./ValidateUser";
import Card from "../../CommonComponents/Card";

export default function UserMaintenance(props) {
  const dispatch = useDispatch();
  const adminUser = useRef(false);
  let navigate = useNavigate();
  const [_user, ,] = useUser();
  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
    _createSuccessMessage,
    _successMessages,
    _setSuccessMessages,
  ] = useMessage();
  const [resetPassword, setResetPassword] = useState(false);

  const [
    _getUser,
    _userData,
    _setUserData,
    _errorUser,
    _setErrorUser,
    _successUser,
    _setSuccessUser,
    _loadingUser,
  ] = useFetch(GetFullURL("Getuser"), { method: "POST" }, true);

  const [
    _updateUser,
    _updateUserData,
    _setUpdateUserData,
    _errorUserUpdate,
    _setErrorUserUpdate,
    _successUserUpdate,
    _setSuccessUserUpdate,
    _loadingUserUpdate,
  ] = useFetch(GetFullURL("UpdateUser"), { method: "POST" }, true);

  const [
    _getUserDD,
    _userDDData,
    _setUserDDData,
    _errorUserDD,
    _setErrorUserDD,
    _successUserDD,
    _setSuccessUserDD,
    _loadingUserDD,
  ] = useFetch(GetFullURL("UserDropDowns"), { method: "POST" }, true);

  const [frontendWarning, setFrontendWarning] = useState([]);

  useEffect(() => {
    if (_user.id) {
      _getUser({ data: { id: _user.id } });
    }
    _getUserDD();
  }, [_user.id]);

  useEffect(() => {
    if (resetPassword === true) {
      _setUserData((current) => {
        return {
          ...current,
          password: "",
        };
      });
      setResetPassword(false);
    }
  }, [resetPassword]);

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;

    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = "true") : (newValue = "false");
        break;

      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    _setUserData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const fieldsState = {
    dropDowns: _userDDData || [],
  };

  useEffect(() => {
    let userRoles = [];

    userRoles = _user?.roles;

    if (typeof userRoles == "undefined" || userRoles.length === 0) {
      return;
    }
    adminUser.current = userRoles.includes("Admin", "SuperAdmin");
  }, [_user]);

  const mainFieldsToRender = mainArray(fieldsState);
  const infoFieldsToRender = infoArray(fieldsState);
  const dateFieldsToRender = dateArray(fieldsState);
  const defaultsArrayToRender = defaultsArray(fieldsState);

  const mergedFieldData = (field) => {
    if (field.displayOnly || !adminUser.current) {
    }
    let newFieldObject = {
      ...field,
      disabled: field.displayOnly || !adminUser.current,
      dataSource: _userData,
      onChange,
    };
    return newFieldObject;
  };

  const operationLoading = _loadingUser || _loadingUserDD || _loadingUserUpdate;

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessUser([]);
        _setSuccessUserUpdate([]);
        _setSuccessUserDD([]);
        _setSuccessMessages([]);
        break;
      case "error":
        _setErrorUser([]);
        _setErrorUserUpdate([]);
        _setErrorUserDD([]);
        _setErorMessages([]);
        break;
      case "warning":
        setFrontendWarning([]);
        break;
      default:
        break;
    }
  }
  console.log('_userData.systemConfigurator',_userData.systemConfigurator);
  console.log("_userData.singleTerminalUser",_userData.singleTerminalUser);
  console.log("_userData.defaultLocationId",_userData.defaultLocationId);
  return (
    <>
      {operationLoading && <LoadingScreen />}
      <section style={{ marginTop: "30px", height: "calc(100vh - 100px)" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 xol-lg-4 col-sm-12 col-xs-12">
              <Card
                cardHeaderProps={{
                  cardlogo: mainInfo,
                  headerLabel: "Main Info",
                }}
                cardBody={mainFieldsToRender.map((field, index) => (
                  <FieldsReducer field={mergedFieldData(field)} key={index} />
                ))}
              />
            </div>
            <div className="col-xl-3 xol-lg-4 col-sm-12 col-xs-12">
              <Card
                cardHeaderProps={{
                  cardlogo: additionalinfo,
                  headerLabel: "Additional Info",
                }}
                cardBody={infoFieldsToRender.map((field, index) => (
                  <FieldsReducer field={mergedFieldData(field)} key={index} />
                ))}
              />
            </div>
            <div className="col-xl-3 xol-lg-4 col-sm-12 col-xs-12">
              <Card
                cardHeaderProps={{
                  cardlogo: dateinfo,
                  headerLabel: "Defaults",
                }}
                cardBody={defaultsArrayToRender.map((field, index) => (
                  <FieldsReducer field={mergedFieldData(field)} key={index} />
                ))}
              />
            </div>
            <div className="col-xl-3 xol-lg-4 col-sm-12 col-xs-12">
              <Card
                cardHeaderProps={{
                  cardlogo: dateinfo,
                  headerLabel: "Date Info",
                }}
                cardBody={dateFieldsToRender.map((field, index) => (
                  <FieldsReducer field={mergedFieldData(field)} key={index} />
                ))}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-6">
              <Button
                disabled={!adminUser.current}
                className={"col-4"}
                onClick={() => {

                  let validationConfirmResult = ValidateUser(
                    _userData,
                    _createErrorMessage
                  );

                  if (!validationConfirmResult) {
                    return;
                  }

                  _updateUser({ data: _userData }, ({ data: newUserData }) => {
                    _setUserData(newUserData);
                  });
                }}
              >
                Save changes
              </Button>
              <Button
                className={"col-4"}
                onClick={() => {
                  let navigationNextUrl = `/Home`;
                  navigate(navigationNextUrl);
                }}
                style={{
                  marginLeft: 10,
                  background: "#DA1212",
                  borderColor: "#DA1212",
                }}
              >
                Cancel
              </Button>
            </div>
            <div
              className="col-6"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                onClick={() => {
                  dispatch(LogOut());
                  navigate("/Login");
                }}
                style={{
                  marginLeft: 10,
                  background: "#DA1212",
                  borderColor: "#DA1212",
                }}
                className={"col-4"}
              >
                LogOut
              </Button>
              <Button
                className={"col-4"}
                disabled={!adminUser.current}
                style={{
                  marginLeft: 10,
                  background: "#DA1212",
                  borderColor: "#DA1212",
                }}
                onClick={() => {
                  setResetPassword(true);
                  _createSuccessMessage("104");
                }}
              >
                Reset Password
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MessageLog
                errorMessages={[
                  ..._errorUser,
                  ..._errorUserDD,
                  ..._errorUserUpdate,
                  ..._errorMessages,
                ]}
                successMessages={[
                  ..._successUser,
                  ..._successUserUpdate,
                  ..._successMessages,
                ]}
                warningMessages={[...frontendWarning]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
