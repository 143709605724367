import React, { useState, useEffect } from "react";
import { useFetch } from "src/hooks/useFetch";
import { GetFullURL } from "src/CommonComponents/GlobalConfFiles/URLs";
import { useMessage } from "src/hooks/UseMessage";
import { useNavigate } from "react-router-dom";
import MessageLog from "src/CommonComponents/Messages/MessageLog";
import { LoadingScreen } from "src/CommonComponents/LoadingScreen/index";
import LineChart from "src/CommonComponents/Charts/LineChart/LineChart";

export default function WXBOLProcessTimeChart() {
  const [reportData, setReportData] = useState({});
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [yaxisName, setYaxisName] = useState("");
  const [render, setRender] = useState(false);

  const [
    _getChartData,
    _chartData,
    ,
    _errorChartData,
    _setErrorChartData,
    _successChartData,
    _setSuccessChartData,
    _loadingChartData,
  ] = useFetch(GetFullURL("BOLProcessTimeChart"), { method: "POST" });

  const operationLoading = _loadingChartData;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessChartData([]);

        break;
      case "error":
        _setErorMessages([]);
        _setErrorChartData([]);

        break;
      default:
        break;
    }
  }

  useEffect(() => {
    _getChartData(
      {
        data: {
          LocationId: "946",
        },
      },
      ({ data }) => {
        if (data.length == 0) {
          _createErrorMessage("90");
          return;
        }

        let id = data?.map((x) => x.id);
        let time = data?.map((x) => x.differenceInTime);
        let value = data?.map((x) => x.chartValue);
        setReportData({
          ids: id,
          differenceInTime: time,
          chartValue: value,
        });
        setRender(true);
      }
    );
  }, []);
  if (render) {
    return (
      <div className="container-fluid">
        {operationLoading && <LoadingScreen />}
        <div className="row">
          <LineChart
            name={"BOL Process Time in min"}
            {...reportData}
            yaxisName={yaxisName}
          />
        </div>
      </div>
    );
  } else {
    return(
      <div className="row">
        <div className="col-12 mt-3">
          <MessageLog
            errorMessages={[..._errorChartData, ..._errorMessages]}
            successMessages={[]}
            warningMessages={[]}
            clearMessages={clearMessages}
          ></MessageLog>
        </div>
      </div>
    );
  }
}
