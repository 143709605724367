import { issueMessage } from "../../../../../utils/issueMessage";

export const ValidateShipDocAfterAPI = (shipDocData, language) => {
  let errorMessages = [];
  let successMessages = [];
  let warningMessages = [];

  if (shipDocData.loadStDT != null) {
    let { messageText } = issueMessage(language, "3");
    warningMessages.push(messageText);
  }
  if (shipDocData.cutoffWeight <= 0) {
    let { messageText } = issueMessage(language, "15");
    errorMessages.push(messageText);
  }
  return { errorMessages, successMessages, warningMessages };
};

export const ValidateDriverPIN = (shipDocData, _createErrorMessage) => {

  if (
    typeof shipDocData.pin === "undefined" ||
    shipDocData.pin == 0 ||
    shipDocData.pin == null
  ) {
     _createErrorMessage("17");
    return false;
  }

  return true;
};

export const ValidateConfirmChecking = (shipDocData, _createErrorMessage) => {
  if (typeof shipDocData.preactWeight == "undefined") {
    _createErrorMessage("40");
    return false;
  }
  if (typeof shipDocData.preactWeight == 0) {
    _createErrorMessage("40");
    return false;
  }

  if (typeof shipDocData.cutoffWeight == "undefined") {
    _createErrorMessage("23");
    return false;
  }
  
  if (typeof shipDocData.cutoffWeight < 46500) {
    _createErrorMessage("23");
    return false;
  }

  if (typeof shipDocData.cutoffWeight > 81902) {
    _createErrorMessage("23");
    return false;
  }

  if (typeof shipDocData.maxWeight == "undefined") {
    _createErrorMessage("23");
    return false;
  }

  if (shipDocData.maxWeight < 50000) {
    _createErrorMessage("20");
    return false;
  }
  if (shipDocData.maxWeight >= 85401) {
    _createErrorMessage("21");
    return false;
  }

  if (shipDocData.driverId == "") {
    _createErrorMessage("22");
    return false;
  }
  if (typeof shipDocData.driverId == "undefined") {
    _createErrorMessage("22");
    return false;
  }

  // if (!shipDocData.carrierId) {
  //   let { messageText } = issueMessage(language, "5");
  //   errorMessages.push(messageText);
  // }

  // if (!shipDocData.vehicleId) {
  //   let { messageText } = issueMessage(language, "4");
  //   errorMessages.push(messageText);
  // }
  // if (!shipDocData.trailerId) {
  //   let { messageText } = issueMessage(language, "6");
  //   errorMessages.push(messageText);
  // }
  // if (!shipDocData.driverId) {
  //   let { messageText } = issueMessage(language, "7");
  //   errorMessages.push(messageText);
  // }

  // if (!shipDocData.midWeight) {
  //   let { messageText } = issueMessage(language, "8");
  //   messages.push(messageText);
  // }

  

  return true;
};

export const ValidateSetup = (setupItems, _createErrorMessage) => {

let firstItem = setupItems[0];

  if (firstItem == null || undefined){    
    _createErrorMessage("47");
    return false;
}


return true;
};

