exports.DocumentType = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    length: 12,
    key: "true",
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    value: "",
    label: "Universal Descriptor",
  },
  {
    id: "key",
    name: "key",
    type: "text",
    value: "",
    label: "Key",
  },
  {
    id: "documentCategoryId",
    name: "documentCategoryId",
    type: "text",
    value: "",
    length: 6,
    label: "Document Category",
  },
  {
    id: "businessObjectId",
    name: "businessObjectId",
    type: "text",
    value: "",
    length: 12,
    label: "Business Object",
  },
  {
    id: "defaultGroupId",
    name: "defaultGroupId",
    type: "text",
    value: "",
    length: 6,
    label: "Defaul Group",
  },
  {
    id: "outputProcedureId",
    name: "outputProcedureId",
    type: "text",
    value: "",
    length: 12,
    label: "Output  Procedure",
  },
  {
    id: "numberRangeOptions",
    name: "numberRangeOptions",
    type: "number",
    value: "",
    length: 12,
    label: "Number Range Options",
    ddid: "DocTypeNumberRangeOptions",
  },
  {
    id: "numberRange",
    name: "numberRange",
    type: "text",
    value: "",
    label: "Number Range",
    ddid: "numberRangeId",
    length: 20,
  },
  {
    id: "nameLocalizedText",
    name: "nameLocalizedText",
    type: "text",
    value: "",
    label: "nameLocalizedText",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
