import React from "react";
import GridHeader from "./GridHeader";
import GridBody from "./GridBody";
function SimpleGrid({ fields, itemData, ActionColumn, handleButtonClickGrid }) {
  if (!fields) {
    return null;
  }

  return (
    <div>
      <div style={{ overflow: "auto", marginTop: 10 }}>
        <table className="table table-striped table-bordered text-nowrap custom-table">
          <GridHeader fields={fields} ActionColumn={ActionColumn} />
          <GridBody
            fields={fields}
            data={itemData}
            handleButtonClickGrid={handleButtonClickGrid}
          />
        </table>
      </div>
    </div>
  );
}

export default SimpleGrid;
