import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { OrderContext } from "../Contex";
import { MuiButon } from "../../../../CommonComponents/MuiButton";

export default function OrderHeader({ _loadingSaveOrder, _saveOrder }) {
  const { operation, _setOrderData, _orderData } = useContext(OrderContext);

  const saveDocument = () => {
    _saveOrder({ data: _orderData }, ({ data: newOrderData }) => {
      _setOrderData(newOrderData);
    });
  };

  return (
    <Paper className="py-2 px-2 d-flex align-items-center justify-space-between customPaper">
      <TopPanel />
      <MuiButon
        title="Save"
        loading={_loadingSaveOrder}
        onClick={saveDocument}
        disabled={operation === "Display"}
      />
    </Paper>
  );
}
