/** @format */

import React, { useContext } from "react";
import { mainArray, adminArray } from "./Fields";
import { MaterialContext } from "../../context";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
export default function LocationDetails({
  localOperation,
  selectedLocation,
  setSelectedLocation,
  setShowLocationDetails,
}) {
  const { dropDowns, _materialData, _setMaterialData, _createErrorMessage } =
    useContext(MaterialContext);
  
  const userState = useSelector((state) => state.User.user);
  const language = userState.preferredLanguage;
  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;

    switch (e.target.dataset.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setSelectedLocation((current) => {
      return { ...current, materialId: _materialData.id, ...newValueObject };
    });
  };

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: selectedLocation, onChange };

    switch (localOperation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };

  const addChangeLocation = () => {
    //Location must be supplied before accepting
    if (
      !selectedLocation.locationId ||
      selectedLocation.locationId === "select"
    ) {
      _createErrorMessage("50");
      return;
    }
    //Check if the location exists by getting its index
    let currentLocations = _materialData.locations || [];
    var index = currentLocations.findIndex(
      (x) => x.locationId === selectedLocation.locationId
    );
    if (index >= 0 && localOperation === "Create") {
      _createErrorMessage("49");
      return;
    }

    //Change material state
    _setMaterialData((current) => {
      return {
        ...current,
        locations:
          index >= 0
            ? currentLocations.map((location) =>
                location.locationId === selectedLocation.locationId
                  ? selectedLocation
                  : location
              )
            : [...currentLocations, selectedLocation],
      };
    });
    setShowLocationDetails(false);
  };

  const fieldsState = {
    dataSource: selectedLocation,
    dropDowns,
  };

  const mainFieldsToRender = mainArray(fieldsState);
  const adminFieldsRender = adminArray(fieldsState);
  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card">
            <div className="cardHeader">
              <h2>Main Data </h2>
            </div>
            <div className="cardBody">
              {mainFieldsToRender.map((field, i) => (
                <FieldsReducer field={mergedFieldData(field)} key={i} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card">
            <div className="cardHeader">
              <h2 className="parterOverview">Admin </h2>
            </div>
            <div className="cardBody">
              {adminFieldsRender.map((field, i) => (
                <FieldsReducer field={mergedFieldData(field)} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <Button
            type="button"
            data-op="Accept"
            data-id={selectedLocation}
            onClick={addChangeLocation}
            className="me-3"
          >
            Accept
          </Button>

          <Button
            type="button"
            data-op="Cancel"
            data-id={selectedLocation}
            onClick={() => {
              setShowLocationDetails(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
}
