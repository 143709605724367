/** @format */

import React, { useContext, useState } from "react";
import { mainDataArray, enhancedInputArray, quantityArray, addQuantityArray } from "./Fields";
import { InvoiceContext } from "../../Contex";
import { FieldsReducer } from "./../../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";
import MaterialField from "../../../../../CommonComponents/EnhancedFields/Material/MaterialField";
import MaterialList from "../../../../../CommonComponents/DropDowns/Material/MaterialList";
function Index({
  setShowItemsDetailsModal,
  itemOperation,
  item,
  setItem,
  handelAccept,
}) {
  const { dropDowns, _getMaterialList, _materialList } =
    useContext(InvoiceContext);
  const [showMaterialList, setShowMaterialList] = useState(false);

  const onChangeItem = (e) => {
   
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setItem((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: item,
      onChange: onChangeItem,
    };
    switch (itemOperation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: item,
    dropDowns,
  };

  const mainFieldsToRender     = mainDataArray(fieldsState);
  const quantityFieldsToRender = quantityArray(fieldsState);
  const addQuantityFieldsToRender = addQuantityArray(fieldsState);
  const enhancedFieldsToRender = enhancedInputArray(fieldsState);

  var materialField = enhancedFieldsToRender.find(
    ({ id }) => id === "materialId"
  );

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card">
            <div className="cardHeader">
              <h2>Main Data </h2>
            </div>
            <div className="cardBody">
              {mainFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card">
            <div className="cardHeader">
              <h2>Material Data</h2>
            </div>
            <div className="cardBody">
              <MaterialField
                field={mergedFieldData(materialField)}
                setShowMaterialList={setShowMaterialList}
                _getMaterialList={_getMaterialList}
              />
              {quantityFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
                            {addQuantityFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="mt-3"
        >
          <Button
          className="me-3"
          style={{background: "#DA1212", borderColor: "#DA1212"}}
            onClick={() => {
              setShowItemsDetailsModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handelAccept();
              setShowItemsDetailsModal(false);
            }}
          >
            Accept
          </Button>
        </div>
        </div>
        <div className="col-12">
          <MaterialList
          _materialList={_materialList}
          showMaterialList={showMaterialList}
          setShowMaterialList={setShowMaterialList}
          materialFieldName = "materialId"
          onChange={onChangeItem}
        />
        </div>
      </div>
    </>
  );
}

export default Index;
