import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useFetch } from "../../../../../hooks/useFetch";
import { useMessage } from "../../../../../hooks/UseMessage";
import { useUser } from "../../../../../hooks/useUser";
import { useStation } from "../../../../../hooks/UseStation";
import { useDD } from "../../../../../hooks/UseDD";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../../CommonComponents/GlobalConfFiles/URLs";
import { ReactComponent as OverLoadAlram } from "../../../../../Assets/SVGs/Alrams/AnimatedAlram1.svg";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import HeaderV2 from "../../Header/HeaderV2";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { FlipCounter } from "../../../../../CommonComponents/FlipCounter/FlipCounter";
import TruckAnimation from "../../../../../CommonComponents/TruckAnimation/index";
import { useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
function MeterLoadingV1(props) {
  const navigate = useNavigate();
  const params = useParams();
  const shipDocId = params.shipDocId;
  const fieldOperationId = "METER_LOADINGV1";

  const [loading, setLoading] = useState(false);
  const [topLog, setTopLog] = useState({});
  const [issuedAlrams, setIssuedAlrams] = useState([]);
  const [tankNumber, setTankNumber] = useState("");

  //state to indicate loading operation is done
  const [loadingFinished, setLoadingFinished] = useState(false);
  const stopStartButton = useRef(null);
  const finishButton = useRef(null);
  //needed to use useRef in order to pass the current weight to updateReading API Call
  // the "ControllerMeasurements" did not contain the correct value, it only conatins the initial value once the useEffect is run
  const currentMeterReading = useRef(0);
  const currentMeterReadingWithoutCorrection = useRef(0);
  const resetQty = useRef(0);
  const initialMeterLoadedQty = useRef(0);
  const controllerReset = useRef(false);
  const [contollerMeasurements, setContollerMeasurements] = useState({
    meterReading: 0,
    meterUnitId: "LB",
  });
  //hooks
  const [_user, ,] = useUser();

  let serviceSimulation = false;

  if (
    _user.serviceSimulation === "true" ||
    _user.serviceSimulation === "True"
  ) {
    serviceSimulation = true;
  }
  let meterApiInterval = 1000;
  let meterTestIncrement = 1000;

  const [
    _stationInfo,
    ,
    ,
    _getStationOperation,
    _stationOperation,
    _stationMessages,
    _setStationMessages,
  ] = useStation();
  if (_stationInfo.meterApiInterval > 0) {
    meterApiInterval = _stationInfo.meterApiInterval;
  }

  if (_stationInfo.meterTestIncrement > 0) {
    meterTestIncrement = _stationInfo.meterTestIncrement;
  }
  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
  ] = useMessage();

  const [_dd] = useDD();

  const [
    _getWeight,
    ,
    ,
    _requestWeightError,
    _setRequestWeightError,
    _requestWeightSuccess,
    _setRequestWeightSuccess,
    _loadingRequestWeight,
  ] = useFetch(
    GetServiceFullURL("GetWeight"),
    {
      method: "GET",
    },
    true
  );

  const [
    _resetController,
    ,
    ,
    _resetControlleError,
    _setResetControlleError,
    ,
    ,
    _loadingResetController,
  ] = useFetch(GetServiceFullURL("ResetController"), {}, true);

  const [
    _startLoad,
    _startLoadData,
    _setStartLoadData,
    _startLoadError,
    _setStartLoadError,
    ,
    ,
    _loadingStartLoad,
  ] = useFetch(GetServiceFullURL("StartLoadingController"), {
    method: "GET",
    headers: {},
  });

  const [
    _stopLoad,
    _stopLoadData,
    _setStopLoadData,
    _stopLoadError,
    _setStopLoadError,
    ,
    ,
    _loadingStopLoad,
  ] = useFetch(GetServiceFullURL("StopLoadingController"), {
    method: "GET",
    headers: {},
  });
  const [
    _getControllerData,
    _controllerData,
    _setControllerData,
    _controllerDataError,
    _setControllerDataError,
    _controllerDataSuccess,
    _setControllerDataSuccess,
    ,
  ] = useFetch(GetServiceFullURL("GetControllerData"), {
    method: "GET",
    headers: {},
  });

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    _successShipDoc,
    _setSuccessShipDoc,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" }, true);

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" }, true);

  const [_getTank, _TankData, , , , , , ,] = useFetch(
    GetFullURL("GetTank"),
    { method: "POST" },
    true
  );

  const [_updateFieldReadings, , , , , , , ,] = useFetch(
    GetFullURL("UpdateFieldReadings"),
    { method: "POST" }
  );

  function controllerSimulation() {
    if (!serviceSimulation) {
      return;
    }

    setContollerMeasurements((x) => {
      let newQty = x.meterReading + meterTestIncrement;
      return { ...x, meterReading: newQty };
    });
  }

  function GetControllerData() {
    if (!controllerReset.current) {
      return;
    }
    if (serviceSimulation) {
      return;
    }
    _setControllerDataError([]);
    _getControllerData({}, (response) => {
      setTopLog(() => {
        return { success: response.success, message: response.message };
      });
      if (response.success) {
        setContollerMeasurements((current) => {
          return { ...current, meterReading: response.data.meterReading };
        });
      }
    });
  }

  useEffect(() => {
    _getShipDoc({ data: { Id: shipDocId } }, ({ data: shipDocData }) => {
      initialMeterLoadedQty.current = shipDocData?.currentMeterReading;
      _getStationOperation(fieldOperationId, shipDocData.fieldProcessId);
      let newItems = shipDocData.shippingDocItemsComm.find((x) => {
        if (x.itemNumber == 10) {
          return x;
        }
      });

      if (newItems) {
        _getTank({ data: { Id: newItems.tankId } });
      }
    });
    return () => {
      issueStopCommand();
    };
  }, []);
  useEffect(() => {
    //on the mount re-set controller when service mode
    if (!serviceSimulation) {
      _resetController({}, (response) => {
        setTopLog(() => {
          return { success: response.success, message: response.message };
        });
        if (response.success) {
          controllerReset.current = true;
        }
      });
    }
  }, [serviceSimulation]);

  useEffect(() => {
    //Setting the max weight from station one time
    if (_shipDocData.cutoffWeight == 0) {
      //cutt off weight equal station max weight - meter adjustment
      let cutoffWeight =
        _stationInfo?.maxWeight - _stationInfo?.meterAdjustment;
      _setShipDocData((current) => {
        return { ...current, cutoffWeight: parseInt(cutoffWeight) };
      });
    }
  });

  useEffect(() => {
    //This useEffect handles the controller data in real and simulation mode

    let contrllerDataTimer = null;

    if (serviceSimulation) {
      contrllerDataTimer = setInterval(() => {
        controllerSimulation();
      }, meterApiInterval);
    } else {
      contrllerDataTimer = setInterval(() => {
        GetControllerData();
      }, meterApiInterval);
    }

    if (serviceSimulation) {
      if (!loading) {
        clearInterval(contrllerDataTimer);
      }
    }

    return () => {
      clearInterval(contrllerDataTimer);
    };
  }, [loading, serviceSimulation, _TankData]);

  useEffect(() => {
    console.log("Controller", contollerMeasurements.meterReading);
    console.log("Prior Reading", currentMeterReadingWithoutCorrection.current);
    if (
      currentMeterReadingWithoutCorrection.current >
      contollerMeasurements.meterReading
    ) {
      if (resetQty.current == 0) {
        resetQty.current = currentMeterReadingWithoutCorrection.current;
      }
    }
    console.log("Reset Qty", resetQty);
    currentMeterReadingWithoutCorrection.current =
      contollerMeasurements.meterReading;

    currentMeterReading.current =
      contollerMeasurements.meterReading +
      resetQty.current +
      initialMeterLoadedQty.current;

    // if (currentMeterReading.current > 60000) {
    //   currentMeterReading.current = currentMeterReading.current / 2;
    // }

    //Apply correction factor to meter reading
    if (_TankData?.maxVolume > 0) {
      currentMeterReading.current =
        currentMeterReading.current * _TankData?.maxVolume;
    } else if (_stationInfo.meterCorrectionFactor > 0) {
      currentMeterReading.current =
        currentMeterReading.current * _stationInfo.meterCorrectionFactor;
    }
    calculateFillPercent();
    //check for auto cut-off
    if (
      currentMeterReading.current >=
      _shipDocData.cutoffWeight - _shipDocData.inWeight
    ) {
      if (loading) {
        stopStartButton.current.click();

        let foundAlram = issuedAlrams.find((x) => x === 1);
        if (!foundAlram) {
          setIssuedAlrams((current) => [...current, 1]);
        }
      }
    }
  }, [contollerMeasurements.meterReading]);

  function UpdateMeterReading() {
    if (controllerReset.current) {
      _updateFieldReadings(
        {
          data: {
            ShipDocId: shipDocId,
            ReadingType: "METER",
            CurrentReading: currentMeterReading.current,
            ReadingUnitId: contollerMeasurements.meterUnitId,
          },
        },
        () => {}
      );
    }
  }
  useEffect(() => {
    let fieldReadingsTimer = null;

    fieldReadingsTimer = setInterval(() => {
      UpdateMeterReading();
    }, 2000);

    return () => {
      clearInterval(fieldReadingsTimer);
    };
  }, []);

  useEffect(() => {
    if (!loadingFinished) {
      return;
    }

    //Creating the next URL
    let nextUrlattribute1 = "";
    let nextUrlattribute2 = "";
    let nextUrlattribute3 = "";

    if (_stationOperation.nextUrlAttribute1) {
      try {
        nextUrlattribute1 = eval(_stationOperation.nextUrlAttribute1) || "";
      } catch (error) {
        nextUrlattribute1 = _stationOperation.nextUrlAttribute1;
      }
    }

    if (_stationOperation.nextUrlAttribute2) {
      try {
        nextUrlattribute2 = eval(_stationOperation.nextUrlAttribute2) || "";
      } catch (error) {
        nextUrlattribute2 = _stationOperation.nextUrlAttribute2;
      }
    }

    if (_stationOperation.nextUrlAttribute3) {
      try {
        nextUrlattribute3 = eval(_stationOperation.nextUrlAttribute3) || "";
      } catch (error) {
        nextUrlattribute3 = _stationOperation.current.nextUrlAttribute3;
      }
    }
    if (!nextUrlattribute1 || !nextUrlattribute2 || nextUrlattribute3) {
      navigate(`${_stationOperation.nextUrl}`);
    }
    navigate(
      `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
    );
  }, [loadingFinished]);

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessShipDoc([]);
        _setSuccessSaveShipDoc([]);
        _setControllerDataSuccess([]);
        break;
      case "error":
        _setErrorSaveShipDoc([]);
        _setErorMessages([]);
        _setErrorShipDoc([]);
        _setControllerDataError([]);
        _requestWeightSuccess([]);
        _setStartLoadError([]);
        _setStopLoadError([]);
        _setResetControlleError([]);
        _setStationMessages([]);
        break;
      default:
        break;
    }
  }

  const operationLoading =
    _loadingShipDoc ||
    _loadingSaveShipDoc ||
    _loadingStartLoad ||
    _loadingStopLoad;

  function issueStartCommand() {
    if (!serviceSimulation) {
      _setStartLoadError([]);
      _startLoad({}, (response) => {
        setTopLog(() => {
          return { success: response.success, message: response.message };
        });

        if (response.success) {
          setLoading(!loading);
          return;
        }
      });
    } else {
      setLoading(!loading);
    }
  }
  function issueStopCommand() {
    if (!serviceSimulation) {
      _setStopLoadError([]);
      _stopLoad({}, (response) => {
        setTopLog(() => {
          return { success: response.success, message: response.message };
        });
        if (response.success) {
          setLoading(!loading);
          return;
        }
      });
    } else {
      setLoading(!loading);
    }
  }

  const onClickStartStop = () => {
    if (!loading) {
      //When attampting to START
      setIssuedAlrams([]);
      //Before stating check if shipDoc is populated
      if (shipDocId == null || shipDocId == "") {
        _createErrorMessage("16");
        return;
      }
      //Before starting check in cutt-off weight is populated
      if (
        _shipDocData.cutoffWeight == null ||
        _shipDocData.cutoffWeight === 0 ||
        _shipDocData.cutoffWeight == ""
      ) {
        _createErrorMessage("15");
        return;
      }

      if (
        currentMeterReading.current + _shipDocData?.inWeight >=
        _shipDocData.cutoffWeight
      ) {
        _createErrorMessage("85");
        return;
      }

      if (_shipDocData.inWeightStatus === "") {
        _createErrorMessage("71");
        return;
      }

      if (_shipDocData.inWeight <= 10000) {
        _createErrorMessage("128", 10000);
        return true;
      }
      issueStartCommand();
      upDateShipDocStartLoad();
    } else {
      //When attampting to STOP
      issueStopCommand();
      upDateShipDocStopLoad();
    }
  };

  const calculateFillPercent = () => {
    //if max/cuttoff weight value is 0,  set % to zero because we can not divid by 0
    if (_shipDocData.cutoffWeight === 0 || !_shipDocData.cutoffWeight) {
      return 0;
    }
    //Calculate planned loadin qty
    let plannedQty = _shipDocData.cutoffWeight - _shipDocData.inWeight;
    if (plannedQty === 0) {
      return 0;
    }

    let currentQty = currentMeterReading.current;
    let percentLoaded = (currentQty * 100) / plannedQty;

    return percentLoaded.toFixed(2);
  };
  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    //Other way of getting value
    //let x = e.target.getAttribute("data-item");
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    //if item is populated then this is an update from item and hence need to search
    //for a particular item and update it

    _setShipDocData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const onChangeItem = (e) => {
    let item;
    let fieldTypeItem;
    const {
      target: { value, name },
    } = e;

    item = e.target.dataset.item;
    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeItem = e.target.dataset.type;

    let newValue;
    switch (fieldTypeItem) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    let newItems = _shipDocData.shippingDocItemsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.itemNumber == item) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setShipDocData((prevState) => {
      return { ...prevState, shippingDocItemsComm: newItems };
    });

    if (name === "tankId") {
      _getTank({ data: { Id: value } });
    }
  };

  const upDateShipDocStartLoad = () => {
    if (_shipDocData.id != null) {
      _shipDocData.loadStDT = moment().format();
      _shipDocData.loadUnloadinProcess = true;
      _shipDocData.statusId = "LOADING";
      _shipDocData.currentStation = _stationInfo.id;
      _shipDocData.loadingStationId = _stationInfo.id;
      _shipDocData.fieldDataPointId = "METER";

      _setErrorSaveShipDoc([]);
      _setSuccessSaveShipDoc([]);
      _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {
        _setShipDocData(newShipDocData);
      });
    }
  };

  const upDateShipDocFinishLoad = () => {
    if (_shipDocData.id != null) {
      _shipDocData.LoadEnDT = moment().format();
      _shipDocData.LoadStatus = "X";
      _shipDocData.loadUnloadinProcess = false;
      _shipDocData.statusId = "LOADEDSHDOC";
      _shipDocData.signatureReady = true;
      _shipDocData.currentStation = _stationInfo.id;
      _shipDocData.loadingStationId = _stationInfo.id;
      _shipDocData.fieldDataPointId = "METER";
      _shipDocData.currentMeterReading = currentMeterReading.current;
      _shipDocData.meterUnitId = contollerMeasurements.meterUnitId;
      _shipDocData.rawTemperature = contollerMeasurements.temperature;
      _shipDocData.shippingDocItemsComm.forEach((item, index) => {
        item.currentMeterReading = currentMeterReading.current;
        item.meterUnitId = contollerMeasurements.meterUnitId;
      });
      _setErrorSaveShipDoc([]);
      _setSuccessSaveShipDoc([]);
      _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {
        _setShipDocData(newShipDocData);
        setLoadingFinished(true);
      });
    }
  };
  const upDateShipDocStopLoad = () => {
    if (_shipDocData.id != null) {
      _shipDocData.LoadEnDT = moment().format();
      _shipDocData.LoadStatus = "X";
      _shipDocData.loadUnloadinProcess = false;
      _shipDocData.statusId = "LOADEDSHDOC";
      _shipDocData.signatureReady = true;
      _shipDocData.currentStation = _stationInfo.id;
      _shipDocData.loadingStationId = _stationInfo.id;
      _shipDocData.fieldDataPointId = "METER";
      _shipDocData.currentMeterReading = currentMeterReading.current;
      _shipDocData.meterUnitId = contollerMeasurements.meterUnitId;
      _shipDocData.rawTemperature = contollerMeasurements.temperature;

      _shipDocData.shippingDocItemsComm.forEach((item, index) => {
        item.currentMeterReading = currentMeterReading.current;
        item.meterUnitId = contollerMeasurements.meterUnitId;
      });
      _setErrorSaveShipDoc([]);
      _setSuccessSaveShipDoc([]);
      _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {
        _setShipDocData(newShipDocData);
      });
    }
  };
  const finishButtonHandel = () => {
    if (loading) {
      _createErrorMessage("30");
      return;
    }

    //if the finished button is pressed go a head issue the stop command in case the user
    //did not hit stop
    issueStopCommand();
    upDateShipDocFinishLoad();
  };
  const setStartStopButtonColor = () => {
    if (loading) {
      return "red";
    } else {
      return "#08bd55";
    }
  };
  let error = "bg-danger";
  let success = "bg-success";
  let errorClass = `w-100 rounded-lg px-5 py-3 ${error} d-flex align-items-center justify-content-center`;
  let successClass = `w-100 rounded-lg px-5 py-3 ${success} d-flex align-items-center justify-content-center`;

  return (
    <>
      <section>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-12 p-0">
              <HeaderV2 _shipDocData={_shipDocData} weightType={null} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0">
              {topLog.success ? (
                <div className="col-12">
                  <div className={successClass}>
                    <span className="text-white"> {topLog.message}</span>
                  </div>
                </div>
              ) : (
                <div className="col-12">
                  <div className={errorClass}>
                    <span className="text-white"> {topLog.message}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-12 col-xs-12">
              <h3 style={{ fontSize: "40px" }}>Meter Loading</h3>
              <Sidebar
                _shipDocDDData={_dd.shipDoc}
                onChangeItem={onChangeItem}
                onChange={onChange}
                _shipDocData={_shipDocData}
                _stationInfo={_stationInfo}
              />
              {issuedAlrams.map((item, index) => {
                switch (item) {
                  case 1:
                    return (
                      <div className="row d-flex flex-row">
                        <OverLoadAlram />
                        <h3> OverLoad</h3>
                      </div>
                    );

                  default:
                  // code block
                }
              })}
            </div>

            <div className="col-lg-7 col-sm-12 col-xs-12">
              <div className="" style={{ flex: 1 }}>
                <div className="d-flex justify-content-end">
                  <FlipCounter
                    number={currentMeterReading.current}
                    className="mb-2"
                    className2="mt-4"
                    backgroundColorClassName="bg-info"
                    title="Meter"
                  />
                </div>
                <div className="d-flex justify-content-end mt-5">
                  <div className="mx-3" style={{ width: "85%" }}>
                    <TruckAnimation
                      compartCount={1}
                      activeIndex={0}
                      fillPerCent={calculateFillPercent()}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <div
                    className="d-flex justify-content-end"
                    style={{ gap: "3rem" }}
                  >
                    <Button
                      className="px-3 py-3 mt-5 w-50"
                      style={{
                        background: "#0e87cc",
                        borderColor: "#08bd55",
                        color: "black",
                        fontSize: "40px",
                        fontWeight: "bold",
                      }}
                      ref={finishButton}
                      onClick={finishButtonHandel}
                    >
                      Finish
                    </Button>
                    <Button
                      className="px-5 py-3 mt-5 w-50"
                      onClick={onClickStartStop}
                      ref={stopStartButton}
                      style={{
                        background: setStartStopButtonColor(),
                        color: "black",
                        borderColor: "#08bd55",
                        fontSize: "40px",
                        fontWeight: "bold",
                      }}
                    >
                      {loading ? "STOP" : "START"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <MessageLog
            errorMessages={[
              ..._errorShipDoc,
              ..._errorMessages,
              ..._errorSaveShipDoc,
              ..._controllerDataError,
              ..._stationMessages,
            ]}
            successMessages={[..._successShipDoc]}
            clearMessages={clearMessages}
          ></MessageLog>
        </div>
      </section>
    </>
  );
}

export default MeterLoadingV1;
