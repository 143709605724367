import React from "react";
import InfoBlock from "../../../../CommonComponents/InfoBlock";

function HeaderV5({
  _shipDocData,
  controllerPort,
  leftScalePort,
  rightScalePort,
}) {
  return (
    <div
      style={{
        padding: "0.5rem 1rem",
        backgroundColor: "#fff",
        boxShadow: "rgba(100 ,100 ,111 ,20%) 0px 7px 29px 0px",
        marginBottom: "1rem",
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      <div
        className="d-flex justify-content-between"
        style={{ maxWidth: "100%", overflow: "auto", whiteSpace: "nowrap" }}
      >
        <div
          style={{
            width: "25x",
            height: "25px",
            backgroundColor: controllerPort ? "green" : "red",
            borderRadius: "50%",
          }}
        >
          CT
        </div>
        <div
          style={{
            width: "25x",
            height: "25px",
            backgroundColor: leftScalePort ? "green" : "red",
            borderRadius: "50%",
          }}
        >
          LS
        </div>
        <div
          style={{
            width: "25x",
            height: "25px",
            backgroundColor: rightScalePort ? "green" : "red",
            borderRadius: "50%",
          }}
        >
          RS
        </div>
        <InfoBlock title="BOL" value={_shipDocData.id} />
        <InfoBlock title="In Weight" value={_shipDocData.inWeight} />
        <InfoBlock title="Out Weight" value={_shipDocData.outWeight} />
        <InfoBlock title="Cutoff Weight" value={_shipDocData.cutoffWeight} />
        <InfoBlock title="Driver" value={_shipDocData.driverDescriptor} />
        <InfoBlock title="Pin" value={_shipDocData.pin} />
      </div>
    </div>
  );
}

export default HeaderV5;
