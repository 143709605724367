import React from "react";
export default function StationSelect({
  operation,
  options = [],
  station,
  setStation,
}) {
  let value;
  //Get field object that defines all of field attributes
  value = station;

  // Update the Material state from the change event
  function updateState(e) {
    let newValue;
    let val = e.target.value;

    if (val === "select") {
      newValue = "";
    } else {
      newValue = val;
    }

    setStation(newValue);
  }

  function setValue() {
    if (value) {
      return value;
    }

    if (options == null) {
      return "";
    }
  }

  const fieldStatus = (operation) => {
    switch (operation) {
      case "Display":
        return true;
      default:
        return false;
    }
  };

  return (
    <div className="mb-3 row my-3">
      <label
        className="col-sm-4 col-form-label"
        htmlFor="Role"
        style={{
          fontWeight: 900,
        }}
      >
        Station
      </label>

      <select
        type={"text"}
        className="form-control col-sm-7"
        id="Role"
        aria-describedby="emailHelp"
        placeholder="Enter key"
        disabled={fieldStatus(operation)}
        onChange={updateState}
        value={setValue()}
        style={{
          borderRadius: 8,
        }}
        defaultValue={"select"}
      >
        <option value="select">--- SELECT ---</option>
        {options
          ? options.map((item, i) => {
              return (
                <option key={i} value={item.id}>
                  {item.nameLocalizedText}
                </option>
              );
            })
          : null}
      </select>
    </div>
  );
}
