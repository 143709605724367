exports.Element = [
  {
    id: "key",
    name: "key",
    type: "string",
    value: "",
    label: "Key",
    key: "true",
  },
  {
    id: "id",
    name: "id",
    type: "number",
    value: "",
    label: "Id",
    key: "true",
  },
  {
    id: "nameLocalizedText",
    name: "nameLocalizedText",
    type: "text",
    value: "",
    label: "nameLocalizedText",
  },
  {
    id: "elementType",
    name: "elementType",
    type: "number",
    value: "",
    label: "Element Type",
    ddid: "ElementType",
  },
  {
    id: "isVisible",
    name: "isVisible",
    type: "boolean",
    value: "",
    label: "Visible",
  },
  {
    id: "isEnabled",
    name: "isEnabled",
    type: "boolean",
    value: "",
    label: "Enabled",
  },
  {
    id: "isRequired",
    name: "isRequired",
    type: "boolean",
    value: "",
    label: "Required",
  },
  {
    id: "dataField",
    name: "dataField",
    type: "text",
    value: "",
    label: "Data Field",
  },
];
