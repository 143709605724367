import React from "react";
import Button from '@mui/material/Button';
import { ReactComponent as PullFromSAP } from "../../../Assets/NewIconsForCards/PullFromSAP.svg";
import { ReactComponent as SyncWithSAP } from "../../../Assets/NewIconsForCards/SyncWithSAP.svg";
import { ReactComponent as ReactSendToSAPCard } from "../../../Assets/NewIconsForCards/SendToSAP.svg";
import Card from "../../Components/Card";

//Business Process Dash
const Arr = [
  {
    title: "Pull Order from SAP",
    text: "",
    Icon: PullFromSAP,
    link: "/ERPOperartions/GetERPOrder",
  },
  {
    title: "Orders Sync From SAP",
    text: "",
    Icon: SyncWithSAP,
    link: "/ERPOperartions/OrderSync",
  },
  {
    title: "Send ShipDoc to SAP",
    text: "",
    Icon: ReactSendToSAPCard,
    link: "/SendSingleShipDocERP",
  },
  {
    title: "Send All BOLs to SAP",
    text: "",
    Icon: ReactSendToSAPCard,
    link: "/SendAllShipDocsERP",
  },
];

export default function SpecialProcesses() {
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {Arr.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
