exports.PartnerType = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    length: 12,
    key: "true",
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    value: "",
    label: "Universal Descriptor",
  },

  {
    id: "systemPartnerType",
    name: "systemPartnerType",
    type: "number",
    value: "",
    label: "Internal Partner Type",
    ddid: "SystemPartnerTypeOptions",
  },
  {
    id: "numberRangeId",
    name: "numberRangeId",
    type: "text",
    value: "",
    label: "Number Range",
    ddid: "partnerNumberRangeId",
  },
  {
    id: "localText",
    name: "localText",
    type: "text",
    value: "",
    label: "localText",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
