import React, { useState, useEffect } from "react";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import HomeTab from "./Main/Index";
import AdminTab from "./Admin/Index";
import TankPhysicalHeader from "./Header/index";
import { TankPhysicalContext } from "./context";
import { useFetch } from "../../../hooks/useFetch";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen";
import TabPannel from "../../../CommonComponents/TabPannel/index";
import { useParams } from "react-router-dom";
import { useMessage } from "../../../hooks/UseMessage";
import { useUser } from "../../../hooks/useUser";
export default function TankPhysicalMain(props) {
  const [value, setValue] = useState(0);
  const [operation, setOperation] = useState("");
  const [tankPhysicalId, setTankPhysicalId] = useState("");
  const [frontendError, setFrontendError] = useState([]);
  const [_createErrorMessage, _errorMessages, _setErrorMessages] = useMessage();
  const params = useParams();
  const [_user] = useUser();
  const [
    _getTankPhysicalData,
    _tankPhysicalData,
    _setTankPhysicalData,
    _errorTankPhysicalData,
    _setErrorTankPhysicalData,
    _successTankPhysicalData,
    _setSuccessTankPhysicalData,
    _loadingTankPhysicalData,
  ] = useFetch(GetFullURL("GetTankPhysical"), { method: "POST" });
  const [
    _getTankPhysicalDD,
    _tankPhysicalDD,
    ,
    _errorTankPhysicalDD,
    _setErrorTankPhysicalDD,
    _successTankPhysicalDD,
    _setSuccessTankPhysicalDD,
    _loadingtTankPhysicalDD,
  ] = useFetch(GetFullURL("TankPhysicalDropdowns"), { method: "POST" });
  const [
    _saveTankPhysical,
    ,
    ,
    _errorSaveTankPhysical,
    _setErrorSaveTankPhysical,
    _successSaveTankPhysical,
    _setSuccessSaveTankPhysical,
    _loadingSaveTankPhysical,
  ] = useFetch(GetFullURL("SaveTankPhysical"), { method: "POST" });

  const [
    _getMaterialList,
    _materialList,
    _setMaterialList,
    _errorMaterialList,
    _SetErrorMaterialList,
    _successMaterialList,
    _setSuccessMaterialList,
    _loadingMaterialList,
  ] = useFetch(GetFullURL("MaterialList"), { method: "POST" });

  const [
    _getTankList,
    _tankList,
    ,
    _errorTankList,
    _setErrorTankList,
    _successTankList,
    _setSuccessTankList,
    _loadingTankList,
  ] = useFetch(GetFullURL("TankList"), { method: "POST" });

  useEffect(() => {
    //when componen mounts,set ID and operation
    if (params.op) {
      setOperation(params.op);
    }
    if (params.id) {
      setTankPhysicalId(params.id);
    }
  }, []);

  const tempDefault = () => {
    if (!_tankPhysicalData.temperatureUnitId) {
      return _user.defaultTemperatureUnit;
    }
  };
  const locationDefault = () => {
    if (!_tankPhysicalData.locationId) {
      return _user.defaultLocationId;
    }
  };

  useEffect(() => {
    _setTankPhysicalData((current) => ({
      ...current,
      temperatureUnitId: tempDefault(),
      locationId: locationDefault(),
    }));
  }, [params.op]);
  useEffect(() => {
    _getTankPhysicalDD();
    if (tankPhysicalId) {
      _getTankPhysicalData({ data: { id: tankPhysicalId } });
    }
  }, [tankPhysicalId]);

  const handleChange = (event, newValue) => setValue(newValue);

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;
    let newValue;

    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = "true") : (newValue = "false");
        break;

      default:
        newValue = value;
        break;
    }

    if (newValue === "select" || newValue === "SELECT") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    _setTankPhysicalData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const contextState = {
    onChange,
    operation,
    dropDowns: _tankPhysicalDD || [],
    tankPhysicalId,
    _tankPhysicalData,
    _setTankPhysicalData,
    frontendError,
    _getMaterialList,
    _materialList,
    setOperation,
    setFrontendError,
    _getTankList,
    _tankList,
    _createErrorMessage,
  };

  const operationLoading =
    _loadingTankPhysicalData ||
    _loadingtTankPhysicalDD ||
    _loadingMaterialList ||
    _loadingTankList ||
    _loadingSaveTankPhysical;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveTankPhysical([]);
        _setSuccessTankPhysicalData([]);
        _setSuccessMaterialList([]);
        _setSuccessTankPhysicalDD([]);
        _setSuccessTankList([]);

        break;
      case "error":
        _setErrorTankPhysicalData([]);
        _setErrorTankPhysicalDD([]);
        _SetErrorMaterialList([]);
        _setErrorSaveTankPhysical([]);
        _setErrorTankList([]);
        setFrontendError([]);
        _setErrorMessages([]);
        break;
      default:
        break;
    }
  }

  return (
    <TankPhysicalContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <TankPhysicalHeader
        _loadingSaveTankPhysicalData={_loadingSaveTankPhysical}
        _saveTankPhysical={_saveTankPhysical}
      />
      <TabPannel
        value={value}
        handleChange={handleChange}
        labels={["Home", "Admin", "Log"]}
        components={[HomeTab, AdminTab]}
      />
      <MessageLog
        errorMessages={[
          ..._errorTankPhysicalDD,
          ..._errorTankPhysicalData,
          ..._errorMaterialList,
          ..._errorSaveTankPhysical,
          ...frontendError,
          ..._errorTankList,
          ..._errorMessages,
        ]}
        successMessages={[
          ..._successTankPhysicalDD,
          ..._successTankPhysicalData,
          ..._successMaterialList,
          ..._successSaveTankPhysical,
          ..._successTankList,
        ]}
        clearMessages={clearMessages}
      ></MessageLog>
    </TankPhysicalContext.Provider>
  );
}
