import React, { useContext, useState } from "react";
import { mainDataArray ,  bolDataArray , dateDataArray} from "./Fields";
import { InventoryDocContext } from "../Contex";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";

export default function MainTab() {
  const {
    onChange,
    operation,
    dropDowns,
    _inventoryDocData,
    _partnerList,
    _getPartnerList,
  } = useContext(InventoryDocContext);



  const [showList, setShowList] = useState(false);
  const [partnerFieldName, setPartnerFieldName] = useState();
  

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _inventoryDocData, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _inventoryDocData,
    dropDowns,
  };

  const mainDataFieldsToRender = mainDataArray(fieldsState);
  const bolDataFieldsToRender = bolDataArray(fieldsState);
  const dateDataFieldsToRender = dateDataArray(fieldsState);


  return (
   <>
      <div className="row">
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Main Data</h2>
            </div>
            <div className="cardBody">
              {mainDataFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>BOL Data</h2>
            </div>
            <div className="cardBody">
              {bolDataFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Date Data</h2>
            </div>
            <div className="cardBody">
              {dateDataFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
      </div>
    
      </div>
  </>
   
  );
}
