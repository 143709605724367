import React from "react";
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";

//Terminals Home
import TerminalsHome from "./../Home/TerminalOperations/TerminalsHome";

//Woods Cross Screens
import WXTerminalHome from "../Home/TerminalOperations/HollyFrontier/WoodsCross/WXTerminalHome";
import WXAdminHome from "../Home/TerminalOperations/HollyFrontier/WoodsCross/WXAdminHome";

//Tulsa East Screens
import TETerminalHome from "../Home/TerminalOperations/HollyFrontier/TulsaEast/TETerminalHome";
import TEAdminHome from "../Home/TerminalOperations/HollyFrontier/TulsaEast/TEAdminHome";

//Glendale
import GlendaleTerminalHome from "../Home/TerminalOperations/HollyFrontier/Glendale/GlendaleTerminalHome";
import GlendaleAdminHome from "../Home/TerminalOperations/HollyFrontier/Glendale/GlendaleAdminHome";

import EldoCokeScaleStationHome from "../Home/TerminalOperations/HollyFrontier/EldoOrerations/EldoCokeScaleStationHome";
import EldoSignatureStation from "../Home/TerminalOperations/HollyFrontier/EldoOrerations/EldoSignatureStation";
import EldoAdminHome from "../Home/TerminalOperations/HollyFrontier/EldoOrerations/EldoAdminHome";

import EldoTerminalHome from "../Home/TerminalOperations/HollyFrontier/EldoOrerations/EldoTerminalHome";
import EldoTerminalCokeSulfurHome from "../Home/TerminalOperations/HollyFrontier/EldoOrerations/EldoTerminalCokeSulfurHome ";
import EldoTerminalAsphaltsHome from "../Home/TerminalOperations/HollyFrontier/EldoOrerations/EldoTerminalAsphaltsHome";
import ELSulfurHome from "../Home/TerminalOperations/HollyFrontier/EldoOrerations/ELSulfurHome";
function TerminalHomeRoutes() {
  return (
    <Routes>
      <Route
        exact
        path="/TerminalActivity/TerminalsHome"
        element={
          <RequireAuth>
            <TerminalsHome />
          </RequireAuth>
        }
      />

      <Route
        exact
        path="/TerminalActivity/WXTerminalHome"
        element={
          <RequireAuth>
            <WXTerminalHome />
          </RequireAuth>
        }
      />

      <Route
        exact
        path="/TerminalActivity/WXAdminHome"
        element={
          <RequireAuth>
            <WXAdminHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/TETerminalHome"
        element={
          <RequireAuth>
            <TETerminalHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/TEAdminHome"
        element={
          <RequireAuth>
            <TEAdminHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/GLNTerminalHome"
        element={
          <RequireAuth>
            <GlendaleTerminalHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/GLNAdminHome"
        element={
          <RequireAuth>
            <GlendaleAdminHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/EldoTerminalHome"
        element={
          <RequireAuth>
            <EldoTerminalHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/EldoCokeScaleStationHome"
        element={
          <RequireAuth>
            <EldoCokeScaleStationHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/EldoSignatureStation"
        element={
          <RequireAuth>
            <EldoSignatureStation />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/EldoAdminHome"
        element={
          <RequireAuth>
            <EldoAdminHome />
          </RequireAuth>
        }
      />

      <Route
        exact
        path="/TerminalActivity/EldoCokeSulfurHome"
        element={
          <RequireAuth>
            <EldoTerminalCokeSulfurHome />
          </RequireAuth>
        }
      />

      <Route
        exact
        path="/TerminalActivity/EldoAsphaltsHome"
        element={
          <RequireAuth>
            <EldoTerminalAsphaltsHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ELSulfurHome"
        element={
          <RequireAuth>
            <ELSulfurHome />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default TerminalHomeRoutes;
