import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";



export const ExportToExcel = ({ apiData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    ws['!cols'] = [
        {width: 10 },
        {width: 25 },
        {width: 25 },
        {width: 25 },
        {width: 45 },
        {width: 10 },
        {width: 10 },
        {width: 10 },
        {width: 5 },
        {width: 45 },
        {width: 45 },
        {width: 10 },
        {width: 10 },
        {width: 50 },
    ];
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button className='btn btn-primary' style={{
      width: "32%"
    }} onClick={(e) => exportToCSV(apiData, fileName)}>Export</button>
  );
};