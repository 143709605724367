import { Implementation } from "./Fields/Implementation";
import { Currency } from "./Fields/Currency";
import { Incoterm } from "./Fields/Incoterm";
import { BusinessObject } from "./Fields/BusinessObject";
import { ActivityType } from "./Fields/ActiveType";
import { Activity } from "./Fields/Activity";
import { Element } from "./Fields/Element";
import { Component } from "./Fields/Component";
import { ImplementationUse } from "./Fields/ImplementationUse";
import { ImplementationType } from "./Fields/ImplementationType";
import { TransportationMode } from "./Fields/TransportationMode";
import { Company } from "./Fields/Company";
import { Location } from "./Fields/Location";
import { Country } from "./Fields/Country";
import { User } from "./Fields/User";
import { Language } from "./Fields/Language";
import { MessageClass } from "./Fields/MessageClass";
import { Message } from "./Fields/Message";
import { Station } from "./Fields/Station";
import { FieldDataPointType } from "./Fields/FieldDataPointType";
import { MeasurementSource } from "./Fields/MeasurementSource";
import { MeasurementType } from "./Fields/MeasurementType";
import { StationFDP } from "./Fields/StationFDP";
import { FieldDataPoint } from "./Fields/FieldDataPoint";
import { Unit } from "./Fields/Unit";
import { UnitType } from "./Fields/UnitType";
import { Region } from "./Fields/Region";
import { IsoUnit } from "./Fields/IsoUnit";
import { LocationType } from "./Fields/LocationType";
import { MaterialType } from "./Fields/MaterialType";
import { MaterialGroup } from "./Fields/MaterialGroup";
import { UnitConversion } from "./Fields/UnitConversion";
import { PartnerRole } from "./Fields/PartnerRole";
import { NumberRange } from "./Fields/NumberRange";
import { PartnerType } from "./Fields/PartnerType";
import { DocumentCategory } from "./Fields/DocumentCategory";
import { DocumentType } from "./Fields/DocumentType";
import { StatusClass } from "./Fields/StatusClass";
import { StatusType } from "./Fields/StatusType";
import { StatusUse } from "./Fields/StatusUse";
import { BusinessObjectCategory } from "./Fields/BusinessObjectCategory";
import { OrganizationUnit } from "./Fields/OrganizationUnit";
import { Source } from "./Fields/Source";
import { ValidationCondition } from "./Fields/ValidationCondition";
import { Feed } from "./Fields/Feed";
import { MoveType } from "./Fields/MoveType";
import { TransactionCategory } from "./Fields/TransactionCategory";
import { TransactionDateDetermination } from "./Fields/TransactionDateDetemination";
import { TankFarm } from "./Fields/TankFarm";
import { TankGroup } from "./Fields/TankGroup";
import { View } from "./Fields/View";
import { TASGlobal } from "./Fields/TASGlobal";
import { DataOrigin } from "./Fields/DataOrigin";
import { Printer } from "./Fields/Printer";
import { PartnerLocation } from "./Fields/PartnerLocation";
import { Event } from "./Fields/Event";
import { ShipDocEvent } from "./Fields/ShipDocEvent";
import { CurrentFieldData } from "./Fields/CurrentFieldData";
import { FieldOperation } from "./Fields/FieldOperation";
import { StationOperation } from "./Fields/StationOperation";
import { StationProcessing } from "./Fields/StationProcessing";
import { ShipDocType } from "./Fields/ShipDocType";
import { OrderType } from "./Fields/OrderType";
import { FieldProcess } from "./Fields/FieldProcess";
import { ShipDocNumberRange } from "./Fields/ShipDocNumberRange";
import { PartnerNumberRange } from "./Fields/PartnerNumberRange";
import { OrderNumberRange } from "./Fields/OrderNumberRange";
import { MaterialNumberRange } from "./Fields/MaterialNumberRange";
import { StationTerminalSetup } from "./Fields/StationTerminalSetup";
import { OrderStatus } from "./Fields/OrderStatus";
import { ShipDocStatus } from "./Fields/ShipDocStatus";
import { DangerousGoodsClass } from "./Fields/DangerousGoodsClass";
import { Report } from "./Fields/Report";
import { CommGroupAssigment } from "./Fields/CommGroupAssigment";
import { CommGroup } from "./Fields/CommGroup";
import { PartnerBlockCode } from "./Fields/PartnerBlockCode";
import { ShipDocVoidStatus } from "./Fields/ShipDocVoidStatus";
import { OrderVoidStatus } from "./Fields/OrderVoidStatus";
import { ModeOfTransportType } from "./Fields/ModeOfTransportType";
import { DataTranslationType } from "./Fields/DataTranslationType";
import { DataFormat } from "./Fields/DataFormat";
import { SubLocationType } from "./Fields/SubLocationType";
import { SubLocation } from "./Fields/SubLocation";
import { InvDocNumberRange } from "./Fields/InvDocNumberRange";
import { InvDocType } from "./Fields/InvDocType";
import { StageProcedure } from "./Fields/StageProcedure";
import { StageProcedureStep } from "./Fields/StageProcedureStep";
import { AccountType } from "./Fields/AccountType";
import { Account } from "./Fields/Account";
import { VehicleType } from "./Fields/VehicleType";
import { HomeBase } from "./Fields/HomeBase";
import { Fleet } from "./Fields/Fleet";
import { AccountNumberRange } from "./Fields/AccountNumberRange";
import { VehicleNumberRange } from "./Fields/VehicleNumberRange";
import { BillOfMaterialNumberRange } from "./Fields/BillOfMaterialNumberRange";
import { BillOfMaterialType } from "./Fields/BillOfMaterialType";
import { InvoiceType } from "./Fields/InvoiceType";
import { InvoiceStatus } from "./Fields/InvoiceStatus";
import { InvoiceNumberRange } from "./Fields/InvoiceNumberRange";
import { InvoiceVoidStatus } from "./Fields/InvoiceVoidStatus";
import { FinancialDocType } from "./Fields/FinancialDocType";
import { FinancialDocNumberRange } from "./Fields/FinancialDocNumberRange";
import { LeaseNumberRange } from "./Fields/LeaseNumberRange";
import { LogisticStationNumberRange } from "./Fields/LogisticStationNumberRange";
import { LogisticStationType } from "./Fields/LogisticStationType";
import { LeaseType } from "./Fields/LeaseType";
import { LoadBay } from "./Fields/LoadBay";
import { LoadArea } from "./Fields/LoadArea";
import { LoadArm } from "./Fields/LoadArm";
import { DeterLTFMTermSetup } from "./Fields/DeterLTFMTermSetup";
import { UserType } from "./Fields/UserType";
import { Global } from "./Fields/Global";
import { BackgroundJob } from "./Fields/BackgroundJob";
import { OutboundStatus } from "./Fields/OutboundStatus";
import { TransmissionMethod } from "./Fields/TransmissionMethod";
import { OutboundType } from "./Fields/OutboundType";
import { OutboundProcedureDetail } from "./Fields/OutboundProcedureDetail";
import { OutboundProcedure } from "./Fields/OutboundProcedure";
import { State } from "./Fields/State";
import { DocumentClass } from "./Fields/DocumentClass";
import { StationType } from "./Fields/StationType";
import { TestMethodClass } from "./Fields/LabSystem/TestMethod/TestMethodClass";
import { TestMethodSource } from "./Fields/LabSystem/TestMethod/TestMetodSource";
import { ProductGroup } from "./Fields/LabSystem/Product/ProductGroup";
import { PhysicalDocNumberRange } from "./Fields/PhysicalDocNumberRange";
import { PhysicalDocType } from "./Fields/PhysicalDocType";
import { LabTestType } from "./Fields/LabSystem/Lab Test/LabTestType";
import { LabTestNumberRange } from "./Fields/LabSystem/Lab Test/LabTestNumberRange";

let tableFields = {
  LabTestNumberRange,
  LabTestType,
  PhysicalDocNumberRange,
  PhysicalDocType,
  ProductGroup,
  TestMethodSource,
  TestMethodClass,
  StationType,
  DocumentClass,
  OutboundProcedureDetail,
  OutboundProcedure,
  OutboundStatus,
  TransmissionMethod,
  OutboundType,
  BackgroundJob,
  Global,
  UserType,
  LoadArm,
  LoadArea,
  LoadBay,
  LeaseNumberRange,
  LogisticStationNumberRange,
  LogisticStationType,
  LeaseType,
  FinancialDocNumberRange,
  FinancialDocType,
  InvoiceVoidStatus,
  InvoiceNumberRange,
  InvoiceStatus,
  InvoiceType,
  BillOfMaterialType,
  BillOfMaterialNumberRange,
  VehicleNumberRange,
  AccountNumberRange,
  VehicleType,
  HomeBase,
  Fleet,
  Account,
  AccountType,
  StageProcedureStep,
  StageProcedure,
  Implementation,
  Feed,
  Currency,
  Incoterm,
  BusinessObject,
  ActivityType,
  Activity,
  Element,
  Component,
  ImplementationUse,
  ImplementationType,
  TransportationMode,
  Company,
  Location,
  Country,
  User,
  Language,
  MessageClass,
  Message,
  Station,
  FieldDataPointType,
  MeasurementSource,
  MeasurementType,
  StationFDP,
  FieldDataPoint,
  Unit,
  UnitType,
  Region,
  IsoUnit,
  LocationType,
  MaterialType,
  MaterialGroup,
  UnitConversion,
  PartnerRole,
  NumberRange,
  PartnerType,
  DocumentCategory,
  DocumentType,
  StatusClass,
  StatusType,
  StatusUse,
  BusinessObjectCategory,
  OrganizationUnit,
  Source,
  ValidationCondition,
  MoveType,
  TransactionCategory,
  TransactionDateDetermination,
  TankFarm,
  TankGroup,
  View,
  TASGlobal,
  DataOrigin,
  Printer,
  PartnerLocation,
  Event,
  ShipDocEvent,
  CurrentFieldData,
  FieldOperation,
  StationOperation,
  StationProcessing,
  ShipDocType,
  OrderType,
  FieldProcess,
  ShipDocNumberRange,
  PartnerNumberRange,
  OrderNumberRange,
  MaterialNumberRange,
  StationTerminalSetup,
  ShipDocStatus,
  OrderStatus,
  DangerousGoodsClass,
  Report,
  CommGroupAssigment,
  CommGroup,
  OrderVoidStatus,
  ShipDocVoidStatus,
  PartnerBlockCode,
  ModeOfTransportType,
  DataTranslationType,
  DataFormat,
  SubLocationType,
  SubLocation,
  InvDocNumberRange,
  InvDocType,
  DeterLTFMTermSetup,
  State,
};
export default tableFields;
