import React from "react";
import PartnerRelationsGridHeader from "./PartnerRelationsGridHeader";
import PartnerRelationsGridBody from "./PartnerRelationsGridBody";
import { partnerRelationGridFields } from "./PartnerRelationGridFields";
function PartnerRelationsGrid({ updatePartnerRelations }) {
  return (
    <div>
      <div className="col-12">
        <div style={{ overflow: "auto" }}>
          <table className="table table-striped text-nowrap custom-table">
            <PartnerRelationsGridHeader fields={partnerRelationGridFields} />
            <PartnerRelationsGridBody
              fields={partnerRelationGridFields}
              updatePartnerRelations={updatePartnerRelations}
            />
          </table>
        </div>
      </div>
    </div>
  );
}

export default PartnerRelationsGrid;
