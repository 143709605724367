export const mainDataArray = ({ dropDowns }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Order ID",
    key: true,
  },
  {
    id: "orderDate",
    name: "orderDate",
    type: "datetime",
    label: "Order Date",
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    label: "Location",
    tag: "select",
    options: dropDowns?.locations || [],
  },
  {
    id: "orderTypeId",
    name: "orderTypeId",
    type: "text",
    label: "Order Type",
    tag: "select",
    options: dropDowns?.orderTypes || [],
    editableCreateONLY: true,
  },
];

export const partnersArray = () => [
  {
    id: "shiptoId",
    name: "shiptoId",
    label: "Shipto",
  },
  {
    id: "soldtoId",
    name: "soldtoId",
    label: "Soldto",
  },
  {
    id: "driverId",
    name: "driverId",
    label: "Driver",
  },
  {
    id: "carrierId",
    name: "carrierId",
    label: "Carrier",
  },
  {
    id: "leaseId",
    name: "leaseId",
    label: "Lease",
  },
];
export const shippingArray = ({ dropDowns }) => [
  {
    id: "incotermId",
    name: "incotermId",
    tag: "select",
    label: "Incoterms",
    options: dropDowns?.incoterms || [],
  },
  {
    id: "incotermExtension",
    name: "incotermExtension",
    type: "text",
    label: "Incoterm Extension",
  },
  {
    id: "transportationModeId",
    name: "transportationModeId",
    type: "text",
    tag: "select",
    label: "MoT",
    options: dropDowns?.moTs || [],
  },
  {
    id: "purchaseOrder",
    name: "purchaseOrder",
    type: "text",
    label: "Purchase Order",
  },
];
