import React from "react";
import Card from "../../../Home/Components/Card";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BoMIcon } from "../../../Assets/NewIconsForCards/BoMIcon.svg";

const Arr = [
    {
        title: "Bill Of Material",
        text: "",
        Icon: BoMIcon,
        link: "/BillOfMaterialGeneral",
      },
];

export default function BillOfMaterialOverview() {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {Arr.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
