export const carrierArray = ({ dropDowns, dataSource, onChange }) => [
    {
      id: "carrierId",
      name: "carrierId",
      type: "text",
      label: "Carrier",
      // tag: "select",
      tag: "autoComplete",
      options: dropDowns?.carriers || [],
      dataSource,
      onChange,
    },
    {
        id: "driverId",
        name: "driverId",
        type: "text",
        label: "Driver",
        options: dropDowns?.drivers || [],
        // tag: "select",
        tag: "autoComplete",
        dataSource,
        onChange,
      },
];
export const shipToArray = ({ dropDowns, dataSource, onChange }) => [
    {
      id: "shiptoId",
      name: "shiptoId",
      type: "text",
      label: "Ship To",
      dataSource,
      onChange,
    },
];
export const vehicleArray = ({ dropDowns, dataSource, onChange }) => [
    {
      id: "vehicleId",
      name: "vehicleId",
      type: "text",
      label: "Vehicle",
      tag: "inputKeyboard",
      placeHolder: "Enter Vehicle",
      dataSource,
      onChange,
    },
    {
      id: "trailerId",
      name: "trailerId",
      type: "text",
      label: "Trailer",
      dataSource,
      onChange,
    }
];

export const productArray = ({ dropDowns, dataSource, onChange }) => [
    {
      id: "midWeight",
      name: "midWeight",
      type: "number",
      label: "MidWeight",
      dataSource,
      onChange,
    },
    {
        id: "midWeight",
        name: "midWeight",
        type: "number",
        label: "MidWeight",
        dataSource,
        onChange,
    },
];
  