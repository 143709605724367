import React from "react";

export default function IndividualLight({ color, on, key }) {
  const brightness = on ? 2 : 0.75;
  let shadow = "";

  switch (color) {
    case "#b30000":
      shadow = "0 0 6em #ff3333";
      break;
    case "#b2b300":
      shadow = "0 0 6em #ffff33";
      break;
    case "#00b300":
      shadow = "0 0 6em #33ff33";
      break;

    default:
    // code block
  }
  return (
    <div
      className="circle"
      style={{
        backgroundColor: color,
        filter: `brightness(${brightness})`,
        boxShadow: on && shadow,
      }}
    ></div>
  );
}
