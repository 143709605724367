import React from "react";
import "./index.css";
export function FlipCounter({
  number,
  className,
  className2,
  backgroundColorClassName,
  title,
}) {
  const styles = {
    main: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "raw",
    },
  };
  return (
    <>
      <div className={"flipCounter " + className + " " + className2}>
        <div className="counterTitle">
          <h5>{title}</h5>
        </div>
        {number == null ? (
          <div>No Data Received</div>
        ) : (
          <div style={styles.main}>
            {number
              .toString()
              .split("")
              .map((digit, index) => (
                <div
                  className={"number " + backgroundColorClassName}
                  key={index}
                >
                  {digit}
                </div>
              ))}
            <h3>LB.</h3>
          </div>
        )}
      </div>
    </>
  );
}
