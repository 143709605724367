import React, { useContext } from "react";
import { ShipDocContext } from "../ShippingDcoumentMain/Contex";

export default function TopPanel() {
  const { operation, shipDocId } = useContext(ShipDocContext);

  var shipDocInfo;
  switch (operation) {
    case "Display":
      shipDocInfo = `${operation} Shipping Document: ${shipDocId}`;
      break;
    case "Change":
      shipDocInfo = `${operation} Shipping Document: ${shipDocId}`;
      break;
    case "Create":
      shipDocInfo = `${operation} New Shipping Document:`;
      break;
    default:
      shipDocInfo = `Order Shipping Document`;
      break;
  }
  return (
    <h2
      className="heading"
      style={{ position: "relative", top: 8 }}
    >
      {shipDocInfo}
    </h2>
  );
}
