import React from "react";
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import SendSingleShipDocERP from "../Business Processes/TerminalOperations/SendERP/SendShipDoc/SendSingleShipDocERP";
import SendAllShipDocsERP from "../Business Processes/TerminalOperations/SendERP/SendAllShipDocs/SendAllShipDocsERP";
import ShipDocOverview from "../Business Processes/ShippingDocument/Index";
import ShipDocMain from "../Business Processes/ShippingDocument/ShippingDcoumentMain/Index";
import PrintShipDoc from "../Business Processes/TerminalOperations/Print/PrintShipDoc";
import ShipDocDash from "../Home/BusinessProcess/ShipDocDash/ShipDocDash";
import ActiveShipDocs from "../DashBoards/ShipDoc/ActiveShipDocs";
import ShipDocWorkbench from "../Business Processes/ShippingDocument/BOLWorkbench/ShipDocWorkbench";
import ShipDocWorkbenchN from "src/Business Processes/ShippingDocument/Workbench/Index";

import ShipDocMetricsV1 from "../DashBoards/Charts/ShipDocMetricsV1";
import BOLProcessTimeChart from "src/DashBoards/Charts/BOLProcessTimeChart/BOLProcessTimeChart";
//import ShipDocMetricsAmcharts from '../Dashboards/Charts/ShipDocMetricsAmcharts';

//Charts
import GlendaleChart from "src/DashBoards/HollyFrontier/Glendale/GlendaleChart";
import GlendaleBOLProcessTimeChart from "src/DashBoards/HollyFrontier/Glendale/BOLProcessTimeChart/GlendaleBOLProcessTimeChart";

import WXChart from "src/DashBoards/HollyFrontier/WX/WXChart/WXChart";
import WXBOLProcessTimeChart from "src/DashBoards/HollyFrontier/WX/BOLProcessTimeChart/WXBOLProcessTimeChart";

function ShipDocRoutes() {
  return (
    <Routes>
      <Route
        exact
        path="/SendSingleShipDocERP"
        element={
          <RequireAuth>
            <SendSingleShipDocERP />
          </RequireAuth>
        }
      />

      <Route
        exact
        path="/SendAllShipDocsERP"
        element={
          <RequireAuth>
            <SendAllShipDocsERP />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ShipDocOverview"
        element={
          <RequireAuth>
            <ShipDocOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ShipDoc/:op"
        element={
          <RequireAuth>
            <ShipDocMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ShipDoc/:id/:op"
        element={
          <RequireAuth>
            <ShipDocMain />
          </RequireAuth>
        }
      />

      <Route
        exact
        path="/PrintBOL"
        element={
          <RequireAuth>
            <PrintShipDoc />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Dashboard/GlendaleChart"
        element={
          <RequireAuth>
            <GlendaleChart />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Dashboard/GlendaleBOLProcessTimeChart"
        element={
          <RequireAuth>
            <GlendaleBOLProcessTimeChart />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Dashboard/WXChart"
        element={
          <RequireAuth>
            <WXChart />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Dashboard/WXBOLProcessTimeChart"
        element={
          <RequireAuth>
            <WXBOLProcessTimeChart />
          </RequireAuth>
        }
      />

      <Route
        exact
        path="/ShipDocDash"
        element={<RequireAuth> {<ShipDocDash />}</RequireAuth>}
      />

      <Route
        exact
        path="/Dashboard/ActiveShipDocs"
        element={<RequireAuth>{<ActiveShipDocs />}</RequireAuth>}
      />
      <Route
        exact
        path="/Dashboard/ShipDocsMetricsV1"
        element={<RequireAuth>{<ShipDocMetricsV1 />}</RequireAuth>}
      />
      <Route
        exact
        path="/Dashboard/BOLProcessTimeChart"
        element={<RequireAuth>{<BOLProcessTimeChart />}</RequireAuth>}
      />

      {/* <Route
        exact
        path="/Dashboard/ShipDocsMetricsV1"
        element={
          <RequireAuth>
            <ShipDocMetricsV1 />
          </RequireAuth>
        }
      /> */}

      {/* <Route
        exact
        path="/Dashboard/ShipDocsMetricsAmcharts"
        element={
          <RequireAuth>
            <ShipDocMetricsAmcharts />
          </RequireAuth>
        }
      /> */}

      <Route
        exact
        path="/ShipDocWorkbench"
        element={
          <RequireAuth>
            <ShipDocWorkbench />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ShipDocWorkbenchN"
        element={
          <RequireAuth>
            <ShipDocWorkbenchN />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default ShipDocRoutes;
