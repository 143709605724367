import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { FinancialDocContext } from "../Contex";
import { MuiButon } from "../../../../CommonComponents/MuiButton";



export default function FinancialDocOperationHeader({
  _loadingSaveFinancialDocData,
}) {
  const {
    operation,
    _setFinancialDocData,
    _financialDocData,
    _saveFinancialDocData,
  } = useContext(FinancialDocContext);

  const handleSubmit = () => {
    _saveFinancialDocData(
      { data: _financialDocData },
      ({ data: newFinancialDocData }) => {
        _setFinancialDocData(newFinancialDocData);
      }
    );
  };

  return (
    <Paper
    className="py-2 px-2 d-flex align-items-center justify-space-between customPaper"

  >
    <TopPanel />
    <MuiButon
      title="Save"
      loading={_loadingSaveFinancialDocData}
      onClick={handleSubmit}
      disabled={operation === "Display"}
    />
  </Paper>
  );
}
