export default async function backendCall(fullURL, operationType, arg) {
  var response;
  try {
    switch (operationType) {
      case "All":
        response = await fetch(fullURL, {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        break;
      case "GET":
        response = await fetch(fullURL, {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({ id: arg }),
        });
        break;

      case "Create":
        {
          response = await fetch(fullURL, {
            method: "Post",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(arg),
          });
        }
        break;
      case "Delete":
        response = await fetch(fullURL, {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({ id: arg }),
        });
        break;
      case "Update":
        {
          response = await fetch(fullURL, {
            method: "Post",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(arg),
          });
        }
        break;
      default:
        break;
    }
    if (response != null) {
      return response;
    }
  } catch (e) {
    return e;
  }
}
