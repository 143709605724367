export const mainDataArray = ({ dropDowns }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Test Method ID",
    key: true,
    length: 50,
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    label: "Description",
  },
  {
    id: "testMethodClassId",
    name: "testMethodClassId",
    type: "text",
    label: "Test Method Class",
    tag: "select",
    options: dropDowns?.testMethodClasses || [],
  },
  {
    id: "testMethodSourceId",
    name: "testMethodSourceId",
    type: "text",
    label: "Test Method Source",
    tag: "select",
    options: dropDowns?.testMethodSources || [],
  },
];
export const enhancedDDInputArray = ({}) => [
  {
    id: "parentTestMethod",
    name: "parentTestMethod",
    type: "text",
    label: "Parent Test Method",
    tag: "enhancedInput",
  },
  {
    id: "refTestMethod",
    name: "refTestMethod",
    type: "text",
    label: "Reference Test Method",
    tag: "enhancedInput",
  },

];


