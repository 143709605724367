export const orderSummaryGridFields = [
  {
    id: "itemNumber",
    label: "Item",
  },
  {
    id: "materialDescriptor",
    label: "Material",
  },
  {
    id: "quantity",
    label: "Quantity",
  },
  {
    id: "unitId",
    label: "Unit",
  },
];
