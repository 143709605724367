import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { issueMessage } from "../utils/issueMessage";
export const useStation = () => {
  const [terminalSetup, setTerminalSetup] = useState({});
  const [stationOperation, setStationOperation] = useState({});
  const [messages, setMessages] = useState([]);
  const stationData = useRef();
  const loginLanguage = useRef();
  const stationInfo = useSelector((state) => state.stationData.Station);
  stationData.current = stationInfo;

  const userInfo = useSelector((state) => state.userData.User.user);

  loginLanguage.current = userInfo?.preferredLanguage;

  const getTerminalSetup = (fieldProcessId) => {
    let TerminalStationSetups = stationData.current?.stationTrmSetupsComm ?? [];
    let terminalSetupsData = stationData.current?.stationSetups ?? [];
    let fieldProcesses = stationData.current.fieldProcesses ?? [];
    //Find the correct terminal setup id based on fieldprocessid
    let terminalStationSetup = TerminalStationSetups.find(
      (x) => x.fieldProcessId === fieldProcessId
    );

    //Find the correct field process Id config fieldprocessid
    let fieldProcess = fieldProcesses.find((x) => x.id === fieldProcessId);

    if (fieldProcess.terminalSetupRequired && terminalStationSetup) {
      let { messageText } = issueMessage(loginLanguage.current, "18");
      setMessages((current) => [...current, messageText]);
    }

    if (!terminalStationSetup) {
      return;
    }

    //the == is used because 5 == '5' needs to be true
    let currentTerminalSetup = terminalSetupsData.find(
      (x) => x.id == terminalStationSetup.orderId
    );
    setTerminalSetup(currentTerminalSetup);
  };

  const getStationOperation = (fieldOperationId, fieldProcessId) => {
    if (!fieldOperationId) {
      return;
    }
    if (!fieldProcessId) {
      return;
    }
    let stationOperations = stationData.current.stationOperationComm ?? [];

    let stationOperationDetails = stationOperations.find(
      (x) =>
        x.fieldOperationId === fieldOperationId &&
        x.fieldProcessId === fieldProcessId
    );

    if (typeof stationOperationDetails === "undefined") {
      let { messageText } = issueMessage(
        loginLanguage.current,
        "76",
        fieldOperationId,
        fieldProcessId
      );
      setMessages((current) => [...current, messageText]);
      return;
    }

    if (Object.keys(stationOperationDetails).length === 0) {
      let { messageText } = issueMessage(loginLanguage.current, "76");
      setMessages((current) => [...current, messageText]);
      return;
    }

    setStationOperation(stationOperationDetails);
  };

  return [
    stationInfo,
    getTerminalSetup,
    terminalSetup,
    getStationOperation,
    stationOperation,
    messages,
    setMessages,
  ];
};
