import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { useFetch } from "../../../../../hooks/useFetch";
import { useStation } from "../../../../../hooks/UseStation";
import { useMessage } from "../../../../../hooks/UseMessage";
import { useDD } from "../../../../../hooks/UseDD";
import { useUser } from "../../../../../hooks/useUser";
import {
  driverPinArray,
  carrierArray,
  vehicleArray,
  trailerArray,
  leftPanelArray,
} from "./Fields";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../../../CommonComponents/GlobalConfFiles/URLs";
import { useParams } from "react-router-dom";
import { ValidateConfirmChecking, ValidateDriverPIN } from "./ValidateShipDoc";
import ListModal from "../../../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import Card from "src/CommonComponents/Card";
import Modal from "react-bootstrap/Modal";
function CheckinV5(props) {
  //This is a simple checkin, using a guided procedure, to take the PIN, then confirm carrier, then vehicle then trailer and the end shows a summary. Used by
  //Glendale and WX
  const params = useParams();
  const navigate = useNavigate();
  const shipDocid = params.shipDoc;
  const fieldOperationId = "CHECKIN";
  const [showPartnerList, setShowPartnerList] = useState(false);
  const [filteredPartnerList, setfilteredPartnerList] = useState([]);
  const [keyboardFocusField, setKeyboardFocusField] = useState(null);
  const [checkinFinished, setCheckinFinished] = useState(false);
  const [terminalSetupCarrier, setTerminalSetupCarrier] = useState([]);
  const [terminalSetupShiptos, setTerminalSetupShiptos] = useState([]);
  const shipDocPrintOptions = useRef("");
  const [stage, setStage] = useState("");
  const [show, setShow] = useState(false);

  const [
    _getPartnerList,
    _partnerList,
    _setParnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    ,
    ,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("GetPartnerPerPINandType"), { method: "POST" });

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" }, true);

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    ,
    ,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" }, true);

  const [
    _deterSetup,
    _deterSetupData,
    ,
    _errorDeterSetup,
    _setErrorDeterSetup,
    ,
    ,
    _loadingSetupDeter,
  ] = useFetch(GetFullURL("DetermineSetup"), { method: "POST" }, true);

  const [
    _searchWIPShipDocs,
    _serachWIPShipDocData,
    ,
    ,
    ,
    ,
    ,
    _loadingSearchWIPShipDoc,
  ] = useFetch(GetFullURL("AdvancedShipDocSearch"), { method: "POST" });
  const [
    _stationInfo,
    _getTerminalSetup,
    _terminalSetup,
    _getStationOperation,
    _stationOperation,
    _stationMessages,
    _setStationMessages,
  ] = useStation();

  const [_dd] = useDD();
  const [_user] = useUser();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    ,
    ,
    ,
    _createSuccessMessage,
    _successMessages,
    _setSuccessMessages,
  ] = useMessage();

  //  useEffect(() => {}, [_shipDocData.fieldProcessId]);

  useEffect(() => {
    //This is done to set the shipDoc print options
    //this setting can come from location or terminal setup determination
    //preference is given to terminal setup, there are 4 options
    // NoTLOADPriting = 1,
    // AlwaysFromTload = 2,
    // FromTloadWhenERPDown = 3,
    if (typeof _shipDocData === "undefined") {
      return;
    }

    if (typeof _deterSetupData === "undefined") {
      return;
    }

    let locations = _shipDocData?.locationsInfoComm ?? [];

    let location = locations.find((x) => x.id == _shipDocData.locationId);

    let locationPrintOption = location?.shipDocPrintOptions;

    let termSetupPrintOption = _deterSetupData?.shipDocPrintOptions;

    if (termSetupPrintOption) {
      shipDocPrintOptions.current = termSetupPrintOption;
    } else {
      shipDocPrintOptions.current = locationPrintOption;
    }
  }, [_deterSetupData, _shipDocData]);

  useEffect(() => {
    _getShipDoc({ data: { Id: shipDocid } }, ({ data: shipDocData }) => {
      //Set the stage

      if (
        shipDocData.pin &&
        shipDocData.carrierId &&
        shipDocData.trailerId &&
        shipDocData.vehicleId
      ) {
        setStage(5);
      } else {
        setStage(1);
      }
      //If any of checking data has populated then show them
      _getStationOperation(fieldOperationId, shipDocData.fieldProcessId);
      _deterSetup({
        data: {
          LocationId: shipDocData.locationId,
          FieldProcessId: shipDocData.fieldProcessId,
          TransportationModeId: shipDocData.transportationModeId,
          MaterialId: shipDocData.shippingDocItemsComm[0].materialId,
        },
      });
    });
  }, []);

  useEffect(() => {
    //This use effect is filter the list based on locationId,
    //if the result of filter is empty arrau issue and error message and close partner list modal
    let newPartnerList = [];
    if (!Array.isArray(_partnerList)) {
      return;
    }

    if (_partnerList.length === 0) {
      return;
    }
    // filter the list based on location id and update the state
    newPartnerList = _partnerList.filter(
      (x) => x.locationId === _stationInfo.locationId
    );

    if (newPartnerList.length === 0) {
      _createErrorMessage("18");
    }

    if (newPartnerList.length === 1) {
      _setShipDocData((current) => {
        return {
          ...current,
          driverDescriptor: newPartnerList[0].partnerDescriptor,
          driverId: newPartnerList[0].id,
        };
      });

      setStage(2);
      return;
    }

    if (newPartnerList.length > 1) {
      setfilteredPartnerList(newPartnerList);
      setShowPartnerList(true);
      return;
    }
  }, [_partnerList]);

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }

    const newValueObject = { [name]: newValue };
    _setShipDocData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  useEffect(() => {
    if (!checkinFinished) {
      return;
    }

    let nextUrlattribute1 = "";
    let nextUrlattribute2 = "";
    let nextUrlattribute3 = "";

    if (_stationOperation.nextUrlAttribute1) {
      try {
        nextUrlattribute1 = eval(_stationOperation.nextUrlAttribute1);
      } catch (error) {
        nextUrlattribute1 = _stationOperation.nextUrlAttribute1;
      }
    }

    if (_stationOperation.nextUrlAttribute2) {
      try {
        nextUrlattribute2 = eval(_stationOperation.nextUrlAttribute2);
      } catch (error) {
        nextUrlattribute2 = _stationOperation.nextUrlAttribute2;
      }
    }

    if (_stationOperation.nextUrlAttribute3) {
      try {
        nextUrlattribute3 = eval(_stationOperation.nextUrlAttribute3);
      } catch (error) {
        nextUrlattribute3 = _stationOperation.nextUrlAttribute3;
      }
    }
    //TODO, we must do the west laod rack at some-point
    if (
      _stationOperation.stationId === "GLND_EAST_RACK" ||
      _stationOperation.stationId === "GLND_WEST_RACK"
    ) {
      setShow(true);
      return;
    }

    navigate(
      `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
    );
    // _createSuccessMessage("94");
    // var leaveTimer = setTimeout(() => {
    //   navigate(
    //     `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
    //   );
    // }, 3000);
    // return () => {
    //   clearTimeout(leaveTimer);
    // };
  }, [checkinFinished]);

  useEffect(() => {
    //Get shipto and carrier dropdown from terminal setup
    if (!_deterSetupData.setupData?.orderPartnersComm) {
      return;
    }

    _deterSetupData.setupData.partnersInfoComm.forEach((element) => {
      switch (element.partnerTypeId) {
        case "SHIPTO":
          let shiptoDD = {
            value: element.id,
            label: element.partnerDescriptor,
          };
          setTerminalSetupShiptos((current) => {
            return [...current, shiptoDD];
          });
          break;
        case "CARRIER":
          let carrierDD = {
            value: element.id,
            label: element.partnerDescriptor,
          };

          setTerminalSetupCarrier((current) => {
            return [...current, carrierDD];
          });

          break;
        default:
        // code block
      }
    });
  }, [_deterSetupData]);

  const fieldsState = {
    dropDowns: _dd.shipDoc,
    trmCarriers: terminalSetupCarrier,
  };
  const handleOnKeyboardFocus = (fieldId, event) => {
    //when a keyboard field is clicked, set it in the state becuase we need
    //to only render this field
    if (event === "onFocus") {
      setKeyboardFocusField(fieldId);
    } else {
      //The blur event is triggered not only when a field is unfocused but also when a
      //field is focused it tiggers blur for all other fields, we only need to blur only
      //the relvant field in being unfocused
      if (fieldId === keyboardFocusField) {
        setKeyboardFocusField("");
      }
    }
  };

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: _shipDocData,
      onChange,
      handleOnKeyboardFocus,
      hideKeyboard: _user.disableKeyboard,
    };

    return newFieldObject;
  };
  const leftPanelFields = leftPanelArray(fieldsState);
  const driverFields = driverPinArray(fieldsState);
  const carrierFields = carrierArray(fieldsState);
  const vehicleFields = vehicleArray(fieldsState);
  const trailerFields = trailerArray(fieldsState);

  const operationLoading =
    _loadingSaveShipDoc ||
    _loadingPartnerList ||
    _loadingShipDoc ||
    _loadingSetupDeter;

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveShipDoc([]);
        _setSuccessMessages([]);

        break;
      case "error":
        _setErrorSaveShipDoc([]);
        _setStationMessages([]);
        _setErrorShipDoc([]);
        _setErrorPartnerList([]);
        _setErorMessages([]);

        break;
      case "warning":
        break;
      default:
        break;
    }
  }
  function confirmCheckin() {
    let validationConfirmResult = ValidateConfirmChecking(
      _shipDocData,
      _createErrorMessage
    );

    if (!validationConfirmResult) {
      return;
    }

    if (!_stationOperation.fieldOperationId) {
      _createErrorMessage(
        "76",
        _shipDocData.fieldProcessId,
        fieldOperationId.current
      );
      return;
    }

    if (!_stationOperation.shipDocStatusId) {
      _createErrorMessage("95", fieldOperationId.current);
      return;
    }

    //load start date/end (loadEnDT, loadStDT) is set initially at check-in and finally at signature
    let shipDataforSave = {
      ..._shipDocData,
      checkedStatus: "X",
      checkedStDT: moment().format(),
      checkedEnDT: moment().format(),
      statusId: _stationOperation.shipDocStatusId,
      shipDocDate: moment().format(),
      startDT: moment().format(),
      weightUnitId: "LB",
      terminalSetupId: _deterSetupData.trmSetupId,
      terminalActivityinProcess: _stationOperation.terminalActivityinProcess,
      shipDocPrintOptions: shipDocPrintOptions.current,
      currentStation: _stationInfo.id,
    };
    _saveShipDoc({ data: shipDataforSave }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
      setCheckinFinished(true);
    });
  }

  async function validatePIN() {
    _setErorMessages([]);
    let pinValidationResult = ValidateDriverPIN(
      _shipDocData,
      _createErrorMessage
    );
    if (!pinValidationResult) {
      return;
    }
    //When pressing the validate pin, clear the driver info
    _setShipDocData((current) => {
      return {
        ...current,
        driverDescriptor: "",
        driverId: "",
      };
    });

    await _getPartnerList(
      {
        data: {
          partnerTypeId: "DRIVER",
          Pin: _shipDocData.pin,
        },
      },
      ({ data: driverList }) => {
        if (driverList.length === 0) {
          _createErrorMessage("18");
          return;
        } else {
          setKeyboardFocusField("");
        }
      }
    );
  }
  const handelListSelect = (id) => {
    let partner = _partnerList.find((x) => x.id === id);

    if (partner) {
      _setShipDocData((current) => {
        return {
          ...current,
          driverDescriptor: partner.partnerDescriptor,
          driverId: partner.id,
        };
      });
      setStage(2);
    }
  };
  async function nextButton() {
    _setErorMessages([]);
    switch (stage) {
      case 1:
        validatePIN();
        break;
      case 2:
        if (
          _shipDocData.carrierId == "" ||
          _shipDocData.carrierId === undefined
        ) {
          _createErrorMessage("5");
        } else {
          setStage((current) => current + 1);
        }

        break;
      case 3:
        if (
          _shipDocData.vehicleId == "" ||
          _shipDocData.vehicleId === undefined
        ) {
          _createErrorMessage("63");
        } else {
          setStage((current) => current + 1);
        }

        break;
      case 4:
        if (
          _shipDocData.trailerId == "" ||
          _shipDocData.trailerId === undefined
        ) {
          _createErrorMessage("6");
        } else {
          setStage((current) => current + 1);
        }

        break;
      case 5:
        if (
          _shipDocData.trailerId == "" ||
          _shipDocData.trailerId === undefined
        ) {
          _createErrorMessage("6");
        }
        if (
          _shipDocData.vehicleId == "" ||
          _shipDocData.vehicleId === undefined
        ) {
          _createErrorMessage("63");
        }
        confirmCheckin();

        break;
      default:
      // code block
    }
  }

  const backButton = () => {
    if (stage > 1) {
      setStage((current) => current - 1);
    }
  };

  const handlePickScale3 = () => {
    setShow(false);
    if (_stationOperation.stationId === "GLND_EAST_RACK") {
      navigate(`${_stationOperation.nextUrl}/${shipDocid}/Scale_3`);
    } else {
      navigate(`${_stationOperation.nextUrl}/${shipDocid}/Scale_1`);
    }
  };
  const handlePickScale4 = () => {
    setShow(false);
    if (_stationOperation.stationId === "GLND_EAST_RACK") {
      navigate(`${_stationOperation.nextUrl}/${shipDocid}/Scale_4`);
    } else {
      navigate(`${_stationOperation.nextUrl}/${shipDocid}/Scale_2`);
    }
  };
  const handleClose = () => {
    setShow(false);
    setCheckinFinished(false);
  };
  return (
    <div className="container-fluid">
      {operationLoading && <LoadingScreen />}
      <div className="row">
        <div className="col-lg-3 col-sm-12 col-xs-12" id="leftPanel">
          <Card
            cardHeaderProps={{
              headerLabel: "Summary Info",
            }}
            cardBody={leftPanelFields.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          />
        </div>
        {stage === 5 && (
          <div className="col-lg-6 col-sm-12 col-xs-12" id="rightPanel">
            <Card
              cardHeaderProps={{}}
              cardBody={driverFields.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
              style={{ width: "100%" }}
            />
            <Card
              cardHeaderProps={{}}
              cardBody={carrierFields.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
              style={{ width: "100%" }}
            />
            <Card
              cardHeaderProps={{}}
              cardBody={vehicleFields.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
              style={{ width: "100%" }}
            />
            <Card
              cardHeaderProps={{}}
              cardBody={trailerFields.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
              style={{ width: "100%" }}
            />
          </div>
        )}
        {stage === 1 && (
          <div className="col-lg-6 col-sm-12 col-xs-12" id="rightPanel">
            <Card
              cardHeaderProps={{}}
              cardBody={driverFields.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
              style={{ width: "100%" }}
            />
          </div>
        )}
        {stage === 2 && (
          <div className="col-lg-6 col-sm-12 col-xs-12" id="rightPanel">
            <Card
              cardHeaderProps={{}}
              cardBody={carrierFields.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
              style={{ width: "100%" }}
            />
          </div>
        )}
        {stage === 3 && (
          <div className="col-lg-6 col-sm-12 col-xs-12" id="rightPanel">
            <Card
              cardHeaderProps={{}}
              cardBody={vehicleFields.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
              style={{ width: "100%" }}
            />
          </div>
        )}
        {stage === 4 && (
          <div className="col-lg-6 col-sm-12 col-xs-12" id="rightPanel">
            <Card
              cardHeaderProps={{}}
              cardBody={trailerFields.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
              style={{ width: "100%" }}
            />
          </div>
        )}
        <div className="col-lg-3 col-sm-12 col-xs-12" id="buttons">
          <div className="row px-2">
            <Button
              className="btn btn-primary mt-1"
              style={{
                hight: "350ev",
                background: "#08bd55",
                borderColor: "#08bd55",
                fontSize: "45px",
              }}
              onClick={nextButton}
            >
              NEXT
            </Button>
          </div>
          <div className="row px-2 mt-5">
            <Button
              className="btn btn-primary mt-1"
              style={{
                hight: "350ev",
                background: "#DA1212",
                borderColor: "#DA1212",
                fontSize: "35px",
              }}
              onClick={backButton}
            >
              Back
            </Button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mt-3">
          <MessageLog
            errorMessages={[
              ..._errorPartnerList,
              ..._errorSaveShipDoc,
              ..._errorShipDoc,
              ..._errorMessages,
              ..._stationMessages,
            ]}
            successMessages={[..._successMessages, ..._successSaveShipDoc]}
            warningMessages={[]}
            clearMessages={clearMessages}
          ></MessageLog>
        </div>
      </div>
      <ListModal
        setId={handelListSelect}
        dataList={filteredPartnerList}
        showListModal={showPartnerList}
        setShowModal={setShowPartnerList}
        title="List of Drivers"
        columns={[
          { field: "id", headerName: "Driver ID", width: 150 },
          {
            field: "name",
            headerName: "Driver Name",
            width: 250,
          },
        ]}
      />
      <Modal show={show} onHide={handleClose} size="xl" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Pick LoadRack/Scale</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button
            onClick={handlePickScale3}
            style={{
              height: "5rem",
              marginBottom: "4rem",
              marginTop: "2rem",
              width: "49%",
              fontSize: 30,
              color: "white",
              padding: 5,
              fontWeight: "bold",
              background: "#29ABE2",
            }}
          >
            {_stationOperation.stationId === "GLND_EAST_RACK"
              ? "Scale 3"
              : "Scale 1"}
          </button>
          <button
            onClick={handlePickScale4}
            style={{
              height: "5rem",
              marginBottom: "4rem",
              marginTop: "2rem",
              width: "49%",
              fontSize: 30,
              color: "white",
              padding: 5,
              fontWeight: "bold",
              background: "#F7931E",
            }}
          >
            {_stationOperation.stationId === "GLND_EAST_RACK"
              ? "Scale 4"
              : "Scale 2"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CheckinV5;
