import React, { useContext } from "react";
import DeleteBtnAction from "./DeleteBtnAction";

import { PartnerContext } from "../../context";
export default function PartnerRelationsGridBody({
  fields,
  updatePartnerRelations,
}) {
  const { operation, _partnerData } = useContext(PartnerContext);

  function handleButtonClickGrid(e) {

    let id = e.target.dataset.id;
    updatePartnerRelations("Delete", id);
  }

  let data = _partnerData?.partnerRelationsComm ?? [];
  return (
    <tbody>
      {data.map((dataRecord, i) => {
        return (
          <tr key={i}>
            {fields.map((field, i1) => {
              return <td key={i1}>{dataRecord[field.id]}</td>;
            })}
            <td>
              <DeleteBtnAction
                operation={operation}
                handleButtonClickGrid={handleButtonClickGrid}
                dataRecord={dataRecord}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}
