exports.UnitConversion = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    key: "true",
    length: 12,
  },
  {
    id: "fromUnit",
    name: "fromUnit",
    type: "text",
    value: "",
    ddid: "UnitId",
    label: "From Unit",
    length: 3,
  },
  {
    id: "toUnit",
    name: "toUnit",
    type: "text",
    value: "",
    ddid: "UnitId",
    label: "To Unit",
    length: 3,
  },
  {
    id: "factor",
    name: "factor",
    type: "number",
    value: "",
    label: "Factor",
  },
  {
    id: "nameLocalizedText",
    name: "nameLocalizedText",
    type: "text",
    value: "",
    label: "nameLocalizedText",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
  {
    id: "nameResourceKey",
    name: "nameResourceKey",
    type: "text",
    value: "",
    label: "nameResourceKey",
    hide: "true",
    displayonly: "true",
  },
];
