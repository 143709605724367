export const mainDataArray = ({ dropDowns }) => [
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    disabled: true,
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    disabled: true,
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    disabled: true,
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "modifiedOn On",
    disabled: true,
  },
];

export const statusArray = ({ dropDowns }) => [
  {
    id: "statusId",
    name: "statusId",
    tag: "select",
    label: "Status",
    options: dropDowns?.invoiceStatuses || [],
  },
  {
    id: "voidStatusId",
    name: "voidStatusId",
    label: "Void Status",
    tag: "select",
    options: dropDowns?.invoiceVoidStatuses || [],
  },
];
