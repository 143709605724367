exports.TASGlobal = [
  {
    id: "id",
    name: "id",
    type: "number",
    value: "",
    label: "Id",
    key: "true",
  },
  {
    id: "enviroment",
    name: "enviroment",
    type: "number",
    value: "",
    label: "Enviroment",
  },
  {
    id: "erpReadServiceProductionURL",
    name: "erpReadServiceProductionURL",
    type: "text",
    value: "",
    label: "ERP Read Service Production URL",
    length: 300,
  },
  {
    id: "erpSaveServiceProductionURL",
    name: "erpSaveServiceProductionURL",
    type: "text",
    value: "",
    label: "ERP Save Service Production URL",
    length: 300,
  },
  {
    id: "erpSyncServiceProductionURL",
    name: "erpSyncServiceProductionURL",
    type: "text",
    value: "",
    label: "ERP Sync Service Production URL",
    length: 300,
  },
  {
    id: "erpServiceProductionUser",
    name: "erpServiceProductionUser",
    type: "text",
    value: "",
    label: "ERP Service Production User",
    length: 30,
  },
  {
    id: "erpServiceProductionPassword",
    name: "erpServiceProductionPassword",
    type: "text",
    value: "",
    label: "ERP Service Production Password",
    length: 30,
  },
  {
    id: "erpReadServiceTestURL",
    name: "erpReadServiceTestURL",
    type: "text",
    value: "",
    label: "ERP Read Service Test URL",
    length: 300,
  },
  {
    id: "erpSaveServiceTestURL",
    name: "erpSaveServiceTestURL",
    type: "text",
    value: "",
    label: "ERP Save Service Test URL",
    length: 300,
  },
  {
    id: "erpSyncServiceTestURL",
    name: "erpSyncServiceTestURL",
    type: "text",
    value: "",
    label: "ERP Sync Service Test URL",
    length: 300,
  },
  {
    id: "erpServiceTestUser",
    name: "erpServiceTestUser",
    type: "text",
    value: "",
    label: "ERP Service Test User",
    length: 30,
  },
  {
    id: "erpServiceTestPassword",
    name: "erpServiceTestPassword",
    type: "text",
    value: "",
    label: "ERP Service Test Password",
    length: 30,
  },
  {
    id: "erpServiceDemoURL",
    name: "erpServiceDemoURL",
    type: "text",
    value: "",
    label: "ERP Service Demo URL",
    length: 300,
  },

  {
    id: "erpServiceDemoUser",
    name: "erpServiceDemoUser",
    type: "text",
    value: "",
    label: "ERP Service Demo User",
    length: 30,
  },

  {
    id: "erpServiceDemoPassword",
    name: "erpServiceDemoPassword",
    type: "text",
    value: "",
    label: "erp Service Demo Password",
    length: 30,
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
