exports.View = [
  {
    id: "id",
    name: "id",
    type: "number",
    value: "",
    label: "Id",
    key: "true",
  },
  {
    id: "key",
    name: "key",
    type: "string",
    value: "",
    label: "Key",
    key: "true",
  },
  {
    id: "nameLocalizedText",
    name: "nameLocalizedText",
    type: "text",
    value: "",
    label: "nameLocalizedText",
  },
  {
    id: "isEnabled",
    name: "isEnabled",
    type: "boolean",
    value: "",
    label: "Enabled",
  },
];
