import React, { useState, useEffect } from "react";
import { ReactComponent as ClearIcon } from "../../Assets/NewIconsForCards/ClearIcon.svg";
import { ReactComponent as GlobalAppDataIcon } from "../../Assets/NewIconsForCards/GlobalAppDataIcon.svg";
import Card from "../Components/Card";
import { useUser } from "../../hooks/useUser";

const CardsArray = [
  {
    title: "Clear Cache",
    text: "",
    Icon: ClearIcon,
    link: "/ClearCache",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Global App Data",
    text: "",
    Icon: GlobalAppDataIcon,
    link: "/GlobalAppData",
    roles: ["Admin", "SuperAdmin"],
  },

];

export default function BasicSystem(props) {
  const [_user, ,] = useUser();
  const [newCards, setNewCards] = useState([]);

  useEffect(() => {
    if (!_user.roles) {
      return CardsArray;
    }
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card?.roles?.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);

  return (
    <div className="overviewCardContainer">
      {newCards.map((record, i) => (
        <div key={i}>
          <Card {...record} />
        </div>
      ))}
    </div>
  );
}
