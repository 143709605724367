export const mainArray = ({}) => [
  {
    id: "date",
    name: "date",
    type: "datetime",
    label: "Reading Date",
  },
];
export const tankArray = ({}) => [
  {
    id: "tankId",
    name: "tankId",
    label: "Tank",
  },
];
