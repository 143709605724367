import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { DataGrid } from '@mui/x-data-grid';

export default function PartnerList({
  _partnerList,
  showPartnerList,
  setShowPartnerList,
  partnerFieldName,
  onChange,
}) {
 
 //Passing the so-called partner in order to identify the field that must be updated in the main state
 //the main state in main page 
  function handleOnSelect(selectedId) {
    debugger;
     onChange({
       target: { value: selectedId[0], name: partnerFieldName, type: "text" },
     });
    setShowPartnerList(false);
  }

  return (
    <Modal
      show={showPartnerList}
      size="xl"
      onHide={() => setShowPartnerList(false)}
      dialogClassName="modal-90w modal-dialog-scrollable"
      aria-labelledby="example-custom-modal-styling-title"
      className="partner-list-modal"
    >
      <Modal.Header
        style={{
          background: "#d4e1f5",
        }}
      >
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{
            fontWeight: 700,
          }}
        >
          List of Partners
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {_partnerList?.length && (
        <div style={{ height: '50vh', width: '100%' }}>
          <DataGrid
          rows={_partnerList || []}
          columns={[
            { field: 'id', headerName: 'Partner ID', width: 250 },
            { field: 'partnerTypeId', headerName: 'Partner Type', width: 250 },
            { field: 'name', headerName: 'Name', width: 350 },
            { field: 'city', headerName: 'City', width: 200 },
          ]}
          pageSize={99}
          rowsPerPageOptions={[99]}
          onSelectionModelChange={selectedId => handleOnSelect(selectedId)}
        />
        </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="offset-11 col-1">
          <Button
            variant="danger"
            onClick={() => {
              setShowPartnerList(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
