import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { AccountContext } from "../context";
import { MuiButon } from "../../../../../CommonComponents/MuiButton";

export default function AccountOperationHeader({
  _loadingSaveAccount,
  _saveAccount,
}) {
  const { operation, _setAccountData, _accountData, setOperation } =
    useContext(AccountContext);

  const handleSave = () => {
    _saveAccount(
      { data: _accountData },
      ({ data: NewAccountData, success: callStatus }) => {
        if (callStatus) {
          if (operation === "Create") {
          }
          setOperation("Change");
        }
        _setAccountData(NewAccountData);
      }
    );
  };

  return (
    <Paper className="py-2 px-2 d-flex align-items-center justify-space-between customPaper">
      <TopPanel />
      <MuiButon
        title="Save"
        loading={_loadingSaveAccount}
        onClick={handleSave}
        disabled={operation === "Display"}
      />
    </Paper>
  );
}
