import React from "react";
import Button from '@mui/material/Button';
import { ValidatePartnerInput } from "./../ValidatePartnerInput";

const btnsArray = [
  { buttonOperation: "Display" },
  { buttonOperation: "Change" },
  { buttonOperation: "Create" },
];

export default function OverviewButtons({
  partner,
  partnerType,
  navigate,
  _getPartnerExists,
  _createErrorMessage,
}) {
  const handleClick = (buttonOperation, url) => {
    //If create button is pressed then just proceed to route
    let navigationUrl;
    if (buttonOperation === "Create") {
      if (partnerType) {
        navigationUrl = `/SpecificPartner/${buttonOperation}/${partnerType}`;
      } else {
        navigationUrl = `/Partner/${buttonOperation}`;
      }
      navigate(navigationUrl);
      return;
    }

    let validationPartnerResult = ValidatePartnerInput(
      partner,
      _createErrorMessage
    );

    if (!validationPartnerResult) {
      return;
    }

    _getPartnerExists(
      { data: { partnerId: partner, PartnerTypeId: partnerType } },
      ({ data: isExist }) => {
        if (!isExist) {
          _createErrorMessage("34");
          return;
        }
        if (partnerType) {
          navigationUrl = `/SpecificPartner/${partner}/${buttonOperation}/${partnerType}`;
        } else {
          navigationUrl = `/Partner/${partner}/${buttonOperation}`;
        }

        navigate(navigationUrl);
      }
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ buttonOperation }, i) => (
        <div style={{ width: "48%", marginBottom: 15 }} key={i}>
          <Button
            className="btn btn-primary w-100"
            onClick={() => handleClick(buttonOperation)}
          >
            {buttonOperation}
          </Button>
        </div>
      ))}
    </div>
  );
}
