import { integerPropType } from "@mui/utils";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

export default class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: props.name,
          data: props.chartValue,
        },
      ],
      options: {
        grid: {
          row: {
            colors: ["#D6EAF8 ", "#D6EAF8"],
          },
        },
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: Number.MAX_VALUE,
                  color: "#29ABE2",
                },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + ` min`;
          },
          offsetX: 15,
          style: {
            fontSize: "12px",
            colors: ["#FFFFFF"],
          },
        },
        xaxis: {
          categories: props.ids,
          labels: {
            style: {
              colors: [],
              fontSize: "15px",
              fontWeight: 500,
            },
          },
        },
        yaxis: {
          categories: props.differenceInTime,
          labels: {
            style: {
              colors: [],
              fontSize: "15px",
              fontWeight: 500,
            },
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            width: 10,
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
        />
      </div>
    );
  }
}
