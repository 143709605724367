import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { InvoiceContext } from "../Contex";
import { MuiButon } from "../../../../CommonComponents/MuiButton";



export default function InvoiceOperationHeader({
  _loadingSaveInvoiceData,
}) {
  const {
    operation,
    _setInvoiceData,
    _invoiceData,
    _saveInvoiceData,
  } = useContext(InvoiceContext);

  const handleSubmit = () => {
    _saveInvoiceData(
      { data: _invoiceData },
      ({ data: newInvoiceData }) => {
        _setInvoiceData(newInvoiceData);
      }
    );
  };

  return (
    <Paper
    className="py-2 px-2 d-flex align-items-center justify-space-between customPaper"

  >
    <TopPanel />
    <MuiButon
      title="Save"
      loading={_loadingSaveInvoiceData}
      onClick={handleSubmit}
      disabled={operation === "Display"}
    />
  </Paper>
  );
}
