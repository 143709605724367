export const mainArray = ({ dropDowns }) => [
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    label: "Location Id",
    options: dropDowns?.locations || [],
    tag: "select",
  },
];
