export const reversalDataArray = ({ dropDowns }) => [
  {
    id: "reversed",
    name: "reversed",
    type: "text",
    label: "Reversed",
    tag: "select",
    options: [
      { label: "true", value: "true" },
      { label: "false", value: "false" },
    ],
  },
  {
    id: "reversalDocumentId",
    name: "reversalDocumentId",
    type: "text",
    label: "Reversal Document Id",
  },
  {
    id: "Reversal",
    name: "Reversal",
    type: "text",
    label: "Reversal",
    tag: "select",
    options: [
      { label: "true", value: "true" },
      { label: "false", value: "false" },
    ],
  },
  {
    id: "reasonId",
    name: "reasonId",
    type: "text",
    label: "ReasonId",
    tag: "select",
    options: dropDowns?.reasons || [],
  },
];
export const refDataArray = ({ dropDowns }) => [

  {
    id: "referenceDoc",
    name: "referenceDoc",
    type: "text",
    label: "ReferenceDoc",
    disabled: true,
  },
  {
    id: "referenceDocTypeId",
    name: "referenceDocTypeId",
    type: "text",
    label: "ReferenceDocTypeId",
    disabled: true,

  },
  {
    id: "referenceDocCategoryId",
    name: "referenceDocCategoryId",
    type: "text",
    label: "ReferenceDocCategoryId",
    disabled: true,

  },
 
];

export const adminDataArray = ({ dropDowns }) => [
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    disabled: true,
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    disabled: true,
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    disabled: true,
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "Modified On",
    disabled: true,
  },
];


