import EnglishErrorMsg from "../Error/En.json";
import ArabicErrorMsg from "../Error/Ar.json";

export const issueMessage = (
  language,
  Id,
  var1 = "",
  var2 = "",
  var3 = "",
  var4 = ""
) => {
  //If langauge is blank then set it to English
  if (!language) {
    language = "EN";
  }
  switch (language) {
    case "EN":
      const result = EnglishErrorMsg.find(({ id }) => id == Id);
      if (result) {
        let Messagefile = result.message;
        let newMessage = Messagefile.replace("#1", var1)
          .replace("#2", var2)
          .replace("#3", var3)
          .replace("#4", var4);

        let messageInfo = `FR-${result.id}`;
        let totalMessage = `${messageInfo}: ${newMessage}`;

        return { messageText: totalMessage };
      } else {
        return "Message Not Found";
      }
    case "AR":
      // code block
      break;
    default:
    // code block
  }
};
