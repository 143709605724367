import React from "react";
import "./index.scss";

export const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      {/* <CircularProgress size={90} /> */}
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div>
  );
};
