import React, { useState, useEffect } from "react";
import { InvoiceContext } from "./Contex";
import MessageLog from "../Components/MessageLog";
import HomeTab from "./Main/index";
import Items from "./../InvoiceMain/Items/Items";
import InvoiceOperationHeader from "./Header/Index";
import AdminTab from "./Admin/Index";
import PartnerTab from "./PartnerTab/Index";
import { useFetch } from "./../../../hooks/useFetch";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Tabpannel from "../../../Business Processes/Components/TabPannel";
import { useParams } from "react-router-dom";

const UpdatedHomeComponent = () => (
  <React.Fragment>
    <HomeTab />
    <Items />
  </React.Fragment>
);

export default function Index(props) {
  //read URL parameters
  const params = useParams();
  //Partner ID
  let id = params.id;

  //Operation
  let operation = params.op;

  //Make sure that ID is numeric
  const invoiceId = id;
  //Do the following when page loads

  const [value, setValue] = useState(0);

  const [frondendError, setFrontendError] = useState([]);
  const [frontendWarning, setFrontendWarning] = useState([]);

  const [
    _getInvoiceData,
    _invoiceData,
    _setInvoiceData,
    _errorInvoiceData,
    _setErrorInvoiceData,
    _successInvoiceData,
    _setSuccessInvoiceData,
    _loadingInvoiceData,
  ] = useFetch(GetFullURL("GetInvoice"), { method: "POST" });

  const [
    _getPartnerList,
    _partnerList,
    _setPartnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    _successPartnetList,
    _setSuccessPartnerList,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("PartnerList"), { method: "POST" });

  const [
    _getInvoiceDD,
    _invoiceDD,
    ,
    _errorInvoiceDD,
    _setErrorInvoiceDD,
    _successInvoiceDD,
    _setSuccessInvoiceDD,
    _loadingtInvoiceDD,
  ] = useFetch(GetFullURL("InvoiceDropDowns"), { method: "POST" });

  const [
    _getMaterialList,
    _materialList,
    _setMaterialList,
    _errorMaterialList,
    _SetErrorMaterialList,
    _successMaterialList,
    _setSuccessMaterialList,
    _loadingMaterialList,
  ] = useFetch(GetFullURL("MaterialList"), { method: "POST" });

  const [
    _saveInvoiceData,
    _invoiceSaveData,
    _setInvoiceSaveData,
    _errorSaveInvoiceData,
    _setErrorSaveInvoiceData,
    _successSaveInvoiceData,
    _setSuccessSaveInvoiceData,
    _loadingSaveInvoiceData,
  ] = useFetch(GetFullURL("SaveInvoice"), { method: "POST" });

  const [
    _getTankList,
    _tankList,
    ,
    _errorTankList,
    _setErrorTankList,
    _successTankList,
    _setSuccessTankList,
    _loadingTankList,
  ] = useFetch(GetFullURL("TankList"), { method: "POST" });

  useEffect(() => {
    _getInvoiceDD();
    _getMaterialList();
    if (invoiceId) {
      _getInvoiceData({ data: { id: invoiceId } });
    }
  }, [invoiceId]);

  const onChange = (e) => {
    let item;
    let fieldTypeItem;
    let fieldType;
    
    const {
      target: { value, name },
    } = e;
    let newValue;
    if (e.target.dataset) {
      item = e.target.dataset.item;
      //Get field type from  from custom attribute, this used for select mainly
      fieldTypeItem = e.target.dataset.type;
    }

    if (fieldTypeItem) {
      fieldType = fieldTypeItem;
    } else {
      fieldType = e.target.type;
    }

    switch (fieldType) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    if (item) {
      _setInvoiceData((prevState) => {
        let newItems = prevState.orderItemsComm.map((i) => {
          //Did not use === becuase '5' equals to 5
          if (i.itemNumber == item) {
            return { ...i, ...newValueObject };
          }
          return i;
        });
        return { ...prevState, orderItemsComm: newItems };
      });
    } else {
      _setInvoiceData((prevState) => {
        return { ...prevState, ...newValueObject };
      });
    }
  };

  const handleChange = (event, newValue) => setValue(newValue);



  //Use the context and pass the data, dropdowns
  const contextState = {
    onChange,
    operation,
    dropDowns: _invoiceDD || [],
    invoiceId,
    _invoiceData,
    _setInvoiceData,
    _saveInvoiceData,
    _getPartnerList,
    _partnerList,
    _materialList,
    _tankList,
    _getMaterialList,
    _getTankList,
    setFrontendError,
  };


  const operationLoading =
    _loadingInvoiceData       ||
    _loadingMaterialList           ||
    _loadingtInvoiceDD        ||
    _loadingPartnerList            ||
    _loadingSaveInvoiceData   ||
    _loadingTankList;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveInvoiceData([]);
        _setSuccessInvoiceData([]);
        _setSuccessInvoiceDD([]);
        _setSuccessMaterialList([]);
        _setSuccessPartnerList([]);
        _setSuccessTankList([]);
        break;
      case "error":
        _setErrorInvoiceData([]);
        _setErrorInvoiceDD([]);
        _SetErrorMaterialList([]);
        _setErrorSaveInvoiceData([]);
        _setErrorPartnerList([]);
        _setErrorTankList([]);
        setFrontendError([]);
        break;
      case "warning":
        setFrontendWarning([]);
      default:
        break;
    }
  }
  return (
    <InvoiceContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <InvoiceOperationHeader
        _loadingSaveInvoicelData={_loadingSaveInvoiceData}
      />
      <Tabpannel
        value={value}
        handleChange={handleChange}
        labels={["Home",  "Items","Partners","Transportation","Admin","Log"]}
        components={[
          UpdatedHomeComponent,
          Items,
          PartnerTab,
          AdminTab,
          () => (
            <MessageLog
              errorMessages={[
                ..._errorInvoiceDD,
                ..._errorInvoiceData,
                ..._errorMaterialList,
                ..._errorSaveInvoiceData,
                ..._errorPartnerList,
              ]}
              successMessages={[
                ..._successInvoiceDD,
                ..._successInvoiceData,
                ..._successMaterialList,
                ..._successSaveInvoiceData,
                ..._successPartnetList,
              ]}
              warningMessages={[...frontendWarning]}
              clearMessages={clearMessages}
            ></MessageLog>
          ),
        ]}
      />
    </InvoiceContext.Provider>
  );
}


