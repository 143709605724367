import React, { useState, useEffect } from "react";
import { useMessage } from "../../../../hooks/UseMessage";
import { useUser } from "../../../../hooks/useUser";
import { useStation } from "../../../../hooks/UseStation";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import { ReactComponent as ReactSulfurCard } from "../../../../Assets/NewIconsForCards/Sulfur.svg";
import { ReactComponent as ReactCokeCard } from "../../../../Assets/NewIconsForCards/PetroleumCoke.svg";
import { ReactComponent as ReactSignatureCard } from "../../../../Assets/NewIconsForCards/SignatureIcon.svg";
import { ReactComponent as ReactPrinterCard } from "../../../../Assets/NewIconsForCards/PrinterIcon.svg";
import { ReactComponent as ReactTruckScaleCard } from "../../../../Assets/NewIconsForCards/TruckScaleIcon.svg";
import CardV1 from "../../../../Home/Components/CardV1";

export default function EldoAdminHome(props) {
  const [newCards, setNewCards] = useState([]);
  const [_user] = useUser();
  const [_stationInfo, , , , , _stationMessages, _setStationMessages] =
    useStation();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  useEffect(() => {
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card.roles.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        _setStationMessages([]);
        break;
      case "warning":
        break;
      default:
        break;
    }
  }

  const CardsArray = [
    {
      title: "Sulfur",
      text: "",
      Icon: ReactSulfurCard,
      link: "/SulfurOverview",
      roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
    },
    {
      title: "Petroleum Coke",
      text: "",
      Icon: ReactCokeCard,
      link: "/TerminalActivity/CheckinV2/COKE-ELDO",
      roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
    },
    {
      title: "BOL Signature",
      text: "",
      Icon: ReactSignatureCard,
      link: "/TerminalActivity/SignatureReady",
      roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
    },
    {
      title: "Print Scale Ticket",
      text: "",
      Icon: ReactPrinterCard,
      link: "/PrintScaleTicket",
      roles: ["Admin", "SuperAdmin", "TerminalUser"],
    },
    {
      title: "Print BOL",
      text: "",
      Icon: ReactPrinterCard,
      link: "/PrintBOL",
      roles: ["Admin", "SuperAdmin", "TerminalUser"],
    },
    {
      title: "Scale State",
      text: "",
      Icon: ReactTruckScaleCard,
      link: "/TerminalActivity/MTScaleState",
      roles: ["Admin", "SuperAdmin", "TerminalUser"],
    },
  ];

  return (
    <div className="container-fluid ">
      <div className="mainContainer">
        <div className="overviewCardContainer">
          {newCards.map((record, i) => {
            let newRecord = {
              ...record,
              loggedStationId: _stationInfo.id,
              _createErrorMessage,
            };
            return <CardV1 {...newRecord} key={i} />;
          })}
        </div>
      </div>
      <MessageLog
        errorMessages={[..._errorMessages]}
        successMessages={[]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}
