import React from "react";
import Modal from "react-bootstrap/Modal";
import { DataGrid } from '@mui/x-data-grid';
import Button from "react-bootstrap/Button";

export default function TankList({
  _tankList,
  showTankList,
  setShowTankList,
  tankFieldName,
  onChange,
}) {
 
  function handleOnSelect(selectedId) {
    debugger;
    onChange({
      target: { value: selectedId[0], name: tankFieldName, type: "text"  },
    });
    setShowTankList(false);
  }

  const columns = [
    { field: 'id', headerName: 'Tank ID', width: 250 },
    { field: 'tankFarmId', headerName: 'Tank Farm', width: 350 },
    { field: 'tankGroupId', headerName: 'Tank Group', width: 200 },
    { field: 'capacity', headerName: 'Capacity', width: 200 },
  ];

  return (
    <Modal
      show={showTankList}
      size="xl"
      onHide={() => setShowTankList(false)}
      dialogClassName="modal-90w modal-dialog-scrollable"
      aria-labelledby="example-custom-modal-styling-title"
      className="partner-list-modal"
    >
      <Modal.Header
        closeButton
        style={{
          background: "#d4e1f5",
        }}
      >
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{
            fontWeight: 700,
          }}
        >
          List of Tanks
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {_tankList?.length && (
        <div style={{ height: '50vh', width: '100%' }}>
          <DataGrid
          rows={_tankList || []}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[5]}
          onSelectionModelChange={selectedId => handleOnSelect(selectedId)}
        />
        </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="offset-11 col-1">
          <Button
            variant="danger"
            onClick={() => {
              setShowTankList(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
