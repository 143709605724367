/** @format */

import React, { useState, useEffect } from "react";
import { mainArray } from "./Fields";
import { FieldsReducer } from "../../../CommonComponents/Reducer/Index";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../../hooks/useFetch";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { useNavigate } from "react-router-dom";

function GetERPOrder(props) {
  const [erpOrderParam, setERPOrderParam] = useState();
  let navigate = useNavigate();

  const [
    _getOrder,
    _orderData,
    _setOrderData,
    _errorOrder,
    _setErrorOrder,
    _successOrder,
    _setSuccessOrder,
    _loadingOrder,
  ] = useFetch(GetFullURL("GetFromERPOrder"), { method: "POST" }, true);

  const [
    _getGeneralDD,
    _GeneralDDData,
    ,
    _errorGeneralDD,
    _setErrorGeneralDD,
    _successGeneralDD,
    _setSuccessGeneralDD,
    _loadingGeneralDD,
  ] = useFetch(GetFullURL("GetGeneralDropDown"), { method: "POST" });

  const operationLoading = _loadingOrder || _loadingGeneralDD;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessOrder([]);
        _setSuccessGeneralDD([]);
        break;
      case "error":
        _setErrorOrder([]);
        _setErrorGeneralDD([]);
        break;
      default:
        break;
    }
  }

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setERPOrderParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  useEffect(() => {
    _getGeneralDD({ data: { locations: true } });
  }, []);

  const fieldsState = {
    dropDowns: _GeneralDDData || [],
  };

  const mainFieldsToRender = mainArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    return newFieldObject;
  };

  return (
    <>
      <section className="heroCard heroPartner">
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="cardHeader">
                  <h2>Pull Order From SAP</h2>
                </div>
                <div className="cardBody">
                  {mainFieldsToRender.map((field, index) => (
                    <FieldsReducer
                      field={mergedFieldData(field, erpOrderParam)}
                      key={index}
                    />
                  ))}
                  <div className="cardButton d-flex">
                    <Button
                      onClick={() => {
                        _getOrder(
                          {
                            data: {
                              Id: erpOrderParam.id,
                              LocationId: erpOrderParam.locationId,
                            },
                          },
                          () => {}
                        );
                      }}
                      style={{
                        width: "49%",
                      }}
                    >
                      Get Order From SAP
                    </Button>

                    <Button
                      className="ms-3 px-3"
                      style={{ background: "#DA1212", borderColor: "#DA1212" }}
                      onClick={() => {
                        let navigationNextUrl = `/Home`;
                        navigate(navigationNextUrl);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            marginLeft: "10px",
          }}
        >
          <pre>{JSON.stringify(_orderData)}</pre>
        </div>
        <MessageLog
          errorMessages={[..._errorOrder, ..._errorGeneralDD]}
          successMessages={[..._successOrder, ..._successGeneralDD]}
          clearMessages={clearMessages}
        ></MessageLog>
      </div>
    </>
  );
}

export default GetERPOrder;
