import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../hooks/useFetch";
import { useMessage } from "../../../../hooks/UseMessage";
import { GetFullURL } from "../../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { ReactComponent as ReactSignatureCard } from "../../../../Assets/NewIconsForCards/SignatureIcon.svg";
import { ReactComponent as ReactPrinterCard } from "../../../../Assets/NewIconsForCards/PrinterIcon.svg";
import Card from "../../../../Home/Components/Card";

export default function EldoSignatureStation(props) {
  const [usedStation, setUsedStation] = useState();
  const [newCards, setNewCards] = useState([]);
  const userState = useSelector((state) => state.User.user);
  const userRoles = userState.roles;
  const language = userState.preferredLanguage;

  const userStation = userState.stationId;

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [
    _getStationData,
    _stationData,
    ,
    _errorStationData,
    _setErrorStationData,
    _successStationData,
    _setSuccessStationData,
    _loadingStationData,
  ] = useFetch(GetFullURL("GetStation"), { method: "POST" });

  const operationLoading = _loadingStationData;
  let storedCookie;

  useEffect(() => {
    let usedStation = "";
    storedCookie = Cookies.get("station");

    if (!userStation) {
      if (!storedCookie) {
        _createErrorMessage("1");
        return;
      }
    }
    usedStation = storedCookie ? storedCookie : userStation;

    setUsedStation(usedStation);
    _getStationData({ data: { stationId: usedStation } });
  }, []);
  useEffect(() => {
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card.roles.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = userRoles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);
  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessStationData([]);
        break;
      case "error":
        _setErrorStationData([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }

  const CardsArray = [
    {
      title: "BOL Signature",
      text: "",
      Icon: ReactSignatureCard,
      link: "/TerminalActivity/SignatureReady",
      roles: ["Admin", "SuperAdmin", "TerminalUser"],
    },
    {
      title: "Print Scale Ticket",
      text: "",
      Icon: ReactPrinterCard,
      link: "/PrintScaleTicket",
      roles: ["Admin", "SuperAdmin", "TerminalUser"],
    },
    {
      title: "Print BOL",
      text: "",
      Icon: ReactPrinterCard,
      link: "/PrintBOL",
      roles: ["Admin", "SuperAdmin", "TerminalUser"],
    },
  ];

  return (
    <div className="container-fluid ">
      {operationLoading && <LoadingScreen />}
      <div className="mainContainer">
        <div className="overviewCardContainer">
          {newCards.map((record, i) => (
            <Card {...record} key={i} />
          ))}
        </div>
      </div>
      <MessageLog
        errorMessages={[..._errorStationData, ..._errorMessages]}
        successMessages={[..._successStationData]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}
