export const mainDataArray = ({ dropDowns }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Inventory Document ID",
    key: true,
  },
  {
    id: "companyId",
    name: "companyId",
    type: "text",
    label: "Company",
    tag: "select",
    options: dropDowns?.companies || [],
  },

  {
    id: "inventoryDocTypeId",
    name: "inventoryDocTypeId",
    type: "text",
    label: "Inventory Doc Type Id",
    tag: "select",
    options: dropDowns?.invDocTypes || [],
  },
  {
    id: "moveTypeId",
    name: "moveTypeId",
    type: "text",
    label: "Move Type Id",
    tag: "select",
    options: dropDowns?.moveTypes || [],
  },
];

export const bolDataArray = ({ dropDowns }) => [

  {
    id: "bolNumber",
    name: "bolNumber",
    type: "text",
    label: "BOL Number",
    
  },
  {
    id: "ref1",
    name: "ref1",
    type: "text",
    label: "Ref1",
  },
  {
    id: "Ref2",
    name: "Ref2",
    type: "text",
    label: "Ref2",
  },
  
];

export const dateDataArray = ({ dropDowns }) => [

  {
    id: "invDocDT",
    name: "invDocDT",
    type: "datetime",
    label: "Inventory Document Date",
  },
  {
    id: "postingDT",
    name: "postingDT",
    type: "datetime",
    label: "Posting DT",
  },

];




