export const gridArray = () => [
  {
    id: "orderId",
    name: "orderId",
    type: "text",
    label: "Order",
    disabled: true,
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    label: "Location",
    disabled: true,
  },
  {
    id: "orderStatus",
    name: "orderStatus",
    type: "text",
    label: "Order Status",
    disabled: true,
  },
  {
    id: "creationDT",
    name: "creationDT",
    type: "datetime",
    label: "Creation DT",
    disabled: true,
  },
  {
    id: "loadDT",
    name: "loadDT",
    type: "datetime",
    label: "Load DT",
    disabled: true,
  },
  {
    id: "syncRequired",
    name: "syncRequired",
    type: "text",
    label: "Sync Required",
    disabled: true,
  },
  {
    id: "orderPulledfromERP",
    name: "orderPulledfromERP",
    type: "text",
    label: "Order Pulled From ERP",
    disabled: true,
  },
];
