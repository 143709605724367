export type fieldType = {
  id: string;
  name: string;
  type: string;
  label: string;
  displayOnly?: boolean;
  disabled?: boolean;
};
export const mainArray = () => [
  {
    id: 'id',
    name: 'id',
    type: 'text',
    label: 'User ID',
    displayOnly: true,
  },

  {
    id: 'firstName',
    name: 'firstName',
    type: 'text',
    label: 'First Name',
  },
  {
    id: 'lastName',
    name: 'lastName',
    type: 'text',
    label: 'Last Name',
  },
  {
    id: 'password',
    name: 'password',
    type: 'text',
    label: 'Password',
    disabled: true,
  },
];

export const infoArray = ({ dropDowns }) => [
  {
    id: 'preferredLanguage',
    name: 'preferredLanguage',
    type: 'text',
    label: 'Preferred Language:',
    tag: 'select',
    options: dropDowns?.languages || [],
  },
  {
    id: 'userTypeId',
    name: 'userTypeId',
    type: 'text',
    label: 'User Type:',
    tag: 'select',
    options: dropDowns?.userTypes || [],
  },
  {
    id: 'defaultUrl',
    name: 'defaultUrl',
    type: 'text',
    label: 'Default URL',
  },
  {
    id: 'active',
    name: 'active',
    type: 'checkbox',
    label: 'Active User',
    tag: 'checkbox',
  },
  {
    id: 'stationId',
    name: 'stationId',
    type: 'text',
    label: 'Station:',
    tag: 'select',
    options: dropDowns?.stations || [],
  },
  {
    id: 'email',
    name: 'email',
    type: 'text',
    label: 'Email',
  },
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    type: 'text',
    label: 'Phone Number',
  },
  {
    id: 'serviceSimulation',
    name: 'serviceSimulation',
    type: 'checkbox',
    label: 'Service Simulation',
    tag: 'checkbox',
  },
  {
    id: 'disableKeyboard',
    name: 'disableKeyboard',
    type: 'checkbox',
    label: 'Disable Keyboard',
    tag: 'checkbox',
  },
  {
    id: 'singleTerminalUser',
    name: 'singleTerminalUser',
    type: 'checkbox',
    label: 'Single Terminal User',
    tag: 'checkbox',
  },
  {
    id: 'systemConfigurator',
    name: 'systemConfigurator',
    type: 'checkbox',
    label: 'System Configurator',
    tag: 'checkbox',
  },
];

export const defaultsArray = ({dropDowns}) => [
  {
    id: 'defaultLocationId',
    name: 'defaultLocationId',
    type: 'text',
    label: 'Default Location',
    tag: 'select',
    options: dropDowns?.locations || [],
  },
  {
    id: 'defaultTemperatureUnit',
    name: 'defaultTemperatureUnit',
    type: 'text',
    label: 'Default Temperature Unit',
    tag: 'select',
    options: dropDowns?.tempUnits || [],
  },
  {
    id: 'defaultTankFarmId',
    name: 'defaultTankFarmId',
    type: 'text',
    label: 'Default Tank Farm',
    tag: 'select',
    options: dropDowns?.tankFarms || [],
  },
  {
    id: 'manualMesuarmentsQty',
    name: 'manualMesuarmentsQty',
    type: 'checkbox',
    label: 'ManualMesuarmentsQty',
    tag: 'checkbox',
  },
];

export const dateArray = () => [
  {
    id: 'lastLoginDT',
    name: 'lastLoginDT',
    type: 'datetime',
    label: 'Last Login DT',
    disabled: true,
  },
  {
    id: 'validFromDT',
    name: 'validFromDT',
    type: 'datetime',
    label: 'Valid From DT',
  },
  {
    id: 'validToDT',
    name: 'validToDT',
    type: 'datetime',
    label: 'Valid To DT',
  },
];
