export const leftPanelArray = () => [
  {
    id: "referenceDoc",
    name: "referenceDoc",
    type: "text",
    label: "Order",
    disabled: true,
  },
  {
    id: "materialDescriptor",
    name: "materialDescriptor",
    type: "text",
    label: "Material",
    disabled: true,
  },
  {
    id: "driverDescriptor",
    name: "driverDescriptor",
    type: "text",
    label: "Driver",
    disabled: true,
  },
  {
    id: "activityId",
    name: "activityId",
    type: "text",
    label: "Haul Type",
    disabled: true,
  },
];
export const carrierArray = ({ dropDowns, trmCarriers }) => [
  {
    id: "carrierId",
    name: "carrierId",
    type: "text",
    label: "Carrier",
    tag: "select",
    options: prepareCarrierDD(dropDowns, trmCarriers) || [],
    required: true,
  },
];
export const vehicleArray = ({ dropDowns, trmCarriers }) => [
  {
    id: "vehicleId",
    name: "vehicleId",
    type: "text",
    label: "Vehicle",
    tag: "inputKeyboard",
    placeHolder: "Enter Vehicle",
    defaultKeyboardType: "number",
    required: true,
  },
  ,
];
export const trailerArray = ({ dropDowns, trmCarriers }) => [
  {
    id: "trailerId",
    name: "trailerId",
    type: "text",
    label: "Trailer",
    tag: "inputKeyboard",
    placeHolder: "Enter Trailer",
    defaultKeyboardType: "number",
    required: true,
  },
  ,
];

function prepareCarrierDD(dropDowns, trmCarriers) {
  if (typeof trmCarriers !== "undefined" && trmCarriers.length === 0) {
    return dropDowns?.carriers;
  }
  return trmCarriers;
}
