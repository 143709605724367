import React, { useState, useEffect } from "react";
import { ReactComponent as ReactSettingsCard } from "../Assets/NewIconsForCards/SettingsIcon.svg";
import { ReactComponent as ReactMasterDataCard } from "../Assets/NewIconsForCards/MasterDataIcon.svg";
import { ReactComponent as ReactUserSettingsCard } from "../Assets/NewIconsForCards/UserSettingsIcon.svg";
import { ReactComponent as ReactPlannedOrderCard } from "../Assets/NewIconsForCards/BusinessProcessIcon.svg";
import { ReactComponent as ReactDashboardCard } from "../Assets/NewIconsForCards/Dashboard.svg";
import { ReactComponent as ReactTerminalOperationCard } from "../Assets/NewIconsForCards/TerminalOperations.svg";
import { ReactComponent as ReportIcon } from "../Assets/NewIconsForCards/ReportIcon.svg";
import { ReactComponent as BasicSystemIcon } from "../Assets/NewIconsForCards/BasicSystemIcon.svg";
import { ReactComponent as LabSystemIcon } from "../Assets/NewIconsForCards/LabSystem/LabSystemIcon.svg";
import Card from "./Components/Card";
import { useUser } from "../hooks/useUser";

const CardsArray = [
  {
    title: "App Settings",
    text: "",
    Icon: ReactSettingsCard,
    link: "/Settings",
    roles: ["SuperAdmin"],
    systemConfig: true,
  },
  {
    title: "Master Data",
    text: "",
    Icon: ReactMasterDataCard,
    link: "/MasterDataHome",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Dashboard",
    text: "",
    Icon: ReactDashboardCard,
    link: "/DashboardHome",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Business Process",
    text: "",
    Icon: ReactPlannedOrderCard,
    link: "/BusinessProcess",
    roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
  },
  {
    title: "Terminal Operations",
    text: "",
    Icon: ReactTerminalOperationCard,
    link: "/TerminalActivity/TerminalsHome",
    roles: ["Admin", "SuperAdmin", "TerminalUser", "Loader"],
  },
  {
    title: "User Settings",
    text: "",
    Icon: ReactUserSettingsCard,
    link: "/UserMaintenance",
    roles: ["Admin", "SuperAdmin", "Loader"],
  },
  {
    title: "Reports",
    text: "",
    Icon: ReportIcon,
    link: "/ReportsHome",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Basic System",
    text: "",
    Icon: BasicSystemIcon,
    link: "/BasicSystem",
    roles: ["SuperAdmin"],
  },
  {
    title: "Lab System",
    text: "",
    Icon: LabSystemIcon,
    link: "/LabSystemHome",
    roles: ["Admin", "SuperAdmin"],
  },
];

export default function Home(props) {
  const [_user, ,] = useUser();
  const [newCards, setNewCards] = useState([]);
  useEffect(() => {
    if (!_user.roles) {
      return CardsArray;
    }
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card?.roles?.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        if (
          card.systemConfig === true &&
          (_user.systemConfigurator === "false" || !_user.systemConfigurator)
        ) {
          return;
        }

        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);

  return (
    <div className="overviewCardContainer">
      {newCards.map((record, i) => (
        <div key={i}>
          <Card {...record} />
        </div>
      ))}
    </div>
  );
}
