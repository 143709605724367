import React, { useContext } from "react";
import { InventoryDocContext } from "../InventoryDocumentMain/Contex";

export default function TopPanel() {
  const { operation, inventoryDocId } = useContext(InventoryDocContext);

  var inventoryDocInfo;
  switch (operation) {
    case "Display":
      inventoryDocInfo = `${operation} Inventory Document: ${inventoryDocId}`;
      break;
    case "Change":
      inventoryDocInfo = `${operation} Inventory Document: ${inventoryDocId}`;
      break;
    case "Create":
      inventoryDocInfo = `${operation} New Inventory Document:`;
      break;
    default:
      inventoryDocInfo = `Inventory Doc Info`;
      break;
  }
  return (
    <h2
      className="heading"
      style={{ marginLeft: 44, position: "relative", top: 8 }}
    >
      {inventoryDocInfo}
    </h2>
  );
}
