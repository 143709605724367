const newUserData = (arg) => {
  return {
    type: "NEW_USER_DATA",
    payload: arg,
  };
};

const LogOut = () => ({
  type: "LOG_OUT",
});

export { newUserData, LogOut };
