import React from "react";
import { addressFieldsArray } from "./fields";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import { useContext } from "react";
import { LogisticStationContext } from "../context";

export default function AddressTab() {
  const { _logisticStationData, onChange, operation, dropDowns } =
    useContext(LogisticStationContext);

  //Add data source to field object and change some attributes
  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _logisticStationData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };

  const fieldsState = {
    dataSource: _logisticStationData,
    dropDowns,
  };

  //Get view fields and assign drop-down values
  let viewFields = addressFieldsArray(fieldsState);
  return (
    <div className="row">
      <div className="col-lg-3 col-xs-6 col-xs-12">
        <div className="card" style={{ minHeight: "auto" }}>
          <div className="cardHeader">
            <h2>Address Info</h2>
          </div>
          <div className="cardBody">
            {viewFields.map((field) => (
              <FieldsReducer field={mergedFieldData(field)} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
