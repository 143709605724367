export const driverArray = ({ dropDowns, dataSource, onChange }) => [
  {
    id: "pin",
    name: "pin",
    type: "number",
    label: "Driver PIN",
    tag: "inputKeyboard",
    placeHolder: "Type Max Driver PIN Here...",
    dataSource,
    onChange,
  },
  {
    id: "driverDescriptor",
    name: "driverDescriptor",
    type: "text",
    label: "Driver",
    dataSource,
    onChange,
  },
];

export const sideBarArray = ({ dataSource, onChange, weightTypeOptions }) => [
  {
    id: "weightType",
    name: "weightType",
    type: "text",
    label: "Requsted Weight Type",
    tag: "select",
    options: weightTypeOptions,
    dataSource,
    onChange,
  },
];
