export const weightArray1 = ({ dropDowns }) => [
  {
    id: "inWeight",
    name: "inWeight",
    type: "number",
    label: "In Weight",
  },
  {
    id: "midWeight",
    name: "midWeight",
    type: "number",
    label: "Mid Weight",
  },
  {
    id: "outWeight",
    name: "outWeight",
    type: "number",
    label: "Out Weight",
  },
  {
    id: "netWeight",
    name: "netWeight",
    type: "number",
    label: "Net Weight",
  },
];
export const tempArray = ({ dropDowns }) => [
  {
    id: "temperature",
    name: "temperature",
    type: "number",
    label: "Temperature",
  },
  {
    id: "temperatureUnitId",
    name: "temperatureUnitId",
    type: "text",
    label: "Temperature Unit",
    tag: "select",
    options: dropDowns?.tempUnits || [],
  },
  {
    id: "gravity",
    name: "gravity",
    type: "text",
    label: "Gravity",
  },
  
];
export const weightArray2 = ({ dropDowns }) => [
  {
    id: "cutoffWeight",
    name: "cutoffWeight",
    type: "number",
    label: "Cutoff Weight",
  },
  {
    id: "preactWeight",
    name: "preactWeight",
    type: "number",
    label: "Preact Weight",
  },
  {
    id: "maxWeight",
    name: "maxWeight",
    type: "number",
    label: "Max Weight",
  },
  {
    id: "weightUnitId",
    name: "weightUnitId",
    type: "text",
    label: "Weight Unit",
    tag: "select",
    options: dropDowns?.weightUnits || [],
  },
];

export const scaleAndMeterArray = ({ dropDowns }) => [
  {
    id: "currentScaleWeight",
    name: "currentScaleWeight",
    type: "number",
    label: "Current Scale Weight",
  },
  {
    id: "scaleUnitId",
    name: "scaleUnitId",
    type: "text",
    label: "ScaleUnitId",
    tag: "select",
    options: dropDowns?.weightUnits || [],
  },
  {
    id: "currentMeterReading",
    name: "currentMeterReading",
    type: "number",
    label: "Current Meter Reading",
  },
  {
    id: "meterUnitId",
    name: "meterUnitId",
    type: "text",
    label: "Meter Unit",
    tag: "select",
    options: dropDowns?.weightUnits || [],
  },
];
