exports.DeterLTFMTermSetup = [
  {
    id: "id",
    name: "id",
    type: "number",
    value: "",
    label: "Id",
    key: "true",
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    value: "",
    label: "Universal Descriptor",
    length: 50,
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    value: "",
    label: "LocationId",
  },

  {
    id: "transportationModeId",
    name: "transportationModeId",
    type: "text",
    value: "",
    label: "MoT",
  },
  {
    id: "fieldProcessId",
    name: "fieldProcessId",
    type: "text",
    value: "",
    label: "field Process Id",
  },
  {
    id: "materialId",
    name: "materialId",
    type: "text",
    value: "",
    label: "Material Id",
  },

  {
    id: "trmSetupId",
    name: "trmSetupId",
    type: "text",
    value: "",
    label: "Terminal Setup Number",
  },
  {
    id: "compTrmSetupUpdate",
    name: "compTrmSetupUpdate",
    type: "boolean",
    value: "",
    label: "Complete Terminal Update",
  },
  {
    id: "shipDocPrintOptions",
    name: "shipDocPrintOptions",
    type: "text",
    value: "",
    label: "ShipDoc Print Options",
    length: 3,
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
