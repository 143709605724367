exports.CommGroupAssigment = [
  {
    id: "id",
    name: "id",
    type: "number",
    value: "",
    label: "Id",
    key: "true",
    displayonly:"true"
   },
   {
    id: "commGroupId",
    name: "commGroupId",
    type: "text",
    value: "",
    label: "CommGroupId",
    length: 50,
    ddid:"commGroupId",
  },
    {
      id: "email",
      name: "email",
      type: "text",
      value: "",
      label: "Email",
      length: 150,
    },
    {
      id: "fax",
      name: "fax",
      type: "text",
      value: "",
      label: "Fax",
      length: 150,
    },
    {
      id: "telephone",
      name: "telephone",
      type: "text",
      value: "",
      label: "Telephone",
      length: 150,
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "datetime",
      label: "ModifiedOn",
      displayonly: "true",
      value: " ",
    },
  ];
  