import React, { useState } from "react";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Keybaord from "../keybaordV1/index";
//import "./Calculator.css";
export interface IKeyboardInputPropsV1 {
  value: any;
  placeholder: string;
  onChange: (val?: any) => void;
  onEnterPress: () => void;
  name: string;
  disableKeyboard: boolean;
  actionButton?: React.ReactElement;
  keyboardField: React.MutableRefObject<any>;
  fieldType: string;
  defaultKeyboard: string;
}
export default function KeyboardInputV1(props: IKeyboardInputPropsV1) {
  const {
    actionButton,
    value: passedValue,
    placeholder,
    onEnterPress = () => {},
    onChange = () => {},
    name,
    disableKeyboard = false,
    keyboardField,
    fieldType,
    defaultKeyboard,
  } = props;

  const [showKeyboard, setShowkeyboard] = useState(false);
  const onFocus = () => {
    if (!disableKeyboard) {
      setShowkeyboard(true);
    }
  };

  const onBlur = () => {
    //hide key board once usere clicks outside it
    setShowkeyboard(false);
  };

  const okButton = () => {
    //hide key board once usere clicks outside it
    setShowkeyboard(false);
  };

  const onChangeKeybaordInput = (keybaordValue) => {
    let changeObject = { target: { name: [name], value: keybaordValue } };
    onChange(changeObject);
  };
  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onEnterPress();
    }
  };
  // for input it runs like other input fields, it get the value from higher order state via variable (passedValue)
  //and on change of field it calls a function that updates this higher order state, the input does
  //not care about the key board

  // for input it runs like other input fields, it get the value from higher order state via variable (passedValue)
  //and on change of keybaord value it calls a function that updates this higher order state, this done
  //via call to  "onChangeKeybaordInput" in order prepare response then it call "onChange"
  return (
    <ClickAwayListener onClickAway={onBlur}>
      <div className="keyboard-whole-wrapper">
        <div className="row">
          <div className="col-12">
            <input
              onClick={onFocus}
              type={fieldType}
              value={passedValue}
              name={name}
              onChange={onChange}
              onKeyDown={_handleKeyDown}
              ref={keyboardField}
              style={{
                borderRadius: 8,
                outline: "none !important",
                padding: "0px 16px",
                height: 70,
                fontSize: "40px",
                width: "100%",
              }}
              placeholder={placeholder}
            />
          </div>
        </div>
        {showKeyboard && (
          <Keybaord
            setInputValue={onChangeKeybaordInput}
            inputValue={passedValue}
            fieldType={fieldType}
            handleOKButton={okButton}
            defaultKeyboard={defaultKeyboard}
          />
        )}
      </div>
    </ClickAwayListener>
  );
}
