import React, { useContext } from "react";
import Paper from '@mui/material/Paper';

import TopPanel from "../../Components/TopPanel";
import { InventoryDocContext } from "../Contex";
import { MuiButon } from "../../../../CommonComponents/MuiButton";



export default function InventoryDocOperationHeader({
  _loadingSaveInventoryDocData,
}) {

  const {
    operation,
    _setInventoryDocData,
    _inventoryDocData,
    _saveInventoryDocData,
  } = useContext(InventoryDocContext);

  const handleSubmit = () => {
    _saveInventoryDocData(
      { data: _inventoryDocData },
      ({ data: newInventoryDocData }) => {
        _setInventoryDocData(newInventoryDocData);
      }
    );
  };

  return (
    <Paper
    className="py-2 px-2 d-flex align-items-center justify-space-between customPaper"

  >
    <TopPanel />
    <MuiButon
      title="Save"
      loading={_loadingSaveInventoryDocData}
      onClick={handleSubmit}
      disabled={operation === "Display"}
    />
  </Paper>
  );
}
