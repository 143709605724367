exports.LogisticStationType = [
    {
      id: "id",
      name: "id",
      type: "text",
      value: "",
      label: "Id",
      length: 24,
      key: "true",
    },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      value: "",
      label: "Universal Descriptor",
      length: 50,
    },
    {
      id: "numberRangeOptions",
      name: "numberRangeOptions",
      type: "number",
      value: "",
      label: "NumberRangeOptions",
      ddid: "DocTypeNumberRangeOptions",
    },
    {
      id: "numberRangeId",
      name: "numberRangeId",
      type: "text",
      value: "",
      label: "Station Number Range",
      length: 12,
      ddid: "logisticStationDocNumberRangeId",
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "datetime",
      label: "ModifiedOn",
      displayonly: "true",
      value: " ",
    },
  ];
  