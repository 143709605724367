export const ValidateTakingWeight = (
  shipDocData,
  weight,
  requestedWeightType,
  _createErrorMessage
) => {
  if (weight <= 0) {
    _createErrorMessage("56");
    return false;
  }
//For Eldo, the outWeight Can not Greater that 85500lb 4/28/2023,
if (requestedWeightType === `OUT` && shipDocData.locationId == '2400') {
  if (weight > 85500) {
    _createErrorMessage("144");
    return false;
  }
}



  //If trying to take out might, then in must have been taken
  if (requestedWeightType === `OUT`) {
    if (shipDocData.inWeight <= 0) {
      _createErrorMessage("57");
      return false;
    }
  }

  //If trying to take IN might, then must be greater than 10000LB
  if (requestedWeightType === `IN`) {
    if (weight <= 10000) {
      _createErrorMessage("130");
      return false;
    }
  }

    //If trying to take IN might, then must NOT be greater than 30000LB
    if (requestedWeightType === `IN`) {
      if (weight > 50000) {
        _createErrorMessage("136");
        return false;
      }
    }

  return true;
};
