export const mainArray = ( {dropDowns}) => [
    {
        id: "id",
        name: "id",
        type: "text",
        label: "BOL ID",
    },
    {
      id: "email",
      name: "email",
      type: "text",
      label: "Email",
  },
  ];

  