import React from "react";

function MTDataInfoSIdeBar({ _mTData }) {
  return (
    <div style={{background: '#FFFFFF',boxShadow: '0px 3px 10px -2px rgba(0, 0, 0, 0.1)',borderRadius: 15,border: 'none',padding: 25}}>
      <label htmlFor="Scale In Motion" style={{ fontWeight: 600 }}>
        Scale in Motion
      </label>

      <input
        placeholder={"Scale in Motion"}
        type="boolean"
        disabled={true}
        value={_mTData.scaleMotion}
        className="form-control"
        style={{
          borderRadius: 10,
          outline: "none !important",
          height: 50,
          fontSize: 16,
          marginBottom: 10
        }}
      />

      <label htmlFor="Scale Status" style={{ fontWeight: 600 }}>
        Scale Status
      </label>

      <input
        placeholder={"Scale Status"}
        type="text"
        disabled={true}
        value={_mTData.scaleStatus}
        className="form-control"
        style={{
          borderRadius: 10,
          outline: "none !important",
          height: 50,
          fontSize: 16,
          marginBottom: 10
        }}
      />

      <label htmlFor="In Weight Taken" style={{ fontWeight: 600 }}>
        In Weight Taken
      </label>

      <input
        placeholder={"In Weight Taken"}
        type="boolean"
        disabled={true}
        value={_mTData.inWeightTaken}
        className="form-control"
        style={{
          borderRadius: 10,
          outline: "none !important",
          height: 50,
          fontSize: 16,
          marginBottom: 10
        }}
      />
      <label htmlFor="Out Weight Taken" style={{ fontWeight: 600 }}>
        Out Weight Taken
      </label>

      <input
        placeholder={"Out Weight Taken"}
        type="boolean"
        disabled={true}
        value={_mTData.outWeightTaken}
        className="form-control"
        style={{
          borderRadius: 10,
          outline: "none !important",
          height: 50,
          fontSize: 16,
        }}
      />
    </div>
  );
}

export default MTDataInfoSIdeBar;
