import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetFullURL } from "./../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput";
import OverviewButtons from "./Components/OverviewButtons";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../CommonComponents/CircleButton";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import cardlogo from "../../Assets/cardLogo.svg";
import { useMessage } from "../../hooks/UseMessage";

function PartnerOverview({ partnerType }) {
  let navigate = useNavigate();

  const [partner, setPartner] = useState();
  const [showPartnerList, setShowPartnerList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [_getPartnersList, _partnerList, , , , , , _loadingtPartnerList] =
    useFetch(GetFullURL("PartnerList"), { method: "POST" });

  const [_getPartnerExists, , , , , , , _loadingtPartnerExist] = useFetch(
    GetFullURL("PartnerExists"),
    { method: "POST" }
  );

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }

  let buttonText;
  const operationLoading = _loadingtPartnerExist || _loadingtPartnerList;
  if (partnerType) {
    buttonText = `List of ${partnerType}s`;
  } else {
    buttonText = `List of Partners`;
  }

  return (
    // partner Overview here
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../Assets/icon/partner.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Partner Overview- {partnerType}
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setPartner}
                    document={partner}
                    placeholder={`${partnerType} Partner Id here`}
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={buttonText}
                    clickHandler={() => {
                      _getPartnersList(
                        { data: { partnerTypeId: partnerType } },
                        () => {
                          setShowPartnerList(true);
                        }
                      );
                    }}
                  />
                  <OverviewButtons
                    partner={partner}
                    partnerType={partnerType}
                    navigate={navigate}
                    _getPartnerExists={_getPartnerExists}
                    _createErrorMessage={_createErrorMessage}
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages]}
              successMessages={[]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
            <div className="cardAlert"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setPartner}
              dataList={_partnerList}
              showListModal={showPartnerList}
              setShowModal={setShowPartnerList}
              title="List Of Partners"
              columns={[
                { field: "id", headerName: "Customer ID", width: 250 },
                { field: "partnerTypeId", headerName: "Type", width: 250 },
                { field: "name", headerName: "Name", width: 350 },
                { field: "city", headerName: "City", width: 200 },
              ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnerOverview;
