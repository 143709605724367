import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../../../hooks/useFetch";
import { useStation } from "../../../../hooks/UseStation";
import { useMessage } from "../../../../hooks/UseMessage";
import {
  mainArray,
  destinationArray,
  carrierArray,
  crudeArray,
} from "./HeaderFields";
import { LoadingContext } from "../../Context";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import SimpleGrid from "../../../../CommonComponents/Grid/SimpleGrid/SimpleGrid";
import { itemGridFields } from "./ItemGridFields";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "../../../../CommonComponents/Card";
import { useUser } from "../../../../hooks/useUser";
function ShipDocRefOrder() {
  const navigate = useNavigate();
  const params = useParams();
  const [_user] = useUser();
  const orderId = params.orderId;
  const fieldProcessId = params.fieldProcessId;

  const [stopProcessing, setStopProcessing] = useState(false);

  const confModalTitle = useRef(false);
  const confModalBody = useRef(false);
  const buttonUrl = useRef(false);
  const [show, setShow] = useState(false);
  const [serviceError, setServiceError] = useState([]);
  const [serviceSuccess, setServiceSuccess] = useState([]);
  const handleClose = () => setShow(false);
  const handleYes = () => {
    setShow(false);
    navigate(buttonUrl.current);
  };
  const handleShow = (title, body, url) => {
    confModalTitle.current = title;
    confModalBody.current = body;
    buttonUrl.current = url;
    setShow(true);
  };

  const [
    _getShipDocOrderStart,
    _shipDocData,
    ,
    _errorShipDoc,
    _setErrorShipDoc,
    ,
    ,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("OrderLoadingStart"), { method: "POST" }, true);

  const [_stationInfo] = useStation();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
  ] = useMessage();

  useEffect(() => {
    if (Object.keys(_stationInfo).length === 0) {
      return;
    }

    if (!_stationInfo.locationId) {
      return;
    }

    _getShipDocOrderStart(
      {
        data: {
          Id: orderId,
          locationId: _stationInfo.locationId,
          stationId: _stationInfo.id,
          FieldProcessId: fieldProcessId,
          shipDocType: "BOL",
        },
      },
      ({ data: shipDocInfo }) => {
        if (!shipDocInfo) {
          setStopProcessing(true);
        }
      }
    );
  }, [_stationInfo]);
  const [
    _printShipDoc,
    ,
    ,
    _errorPrintShipDoc,
    _setErrorPrintShipDoc,
    _successPrintShipDoc,
    _setSuccessPrintShipDoc,
    _loadingPrintShipDoc,
  ] = useFetch(GetFullURL("PrintShippingDoc"), { method: "POST" });
  const [
    _printShipDocERP,
    ,
    ,
    _errorPrintShipDocERP,
    _setErrorPrintShipDocERP,
    _successPrintShipDocERP,
    _setSuccessPrintShipDocERP,
    _loadingPrintShipDocERP,
  ] = useFetch(GetFullURL("PrintShipDocERP"), { method: "POST" });
  const [
    _printShipDocLocalService,
    ,
    ,
    _errorPrintShipDocLocalService,
    _setErrorPrintShipDocLocalService,
    _successPrintShipDocLocalService,
    _setSuccessPrintShipDocLocalService,
    _loadingPrintShipDocLocalService,
  ] = useFetch(GetServiceFullURL("PrintPDFNewLOAD"), {
    method: "POST",
    headers: {},
  });
  const mergedFieldData = (field, dataSource) => {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    //Adding name2 becuase glendale asked for it 7/14/22
    if (_shipDocData?.shiptoId) {
      const result = _shipDocData.partnersInfoComm.find(
        ({ id }) => id === _shipDocData.shiptoId
      );
      dataSource = { ...dataSource, shiptoName2: result.name2 };
    }

    if (_shipDocData?.shipDocDate) {
      var DateTimeNew = new Date(Date.parse(_shipDocData?.shipDocDate));
      var DateTimeFinal = DateTimeNew.toLocaleDateString("en-US", options);
      dataSource = { ...dataSource, shipDocDateFormatted: DateTimeFinal };
    }

    return { ...field, dataSource };
  };

  let destinationFields;
  const mainFields = mainArray();
  destinationFields = destinationArray();
  const carrierFields = carrierArray();
  const itemFields = itemGridFields();

  if (fieldProcessId === "SAP_C_ORDER") {
    destinationFields = crudeArray();
  } else {
    destinationFields = destinationArray();
  }

  const contextState = {
    _shipDocData,
  };

  const operationLoading =
    _loadingShipDoc ||
    _loadingPrintShipDoc ||
    _loadingPrintShipDocLocalService ||
    _loadingPrintShipDocERP;
  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        setServiceSuccess([]);
        _setSuccessPrintShipDocLocalService([]);
        break;
      case "error":
        _setErrorShipDoc([]);
        _setErorMessages([]);
        setServiceError([]);
        _setErrorPrintShipDoc([]);
        _setErrorPrintShipDocLocalService([]);
        _setSuccessPrintShipDocERP([]);
        _setErrorPrintShipDocERP([]);

        break;
      case "warning":
      default:
        break;
    }
  }
  function handleButtonClickGrid() {}

  function clickStationOperation(item) {
    let urlattribute1 = "";
    let urlattribute2 = "";
    let urlattribute3 = "";

    if (item.urlAttribute1) {
      try {
        let attribute1 = eval(item.urlAttribute1);
        urlattribute1 = attribute1;
      } catch (error) {
        urlattribute1 = item.urlAttribute1;
      }
    }

    if (item.urlAttribute2) {
      try {
        let attribute2 = eval(item.urlAttribute2);
        urlattribute2 = attribute2;
      } catch (error) {
        urlattribute2 = item.urlAttribute2;
      }
    }

    if (item.urlAttribute3) {
      try {
        let attribute3 = eval(item.urlAttribute3);
        urlattribute3 = attribute3;
      } catch (error) {
        urlattribute3 = item.urlAttribute3;
      }
    }
    if (item.stopRepeatCompletedStep === true && item.done === true) {
      _createErrorMessage("105", item.name);
      return;
    }
    const navigationUrl = `${item.url}/${urlattribute1}/${urlattribute2}/${urlattribute3}`;

    switch (item.fieldOperationId) {
      case "CHECKIN":
        if (_shipDocData.checkedStatus == "X") {
          handleShow("Check-in", "Re Checkin", navigationUrl);
        } else {
          navigate(navigationUrl);
        }

        break;
      case "INWEIGHT":
        if (_shipDocData.checkedStatus === "") {
          _createErrorMessage("60");
          return;
        }
        if (_shipDocData.inWeightStatus == "X") {
          handleShow("In Weight", "Re Take In Weight", navigationUrl);
        } else {
          navigate(navigationUrl);
        }

        break;
      case "OUTWEIGHT":
        if (_shipDocData.checkedStatus === "") {
          _createErrorMessage("60");
          return;
        }

        if (_shipDocData.inWeightStatus === "") {
          _createErrorMessage("62");
          return;
        }

        if (_shipDocData.outWeightStatus == "X") {
          handleShow("Out Weight", "Re Take Out Weight", navigationUrl);
        } else {
          navigate(navigationUrl);
        }

        break;
      case "METER_LOADINGV1":
        if (_shipDocData.checkedStatus === "") {
          _createErrorMessage("60");
          return;
        }

        if (_shipDocData.inWeightStatus === "") {
          _createErrorMessage("61");
          return;
        }
        if (_shipDocData.loadStatus == "X") {
          handleShow("Mater Loading", "Re Do Meter Loading", navigationUrl);
        } else {
          navigate(navigationUrl);
        }

        break;
      case "SCALE_LOADINGV1":
        if (_shipDocData.checkedStatus === "") {
          _createErrorMessage("60");
          return;
        }
        if (_shipDocData.loadStatus == "X") {
          handleShow("Scale Loading", "Re Do Scale Loading", navigationUrl);
        } else {
          navigate(navigationUrl);
        }
        break;
      case "SIGNATURE":
        if (_shipDocData.checkedStatus === "") {
          _createErrorMessage("60");
          return;
        }
        if (_shipDocData.inWeightStatus === "") {
          _createErrorMessage("61");
          return;
        }
        if (_shipDocData.outWeightStatus === "") {
          _createErrorMessage("62");
          return;
        }
        if (_shipDocData.signatureStatus == "X") {
          handleShow("Sign BOL", "Re Sign BOL", navigationUrl);
        } else {
          navigate(navigationUrl);
        }
        break;
      case "MANUALCOMPLETION":
        if (_shipDocData.checkedStatus === "") {
          _createErrorMessage("60");
          return;
        }

        navigate(navigationUrl);

        break;
      case "PRINT_TLOAD":
        _printShipDoc({ data: { Id: _shipDocData.id } }, (response) => {
          if (!response.success) {
            return;
          }
          _printShipDocLocalService(
            {
              data: {
                ShipDocBytes: response.data,
                ShipDoc: _shipDocData.id,
              },
            },
            (response) => {
              if (response.success) {
                setServiceSuccess((current) => {
                  return [...current, response.message];
                });
              } else {
                setServiceError((current) => {
                  return [...current, response.message];
                });
              }
            }
          );
        });
        break;
      case "PRINT_SAP":
        _printShipDocERP(
          { data: { Id: _shipDocData.id } },
          ({ data: printResultERP }) => {}
        );

        break;

      default:
        break;
    }
  }

  function handleButtonColor(item) {
    switch (item.fieldOperationId) {
      case "CHECKIN":
        if (_shipDocData.checkedStatus == "X") {
          return { ...item, done: true };
        }
        break;
      case "INWEIGHT":
        if (_shipDocData.inWeightStatus == "X") {
          return { ...item, done: true };
        }
        break;

      case "OUTWEIGHT":
        if (_shipDocData.outWeightStatus == "X") {
          return { ...item, done: true };
        }
        break;
      case "SCALE_LOADINGV1":
        if (_shipDocData.loadStatus == "X") {
          return { ...item, done: true };
        }
        break;
      case "METER_LOADINGV1":
        if (_shipDocData.loadStatus == "X") {
          return { ...item, done: true };
        }
        break;
      case "SIGNATURE":
        if (_shipDocData.signatureStatus == "X") {
          return { ...item, done: true };
        }
        break;

      default:
        return { ...item, done: false };
    }
    return { ...item, done: false };
  }
  const handleButtonDisable = (item) => {
    //if the stop processing flag is set, then disable all buttons
    if (stopProcessing) {
      return true;
    }

    if (item.fieldOperationId === "MANUALCOMPLETION") {
      debugger;
      let superAdminRole = _user.roles.includes("SuperAdmin");
      let terminalAdminRole = _user.roles.includes("TerminalAdmin");
      let adminRole = _user.roles.includes("Admin");
      if (superAdminRole || terminalAdminRole || adminRole) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <div className="container-fluid">
      <LoadingContext.Provider value={contextState}>
        {operationLoading && <LoadingScreen />}
        <div
          className="d-flex flex-row justify-content-start"
          style={{ gap: 10 }}
        >
          <div>
            <Card
              cardHeaderProps={{
                headerLabel: "Main",
              }}
              cardBody={mainFields?.map((field, index) => (
                <FieldsReducer
                  field={mergedFieldData(field, _shipDocData)}
                  key={index}
                />
              ))}
              style={{ width: "400px" }}
            />
          </div>

          <div>
            <Card
              cardHeaderProps={{
                headerLabel: "Carrier Data",
              }}
              cardBody={carrierFields?.map((field, index) => (
                <FieldsReducer
                  field={mergedFieldData(field, _shipDocData)}
                  key={index}
                />
              ))}
              style={{ width: "400px" }}
            />
          </div>
          <div>
            <Card
              cardHeaderProps={{
                headerLabel: "Other",
              }}
              cardBody={destinationFields?.map((field, index) => (
                <FieldsReducer
                  field={mergedFieldData(field, _shipDocData)}
                  key={index}
                />
              ))}
              style={{ width: "500px" }}
            />
          </div>
        </div>

        <SimpleGrid
          fields={itemFields}
          itemData={_shipDocData.shippingDocItemsComm ?? []}
          handleButtonClickGrid={handleButtonClickGrid}
          ActionColumn={false}
        />

        <div className="row">
          <div className="col-12">
            <div style={{ display: "flex", gap: 10 }}>
              {_stationInfo?.stationOperationComm?.map((item, index) => {
                let newItem = handleButtonColor(item);
                if (item.fieldProcessId === fieldProcessId) {
                  return (
                    <Button
                      style={{
                        color: "black",
                        backgroundColor: newItem.done ? "red" : "greenyellow",
                        fontSize: "30px",
                      }}
                      onClick={(e) => {
                        clickStationOperation(newItem);
                      }}
                      key={index}
                      disabled={handleButtonDisable(newItem)}
                    >
                      {item.name}
                    </Button>
                  );
                }
              })}
            </div>
          </div>
        </div>

        <MessageLog
          errorMessages={[
            ..._errorShipDoc,
            ..._errorMessages,
            ...serviceError,
            ..._errorPrintShipDoc,
            ..._errorPrintShipDocLocalService,
            ..._errorPrintShipDocLocalService,
            ..._errorPrintShipDocERP,
          ]}
          successMessages={[...serviceSuccess]}
          clearMessages={clearMessages}
        ></MessageLog>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Redoing {confModalTitle.current}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you Sure You Want to {confModalBody.current}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleYes}>
              YES
            </Button>
            <Button variant="primary" onClick={handleClose}>
              NO
            </Button>
          </Modal.Footer>
        </Modal>
      </LoadingContext.Provider>
    </div>
  );
}

export default ShipDocRefOrder;
