exports.StationTerminalSetup = [
  {
    id: "id",
    name: "id",
    type: "number",
    value: "",
    label: "Id",
    key: "true",
    displayonly: "true",
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    value: "",
    label: "UniversalDescriptor",
    length: 50,
  },
  {
    id: "stationId",
    name: "stationId",
    type: "text",
    value: "",
    label: "Station Id",
    length: 24,
  },
  {
    id: "fieldProcessId",
    name: "fieldProcessId",
    type: "text",
    value: "",
    label: "Field Process id",
    length: 12,
  },
  {
    id: "partnerId",
    name: "partnerId",
    type: "text",
    value: "",
    label: "Partner Id",
    length: 20,
  },
  {
    id: "orderId",
    name: "orderId",
    type: "text",
    value: "",
    label: "Order Id",
    length: 24,
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
