exports.StationOperation = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    key: "true",
    displayonly: "true",
    length: 50,
  },
  {
    id: "stationId",
    name: "stationId",
    type: "text",
    value: "",
    label: "Station Id",
    key: "true",
    length: 24,
  },
  {
    id: "fieldProcessId",
    name: "fieldProcessId",
    type: "text",
    value: "",
    label: "FieldProcess Id",
    key: "true",
    length: 12,
  },
  {
    id: "fieldOperationSequence",
    name: "fieldOperationSequence",
    type: "number",
    value: "",
    label: "Field Operation Sequence",
    key: "true",
  },
  {
    id: "fieldOperationId",
    name: "fieldOperationId",
    type: "text",
    value: "",
    label: "Field Operation",
    length: 50,
  },
  {
    id: "name",
    name: "name",
    type: "text",
    value: "",
    label: "Name",
    length: 24,
  },
  {
    id: "url",
    name: "url",
    type: "text",
    value: "",
    label: "Url",
    length: 200,
  },
  {
    id: "urlAttribute1",
    name: "urlAttribute1",
    type: "text",
    value: "",
    label: "UrlAttribute1",
    length: 200,
  },
  {
    id: "urlAttribute2",
    name: "urlAttribute2",
    type: "text",
    value: "",
    label: "Url Attribute 1",
    length: 200,
  },
  {
    id: "urlAttribute3",
    name: "urlAttribute3",
    type: "text",
    value: "",
    label: "Url Attribute 3",
    length: 200,
  },
  {
    id: "nextUrl",
    name: "nextUrl",
    type: "text",
    value: "",
    label: "Next Url",
    length: 200,
  },
  {
    id: "nextUrlAttribute1",
    name: "nextUrlAttribute1",
    type: "text",
    value: "",
    label: "Next Attribute 1",
    length: 200,
  },
  {
    id: "nextUrlAttribute2",
    name: "nextUrlAttribute2",
    type: "text",
    value: "",
    label: "Next Attribute 2",
    length: 200,
  },
  {
    id: "nextUrlAttribute3",
    name: "nextUrlAttribute3",
    type: "text",
    value: "",
    label: "Next Attribute 3",
    length: 200,
  },
  {
    id: "shipDocStatusId",
    name: "shipDocStatusId",
    type: "text",
    value: "",
    label: "shipDoc Status",
  },
  {
    id: "signatureReady",
    name: "signatureReady",
    type: "boolean",
    value: "",
    label: "Signature Ready",
  },
  {
    id: "terminalActivityinProcess",
    name: "terminalActivityinProcess",
    type: "boolean",
    value: "",
    label: "Terminal Activity in Process",
  },
  {
    id: "loadUnloadinProcess",
    name: "loadUnloadinProcess",
    type: "boolean",
    value: "",
    label: "LoadUn load inProcess",
  },
  {
    id: "stopRepeatCompletedStep",
    name: "stopRepeatCompletedStep",
    type: "boolean",
    value: "",
    label: "Stop Repeat Completed Step",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
