export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    type: "number",
    label: "Item",
    displayOnly: true,
  },
  {
    id: "higherLevelItem",
    name: "higherLevelItem",
    type: "number",
    label: "Higher Level Item",
  },
  {
    id: "itemCategoryId ",
    name: "itemCategoryId ",
    type: "text",
    label: "Item Cat",
  },
];
export const enhancedDDInputArray = ({ dropDowns, dataSource }) => [
  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    type: "text",
    tag: "enhancedInput",
  },

];
export const quantityArray = ({ dropDowns, dataSource }) => [
  {
    id: "quantity",
    name: "quantity",
    label: "Quantity",
    type: "number",
  },
  {
    id: "unitId",
    name: "unitId",
    label: "Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
];