import React from "react";

export default function GridBody({ fields, data }) {
  return (
    <tbody>
      {data.map((dataRecord, i) => {
        return (
          <tr key={i}>
            {fields.map((field, i1) => {
              return <td key={i1}>{dataRecord[field.id]}</td>;
            })}
          </tr>
        );
      })}
    </tbody>
  );
}
