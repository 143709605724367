export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "outboundNumber",
    name: "outboundNumber",
    type: "number",
    label: "Outbound Number",
    displayOnly: true,
  },
  {
    id: "docNumber",
    name: "docNumber",
    type: "text",
    label: "Document Number",
    displayOnly: true,
  },
];
export const additionalArray = ({ dropDowns, dataSource }) => [
  {
    id: "documentClassId",
    name: "documentClassId",
    type: "text",
    label: "Document Class",
    tag: "select",
    options: dropDowns?.documentClasses || [],
    displayOnly: true,
  },
  {
      id: "outboundTypeId",
      name: "outboundTypeId",
      type: "text",
      label: "Outbound Type",
      tag: "select",
      options: dropDowns?.outboundTypes || [],
    }
];
export const commArray = ({ dropDowns, dataSource }) => [
  {
    id: "email",
    name: "email",
    type: "text",
    label: "Email",
  },
  {
    id: "printerId",
    name: "printerId",
    type: "text",
    label: "Printer",
  },
  {
    id: "faxNumber",
    type: "text",
    name: "faxNumber",
    label: "Fax Number",
  },
];
export const adminArray = ({ dropDowns, dataSource }) => [
  {
    id: "outboundStatusId",
    type: "text",
    name: "outboundStatusId",
    label: "Outbound Status",
    tag: "select",
    options: dropDowns?.outboundStatuses || [],
  },
  {
      id: "completedDT",
      type: "datetime",
      name: "completedDT",
      label: "Completed DT",
    },
    {
      id: "identifier",
      type: "text",
      name: "identifier",
      label: "Identifier",
    },
    {
      id: "identifier1",
      type: "text",
      name: "identifier1",
      label: "Identifier",
    },
];