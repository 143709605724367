import { useState } from "react";
import Axios from "axios";
import toastr from "toastr";

export const useFetch = (url, baseConfig = {}, noToasterMessages) => {
  const [outData, setOutData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState([]);

  const fetchData = async (
    config = { method: "GET" },
    onSuccess = () => {},
    altUrl = "",
    params={}
  ) => {
    //  altUrl ? (usedUrl = altUrl) : (usedUrl = url);

    let x = url;
    let apiData;
    try {
      setLoading(true);
      const { data: response } = await Axios({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        url,
        ...config,
        ...baseConfig,
        params,
      });
      onSuccess(response);
      if (response.data != null) {
        setOutData(response.data);
        apiData = response.data;
      }
      if (response.messages != null) {
        for (const message of response.messages) {
          if (message.messageType === "E") {
            !noToasterMessages && toastr.error(message.messageText);
            setError((current) => {
              return [...current, message.messageText];
            });
          } else {
            !noToasterMessages && toastr.success(message.messageText);
            setSuccess((current) => {
              return [...current, message.messageText];
            });
          }
        }
      }
     
     
    } catch (e) {
      setLoading(false);
      let totalMessage = `Error Calling${url}, ${e.message}`;

      !noToasterMessages && toastr.error(totalMessage);
      setError((current) => {
        return [...current, totalMessage];
      });

      if (e.response == null) {
        !noToasterMessages && toastr.error("No Response from Backend" + url);
        setError((current) => {
          return [...current, "No Response from Backend" + url];
        });
      } else {
        //if the message array is populated then no need to send the individual message,
        //becuase the individual message will be in the array
        let simpleErrors = [];
        if (e.response.data.messages != null) {
          for (const message of e.response.data.messages) {
            !noToasterMessages && toastr.error(message.messageText);
            simpleErrors.push(message.messageText);
          }

          setError((current) => {
            return [...current, ...simpleErrors];
          });
        } else {
          if (e.response.data.message != null) {
            !noToasterMessages && toastr.error(e.response.data.message);
            setError((current) => {
              return [...current, ...e.response.data.message];
            });
          }
        }

        if (e.response.data.errors != null) {
          for (const value of Object.values(e.response.data.errors)) {
            !noToasterMessages && toastr.error(value[0]);
            setError((current) => {
              return [...current, value[0]];
            });
          }
        }
        if (e.response.statusText != null) {
          !noToasterMessages && toastr.error(e.response.statusText);
          setError((current) => {
            return [...current, e.response.statusText];
          });
        }
      }
    }
    setLoading(false);

      return apiData;
 
  };

  return [
    fetchData,
    outData,
    setOutData,
    error,
    setError,
    success,
    setSuccess,
    loading,
  ];
};
