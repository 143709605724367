import React from "react";
export default function GridHeader({ fields, ActionColumn }) {
  return (
    <thead>
      <tr>
        {fields.map((field, i) => {
          return (
            <th key={i} className="GridColumnHeading">
              {field.label}
            </th>
          );
        })}

        {fields.length && ActionColumn > 0 ? (
          <th className="GridColumnHeading">Actions</th>
        ) : null}
      </tr>
    </thead>
  );
}
