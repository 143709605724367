import React from "react";
;


export default function PartnerRelationsGridHeader({fields}) {

      return (
        <thead>
        <tr>
          {/* Create Table Headings */}
          {fields.map((field, i) => {
            return (
              <th key={i} className="GridColumnHeading">
                {field.label}
              </th>
            );
          })}
          {fields.length > 0 ? (
            <th className="GridColumnHeading">Actions</th>
          ) : null}
        </tr>
      </thead>
      );

  }