import React from "react";
import { ountboundFieldsArray } from "./fields";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import { useContext } from "react";
import { PartnerContext } from "../context";
import Card from "src/CommonComponents/Card";
export default function Outbound() {
  const { _partnerData, onChange, operation, dropDowns } =
    useContext(PartnerContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _partnerData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };

  const fieldsState = {
    dataSource: _partnerData,
    dropDowns,
  };

  //Get view fields and assign drop-down values
  let viewFields = ountboundFieldsArray(fieldsState);
  return (
    <div className="row">
      <Card
        cardHeaderProps={{
          headerLabel: "Outbound Info",
        }}
        cardBody={viewFields?.map((field, index) => (
          <FieldsReducer field={mergedFieldData(field)} key={index} />
        ))}
        style={{ width: "25rem" }}
      />
    </div>
  );
}
