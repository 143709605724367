import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../../hooks/useFetch";
import { useMessage } from "../../../../../hooks/UseMessage";
import { GetFullURL } from "../../../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ReactSignatureCard } from "../../../../../Assets/NewIconsForCards/SignatureIcon.svg";
import { ReactComponent as ReactPrinterCard } from "../../../../../Assets/NewIconsForCards/PrinterIcon.svg";
import Card from "../../../../../Home/Components/Card";

export default function SignatureStation(props) {
  let navigate = useNavigate();
  const [usedStation, setUsedStation] = useState();

  const userState = useSelector((state) => state.User.user);

  const language = userState.preferredLanguage;
  const userStation = userState.stationId;

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [
    _getStationData,
    _stationData,
    ,
    _errorStationData,
    _setErrorStationData,
    _successStationData,
    _setSuccessStationData,
    _loadingStationData,
  ] = useFetch(GetFullURL("GetStation"), { method: "POST" });

  const operationLoading = _loadingStationData;
  let storedCookie;

  useEffect(() => {
    let usedStation = "";
    storedCookie = Cookies.get("station");

    if (!userStation) {
      if (!storedCookie) {
        _createErrorMessage("1");
        return;
      }
    }
    usedStation = storedCookie ? storedCookie : userStation;

    setUsedStation(usedStation);
    _getStationData({ data: { stationId: usedStation } });
  }, []);

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessStationData([]);
        break;
      case "error":
        _setErrorStationData([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }

  const Arr = [
    {
      title: "BOL Signature",
      text: "BOL Signature",
      Icon: ReactSignatureCard,
      link: "/TerminalActivity/SignatureReady",
    },
    {
      title: "Print Scale Ticket",
      text: "Print Scale Ticket",
      Icon: ReactPrinterCard,
      link: "/PrintScaleTicket",
    },
    {
      title: "Print BOL",
      text: "Print BOL",
      Icon: ReactPrinterCard,
      link: "/PrintBOL",
    },
  ];

  return (
    <div className="container-fluid ">
      {operationLoading && <LoadingScreen />}
      <div className="mainContainer">
        <div className="overviewCardContainer">
          {Arr.map((record, i) => (
            <Card {...record} key={i} />
          ))}
        </div>
      </div>
      <MessageLog
        errorMessages={[..._errorStationData, ..._errorMessages]}
        successMessages={[..._successStationData]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}
