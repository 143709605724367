import React, { useContext } from "react";
import { VehicleContext } from "../VehicleMain/context";

export default function TopPanel() {
  const { operation, vehicleId } = useContext(VehicleContext);

  var vehicleInfo;
  switch (operation) {
    case "Display":
      vehicleInfo = `${operation} Vehicle: ${vehicleId}`;
      break;
    case "Change":
      vehicleInfo = `${operation} Vehicle ${vehicleId}`;
      break;
    case "Create":
      vehicleInfo = `${operation} New Vehicle`;
      break;
    default:
      vehicleInfo = `Vehicle Info`;
      break;
  }
  return (
    <h2 className="heading" style={{ position: "relative", top: 8 }}>
      {vehicleInfo}
    </h2>
  );
}
