/** @format */

import React, { useContext, useState, useEffect} from "react";
import { mainDataArray, enhancedInputArray, quantityArray,destDDArray,destArray,enhancedDDInputArray } from "./Fields";
import { InventoryDocContext } from "../../Contex";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";
import MaterialField from "../../../../../CommonComponents/EnhancedFields/Material/MaterialField";
import MaterialList from "../../../../../CommonComponents/DropDowns/Material/MaterialList";
import TankField from "../../../../../CommonComponents/EnhancedFields/Tank/TankField";
import TankList from "../../../../../CommonComponents/DropDowns/Tank/TankList";

function Index({
  setShowItemsDetailsModal,
  itemOperation,
  item,
  setItem,
  handleAccept,
}) {
  const {
    _inventoryDocData,
    dropDowns,
    _getMaterialList,
    _materialList,
    _getTankList,
    _tankList,
  } = useContext(InventoryDocContext);

  const [showMaterialList, setShowMaterialList] = useState(false);
  const [showTankList, setShowTankList] = useState(false);
  const [materialFieldName, setMaterialFieldName] = useState();
  const [transferType, setTransferType] = useState(false);


  useEffect(() => {
    if (_inventoryDocData.inventoryDocTypeId === 'Transfer') {
      setTransferType(true);
    }else {
      setTransferType(false);
    }
  }, [_inventoryDocData.inventoryDocTypeId]);

  const onChangeItem = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setItem((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: item,
      onChange: onChangeItem,
    };
    switch (itemOperation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: item,
    dropDowns,
  };

  const mainFieldsToRender = mainDataArray(fieldsState);
  const quantityFieldsToRender = quantityArray(fieldsState);

  const enhancedFieldsToRender = enhancedInputArray(fieldsState);
  const enhancedDDFieldsToRender = enhancedDDInputArray(fieldsState);

  const destDDFieldsToRender = destDDArray(fieldsState);
  const destArrayFieldsToRender = destArray(fieldsState);
  
  
  var materialField    = enhancedDDFieldsToRender.find(({ id }) => id === "materialId");
  var tankField        = enhancedDDFieldsToRender.find(({ id }) => id === "tankId");

  var destMaterialId   = destDDFieldsToRender.find(({id}) => id === "destinationMaterialId");
  var destTankId       = destDDFieldsToRender.find(({id}) => id === "destinationTankId");


  return (
    <div className="row">
      <div className="col-lg-3 col-sm-12 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Main Data</h2>
          </div>
          <div className="cardBody">
            {mainFieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Material Data </h2>
          </div>
          <div className="cardBody">
            <MaterialField
              field={mergedFieldData(materialField)}
              setShowMaterialList={setShowMaterialList}
              _getMaterialList={_getMaterialList}
              setMaterialFieldName={setMaterialFieldName}
            />
            <TankField
              field={mergedFieldData(tankField)}
              setShowList={setShowTankList}
              _getTankList={_getTankList}
            />
             <div className="cardBody">
            {enhancedFieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Quantities </h2>
          </div>
          <div className="cardBody">
            {quantityFieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      {transferType ? (
        <div className="col-lg-3 col-sm-6 col-xs-12">
      <div className="card" style={{ minHeight: 450 }}>
        <div className="cardHeader">
          <h2>Destination</h2>
        </div>
        <MaterialField
            field={mergedFieldData(destMaterialId)}
            setShowMaterialList={setShowMaterialList}
            _getMaterialList={_getMaterialList}
          />
          <TankField
            field={mergedFieldData(destTankId)}
            setShowList={setShowTankList}
            _getTankList={_getTankList}
          />
        <div className="cardBody">
          {destArrayFieldsToRender.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
        </div>
      </div>
      </div>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
        className="my-4"
      >
        <Button
          className="me-4"
          style={{ background: "#DA1212", borderColor: "#DA1212" }}
          onClick={() => {
            setShowItemsDetailsModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          active="true"
          onClick={() => {
            handleAccept();
            setShowItemsDetailsModal(false);
          }}
        >
          Accept
        </Button>
      </div>
      <MaterialList
        _materialList={_materialList}
        showMaterialList={showMaterialList}
        setShowMaterialList={setShowMaterialList}
        onChange={onChangeItem}
      />
      <TankList
        _tankList={_tankList}
        showTankList={showTankList}
        setShowTankList={setShowTankList}
        onChange={onChangeItem}
      />
    </div>
  );
}

export default Index;
