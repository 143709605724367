import React, { useState, useEffect, useRef } from "react";
import { DateTimePicker } from "react-rainbow-components";
import Select from "react-select";
import Keybaord from "../keybaords/keybaordV1/index";

import ClickAwayListener from '@mui/base/ClickAwayListener';
import { ReactComponent as KeyboardIcon } from "../../Assets/LoadingSvgs/KeyboardIcon.svg";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import './index.scss';

export const TextInput = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  length,
  itemKey,
  required,
}) => {
  //Add by Ktelfah 4/28/2022 in order to handle cases when the a text field
  //is used to handle bool from backend
  function getValue() {
    if (typeof dataSource?.[id] === "boolean") {
      if (dataSource?.[id]) {
        return "true";
      } else {
        return "false";
      }
    }
    return dataSource?.[id];
  }

  function getBorderColor() {
    if (required) {
      let fieldValue = getValue();

      if (fieldValue) {
        return "green";
      } else {
        return "red";
      }
    }
  }
  function getBorderWidth() {
    if (required) {
      let fieldValue = getValue();
      if (fieldValue) {
        return "2px";
      } else {
        return "4px";
      }
    }
    return null;
  }

  return (
    <div className="mb-3">
      {!noLable ? (
        <label
          htmlFor={id}
          style={{
            fontWeight: 600,
          }}
        >
          {label}:
        </label>
      ) : null}

      <input
        onFocus={(e) => {
          e.stopPropagation();
        }}
        className="form-control"
        value={getValue() ?? ""}
        onChange={onChange}
        data-type={type}
        type={type}
        data-item={dataSource?.["itemNumber"] || dataSource?.[itemKey]}
        name={name}
        disabled={disabled}
        maxLength={length}
        size="50"
        style={{
          borderRadius: 8,
          padding: 8,
          height: 50,
          fontSize: 20,
          borderColor: getBorderColor(),
          borderWidth: getBorderWidth(),
        }}
      />
    </div>
  );
};
export const Checkbox = ({
  onChange = () => {},
  dataSource = {},
  id,
  label,
  type,
  name,
  disabled,
  noLable,
  itemKey,
}) => {
  return (
    <div className="form-check">
      {!noLable ? (
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      ) : null}

      <input
        style={{ height: "20px", width: "20px" }}
        className="form-check-input"
        checked={
          dataSource?.[id] === "true" ||
          dataSource?.[id] === true ||
          dataSource?.[id] === "True"
        }
        value={dataSource?.[id]}
        onChange={onChange}
        data-type={type}
        type={type}
        data-item={dataSource?.["itemNumber"]}
        name={name}
        disabled={disabled}
      />
    </div>
  );
};
export const DateTimeInput = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  itemKey,
}) => {
  return (
    <div className="mb-3 dateInput">
      {noLable !== true ? <label htmlFor={id}>{label}:</label> : null}

      <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto">
        <DateTimePicker
          value={dataSource?.[id]}
          minDate={new Date(2001, 1, 1)}
          maxDate={new Date(2090, 1, 1)}
          disabled={disabled}
          onChange={(v) => {
            //the formate produced by picker ie v is cuase the backend to save it (convert to) UTC time, by doing this
            //manipulation the backend will save the local time/date without converting it to UTC
            let date = moment(v).format("YYYY-MM-DD");
            let time = moment(v).format("hh:mm:ss");
            let dt = `${date}T${time}`;
            onChange({
              target: {
                value: dt,
                name,
                type: "datetime",
                item: dataSource?.[itemKey],
              },
            });
          }}
          name={name}
        />
      </div>
    </div>
  );
};
export const SelectInput = ({
  id,
  label,
  name,
  options = [],
  dataSource = {},
  onChange = () => {},
  type,
  noLable,
  disabled,
  itemKey,
}) => {
  return (
    <div className="mb-3">
      {noLable != true ? (
        <label
          htmlFor={id}
          style={{
            fontWeight: 600,
          }}
        >
          {label}
        </label>
      ) : null}

      <select
        className="form-control"
        onChange={onChange}
        value={dataSource?.[id] || "select"}
        type={type}
        data-item={dataSource?.["itemNumber"] || dataSource?.[itemKey]}
        data-type={type}
        disabled={disabled}
        name={name}
        style={{
          borderRadius: 8,
          height: 50,
        }}
      >
        <option value="select">--- SELECT ---</option>
        {options.map((item, i) => (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};
const Autocomplete = ({ label, name, id, dataSource, onChange, options }) => {
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    if (dataSource && options.length) {
      let newValue = options.find((option) => option.value == dataSource[id]);
      setValue(newValue ? newValue : { label: null, value: null });
    }
  }, [dataSource, options]);

  const handleChange = (newSelection) => {
    let newValue =
      typeof newSelection === "object"
        ? newSelection.value?.toString()
        : newSelection?.toString();

    //Find the selected item in options
    let _newValue_ = options.find(
      (option) => option.value == newValue?.toString()
    );

    setValue(_newValue_ ? _newValue_ : { label: null, value: null });
    onChange({
      target: {
        name: id,
        value: newValue,
      },
    });
    setMenuIsOpen(false);
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };
  const handleClickAway = () => {
    setMenuIsOpen(false);
    setSearchValue("");
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        style={{ marginBottom: 8 }}
        className="position-relative keyboard-whole-wrapper"
      >
        <label
          htmlFor={id}
          style={{
            fontWeight: 600,
          }}
        >
          {label}
        </label>
        <Select
          inputValue={searchValue}
          onInputChange={handleSearchChange}
          name={name}
          onFocus={(e) => {
            e.stopPropagation();
            setMenuIsOpen(true);
          }}
          isSearchable={true}
          value={value}
          onChange={handleChange}
          options={options || []}
          className="basic-multi-select"
          classNamePrefix="select"
          menuIsOpen={menuIsOpen}
        />
        {menuIsOpen && (
          <Keybaord inputValue={searchValue} setInputValue={setSearchValue} />
        )}
      </div>
    </ClickAwayListener>
  );
};
const AutocompletewithKeyboardicon = ({
  label,
  name,
  id,
  dataSource,
  onChange,
  options,
}) => {
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    if (dataSource && options.length) {
      let newValue = options.find((option) => option.value == dataSource[id]);
      setValue(newValue ? newValue : { label: null, value: null });
    }
  }, [dataSource, options]);

  const handleChange = (newSelection) => {
    let newValue =
      typeof newSelection === "object"
        ? newSelection.value?.toString()
        : newSelection?.toString();

    //Find the selected item in options
    let _newValue_ = options.find(
      (option) => option.value == newValue?.toString()
    );

    setValue(_newValue_ ? _newValue_ : { label: null, value: null });
    onChange({
      target: {
        name: id,
        value: newValue,
      },
    });
    setMenuIsOpen(false);
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };
  const handleClickAway = () => {
    setMenuIsOpen(false);
    setSearchValue("");
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        style={{ marginBottom: 8 }}
        className="position-relative keyboard-whole-wrapper"
      >
        <label
          htmlFor={id}
          style={{
            fontWeight: 600,
          }}
        >
          {label}
        </label>
        <Select
          inputValue={searchValue}
          onInputChange={handleSearchChange}
          name={name}
          onFocus={(e) => {
            e.stopPropagation();
            setMenuIsOpen(true);
          }}
          isSearchable={true}
          value={value}
          onChange={handleChange}
          options={options || []}
          className="basic-multi-select"
          classNamePrefix="select"
          menuIsOpen={menuIsOpen}
        />

        {menuIsOpen && (
          <Keybaord inputValue={searchValue} setInputValue={setSearchValue} />
        )}
      </div>
    </ClickAwayListener>
  );
};

const InputNumpad = ({
  label,
  id,
  dataSource,
  onChange,
  placeHolder,
  type,
}) => {
  const [toggleNumpad, setToggleNumpad] = useState(false);
  const [numbers, setNumbers] = useState([]);

  const handleClose = () => {
    setToggleNumpad(false);
    handleChange(numbers);
  };

  let currentValue = dataSource.temperature || 0;
  let newValue = +numbers.join("");

  if (newValue > 99999) {
    newValue = 0;
    setNumbers([]);
  }

  const onFocus = () => {
    setToggleNumpad(true);
  };

  const handleChange = () => {
    onChange({
      target: {
        name: id,
        value: newValue,
        type,
      },
    });
  };

  const Input = () => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleClose();
      }
      if (event.key === "Escape") {
        handleClose();
      }
      if (event.key === "Backspace") {
        const copyArr = [...numbers];
        copyArr.pop();
        setNumbers(copyArr);
      }

      if (
        (event.key === "1" ||
          event.key === "2" ||
          event.key === "3" ||
          event.key === "4" ||
          event.key === "5" ||
          event.key === "6" ||
          event.key === "7" ||
          event.key === "8" ||
          event.key === "9" ||
          event.key === "0") &&
        event != "e"
      ) {
        let num = parseInt(event.key);
        if (!isNaN(num)) {
          setNumbers((prevNums) => [...prevNums, num]);
        }
      }
    };

    return (
      <input
        onKeyDown={handleKeyDown}
        type={"number"}
        className="numpadInputModal"
        value={newValue || null}
        autoFocus
        placeholder={placeHolder}
      />
    );
  };

  return (
    <div className="numpad">
      <label>
        <h5>{label}:</h5>
      </label>
      <input
        type={"number"}
        className="numpadInput"
        value={currentValue}
        onChange={handleChange}
        placeholder={placeHolder}
        onClick={onFocus}
      />
      {toggleNumpad ? (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={toggleNumpad}
          onHide={handleChange}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {label} : <Input />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="container">
              <div class="row justify-content-evenly">
                <button
                  className="numBtn"
                  onClick={() => {
                    setNumbers((prevNums) => [...prevNums, 1]);
                  }}
                >
                  1
                </button>

                <button
                  className="numBtn"
                  onClick={() => {
                    setNumbers((prevNums) => [...prevNums, 2]);
                  }}
                >
                  2
                </button>

                <button
                  className="numBtn"
                  onClick={() => {
                    setNumbers((prevNums) => [...prevNums, 3]);
                  }}
                >
                  3
                </button>
              </div>

              <div class="row justify-content-evenly">
                <button
                  className="numBtn"
                  onClick={() => {
                    setNumbers((prevNums) => [...prevNums, 4]);
                  }}
                >
                  4
                </button>

                <button
                  className="numBtn"
                  onClick={() => {
                    setNumbers((prevNums) => [...prevNums, 5]);
                  }}
                >
                  5
                </button>

                <button
                  className="numBtn"
                  onClick={() => {
                    setNumbers((prevNums) => [...prevNums, 6]);
                  }}
                >
                  6
                </button>
              </div>
              <div class="row justify-content-evenly">
                <button
                  className="numBtn"
                  onClick={() => {
                    setNumbers((prevNums) => [...prevNums, 7]);
                  }}
                >
                  7
                </button>

                <button
                  className="numBtn"
                  onClick={() => {
                    setNumbers((prevNums) => [...prevNums, 8]);
                  }}
                >
                  8
                </button>

                <button
                  className="numBtn"
                  onClick={() => {
                    setNumbers((prevNums) => [...prevNums, 9]);
                  }}
                >
                  9
                </button>
              </div>
              <div class="row justify-content-evenly">
                <button
                  className="numBtnClear"
                  onClick={() => {
                    setNumbers([]);
                  }}
                >
                  C
                </button>

                <button
                  className="numBtn"
                  onClick={() => {
                    setNumbers((prevNums) => [...prevNums, 0]);
                  }}
                >
                  0
                </button>

                <button
                  className="numBtnCorrect"
                  onClick={() => {
                    setNumbers((prevNums) => prevNums.slice(0, -1));
                  }}
                >
                  <p>
                    <i class="bi bi-arrow-left" style={{ fontSize: 50 }}></i>
                  </p>
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="numpadBtnOk" onClick={handleClose}>
              OK
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

const InputKeyboard = ({
  label,
  id,
  dataSource,
  onChange,
  placeHolder,
  type,
  hideKeyboard,
  defaultKeyboardType,
  handleOnKeyboardFocus = () => {},
  required,
}) => {
  const [showKeyboard, setShowkeyboard] = useState(false);

  let keyBoardType;
  if (defaultKeyboardType) {
    keyBoardType = defaultKeyboardType;
  } else {
    keyBoardType = type;
  }
  //Current Value from main state
  let currentValue = dataSource[id] || "";
  const handleChange = (e) => {
    let newValue = e.target.value;

    onChange({
      target: {
        name: id,
        value: newValue,
        type,
      },
    });
  };

  const handleKeyboardInput = (keyBoardValue) => {
    onChange({
      target: {
        name: id,
        value: keyBoardValue,
        type,
      },
    });
  };

  const handleClickAway = () => {
    if (hideKeyboard != "true" || hideKeyboard != "True") {
      setShowkeyboard(false);
      handleOnKeyboardFocus(id, "onBlur");
    }
  };
  const onFocus = () => {
    if (hideKeyboard != "true" || hideKeyboard != "True") {
      setShowkeyboard(true);
      handleOnKeyboardFocus(id, "onFocus");
    }
  };

  const handleOKButton = () => {
    handleClickAway();
  };
  function getValue() {
    if (typeof dataSource?.[id] === "boolean") {
      if (dataSource?.[id]) {
        return "true";
      } else {
        return "false";
      }
    }
    return dataSource?.[id];
  }

  function getBorderColor() {
    if (required) {
      let fieldValue = getValue();

      if (fieldValue) {
        return "green";
      } else {
        return "red";
      }
    }
  }
  function getBorderWidth() {
    if (required) {
      let fieldValue = getValue();
      if (fieldValue) {
        return "2px";
      } else {
        return "4px";
      }
    }
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        style={{ marginBottom: 8 }}
        className="position-relative keyboard-whole-wrapper"
      >
        <label htmlFor={id} style={{ fontWeight: 600 }}>
          {label}:
        </label>
        <input
          onClick={onFocus}
          type="text"
          className="form-control mb-3"
          value={currentValue}
          onChange={handleChange}
          placeholder={placeHolder}
          style={{
            borderRadius: 10,
            outline: "none !important",
            padding: "0px 16px",
            fontSize: 30,
            borderColor: getBorderColor(),
            borderWidth: getBorderWidth(),
            color: "black",
          }}
        />
        {showKeyboard && (
          <Keybaord
            setInputValue={handleKeyboardInput}
            inputValue={currentValue}
            type={type}
            defaultKeyboard={keyBoardType}
            handleOKButton={handleOKButton}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export const FieldsReducer = ({ field, onFocus = false }) => {
  switch (field.tag) {
    case "select":
      return <SelectInput {...field} onFocus={onFocus} />;
    case "enhancedInput":
      return;
    case "inputKeyboard":
      return <InputKeyboard {...field} />;
    case "autoCompletewithkeyboardicon":
      return <AutocompletewithKeyboardicon {...field} onFocus={onFocus} />;
    case "autoComplete":
      return <Autocomplete {...field} onFocus={onFocus} />;
    case "checkbox":
      return <Checkbox {...field} onFocus={onFocus} />;
    case "numpad":
      return <InputNumpad {...field} />;
    default:
      if (field.type === "datetime") {
        return <DateTimeInput {...field} onFocus={onFocus} />;
      } else {
        return <TextInput {...field} onFocus={onFocus} />;
      }
  }
};
