import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Features/UserSlice";
import stationReducer from "./Features/StationSlice";
import dropDownReducer from "./Features/DropDownsSlice";
export const store = configureStore({
  reducer: {
    userData: userReducer,
    stationData: stationReducer,
    ddData: dropDownReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});
