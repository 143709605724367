import React, { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch";
import { useMessage } from "../../../../hooks/UseMessage";
import { GetFullURL } from "../../../../CommonComponents/GlobalConfFiles/URLs";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import { useNavigate } from "react-router-dom";
import ShipDocField from "../../../../CommonComponents/EnhancedFields/ShipDoc/ShipDocField";
import ShipDocList from "../../../../CommonComponents/DropDowns/ShipDoc/ShipDocList";
import { mainArray } from "./Fields";
import Button from "react-bootstrap/Button";
import "./css.scss";

export default function SendSingleShipDocERP(props) {
  let navigate = useNavigate();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [shipDoc, setShipDoc] = useState();
  const [showShipDocList, setShowShipDocList] = useState(false);
  const [shipDocFieldName, setShipDocFieldName] = useState();

  const [
    _getShippingDocList,
    _shippingDocListData,
    _setShippingDocListData,
    _errorGetShippingDocList,
    _setErrorGetShippingDocList,
    _successGetShippingDocList,
    _setSuccessGetShippingDocList,
    _loadingtShippingDocList,
  ] = useFetch(GetFullURL("ShipDocsERPReady"), { method: "POST" }, true);

  const [
    _sendShipDocERP,
    _sendShipDocERPData,
    _setSendShipDocERPData,
    _errorSendShipDocERP,
    _setErrorSendShipDocERP,
    _successSendShipDocERP,
    _setSuccessSendShipDocERP,
    _loadingSendShipDocERP,
  ] = useFetch(GetFullURL("SendShipDocERP"), { method: "POST" }, true);

  const operationLoading = _loadingtShippingDocList || _loadingSendShipDocERP;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSendShipDocERP([]);
        _setSuccessGetShippingDocList([]);

        break;
      case "error":
        _setErorMessages([]);
        _setErrorSendShipDocERP([]);
        _setErrorGetShippingDocList([]);

        break;
      default:
        break;
    }
  }

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setShipDoc((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const fieldsState = {
    dropDowns: [],
  };

  const mainFieldsToRender = mainArray(fieldsState);
  var shipDocField = mainFieldsToRender.find(({ id }) => id === "id");

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: shipDoc, onChange };

    return newFieldObject;
  };
  //gsc - Generic Send Component

  return (
    <div className="container-fluid">
      <div className="gscMainContainer">
        {operationLoading && <LoadingScreen />}
        <div className="gscMainDiv">
          <h1>Send Single BOL to SAP/ERP</h1>
          <div className="gscDropDownField">
            <ShipDocField
              field={mergedFieldData(shipDocField)}
              setShowShipDocList={setShowShipDocList}
              setShipDocFieldName={setShipDocFieldName}
              _getShipDocList={_getShippingDocList}
            />
          </div>

          <div className="gscBtnDiv">
            <Button
              className="gscBtnProceed"
              onClick={() => {
                _setErrorSendShipDocERP([]);

                if (
                  shipDoc.id === null ||
                  shipDoc.id === undefined ||
                  shipDoc.id === ""
                ) {
                  _createErrorMessage("87");
                  return;
                } else {
                  _sendShipDocERP(
                    {
                      data: {
                        id: shipDoc.id,
                      },
                    },
                    ({}) => {}
                  );
                }
              }}
            >
              Send
            </Button>
            <Button
              className="gscBtnCancel"
              onClick={() => navigate("/SpecialProcesses")}
            >
              Cancel
            </Button>
          </div>
          <ShipDocList
            _shipDocList={_shippingDocListData}
            showShipDocList={showShipDocList}
            setShowShipDocList={setShowShipDocList}
            shipDocFieldName={shipDocFieldName}
            onChange={onChange}
          />
        </div>
      </div>
      <MessageLog
        errorMessages={[
          ..._errorGetShippingDocList,
          ..._errorMessages,
          ..._errorSendShipDocERP,
        ]}
        successMessages={[..._successGetShippingDocList]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}
