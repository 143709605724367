import React, { useContext } from "react";
import { LeaseContext } from "../LeaseMain/context";

export default function TopPanel() {
  const { operation, leaseId } = useContext(LeaseContext);

  var leaseInfo;
  switch (operation) {
    case "Display":
      leaseInfo = `${operation} Lease: ${leaseId}`;
      break;
    case "Change":
      leaseInfo = `${operation} Lease ${leaseId}`;
      break;
    case "Create":
      leaseInfo = `${operation} New Lease`;
      break;
    default:
      leaseInfo = `Lease Info`;
      break;
  }
  return (
    <h2 className="heading" style={{ position: "relative", top: 8 }}>
      {leaseInfo}
    </h2>
  );
}
