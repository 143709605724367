import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { issueMessage } from "../utils/issueMessage";

export const useMessage = (currentFieldOperarionId) => {
  const [errorMessages, setErorMessages] = useState([]);
  const [warningMessages, setWarningMessages] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const loginLanguage = useRef();
  const userInfo = useSelector((state) => state.userData.User.user);

  //Add this language default becuase at the early stage of app initializing, like in login the
  //user state has not been set yet, hence defaulting
  //the language to EN, July 2nd 22. Ktelfah
  if (userInfo?.preferredLanguage) {
    loginLanguage.current = userInfo.preferredLanguage;
  } else {
    loginLanguage.current = "EN";
  }

  const createErrorMessage = (messageNumber, var1, var2, var3, var4) => {
    let { messageText } = issueMessage(
      loginLanguage.current,
      messageNumber,
      var1,
      var2,
      var3,
      var4
    );

    setErorMessages((current) => [...current, messageText]);
  };
  const createSuccessMessage = (messageNumber, var1, var2, var3, var4) => {
    let { messageText } = issueMessage(
      loginLanguage.current,
      messageNumber,
      var1,
      var2,
      var3,
      var4
    );

    setSuccessMessages((current) => [...current, messageText]);
  };
  const createWarningMessage = (messageNumber) => {
    let { messageText } = issueMessage(loginLanguage.current, messageNumber);
    setWarningMessages((current) => [...current, messageText]);
  };

  return [
    createErrorMessage,
    errorMessages,
    setErorMessages,
    createWarningMessage,
    warningMessages,
    setWarningMessages,
    createSuccessMessage,
    successMessages,
    setSuccessMessages,
  ];
};
