import React, { useContext,useState } from "react";
import { mainDataArray , enhancedInputArray , quantityArray,partnersArray} from "./Fields";
import { LeaseContext } from "../context";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import MaterialField from "../../../../CommonComponents/EnhancedFields/Material/MaterialField";
import MaterialList from "../../../../CommonComponents/DropDowns/Material/MaterialList";
import PartnerField from "../../../../CommonComponents/EnhancedFields/Partner/PartnerField";
import PartnerList from "../../../../CommonComponents/DropDowns/Partner/PartnerList";
import LogisticStationField from "../../../../CommonComponents/EnhancedFields/LogisticStation/LogisticStationField";
import LogisticStationList from "../../../../CommonComponents/DropDowns/LogisticStation/LogisticStationList";


export default function MainTab() {
  const { onChange, operation, dropDowns, _leaseData,_getMaterialList, _materialList,_partnerList,_getPartnerList,_getLogisticStationList,_logisticStationList,} =
    useContext(LeaseContext);

    const [showMaterialList, setShowMaterialList] = useState(false);
    const [item, setItem] = useState({});
    const [partnerFieldName, setPartnerFieldName] = useState();
    const [showList, setShowList] = useState(false);
    const [logisticStationFieldName, setLogisticStationFieldName] = useState();
    const [showLogisticStationList, setShowLogisticStationList] = useState(false);



    const onChangeItem = (e) => {
      const {
        target: { value, name },
      } = e;
      let newValue;
      switch (e.target.type) {
        case "number":
          newValue = Number(value);
          break;
        default:
          newValue = value;
          break;
      }
      const newValueObject = { [name]: newValue };
      setItem((prevState) => {
        return { ...prevState, ...newValueObject };
      });
    };

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _leaseData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _leaseData,
    dropDowns,
  };

  const fieldsToRender = mainDataArray(fieldsState);
  const quantityFieldsToRender = quantityArray(fieldsState);
  const enhancedFieldsToRender = enhancedInputArray(fieldsState);
  var materialField = enhancedFieldsToRender.find(
    ({ id }) => id === "materialId"
  );
  var logisticStationField = enhancedFieldsToRender.find(
    ({ id }) => id === "logisticStationId"
  );
  const partnerFieldsRender = partnersArray(fieldsState);
  var carrierfield = partnerFieldsRender.find(({ id }) => id === "carrierId");


  return (
    <div className="row">
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card">
          <div className="cardHeader">
            <h2>Main Data </h2>
          </div>
          <div className="cardBody">
            {fieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Material Data </h2>
          </div>
          <div className="cardBody">
            <MaterialField
              field={mergedFieldData(materialField)}
              setShowMaterialList={setShowMaterialList}
              _getMaterialList={_getMaterialList}
            />
            <LogisticStationField
              field={mergedFieldData(logisticStationField)}
              setShowLogisticStationList={setShowLogisticStationList}
              _getLogisticStationList={_getLogisticStationList}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Quantities </h2>
          </div>
          <div className="cardBody">
            {quantityFieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 610 }}>
            <div className="cardHeader">
              <h2>Partners</h2>
            </div>
            <div className="cardBody">
              <PartnerField
                field={mergedFieldData(carrierfield)}
                setShowList={setShowList}
                _getPartnerList={_getPartnerList}
                setPartnerFieldName={setPartnerFieldName}
                dropDownApiPartnerType="CARRIER"
              />
            </div>
          </div>
        </div>
      <MaterialList
        _materialList={_materialList}
        showMaterialList={showMaterialList}
        setShowMaterialList={setShowMaterialList}
        onChange={onChangeItem}
      />
       <PartnerList
        _partnerList={_partnerList}
        showPartnerList={showList}
        setShowPartnerList={setShowList}
        partnerFieldName={partnerFieldName}
        onChange={onChange}
      />
         <LogisticStationList
        _logisticStationList={_logisticStationList}
        showLogisticStationList={showLogisticStationList}
        setShowLogisticStationList={setShowLogisticStationList}
        onChange={onChange}
      />
    </div>
  );
}
