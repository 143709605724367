import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import { useMessage } from "../../hooks/UseMessage";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput"
import OverviewButtons from "./Components/OverviewButtons";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../CommonComponents/CircleButton";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import cardlogo from "../../Assets/cardLogo.svg"

function LeaseOverview() {
  let navigate = useNavigate();

  const [lease, setLease] = useState();
  const [showLeaseList, setShowLeaseList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [_getLeaseList, _leaseList, , , , , , _loadingtLeaseList] =
    useFetch(GetFullURL("LeaseList"), { method: "POST" });

  const [_getLeaseExists, , , , , , , _loadingtLeaseExist] = useFetch(
    GetFullURL("LeaseExist"),
    { method: "POST" }
  );

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading = _loadingtLeaseExist || _loadingtLeaseList;
  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../Assets/icon/Lease.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Lease Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setLease}
                    document={lease}
                    placeholder="Lease id here..."
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of Leases`}
                    clickHandler={() => {
                      _getLeaseList({}, () => {
                        setShowLeaseList(true);
                      });
                    }}
                  />
                  <OverviewButtons
                    lease={lease}
                    navigate={navigate}
                    _getLeaseExists={_getLeaseExists}
                    _createErrorMessage={_createErrorMessage}
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages]}
              successMessages={[]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
            <div className="cardAlert">
              <ListModal
                setId={setLease}
                dataList={_leaseList}
                showListModal={showLeaseList}
                setShowModal={setShowLeaseList}
                title="List Of Leases"
                columns={[
                  { field: "id", headerName: "Lease ID", width: 100 },
                  { field: "leaseTypeId", headerName: "Lease Type", width: 250 },
                  { field: "logisticStationId", headerName: "Name", width: 350 },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeaseOverview;
