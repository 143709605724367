var Component = {
  Type: "UI_Config",
  name: "Component Configuration",
  LinksEndPoint: "GetComponentDetail",
  Assignment: "element",
  LinksStructure: "ComponentElement",
};

var User = {
  Type: "System",
  IncludeCommonFields: "false",
};

var Language = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var Message = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var Station = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var StationAssigment = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var FieldDataPoint = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var Unit = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var UnitType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var Country = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var Region = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var IsoUnit = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var MeasurementType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var PlantType = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var Plant = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var MeasurementSource = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var FieldDataPointType = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var ImplementationUse = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var ImplementationType = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var MaterialType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var MaterialGroup = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var UnitConversion = {
  Type: "Master",
  IncludeCommonFields: "false",
};
var PartnerRole = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var PartnerType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var NumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var DocumentCategory = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var DocumentType = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var StatusClass = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var StatusType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var StatusUse = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var Implementation = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var BusinessObjectCategory = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var BusinessObject = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var TankFarm = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var TankGroup = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var TASGlobal = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var Location = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var PartnerLocation = {
  Type: "Master",
  IncludeCommonFields: "false",
};

var ShipDocEvent = {
  Type: "Master",
  IncludeCommonFields: "false",
};

var Event = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var CurrentFieldData = {
  Type: "Trans",
  IncludeCommonFields: "false",
};

var FieldOperation = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var StationOperation = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var StationProcessing = {
  Type: "Trans",
  IncludeCommonFields: "false",
};
var ShipDocType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var OrderType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var FieldProcess = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var ShipDocNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var PartnerNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var OrderNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var MaterialNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var StationTerminalSetup = {
  Type: "Master",
  IncludeCommonFields: "false",
};

var ShipDocStatus = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var OrderStatus = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var DangerousGoodsClass = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var Report = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var CommGroupAssigment = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var CommGroup = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var CommGroup = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var CommGroup = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var PartnerBlockCode = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var ShipDocVoidStatus = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var OrderVoidStatus = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var ModeOfTransportType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var TransportationMode = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var Incoterm = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var DataTranslationType = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var DataFormat = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var DataOrigin = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var SubLocationType = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var SubLocation = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var MoveType = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var InvDocNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var InvDocType = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var StageProcedure = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var StageProcedureStep = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var AccountType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var Account = {
  Type: "Master",
  IncludeCommonFields: "false",
};
var Currency = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var VehicleType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var HomeBase = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var Fleet = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var AccountNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var VehicleNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var BillOfMaterialNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var BillOfMaterialType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var InvoiceType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var InvoiceStatus = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var InvoiceNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var InvoiceVoidStatus = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var FinancialDocNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var FinancialDocType = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var LeaseNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var LeaseType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var LogisticStationNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var LogisticStationType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var LoadBay = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var LoadArea = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var LoadArm = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var DeterLTFMTermSetup = {
  Type: "Master",
  IncludeCommonFields: "false",
};

var LoadArm = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var Activity = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var ActivityType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var UserType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var Global = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var BackgroundJob = {
  Type: "System",
  IncludeCommonFields: "false",
};
var OutboundType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var TransmissionMethod = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var OutboundStatus = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var OutboundProcedureDetail = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var OutboundProcedure = {
  Type: "Config",
  IncludeCommonFields: "false",
};

var State = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var DocumentClass = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var StationType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var TestMethodClass = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var TestMethodSource = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var ProductGroup = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var PhysicalDocType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var PhysicalDocNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var LabTestType = {
  Type: "Config",
  IncludeCommonFields: "false",
};
var LabTestNumberRange = {
  Type: "Config",
  IncludeCommonFields: "false",
};


var TableAttributes = {
  LabTestNumberRange,
  LabTestType,
  PhysicalDocNumberRange,
  PhysicalDocType,
  ProductGroup,
  TestMethodSource,
  TestMethodClass,
  StationType,
  DocumentClass,
  OutboundProcedure,
  OutboundProcedureDetail,
  OutboundStatus,
  TransmissionMethod,
  OutboundType,
  BackgroundJob,
  Global,
  UserType,
  LoadArm,
  LoadArea,
  LoadBay,
  LogisticStationType,
  LogisticStationNumberRange,
  LeaseType,
  LeaseNumberRange,
  FinancialDocType,
  FinancialDocNumberRange,
  InvoiceStatus,
  InvoiceType,
  InvoiceVoidStatus,
  InvoiceNumberRange,
  BillOfMaterialType,
  AccountNumberRange,
  VehicleNumberRange,
  BillOfMaterialNumberRange,
  Fleet,
  VehicleType,
  HomeBase,
  Currency,
  Account,
  AccountType,
  StageProcedureStep,
  StageProcedure,
  Component,
  User,
  Language,
  Message,
  Station,
  StationAssigment,
  FieldDataPoint,
  UnitType,
  Unit,
  Country,
  Region,
  IsoUnit,
  MeasurementType,
  PlantType,
  Plant,
  MeasurementSource,
  FieldDataPointType,
  MaterialType,
  MaterialGroup,
  UnitConversion,
  PartnerRole,
  NumberRange,
  PartnerType,
  DocumentCategory,
  DocumentType,
  StatusClass,
  StatusType,
  StatusUse,
  ImplementationUse,
  ImplementationType,
  Implementation,
  BusinessObjectCategory,
  BusinessObject,
  TankFarm,
  TankGroup,
  TASGlobal,
  PartnerLocation,
  Location,
  ShipDocEvent,
  Event,
  CurrentFieldData,
  FieldOperation,
  StationOperation,
  StationProcessing,
  ShipDocType,
  OrderType,
  FieldProcess,
  ShipDocNumberRange,
  PartnerNumberRange,
  OrderNumberRange,
  MaterialNumberRange,
  StationTerminalSetup,
  ShipDocStatus,
  OrderStatus,
  DangerousGoodsClass,
  Report,
  CommGroupAssigment,
  CommGroup,
  OrderVoidStatus,
  ShipDocVoidStatus,
  PartnerBlockCode,
  ModeOfTransportType,
  TransportationMode,
  Incoterm,
  DataTranslationType,
  DataFormat,
  DataOrigin,
  SubLocationType,
  SubLocation,
  MoveType,
  InvDocNumberRange,
  InvDocType,
  DeterLTFMTermSetup,
  Activity,
  ActivityType,
  State,
};

module.exports = { TableAttributes };
