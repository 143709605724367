import React from 'react';
import './infoCardHeader.scss';

export type InfoCardHeaderProps = {
  cardlogo?: string;
  headerLabel: string;
};

export default function InfoCardHeader({
  cardlogo,
  headerLabel,
}: InfoCardHeaderProps) {
  return (
    <div className="info-card-header-container">
      <img src={cardlogo} alt="" />
      <span className="info-card-header-container-label">{headerLabel}</span>
    </div>
  );
}
