/** @format */

import React, { useContext, useState } from "react";
import { mainDataArray, enhancedInputArray, quantityArray1,quantityArray2,loadDockArray,scaleArray1,scaleArray2 } from "./Fields";
import { ShipDocContext } from "../../Contex";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";
import MaterialField from "../../../../../CommonComponents/EnhancedFields/Material/MaterialField";
import MaterialList from "../../../../../CommonComponents/DropDowns/Material/MaterialList";
import TankField from "../../../../../CommonComponents/EnhancedFields/Tank/TankField";
import TankList from "../../../../../CommonComponents/DropDowns/Tank/TankList";

function Index({
  setShowItemsDetailsModal,
  itemOperation,
  item,
  setItem,
  handleAccept,
}) {
  const {
    dropDowns,
    _getMaterialList,
    _materialList,
    _getTankList,
    _tankList,
    setDisplayMainTab,
  } = useContext(ShipDocContext);

  const [showMaterialList, setShowMaterialList] = useState(false);
  const [showTankList, setShowTankList] = useState(false);
  const [materialFieldName,setMaterialFieldName] = useState();
  const [tankFieldName,setTankFieldName] = useState();

  const onChangeItem = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setItem((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: item,
      onChange: onChangeItem,
    };
    switch (itemOperation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: item,
    dropDowns,
  };

  const mainFieldsToRender = mainDataArray(fieldsState);

  const quantityArray1ToRender = quantityArray1(fieldsState);

  const quantityArray2ToRender = quantityArray2(fieldsState);

  const enhancedFieldsToRender = enhancedInputArray(fieldsState);

  const loadDockFieldsRender   = loadDockArray(fieldsState);

  const scaleArray1ToRender   = scaleArray1(fieldsState);

  const scaleArray2ToRender   = scaleArray2(fieldsState);
  
  var materialField = enhancedFieldsToRender.find(
    ({ id }) => id === "materialId"
  );
  var tankField = enhancedFieldsToRender.find(({ id }) => id === "tankId");
  var destinyTankField = enhancedFieldsToRender.find(({ id }) => id === "destinationTankId");

  return (
    <div className="row">
      <div className="col-lg-3 col-sm-12 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Main Data</h2>
          </div>
          <div className="cardBody">
            {mainFieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Material Data </h2>
          </div>
          <div className="cardBody">
            <MaterialField
              field={mergedFieldData(materialField)}
              setShowMaterialList={setShowMaterialList}
              setMaterialFieldName={setMaterialFieldName}
              _getMaterialList={_getMaterialList}
            />
            <TankField
              field={mergedFieldData(tankField)}
              setShowList={setShowTankList}
              setTankFieldName={setTankFieldName}
              _getTankList={_getTankList}
            />
            <TankField
              field={mergedFieldData(destinyTankField)}
              setShowList={setShowTankList}
              setTankFieldName={setTankFieldName}
              _getTankList={_getTankList}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Quantities </h2>
          </div>
          <div className="cardBody">
            {quantityArray1ToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Quantities </h2>
          </div>
          <div className="cardBody">
            {quantityArray2ToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12 mt-2">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Scale</h2>
          </div>
          <div className="cardBody">
            {scaleArray1ToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12 mt-2">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Scale</h2>
          </div>
          <div className="cardBody">
            {scaleArray2ToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12 mt-2">
        <div className="card" style={{ minHeight: 610 }}>
          <div className="cardHeader">
            <h2>Load Dock</h2>
          </div>
          <div className="cardBody">
            {loadDockFieldsRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
        className="my-4"
      >
        <Button
          className="me-4"
          style={{ background: "#DA1212", borderColor: "#DA1212" }}
          onClick={() => {
            setShowItemsDetailsModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          active="true"
          onClick={() => {
            handleAccept();
            setDisplayMainTab(false);
            setShowItemsDetailsModal(false);
          }}
        >
          Accept
        </Button>
      </div>
      <MaterialList
        _materialList={_materialList}
        showMaterialList={showMaterialList}
        setShowMaterialList={setShowMaterialList}
        materialFieldName={materialFieldName}
        onChange={onChangeItem}
      />
      <TankList
        _tankList={_tankList}
        showTankList={showTankList}
        setShowTankList={setShowTankList}
        tankFieldName={tankFieldName}
        onChange={onChangeItem}
      />
    </div>
  );
}

export default Index;
