import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { MaterialContext } from "../context";
import { MuiButon } from "../../../../CommonComponents/MuiButton";

export default function MaterialOperationHeader({
  _loadingSaveMaterial,
  _saveMaterial,
}) {
  const { operation, _setMaterialData, _materialData, setOperation } =
    useContext(MaterialContext);

  const handleSave = () => {
    _saveMaterial(
      { data: _materialData },
      ({ data: NewMaterialData, success: callStatus }) => {
        if (callStatus) {
          if (operation === "Create") {
          }
          setOperation("Change");
        }
        _setMaterialData(NewMaterialData);
      }
    );
  };

  return (
    <Paper className="py-2 px-2 d-flex align-items-center justify-space-between customPaper">
      <TopPanel />
      <MuiButon
        title="Save"
        loading={_loadingSaveMaterial}
        onClick={handleSave}
        disabled={operation === "Display"}
      />
    </Paper>
  );
}
