import React, { useContext } from "react";
import { AccountContext } from "../AccountMain/context";

export default function TopPanel() {
  const { operation, accountId } = useContext(AccountContext);

  var accountInfo;
  switch (operation) {
    case "Display":
      accountInfo = `${operation} Account: ${accountId}`;
      break;
    case "Change":
      accountInfo = `${operation} Account ${accountId}`;
      break;
    case "Create":
      accountInfo = `${operation} New Account`;
      break;
    default:
      accountInfo = `Account Info`;
      break;
  }
  return (
    <h2 className="heading" style={{ position: "relative", top: 8 }}>
      {accountInfo}
    </h2>
  );
}
