import React, { useState, useEffect } from "react";
import { ReactComponent as DriverIconCard } from "../../../../Assets/NewIconsForCards/DriverIcon.svg";
import { ReactComponent as ShipDocCard } from "../../../../Assets/NewIconsForCards/ShipDocOverview.svg";
import { ReactComponent as PrintIcon } from "../../../../Assets/NewIconsForCards/PrinterIcon.svg";
import Card from "../../../Components/Card";
import { useUser } from "../../../../hooks/useUser";
import { ReactComponent as ChartIcon } from "src/Assets/NewIconsForCards/ChartIcon.svg";
import { ReactComponent as ClockIcon } from "src/Assets/NewIconsForCards/ClockIcon.svg";
import { ReactComponent as SearchIcon } from "src/Assets/NewIconsForCards/SearchIcon.svg";
import { ReactComponent as ReactCarrierIconCard } from "src/Assets/NewIconsForCards/CarrierIcon.svg";
import { ReactComponent as PDFIcon } from "src/Assets/NewIconsForCards/PDFIcon.svg";

export default function GlendaleAdminHome(props) {
  const [newCards, setNewCards] = useState([]);
  const [_user, ,] = useUser();
  useEffect(() => {
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card.roles.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);

  const CardsArray = [
    {
      title: "Patner Driver",
      text: "",
      Icon: DriverIconCard,
      link: "/PartnerDriverOverview",
      roles: ["Admin", "SuperAdmin", "Loader"],
    },
    {
      title: "Carrier",
      text: "",
      Icon: ReactCarrierIconCard,
      link: "/PartnerCarrierOverview",
      roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
    },
    {
      title: "Shipment Document Overview",
      text: "",
      Icon: ShipDocCard,
      link: "/ShipDocOverview",
      roles: ["Admin", "SuperAdmin", "Loader"],
    },
    {
      title: "BOL PDF",
      text: "",
      Icon: PDFIcon,
      link: "/ShipDocPDF",
      roles: ["Admin", "SuperAdmin", "TerminalUser"],
    },
    // {
    //   title: "Print BOL From TLOAD",
    //   text: "",
    //   Icon: PrintIcon,
    //   link: "/PrintBOLFromTLOAD",
    //   roles: ["Admin", "SuperAdmin", "Loader"],
    // },
    {
      title: "Print BOL From SAP",
      text: "",
      Icon: PrintIcon,
      link: "/PrintBOLFromSAP",
      roles: ["Admin", "SuperAdmin", "Loader"],
    },
    {
      title: "Chart",
      text: "",
      Icon: ChartIcon,
      link: "/Dashboard/GlendaleChart",
      roles: ["Admin", "SuperAdmin", "Loader"],
    },
    {
      title: "BOL Process Time Chart",
      text: "",
      Icon: ClockIcon,
      link: "/Dashboard/GlendaleBOLProcessTimeChart",
      roles: ["Admin", "SuperAdmin", "Loader"],
    },
    {
      title: "Partner Workbench",
      text: "",
      Icon: SearchIcon,
      link: "/PartnerWorkbench",
      roles: ["Admin", "SuperAdmin"],
    },
  ];

  return (
    <div className="container-fluid ">
      <div className="mainContainer">
        <div className="overviewCardContainer">
          {newCards.map((record, i) => (
            <Card {...record} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
}
