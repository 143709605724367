export const mainCokeArray = ({ dropDowns, shiptos }) => [
  {
    id: "locationDescriptor",
    name: "locationDescriptor",
    type: "text",
    label: "Plant",
    disabled: true,
  },
  {
    id: "id",
    name: "id",
    type: "text",
    label: "BOL Number",
    disabled: true,
  },
  {
    id: "shiptoId",
    name: "shiptoId",
    type: "text",
    label: "Shipto",
    tag: "select",
    options: prepareShiptoDD(shiptos, dropDowns) || [],
  },
];
export const mainSulfurArray = ({ dropDowns }) => [
  {
    id: "locationDescriptor",
    name: "locationDescriptor",
    type: "text",
    label: "Plant",
    disabled: true,
  },
  {
    id: "id",
    name: "id",
    type: "text",
    label: "BOL Number",
    disabled: true,
  },
  {
    id: "railcar",
    name: "railcar",
    type: "text",
    label: "Railcar",
  },
];

export const mainCommonArray = ({ dropDowns }) => [
  {
    id: "locationDescriptor",
    name: "locationDescriptor",
    type: "text",
    label: "Plant",
    disabled: true,
  },
  {
    id: "id",
    name: "id",
    type: "text",
    label: "BOL Number",
    disabled: true,
  },
  {
    id: "shiptoId",
    name: "shiptoId",
    type: "text",
    label: "Shipto",
    disabled: true,
  },
];
export const mainwithTempArray = ({ dropDowns }) => [
  {
    id: "temperature",
    name: "temperature",
    type: "number",
    label: "Temperature",
    required: true,
  },
  {
    id: "gravity",
    name: "gravity",
    type: "text",
    label: "Gravity",
  },
  {
    id: "id",
    name: "id",
    type: "text",
    label: "BOL Number",
    disabled: true,
  },
  {
    id: "shiptoId",
    name: "shiptoId",
    type: "text",
    label: "Shipto",
    disabled: true,
  },
];
export const mainwithTempArrayV1 = ({ dropDowns }) => [
  {
    id: "temperature",
    name: "temperature",
    type: "number",
    label: "Temperature",
    tag: "numpad",
    required: true,
  },
  {
    id: "gravity",
    name: "gravity",
    type: "text",
    label: "Gravity",
  },
  {
    id: "id",
    name: "id",
    type: "text",
    label: "BOL Number",
    disabled: true,
  },
  {
    id: "shiptoId",
    name: "shiptoId",
    type: "text",
    label: "Shipto",
    disabled: true,
  },
];
export const mainWithoutTempArray = ({ dropDowns }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "BOL Number",
    disabled: true,
  },
  {
    id: "shiptoId",
    name: "shiptoId",
    type: "text",
    label: "Shipto",
    disabled: true,
  },

  {
    id: "gravity",
    name: "gravity",
    type: "text",
    label: "Gravity",
  },
]
export const carrierArray = ({ dropDowns, carriers }) => [
  {
    id: "carrierDescriptor",
    name: "carrierDescriptor",
    type: "text",
    label: "Carrier",
    tag: "select",
    options: prepareCarrierDD(carriers, dropDowns) || [],
  },
  {
    id: "driverDescriptor",
    name: "driverDescriptor",
    tag: "select",
    label: "Driver",
    options: dropDowns?.drivers || [],
  },
  {
    id: "vehicleId",
    name: "vehicleId",
    type: "text",
    label: "Vehicle",
    //   disabled: true,
  },
  {
    id: "trailerId",
    name: "trailerId",
    type: "text",
    label: "Trailer",
    //    disabled: true,
  },
];
export const carrierCommonArray = ({ dropDowns }) => [
  {
    id: "carrierDescriptor",
    name: "carrierDescriptor",
    type: "text",
    label: "Carrier",
    disabled: true,
  },
  {
    id: "driverDescriptor",
    name: "driverDescriptor",

    label: "Driver",
    disabled: true,
  },
  {
    id: "vehicleId",
    name: "vehicleId",
    type: "text",
    label: "Vehicle",
    disabled: true,
  },
  {
    id: "trailerId",
    name: "trailerId",
    type: "text",
    label: "Trailer",
    disabled: true,
  },
];
export const otherArray = () => [
  {
    id: "startDT",
    name: "startDT",
    type: "DateTime",
    label: "Load Start Date/Time",
    disabled: true,
  },

  {
    id: "incotermDescriptor",
    name: "incotermDescriptor",
    type: "text",
    label: "Incoterms",
    disabled: true,
  },
];
export const weightArray = () => [
  {
    id: "inWeight",
    name: "inWeight",
    type: "text",
    label: "In Weight",
    disabled: true,
  },
  {
    id: "outWeight",
    name: "outWeight",
    type: "text",
    label: "Out Weight",
    disabled: true,
  },
  {
    id: "netWeight",
    name: "netWeight",
    type: "text",
    label: "Net Weight",
    disabled: true,
  },
];

function prepareCarrierDD(carriers, dropDowns) {
  if (typeof carriers !== "undefined" && carriers.length === 0) {
    return dropDowns?.carriers;
  }
  return carriers;
}

function prepareShiptoDD(shiptos, dropDowns) {
  if (typeof shiptos !== "undefined" && shiptos.length === 0) {
    return dropDowns?.shiptos;
  }
  return shiptos;
}
