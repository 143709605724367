import React, { useContext } from "react";
import RenderField from "./RenderField";
import { TableDataContext } from "../Contexts/TableDataContext";
export default function RenderFields() {
  const { tableFieldsN, tableRecordN, tableDropDowns } =
    useContext(TableDataContext);
  function createDropDown(field) {
    debugger;
    var hLvlKey = null;
    let dropDownKeyN =
      field.dropdownkey?.charAt(0).toUpperCase() + field.dropdownkey.slice(1);
    //Get the full dropdown list based on field ID
    var list = tableDropDowns[field.dropdownkey];
    if (list == null) {
      list = tableDropDowns[dropDownKeyN];
    }

    //if there no dropdown list then leave Function
    if (list == null) {
      return;
    }

    //Get the Value of the field that dropdown list must be filtered by, the field is defined in variable (field.higherLevelKey1),
    //its value in the current table record is obtained from the (TableRecord) Redux state
    if (field.higherLevelKey1 != null) {
      hLvlKey = tableRecordN[field.higherLevelKey1];
    }

    //loop at dropdown list and create a new one by fillering based on the value of (field.higherLevelKey1)
    var filteredList = list.filter((item) => {
      var hLevelKeyValue = null;

      //Get the value of higher level key from the current object of the array
      hLevelKeyValue = item[field.higherLevelKey1];

      //if the value of the higher level key from the table record (field.higherLevelKey1) DOES NOT equal the value from the list item, leave and do
      //return a list item
      if (hLevelKeyValue != hLvlKey) {
        return;
      }

      return item;
    });

    var finalList = filteredList.map((item) => {
      let id = null;
      let text = null;
      let enhancedText = null;

      //The output of this function should be a simple list consisting of an array of objects, the object formate will be {id:"SomeID", Text:"Sometext"}
      //The ID can be extracted from the current array object by searching in 2 ways:
      //1-for simple dropdown list, the ID will be just called "id".
      //2-for complicated situations,  we will look for an ID based on the field ID being procssed in the function i.e (field.id), for example regionId
      // we look from specific to less specific
      id = item[field.id];
      if (id == null) {
        id = item["id"];
      }

      //for enums
      if (id == null) {
        id = item["value"];
      }

      //We are chaning the way local text is processed in backend, the new field is called "localText"
      //1st use localText
      if (item?.localText) {
        text = item?.localText;
      }
      if (text) {
        enhancedText = `(${id}): ${text}`;
        return { id, text: enhancedText };
      }

      if (item?.nameLocalizedText) {
        if (item?.nameLocalizedText != "N/A") {
          text = item?.nameLocalizedText;
        }
      }
      if (text) {
        enhancedText = `(${id}): ${text}`;
        return { id, text: enhancedText };
      }

      if (item?.universalDescriptor) {
        text = item?.universalDescriptor;
      }
      if (text) {
        enhancedText = `(${id}): ${text}`;
        return { id, text: enhancedText };
      }

      //for enums
      if (item?.key) {
        enhancedText = `(${item?.value}): ${item.key}`;
        text = enhancedText;
        return { id: item.value, text };
      }
    });

    return finalList;
  }
  return (
    <div>
      {tableFieldsN.map((field, i) => {
        var dropDownList = createDropDown(field);
        if (field.hide == null || field.hide === "false") {
          return <RenderField field={field} ddvalues={dropDownList} key={i} />;
        }
      })}
    </div>
  );
}
