
export const ValidateTankInput = (tank, _createErrorMessage) => {

  if (!tank) {
    _createErrorMessage("54");
    return false;
  }

  return true;
};
