/** @format */

import React from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import Button from '@mui/material/Button';

export default function LeaseField({
  field,
  setShowLeaseList,
  _getLeaseList,
  setLeaseFieldName,
}) {
  return (
    <div className="mb-3">
      <label
        htmlFor={field.id}
        style={{
          fontWeight: 600,
        }}
      >
        {field.label}:
      </label>
      <div className="d-flex align-items-center justify-content-between">
        <input
          className="form-control"
          value={field.dataSource?.[field.id]}
          onChange={field.onChange}
          data-type={field.type}
          type={field.type}
          name={field.name}
          disabled={field.disabled}
          style={{
            borderRadius: 8,
            padding: 8,
            width: `calc(100% - 65px)`,
            height: 50,
          }}
        />
        <Button
          style={{
            display: "flex",
            alignItems: "center",
            height: 50,
            width: 50,
            minWidth: "auto",
            overflow: "hidden !important",
            borderRadius: "50%",
            boxShadow:
              ".1rem .1rem .2rem rgba(0,0,0,0.1),-.1rem -.1rem .2rem rgba(0,0,0,0.1)",
          }}
          onClick={() => {
            _getLeaseList({}, () => {
              setLeaseFieldName(field.id);
              setShowLeaseList(true);
            });
          }}
        >
          <IoMdArrowDropdown
            style={{ fontSize: "35px", color: "rgb(2, 119, 189)" }}
          />
        </Button>
      </div>
    </div>
  );
}
