exports.Global = [
    {
      id: "id",
      name: "id",
      type: "text",
      value: "",
      label: "Id",
      length: 12,
      key: "true",
    },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      value: "",
      label: "Universal Descriptor",
      length: 50,
    },
    {
        id: "disableBackgroundJobs",
        name: "disableBackgroundJobs",
        type: "boolean",
        label: "Disable Background Jobs",
      },
      {
        id: "addMessagesToLog",
        name: "addMessagesToLog",
        type: "boolean",
        label: "AddMessagesToLog",
      },
      {
        id: "minLogLevel",
        name: "minLogLevel",
        type: "text",
        value: "",
        label: "Min Log Level",
        length: 5,
      },
      {
        id: "debugMode",
        name: "debugMode",
        type: "boolean",
        label: "Debug Mode",
      },
      {
        id: "disableShipDocERP",
        name: "disableShipDocERP",
        type: "boolean",
        label: "Disable Sending Shipping Document ERP",
      },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "datetime",
      label: "ModifiedOn",
      displayonly: "true",
      value: " ",
    },
  ];
  