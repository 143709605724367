/** @format */

import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../hooks/useFetch";

import { GetFullURL } from "../../../../CommonComponents/GlobalConfFiles/URLs";
import { useMessage } from "../../../../hooks/UseMessage";
import { ReactComponent as TruckandScale } from "../../../../Assets/SVGs/TruckAndScale.svg";
import { ReactComponent as OverLoadAlram } from "../../../../Assets/SVGs/Alrams/AnimatedAlram1.svg";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import HeaderV3 from "../../LoadingStages/Header/HeaderV3";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import { FlipCounter } from "../../../../CommonComponents/FlipCounter/FlipCounter";
import { useSelector } from "react-redux";

import TrafficLight from "../../../../CommonComponents/TrafficLights/Components/TrafficLightSet";
import TruckAnimation from "../../../../CommonComponents/TruckAnimation/index";
import { useParams } from "react-router-dom";
import MTDataInfoSIdeBar from "./MTDataInfoSIdeBar";
function ScaleStateMT(props) {
  const [usedStation, setUsedStation] = useState();
  const params = useParams();
  //ShipDoc ID
  let shipDoc = params.shipDoc;

  let navigate = useNavigate();
  const [weight, setWeight] = useState(null);
  const [loading, setLoading] = useState(false);
  const [topLog, setTopLog] = useState("");
  const [alrams, setAlrams] = useState([]);
  const [entryLights, setEntryLights] = useState({
    activeLight: 1,
    blinking: false,
  });
  const [exitLights, setExitLights] = useState({
    activeLight: 0,
    blinking: false,
  });

  const { preferredLanguage, stationId } = useSelector(
    (state) => state.userData.User.user
  );

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [frondendSuccess, setFrontendSuccess] = useState([]);
  const language = preferredLanguage;
  const userStation = stationId;

  let getCurrentMTDataURL = "http://localhost:8089/ReceiveCurrentMTData";
  let reSetURL = "http://localhost:8089/Reset";

  const [
    _resetService,
    _resetServiceData,
    _setResetServiceData,
    _resetServiceError,
    _setResetServiceError,
    _resetServiceSuccess,
    _setResetServiceSucces,
    _loadingResetService,
  ] = useFetch(reSetURL, {
    method: "POST",
    headers: {},
  });

  const [
    _getMTData,
    _mTData,
    _setMTData,
    _mTDataError,
    _setMTDataError,
    _mTDataSuccess,
    _setMTDataSuccess,
    _loadingGetMTData,
  ] = useFetch(getCurrentMTDataURL, {
    method: "POST",
    headers: {},
  });

  function GetMTServiceData() {
    _getMTData({}, (response) => {
      if (response.success) {
        //Set Weight
        setWeight(response.data.currentWeight);
      } else {
        setTopLog((current) => {
          return [response.message];
        });
        return;
      }
    });
  }

  let storedCookie;
  useEffect(() => {
    let usedStation = "";
    storedCookie = Cookies.get("station");

    if (!userStation) {
      if (!storedCookie) {
        _createErrorMessage("1");
        return;
      }
    }

    usedStation = storedCookie ? storedCookie : userStation;
    setUsedStation(usedStation);
  }, []);

  //when loading status changes, run this use effect which will do the following:
  //1st, if if loading in on, then set the timer
  //2nd, cleanup from previous use effect by stopping timer

  useEffect(() => {
    let mTServiceTimer;
    //   if (loading) {
    //    mTServiceTimer = setInterval(GetMTServiceData, 2000);
    //  }
    mTServiceTimer = setInterval(GetMTServiceData, 2000);
    return () => {
      clearInterval(mTServiceTimer);
    };
  }, []);

  useEffect(() => {
    if (_mTData.flashingEntryGreenLight === true) {
      setEntryLights((current) => {
        return { ...current, activeLight: 1, blinking: true };
      });
    } else if (_mTData.entryGreenLight === "On") {
      setEntryLights((current) => {
        return { ...current, activeLight: 1 };
      });
    }

    if (_mTData.flashingEntryRedLight === true) {
      setEntryLights((current) => {
        return { ...current, activeLight: 0, blinking: true };
      });
    } else if (_mTData.entryRedLight === "On") {
      setEntryLights((current) => {
        return { ...current, activeLight: 0 };
      });
    }
  }, [
    _mTData.entryGreenLight,
    _mTData.entryRedLight,
    _mTData.flashingEntryGreenLight,
    _mTData.flashingEntryRedLight,
  ]);

  useEffect(() => {
    if (_mTData.flashingExitGreenLight === true) {
      setExitLights((current) => {
        return { ...current, activeLight: 1, blinking: true };
      });
    }

    if (_mTData.flashingExitGreenLight === false) {
      setExitLights((current) => {
        return { ...current, activeLight: 1, blinking: false };
      });
    }

    if (_mTData.exitGreenLight === "On") {
      setExitLights((current) => {
        return { ...current, activeLight: 1 };
      });
    }

    if (_mTData.exitRedLight === "On") {
      setExitLights((current) => {
        return { ...current, activeLight: 0 };
      });
    }
  }, [
    _mTData.exitGreenLight,
    _mTData.exitRedLight,
    _mTData.flashingExitGreenLight,
    _mTData.flashingExitRedLight,
  ]);

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        setFrontendSuccess([]);
        _setResetServiceSucces([]);
        _setMTDataSuccess([]);
        break;
      case "error":
        _setErorMessages([]);
        _setResetServiceError([]);
        _setMTDataError([]);
        break;
      default:
        break;
    }
  }

  const operationLoading = _loadingResetService;

  const onClickResetService = () => {
    _resetService({}, (response) => {
      setTopLog((current) => {
        return [response.message];
      });

      if (response.success) {
        return;
      }
    });
  };

  const calculateFillPercent = () => {
    //if max weight value is 0 set % to zero because we can not divid by 0
    if (_mTData.maxWeight === 0) {
      return 0;
    }

    //if in weight value has not been taken ie we have not started loading, then set % to 0
    if (!_mTData.inWeightTaken) {
      return 0;
    }

    let currentLoadedQty = weight - _mTData.inWeight;
    let fullLoad = _mTData.maxWeight - _mTData.inWeight;

    let percentLoaded = (currentLoadedQty * 100) / fullLoad;

    return percentLoaded.toFixed(2);
  };
  calculateFillPercent();

  return (
    <>
      <section>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-12 p-0">
              <HeaderV3 stationId={usedStation} />
            </div>

            {topLog && (
              <div className="col-12">
                <div className="w-100 rounded-lg px-5 py-3 bg-danger d-flex align-items-center justify-content-center">
                  <span className="text-white"> {topLog}</span>
                </div>
              </div>
            )}
            <div className="col-lg-3 col-sm-12 col-xs-12">
              <MTDataInfoSIdeBar _mTData={_mTData} />
            </div>
            <div className="col-lg-9 col-sm-12 col-xs-12">
              <div className="" style={{ flex: 1 }}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="h-50">
                    <TrafficLight
                      enabledIndex={exitLights.activeLight}
                      blinking={exitLights.blinking}
                    />
                  </div>
                  <div className="mx-3" style={{ width: "85%" }}>
                    <TruckAnimation
                      compartCount={1}
                      activeIndex={0}
                      fillPerCent={calculateFillPercent()}
                    />
                  </div>
                  <div className="h-50">
                    <TrafficLight
                      enabledIndex={entryLights.activeLight}
                      blinking={entryLights.blinking}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <FlipCounter number={weight} className="mb-5" />
                  <div className="d-flex" style={{ gap: "1rem" }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      className="px-5 py-3 mt-5 w-50"
                      onClick={() => {
                        navigate(`/ELDOScaleOperations`);
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      color={loading ? "danger" : "primary"}
                      variant="contained"
                      className="px-5 py-3 mt-5 w-50"
                      disabled={loading ? true : false}
                      onClick={onClickResetService}
                    >
                      Reset Service
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-2 d-flex justify-content-center ml-4">
              {alrams.map((item, index) => {
                return (
                  <div key={index}>
                    {item} <br />
                  </div>
                );
              })}
            </div>
            <div className="col-12">
              <MessageLog
                errorMessages={[
                  ..._errorMessages,
                  ..._resetServiceError,
                  ..._mTDataError,
                ]}
                successMessages={[
                  ...frondendSuccess,
                  ..._mTDataSuccess,
                  ..._resetServiceSuccess,
                ]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ScaleStateMT;
