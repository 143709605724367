import React from "react";
import Button from '@mui/material/Button';
import { ValidateShipDocInput } from "../ValidateShipDocInput";
const btnsArray = [
  { operation: "Display" },
  { operation: "Change" },
  { operation: "Create" },
  { operation: "Cancel" },
];

export default function OverviewButtons({
  shippingDocument,
  navigate,
  _getShippingDocExists,
  _createErrorMessage,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ operation }, i) => (
        <div style={{ width: "45%", marginBottom: 15 }} key={i}>
          <Button
            className="btn btn-primary w-100"
            key={i}
            onClick={() => {
              //If creating shipdoc no need for any further work just proceed to URL
              if (operation === "Create") {
                navigate(`/ShipDoc/Create`);
                return;
              }
              if (operation === "Cancel") {
                navigate(-1);
                return;
              }
              let validationShipDocResult = ValidateShipDocInput(
                shippingDocument,
                _createErrorMessage
              );

              if (!validationShipDocResult) {
                return;
              }

              _getShippingDocExists(
                { data: { Id: shippingDocument } },
                ({ data: isExist }) => {
                  if (!isExist) {
                    _createErrorMessage("45");
                    return;
                  }
                  const navigationUrl = `/ShipDoc/${shippingDocument}/${operation}`;
                  navigate(navigationUrl);
                }
              );
            }}
          >
            {operation}
          </Button>
        </div>
      ))}
    </div>
  );
}
