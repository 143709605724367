
export const ValidateAccountInput = (account, _createErrorMessage) => {

  if (!account) {
    _createErrorMessage("58");
    return false;
  }

  return true;
};
  