export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Lease ID",
    disabled: true,
  },
  {
    id: "leaseTypeId",
    name: "leaseTypeId",
    tag: "select",
    options: dropDowns?.leaseTypes || [],
    type: "text",
    label: "Lease Type",
  },
  {
    id: "companyId",
    name: "companyId",
    tag: "select",
    options: dropDowns?.companies || [],
    type: "text",
    label: "Company",
  },
  {
    id: "gatheringUnit",
    name: "gatheringUnit",
    type: "text",
    label: "Gathering Unit",
  },
  {
    id: "tankFarmId",
    name: "tankFarmId",
    type: "text",
    label: "Tank Farm",
    tag: "select",
    options: dropDowns?.tankFarms || [],
  },
  {
    id: "aPI_WELL_NUM",
    name: "aPI_WELL_NUM",
    type: "text",
    label: "API_WELL_NUM",
  },
];

export const enhancedInputArray = ({ dropDowns, dataSource }) => [
  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    type: "text",
    tag: "enhancedInput",
  },
  {
    id: "logisticStationId",
    name: "logisticStationId",
    label: "Logistic Station",
    type: "text",
    tag: "enhancedInput",
  },
];

export const quantityArray = ({ dropDowns, dataSource }) => [
  {
    id: "monthlyQuantity",
    name: "monthlyQuantity",
    label: "Monthly Quantity",
    type: "number",
  },
  {
    id: "unitId",
    name: "unitId",
    label: "Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
];
export const partnersArray = () => [
  {
    id: "carrierId",
    name: "carrierId",
    label: "Carrier",
  },
];
