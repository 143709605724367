import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { VehicleContext } from "../context";
import { MuiButon } from "../../../../../CommonComponents/MuiButton";

export default function VehicleOperationHeader({
  _loadingSaveVehicle,
  _saveVehicle,
}) {
  const { operation, _setVehicleData, _vehicleData, setOperation } =
    useContext(VehicleContext);

  const handleSave = () => {
    _saveVehicle(
      { data: _vehicleData },
      ({ data: NewVehicleData, success: callStatus }) => {
        if (callStatus) {
          if (operation === "Create") {
          }
          setOperation("Change");
        }
        _setVehicleData(NewVehicleData);
      }
    );
  };

  return (
    <Paper className="py-2 px-2 d-flex align-items-center justify-space-between customPaper">
      <TopPanel />
      <MuiButon
        title="Save"
        loading={_loadingSaveVehicle}
        onClick={handleSave}
        disabled={operation === "Display"}
      />
    </Paper>
  );
}
