import React, { useContext } from "react";
import { TableDataContext } from "../Contexts/TableDataContext";
export default function Input({ field }) {
  const { tableOperationN, tableRecordN, setTableRecordN } =
    useContext(TableDataContext);

  var value = tableRecordN[field.id];

  const changeHandler = (e) => {
    let newValue;
    let fieldId = e.target.id;
    let val = e.target.value;

    //Check field length and ignore input based the max length
    var fieldLength = val.length;
    if (fieldLength > field.length) {
      return;
    }

    // field type and format
    switch (field.type) {
      case "number":
        newValue = Number(val);
        break;
      case "checkbox":
        newValue = e.target.checked;
        break;
      case "text":
        newValue = e.target.value.trimStart();
        break;
      default:
        newValue = val;
        break;
    }
    let valueObject = { [fieldId]: newValue };
    setTableRecordN((current) => {
      return { ...current, ...valueObject };
    });
  };

  // field type and format
  switch (field.type) {
    case "number":
      break;
    case "checkbox":
      break;
    case "date":
      break;
    default:
  }

  function disable(op) {
    if (field.displayonly === "true") {
      return true;
    }
    //if the field is key then disable the field in the change mode
    if (op === "cha" && field.key === "true") {
      return true;
    }

    //if the operation is change and the field can not be changed in the change mode then disable
    if (op === "cha" && field.changedisplayonly === "true") {
      return true;
    }

    if (op === "dis") {
      return true;
    }
    return false;
  }

  return (
    <div className="mb-3">
      <label htmlFor={field.id}>{field.label}</label>
      <input
        key={field.id}
        type={field.type}
        className="form-control"
        disabled={disable(tableOperationN)}
        id={field.id}
        name={field.name}
        required
        checked={value}
        onChange={changeHandler}
        value={value}
      />
    </div>
  );
}
