import React, { useState, useEffect } from "react";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import HomeTab from "./Main/Index";
import AdminTab from "./Admin/Index";
import AccountHeader from "./Header/index";
import { AccountContext } from "./context";
import { useFetch } from "./../../../../hooks/useFetch";
import { GetFullURL } from "./../../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen";
import TabPannel from "../../../../CommonComponents/TabPannel/index";
import { useParams } from "react-router-dom";

export default function AccountMain(props) {
  const [value, setValue] = useState(0);
  const [operation, setOperation] = useState("");
  const [accountId, setAccountId] = useState("");
  const [frontendError, setFrontendError] = useState([]);
  const params = useParams();

  const [
    _getAccountData,
    _accountData,
    _setAccountData,
    _errorAccountData,
    _setErrorAccountData,
    _successAccountData,
    _setSuccessAccountData,
    _loadingAccountData,
  ] = useFetch(GetFullURL("GetAccount"), { method: "POST" });
  const [
    _getAccountDD,
    _accountDD,
    ,
    _errorAccountDD,
    _setErrorAccountDD,
    _successAccountDD,
    _setSuccessAccountDD,
    _loadingtAccountDD,
  ] = useFetch(GetFullURL("AccountDropDowns"), { method: "POST" });
  const [
    _saveAccount,
    ,
    ,
    _errorSaveAccount,
    _setErrorSaveAccount,
    _successSaveAccount,
    _setSuccessSaveAccount,
    _loadingSaveAccount,
  ] = useFetch(GetFullURL("SaveAccount"), { method: "POST" });

  useEffect(() => {
    //when componen mounts,set ID and operation
    if (params.op) {
      setOperation(params.op);
    }
    if (params.id) {
      setAccountId(params.id);
    }
  }, []);

  useEffect(() => {
    _getAccountDD();
    if (accountId) {
      _getAccountData({ data: { id: accountId } });
    }
  }, [accountId]);

  const handleChange = (event, newValue) => setValue(newValue);

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;
    let newValue;

    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = "true") : (newValue = "false");
        break;

      default:
        newValue = value;
        break;
    }

    if (newValue === "select" || newValue === "SELECT") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    _setAccountData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const contextState = {
    onChange,
    operation,
    dropDowns: _accountDD || [],
    accountId,
    _accountData,
    _setAccountData,
    frontendError,
    setOperation,
    setFrontendError,
  };

  const operationLoading =
    _loadingAccountData ||
    _loadingtAccountDD ||
    _loadingSaveAccount;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveAccount([]);
        _setSuccessAccountData([]);
        _setSuccessAccountDD([]);
        break;
      case "error":
        _setErrorAccountData([]);
        _setErrorAccountDD([]);
        _setErrorSaveAccount([]);
        setFrontendError([]);
        break;
      default:
        break;
    }
  }

  return (
    <AccountContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <AccountHeader
        _loadingSaveAccountData={_loadingSaveAccount}
        _saveAccount={_saveAccount}
      />
      <TabPannel
        value={value}
        handleChange={handleChange}
        labels={[
          "Home",
          "Admin",
          "Log",
        ]}
        components={[
          HomeTab,
          AdminTab,
        ]}
      />
      <MessageLog
        errorMessages={[
          ..._errorAccountDD,
          ..._errorAccountData,
          ..._errorSaveAccount,
          ...frontendError,
        ]}
        successMessages={[
          ..._successAccountDD,
          ..._successAccountData,
          ..._successSaveAccount,
        ]}
        clearMessages={clearMessages}
      ></MessageLog>
    </AccountContext.Provider>
  );
}
