export const mainDataArray = ({ dropDowns }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Ship Doc ID",
    key: true,
  },
  {
    id: "shipDocTypeId",
    name: "shipDocTypeId",
    type: "text",
    label: "Ship Doc Type",
    tag: "select",
    options: dropDowns?.shipDocTypes || [],
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    label: "Location",
    tag: "select",
    options: dropDowns?.locations || [],
  },

  {
    id: "startDT",
    name: "startDT",
    type: "datetime",
    label: "Start DT",
  },
  {
    id: "endDT",
    name: "endDT",
    type: "datetime",
    label: "End DT",
  },
  {
    id: "referenceDoc",
    type: "text",
    name: "referenceDoc",
    label: "reference Doc",
    displayOnly: true,
  },
];
export const partnersArray = () => [
  {
    id: "shiptoId",
    name: "shiptoId",
    label: "Shipto",
  },
  {
    id: "soldtoId",
    name: "soldtoId",
    label: "Soldto",
  },
  {
    id: "carrierId",
    name: "carrierId",
    label: "Carrier",
  },
  {
    id: "driverId",
    name: "driverId",
    label: "Driver",
  },
  {
    id: "vehicleId",
    type: "text",
    name: "vehicleId",
    label: "Vehicle",
  },

  {
    id: "trailerId",
    type: "text",
    name: "trailerId",
    label: "Trailer ",
  },
  {
    id: "leaseId",
    type: "text",
    name: "leaseId",
    label: "Lease",
    //dd
  },
];
export const FreightArray = () => [
  {
    id: "vehicleId",
    type: "text",
    name: "vehicleId",
    label: "Vehicle",
  },
  {
    id: "trailerId",
    type: "text",
    name: "trailerId",
    label: "Trailer ",
  },
  {
    id: "railcar",
    type: "text",
    name: "railcar",
    label: "Railcar",
    length: 10,
  },
];
