import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { useFetch } from "../../../hooks/useFetch";
import { useStation } from "../../../hooks/UseStation";
import { useMessage } from "../../../hooks/UseMessage";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import { LoadingContext } from "../Context";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import ListModal from "../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import { useNavigate } from "react-router-dom";
import buttonarrow from "../../../Assets/arrow.svg";
import KeyboardButtonInput from "../../../CommonComponents/keybaords/keyboardButtonHeaderInput";

function StartLoadingWithOrder(props) {
  const [pageData, setPageData] = useState({});
  const [showShipDocList, setShowShipDocList] = useState(false);
  const navigate = useNavigate();
  const fieldProcessId = useRef();
  const keyboardField = useRef(null);
  const hideTopButtons = useRef(false);
  //This start loading page is meant to work with  SAP order hence the fieldProcessId is hard-coded to
  // SAP_ORDER
  fieldProcessId.current = "SAP_ORDER";

  const [_searchShipDocs, _serachShipDocData, , , , , , _loadingSearchShipDoc] =
    useFetch(GetFullURL("AdvancedShipDocSearch"), { method: "POST" });

  const [
    _getOrderInternalERP,
    _orderInternalERPData,
    _setOrderInternalERPData,
    _orderInternalERPError,
    setOrderInternalERPError,
    ,
    ,
    _loadingInternalERP,
  ] = useFetch(GetFullURL("GetOrderInternalERP"), { method: "POST" }, true);

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
  ] = useMessage();

  const [_stationInfo, , , , ,] = useStation();

  const contextState = {};

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        setOrderInternalERPError([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }
  const getRelevantShipDoc = (e) => {
    const report = e.target.dataset.report;

    _searchShipDocs(
      {
        data: {
          locationId: _stationInfo.locationId,
          searchName: report,
        },
      },
      ({ data }) => {
        if (!data) {
          _createErrorMessage("27");
          return;
        }
        if (data.length === 0) {
          _createErrorMessage("27");
          return;
        }
        setShowShipDocList(true);
      }
    );
  };
  const handleShipDocSelect = (selectedId) => {
    let selectedShipDoc = _serachShipDocData.find((x) => x.id == selectedId);
    if (selectedShipDoc) {
      setPageData({
        order: selectedShipDoc.id,
      });
    }
  };

  const checkedShipDocsButtonStatus = () => {
    let stationOperation = [];
    stationOperation = _stationInfo.stationOperationComm;
    var found = stationOperation.find((x) => x.fieldOperationId === "CHECKIN");
    if (found) {
      return false;
    }
    return true;
  };
  const inWeightShipDocsButtonStatus = () => {
    let stationOperation = [];
    stationOperation = _stationInfo.stationOperationComm;
    var found = stationOperation.find(
      (x) =>
        x.fieldOperationId === "INWEIGHT" ||
        x.fieldOperationId === "SCALE_LOADINGV1"
    );
    if (found) {
      return false;
    }
    return true;
  };

  const onKeyboardButtonClick = () => {
    setOrderInternalERPError([]);
    _setErorMessages([]);
    if (!pageData.order) {
      _createErrorMessage("2");
      return;
    }
    _getOrderInternalERP(
      {
        data: {
          id: pageData.order,
          locationId: _stationInfo.locationId,
        },
      },
      ({ data }) => {
        if (!data) {
          return;
        }
        if (data?.locationId != _stationInfo.locationId) {
          _createErrorMessage("126", _stationInfo.locationId);
          return;
        }
        navigate(
          `/TerminalActivity/ShipDocRefOrder/${pageData.order}/${fieldProcessId.current}`
        );
      }
    );
  };
  const onChangeKeyboard = (e) => {
    let value = e?.target?.value;
    let name = e?.target?.name;
    const newValueObject = { [name]: value };
    setPageData((current) => {
      return { ...current, ...newValueObject };
    });
  };
  useEffect(() => {
    keyboardField.current.focus();
  }, []);
  if (_stationInfo.stationTypeId === "Check-in") {
    hideTopButtons.current = true;
  }

  const operationLoading = _loadingSearchShipDoc || _loadingInternalERP;
  return (
    <div className="container-fluid ">
      <LoadingContext.Provider value={contextState}>
        {operationLoading && <LoadingScreen />}

        {hideTopButtons.current === false ? (
          <div className="orderBtn">
            <Button
              onClick={getRelevantShipDoc}
              color="primary"
              data-report="CHECKIN"
              disabled={checkedShipDocsButtonStatus()}
            >
              Checked-in BOLs
            </Button>
            <Button
              onClick={getRelevantShipDoc}
              color="primary"
              data-report="INWEIGHT"
              disabled={inWeightShipDocsButtonStatus()}
            >
              In Weight Captured BOLs
            </Button>
          </div>
        ) : null}

        <KeyboardButtonInput
          //          cardlogo={cardlogo}
          keyboardInputProps={{
            value: pageData.order ?? "",
            placeholder: "Order id here...",
            onChange: onChangeKeyboard,
            onEnterPress: onKeyboardButtonClick,
            name: "order",
            keyboardField: keyboardField,
            fieldType: "number",
            defaultKeyboard: "number",
            actionButton: (
              <Button
                className="btn btn-primary"
                style={{
                  width: "25rem",
                  height: 70,
                  fontSize: "20px",
                  background: "#08bd55",
                  borderColor: "#08bd55",
                }}
                onClick={onKeyboardButtonClick}
              >
                Proceed with Order
                <span className="arrow">
                  <img src={buttonarrow} alt="" />
                </span>
              </Button>
            ),
          }}
          headerLabel={"Load with order"}
        />
        <MessageLog
          errorMessages={[..._orderInternalERPError, ..._errorMessages]}
          successMessages={[]}
          clearMessages={clearMessages}
        ></MessageLog>

        <ListModal
          setId={handleShipDocSelect}
          dataList={_serachShipDocData}
          showListModal={showShipDocList}
          setShowModal={setShowShipDocList}
          title="List Of BOL for OutWeight"
          columns={[
            { field: "id", headerName: "BOL ID", width: 150 },
            { field: "driverDescriptor", headerName: "Driver", width: 250 },
            {
              field: "materialDescriptor",
              headerName: "Material",
              width: 250,
            },
            { field: "startDT", headerName: "Start DT", width: 250 },
            { field: "inWeight", headerName: "In-Weight", width: 200 },
            { field: "outWeight", headerName: "Out-Weight", width: 200 },
          ]}
        />
      </LoadingContext.Provider>
    </div>
  );
}

export default StartLoadingWithOrder;
