import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput";
import OverviewButtons from "./Components/OverviewButtons";
import { useFetch } from "../../hooks/useFetch";
import { useMessage } from "../../hooks/UseMessage";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../CommonComponents/CircleButton";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";

function Index() {
  let navigate = useNavigate();

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [invoice, setInvoice] = useState();

  const [showInvoiceList, setShowInvoiceList] = useState(false);

  const [
    _getInvoiceList,
    _invoiceList,
    ,
    ,
    ,
    ,
    ,
    _loadingInvoiceList,
  ] = useFetch(GetFullURL("InvoiceList"), { method: "POST" });

  const [_getInvoiceExists, , , , , , , _loadingInvoiceExist] =
    useFetch(GetFullURL("InvoiceExists"), { method: "POST" });


  const operationLoading =
    _loadingInvoiceList ||
    _loadingInvoiceExist ;

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../Assets/icon/invoice.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Invoice Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setInvoice}
                    document={invoice}
                    placeholder="Invoice id here..."
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`Invoices`}
                    clickHandler={() => {
                      _getInvoiceList({}, () => {
                        setShowInvoiceList(true);
                      });
                    }}
                  />
                  <OverviewButtons
                    invoice={invoice}
                    navigate={navigate}
                    _getInvoiceExists={_getInvoiceExists}
                    _createErrorMessage={_createErrorMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setInvoice}
              dataList={_invoiceList}
              showListModal={showInvoiceList}
              setShowModal={setShowInvoiceList}
              title="List Of Inventory Documents"
              columns={[
                { field: "id", headerName: "Invoice ID", width: 250 },
                { field: "invoiceDate", headerName: "Invoice Date", width: 250 },
                { field: "locationId", headerName: "Location", width: 350 },
                { field: "soldtoId", headerName: "Sold To", width: 200 },
                { field: "createdBy", headerName: "Created By", width: 200 },
              ]}
            />
          </div>
        </div>
        <MessageLog
          errorMessages={[..._errorMessages]}
          successMessages={[]}
          warningMessages={[]}
          clearMessages={clearMessages}
        ></MessageLog>
      </div>
    </section>
  );
}

export default Index;
