import React from "react";
export default function GridHeader({ fields }) {
  return (
    <thead>
      <tr>
        {/* Create Table Headings */}
        {fields.map((field, i) => {
          return (
            <th key={i} className="GridColumnHeading">
              {field.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
