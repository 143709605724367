/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput";
import OverviewButtons from "./Components/OverviewButtons";
import { useFetch } from "../../hooks/useFetch";
import { useMessage } from "../../hooks/UseMessage";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../CommonComponents/CircleButton";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";

function Index() {
  let navigate = useNavigate();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [shippingDoc, setShippingDoc] = useState();
  const [showShippingDocList, setShowShippingDocList] = useState(false);

  const [
    _getShippingDocList,
    _shippingDocList,
    ,
    ,
    ,
    ,
    ,
    _loadingtShippingDocList,
  ] = useFetch(GetFullURL("ShippingDocList"), { method: "POST" });

  const [_getShippingDocExists, , , , , , , _loadingShippingDocExist] =
    useFetch(GetFullURL("ShippingDocExists"), { method: "POST" });

  const operationLoading = _loadingtShippingDocList || _loadingShippingDocExist;

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }
  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../Assets/icon/shipping.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Bill-Of-Lading Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setShippingDoc}
                    document={shippingDoc}
                    placeholder="BOL Id here..."
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of BOLs`}
                    clickHandler={() => {
                      _getShippingDocList({}, () => {
                        setShowShippingDocList(true);
                      });
                    }}
                  />
                  <OverviewButtons
                    shippingDocument={shippingDoc}
                    navigate={navigate}
                    _getShippingDocExists={_getShippingDocExists}
                    _createErrorMessage={_createErrorMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setShippingDoc}
              dataList={_shippingDocList}
              showListModal={showShippingDocList}
              setShowModal={setShowShippingDocList}
              title="List Of Shipping Documents"
              columns={[
                { field: "id", headerName: "BOL", width: 100 },
                { field: "statusDescriptor", headerName: "BOL Status", width: 250 },
                { field: "shipDocDate", headerName: "BOL Date", width: 190 },
                { field: "startDT", headerName: "BOL Start DT", width: 190 },
                { field: "endDT", headerName: "BOL End DT", width: 190 },
                { field: "shiptoDescriptor", headerName: "Ship To", width: 350,},
                { field: "soldtoDescriptor", headerName: "Sold To", width: 350,},
                { field: "inWeight", headerName: "In-Weight", width: 100,},
                { field: "netWeight", headerName: "Net-Weight", width: 100, },
                { field: "outWeight",headerName: "Out-Weight", width: 100, },
                { field: "pin", headerName: "Driver Pin", width: 100 },
                { field: "driverDescriptor", headerName: "Driver", width: 250,},
                { field: "carrierDescriptor", headerName: "Carrier", width: 250 },
                { field: "vehicleId", headerName: "Vehicle", width: 100 },
                { field: "trailerId", headerName: "Trailer", width: 100 },
               { field: "materialDescriptor", headerName: "Material",  width: 350,},
              ]}
            />
          </div>
        </div>
        <MessageLog
          errorMessages={[..._errorMessages]}
          successMessages={[]}
          warningMessages={[]}
          clearMessages={clearMessages}
        ></MessageLog>
      </div>
    </section>
  );
}

export default Index;
