import Card from '@mui/material/Card';
import React, { useMemo, useState, useEffect, useRef } from "react";
import { keyboardArray, numpadArray } from "./buttons";
import "./index.scss";
import "./Calculator.css";
export default function Keybaord({
  setInputValue,
  inputValue,
  fieldType,
  handleOKButton,
  defaultKeyboard,
}) {
  const [isNumpad, setIsNumpad] = useState(false);
  const className = isNumpad ? "numpad-wrapper" : "full-keyboard-wrapper";
  const numberPad = useRef([]);

  useEffect(() => {
    numberPad.current = [...numpadArray];

    if (fieldType === "number") {
      //if the field type is number then set the number pad and disable the A-Z button
      if (!isNumpad) {
        setIsNumpad(!isNumpad);
      }
      //remove the "A-Z" from number pad if field is number
      let index = numberPad.current.findIndex((x) => x?.text === "A-Z");
      if (index) {
        delete numberPad.current[index];
      }
      //the "-" was added to number pad, however it applicable when the field type is text only, the field type
      //is number then we must remove it.
      let index1 = numberPad.current.findIndex((x) => x?.text === "-");
      if (index1) {
        delete numberPad.current[index1];
      }
      return;
    }

    switch (defaultKeyboard) {
      case "text":
        if (isNumpad) {
          setIsNumpad(!isNumpad);
        }
        // code block
        break;
      case "number":
        if (!isNumpad) {
          setIsNumpad(!isNumpad);
        }
        break;
      default:
      // code block
    }
  }, [defaultKeyboard, fieldType]);

  const renderedArray = useMemo(
    () => (isNumpad ? numberPad.current : keyboardArray),
    [isNumpad]
  );

  const toggleKeyboardType = () => {
    setIsNumpad(!isNumpad);
  };

  const handleClick = (elem) => {
    let newValue;

    if (elem.text === "Ok") {
      handleOKButton();
      return;
    }

    if (fieldType == "number" || isNumpad) {
      newValue = inputValue.toString() + elem.text;
    } else {
      newValue = inputValue + elem.text;
    }
    elem.onClick
      ? elem.onClick({
          setInputValue,
          inputValue,
          toggleKeyboardType,
          fieldType,
        })
      : setInputValue(newValue);
  };
  return (
    <div className="keyboard-wrapper">
      <Card className={"keyboarb-input " + className}>
        {renderedArray.map((elem, index) => (
          <button onClick={() => handleClick(elem)} key={index}>
            {elem.text}
          </button>
        ))}
      </Card>
    </div>
  );
}
