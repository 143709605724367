exports.OutboundProcedureDetail = [
    {
      id: "id",
      name: "id",
      type: "text",
      value: "",
      label: "Id",
      key: "true",
      length: 25,
      displayonly:true,
    },
    {
        id: "procedureId",
        name: "procedureId",
        type: "text",
        value: "",
        label: "Procedure Id",
        key: "true",
        length: 12,
        ddid:"outboundProcedureId",
      },
      {
        id: "stepId",
        name: "stepId",
        type: "text",
        value: "",
        label: "Step Id",
        key: "true",
        length: 12,
        ddid:"outboundStepId",
      },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      value: "",
      label: "Universal Descriptor",
      length: 50,
    },
    {
      id: "counter",
      name: "counter",
      type: "number",
      value: "",
      label: "Counter",
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "datetime",
      label: "ModifiedOn",
      displayonly: "true",
      value: " ",
    },
  ];
  