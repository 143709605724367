import React, { useContext, useState } from "react";
import UniversalEditableGrid from "../../../../CommonComponents/Grid/EditableGrid/UniversalEditableGrid";
import { ShipDocContext } from "../Contex";
import { itemGridFields } from "./ItemGridFields";
import Button from "react-bootstrap/Button";
import * as _ from "underscore";
import ItemDetails from "./ItemDetails/Index";
function Items(props) {
  const { _shipDocData, operation, _setShipDocData, dropDowns , setDisplayMainTab } =
    useContext(ShipDocContext);
  const [showItemsDetailsModal, setShowItemsDetailsModal] = useState(false);
  const [itemOperation, setItemOperation] = useState();
  const [item, setItem] = useState({});

  const addNewItemClick = () => {
    let maxItemNumber = 0;
    let itemNumbers = [];

    if (_shipDocData.shippingDocItemsComm != null) {
      _shipDocData.shippingDocItemsComm.forEach((arrayItem) => {
        itemNumbers.push(arrayItem.itemNumber);
      });
      maxItemNumber = _.max(itemNumbers);
    }

    if (maxItemNumber === -Infinity || maxItemNumber === 0) {
      maxItemNumber = 10;
    } else {
      maxItemNumber = maxItemNumber + 10;
    }

    let newItemObject = {
      itemNumber: maxItemNumber,
    };
    setItemOperation("Create");
    setShowItemsDetailsModal(true);
    setItem({ ...newItemObject });
    setDisplayMainTab(true);
  };
  //Grid functions handler
  function handleButtonClickGrid(e) {
    debugger;
    let selectedItemNumber = e.target.dataset.id;
    let gridButtonOperation = e.target.dataset.op;
    let result;
    let currentItems = _shipDocData.shippingDocItemsComm;

    setItemOperation(gridButtonOperation);
    switch (gridButtonOperation) {
      case "Display":
      case "Change":
        result = _shipDocData.shippingDocItemsComm.find(
          ({ itemNumber }) => +itemNumber === +selectedItemNumber
        );
        setItem(result);
        setShowItemsDetailsModal(true);
        break;
      case "Delete":
        currentItems = currentItems.filter(
          (x) => +x.itemNumber !== +selectedItemNumber
        );
        _setShipDocData((current) => {
          return { ...current, shippingDocItemsComm: currentItems };
        });
        break;

      default:
    }
  }
  //Acccpte chnages from item details window
  const handleAccept = () => {
    if (itemOperation === "Create") {
      _setShipDocData((current) => {
        let items = current.shippingDocItemsComm || [];

        items.push(item);
        return { ...current, shippingDocItemsComm: items };
      });
    } else {
      _setShipDocData((current) => {
        let items = current.shippingDocItemsComm;

        var index = items.findIndex((x) => x.itemNumber === item.itemNumber);
        if (index >= 0) {
          items[index] = item;
        }
        return { ...current, shippingDocItemsComm: items };
      });
    }
  };
  //Update main state, update item data
  const onChangeItem = (e) => {

    let item;
    let fieldTypeItem;
    const {
      target: { value, name },
    } = e;

    item = e.target.dataset.item;
    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeItem = e.target.dataset.type;

    let newValue;
    switch (fieldTypeItem) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    let newItems = _shipDocData.shippingDocItemsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.itemNumber == item) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setShipDocData((prevState) => {
      return { ...prevState, shippingDocItemsComm: newItems };
    });
  };

  if (showItemsDetailsModal) {
    return (
      <div className="mt-4">
        <ItemDetails
          setShowItemsDetailsModal={setShowItemsDetailsModal}
          itemOperation={itemOperation}
          item={item}
          setItem={setItem}
          handleAccept={handleAccept}
        />
      </div>
    );
  }
  return (
    <div>
      <Button
        variant="primary"
        active="true"
        data-op="Create"
        disabled={operation === "Display"}
        onClick={
          addNewItemClick
        }
        style={{marginTop:"10px"}}
      >
        Add New Item
      </Button>

      <UniversalEditableGrid
        fields={itemGridFields}
        headerData={_shipDocData}
        itemData={_shipDocData?.shippingDocItemsComm ?? []}
        dropDowns={dropDowns}
        operation={operation}
        onChange={onChangeItem}
        handleButtonClickGrid={handleButtonClickGrid}
        ActionColumn={true}
      />
    </div>
  );
}

export default Items;
