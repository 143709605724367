import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { useFetch } from "../../../hooks/useFetch";
import { useMessage } from "../../../hooks/UseMessage";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import OrderSummary from "../Components/OrderSummary/OrderSummary";
import { LoadingContext } from "../Context";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import Cookies from "js-cookie";
import ListModal from "../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import KeyboardInput from "../../../CommonComponents/keybaords/KeyboardButtonInput";
import { useSelector } from "react-redux";

function StartLoadingWithTerminalSetup(props) {
  const [usedStation, setUsedStation] = useState();
  const [order, setOrder] = useState();
  const [shipDoc, setShipDoc] = useState();
  const [cookie, setCookie] = useState();
  const [showOrderList, setShowOrderList] = useState(false);

  const userState = useSelector((state) => state.User.user);

  const language = userState.preferredLanguage;
  const userStation = userState.stationId;

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [
    _getOrderList,
    _orderList,
    _setOrderList,
    _errorOrderList,
    _setRrrorOrderList,
    _successOrderList,
    _setSuccessOrderList,
    _loadingtOrderList,
  ] = useFetch(GetFullURL("OrderList"), { method: "POST" });

  const [
    _getTRMSetup,
    _TRMSetupData,
    _setTRMSetupData,
    _errorTRMSetup,
    _setErrorTRMSetup,
    _successTRMSetup,
    _setSuccessTRMSetup,
    _loadingTRMSetup,
  ] = useFetch(GetFullURL("GetTRMSetup"), { method: "POST" });

  const [
    _getStationData,
    _stationData,
    ,
    _errorStationData,
    _setErrorStationData,
    _successStationData,
    _setSuccessStationData,
    _loadingStationData,
  ] = useFetch(GetFullURL("GetStation"), { method: "POST" });

  const operationLoading =
    _loadingtOrderList || _loadingTRMSetup || _loadingStationData;
  let storedCookie;

  useEffect(() => {
    let usedStation = "";
    storedCookie = Cookies.get("station");

    if (!userStation) {
      if (!storedCookie) {
        _createErrorMessage("1");
        return;
      }
    }

    usedStation = storedCookie ? storedCookie : userStation;

    setUsedStation(usedStation);

    _getStationData({ data: { id: usedStation } });
  }, []);

  //Use the context and pass the data, dropdowns
  const contextState = {
    _TRMSetupData,
    _stationData,
    station: cookie,
  };

  function displayOrderSummary() {
    if (_TRMSetupData?.id != null) {
      return true;
    } else {
      return false;
    }
  }

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessTRMSetup([]);
        _setSuccessStationData([]);
        _setSuccessOrderList([]);

        break;
      case "error":
        _setErrorTRMSetup([]);
        _setErrorStationData([]);
        _setRrrorOrderList([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }
  return (
    <div className="container-fluid ">
      <LoadingContext.Provider value={contextState}>
        {operationLoading && <LoadingScreen />}

        <Button
          onClick={() => {
            _getOrderList({}, () => {
              setShowOrderList(true);
            });
          }}
          color="primary"
          variant="outlined"
        >
          Load Orders
        </Button>

        <div className="d-flex flex-column" style={{ gap: "4rem" }}>
          <div className="row m-0">
            <div
              className="card shadow-sm mb-2 p-4 col-12"
              style={{
                display: "grid",
                gridTemplateColumns: "3fr 1fr",
                gridGap: "1.5rem",
                minHeight: "16vh",
              }}
            >
              <KeyboardInput
                value={order ?? ""}
                setValue={setOrder}
                placeholder="NEW BOL, Type Profile id here..."
                onChange={setOrder}
              />
              <Button
                className="w-100 h-100"
                color="primary"
                variant="contained"
                onClick={() => {
                  if (!order) {
                    _createErrorMessage("2");
                    return;
                  }

                  _getTRMSetup(
                    {
                      data: {
                        Id: order,
                        LocationId: _stationData?.locationId,
                        StationId: _stationData?.id,
                        FieldProcessId: "SETUP_LOAD",
                      },
                    },

                    ({ data: newShipDocData }) => {}
                  );
                }}
              >
                Start New BOL
              </Button>
            </div>
          </div>
          <div className="row m-0">
            <div
              className="card shadow-sm mb-2 p-4 col-12"
              style={{
                display: "grid",
                gridTemplateColumns: "3fr 1fr",
                gridGap: "1.5rem",
                minHeight: "16vh",
              }}
            >
              <KeyboardInput
                value={order ?? ""}
                setValue={setShipDoc}
                placeholder="EXISTING BOL, Type BOL Number here..."
                onChange={setShipDoc}
              />
              <Button
                className="w-100 h-100"
                color="primary"
                variant="contained"
                onClick={() => {
                  if (!order) {
                    _createErrorMessage("2");
                    return;
                  }

                  _getTRMSetup(
                    {
                      data: {
                        Id: order,
                        LocationId: _stationData?.locationId,
                        StationId: _stationData?.id,
                        FieldProcessId: "SETUP_LOAD",
                      },
                    },

                    ({ data: newShipDocData }) => {}
                  );
                }}
              >
                Existing BOL
              </Button>
            </div>
          </div>
        </div>
        <div>{displayOrderSummary() ? <OrderSummary /> : null}</div>
        <MessageLog
          errorMessages={[
            ..._errorTRMSetup,
            ..._errorStationData,
            ..._errorOrderList,
            ..._errorMessages,
          ]}
          successMessages={[
            ..._successTRMSetup,
            ..._successStationData,
            ..._successOrderList,
          ]}
          clearMessages={clearMessages}
        ></MessageLog>
        <ListModal
          setId={setOrder}
          dataList={_orderList}
          showListModal={showOrderList}
          setShowModal={setShowOrderList}
          title="List Of Orders"
          columns={[
            { field: "id", headerName: "Customer ID", width: 250 },
            { field: "orderDate", headerName: "Type", width: 250 },
            { field: "locationId", headerName: "Name", width: 350 },
            { field: "soldtoId", headerName: "Name", width: 350 },
            { field: "shiptoId", headerName: "City", width: 200 },
            { field: "carrierId", headerName: "Name", width: 350 },
            { field: "createdBy", headerName: "City", width: 200 },
            { field: "createdOn", headerName: "City", width: 200 },
          ]}
        />
      </LoadingContext.Provider>
    </div>
  );
}

export default StartLoadingWithTerminalSetup;
