import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useFetch } from "../../../../../hooks/useFetch";
import { useMessage } from "../../../../../hooks/UseMessage";

import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../../CommonComponents/GlobalConfFiles/URLs";

import { ReactComponent as TruckandScale } from "../../../../../Assets/SVGs/TruckAndScale.svg";
import { ReactComponent as OverLoadAlram } from "../../../../../Assets/SVGs/Alrams/AnimatedAlram1.svg";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import MessageLog from "./../../../../../CommonComponents/Messages/MessageLog";
import HeaderV2 from "../../Header/HeaderV2";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { FlipCounter } from "../../../../../CommonComponents/FlipCounter/FlipCounter";
import TrafficLight from "../../../../../CommonComponents/TrafficLights/Components/TrafficLightSet";
import TruckAnimation from "../../../../../CommonComponents/TruckAnimation/index";
import { useParams } from "react-router-dom";
import MTDataInfoSIdeBar from "./MTDataInfoSIdeBar";

function WeightBasedLoadingMT(props) {
  const navigate = useNavigate();
  const params = useParams();
  const shipDoc = params.shipDoc;

  const [weight, setWeight] = useState(null);
  const [loading, setLoading] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [topLog, setTopLog] = useState("");
  const [entryLights, setEntryLights] = useState({
    activeLight: 1,
    blinking: false,
  });
  const [exitLights, setExitLights] = useState({
    activeLight: 0,
    blinking: false,
  });

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    ,
    ,
    ,
    _createSuccessMessage,
    _successMessages,
    _setSuccessMessages,
  ] = useMessage();

  const [frondendSuccess, setFrontendSuccess] = useState([]);

  const [
    _startLoad,
    _startLoadData,
    _setStartLoadData,
    _startLoadError,
    _setStartLoadError,
    _startLoadSuccess,
    _setStartLoadSuccess,
    _loadingStartLoad,
  ] = useFetch(
    GetServiceFullURL("startLoad"),
    {
      method: "POST",
      headers: {},
    },
    true
  );

  const [_stopLoad, _stopLoadData, _setStopLoadData, , , , , _loadingStopLoad] =
    useFetch(GetServiceFullURL("stopLoad"), {
      method: "POST",
      headers: {},
    });

  const [
    _getMTData,
    _mTData,
    ,
    _mTDataError,
    _setMTDataError,
    _mTDataSuccess,
    _setMTDataSuccess,
    ,
  ] = useFetch(
    GetServiceFullURL("getCurrentMTData"),
    {
      method: "POST",
      headers: {},
    },
    true
  );

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    _successShipDoc,
    _setSuccessShipDoc,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" });

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" });

  function GetMTServiceData() {
    _getMTData({}, (response) => {
      setTopLog(() => {
        return { success: response.success, message: response.message };
      });
      if (response.success) {
        //Set Weight
        setWeight(response.data.currentWeight);
      }
    });
  }

  useEffect(() => {
    _getShipDoc(
      { data: { Id: shipDoc } },

      ({ data: shipDocData }) => {
        if (shipDocData.inWeight > 0) {
          _createErrorMessage("9");
        }

        if (shipDocData.outWeight > 0) {
          _createErrorMessage("10");
        }
      }
    );
  }, []);

  useEffect(() => {
    let mTServiceTimer = setInterval(GetMTServiceData, 2000);
    return () => {
      clearInterval(mTServiceTimer);
    };
  }, []);

  useEffect(() => {
    if (finishedLoading) {
      var leaveTimer = setTimeout(() => {
        navigate(`/TerminalActivity/EldoCokeScaleStationHome`);
      }, 10000);
    }
    return () => {
      clearTimeout(leaveTimer);
    };
  }, [finishedLoading]);

  //Monitor in Weight when value changes, update shipDoc
  useEffect(() => {
    //continuously check  weight value in order to auto stop when max weight is reached
    if (_mTData.inWeight > 0) {
      let newShipData = _shipDocData;
      if (newShipData.id != null) {
        newShipData.inWeight = _mTData.inWeight;
        newShipData.inWeightStatus = "X";
        newShipData.inWeightStDT = moment().format();
        newShipData.inWeightEnDT = moment().format();
        newShipData.statusId = "INWGHTSHDOC";
        newShipData.loadUnloadinProcess = true;
        _saveShipDoc({ data: newShipData }, ({ data: savedShipDocData }) => {
          _setShipDocData(savedShipDocData);
        });
      }
    }
  }, [_mTData.inWeightTaken]);

  //Monitor out Weight when value changes, update shipDoc
  useEffect(() => {
    if (_mTData.outWeight > 0) {
      let newShipData = _shipDocData;
      if (newShipData.id != null) {
        newShipData.outWeight = _mTData.outWeight;
        newShipData.outWeightStatus = "X";
        newShipData.outWeightStDT = moment().format();
        newShipData.outWeightEnDT = moment().format();
        newShipData.loadEnDT = moment().format();
        newShipData.loadStatus = "X";
        newShipData.statusId = "OUTWGHTSHDOC";
        newShipData.loadUnloadinProcess = false;
        newShipData.signatureReady = true;
        _saveShipDoc({ data: _shipDocData }, ({ data: savedShipDocData }) => {
          _setShipDocData(savedShipDocData);
          setFinishedLoading(true);
        });
      }
    }
  }, [_mTData.outWeightTaken]);

  useEffect(() => {
    if (_mTData.entryGreenLight === "Flashing") {
      setEntryLights({ activeLight: 1, blinking: true });
    } else if (_mTData.entryGreenLight === "On") {
      setEntryLights({ activeLight: 1, blinking: false });
    }

    if (_mTData.entryRedLight === "Flashing") {
      setEntryLights({ activeLight: 0, blinking: true });
    } else if (_mTData.entryRedLight === "On") {
      setEntryLights({ activeLight: 0, blinking: false });
    }
  }, [_mTData.entryGreenLight, _mTData.entryRedLight]);

  useEffect(() => {
    if (_mTData.exitGreenLight === "Flashing") {
      setExitLights({ activeLight: 1, blinking: true });
    }
    if (_mTData.exitGreenLight === "On") {
      setExitLights({ activeLight: 1, blinking: false });
    }

    if (_mTData.exitRedLight === "Flashing") {
      setExitLights({ activeLight: 0, blinking: true });
    }

    if (_mTData.exitRedLight === "On") {
      setExitLights({ activeLight: 0, blinking: false });
    }
  }, [_mTData.exitGreenLight, _mTData.exitRedLight]);

  useEffect(() => {
    calculateFillPercent();
  }, [weight]);

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessShipDoc([]);
        setFrontendSuccess([]);
        _setSuccessSaveShipDoc([]);
        _setStartLoadSuccess([]);
        _setMTDataSuccess([]);
        break;
      case "error":
        _setErrorSaveShipDoc([]);
        _setErorMessages([]);
        _setErrorShipDoc([]);
        _setStartLoadError([]);
        _setMTDataError([]);
        break;
      default:
        break;
    }
  }

  const operationLoading =
    _loadingShipDoc ||
    _loadingSaveShipDoc ||
    _loadingStartLoad ||
    _loadingStopLoad;

  const onClickStartStop = () => {
    if (!loading) {
      if (_shipDocData.id == null) {
        _createErrorMessage("13");
        return;
      }
      //Before starting check in cutt-off weight is populated
      if (
        _shipDocData.cutoffWeight == null ||
        _shipDocData.cutoffWeight === 0 ||
        _shipDocData.cutoffWeight == ""
      ) {
        _createErrorMessage("15");
        return;
      }
      //Before stating check if shipDoc is populated
      if (shipDoc == null || shipDoc == "") {
        _createErrorMessage("16");
        return;
      }

      _startLoad(
        {
          data: {
            shipDoc: shipDoc,
            midWeight: _shipDocData.midWeight,
            ScaleMode: "Loading",
            cutoffWeight: _shipDocData.cutoffWeight,
          },
        },
        (response) => {
          setTopLog(() => {
            return { success: response.success, message: response.message };
          });
          if (response.success) {
            setLoading(!loading);

            return;
          }
        }
      );

      //After staring load, set the loading started
      if (_shipDocData.id != null) {
        _shipDocData.loadStDT = moment().format();
        _shipDocData.loadUnloadinProcess = true;
        _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {});
      }
    } else {
      _stopLoad({}, (response) => {
        setTopLog((current) => {
          return [...response.message];
        });
        if (response.success) {
          setLoading(!loading);
          return;
        }
      });
    }
  };

  const calculateFillPercent = () => {
    //if max weight value is 0 set % to zero because we can not divid by 0
    if (_shipDocData.maxWeight === 0) {
      return 0;
    }

    //if in weight value has not been taken ie we have not started loading, then set % to 0
    if (!_mTData.inWeightTaken) {
      return 0;
    }

    let currentLoadedQty = weight - _mTData.inWeight;
    let fullLoad = _shipDocData.maxWeight - _mTData.inWeight;

    let percentLoaded = (currentLoadedQty * 100) / fullLoad;

    return percentLoaded.toFixed(2);
  };
  let error = "bg-danger";
  let success = "bg-success";
  let errorClass = `w-100 rounded-lg px-5 py-3 ${error} d-flex align-items-center justify-content-center`;
  let successClass = `w-100 rounded-lg px-5 py-1 ${success} d-flex align-items-center justify-content-center`;
  return (
    <>
      <section>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}

          <div className="row px-3">
            <div className="col-12 p-0">
              <HeaderV2 _shipDocData={_shipDocData} weightType={null} />
            </div>
          </div>

          <div className="row px-3">
            <div className="col-12 p-0">
              {topLog.success ? (
                <div className="col-12">
                  <div className={successClass}>
                    <span className="text-white"> {topLog.message}</span>
                  </div>
                </div>
              ) : (
                <div className="col-12">
                  <div className={errorClass}>
                    <span className="text-white"> {topLog.message}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-sm-12 col-xs-12">
              <MTDataInfoSIdeBar _mTData={_mTData} />
            </div>

            <div className="col-lg-9 col-sm-12 col-xs-12">
              <div className="" style={{ flex: 1 }}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="h-50">
                    <TrafficLight
                      enabledIndex={exitLights.activeLight}
                      blinking={exitLights.blinking}
                    />
                  </div>
                  <div className="mx-3" style={{ width: "85%" }}>
                    <TruckAnimation
                      compartCount={1}
                      activeIndex={0}
                      fillPerCent={calculateFillPercent()}
                    />
                  </div>
                  <div className="h-50">
                    <TrafficLight
                      enabledIndex={entryLights.activeLight}
                      blinking={entryLights.blinking}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <FlipCounter number={weight} className="mb-5" />
                  <div className="d-flex" style={{ gap: "1rem" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      className="px-5 py-3 mt-5 w-50"
                      onClick={() => {
                        if (loading) {
                          _createErrorMessage("39");
                          return;
                        }

                        navigate(`/ELDOScaleOperations`);
                      }}
                    >
                      Finish
                    </Button>
                    <Button
                      color={loading ? "secondary" : "primary"}
                      variant="contained"
                      className="px-5 py-3 mt-5 w-50"
                      onClick={onClickStartStop}
                    >
                      {loading ? "STOP" : "START LOADING  "}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <MessageLog
            errorMessages={[
              ..._errorShipDoc,
              ..._errorMessages,
              ..._errorSaveShipDoc,
              ..._startLoadError,
              ..._mTDataError,
            ]}
            successMessages={[
              ..._successShipDoc,
              ..._successSaveShipDoc,
              ...frondendSuccess,
              ..._mTDataSuccess,
              ..._startLoadSuccess,
            ]}
            clearMessages={clearMessages}
          ></MessageLog>
        </div>
      </section>
    </>
  );
}

export default WeightBasedLoadingMT;
