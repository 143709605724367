import React, { useState, useEffect } from "react";
import PartnerAssignment from "./PartnerAssignment/Index";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import HomeTab from "./Main/index";
import AddressTab from "./Address/index";
import AdminTab from "./Admin/index";
import ShippingTab from "./Shipping/index";
import PartnerHeader from "./Header";
import Outbound from "./Outbound/Outbound";
import { PartnerContext } from "./context";
import { useFetch } from "./../../../hooks/useFetch";
import { useMessage } from "./../../../hooks/UseMessage";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen";
import { GetFullURL } from "./../../../CommonComponents/GlobalConfFiles/URLs";
import TabPannel from "../../../Business Processes/Components/TabPannel";
import { useParams } from "react-router-dom";
import { useUser } from "src/hooks/useUser";

const PartnerMain = (props) => {
  const [value, setValue] = React.useState(0);
  const [operation, setOperation] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [partnerType, setPartnerType] = useState("");
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [_user, ,] = useUser();
  const params = useParams();

  const [
    _getPartnerData,
    _partnerData,
    _setPartnerData,
    _errorPartnerData,
    _setErrorPartnerData,
    _successPartnerData,
    _setSuccessPartnerData,
    _loadingPartnerData,
  ] = useFetch(GetFullURL("GetPartner"), { method: "POST" });

  const [
    _validatePartner,
    _validatePartnerData,
    ,
    _errorValidatePartner,
    _setErrorValidatePartner,
    ,
    ,
    _loadingValidatePartner,
  ] = useFetch(GetFullURL("ValidatePartner"), { method: "POST" });
  const [
    _getPartnerType,
    _partnerTypeData,
    ,
    _errorPartnerType,
    _setErrorPartnerType,
    ,
    ,
    _loadingPartnerType,
  ] = useFetch(GetFullURL("GetPartnerTypeExtended"), { method: "POST" });

  const [
    _getPartnerDD,
    _partnerDD,
    ,
    _errorPartnerDD,
    _setErrorPartnerDD,
    _successPartnerDD,
    _setSuccessPartnerDD,
    _loadingtPartnerDD,
  ] = useFetch(GetFullURL("PartnerDropDown"), { method: "POST" });

  const [
    _getPartnersList,
    _partnersList,
    _setPartnerList,
    _errorPartnerList,
    _SetErrorPartnerList,
    _successPartnerList,
    _setSuccessPartnerList,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("PartnerList"), { method: "POST" });

  const [
    _savePartner,
    ,
    ,
    _errorSavePartner,
    _setErrorSavePartner,
    _successSavePartner,
    _setSuccessSavePartner,
    _loadingSaveData,
  ] = useFetch(GetFullURL("SavePartner"), { method: "POST" });
  useEffect(() => {
    //when componen mounts,set ID and operation
    if (params.op) {
      setOperation(params.op);
    }

    if (params.id) {
      setPartnerId(params.id);
    }

    if (params.partnerType) {
      setPartnerType(params.partnerType);
      _setPartnerData((prevState) => {
        return { ...prevState, partnerTypeId: params.partnerType };
      });
      _getPartnerType({ data: { id: params.partnerType } });
    }

    if (_user?.singleTerminalUser === "true") {
      _setPartnerData((current) => ({
        ...current,
        locationId: _user?.defaultLocationId,
      }));
    }
  }, []);

  useEffect(() => {
    _getPartnerDD();

    if (partnerId) _getPartnerData({ data: { id: partnerId } });
  }, [partnerId]);

  const handleChange = (event, newValue) => setValue(newValue);

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;
    let newValue;

    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = "true") : (newValue = "false");
        break;

      default:
        newValue = value;
        break;
    }

    //for select options, if the value equals select then ignore it
    if (newValue === "SELECT" || newValue === "select") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    //Set the update operarion

    _setPartnerData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  //Use the context and pass the data, dropdowns
  const contextState = {
    onChange,
    operation,
    dropDowns: _partnerDD || [],
    partnerId,
    _partnerData,
    _partnerDD,
    _setPartnerData,
    _partnersList,
    partnerType,
    setOperation,
    setPartnerId,
    _createErrorMessage,
    _user,
    _validatePartner,
    _validatePartnerData,
    _partnerTypeData,
    _getPartnersList,
  };

  const operationLoading =
    _loadingPartnerData ||
    _loadingPartnerList ||
    _loadingtPartnerDD ||
    _loadingSaveData ||
    _loadingValidatePartner;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSavePartner([]);
        _setSuccessPartnerData([]);
        _setSuccessPartnerDD([]);
        _setSuccessPartnerList([]);
        break;
      case "error":
        _setErrorPartnerData([]);
        _setErrorPartnerDD([]);
        _SetErrorPartnerList([]);
        _setErrorSavePartner([]);
        _setErorMessages([]);
        _setErrorValidatePartner([]);
        break;
      default:
        break;
    }
  }

  return (
    <PartnerContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <PartnerHeader
        _loadingSaveData={_loadingSaveData}
        _savePartner={_savePartner}
      />
      <TabPannel
        value={value}
        handleChange={handleChange}
        key="test"
        labels={[
          "Home",
          "Address",
          "Outbound",
          "Partner Assigment",
          "Admin",
          "Shipping",
          "Log",
        ]}
        components={[
          HomeTab,
          AddressTab,
          Outbound,
          PartnerAssignment,
          AdminTab,
          ShippingTab,
        ]}
      />
      <MessageLog
        errorMessages={[
          ..._errorPartnerDD,
          ..._errorPartnerData,
          ..._errorPartnerList,
          ..._errorSavePartner,
          ..._errorMessages,
          ..._errorValidatePartner,
        ]}
        successMessages={[
          ..._successPartnerDD,
          ..._successPartnerData,
          ..._successPartnerList,
          ..._successSavePartner,
        ]}
        clearMessages={clearMessages}
      />
    </PartnerContext.Provider>
  );
};

export default PartnerMain;
