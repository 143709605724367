import React, { useState, useEffect } from "react";
import Light from "./IndividualLight.js";
import "./traffic-light.css";

export default function TrafficLightSet({
  colors = ["#b30000", "#00b300"],
  enabledIndex,
  blinking,
}) {
  const [isOn, setIsOn] = useState(true);
  //  colors = ["#b30000", "#b2b300", "#00b300"],
  useEffect(() => {
    const interval = setInterval(() => {
      if (!blinking) {
        if (enabledIndex != null) {
          setIsOn(true);
        }

        return;
      }

      setIsOn(!isOn);
    }, 1000);

    return () => clearInterval(interval);
  }, [isOn, blinking]);

  return (
    <div className="trafficLight d-flex flex-column justify-content-around">
      {colors.map((color, index) => (
        <div className="mt-2" key={index}>
          <Light color={color} on={enabledIndex === index && isOn} />{" "}
        </div>
      ))}
    </div>
  );
}
