export const mainArray = ({ dropDowns, dataSource }) => [
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    label: "Location ID ",
    tag: "select",
    options: dropDowns?.locations || [],
    key: true,
  },
  {
    id: "stockLevel",
    name: "stockLevel",
    type: "number",
    label: "StockLevel",
  },
  {
    id: "stockUnit",
    name: "stockUnit",
    type: "text",
    label: "Stock Unit",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "standardPrice",
    name: "standardPrice",
    type: "number",
    label: "Standard Price",
  },
  {
    id: "movingAveragePrice",
    name: "movingAveragePrice",
    type: "number",
    label: "MovingAverage Price",
  },
];

export const adminArray = ({ dropDowns, dataSource }) => [
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayOnly: true,
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "text",
    label: "Created On",
    displayOnly: true,
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayOnly: true,
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "text",
    label: "Modified On",
    displayOnly: true,
  },
];
