/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput";
import OverviewButtons from "./Components/OverviewButtons";
import { useFetch } from "../../hooks/useFetch";
import { useMessage } from "../../hooks/UseMessage";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../CommonComponents/CircleButton";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import cardlogo from "../../Assets/cardLogo.svg";

function Index() {
  let navigate = useNavigate();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [order, setOrder] = useState();
  const [showOrderList, setShowOrderList] = useState(false);

  const [_getOrderList, _orderList, , , , , , _loadingtOrderList] = useFetch(
    GetFullURL("OrderList"),
    { method: "POST" }
  );

  const [_getOrderExists, , , , , , , _loadingOrderExist] = useFetch(
    GetFullURL("OrderExists"),
    { method: "POST" }
  );

  const headerStyle = {
    fontWeight: 400,
    color: "#fff",
    background: "#29ABE2",
  };
  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }

  const operationLoading = _loadingOrderExist || _loadingtOrderList;
  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                    <img
                      src={require("../../Assets/icon/Order.png")}
                      className="img-fluid cardLogo"
                      alt=""
                    />
                  </span>
                  Order Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setOrder}
                    document={order}
                    placeholder="Order id here..."
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of orders`}
                    clickHandler={() => {
                      _getOrderList({}, () => {
                        setShowOrderList(true);
                      });
                    }}
                  />
                  <OverviewButtons
                    order={order}
                    navigate={navigate}
                    _getOrderExists={_getOrderExists}
                    _createErrorMessage={_createErrorMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setOrder}
              dataList={_orderList}
              showListModal={showOrderList}
              setShowModal={setShowOrderList}
              title="List Of Orders"
              columns={[
                { field: "id", headerName: "Order ID", width: 250 },
                { field: "orderDate", headerName: "Order Date", width: 250 },
                { field: "locationId", headerName: "Location", width: 350 },
                { field: "soldtoId", headerName: "Sold To", width: 200 },
                { field: "createdBy", headerName: "Created By", width: 200 },
              ]}
            />
          </div>
        </div>
        <MessageLog
          errorMessages={[..._errorMessages]}
          successMessages={[]}
          warningMessages={[]}
          clearMessages={clearMessages}
        ></MessageLog>
      </div>
    </section>
  );
}

export default Index;
