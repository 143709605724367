import React, { useContext } from "react";
import { mainDataArray} from "./Fields";
import { LogisticStationContext } from "../context";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";

export default function MainTab() {
  const { onChange, operation, dropDowns, _logisticStationData } =
    useContext(LogisticStationContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _logisticStationData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _logisticStationData,
    dropDowns,
  };

  const fieldsToRender = mainDataArray(fieldsState);

  return (
    <div className="row">
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card">
          <div className="cardHeader">
            <h2>Main Data </h2>
          </div>
          <div className="cardBody">
            {fieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
