import React, { useContext, useEffect } from "react";
import {
  mainFieldsArray,
  mainDriverFieldsArray,
  otherFieldsArray,
  otherDriverFieldsArray,
} from "./fields";

import { PartnerContext } from "../context";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import Card from "src/CommonComponents/Card";

export default function HomeTab() {
  const {
    _partnerData,
    onChange,
    operation,
    dropDowns,
    partnerType,
    _user,
    _partnerTypeData,
  } = useContext(PartnerContext);

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: _partnerData,
      onChange,
      disabled: field.displayOnly,
    };

    //in case of using specific partner tranaction, then partner type is passed from overview screen
    //and it can not be changed
    if (partnerType) {
      if (field.id === "partnerTypeId") {
        newFieldObject.disabled = true;
      }
    }

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        newFieldObject.disabled =
          newFieldObject.key ||
          newFieldObject.displayOnly ||
          newFieldObject.editableCreateONLY;
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }

        break;
      default:
    }

    if (!_partnerTypeData.isExternal && newFieldObject.key) {
      newFieldObject.disabled = true;
    }

    return newFieldObject;
  };

  const fieldsState = {
    dataSource: _partnerData,
    onChange,
    dropDowns,
  };

  let mainFieldsToRender = [];
  let otherFieldsToRender = [];
  if (_partnerData.partnerTypeId === "DRIVER") {
    mainFieldsToRender = mainDriverFieldsArray(fieldsState);
    otherFieldsToRender = otherDriverFieldsArray(fieldsState);
  } else {
    mainFieldsToRender = mainFieldsArray(fieldsState);
    otherFieldsToRender = otherFieldsArray(fieldsState);
  }

  return (
    <div className="row" style={{ gap: "3rem" }}>
      <Card
        cardHeaderProps={{
          headerLabel: "Main Data",
        }}
        cardBody={mainFieldsToRender?.map((field, index) => (
          <FieldsReducer field={mergedFieldData(field)} key={index} />
        ))}
        style={{ width: "25rem" }}
      />

      <Card
        cardHeaderProps={{
          headerLabel: "Names",
        }}
        cardBody={otherFieldsToRender?.map((field, index) => (
          <FieldsReducer field={mergedFieldData(field)} key={index} />
        ))}
        style={{ width: "35rem" }}
      />
    </div>
  );
}
