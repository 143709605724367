import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useFetch } from "../../../../../../hooks/useFetch";
import { useStation } from "../../../../../../hooks/UseStation";
import { useMessage } from "../../../../../../hooks/UseMessage";
import { useUser } from "../../../../../../hooks/useUser";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../../../CommonComponents/GlobalConfFiles/URLs";

import { ReactComponent as TruckandScale } from "../../../../../../Assets/SVGs/TruckAndScale.svg";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../../CommonComponents/Messages/MessageLog";
import HeaderV4 from "../../../Header/HeaderV4";
import { LoadingScreen } from "../../../../../../CommonComponents/LoadingScreen/index";
import { FlipCounter } from "../../../../../../CommonComponents/FlipCounter/FlipCounter";
import TruckAnimation from "../../../../../../CommonComponents/TruckAnimation/index";
import { useParams } from "react-router-dom";
import { ValidateTakingWeight } from "./ValidateShipDoc";
import InfoSideBar from "./InfoSideBar";
import FlipCounter2 from "../../../../../../CommonComponents/FlipCounter/FlipCounter2";

function InOutWeightCrude(props) {
  const params = useParams();
  const navigate = useNavigate();

  //URL parameters, shipDoc number and requsted weight type IN or OUT
  const shipDocParam = params.shipDoc;
  const WeightTypeParam = params.weightType;

  //Service Data which is modbus data, weight, temp, BSW
  const [modbusMeasurements, setModbusMeasurements] = useState({});

  //Toplog for single message from service
  const [topLog, setTopLog] = useState("");

  //this for message array from service
  const [serviceError, setServiceError] = useState([]);
  const [serviceSuccess, setServiceSuccess] = useState([]);

  //State to hold which scale is being used
  const [scaleName, setScaleName] = useState("SouthScale");

  //state to indicate scalling operation is done
  const [finishedScaling, setFinishedScaling] = useState(false);

  //the requsted weight type can be supplied by url parameters or it can be
  //determined based on shipDoc data
  const requestedWeightType = useRef();
  const fieldOperationId = useRef();

  //hooks
  const [_user] = useUser();

  let serviceSimulation = false;

  if (
    _user.serviceSimulation === "true" ||
    _user.serviceSimulation === "True"
  ) {
    serviceSimulation = true;
  }

  let scaleApiInterval = 2000;

  const [
    _stationInfo,
    _getTerminalSetup,
    _terminalSetup,
    _getStationOperation,
    _stationOperation,
    _messages,
    _setMessages,
  ] = useStation();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
  ] = useMessage();

  const [
    _getModbusData,
    ,
    ,
    _modbusError,
    _setModbusError,
    _modbusSuccess,
    _setModbusSuccess,
    _loadingModbusData,
  ] = useFetch(
    GetServiceFullURL("GetModBusData"),
    {
      method: "POST",
    },
    true
  );

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    _successShipDoc,
    _setSuccessShipDoc,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" }, true);

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDocData,
    _setErrorSaveShipDocData,
    _successSaveShipDocData,
    _setSuccessSaveShipDocData,
    _loadingSaveShipDocData,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" }, true);

  if (_stationInfo.scaleApiInterval > 0) {
    scaleApiInterval = _stationInfo.scaleApiInterval;
  }

  useEffect(() => {
    //onMount or change of shipDoc parameter read shipDoc
    _getShipDoc({ data: { Id: shipDocParam } }, ({ data: shipDocData }) => {});
  }, [shipDocParam]);

  useEffect(() => {
    //onMount or change of shipDoc parameter
    //Read shipDoc and set service timer

    let modbusServiceTimer = null;
    if (serviceSimulation) {
      modbusServiceTimer = setInterval(() => {
        modbusSimulation();
      }, scaleApiInterval);
    } else {
      modbusServiceTimer = setInterval(() => {
        GetWeightModbusData();
      }, scaleApiInterval);
    }

    return () => {
      clearInterval(modbusServiceTimer);
    };
  }, [scaleName, serviceSimulation]);

  useEffect(() => {
    _getStationOperation(fieldOperationId.current, _shipDocData.fieldProcessId);
  }, [fieldOperationId.current, _shipDocData.fieldProcessId]);

  useEffect(() => {
    //This sets the requssted weight type, it be deduced from shipDoc data (Auto)
    //or it can be passed a parameter, also this determines the field operationId
    if (Object.keys(_shipDocData).length === 0) {
      return;
    }

    //Determine the requested weight type, this only relevant if it is auto, if it set as IN or OUT
    //by previous page then we will just take that
    if (WeightTypeParam === "AUTO") {
      _shipDocData.inWeightStatus === "X"
        ? (requestedWeightType.current = "OUT")
        : (requestedWeightType.current = "IN");
    } else {
      requestedWeightType.current = WeightTypeParam;
    }

    //Determine field operarationId based on requsted weight type
    switch (requestedWeightType.current) {
      case "IN":
        fieldOperationId.current = "INWEIGHT";
        break;
      case "OUT":
        fieldOperationId.current = "OUTWEIGHT";
        break;
      default:
        return;
    }
  }, [_shipDocData]);

  useEffect(() => {
    if (!finishedScaling) {
      return;
    }

    let nextUrlattribute1 = "";
    let nextUrlattribute2 = "";
    let nextUrlattribute3 = "";
    if (_stationOperation.nextUrlAttribute1) {
      try {
        let attribute1 = eval(_stationOperation.nextUrlAttribute1);
        nextUrlattribute1 = attribute1;
      } catch (error) {
        nextUrlattribute1 = _stationOperation.nextUrlAttribute1;
      }
    }

    if (_stationOperation.nextUrlAttribute2) {
      try {
        let attribute2 = eval(_stationOperation.nextUrlAttribute2);
        nextUrlattribute2 = attribute2;
      } catch (error) {
        nextUrlattribute2 = _stationOperation.nextUrlAttribute2;
      }
    }

    if (_stationOperation.nextUrlAttribute3) {
      try {
        let attribute3 = eval(_stationOperation.nextUrlAttribute3);
        nextUrlattribute3 = attribute3;
      } catch (error) {
        nextUrlattribute3 = _stationOperation.nextUrlAttribute3;
      }
    }

    var leaveTimer = setTimeout(() => {
      navigate(
        `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
      );
    }, 500);
    return () => {
      clearTimeout(leaveTimer);
    };
  }, [finishedScaling]);

  useEffect(() => {
    //warn the user if the requested weight in IN and it has been taken
    if (
      requestedWeightType.current === "IN" &&
      _shipDocData.inWeightStatus === "X"
    ) {
      _createWarningMessage("9");
    }

    //warn the user if the requested weight in OUT and it has been taken
    if (
      requestedWeightType.current === "OUT" &&
      _shipDocData.outWeightStatus === "X"
    ) {
      _createWarningMessage("10");
    }
  }, []);

  function clearMessages(e) {
    let type = e.target.dataset.type;

    switch (type) {
      case "success":
        _setSuccessShipDoc([]);
        _setSuccessSaveShipDocData([]);
        _setModbusSuccess([]);
        setServiceSuccess([]);
        break;
      case "error":
        _setErrorSaveShipDocData([]);
        _setErrorShipDoc([]);
        _setModbusError([]);
        setServiceError([]);
        _setErorMessages([]);
        break;
      case "warning":
        _setWarningMessages([]);
        break;
      default:
        break;
    }
  }
  const operationLoading = _loadingShipDoc || _loadingSaveShipDocData;

  const onClickGetWeight = () => {
    let changedShipDocData = _shipDocData;

    let weightValidationResult = ValidateTakingWeight(
      _shipDocData,
      modbusMeasurements.weight,
      requestedWeightType.current,
      _createErrorMessage
    );

    if (!weightValidationResult) {
      return;
    }

    switch (requestedWeightType.current) {
      case "IN":
        changedShipDocData.inWeight = modbusMeasurements.weight;
        changedShipDocData.weightUnitId = "LB";
        changedShipDocData.inWeightStatus = "X";
        changedShipDocData.inWeightStDT = moment().format();
        changedShipDocData.inWeightEnDT = moment().format();
        changedShipDocData.statusId = "INWGHTSHDOC";
        changedShipDocData.loadUnloadinProcess = true;
        changedShipDocData.currentStation = _stationInfo.id;
        _saveShipDoc(
          { data: changedShipDocData },
          ({ data: newShipDocData }) => {
            _setShipDocData(newShipDocData);
            setFinishedScaling(true);
          }
        );
        break;
      case "OUT":
        let netWeight = Math.abs(
          modbusMeasurements.weight - changedShipDocData.inWeight
        );
        changedShipDocData.outWeight = modbusMeasurements.weight;
        changedShipDocData.weightUnitId = "LB";
        changedShipDocData.outWeightStatus = "X";
        changedShipDocData.outWeightStDT = moment().format();
        changedShipDocData.outWeightEnDT = moment().format();
        changedShipDocData.statusId = "COMPSHPDOC";
        changedShipDocData.loadUnloadinProcess = false;
        changedShipDocData.signatureReady = true;
        changedShipDocData.terminalActivityinProcess = false;
        changedShipDocData.endDT = moment().format();
        changedShipDocData.currentStation = _stationInfo.id;

        changedShipDocData.shippingDocItemsComm.forEach((item, index) => {
          item.density = modbusMeasurements.density;
          item.densityUnitId = "LBB";
          item.temperature = modbusMeasurements.temperature;
          item.temperatureUnitId = "FAH";
          item.bsw = modbusMeasurements.bsw;
          item.quantity = netWeight;
          item.unitId = "LB";
        });

        _saveShipDoc(
          { data: changedShipDocData },
          ({ data: newShipDocData }) => {
            _setShipDocData(newShipDocData);
            setFinishedScaling(true);
          }
        );
        break;
      default:
        return;
    }
  };

  const calculateFillPercent = () => {
    let loadPercent = 0;
    requestedWeightType.current === "OUT"
      ? (loadPercent = 0)
      : (loadPercent = 100);

    return loadPercent.toFixed(2);
  };
  calculateFillPercent();

  const handleChangeScale = (e) => {
    const {
      target: { value, name, id },
    } = e;

    setScaleName(id);
  };
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function GetWeightModbusData() {
    _setModbusError([]);
    _getModbusData({ data: { ScaleId: scaleName } }, (response) => {
      if (response.success) {
        setTopLog(() => {
          return { success: response.success, message: response.message };
        });
        //Set Weight
        setModbusMeasurements({ ...response.data });
      }
    });
  }
  function modbusSimulation() {
    let simWeight;
    let simTemp;
    let simBSW;
    let simDensity;

    switch (requestedWeightType.current) {
      case "IN":
        simWeight = getRandomInt(120000, 125000);
        break;
      case "OUT":
        simWeight = getRandomInt(23000, 25000);
        break;
      default:
        return;
    }
    simTemp = getRandomInt(60, 80);
    simBSW = getRandomInt(1, 10);
    simDensity = getRandomInt(200, 297);
    setModbusMeasurements({
      weight: simWeight,
      temperature: simTemp,
      density: simDensity,
      bsw: simBSW,
    });
    return;
  }

  let error = "bg-danger";
  let success = "bg-success";
  let errorClass = `w-100 rounded-lg px-5 py-3 ${error} d-flex align-items-center justify-content-center`;
  let successClass = `w-100 rounded-lg px-5 py-3 ${success} d-flex align-items-center justify-content-center`;
  return (
    <div className="container-fluid p-0">
      {operationLoading && <LoadingScreen />}

      <div className="row px-3">
        <div className="col-12 p-0">
          <HeaderV4 _shipDocData={_shipDocData} WeightTypeParam={null} />
        </div>
      </div>

      <div className="row px-3">
        <div className="col-12 p-0">
          {topLog.success ? (
            <div className="col-12">
              <div className={successClass}>
                <span className="text-white"> {topLog.message}</span>
              </div>
            </div>
          ) : (
            <div className="col-12">
              <div className={errorClass}>
                <span className="text-white"> {topLog.message}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row align-items-center justify-content-around">
        <div className="col-2">
          <InfoSideBar modbusMeasurements={modbusMeasurements} />
        </div>
        <div className="col-7 d-flex align-items-center justify-content-center">
          <div className="" style={{ flex: 1, fontSize: 24 }}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="mx-3" style={{ width: "85%" }}>
                <TruckAnimation
                  compartCount={1}
                  activeIndex={0}
                  fillPerCent={calculateFillPercent()}
                />
              </div>
              <div className="truckWight">
                <h2>{requestedWeightType.current} WEIGHT</h2>
              </div>
            </div>
            <div className="d-flex flex-column">
              <FlipCounter
                number={modbusMeasurements.weight}
                className="mb-5"
                className2="mt-4"
                title="Scale"
              />
              {/* <FlipCounter2 value={"70"}/> */}
              <div className="d-flex" style={{ gap: "1rem" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className="px-5 py-3 mt-5 w-40"
                  style={{
                    hight: "100ev",
                    background: "red",
                    borderColor: "#08bd55",
                    fontSize: "40px",
                  }}
                  onClick={() => {
                    navigate(`/TerminalActivity/StartWithCrudeOrder`);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="px-5 py-3 mt-5 w-60"
                  style={{
                    hight: "250ev",
                    background: "#08bd55",
                    borderColor: "#08bd55",
                    fontSize: "40px",
                  }}
                  onClick={onClickGetWeight}
                >
                  Take {WeightTypeParam} Weight
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 d-flex flex-column justify-content-center">
          <div className="form-check mb-3" style={{ fontSize: "30px" }}>
            <input
              className="form-check-input radio-zoom"
              type="radio"
              name="ScaleName"
              id="SouthScale"
              checked={scaleName === "SouthScale"}
              onChange={handleChangeScale}
            />
            <label className="form-check-label" htmlFor="SouthScale">
              South Scale
            </label>
          </div>
          <div className="form-check" style={{ fontSize: "30px" }}>
            <input
              className="form-check-input radio-zoom"
              type="radio"
              name="ScaleName"
              id="NorthScale"
              checked={scaleName === "NorthScale"}
              onChange={handleChangeScale}
            />
            <label className="form-check-label" htmlFor="NorthScale">
              North Scale
            </label>
          </div>
        </div>
      </div>
      <MessageLog
        errorMessages={[
          ..._errorShipDoc,
          ..._errorSaveShipDocData,
          ...serviceError,
          ..._modbusError,
          ..._errorMessages,
        ]}
        successMessages={[
          ..._successSaveShipDocData,
          ...serviceSuccess,
          ..._modbusSuccess,
        ]}
        warningMessages={[..._warningMessages]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}

export default InOutWeightCrude;
