import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import SignaturePad from "react-signature-canvas";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../../../hooks/useFetch";
import { useMessage } from "../../../../hooks/UseMessage";
import { useStation } from "../../../../hooks/UseStation";
import { useDD } from "../../../../hooks/UseDD";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import { LoadingContext } from "../../Context";
import { useParams } from "react-router-dom";
import {
  ValidateCokeSubmit,
  ValidateSulfurSubmit,
  ValidateSAPOrderSubmit,
  ValidateSAPOrderSubmitV1,
} from "./ValidateShipDoc";
import MessageLog from "./../../../../CommonComponents/Messages/MessageLog";
import UniversalEditableGrid from "../../../../CommonComponents/Grid/EditableGrid/UniversalEditableGrid";
import { itemArray, itemSulfurArray, itemArrayV1 } from "./ItemGridFields";
import {
  mainCokeArray,
  mainSulfurArray,
  mainCommonArray,
  mainwithTempArray,
  mainwithTempArrayV1,
  mainWithoutTempArray,
  carrierCommonArray,
  carrierArray,
  otherArray,
  weightArray,
} from "./HeaderFields";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import "./Signature.css";
import Card from "src/CommonComponents/Card";

const Signature = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  //URL parameters, shipDoc number
  const shipDoc = params.shipDoc;

  const [serviceError, setServiceError] = useState([]);
  const [serviceSuccess, setServiceSuccess] = useState([]);

  const shiptoDropDown = useRef([]);
  const carrierDropDown = useRef([]);
  const canvasRef = useRef();

  const signatureData = useRef();

  const fieldOperationId = "SIGNATURE";
  //hooks
  const [
    _stationInfo,
    _getTerminalSetup,
    _terminalSetup,
    _getStationOperation,
    _stationOperation,
    _messages,
    _setMessages,
  ] = useStation();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
  ] = useMessage();

  const [_dd] = useDD();
  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    ,
    ,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" });
  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" });

  const [
    _printShipDoc,
    ,
    ,
    _errorPrintShipDoc,
    _setErrorPrintShipDoc,
    _successPrintShipDoc,
    _setSuccessPrintShipDoc,
    _loadingPrintShipDoc,
  ] = useFetch(GetFullURL("PrintShippingDoc"), { method: "POST" });

  const [
    _printShipDocLocalService,
    ,
    ,
    _errorPrintShipDocLocalService,
    _setErrorPrintShipDocLocalService,
    _successPrintShipDocLocalService,
    _setSuccessPrintShipDocLocalService,
    _loadingPrintShipDocLocalService,
  ] = useFetch(GetServiceFullURL("PrintPDFNewLOAD"), {
    method: "POST",
    headers: {},
  });
  const [
    _deterSetup,
    _deterSetupData,
    ,
    _errorDeterSetup,
    _setErrorDeterSetup,
    ,
    ,
    _loadingSetupDeter,
  ] = useFetch(GetFullURL("DetermineSetup"), { method: "POST" }, true);
  const clearCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.clear();
      signatureData.current = "";
    }
  };

  const generateUrl = () => {
    if (!canvasRef.current.isEmpty()) {
      signatureData.current = canvasRef.current
        .getCanvas()
        .toDataURL("image/png");
    }
  };
  const fieldsState = {
    dropDowns: _dd.shipDoc || [],
    shipsDD: shiptoDropDown.current,
    carrierDD: carrierDropDown.current,
  };

  let mainFields;
  let itemFields;
  let carrierFields;
  let otherFields;
  let weightFields;
  switch (_shipDocData.fieldProcessId) {
    case "COKE-ELDO":
      mainFields = mainCokeArray(fieldsState);
      carrierFields = carrierArray(fieldsState);
      otherFields = otherArray(fieldsState);
      weightFields = weightArray(fieldsState);
      itemFields = itemArray;
      break;
    case "SULFUR-ELDO":
      mainFields = mainSulfurArray(fieldsState);
      carrierFields = carrierArray(fieldsState);
      otherFields = otherArray(fieldsState);
      weightFields = weightArray(fieldsState);
      itemFields = itemSulfurArray;
      break;
    default:
      switch (_shipDocData.locationId) {
        case "4001":
          mainFields = mainwithTempArrayV1(fieldsState);
          carrierFields = carrierCommonArray(fieldsState);
          otherFields = otherArray(fieldsState);
          weightFields = weightArray(fieldsState);
          itemFields = itemArray;
          break;
        case "2550":
          mainFields = mainwithTempArray(fieldsState);
          carrierFields = carrierCommonArray(fieldsState);
          otherFields = otherArray(fieldsState);
          weightFields = weightArray(fieldsState);
          itemFields = itemArray;
          break;
        case "2400":
          mainFields = mainWithoutTempArray(fieldsState);
          carrierFields = carrierCommonArray(fieldsState);
          otherFields = otherArray(fieldsState);
          weightFields = weightArray(fieldsState);
          itemFields = itemArray;
          break;
        default:
          mainFields = mainCommonArray(fieldsState);
          carrierFields = carrierCommonArray(fieldsState);
          otherFields = otherArray(fieldsState);
          weightFields = weightArray(fieldsState);
          itemFields = itemArray;
      }
  }

  const mergedFieldData = (field, dataSource) => {
    return { ...field, dataSource, onChange };
  };

  useEffect(() => {
    _getShipDoc({ data: { Id: shipDoc } }, ({ data: shipDocData }) => {
      if (shipDocData?.signatureData) {
        canvasRef.current.fromDataURL(shipDocData?.signatureData);
      }

      _deterSetup({
        data: {
          LocationId: shipDocData.locationId,
          FieldProcessId: shipDocData.fieldProcessId,
          TransportationModeId: shipDocData.transportationModeId,
          MaterialId: shipDocData.shippingDocItemsComm[0].materialId,
        },
      });
    });
  }, []);

  useEffect(() => {
    _getStationOperation(fieldOperationId, _shipDocData.fieldProcessId);
  }, [_shipDocData.fieldProcessId]);

  useEffect(() => {
    //Get shipto and carrier dropdown from terminal setup
    if (!_deterSetupData.setupData?.orderPartnersComm) {
      return;
    }

    _deterSetupData.setupData.partnersInfoComm.forEach((element) => {
      switch (element.partnerTypeId) {
        case "SHIPTO":
          let partnerDD = {
            value: element.id,
            label: element.partnerDescriptor,
          };
          shiptoDropDown.current.push(partnerDD);
          break;
        case "CARRIER":
          let carrierDD = {
            value: element.id,
            label: element.partnerDescriptor,
          };
          carrierDropDown.current.push(carrierDD);

          break;
        default:
        // code block
      }
    });
  }, [_deterSetupData.setupData?.orderPartnersComm]);
  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    //Other way of getting value
    //let x = e.target.getAttribute("data-item");
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    //if item is populated then this is an update from item and hence need to search
    //for a particular item and update it

    _setShipDocData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };
  const contextState = {
    _shipDocData,
    onChange,
  };
  const operationLoading =
    _loadingShipDoc ||
    _loadingSaveShipDoc ||
    _loadingPrintShipDoc ||
    _loadingPrintShipDocLocalService;
  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveShipDoc([]);
        _setSuccessPrintShipDocLocalService([]);
        _setSuccessPrintShipDoc([]);

        break;
      case "error":
        _setErrorSaveShipDoc([]);
        _setErrorShipDoc([]);
        _setErorMessages([]);
        _setErrorPrintShipDoc([]);
        _setErrorPrintShipDocLocalService([]);
        _setMessages([]);
        setServiceError([]);

        break;
      case "warning":
      default:
        break;
    }
  }

  const onChangeItem = (e) => {
    let item;
    let fieldTypeItem;
    const {
      target: { value, name },
    } = e;

    item = e.target.dataset.item;
    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeItem = e.target.dataset.type;

    let newValue;
    switch (fieldTypeItem) {
      case "number":
        newValue = Number(value);
        break;
      default:
        if (value === "select" || value === "SELECT") {
          newValue = "";
        } else {
          newValue = value;
        }

        break;
    }
    const newValueObject = { [name]: newValue };

    let newItems = _shipDocData.shippingDocItemsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.itemNumber == item) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setShipDocData((prevState) => {
      return { ...prevState, shippingDocItemsComm: newItems };
    });
  };
  function handleButtonClickGrid(e) {}
  function completeShipDoc() {
    if (!_stationOperation.fieldOperationId) {
      _createErrorMessage(
        "76",
        _shipDocData.fieldProcessId,
        fieldOperationId.current
      );
      return;
    }

    if (!_stationOperation.shipDocStatusId) {
      _createErrorMessage("95", fieldOperationId.current);
      return;
    }
    //the following long code is just to obtain next URL
    let nextUrlattribute1 = "";
    let nextUrlattribute2 = "";
    let nextUrlattribute3 = "";

    if (_stationOperation.nextUrlAttribute1) {
      try {
        let attribute1 = eval(_stationOperation.nextUrlAttribute1);
        nextUrlattribute1 = attribute1;
      } catch (error) {
        nextUrlattribute1 = _stationOperation.nextUrlAttribute1;
      }
    }

    if (_stationOperation.nextUrlAttribute2) {
      try {
        let attribute2 = eval(_stationOperation.nextUrlAttribute2);
        nextUrlattribute2 = attribute2;
      } catch (error) {
        nextUrlattribute2 = _stationOperation.nextUrlAttribute2;
      }
    }

    if (_stationOperation.nextUrlAttribute3) {
      try {
        let attribute3 = eval(_stationOperation.nextUrlAttribute3);
        nextUrlattribute3 = attribute3;
      } catch (error) {
        nextUrlattribute3 = _stationOperation.nextUrlAttribute3;
      }
    }

    let changedShipDocData = _shipDocData;

    let errorReturn = {};
    switch (_shipDocData.fieldProcessId) {
      case "COKE_ELDO":
        errorReturn = ValidateCokeSubmit(_shipDocData, _createErrorMessage);
        break;
      case "COKE_ELDO1":
        errorReturn = ValidateSulfurSubmit(_shipDocData, _createErrorMessage);
        break;

      case "SAP_ORDER":
        if (_shipDocData.locationId === "2400") {
          errorReturn = ValidateSAPOrderSubmitV1(
            _shipDocData,
            _createErrorMessage
          );
        } else {
          errorReturn = ValidateSAPOrderSubmit(
            _shipDocData,
            _createErrorMessage
          );
        }
        break;
      default:
      // code block
    }

    if (!errorReturn) {
      return;
    }
    //Determine if a PDF form is needed on backend
    let shipDocPrint = false;
    if (
      changedShipDocData.shipDocPrintOptions == "2" ||
      changedShipDocData.shipDocPrintOptions == "3"
    ) {
      shipDocPrint = true;
    }

    //load start date/end (loadEnDT, loadStDT) is set initially at check-in and finally at signature
    //let navigationNextUrl = stationOperation.nextUrl;
      changedShipDocData.EndDT = moment().format();
      changedShipDocData.signatureStatus = "X";
      changedShipDocData.signatureDT = moment().format();
      if (signatureData.current) {
        changedShipDocData.signatureData = signatureData.current;
      }
      //This is next shipDoc status after successfull completion
      changedShipDocData.statusId = _stationOperation.shipDocStatusId;

      changedShipDocData.loadUnloadinProcess =
        _stationOperation.loadUnloadinProcess;

      changedShipDocData.signatureReady = _stationOperation.signatureReady;

      changedShipDocData.terminalActivityinProcess =
        _stationOperation.terminalActivityinProcess;

      //The backend uses the flag and shipDoc status to trigger printing
      changedShipDocData.printShipDocRelevant = shipDocPrint;

      changedShipDocData.shippingDocItemsComm.forEach((item, index) => {
        item.temperature = changedShipDocData.temperature;
        item.temperatureUnitId = "FAH";
      });

    _saveShipDoc({ data: changedShipDocData }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
      // 1, No Printing from TLOAD
      //2, Always from TLOAD
      //3, From TLOAD when ERP is down

      if (!newShipDocData.shipDocPrintOptions) {
        navigate(
          `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
        );
        return;
      }
      // no printing is needed exit
      if (newShipDocData.shipDocPrintOptions == "1") {
        navigate(
          `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
        );
        return;
      }

      //when 3 and ERP call is OK, then exit
      if (
        newShipDocData.shipDocPrintOptions == "3" &&
        newShipDocData.sentToERP == true
      ) {
        navigate(
          `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
        );
        return;
      }

      //      _printShipDoc({ data: { Id: shipDoc } }, ({ data: ShipDocBytes }) => {
      _printShipDocLocalService(
        {
          data: {
            ShipDocBytes: newShipDocData.shipDocPDFBytes,
            ShipDoc: shipDoc,
            NumberPrintShipDocCopies: _stationInfo.numberPrintShipDocCopies,
          },
        },
        (response) => {
          if (response.success) {
            setServiceSuccess((current) => {
              return [...current, response.message];
            });
            navigate(
              `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
            );
          } else {
            setServiceError((current) => {
              return [...current, response.message];
            });
          }
        }
      );
      //    });
    });
  }

  return (
    <div className="container-fluid">
      <LoadingContext.Provider value={contextState}>
        {operationLoading && <LoadingScreen />}

        <div
          className="d-flex flex-row justify-content-start mt-2"
          style={{ gap: 10 }}
        >
          <Card
            cardHeaderProps={{
              headerLabel: "Main",
            }}
            cardBody={mainFields?.map((field, index) => (
              <FieldsReducer
                field={mergedFieldData(field, _shipDocData)}
                key={index}
              />
            ))}
            style={{ width: "400px" }}
          />
          <Card
            cardHeaderProps={{
              headerLabel: "Carrier",
            }}
            cardBody={carrierFields?.map((field, index) => (
              <FieldsReducer
                field={mergedFieldData(field, _shipDocData)}
                key={index}
              />
            ))}
            style={{ width: "400px" }}
          />
          <Card
            cardHeaderProps={{
              headerLabel: "Weights",
            }}
            cardBody={weightFields?.map((field, index) => (
              <FieldsReducer
                field={mergedFieldData(field, _shipDocData)}
                key={index}
              />
            ))}
            style={{ width: "200px" }}
          />
          <div className="row d-flex flex-column ml-2">
            <div className="col-3 mt-2">
              <h style={{ fontSize: "25px" }}>Sign Here</h>
              <SignaturePad
                penColor="blue"
                canvasProps={{ className: "signaturePad" }}
                ref={canvasRef}
                onEnd={generateUrl}
              />
            </div>
            <div className="col-6 d-flex flex-row">
              <button
                className="btn btn-primary"
                onClick={completeShipDoc}
                style={{
                  color: "black",
                  backgroundColor: "#08bd55",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                Complete and Print BOL
              </button>
              <button
                className="btn btn-primary ms-3"
                onClick={clearCanvas}
                style={{
                  color: "black",
                  backgroundColor: "#DA1212",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                Clear Signature
              </button>
            </div>
          </div>
        </div>

        <UniversalEditableGrid
          fields={itemFields}
          headerData={_shipDocData}
          itemData={_shipDocData.shippingDocItemsComm ?? []}
          dropDowns={_dd.shipDoc}
          operation={"Create"}
          onChange={onChangeItem}
          handleButtonClickGrid={handleButtonClickGrid}
          ActionColumn={false}
        />

        <MessageLog
          errorMessages={[
            ..._errorShipDoc,
            ..._errorSaveShipDoc,
            ..._errorPrintShipDoc,
            ..._errorPrintShipDocLocalService,
            ..._errorMessages,
            ...serviceError,
            ..._messages,
          ]}
          successMessages={[
            ..._successSaveShipDoc,
            ...serviceSuccess,
            ..._successPrintShipDocLocalService,
            ..._successPrintShipDoc,
          ]}
          clearMessages={clearMessages}
        ></MessageLog>
      </LoadingContext.Provider>
    </div>
  );
};

export default Signature;
