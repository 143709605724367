import React, { useState, useEffect } from "react";
import { InventoryDocContext } from "./Contex";
import MessageLog from "../Components/MessageLog";
import HomeTab from "./Main/index";
import Items from "./../InventoryDocumentMain/Items/Index";
import InventoryDocOperationHeader from "./Header/Index";
import AdminTab from "./Admin/Index";
import PartnerTab from "./PartnerTab/Index";
import TransportationTab from "./TransportationTab/Index";
import { useFetch } from "./../../../hooks/useFetch";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Tabpannel from "../../../Business Processes/Components/TabPannel";
import { useParams } from "react-router-dom";

const UpdatedHomeComponent = () => (
  <React.Fragment>
    <HomeTab />
    <Items />
  </React.Fragment>
);

export default function Index(props) {
  //read URL parameters
  const params = useParams();
  //Partner ID
  let id = params.id;

  //Operation
  let operation = params.op;

  //Make sure that ID is numeric
  const inventoryDocId = id;
  //Do the following when page loads

  const [value, setValue] = useState(0);

  const [frondendError, setFrontendError] = useState([]);
  const [frontendWarning, setFrontendWarning] = useState([]);

  const [
    _getInventoryDocData,
    _inventoryDocData,
    _setInventoryDocData,
    _errorInventoryDocData,
    _setErrorInventoryDocData,
    _successInventoryDocData,
    _setSuccessInventoryDocData,
    _loadingInventoryDocData,
  ] = useFetch(GetFullURL("GetInventoryDoc"), { method: "POST" });

  const [
    _getPartnerList,
    _partnerList,
    _setPartnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    _successPartnetList,
    _setSuccessPartnerList,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("PartnerList"), { method: "POST" });

  const [
    _getInventoryDocDD,
    _inventoryDocDD,
    ,
    _errorInventoryDocDD,
    _setErrorInventoryDocDD,
    _successInventoryDocDD,
    _setSuccessInventoryDocDD,
    _loadingtInventoryDocDD,
  ] = useFetch(GetFullURL("InventoryDocDropDowns"), { method: "POST" });

  const [
    _getMaterialList,
    _materialList,
    _setMaterialList,
    _errorMaterialList,
    _SetErrorMaterialList,
    _successMaterialList,
    _setSuccessMaterialList,
    _loadingMaterialList,
  ] = useFetch(GetFullURL("MaterialList"), { method: "POST" });

  const [
    _saveInventoryDocData,
    _inventoryDocSaveData,
    _setInventoryDocSaveData,
    _errorSaveInventoryDocData,
    _setErrorSaveInventoryDocData,
    _successSaveInventoryDocData,
    _setSuccessSaveInventoryDocData,
    _loadingSaveInventoryDocData,
  ] = useFetch(GetFullURL("SaveInventoryDoc"), { method: "POST" });

  const [
    _getTankList,
    _tankList,
    ,
    _errorTankList,
    _setErrorTankList,
    _successTankList,
    _setSuccessTankList,
    _loadingTankList,
  ] = useFetch(GetFullURL("TankList"), { method: "POST" });

  useEffect(() => {
    _getInventoryDocDD();
    _getMaterialList();
    if (inventoryDocId) {
      _getInventoryDocData({ data: { id: inventoryDocId } });
    }
  }, [inventoryDocId]);

  const onChange = (e) => {
    let item;
    let fieldTypeItem;
    let fieldType;
    
    const {
      target: { value, name },
    } = e;
    let newValue;
    if (e.target.dataset) {
      item = e.target.dataset.item;
      //Get field type from  from custom attribute, this used for select mainly
      fieldTypeItem = e.target.dataset.type;
    }

    if (fieldTypeItem) {
      fieldType = fieldTypeItem;
    } else {
      fieldType = e.target.type;
    }

    switch (fieldType) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    if (item) {
      _setInventoryDocData((prevState) => {
        let newItems = prevState.orderItemsComm.map((i) => {
          //Did not use === becuase '5' equals to 5
          if (i.itemNumber == item) {
            return { ...i, ...newValueObject };
          }
          return i;
        });
        return { ...prevState, orderItemsComm: newItems };
      });
    } else {
      _setInventoryDocData((prevState) => {
        return { ...prevState, ...newValueObject };
      });
    }
  };

  const handleChange = (event, newValue) => setValue(newValue);



  //Use the context and pass the data, dropdowns
  const contextState = {
    onChange,
    operation,
    dropDowns: _inventoryDocDD || [],
    inventoryDocId,
    _inventoryDocData,
    _setInventoryDocData,
    _saveInventoryDocData,
    _getPartnerList,
    _partnerList,
    _materialList,
    _tankList,
    _getMaterialList,
    _getTankList,
    setFrontendError,
  };


  const operationLoading =
    _loadingInventoryDocData       ||
    _loadingMaterialList           ||
    _loadingtInventoryDocDD        ||
    _loadingPartnerList            ||
    _loadingSaveInventoryDocData   ||
    _loadingTankList;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveInventoryDocData([]);
        _setSuccessInventoryDocData([]);
        _setSuccessInventoryDocDD([]);
        _setSuccessMaterialList([]);
        _setSuccessPartnerList([]);
        _setSuccessTankList([]);
        break;
      case "error":
        _setErrorInventoryDocData([]);
        _setErrorInventoryDocDD([]);
        _SetErrorMaterialList([]);
        _setErrorSaveInventoryDocData([]);
        _setErrorPartnerList([]);
        _setErrorTankList([]);
        setFrontendError([]);
        break;
      case "warning":
        setFrontendWarning([]);
      default:
        break;
    }
  }
  return (
    <InventoryDocContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <InventoryDocOperationHeader
        _loadingSaveInventoryDoclData={_loadingSaveInventoryDocData}
      />
      <Tabpannel
        value={value}
        handleChange={handleChange}
        labels={["Home",  "Items","Partners","Transportation","Admin","Log"]}
        components={[
          UpdatedHomeComponent,
          Items,
          PartnerTab,
          TransportationTab,
          AdminTab,
          () => (
            <MessageLog
              errorMessages={[
                ..._errorInventoryDocDD,
                ..._errorInventoryDocData,
                ..._errorMaterialList,
                ..._errorSaveInventoryDocData,
                ..._errorPartnerList,
              ]}
              successMessages={[
                ..._successInventoryDocDD,
                ..._successInventoryDocData,
                ..._successMaterialList,
                ..._successSaveInventoryDocData,
                ..._successPartnetList,
              ]}
              warningMessages={[...frontendWarning]}
              clearMessages={clearMessages}
            ></MessageLog>
          ),
        ]}
      />
    </InventoryDocContext.Provider>
  );
}


