import React, { useContext } from "react";
import { TankContext } from "../MainData/context";

export default function TopPanel() {
  const { operation, tankId } = useContext(TankContext);

  var tankInfo;
  switch (operation) {
    case "Display":
      tankInfo = `${operation} Tank: ${tankId}`;
      break;
    case "Change":
      tankInfo = `${operation} Tank ${tankId}`;
      break;
    case "Create":
      tankInfo = `${operation} New Tank`;
      break;
    default:
      tankInfo = `Tank Info`;
      break;
  }
  return (
    <h2 className="heading" style={{position: "relative", top: 8 }} >
      {tankInfo}
    </h2>
  );
}
