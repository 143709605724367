import React, { useContext } from "react";
import { mainDataArray , weightDataArray , latLongDataArray} from "./Fields";
import { VehicleContext } from "../context";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";

export default function MainTab() {
  const { onChange, operation, dropDowns, _vehicleData } =
    useContext(VehicleContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _vehicleData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _vehicleData,
    dropDowns,
  };

  const mainDatafieldsToRender       = mainDataArray(fieldsState);
  const weightDatafieldsToRender     = weightDataArray(fieldsState);
  const latLongDatafieldsToRender    = latLongDataArray(fieldsState);

  return (
    <div className="row">
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card">
          <div className="cardHeader">
            <h2>Size</h2>
          </div>
          <div className="cardBody">
            {mainDatafieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card">
          <div className="cardHeader">
            <h2>Weight</h2>
          </div>
          <div className="cardBody">
            {weightDatafieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card">
          <div className="cardHeader">
            <h2>Latitude and Longitude</h2>
          </div>
          <div className="cardBody">
            {latLongDatafieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
