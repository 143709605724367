import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useStation } from "../../hooks/UseStation";
import { useUser } from "../../hooks/useUser";
import { ReactComponent as ArrowBack } from "../../Assets/Header/ArrowBack.svg";
import { ReactComponent as TloadLogo } from "../../Assets/Header/TloadLogo.svg";
import { ReactComponent as HomeIcon } from "../../Assets/Header/HomeIcon.svg";
import "./Header.scss";

export default function Header(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const [_stationInfo, , , , ,] = useStation();
  const [_user, ,] = useUser();
  let homePath;
  let logoPath;
  _stationInfo.stationHome
    ? (homePath = _stationInfo.stationHome)
    : (homePath = "/");

  if (_user?.userTypeId === "STATION") {
    logoPath = homePath;
  } else {
    logoPath = "/";
  }

  return (
    <nav className="navbar navbar-expand-lg" id="navBar">
      <ul className="navbar-nav w-100 justify-content-between">
        <li className="nav-item">
          <a
            className="nav-link"
            id="navLink"
            href="#"
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </a>
        </li>
        <li className="nav-item">
          <Link className="nav-link" id="navLink" to={logoPath}>
            <TloadLogo />
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" id="navLink" to="UserMaintenance">
            Welcome to HF Sinclair: {_user?.firstName}
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" id="navLink" to="UserMaintenance">
            Current Station: {_stationInfo?.universalDescriptor}
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" id="navLink" to={logoPath}>
            <HomeIcon />
          </Link>
        </li>
      </ul>
    </nav>
  );
}
