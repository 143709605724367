import React, { useState, useEffect } from "react";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../../hooks/useFetch";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { mainArray, partnerArray } from "./Fields";
import { useNavigate } from "react-router-dom";
import { FieldsReducer } from "../../../CommonComponents/Reducer/Index";
import { DataGrid } from "@mui/x-data-grid";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useMessage } from "../../../hooks/UseMessage";
import PartnerField from "../../../CommonComponents/EnhancedFields/Partner/PartnerField";
import PartnerList from "../../../CommonComponents/DropDowns/Partner/PartnerList";

function ShipDocWorkbench(props) {
  let navigate = useNavigate();
  const [shipDocSearchParam, setShipDocSearchParam] = useState();
  const [shipDocSearch, setShipDocSearch] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [searchView, setSearchView] = useState(false);
  const [searchFiltersView, setSearchFiltersView] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [selectionModel, setSelectionModel] = useState([]);
  const [shipDocButtonChange, setShipDocButtonChange] = useState(false);
  const [shipDocButtonDisplay, setShipDocButtonDisplay] = useState(false);
  const [showList, setShowList] = useState(false);
  const [partnerFieldName, setPartnerFieldName] = useState();

  const [
    _shipDocSearch,
    _shipDocSearchData,
    ,
    _errorShipDocSearch,
    _setErrorShipDocSearch,
    _successShipDocSearch,
    _setSuccessShipDocSearch,
    _loadingShipDocSearch,
  ] = useFetch(GetFullURL("ShipDocsSearching"), { method: "POST" });

  const [
    _getGeneralDD,
    _GeneralDDData,
    ,
    _errorGeneralDD,
    _setErrorGeneralDD,
    _successGeneralDD,
    _setSuccessGeneralDD,
    _loadingGeneralDD,
  ] = useFetch(GetFullURL("GetGeneralDropDown"), { method: "POST" });

  const [
    _getPartnerList,
    _partnerList,
    _setPartnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    _successPartnerList,
    _setSuccessPartnerList,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("PartnerList"), { method: "POST" });

  const operationLoading =
    _loadingShipDocSearch || _loadingGeneralDD || _loadingPartnerList;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessShipDocSearch([]);
        _setSuccessGeneralDD([]);
        _setSuccessPartnerList([]);
        break;
      case "error":
        _setErrorShipDocSearch([]);
        _setErrorGeneralDD([]);
        _setErorMessages([]);
        _setErrorPartnerList([]);
        break;
      default:
        break;
    }
  }

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;

    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        //if checkbox then pass the value as true or false assuming that field is defined as bool in backend
        checked ? (newValue = true) : (newValue = false);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    setShipDocSearchParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  useEffect(() => {
    _getGeneralDD({
      data: { locations: true, soldTos: false, shipTos: false },
    });
  }, []);

  let gridData;
  switch (activeTab) {
    case 1:
      const a = shipDocSearch.filter((x) => x.statusId === "ERRSHPDOC");
      gridData = a;
      break;
    case 2:
      const b = shipDocSearch.filter((x) => x.statusId === "NEWSHDOC");
      gridData = b;
      break;
    case 3:
      const c = shipDocSearch.filter((x) => x.statusId === "CHECKINSHDOC");
      gridData = c;
      break;
    case 4:
      const d = shipDocSearch.filter((x) => x.statusId === "INWGHTSHDOC");
      gridData = d;
      break;
    case 5:
      const e = shipDocSearch.filter((x) => x.statusId === "LOADEDSHDOC");
      gridData = e;
      break;
    case 6:
      const f = shipDocSearch.filter((x) => x.statusId === "OUTWGHTSHDOC");
      gridData = f;
      break;
    case 7:
      const g = shipDocSearch.filter((x) => x.statusId === "COMPSHPDOC");
      gridData = g;
      break;
    case 8:
      const h = shipDocSearch.filter((x) => x.statusId === "SENTERPSHDOC");
      gridData = h;
      break;
    case 9:
      const i = shipDocSearch.filter(
        (x) =>
          x.statusId === "" ||
          x.statusId === null ||
          (x.statusId !== "CHECKINSHDOC" &&
            x.statusId !== "COMPSHPDOC" &&
            x.statusId !== "ERRSHPDOC" &&
            x.statusId !== "INWGHTSHDOC" &&
            x.statusId !== "LOADEDSHDOC" &&
            x.statusId !== "NEWSHDOC" &&
            x.statusId !== "OUTWGHTSHDOC" &&
            x.statusId !== "SENTERPSHDOC" &&
            x.statusId !== "SHDOCCREATED")
      );
      gridData = i;
      break;
    default:
      gridData = shipDocSearch;
      break;
  }

  const fieldsState = {
    dropDowns: _GeneralDDData || [],
  };

  const mainFieldsToRender = mainArray(fieldsState);
  const partnerArrayToRender = partnerArray(fieldsState);

  var soldtofield = partnerArrayToRender.find(({ id }) => id === "soldtoId");
  var shiptofield = partnerArrayToRender.find(({ id }) => id === "shiptoId");

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource: shipDocSearchParam, onChange };

    return newFieldObject;
  };
  const columns = [
    { field: "id", headerName: "BOL", width: 100 },
    { field: "statusDescriptor", headerName: "BOL Status", width: 250 },
    { field: "shipDocDate", headerName: "BOL Date", width: 190 },
    { field: "startDT", headerName: "BOL Start DT", width: 190 },
    { field: "endDT", headerName: "BOL End DT", width: 190 },
    { field: "shiptoDescriptor", headerName: "Ship To", width: 350 },
    { field: "soldtoDescriptor", headerName: "Sold To", width: 350 },
    { field: "inWeight", headerName: "In-Weight", width: 100 },
    { field: "netWeight", headerName: "Net-Weight", width: 100 },
    { field: "outWeight", headerName: "Out-Weight", width: 100 },
    { field: "pin", headerName: "Driver Pin", width: 100 },
    { field: "driverDescriptor", headerName: "Driver", width: 250 },
    { field: "carrierDescriptor", headerName: "Carrier", width: 350 },
    { field: "vehicleId", headerName: "Vehicle", width: 100 },
    { field: "trailerId", headerName: "Trailer", width: 100 },
    { field: "materialDescriptor", headerName: "Material", width: 350 },
  ];

  let shipDoc;
  useEffect(() => {
    if (Array.isArray(_shipDocSearchData)) {
      shipDoc = _shipDocSearchData.find((x) => x.id === selectionModel[0]);
    }

    if (selectionModel.length > 1) {
      setShipDocButtonChange(false);
      setShipDocButtonDisplay(false);
      _createErrorMessage("100");
      return;
    }

    if (selectionModel[0] != undefined && shipDoc?.statusId != "COMPSHPDOC") {
      setShipDocButtonChange(true);
      setShipDocButtonDisplay(true);
    } else if (
      selectionModel[0] != undefined &&
      shipDoc?.statusId == "COMPSHPDOC"
    ) {
      setShipDocButtonChange(false);
      setShipDocButtonDisplay(true);
    } else {
      setShipDocButtonChange(false);
      setShipDocButtonDisplay(false);
    }
  }, [selectionModel]);

  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            {searchFiltersView ? (
              " "
            ) : (
              <div className="col-12">
                <div className="card mb-4">
                  <div className="cardHeader">
                    <h2>BOL Workbench</h2>
                  </div>
                  <div className="cardBody">
                    {mainFieldsToRender.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field, shipDocSearchParam)}
                        key={index}
                      />
                    ))}
                    <PartnerField
                      field={mergedFieldData(soldtofield)}
                      setShowList={setShowList}
                      _getPartnerList={_getPartnerList}
                      setPartnerFieldName={setPartnerFieldName}
                      dropDownApiPartnerType="SOLDTO"
                    />
                    <PartnerField
                      field={mergedFieldData(shiptofield)}
                      setShowList={setShowList}
                      _getPartnerList={_getPartnerList}
                      setPartnerFieldName={setPartnerFieldName}
                      dropDownApiPartnerType="SHIPTO"
                    />
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{
                          width: "32%",
                        }}
                        onClick={() => {
                          if (shipDocSearchParam?.locationId == "select") {
                            shipDocSearchParam.locationId = null;
                          } else if (shipDocSearchParam?.soldtoId == "select") {
                            shipDocSearchParam.soldtoId = null;
                          } else if (shipDocSearchParam?.shiptoId == "select") {
                            shipDocSearchParam.shiptoId = null;
                          }

                          _shipDocSearch(
                            {
                              data: {
                                LocationId: shipDocSearchParam?.locationId,
                                ShipDocDate: shipDocSearchParam?.shipDocDate,
                                SoldtoId: shipDocSearchParam?.soldtoId,
                                ShiptoId: shipDocSearchParam?.shiptoId,
                                Pin: shipDocSearchParam?.pin,
                              },
                            },
                            ({ data }) => {
                              if (data != null) {
                                if (data.length != 0) {
                                  setShipDocSearch(data);
                                  setSearchView(true);
                                  setSearchFiltersView(true);
                                } else {
                                  _createErrorMessage("98");
                                }
                              }
                            }
                          );
                        }}
                      >
                        Execute
                      </Button>
                      <Button
                        onClick={() => {
                          let navigationNextUrl = `/Home`;
                          navigate(navigationNextUrl);
                        }}
                        style={{
                          width: "32%",
                          background: "#DA1212",
                          borderColor: "#DA1212",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {searchView ? (
              <div>
                <Tabs
                  defaultIndex={0}
                  onSelect={(index) => setActiveTab(index)}
                >
                  <TabList>
                    <Tab>All BOLs</Tab>
                    <Tab>In Errors</Tab>
                    <Tab>New</Tab>
                    <Tab>Check-in Done</Tab>
                    <Tab>In-Weight Done</Tab>
                    <Tab>Loaded</Tab>
                    <Tab>Out-Weight Done</Tab>
                    <Tab>Complete</Tab>
                    <Tab>Sent to SAP/ERP</Tab>
                    <Tab>Status is unknown/undefined</Tab>
                  </TabList>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 600, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={gridData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={gridData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={gridData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={gridData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={gridData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={gridData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={gridData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={gridData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={gridData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={gridData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                </Tabs>
                <div className="d-flex flex-row">
                  <div className="p-2">
                    <Button
                      onClick={() => {
                        setSearchView(false);
                        setSearchFiltersView(false);
                        if (
                          shipDocSearchParam?.locationId ||
                          shipDocSearchParam?.shipDocDate ||
                          shipDocSearchParam?.soldtoId ||
                          shipDocSearchParam?.shiptoId ||
                          shipDocSearchParam?.pin > 1
                        ) {
                          shipDocSearchParam.locationId = null;
                          shipDocSearchParam.shipDocDate = null;
                          shipDocSearchParam.soldtoId = null;
                          shipDocSearchParam.shiptoId = null;
                          shipDocSearchParam.pin = 0;
                        }
                      }}
                      style={{
                        width: "150px",
                        background: "#DA1212",
                        borderColor: "#DA1212",
                      }}
                    >
                      Reset Filter
                    </Button>
                  </div>
                  <div className="p-2">
                    {shipDocButtonDisplay ? (
                      <Button
                        onClick={() => {
                          let id = selectionModel[0];
                          console.log("ID", id);
                          let navigationNextUrl = `/ShipDoc/${id}/Display`;
                          window.open(navigationNextUrl);
                        }}
                        style={{
                          width: "150px",
                          background: "#229954",
                          borderColor: "#229954",
                        }}
                      >
                        Display BOL
                      </Button>
                    ) : (
                      " "
                    )}
                    {shipDocButtonChange ? (
                      <Button
                        onClick={() => {
                          let id = selectionModel[0];
                          console.log("ID", id);
                          let navigationNextUrl = `/ShipDoc/${id}/Change`;
                          window.open(navigationNextUrl);
                        }}
                        style={{
                          width: "150px",
                          background: "#3498DB",
                          borderColor: "#3498DB",
                          marginLeft: "15px",
                        }}
                      >
                        Change BOL
                      </Button>
                    ) : (
                      " "
                    )}
                  </div>
                </div>
              </div>
            ) : (
              " "
            )}
            <div className="col-12 mt-4">
              <MessageLog
                errorMessages={[
                  ..._errorShipDocSearch,
                  ..._errorGeneralDD,
                  ..._errorMessages,
                  ..._errorPartnerList,
                ]}
                successMessages={[
                  ..._successShipDocSearch,
                  ..._successGeneralDD,
                  ..._successPartnerList,
                ]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
      <PartnerList
        _partnerList={_partnerList}
        showPartnerList={showList}
        setShowPartnerList={setShowList}
        partnerFieldName={partnerFieldName}
        onChange={onChange}
      />
    </>
  );
}

export default ShipDocWorkbench;
