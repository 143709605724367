import React from "react";
import { conversionGridFields } from "./Fields";
function ConversionsGrid({ gridData, operation, gridButtonsHandle }) {
  return (
    <div>
      <div className="col-12" id="ConversionGrid">
        <div style={{ overflow: "auto" }}>
          <div className="table table-striped text-nowrap">
            <table className="table table-striped text-nowrap custom-table">
              <thead>
                <tr>
                  {conversionGridFields.map((field, i) => {
                    return (
                      <th key={i} className="GridColumnHeading">
                        {field.label}
                      </th>
                    );
                  })}
                  {conversionGridFields.length > 0 ? (
                    <th className="GridColumnHeading">Actions</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {gridData.map((dataRecord, i) => {
                  return (
                    <tr key={i}>
                      {conversionGridFields.map((field, i1) => {
                        return (
                          <td className="GridColumnContent" key={i1}>
                            {dataRecord[field.dataField]}
                          </td>
                        );
                      })}
                      <td>
                        <button
                          type="button"
                          className="ButtonDelete"
                          data-id={dataRecord["unitConversionId"]}
                          data-op="Delete"
                          onClick={gridButtonsHandle}
                          hidden={operation === "Display"}
                        ></button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConversionsGrid;
