/** @format */

import React, { useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import Button from "react-bootstrap/Button";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "./../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";

function PrintShipDoc(props) {
  const [shipDoc, setShipDoc] = useState();
  const [serviceError, setServiceError] = useState([]);
  const [serviceSuccess, setServiceSuccess] = useState([]);

  const containerStyles = {
    maxWidth: 400,
  };
  const [
    _printShipDoc,
    _printShipDocData,
    _setPrintShipDocData,
    _errorPrintShipDoc,
    _setErrorPrintShipDoc,
    _successPrintShipDoc,
    _setSuccessPrintShipDoc,
    _loadingPriceShipDoc,
  ] = useFetch(GetFullURL("PrintShippingDoc"), { method: "POST" });

  const [
    _printShipDocLocalService,
    _printShipDocLocalServiceData,
    _setPrintShipDocLocalService,
    _errorPrintShipDocLocalService,
    _setErrorPrintShipDocLocalService,
    _successPrintShipDocLocalService,
    _setSuccessPrintShipDocLocalServic,
    _loadingPrintShipDocLocalService,
  ] = useFetch("http://localhost:8089/PrintPDFNewLOAD", {
    method: "POST",
    headers: {},
  });

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessPrintShipDoc([]);
        setServiceSuccess([]);
        break;
      case "error":
        _setErrorPrintShipDoc([]);
        setServiceError([]);
        break;
      default:
        break;
    }
  }

  const operationLoading = _loadingPriceShipDoc;
  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          <div className="row">
            {operationLoading && <LoadingScreen />}
            <div className="col-12">
              <div className="card card2">
                <div className="cardHeader">
                  <h2>
                    <label htmlFor="ShipDocd">Print BOL</label>
                  </h2>
                </div>
                <div className="cardBody">
                  <div className="cardInput orderInput">
                    <input
                      placeholder={"Type BOL Number"}
                      type="text"
                      value={shipDoc ?? ""}
                      onChange={(e) => {
                        let val = e.target.value;
                        setShipDoc(val);
                      }}
                      className="cardInput"
                      style={{
                        borderRadius: 8,
                        outline: "none !important",
                        height: 70,
                      }}
                    />
                  </div>
                  <div className="cardButton cardorder">
                    <Button
                      className="w-100"
                      onClick={() => {
                        _printShipDoc(
                          { data: { Id: shipDoc } },
                          ({ data: ShipDocBytes }) => {
                            _printShipDocLocalService(
                              {
                                data: {
                                  ShipDocBytes: ShipDocBytes,
                                  ShipDoc: shipDoc,
                                },
                              },
                              (response) => {
                                if (response.messageType === "Success") {
                                  setServiceSuccess((current) => {
                                    return [...current, response.message];
                                  });
                                }
                                if (response.messageType === "Error") {
                                  setServiceError((current) => {
                                    return [...current, response.message];
                                  });
                                }
                              }
                            );
                          }
                        );
                      }}
                    >
                      Print BOL/Shipping Doc
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <MessageLog
                errorMessages={[..._errorPrintShipDoc, ...serviceError]}
                successMessages={[..._successPrintShipDoc, ...serviceSuccess]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrintShipDoc;
