
export const ValidateTankPhysicalInput = (tankPhysical, _createErrorMessage) => {

  if (!tankPhysical) {
    _createErrorMessage("110");
    return false;
  }

  return true;
};
  