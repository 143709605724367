/** @format */

import React, { useContext, useState, useEffect } from "react";
import OrderGrid from ".//Grid/Index";
import { mainDataArray, destinationArray, carrierArray } from "./Fields";
import { LoadingContext } from "../../Context";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
export default function OrderSummary() {
  let navigate = useNavigate();
  const { _shipDocData, _stationData = {} } = useContext(LoadingContext);

  const fieldsState = {
    dataSource: _shipDocData,
  };
  const [chekinBtnAttributes, setChekinBtnAttributes] = useState({});
  const [inWeightBtnAttributes, setInWeightBtnAttributes] = useState({});
  const [outWeightBtnAttributes, setOutWeightBtnAttributes] = useState({});
  const [loadBtnAttributes, setLoadBtnAttributes] = useState({});
  const [signitureBtnAttributes, setSignitureBtnAttributes] = useState({});

  const mainDatafieldsToRender = mainDataArray(fieldsState);
  const destinationToRender = destinationArray(fieldsState);
  const carrierToRender = carrierArray(fieldsState);
  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _shipDocData };
    newFieldObject.disabled = true;
    return newFieldObject;
  };
  function buttonStatus() {
    switch (_shipDocData.statusId) {
      case "NEWSHDOC":
        setChekinBtnAttributes((current) => {
          return { ...current, disabled: false };
        });

        setInWeightBtnAttributes((current) => {
          return { ...current, disabled: true };
        });

        setOutWeightBtnAttributes((current) => {
          return { ...current, disabled: true };
        });

        setLoadBtnAttributes((current) => {
          return { ...current, disabled: true };
        });

        setSignitureBtnAttributes((current) => {
          return { ...current, disabled: true };
        });
        break;
      case "CHECKINSHDOC":
        setChekinBtnAttributes((current) => {
          return { ...current, disabled: false };
        });
        setInWeightBtnAttributes((current) => {
          return { ...current, disabled: false };
        });
        setOutWeightBtnAttributes((current) => {
          return { ...current, disabled: true };
        });

        setLoadBtnAttributes((current) => {
          return { ...current, disabled: true };
        });

        setSignitureBtnAttributes((current) => {
          return { ...current, disabled: true };
        });
        // code block
        break;
      case "INWGHTSHDOC":
        setChekinBtnAttributes((current) => {
          return { ...current, disabled: false };
        });
        setInWeightBtnAttributes((current) => {
          return { ...current, disabled: false };
        });
        setOutWeightBtnAttributes((current) => {
          return { ...current, disabled: false };
        });

        setLoadBtnAttributes((current) => {
          return { ...current, disabled: true };
        });

        setSignitureBtnAttributes((current) => {
          return { ...current, disabled: true };
        });
        break;
      case "OUTWGHTSHDOC":
        setChekinBtnAttributes((current) => {
          return { ...current, disabled: false };
        });
        setInWeightBtnAttributes((current) => {
          return { ...current, disabled: false };
        });
        setOutWeightBtnAttributes((current) => {
          return { ...current, disabled: false };
        });

        setLoadBtnAttributes((current) => {
          return { ...current, disabled: true };
        });

        setSignitureBtnAttributes((current) => {
          return { ...current, disabled: false };
        });
      default:
      // code block
    }
  }

  useEffect(() => {
    buttonStatus();
  }, [_shipDocData?.statusId]);

  return (
    <>
      <div className="heroCard" style={{ height: 0 }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <div className="card">
                <div className="cardHeader">
                  <h2>Main Data</h2>
                </div>
                <div className="cardBody">
                  {mainDatafieldsToRender.map((field, index) => (
                    <FieldsReducer field={mergedFieldData(field)} key={index} />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <div className="card">
                <div className="cardHeader">
                  <h2>Carrier</h2>
                </div>
                <div className="cardBody">
                  {carrierToRender.map((field, index) => (
                    <FieldsReducer field={mergedFieldData(field)} key={index} />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <div className="card">
                <div className="cardHeader">
                  <h2>Destinations</h2>
                </div>
                <div className="cardBody">
                  {destinationToRender.map((field, index) => (
                    <FieldsReducer field={mergedFieldData(field)} key={index} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <OrderGrid />
        <div className="row">
          <div className="col-12">
            <div style={{ display: "flex", gap: 10 }}>
              {_stationData?.stationOperationAssigmentComm.map((item) => {
                return (
                  <>
                    {/* 
The buttons for stages of processing are configured in the backend in the (StationOperationAssigment) table, that inculdes
The button name, next URL and URL parameter in (attribute1) and the disable condition which depends on shipdoc status that found
in various locals statues all under function (buttonStatus)
 */}
                    <Button
                      disabled={eval(item.attribute2)}
                      style={{}}
                      onClick={() => {
                    
                        const navigationUrl =
                          item.processUrl + eval(item.attribute1);
                        navigate(navigationUrl);
                      }}
                    >
                      {item.name}
                    </Button>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
