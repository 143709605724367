export const mainDataArray = ({ dropDowns }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Bill Of Material ID",
    disabled: true,
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    label: "Description",
  },

  {
    id: "billOfMaterialTypeId",
    name: "billOfMaterialTypeId",
    type: "text",
    label: "Bill Of Material Type",
    tag: "select",
    options: dropDowns?.billOfMaterialTypes || [],
  },
];

export const enhancedDDInputArray = ({ dropDowns, dataSource }) => [
  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    type: "text",
    tag: "enhancedInput",
  },

];
export const quantityArray = ({ dropDowns, dataSource }) => [
  {
    id: "quantity",
    name: "quantity",
    label: "Quantity",
    type: "number",
  },
  {
    id: "unitId",
    name: "unitId",
    label: "Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
];

export const dateDataArray = ({ dropDowns }) => [

  {
    id: "validFrom",
    name: "validFrom",
    type: "datetime",
    label: "Valid From",
  },
  {
    id: "validTo",
    name: "validTo",
    type: "datetime",
    label: "Valid To",
  },

];




