import React, { useState, useEffect } from "react";
import { ReactComponent as ReactSendCard } from "../../Assets/NewIconsForCards/SendIcon.svg";
import { ReactComponent as BOLReportIcon } from "../../Assets/NewIconsForCards/BOLReportIcon.svg";
import { ReactComponent as LOGIcon } from "../../Assets/NewIconsForCards/LOGIcon.svg";
import { ReactComponent as PDFIcon } from "../../Assets/NewIconsForCards/PDFIcon.svg";
import { ReactComponent as EmailIcon } from "../../Assets/NewIconsForCards/EmailIcon.svg";
import Card from "../Components/Card";
import { useUser } from "../../hooks/useUser";

const CardsArray = [
  {
    title: "Daily BOLs Report/Export",
    text: "",
    Icon: BOLReportIcon,
    link: "/ShipDocReportV1",
    roles: ["Admin", "SuperAdmin", "TerminalUser", "Loader"],
  },
  {
    title: "App Message Log",
    text: "",
    Icon: LOGIcon,
    link: "/AppLog",
    roles: ["Admin", "SuperAdmin", "TerminalUser"],
  },
  {
    title: "BOL PDF",
    text: "",
    Icon: PDFIcon,
    link: "/ShipDocPDF",
    roles: ["Admin", "SuperAdmin", "TerminalUser"],
  },
  {
    title: "Background Job Log",
    text: "",
    Icon: LOGIcon,
    link: "/BackgroundJobLog",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Summary Job Log",
    text: "",
    Icon: LOGIcon,
    link: "/JobSumLog",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Email",
    text: "",
    Icon: EmailIcon,
    link: "/Email",
    roles: ["Admin", "SuperAdmin"],
  },
];

export default function ReportsHome(props) {
  const [_user, ,] = useUser();
  const [newCards, setNewCards] = useState([]);

  useEffect(() => {
    if (!_user.roles) {
      return CardsArray;
    }
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card?.roles?.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);

  return (
    <div className="overviewCardContainer">
      {newCards.map((record, i) => (
        <div key={i}>
          <Card {...record} />
        </div>
      ))}
    </div>
  );
}
