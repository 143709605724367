/** @format */

import React, { useContext, useState } from "react";
import { mainDataArray, statusArray } from "./Fields";
import { OrderContext } from "../Contex";
import { FieldsReducer } from "./../../../../CommonComponents/Reducer/Index";
export default function AdminTab() {
  const { onChange, operation, dropDowns, _orderData } =
    useContext(OrderContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _orderData, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _orderData,
    dropDowns,
  };

  const fieldsToRender = mainDataArray(fieldsState);
  const statusFieldsRender = statusArray(fieldsState);

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: "auto" }}>
            <div className="cardHeader">
              <h2>Dates/User</h2>
            </div>
            <div className="cardBody">
              {fieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 440 }}>
            <div className="cardHeader">
              <h2>Status</h2>
            </div>
            <div className="cardBody">
              {statusFieldsRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
