export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Account ID",
    disabled: true,
  },
  {
    id: "accountName",
    name: "accountName",
    type: "text",
    label: "Account Name",
  },
  {
    id: "accountTypeId",
    name: "accountTypeId",
    tag: "select",
    options: dropDowns?.accountTypes || [],
    type: "text",
    label: "Account Type",
  },
  {
    id: "accountCurrency",
    name: "accountCurrency",
    tag: "select",
    options: dropDowns?.currencies || [],
    type: "text",
    label: "Account Currency",
  },
];

