import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import { Delete, Edit, Pageview } from "@mui/icons-material";

export default function GridAction({
  handleButtonClickGrid,
  operation,
  dataRecord,
}) {
  const displayGridItem = useRef(null);
  const changeGridItem = useRef(null);
  const deleteGridItem = useRef(null);
  return (
    <React.Fragment>
      <Button
        type="button"
        className="btn-danger"
        disabled={operation === "Display"}
        data-op="Display"
        data-id={dataRecord["itemNumber"]}
        onClick={handleButtonClickGrid}
        ref={displayGridItem}
      >
        <Pageview
          style={{ color: "white" }}
          size="small"
          data-op="Display"
          onClick={(e) => {
            e.stopPropagation();
            displayGridItem.current.click(e);
          }}
        />
      </Button>

      <Button
        type="button"
        className="mx-2"
        style={{ background: "#008000", borderColor: "#008000" }}
        data-op="Change"
        data-id={dataRecord["itemNumber"]}
        onClick={handleButtonClickGrid}
        disabled={operation === "Display"}
        ref={changeGridItem}
      >
        <Edit
          style={{ color: "white" }}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            changeGridItem.current.click();
          }}
        />
      </Button>
      <Button
        type="button"
        className="btn-danger"
        style={{ background: "#dc3545", borderColor: "#dc3545" }}
        data-op="Delete"
        data-id={dataRecord["itemNumber"]}
        onClick={handleButtonClickGrid}
        disabled={operation === "Display"}
        ref={deleteGridItem}
      >
        <Delete
          style={{ color: "white" }}
          size="small"
          data-op="Delete"
          data-id={dataRecord["itemNumber"]}
          onClick={(e) => {
            e.stopPropagation();
            deleteGridItem.current.click();
          }}
        />
      </Button>
    </React.Fragment>
  );
}
