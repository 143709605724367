export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    type: "number",
    label: "Item",
    displayOnly: true,
  },
  {
    id: "higherLevelItem",
    name: "higherLevelItem",
    type: "number",
    label: "Higher Level Item",
  },
  {
    id: "itemCategoryId ",
    name: "itemCategoryId ",
    type: "text",
    label: "Item Cat",
  },
];
export const enhancedInputArray = ({ dropDowns, dataSource }) => [
  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    type: "text",
    tag: "enhancedInput",
  },
  {
    id: "tankId",
    name: "tankId",
    label: "Tank",
    type: "text",
    tag: "enhancedInput",
  },
  {
    id: "destinationTankId",
    name: "destinationTankId",
    label: "Destination Tank",
    type: "text",
    tag: "enhancedInput",
  },
];
export const quantityArray1 = ({ dropDowns, dataSource }) => [
  {
    id: "quantity",
    name: "quantity",
    label: "Quantity",
    type: "number",
  },
  {
    id: "unitId",
    name: "unitId",
    label: "Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "quantity1",
    name: "quantity1",
    label: "Additional Quantity",
    type: "number",
  },
];
export const quantityArray2 = ({ dropDowns, dataSource }) => [
  {
    id: "unit1Id",
    name: "unit1Id",
    label: "Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "quantity2",
    name: "quantity2",
    label: "Additional Quantity",
    type: "number",
  },
  {
    id: "unit2Id",
    name: "unit2Id",
    label: "Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
];
export const scaleArray1 = ({ dropDowns }) => [

  {
    id: "weight",
    type: "text",
    name: "weight",
    label: "Weight",
    type: "number",
  },
  {
    id: "weightUnitId",
    name: "weightUnitId",
    label: "Weight Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.weightUnits || [],
  },
  {
    id: "temperature",
    type: "number",
    name: "temperature",
    label: "Temperature",
    type: "number",
  },
  {
    id: "temperatureUnitId",
    name: "temperatureUnitId",
    label: "Temperature Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.tempUnits || [],
  },
];
export const scaleArray2 = ({ dropDowns }) => [
  {
    id: "density",
    type: "number",
    name: "density",
    label: "Density",
    type: "number",
  },
  {
    id: "densityUnitId",
    type: "number",
    name: "densityUnitId",
    label: "Density Unit",
    type: "number",
    tag: "select",
    options: dropDowns?.densityUnits || [],
  },
  {
    id: "specificGravity",
    type: "number",
    name: "specificGravity",
    label: "Specific Gravity",
    type: "number",
  },
  {
    id: "bSW",
    type: "number",
    name: "bSW",
    label: "BSW",
    type: "number",
  },

];
export const loadDockArray = ({ dropDowns }) => [

  {
    id: "loadAreaId",
    type: "text",
    name: "loadAreaId",
    label: "Load Dock",
    tag: "select",
    options: dropDowns?.loadAreas || [],
  },

  {
    id: "loadBayId",
    type: "text",
    name: "loadBayId",
    label: "Load Bay ",
    tag: "select",
    options: dropDowns?.loadBays || [],
  },
  {
    id: "loadArmId",
    name: "loadArmId",
    label: "Load Arm",
    tag: "select",
    options: dropDowns?.loadArms || [],
  },
];
