import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export default function Log({ errors, success, clearButtonFM }) {
  return (
    <Container fluid>
      <Row>
        <Col>
          {errors.length > 1 ? (
            <div className="alert alert-danger" role="alert">
              {errors.map((item, index) => {
                return (
                  <div key={index}>
                    {item} <br />
                  </div>
                );
              })}
              <button
                type="button"
                onClick={clearButtonFM}
                data-type="error"
                className="btn btn-primary"
                style={{
                  borderRadius: 10,
                }}
              >
                Clear
              </button>
            </div>
          ) : (
            ""
          )}
        </Col>
        <Col>
          {success.length > 0 ? (
            <div className="alert alert-success" role="alert">
              {success.map((item, index) => {
                return (
                  <div key={index}>
                    {item} <br />
                  </div>
                );
              })}
              <button
                type="button"
                onClick={clearButtonFM}
                data-type="success"
                className="btn btn-primary"
                style={{
                  borderRadius: 10,
                }}
              >
                Clear
              </button>
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Container>
  );
}
