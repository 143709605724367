import React from 'react';
import Card from '../Components/Card';
import { ReactComponent as ReactAlarmCard } from '../../Assets/NewIconsForCards/AlarmIcon.svg';
import { ReactComponent as ReactTerminalsCard } from '../../Assets/NewIconsForCards/Terminals.svg';
import { ReactComponent as ChartIcon } from "src/Assets/NewIconsForCards/ChartIcon.svg";
import { ReactComponent as ClockIcon } from "src/Assets/NewIconsForCards/ClockIcon.svg";

let Arr = [
  {
    title: 'Active Loading/Unloading',
    text: '',
    Icon: ReactTerminalsCard,
    link: '/Dashboard/ActiveShipDocs',
  },
  {
    title: 'Alarms',
    text: '',
    Icon: ReactAlarmCard,
  },
  {
    title: 'BOL Metrics',
    text: '',
    Icon: ChartIcon,
    link: '/Dashboard/ShipDocsMetricsV1',
  },
  {
    title: 'BOL Process Time Chart',
    text: '',
    Icon: ClockIcon,
    link: '/Dashboard/BOLProcessTimeChart',
  },
];

export default function DashboardOverview() {
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {Arr.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
