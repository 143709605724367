import React, { useState } from "react";
import { mainArray } from "./SidebarFields";
import Card from "src/CommonComponents/Card";
import { FieldsReducer } from "src/CommonComponents/Reducer/Index";
function Sidebar({
  _shipDocDDData,
  onChangeItem,
  onChange,
  _shipDocData,
  _stationInfo,
  controllerData,
}) {
  const fieldsState = {
    shipDoc: _shipDocData,
    tankDD: getRelevantTanks(),
    onChangeItem: onChangeItem,
    onChange,
    controllerData,
  };

  function getRelevantTanks() {
    let tanks = [];
    tanks = _shipDocDDData?.tanks.filter(
      (x) => x.filter1 == _stationInfo.locationId
    );
    return tanks;
  }
  const mainFields = mainArray(fieldsState);
  return (
    <>
      <Card
        cardHeaderProps={{
          headerLabel: "Main",
        }}
        cardBody={mainFields?.map((field, index) => (
          <FieldsReducer field={field} key={index} />
        ))}
        style={{ width: "400px" }}
      />
    </>
  );
}

export default Sidebar;
