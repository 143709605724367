import React from "react";

export default function MessageLog({
  errorMessages,
  successMessages,
  clearMessages,
}) {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="mt-2">
            {errorMessages?.length > 0 ? (
              <div className="alert alert-danger" role="alert">
                {errorMessages.map((item, index) => {
                  return (
                    <div key={index}>
                      {item} <br />
                    </div>
                  );
                })}
                <button
                  type="button"
                  onClick={clearMessages}
                  data-type="error"
                  className="btn btn-primary"
                >
                  Clear
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          <div>
            {successMessages?.length > 0 ? (
              <div className="alert alert-success" role="alert">
                {successMessages.map((item, index) => {
                  return (
                    <div key={index}>
                      {item} <br />
                    </div>
                  );
                })}
                <button
                  type="button"
                  onClick={clearMessages}
                  data-type="success"
                  className="btn btn-primary"
                >
                  Clear
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
