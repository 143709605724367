import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { TableDataContext } from "../Contexts/TableDataContext";
export default function Delete({ mainButtonFM, show, handleClose, name }) {
  const { tableRecordN } = useContext(TableDataContext);
  console.log("tableRecordN111", tableRecordN);
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title> Delete:{name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you sure you want to delete:
        <h2>
          {tableRecordN.key || tableRecordN.id}:-{" "}
          {tableRecordN.nameLocalizedText ||
            tableRecordN.firstName ||
            tableRecordN.messageId}
        </h2>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={mainButtonFM}
          data-dismiss="modal"
          data-id={tableRecordN.id}
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
}
