import React from "react";
import Modal from "react-bootstrap/Modal";
import { DataGrid } from '@mui/x-data-grid';
import Button from "react-bootstrap/Button";

export default function ShipDocList({
  _shipDocList,
  showShipDocList,
  setShowShipDocList,
  shipDocFieldName,
  onChange,
  
}) {


  function handleOnSelect(selectedId) {

    onChange({
      target: { value: selectedId[0], name: shipDocFieldName, type: "text" },
    });
    setShowShipDocList(false);
  } 
  return (
    <Modal
      show={showShipDocList}
      size="xl"
      onHide={() => setShowShipDocList(false)}
      dialogClassName="modal-90w modal-dialog-scrollable"
      aria-labelledby="example-custom-modal-styling-title"
      className="partner-list-modal"
    >
      <Modal.Header
        style={{
          background: "#d4e1f5",
        }}
      >
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{
            fontWeight: 700,
          }}
        >
          List of ShipDocs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {_shipDocList?.length && (
          <div style={{ height: 600, overflow: "auto" }}>
         <DataGrid
         rows={_shipDocList || []}
         columns={[
          { field: "id", headerName: "BOL", width: 100 },
          { field: "statusDescriptor", headerName: "BOL Status", width: 250 },
          { field: "shipDocDate", headerName: "BOL Date", width: 190 },
          { field: "startDT", headerName: "BOL Start DT", width: 190 },
          { field: "endDT", headerName: "BOL End DT", width: 190 },
          { field: "shiptoDescriptor", headerName: "Ship To", width: 350,},
          { field: "soldtoDescriptor", headerName: "Sold To", width: 350,},
          { field: "inWeight", headerName: "In-Weight", width: 100,},
          { field: "netWeight", headerName: "Net-Weight", width: 100, },
          { field: "outWeight",headerName: "Out-Weight", width: 100, },
          { field: "pin", headerName: "Driver Pin", width: 100 },
          { field: "driverDescriptor", headerName: "Driver", width: 250,},
          { field: "carrierDescriptor", headerName: "Carrier", width: 250 },
          { field: "vehicleId", headerName: "Vehicle", width: 100 },
          { field: "trailerId", headerName: "Trailer", width: 100 },
          { field: "materialDescriptor", headerName: "Material",  width: 350,},
         ]}
         pageSize={100}
         rowsPerPageOptions={[1]}
         onSelectionModelChange={selectedId => handleOnSelect(selectedId)}
       />
       </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="offset-11 col-1">
          <Button
            variant="danger"
            onClick={() => {
              setShowShipDocList(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
