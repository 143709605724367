exports.BackgroundJob = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    key: "true",
    displayonly: "true",
    length: 100,
  },
  {
    id: "jobName",
    name: "jobName",
    type: "text",
    value: "",
    label: "Job Name",
    key: "true",
    length: 100,
  },
  {
    id: "jobIdentifier",
    name: "jobIdentifier",
    type: "text",
    value: "",
    label: "Job Identifier",
    disabled: true,
    length: 100,
  },
  {
    id: "jobStatus",
    name: "jobStatus",
    type: "number",
    value: "",
    label: "Job Status",
    length: 2,
  },
  {
    id: "personResposible",
    name: "personResposible",
    type: "text",
    value: "",
    label: "Person Resposible",
    length: 100,
  },
  {
    id: "email",
    name: "email",
    type: "text",
    value: "",
    label: "Email",
    length: 100,
  },
  {
    id: "errorCommGroup",
    name: "errorCommGroup",
    type: "text",
    value: "",
    label: "Error Commuincation Group",
    ddid: "commGroupId",
    length: 50,
  },
  {
    id: "successCommGroup",
    name: "successCommGroup",
    type: "text",
    value: "",
    label: "Success Commuincation Group",
    ddid: "commGroupId",
    length: 50,
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
