import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../../../hooks/useFetch";
import { useMessage } from "../../../../hooks/UseMessage";
import { useStation } from "../../../../hooks/UseStation";
import { useDD } from "../../../../hooks/UseDD";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import { LoadingContext } from "../../Context";
import { useParams } from "react-router-dom";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import UniversalEditableGrid from "../../../../CommonComponents/Grid/EditableGrid/UniversalEditableGrid";
import { itemArray, itemSulfurArray, itemArrayV1 } from "./ItemGridFields";
import { mainwithTempArray, carrierArray, weightArray } from "./HeaderFields";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import "./Signature.css";
import Card from "src/CommonComponents/Card";

const ManualCompletion = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  //URL parameters, shipDoc number
  const shipDoc = params.shipDoc;

  const [serviceError, setServiceError] = useState([]);
  const [serviceSuccess, setServiceSuccess] = useState([]);
  const [carriersFromSetup, setCarriersFromSetup] = useState([]);
  const [shiptosFromSetup, setShiptosFromSetup] = useState([]);
  const canvasRef = useRef();
  //state to indicate scalling operation is done
  const [finishedScaling, setFinishedScaling] = useState(false);
  const signatureData = useRef();

  const fieldOperationId = "MANUALCOMPLETION";
  //hooks
  const [
    _stationInfo,
    _getTerminalSetup,
    _terminalSetup,
    _getStationOperation,
    _stationOperation,
    _messages,
    _setMessages,
  ] = useStation();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
  ] = useMessage();

  const [_dd] = useDD();
  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    ,
    ,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" });
  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" });

  const [
    _printShipDoc,
    ,
    ,
    _errorPrintShipDoc,
    _setErrorPrintShipDoc,
    _successPrintShipDoc,
    _setSuccessPrintShipDoc,
    _loadingPrintShipDoc,
  ] = useFetch(GetFullURL("PrintShippingDoc"), { method: "POST" });

  const [
    _printShipDocLocalService,
    ,
    ,
    _errorPrintShipDocLocalService,
    _setErrorPrintShipDocLocalService,
    _successPrintShipDocLocalService,
    _setSuccessPrintShipDocLocalService,
    _loadingPrintShipDocLocalService,
  ] = useFetch(GetServiceFullURL("PrintPDFNewLOAD"), {
    method: "POST",
    headers: {},
  });
  const [
    _deterSetup,
    _deterSetupData,
    ,
    _errorDeterSetup,
    _setErrorDeterSetup,
    ,
    ,
    _loadingSetupDeter,
  ] = useFetch(GetFullURL("DetermineSetup"), { method: "POST" }, true);
  const cancel = () => {
    navigate(`/TerminalActivity/WXTerminalHome/`);
  };

  const fieldsState = {
    dropDowns: _dd.shipDoc || [],
    shipsDD: shiptosFromSetup,
    carriers: carriersFromSetup,
  };
  let mainFields;
  let itemFields;
  let carrierFields;
  let weightFields;
  mainFields = mainwithTempArray(fieldsState);
  carrierFields = carrierArray(fieldsState);
  weightFields = weightArray(fieldsState);
  itemFields = itemArray;

  const mergedFieldData = (field, dataSource) => {
    return { ...field, dataSource, onChange };
  };

  useEffect(() => {
    _getShipDoc({ data: { Id: shipDoc } }, ({ data: shipDocData }) => {
      if (shipDocData?.signatureData) {
        canvasRef.current.fromDataURL(shipDocData?.signatureData);
      }

      _deterSetup({
        data: {
          LocationId: shipDocData.locationId,
          FieldProcessId: shipDocData.fieldProcessId,
          TransportationModeId: shipDocData.transportationModeId,
          MaterialId: shipDocData.shippingDocItemsComm[0].materialId,
        },
      });
    });
  }, []);

  useEffect(() => {
    _getStationOperation(fieldOperationId, _shipDocData.fieldProcessId);
  }, [_shipDocData.fieldProcessId]);

  useEffect(() => {
    //Get shipto and carrier dropdown from terminal setup
    if (!_deterSetupData.setupData?.orderPartnersComm) {
      return;
    }

    let carrierfromSetupLocal = [];
    let shiptosfromSetupLocal = [];
    _deterSetupData.setupData.partnersInfoComm.forEach((element) => {
      switch (element.partnerTypeId) {
        case "SHIPTO":
          let partnerDD = {
            value: element.id,
            label: element.partnerDescriptor,
          };
          shiptosfromSetupLocal.push(partnerDD);
          break;
        case "CARRIER":
          let carrierDD = {
            value: element.id,
            label: element.partnerDescriptor,
          };

          carrierfromSetupLocal.push(carrierDD);
          break;
        default:
        // code block
      }
    });

    setShiptosFromSetup(shiptosfromSetupLocal);
    setCarriersFromSetup(carrierfromSetupLocal);
  }, [_deterSetupData.setupData?.orderPartnersComm]);

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    //Other way of getting value
    //let x = e.target.getAttribute("data-item");
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    //if item is populated then this is an update from item and hence need to search
    //for a particular item and update it

    _setShipDocData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };
  const contextState = {
    _shipDocData,
    onChange,
  };
  const operationLoading =
    _loadingShipDoc ||
    _loadingSaveShipDoc ||
    _loadingPrintShipDoc ||
    _loadingPrintShipDocLocalService;
  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveShipDoc([]);
        _setSuccessPrintShipDocLocalService([]);
        _setSuccessPrintShipDoc([]);

        break;
      case "error":
        _setErrorSaveShipDoc([]);
        _setErrorShipDoc([]);
        _setErorMessages([]);
        _setErrorPrintShipDoc([]);
        _setErrorPrintShipDocLocalService([]);
        _setMessages([]);
        setServiceError([]);

        break;
      case "warning":
      default:
        break;
    }
  }

  const onChangeItem = (e) => {
    let item;
    let fieldTypeItem;
    const {
      target: { value, name },
    } = e;

    item = e.target.dataset.item;
    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeItem = e.target.dataset.type;

    let newValue;
    switch (fieldTypeItem) {
      case "number":
        newValue = Number(value);
        break;
      default:
        if (value === "select" || value === "SELECT") {
          newValue = "";
        } else {
          newValue = value;
        }

        break;
    }
    const newValueObject = { [name]: newValue };

    let newItems = _shipDocData.shippingDocItemsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.itemNumber == item) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setShipDocData((prevState) => {
      return { ...prevState, shippingDocItemsComm: newItems };
    });
  };
  function handleButtonClickGrid(e) {}
  const onClickGetWeight = () => {
    let changedShipDocData = _shipDocData;
    if (
      changedShipDocData?.inWeight == 0 ||
      changedShipDocData?.inWeight == undefined
    ) {
      _createErrorMessage("145");
      return;
    }

    if (changedShipDocData?.inWeight < 100000) {
      _createErrorMessage("146");
      return;
    }

    if (changedShipDocData.inWeight > 0) {
      changedShipDocData.weightUnitId = "LB";
      changedShipDocData.inWeightStatus = "X";
      changedShipDocData.inWeightStDT = moment().format();
      changedShipDocData.inWeightEnDT = moment().format();
      changedShipDocData.statusId = "INWGHTSHDOC";
      changedShipDocData.loadUnloadinProcess = true;
      changedShipDocData.currentStation = _stationInfo.id;
    }
    if (changedShipDocData.outWeight > 0) {
      if (changedShipDocData?.outWeight < 20000) {
        _createErrorMessage("147");
        return;
      }
      changedShipDocData.weightUnitId = "LB";
      changedShipDocData.outWeightStatus = "X";
      changedShipDocData.outWeightStDT = moment().format();
      changedShipDocData.outWeightEnDT = moment().format();
      changedShipDocData.statusId = "COMPSHPDOC";
      changedShipDocData.loadUnloadinProcess = false;
      changedShipDocData.signatureReady = true;
      changedShipDocData.terminalActivityinProcess = false;
      changedShipDocData.endDT = moment().format();
      changedShipDocData.currentStation = _stationInfo.id;
    }

    _saveShipDoc({ data: changedShipDocData }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
      if (changedShipDocData.outWeight > 0) {
        setFinishedScaling(true);
      }
    });
  };

  return (
    <div className="container-fluid">
      <LoadingContext.Provider value={contextState}>
        {operationLoading && <LoadingScreen />}
        <div
          className="d-flex flex-row justify-content-start mt-2"
          style={{ gap: 5 }}
        >
          <div className="col-4">
            <Card
              cardHeaderProps={{
                headerLabel: "Main",
              }}
              cardBody={mainFields?.map((field, index) => (
                <FieldsReducer
                  field={mergedFieldData(field, _shipDocData)}
                  key={index}
                />
              ))}
              style={{ width: "400px" }}
            />
          </div>
          <div className="col-4">
            <Card
              cardHeaderProps={{
                headerLabel: "Weights",
              }}
              cardBody={weightFields?.map((field, index) => (
                <FieldsReducer
                  field={mergedFieldData(field, _shipDocData)}
                  key={index}
                />
              ))}
              style={{ width: "400px" }}
            />
          </div>

          {/* <Card
            cardHeaderProps={{
              headerLabel: "Carrier",
            }}
            cardBody={carrierFields?.map((field, index) => (
              <FieldsReducer
                field={mergedFieldData(field, _shipDocData)}
                key={index}
              />
            ))}
            style={{ width: "400px" }}
          /> */}

          <div className="col-4">
            <div className="d-flex flex-row">
              <button
                className="btn btn-primary"
                onClick={onClickGetWeight}
                style={{
                  color: "black",
                  backgroundColor: "#08bd55",
                  fontSize: "25px",
                  fontWeight: "bold",
                }}
              >
                Complete BOL
              </button>
              <button
                className="btn btn-primary ms-3"
                onClick={cancel}
                style={{
                  color: "black",
                  backgroundColor: "#DA1212",
                  fontSize: "25px",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <UniversalEditableGrid
          fields={itemFields}
          headerData={_shipDocData}
          itemData={_shipDocData.shippingDocItemsComm ?? []}
          dropDowns={_dd.shipDoc}
          operation={"Create"}
          onChange={onChangeItem}
          handleButtonClickGrid={handleButtonClickGrid}
          ActionColumn={false}
        />

        <MessageLog
          errorMessages={[
            ..._errorShipDoc,
            ..._errorSaveShipDoc,
            ..._errorPrintShipDoc,
            ..._errorPrintShipDocLocalService,
            ..._errorMessages,
            ...serviceError,
            ..._messages,
          ]}
          successMessages={[
            ..._successSaveShipDoc,
            ...serviceSuccess,
            ..._successPrintShipDocLocalService,
            ..._successPrintShipDoc,
          ]}
          clearMessages={clearMessages}
        />
      </LoadingContext.Provider>
    </div>
  );
};

export default ManualCompletion;
