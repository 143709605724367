exports.Location = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    length: 12,
    key: "true",
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    value: "",
    label: "Universal Descriptor",
    length: 50,
  },
  {
    id: "locationTypeId",
    name: "locationTypeId",
    type: "text",
    value: "",
    label: "Location Type Id",
  },
  {
    id: "companyId",
    name: "companyId",
    type: "text",
    value: "",
    label: "Company Id",
  },
  {
    id: "tankFarmId",
    name: "tankFarmId",
    type: "text",
    value: "",
    label: "Tank Farm",
    length: 10,
  },
  {
    id: "name",
    name: "name",
    type: "text",
    value: "",
    label: "Name",
    length: 100,
  },
  {
    id: "name1",
    name: "name1",
    type: "text",
    value: "",
    label: "Name 1",
    length: 100,
  },
  {
    id: "localText",
    name: "localText",
    type: "text",
    value: "",
    label: "Local Text",
  },
  {
    id: "streetAddress",
    name: "streetAddress",
    type: "text",
    value: "",
    label: "Street Address",
    length: 50,
  },
  {
    id: "streetAddress1",
    name: "streetAddress1",
    type: "text",
    value: "",
    label: "StreetAddress 1",
    length: 50,
  },
  {
    id: "city",
    name: "city",
    type: "text",
    value: "",
    label: "City",
    length: 50,
  },
  {
    id: "stateId",
    name: "stateId",
    type: "text",
    value: "",
    label: "State",
  },
  {
    id: "countryId",
    name: "countryId",
    type: "text",
    value: "",
    label: "Country",
  },
  // {
  //   id: "regionId",
  //   name: "regionId",
  //   type: "text",
  //   value: "",
  //   label: "Region Id",
  //   higherLevelKey1: "countryId",
  // },
  {
    id: "postalCode",
    name: "postalCode",
    type: "text",
    value: "",
    label: "Postal Code",
    length: 50,
  },

  {
    id: "splcCode",
    name: "splcCode",
    type: "text",
    value: "",
    label: "Splc Code",
    length: 50,
  },
  {
    id: "taxJurisdictionCode",
    name: "taxJurisdictionCode",
    type: "text",
    value: "",
    label: "Tax Jurisdiction Code",
    length: 20,
  },
  {
    id: "shipDocNumberRange",
    name: "shipDocNumberRange",
    type: "text",
    value: "",
    label: "ShipDoc Number Range",
    ddid: "numberRangeId",
    length: 20,
  },
  {
    id: "timeZone",
    name: "timeZone",
    type: "text",
    value: "",
    label: "Time Zone",
    length: 100,
  },
  {
    id: "personResponsible",
    name: "personResponsible",
    type: "text",
    value: "",
    label: "Person Responsible",
    length: 100,
  },
  {
    id: "shipDocPrintOptions",
    name: "shipDocPrintOptions",
    type: "text",
    value: "",
    label: "ShipDoc Print Options",
    length: 3,
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
