export const ValidateOrderSyncInput = (syncOrderParam, _createErrorMessage) => {
  if (typeof syncOrderParam === "undefined") {
    _createErrorMessage("37");
    return false;
  }

  if (!syncOrderParam.syncDate) {
    _createErrorMessage("39");
    return false;
  }

  // if (!syncOrderParam.locationId) {
  //   _createErrorMessage("38");
  //   return false;
  // }

  return true;
};
