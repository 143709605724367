import React, { useState } from "react";
import { useMessage } from "../../hooks/UseMessage";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput";
import OverviewButtons from "./Components/OverviewButtons";
import { useFetch } from "../../hooks/useFetch";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../CommonComponents/CircleButton";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";

function Index() {
  let navigate = useNavigate();

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [billOfMaterial, setBillOfMaterial] = useState();


  const [showBillOfMaterialList, setShowBillOfMaterialList] = useState(false);


  const [
    _getBillOfMaterialList,
    _billOfMaterialList,
    ,
    ,
    ,
    ,
    ,
    _loadingBillOfMaterialList,
  ] = useFetch(GetFullURL("BillOfMaterialList"), { method: "POST" });

  const [_getBillOfMaterialExists, , , , , , , _loadingBillOfMaterialExist] =
    useFetch(GetFullURL("BillOfMaterialExists"), { method: "POST" });

  const operationLoading =
    _loadingBillOfMaterialList ||
    _loadingBillOfMaterialExist ;

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../Assets/icon/Material.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Bill Of Material Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setBillOfMaterial}
                    document={billOfMaterial}
                    placeholder="Bill Of Material id here..."
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`Bills Of Material`}
                    clickHandler={() => {
                      _getBillOfMaterialList({}, () => {
                        setShowBillOfMaterialList(true);
                      });
                    }}
                  />
                  <OverviewButtons
                    billOfMaterial={billOfMaterial}
                    navigate={navigate}
                    _getBillOfMaterialExists={_getBillOfMaterialExists}
                    _createErrorMessage={_createErrorMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setBillOfMaterial}
              dataList={_billOfMaterialList}
              showListModal={showBillOfMaterialList}
              setShowModal={setShowBillOfMaterialList}
              title="List Of Bill Of Materials Documents"
              columns={[
                { field: "id", headerName: "Bill Of Material ID", width: 250 },
                { field: "billOfMaterialTypeId", headerName: "Type", width: 350 },
                { field: "validFrom", headerName: "Valid From", width: 200 },
                { field: "validTo", headerName: "ValidTo", width: 250 },
              ]}
            />
          </div>
        </div>
        <MessageLog
          errorMessages={[..._errorMessages]}
          successMessages={[]}
          warningMessages={[]}
          clearMessages={clearMessages}
        ></MessageLog>
      </div>
    </section>
  );
}

export default Index;
