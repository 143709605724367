exports.Station = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    key: "true",
    length: 24,
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    value: "",
    label: "Universal Descriptor",
    length: 50,
  },
  {
    id: "localText",
    name: "localText",
    type: "text",
    value: "",
    label: "localText",
  },
  {
    id: "stationTypeId",
    name: "stationTypeId",
    type: "text",
    value: "",
    label: "Station Type Id",
    ddid:"stationTypeId"
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    value: "",
    label: "Location Id",
  },
  {
    id: "ip",
    name: "ip",
    type: "text",
    value: "",
    label: "IP",
    length: 100,
  },
  {
    id: "printerId",
    name: "printerId",
    type: "text",
    value: "",
    label: "Printer Id",
    length: 12,
  },
  {
    id: "numberPrintShipDocCopies",
    name: "numberPrintShipDocCopies",
    type: "number",
    value: "",
    label: "ShipDoc Num copies",
  },
  {
    id: "maxWeight",
    name: "maxWeight",
    type: "number",
    value: "",
    label: "Max Weight",
  },
  {
    id: "preactWeight",
    name: "preactWeight",
    type: "number",
    value: "",
    label: "Preact Weight",
  },
  {
    id: "preactUnitId",
    name: "preactUnitId",
    type: "text",
    value: "",
    label: "Preact Unit id",
    ddid: "unitId",
  },
  {
    id: "stationHome",
    name: "stationHome",
    type: "text",
    value: "",
    label: "Station Home",
  },

  {
    id: "numberPrintShipDocCopies",
    name: "numberPrintShipDocCopies",
    type: "number",
    value: "",
    label: "Number Print Ship Doc Copies",
  },
  {
    id: "transportationModeId",
    name: "transportationModeId",
    type: "text",
    label: "Transportation Mode",
    ddid: "transportationModeId",
  },
  {
    id: "scaleUnitId",
    name: "scaleUnitId",
    type: "text",
    label: "Scale Unit",
    ddid: "unitId",
  },
  {
    id: "meterUnitId",
    name: "meterUnitId",
    type: "text",
    label: "Meter Unit",
    ddid: "unitId",
  },
  {
    id: "scaleApiInterval",
    name: "scaleApiInterval",
    type: "number",
    value: "",
    label: "Scale Api Interval",
  },
  {
    id: "meterApiInterval",
    name: "meterApiInterval",
    type: "nubmer",
    value: "",
    label: "Meter Api Interval",
  },
  {
    id: "scaleTestIncrement",
    name: "scaleTestIncrement",
    type: "number",
    value: "",
    label: "Scale Test Increment",
  },
  {
    id: "meterTestIncrement",
    name: "meterTestIncrement",
    type: "nubmer",
    value: "",
    label: "Meter Test Increment",
  },
  {
    id: "scaleTicketRelevant",
    name: "scaleTicketRelevant ",
    type: "boolean",
    value: "",
    label: "Scale Ticket Relevant",
  },
  {
    id: "meterAdjustment",
    name: "meterAdjustment",
    type: "number",
    value: "",
    label: "Meter Adjustment",
  },
  {
    id: "meterCorrectionFactor",
    name: "meterCorrectionFactor",
    type: "number",
    value: "",
    label: "Meter Correction Factor",
  },
  {
    id: "tankFarmId",
    name: "tankFarmId",
    type: "text",
    value: "",
    label: "Tank Farm Id",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
