import React, { useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import TopPanel from "../../Components/TopPanel";
import { PartnerContext } from "../context";
import { MuiButon } from "../../../../CommonComponents/MuiButton";
import { useNavigate } from "react-router-dom";
export default function PartnerOperationHeader({
  _loadingSaveData,
  _savePartner,
}) {
  let navigate = useNavigate();
  const {
    _partnerData,
    operation,
    setOperation,
    setPartnerId,
    _setPartnerData,
    _validatePartner,
    _validatePartnerData,
    _createErrorMessage,
  } = useContext(PartnerContext);

  const handleSave = () => {
    if (
      _partnerData.partnerTypeId === null ||
      _partnerData.partnerTypeId === undefined
    ) {
      _createErrorMessage("124");
      return;
    }

    _validatePartner({ data: _partnerData }, ({ data: valResult }) => {
      if (!valResult) {
        return;
      }
      _savePartner(
        { data: _partnerData },
        ({ data: NewPartnerData, success: callStatus }) => {
          if (callStatus) {
            //after saving go back to overview screen of issues that has been causing
            let navigationUrl = `/PartnerDriverOverview/`;
            navigate(navigationUrl);
            // if (operation === "Create") {
            // }
            //            setOperation("Change");
          }
          //          _setPartnerData(NewPartnerData);
          //          setPartnerId(NewPartnerData.id);
        }
      );
    });
  };

  return (
    <Paper className="py-2 px-2 d-flex align-items-center justify-space-between customPaper">
      <TopPanel />
      <MuiButon
        title="Save"
        loading={_loadingSaveData}
        onClick={handleSave}
        disabled={operation === "Display"}
      />
    </Paper>
  );
}
