export const itemsGridFields = ({ dropDowns }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    label: "item Num",
    disabled: true,
  },
  {
    id: "locationId",
    name: "locationId",
    label: "Origin Location",
    tag: "select",
    options: dropDowns?.locations || [],
  },
  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    tag: "select",
    options: dropDowns?.materials || [],
  },

  {
    id: "quantity",
    name: "quantity",
    label: "Quantity",
  },
  {
    id: "unitId",
    name: "unitId",
    label: "Unit",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "quantity1",
    name: "quantity1",
    label: "Add Quantity ",
  },
  {
    id: "unit1Id",
    name: "unit1Id",
    label: "Unit",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
];

export const itemsGridTransferTypeFields = ({ dropDowns }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    label: "item Num",
    disabled: true,
  },
  {
    id: "locationId",
    name: "locationId",
    label: "Origin Location",
    tag: "select",
    options: dropDowns?.locations || [],
  },
  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    tag: "select",
    options: dropDowns?.materials || [],
  },

  {
    id: "quantity",
    name: "quantity",
    label: "Quantity",
  },
  {
    id: "unitId",
    name: "unitId",
    label: "Unit",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "quantity1",
    name: "quantity1",
    label: "Add Quantity ",
  },
  {
    id: "unit1Id",
    name: "unit1Id",
    label: "Unit",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "destinationMaterialId",
    name: "destinationMaterialId",
    label: "Destination Material",
    type: "text",

  },
  {
    id: "destinationTankId",
    name: "destinationTankId",
    label: "Destination Tank",
    type: "text",
 
  },
  {
    id: "destLocationId",
    name: "destLocationId",
    label: "Destination Location",
    type: "text",
    tag: "select",
    options: dropDowns?.locations || [],
  },
];

