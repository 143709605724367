import React from "react";
import Button from '@mui/material/Button';
import { ValidateLogisticStationAdminFuncInput } from "./ValidateLogisticStationAdminFuncInput";

const btnsArray = [{ operation: "Delete" }, { operation: "Cancel" }];

export default function OverviewButtons({
  logisticStation,
  navigate,
  _logisticStationExist,
  _createErrorMessage,
  _deleteLogisticStation,
}) {
  const onClick = (operation) => {
    if (operation === "Cancel") {
      let navigationNextUrl = `/LogisticStationOverview`;
      navigate(navigationNextUrl);
      return;
    }

    let validationLSResult = ValidateLogisticStationAdminFuncInput(
      logisticStation,
      _createErrorMessage
    );

    if (!validationLSResult) {
      return;
    }

    _logisticStationExist(
      { data: { Id: logisticStation } },
      ({ data: isExist }) => {
        if (!isExist) {
          _createErrorMessage("77");
          return;
        }
        _deleteLogisticStation({ data: { Id: logisticStation } });
      }
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ operation, noValidation, navPath }, i) => (
        <div style={{ width: "48%", marginBottom: 15 }}>
          <Button
            className="btn btn-primary w-100"
            key={operation}
            onClick={() => onClick(operation, noValidation, navPath)}
          >
            {operation}
          </Button>
        </div>
      ))}
    </div>
  );
}
