import React from "react";
import Button from '@mui/material/Button';
import { ValidateOrderInput } from "./../ValidateOrderInput";

const btnsArray = [
  { operation: "Display" },
  { operation: "Change" },
  { operation: "Create" },
];

export default function OverviewButtons({
  order,
  navigate,
  _getOrderExists,
  _createErrorMessage,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ operation }, i) => (
        <div style={{ width: "48%", marginBottom: 15 }}>
          <Button
            className="btn btn-primary w-100"
            key={i}
            onClick={() => {
              //If creating order no need for any further work just proceed to URL
              if (operation === "Create") {
                navigate(`/Order/Create`);
                return;
              }

              let validationOrderResult = ValidateOrderInput(
                order,
                _createErrorMessage
              );

              if (!validationOrderResult) {
                return;
              }

              _getOrderExists({ data: { Id: order } }, ({ data: isExist }) => {
                if (!isExist) {
                  _createErrorMessage("43");
                  return;
                }
                const navigationUrl = `/Order/${order}/${operation}`;
                navigate(navigationUrl);
              });
            }}
          >
            {operation}
          </Button>
        </div>
      ))}
    </div>
  );
}
