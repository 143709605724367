exports.Message = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    key: "true",
  },
  {
    id: "messageId",
    name: "messageId",
    type: "number",
    value: "",
    label: "Message Num",
  },

  {
    id: "languageId",
    name: "languageId",
    type: "text",
    value: "",
    label: "Language",
    length: 2,
  },
  {
    id: "messageClassId",
    name: "messageClassId",
    type: "text",
    value: "",
    label: "Message Class",
    length: 12,
  },
  {
    id: "messageText",
    name: "messageText",
    type: "text",
    value: "",
    label: "Message Text",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
