export const mainArray = ({ dropDowns, reportDD }) => [
  {
    id: "fromLoadStD",
    name: "fromLoadStD",
    type: "date",
    label: "From Date",
  },
  {
    id: "toLoadStD",
    name: "toLoadStD",
    type: "date",
    label: "To Date",
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    label: "Location Id",
    options: dropDowns?.locations || [],
    tag: "select",
  },
  {
    id: "reportId",
    name: "reportId",
    type: "text",
    label: "Report Type",
    options: reportDD || [],
    tag: "select",
  },
];
