export const URLs = {
 //Host: "http://localhost:8000/",
 Host: "https://tload.app:8000/",
  //Host: "https://tload.dev:8000/",
  Config: "api/Configuration/",
  System: "api/System/",
  UI_Config: "api/UI/",
  MasterData: "api/MasterData/",
  DropDowns: "api/Dropdowns/GetAll",

  // Partner URls
  GetPartner: "api/MasterData/GetPartner",
  PartnerList: "api/MasterData/PartnerSearch",
  PartnerDropDown: "api/Dropdowns/Partner",
  PartnerExists: "api/MasterData/PartnerExists",
  PartnerExistSoft: "api/MasterData/PartnerExistSoft",
  SavePartner: "api/MasterData/SavePartner",
  DeletePartner: "api/MasterData/DeletePartner",
  GetAllPartnersForDelete: "api/MasterData/GetAllPartnersForDelete",
  PartnerSearchForWorkbench: "api/MasterData/PartnerSearchForWorkbench",
  PartnerForceDelete: "api/MasterData/PartnerForceDelete",
  ValidatePartner: "api/MasterData/ValidatePartner",
  GetPartnerTypeExtended: "api/Configuration/GetPartnerTypeExtended",

  // Material URls
  GetMaterial: "api/MasterData/GetMaterial",
  MaterialList: "api/MasterData/MaterialSearch",
  MaterialDropDowns: "api/Dropdowns/Material",
  MaterialExists: "api/MasterData/MaterialExists",
  SaveMaterial: "api/MasterData/SaveMaterial",
  ConversionList: "api/MasterData/GetAllUnitConversions",
  GetAllMaterialsForDelete: "api/MasterData/GetAllMaterialsForDelete",
  DeleteMaterial: "api/MasterData/DeleteMaterial",

  // Order URls
  GetOrder: "api/Order/GetOrder",
  OrderList: "api/Order/OrderSearch",
  OrderDropDowns: "api/Dropdowns/Order",
  OrderExists: "api/Order/OrderExists",
  SaveOrder: "api/Order/SaveOrder",
  CreateShipDocRefOrder: "api/ShippingDoc/CreateShippingDocRefOrder",
  StartOrderSync: "api/Order/StartOrderSync",
  GetFromERPOrder: "api/Order/GetOrderFromERP",
  GetERPOrder: "api/Order/GetERPOrder",
  GetOrderInternalERP: "api/Order/GetOrderInternalERP",
  OrderSearching: "api/Order/OrderSearching",
  DetermineSetup: "api/Order/RunTerminalSetupDeter",

  // InventoryDoc URls
  GetInventoryDoc: "api/InventoryDoc/GetInventoryDoc",
  InventoryDocList: "api/InventoryDoc/SearchInventoryDoc",
  InventoryDocDropDowns: "api/Dropdowns/InventoryDoc",
  InventoryDocExists: "api/InventoryDoc/InventoryDocExists",
  SaveInventoryDoc: "api/InventoryDoc/SaveInventoryDoc",
  SearchInventoryDoc: "api/InventoryDoc/SearchInventoryDoc",
  CreateInventoryDocRefOrder: "api/InventoryDoc/CreateInventoryDocRefOrder",

  // Tank URls
  GetTank: "api/MasterData/GetTank",
  TankList: "api/MasterData/TankSearch",
  TankDropDowns: "api/Dropdowns/Tank",
  TankExists: "api/MasterData/TankExists",
  SaveTank: "api/MasterData/SaveTank",
  GetAllTanksForDelete: "api/MasterData/GetAllTanksForDelete",
  DeleteTank: "api/MasterData/DeleteTank",

  //Shipping Doc URls
  GetShippingDoc: "api/ShippingDoc/GetShippingDoc",
  ShippingDocList: "api/ShippingDoc/SearchShippingDoc",
  ShippingDocDropDowns: "api/Dropdowns/ShippingDoc",
  ShippingDocExists: "api/ShippingDoc/ShippingDocExists",
  SaveShippingDoc: "api/ShippingDoc/SaveShippingDoc",

  PrintShippingDocFromTLOAD: "api/ShippingDoc/PrintShipDoc",
  PrintShippingDoc: "api/ShippingDoc/PrintShipDoc",
  PrintScaleTicket: "api/ShippingDoc/PrintScaleTicket",
  SignatureReady: "api/ShippingDoc/SearchSignatureReadyHeaders",
  InOutWeight: "api/ShippingDoc/SearchWeightInOutHeaders",
  DailyShipReport: "api/ShippingDoc/DailyShipReport",
  ShipDocERPReady: "api/ShippingDoc/DailyShipmentReport",
  OrderLoadingStart: "api/Order/OrderLoadingStart",
  ShipDocsERPReady: "api/ShippingDoc/ShipDocsERPReady",
  SendShipDocERP: "api/ShippingDoc/SendShipDocERP",
  SendShipDocsERP: "api/ShippingDoc/SendShipDocsERP",
  AdvancedShipDocSearch: "api/ShippingDoc/AdvancedShipDocSearch",
  ShipDocsSearching: "api/ShippingDoc/ShipDocsSearching",
  DynamicShipDocSearch: "api/ShippingDoc/ShipDocSearchDynamicFilter",
  ActiveShipDocs: "api/ShippingDoc/ActiveShipDocs",
  ShipDocPDF: "api/ShippingDoc/ShipDocPDF",
  EmailShipDoc: "api/ShippingDoc/EmailShipDoc",
  PrintShipDocERP: "api/ShippingDoc/PrintShipDocERP",
  UpdateFieldReadings: "api/ShippingDoc/UpdateFieldReadings",
  GetPartnerPerPINandType: "api/MasterData/GetPartnersPerPINAndPartnerType",
  GetTRMSetup: "api/Order/GetTerminalSetup",
  ShipDocsVolumeV1: "api/ShippingDoc/ShipDocsVolumeV1",
  BOLProcessTimeChart: "api/ShippingDoc/BOLProcessTimeChart",
  //User URLs
  CreateUser: "api/System/CreateUser",
  Getuser: "api/System/Getuser",
  GetAllUsers: "api/System/GetAllUsers",
  UpdateUser: "api/System/UpdateUser",
  DeleteUser: "api/System/DeleteUser",
  UserDropDowns: "api/Dropdowns/UserDropDown",
  Authenticate: "api/System/Authenticate",

  //Configuration URls
  GetStation: "api/Configuration/GetStationExtended",

  //Gerneral dropdowns URLs
  GetGeneralDropDown: "api/Dropdowns/GeneralDropDowns",

  //Financial Urls
  GetAccount: "api/Financial/GetAccount",
  AccountList: "api/Financial/AccountSearch",
  AccountDropDowns: "api/Dropdowns/FinancialDropDowns",
  AccountExist: "api/Financial/AccountExist",
  SaveAccount: "api/Financial/SaveAccount",
  GetAllAccountsForDelete: "api/Financial/GetAllAccountsForDelete",
  DeleteAccount: "api/Financial/DeleteAccount",

  //Transportation Urls
  GetVehicle: "api/Transportation/GetVehicle",
  VehicleList: "api/Transportation/VehicleSearch",
  VehicleDropDowns: "api/Dropdowns/VehicleDropDowns",
  VehicleExist: "api/Transportation/VehicleExist",
  SaveVehicle: "api/Transportation/SaveVehicle",
  GetAllVehiclesForDelete: "api/Transportation/GetAllVehiclesForDelete",
  DeleteVehicle: "api/Transportation/DeleteVehicle",

  // InventoryDoc URls
  GetBillOfMaterial: "api/BillOfMaterial/GetBillOfMaterial",
  BillOfMaterialList: "api/BillOfMaterial/SearchBillOfMaterial",
  BillOfMaterialDropDowns: "api/Dropdowns/BillOfMaterialDropDowns",
  BillOfMaterialExists: "api/BillOfMaterial/BillOfMaterialExists",
  SaveBillOfMaterial: "api/BillOfMaterial/SaveBillOfMaterial",
  SearchBillOfMaterial: "api/BillOfMaterial/SearchBillOfMaterial",

  // Invoice URls
  GetInvoice: "api/Invoice/GetInvoice",
  InvoiceList: "api/Invoice/SearchInvoice",
  InvoiceDropDowns: "api/Dropdowns/InvoiceDropDowns",
  InvoiceExists: "api/Invoice/InvoiceExists",
  SaveInvoice: "api/Invoice/SaveInvoice",
  SearchInvoice: "api/Invoice/SearchInvoice",

  // FinancialDoc URls
  GetFinancialDoc: "api/FinancialDoc/GetFinancialDoc",
  FinancialDocList: "api/FinancialDoc/SearchFinancialDoc",
  FinancialDocDropDowns: "api/Dropdowns/FinancialDocDropDowns",
  FinancialDocExists: "api/FinancialDoc/FinancialDocExists",
  SaveFinancialDoc: "api/FinancialDoc/SaveFinancialDoc",
  SearchFinancialDoc: "api/FinancialDoc/SearchFinancialDoc",

  // Logistic Station URls
  GetLogisticStation: "api/LogisticStation/GetLogisticStation",
  LogisticStationList: "api/LogisticStation/LogisticStationSearch",
  LogisticStationDropDowns: "api/Dropdowns/LogisticStationDropDowns",
  LogisticStationExist: "api/LogisticStation/LogisticStationExist",
  SaveLogisticStation: "api/LogisticStation/SaveLogisticStation",
  GetAllLogisticStationsForDelete:
    "api/LogisticStation/GetAllLogisticStationsForDelete",
  DeleteLogisticStation: "api/LogisticStation/DeleteLogisticStation",

  // Lease Station URls
  GetLease: "api/Lease/GetLease",
  LeaseList: "api/Lease/LeaseSearch",
  LeaseDropDowns: "api/Dropdowns/LeaseDropDowns",
  LeaseExist: "api/Lease/LeaseExist",
  SaveLease: "api/Lease/SaveLease",
  GetAllLeasesForDelete: "api/Lease/GetAllLeasesForDelete",
  DeleteLease: "api/Lease/DeleteLease",

  //Logs
  AppLog: "api/EntireMessage/GetAppLog",
  ClearAppLog: "api/EntireMessage/ClearAppLog",
  BackgroundJobLog: "api/System/BackgroundJobLog",
  ClearBackgroundJobLog: "api/System/ClearBackgroundJobLog",
  JobSumLog: "api/System/GetJobSumLog",
  ClearJobSumLog: "api/System/ClearJobLogSummary",

  //Basic System
  ClearCahce: "api/System/ClearCache",
  GetGlobalAppData: "api/System/GetGlobalAppData",

  //Common Transactional
  InsertOutbound: "api/CommonTransactional/InsertOutbound",
  UpdateOutbound: "api/CommonTransactional/UpdateOutbound",
  GetOutbound: "api/CommonTransactional/GetOutbound",
  SearchOutbounds: "api/CommonTransactional/SearchOutbounds",
  DeleteOutbound: "api/CommonTransactional/DeleteOutbound",
  OutboundExists: "api/CommonTransactional/OutboundExists",

  //Lab System
  GetTestMethod: "api/LabSystem/GetTestMethod",
  SaveTestMethod: "api/LabSystem/SaveTestMethod",
  TestMethodDropdowns: "api/Dropdowns/TestMethodDropdowns",
  TestMethodExists: "api/LabSystem/TestMethodExists",
  TestMethodList: "api/LabSystem/SearchTestMethod",
  GetAllTestMethodsForDelete: "api/LabSystem/GetAllTestMethodsForDelete",
  DeleteTestMethod: "api/LabSystem/DeleteTestMethod",

  GetLabTest: "api/LabSystem/GetLabTest",
  SaveLabTest: "api/LabSystem/SaveLabTest",
  LabTestDropdowns: "api/Dropdowns/LabTestDropdowns",
  LabTestExists: "api/LabSystem/LabTestExists",
  LabTestList: "api/LabSystem/SearchLabTest",
  GetAllLabTestsForDelete: "api/LabSystem/GetAllLabTestsForDelete",
  DeleteLabTest: "api/LabSystem/DeleteLabTest",

  //TankPhysical
  GetTankPhysical: "api/MasterData/GetTankPhysical",
  SaveTankPhysical: "api/MasterData/SaveTankPhysical",
  TankPhysicalDropdowns: "api/Dropdowns/TankPhysicalDropdowns",
  TankPhysicalExists: "api/MasterData/TankPhysicalExists",
  TankPhysicalList: "api/MasterData/TankPhysicalSearch",
  GetAllTankPhysicalsForDelete: "api/MasterData/GetAllTankPhysicalsForDelete",
  DeleteTankPhysical: "api/MasterData/DeleteTankPhysical",
  GetFileteredTankPhysicals: "api/MasterData/GetFileteredTankPhysicals",
  ForceDeleteTankPhysical: "api/MasterData/ForceDeleteTankPhysical",
  GetTank:"api/MasterData/GetTank"
};
export const ServiceURLs = {
  Host: "http://localhost:8095/",
  PrintPDFNewLOAD: "PrintShipDocPDF",
  PrintScaleTicketPDF: "PrintScaleTicketPDF",
  InitializeLoad: "InitializeLoad",
  getCurrentMTData: "ReceiveCurrentMTData",
  startLoad: "StartLoad",
  stopLoad: "StopLoad",
  RequestWeightMT: "RequestWeightMT",
  GetWeight: "GetWeight",
  GetWeightNew: "GetWeightNew",
  GetControllerData: "GetControllerData",
  StopLoadingController: "StopLoadingController",
  StartLoadingController: "StartLoadingController",
  GetModBusData: "GetModBusData",
  ResetController: "ResetController",
  GetWeightFromScales: "GetWeightFromScales",
};

export const GetFullURL = (endPoint) => {
  let fullUrl = URLs.Host + URLs[endPoint];

  return fullUrl;
};

export const GetServiceFullURL = (endPoint) => {
  let fullUrl = ServiceURLs.Host + ServiceURLs[endPoint];

  return fullUrl;
};
