
export const ValidateMaterialAdminFuncInput = (material, _createErrorMessage) => {

  if (!material) {
    _createErrorMessage("35");
    return false;
  }

  return true;
};
