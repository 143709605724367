import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import BackGround from "../../Assets/LoginScreen/LoginBackground.svg";
import { newUserData, LogOut } from "../../redux/Features/UserSlice";
import { languages } from "../GlobalFiles/Languages";
import Button from "react-bootstrap/Button";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import { useFetch } from "./../../hooks/useFetch";
import { useUser } from "./../../hooks/useUser";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
function Login() {
  const [user, setUser] = useState({
    loginName: "",
    password: "",
    language: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_user, _fullUser] = useUser();
  const [
    _athenticate,
    userData,
    setUserData,
    _errorUser,
    _setErrorUser,
    _successUser,
    _setsuccessUser,
    _loadingAuthenticate,
  ] = useFetch(GetFullURL("Authenticate"), { method: "POST" }, true);
  useEffect(() => {
    if (_fullUser?.isAuthenticated) {
      if (_user.defaultUrl) {
        navigate(_user.defaultUrl);
      }

      navigate("/Home");
    }
  });
  function changeHandler(e) {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setsuccessUser([]);
        break;
      case "error":
        _setErrorUser([]);

        break;
      case "warning":
        break;
      default:
        break;
    }
  }
  const operationLoading = _loadingAuthenticate;
  return (
    <div
      className="Background"
      style={{
        backgroundImage: `url(${BackGround})`,
      }}
    >
      <div className="containerLogin">
        {operationLoading && <LoadingScreen />}
        <form className="Form">
          <div className="mb-3">
            <label id="UserIDlabel" htmlFor="user">
              User ID
            </label>
            <input
              type="text"
              className="form-control"
              id="user"
              aria-describedby="emailHelp"
              placeholder="Enter User ID"
              onChange={changeHandler}
              name="loginName"
              value={user.loginName}
            />
            <small id="emailHelp" className="form-text text-muted">
              User ID is Case Sensitve.
            </small>
          </div>
          <div className="mb-3">
            <label id="userPassLabel" htmlFor="Password">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="Password"
              placeholder="Password"
              onChange={changeHandler}
              name="password"
              value={user.password}
            />
          </div>
          <div className="mb-3">
            <label id="languageLabel" htmlFor="Language">
              Language
            </label>

            <select
              type="text"
              className="form-control"
              id="Language"
              onChange={changeHandler}
              value={user.language}
              name="language"
            >
              {languages.map((l, i) => {
                return (
                  <option key={i} value={l.key}>
                    {l.Lable}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3 form-check" style={{ position: "relative" }}>
            <label className="checkboxContainer">
              Remember Me
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </div>
          <Button
            disabled={_loadingAuthenticate}
            type="button"
            onClick={() => {
              _athenticate({ data: user }, ({ data: userData }) => {
                if (userData == null) {
                  dispatch(LogOut());
                  return;
                }
                dispatch(newUserData(userData));

                localStorage.setItem("token", userData.token);
                localStorage.setItem("id", userData.user.id);

                if (userData.user.defaultUrl) {
                  navigate(userData.user.defaultUrl);
                } else {
                  navigate("/Home");
                }
              });
            }}
            className="btn"
          >
            Login
          </Button>
        </form>
      </div>
      <MessageLog
        errorMessages={[..._errorUser]}
        successMessages={[..._successUser]}
        warningMessages={[]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}

export default Login;
