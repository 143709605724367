import React, { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch";
import { useMessage } from "../../../../hooks/UseMessage";
import { GetFullURL } from "../../../../CommonComponents/GlobalConfFiles/URLs";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import ListModal from "../../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import OverviewButtons from "./Components/OverviewButtons";
import CircleButton from "../../../../CommonComponents/CircleButton";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import { useNavigate } from "react-router-dom";
import cardlogo from "../../../../Assets/cardLogo.svg";

function SendAllShipDocsERP(props) {
  let navigate = useNavigate();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [shippingDocs, setShippingDocs] = useState();
  const [showList, setShowList] = useState(false);

  const [
    _getShippingDocList,
    _shippingDocListData,
    _setShippingDocListData,
    _errorGetShippingDocList,
    _setErrorGetShippingDocList,
    _successGetShippingDocList,
    _setSuccessGetShippingDocList,
    _loadingtShippingDocList,
  ] = useFetch(GetFullURL("ShipDocsERPReady"), { method: "POST" }, true);

  const [
    _sendShipDocsERP,
    _sendShipDocsERPData,
    _setSendShipDocsERPData,
    _errorSendShipDocsERP,
    _setErrorSendShipDocsERP,
    _successSendShipDocsERP,
    _setSuccessSendShipDocsERP,
    _loadingSendShipDocsERP,
  ] = useFetch(GetFullURL("SendShipDocsERP"), { method: "POST" }, true);

  const operationLoading = _loadingtShippingDocList || _loadingSendShipDocsERP;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSendShipDocsERP([]);
        _setSuccessGetShippingDocList([]);

        break;
      case "error":
        _setErorMessages([]);
        _setErrorSendShipDocsERP([]);
        _setErrorGetShippingDocList([]);
        break;
      default:
        break;
    }
  }

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                    <img src={cardlogo} alt="" />
                  </span>
                  Send ALL ShipDocs to ERP/SAP
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of ShipDocs ERP Ready`}
                    clickHandler={() => {
                      _getShippingDocList({}, () => {
                        setShowList(true);
                      });
                    }}
                  />
                  <OverviewButtons
                    navigate={navigate}
                    _sendShipDocsERP={_sendShipDocsERP}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setShippingDocs}
              dataList={_shippingDocListData}
              showListModal={showList}
              setShowModal={setShowList}
              title="List Of Orders"
              columns={[
                { field: "id", headerName: "ShipDoc", width: 100 },
                {
                  field: "shipDocDate",
                  headerName: "Ship Doc Date",
                  width: 200,
                },
                { field: "locationId", headerName: "Location", width: 200 },
                { field: "shiptoId", headerName: "ShipTo", width: 200 },

                { field: "statusId", headerName: "Status", width: 200 },
                { field: "netWeight", headerName: "Net Weight", width: 200 },
              ]}
            />
          </div>
        </div>
        <MessageLog
          errorMessages={[..._errorMessages, ..._errorSendShipDocsERP]}
          successMessages={[]}
          warningMessages={[]}
          clearMessages={clearMessages}
        ></MessageLog>
      </div>
    </section>
  );
}

export default SendAllShipDocsERP;
