export const mainArray = ( {dropDowns}) => [
    {
        id: "tableName",
        name: "tableName",
        type: "text",
        label: "Table Name",
        options: dropDowns?.tablesInCache || [],
        tag: "select",
    },
  ];

  