import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { useFetch } from "../../../hooks/useFetch";
import { useMessage } from "../../../hooks/UseMessage";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FieldsReducer } from "../../../CommonComponents/Reducer/Index";
import { carrierArray } from "./Fields";

function StartLoading(props) {
  const [usedStation, setUsedStation] = useState();

  const [cookie, setCookie] = useState();
  const userState = useSelector((state) => state.User.user);
  const userStation = userState.stationId;
  let paramObj = props.computedMatch.params;
  let navigate = useNavigate();
  let shipDoc = paramObj.shipDoc;

  const [
    _getStationData,
    _stationData,
    _setStationData,
    _errorStationData,
    _setErrorStationData,
    _successStationData,
    _setSuccessStationData,
    _loadingStationData,
  ] = useFetch(GetFullURL("GetStation"), { method: "POST" });

  const [
    _getLoadInitialize,
    _loadLoadInitializeData,
    _setLoadLoadInitializeData,
    ,
    ,
    ,
    ,
    _loadingLoadInitialize,
  ] = useFetch("http://localhost:8089/GetInitializeLoad", {
    method: "GET",
    headers: {},
  });
  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    _successShipDoc,
    _setSuccessShipDoc,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" });

  const [
    _getShipDocDD,
    _shipDocDDData,
    ,
    _errorShipDocDD,
    setErrorShipDocDD,
    _successShipDocDD,
    _setSuccessShipDocDD,
    _loadingtShipDocDD,
  ] = useFetch(GetFullURL("ShippingDocDropDowns"), { method: "POST" });

  const [
    _saveShipDocData,
    _shipDocSaveData,
    _setShipDocSaveData,
    _errorSaveShipDocData,
    _setErrorSaveShipDocData,
    _successSaveShipDocData,
    _setSuccessSaveShipDocData,
    _loadingSaveShipDocData,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" });

  const operationLoading =
    _loadingStationData ||
    _loadingShipDoc ||
    _loadingtShipDocDD ||
    _loadingStationData ||
    _loadingSaveShipDocData ||
    _loadingLoadInitialize;

  let storedCookie;

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  useEffect(() => {
    let usedStation = "";
    storedCookie = Cookies.get("station");

    if (!userStation) {
      if (!storedCookie) {
        _createErrorMessage("1");
        return;
      }
    }

    usedStation = storedCookie ? storedCookie : userStation;

    setUsedStation(usedStation);
    _getStationData({ data: { id: usedStation } });
    // _getShipDoc({ data: { Id: shipDoc } }, ({ data: shipDocData }) => {
    //   if (shipDocData.loadStDT != null) {
    //     let { messageText } = issueMessage(language, "3");
    //     setFrontendError((current) => {
    //       return [...current, messageText];
    //     });
    //   }
    // });
    _getShipDocDD();
  }, [shipDoc]);

  const contextState = {
    _stationData,
    station: cookie,
  };

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessStationData([]);
        _setSuccessShipDoc([]);
        _setSuccessShipDocDD([]);
        _setSuccessSaveShipDocData([]);
        break;
      case "error":
        _setErrorStationData([]);
        _setErrorSaveShipDocData([]);
        setErrorShipDocDD([]);
        _setErrorShipDoc([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    // keyboardRef.current.setInput(newValue);
    // _setShipDocData((prevState) => {
    //   return { ...prevState, ...newValueObject };
    // });
  };

  const fieldsState = {
    dataSource: _shipDocData,
    dropDowns: _shipDocDDData || [],
    onChange,
  };

  const fieldsToRender = carrierArray(fieldsState);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field };

    return newFieldObject;
  };

  return (
    <React.Fragment>
      {operationLoading && <LoadingScreen />}
      <div
        style={{
          width: "50%",
        }}
      >
        <div>
          <fieldset
            className="border px-3 py-4 mt-3 fields-wrapper"
            style={{
              boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
              borderRadius: 20,
            }}
          >
            <legend style={{ fontWeight: 600 }}>
              Start Loading Without Order
            </legend>
            {fieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </fieldset>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "10px 0px",
            }}
          >
            {_stationData.checkin ? (
              <Button
                variant="primary"
                active="true"
                size="lg"
                onClick={() => {
                  // if (!_shipDocData.carrierId) {
                  //   let { messageText } = issueMessage(language, "5");
                  //   setFrontendError((current) => {
                  //     return [...current, messageText];
                  //   });
                  //   return;
                  // }
                  // if (!_shipDocData.driverId) {
                  //   let { messageText } = issueMessage(language, "7");
                  //   setFrontendError((current) => {
                  //     return [...current, messageText];
                  //   });
                  //   return;
                  // }
                  let navigationNextUrl = `/ShipTo/${_shipDocData.id}/`;
                  // _shipDocData.checkedStatus = "X";
                  // _shipDocData.checkedStDT = new Date().toISOString();
                  // _shipDocData.checkedEnDT = new Date().toISOString();
                  // _shipDocData.loadStDT = new Date().toISOString();
                  // _shipDocData.statusId = "CHECKINSHDOC";
                  // _saveShipDocData(
                  //   { data: _shipDocData },
                  //   ({ data: newShipDocData }) => {
                  //     _setShipDocData(newShipDocData);
                  //     //if the station in NOT marked as in weight relevant then go back to order
                  //     //search page

                  //     _getLoadInitialize({}, () => {
                  //       if (_stationData.inWeight === false) {
                  //         navigationNextUrl = `/StartLoading/`;
                  //       }
                  //       navigate(navigationNextUrl);
                  //     });
                  //   }
                  // );
                  _getLoadInitialize({}, () => {
                    if (_stationData.inWeight === false) {
                      navigationNextUrl = `/StartLoading/`;
                    }
                    navigate(navigationNextUrl);
                  });
                }}
              >
                Next
              </Button>
            ) : null}
            <Button
              variant="primary"
              active="true"
              size="lg"
              onClick={() => {
                let navigationNextUrl = `/StartLoading/`;
                navigate(navigationNextUrl);
              }}
              style={{
                marginLeft: 10,
              }}
            >
              Cancel
            </Button>
          </div>
        </div>

        <MessageLog
          errorMessages={[
            ..._errorShipDoc,
            ..._errorStationData,
            ..._errorShipDocDD,
            ..._errorSaveShipDocData,
            ..._errorMessages,
          ]}
          successMessages={[
            ..._successShipDoc,
            ..._successStationData,
            ..._successShipDocDD,
            ..._successSaveShipDocData,
          ]}
          clearMessages={clearMessages}
        ></MessageLog>
      </div>
    </React.Fragment>
  );
}

export default StartLoading;
