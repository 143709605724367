
export const ValidateVehicleInput = (vehicle, _createErrorMessage) => {

  if (!vehicle) {
    _createErrorMessage("63");
    return false;
  }

  return true;
};
  