/** @format */

import React from "react";
import Button from '@mui/material/Button';
import { ValidateTankInput } from "../ValidateTankInput";

const btnsArray = [
  { buttonOperation: "Display" },
  { buttonOperation: "Change" },
  { buttonOperation: "Create" },
];

export default function OverviewButtons({
  tank,
  navigate,
  _getTankExists,
  _createErrorMessage,
}) {
  const onClick = (buttonOperation, noValidation, navPath) => {
    let navigationUrl;
    if (buttonOperation === "Create") {
      navigationUrl = `/Tank/Create`;
      navigate(navigationUrl);
      return;
    }

    let validationTankResult = ValidateTankInput(tank, _createErrorMessage);

    if (!validationTankResult) {
      return;
    }

    _getTankExists({ data: { Id: tank } }, ({ data: isExist }) => {
      if (!isExist) {
        _createErrorMessage("55");
        return;
      }
      const navigationUrl = `/Tank/${tank}/${buttonOperation}`;
      navigate(navigationUrl);
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ buttonOperation, noValidation, navPath }, i) => (
        <div style={{ width: "48%", marginBottom: 15 }} key={i}>
          <Button
            className="btn btn-primary w-100"
            onClick={() => onClick(buttonOperation, noValidation, navPath)}
          >
            {buttonOperation}
          </Button>
        </div>
      ))}
    </div>
  );
}
