import InfoCardHeader from "../../InfoCardHeader";
import KeyboardInputV1, { IKeyboardInputPropsV1 } from "../KeyboardButtonInput";

interface IKeyboardButtonInputProps {
  cardlogo: string;
  keyboardInputProps: IKeyboardInputPropsV1;
  headerLabel: string;
}
export const KeyboardButtonInput = (props: IKeyboardButtonInputProps) => {
  const { cardlogo, keyboardInputProps, headerLabel } = props;
  return (
    <div
      className="m-4 row"
      style={{ borderRadius: "15px", backgroundColor: "white" }}
    >
      <div className="row col-3">
        <InfoCardHeader cardlogo={cardlogo} headerLabel={headerLabel} />
      </div>
      <div className="row">
        <KeyboardInputV1 {...keyboardInputProps} />
      </div>
    </div>
  );
};

export default KeyboardButtonInput;
