import React, { useContext , useState} from "react";
import { mainDataArray, enhancedDDInputArray } from "./fields";
import { TestMethodContext } from "../context";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import TestMethodField from "../../../../CommonComponents/EnhancedFields/TestMethod/TestMethodField";
import TestMethodList from "../../../../CommonComponents/DropDowns/TestMethod/TestMethodList";

export default function HomeTab() {
  const { _testMethodData, onChange, operation, dropDowns, testMethodType ,_getTestMethodsList , _testMethodsList} =
    useContext(TestMethodContext);

  const [showTestMethodList, setShowTestMethodList] = useState(false);
  const [testMethodFieldName, setTestMethodFieldName] = useState(); 

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: _testMethodData,
      onChange,
      disabled: field.displayOnly,
    };

    if (testMethodType) {
      if (field.id === "testMethodTypeId") {
        newFieldObject.disabled = true;
      }
    }

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        newFieldObject.disabled =
          newFieldObject.key ||
          newFieldObject.displayOnly ||
          newFieldObject.editableCreateONLY;
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };

  const fieldsState = {
    dataSource: _testMethodData,
    onChange,
    dropDowns,
  };

  const mainDataArrayToRender       = mainDataArray(fieldsState);
  const enhancedDDFieldsToRender    = enhancedDDInputArray(fieldsState);
  
  var parentTestMethodField = enhancedDDFieldsToRender.find(({ id }) => id === "parentTestMethod");
  var refTestMethodField    = enhancedDDFieldsToRender.find(({ id }) => id === "refTestMethod");

  return (
    <div className="row">
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: "auto" }}>
          <div className="cardHeader">
            <h2>Main Data </h2>
          </div>
          <div className="cardBody">
            {mainDataArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: "auto" }}>
          <div className="cardHeader">
            <h2>Reference</h2>
          </div>
          <div className="cardBody">
               <TestMethodField
              field={mergedFieldData(parentTestMethodField)}
              setShowTestMethodList={setShowTestMethodList}
              _getTestMethodsList={_getTestMethodsList}
              setTestMethodFieldName={setTestMethodFieldName}
            />
                <TestMethodField
              field={mergedFieldData(refTestMethodField)}
              setShowTestMethodList={setShowTestMethodList}
              _getTestMethodsList={_getTestMethodsList}
              setTestMethodFieldName={setTestMethodFieldName}
            />
          </div>
        </div>
      </div>
      <TestMethodList
        _testMethodList={_testMethodsList}
        showTestMethodList={showTestMethodList}
        setShowTestMethodList={setShowTestMethodList}
        testMethodFieldName={testMethodFieldName}
        onChange={onChange}
      />
    </div>
  );
}
