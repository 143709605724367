/** @format */

import React, { useContext, useState } from "react";
import { mainDataArray, partnersArray, shippingArray } from "./Fields";
import { InvoiceContext } from "../Contex";
import { FieldsReducer } from "./../../../../CommonComponents/Reducer/Index";
import PartnerField from "../../../../CommonComponents/EnhancedFields/Partner/PartnerField";
import PartnerList from "../../../../CommonComponents/DropDowns/Partner/PartnerList";
export default function MainTab() {
  const {
    onChange,
    operation,
    dropDowns,
    _invoiceData,
    _partnerList,
    _getPartnerList,
  } = useContext(InvoiceContext);

  const [showList, setShowList] = useState(false);
  const [partnerFieldName, setPartnerFieldName] = useState();

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _invoiceData, onChange };

    //Disabling the invoice type after the create becuase number range depends on it
    if (field.editableCreateONLY === true) {
      let value = _invoiceData["id"];
      if (value) {
        newFieldObject.disabled = true;
      }
    }

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _invoiceData,
    dropDowns,
  };

  const mainDataFieldsToRender = mainDataArray(fieldsState);
  const partnerFieldsRender = partnersArray(fieldsState);
  const shippingFieldsRender = shippingArray(fieldsState);

  var soldtofield = partnerFieldsRender.find(({ id }) => id === "soldtoId");
  var driverfield = partnerFieldsRender.find(({ id }) => id === "driverId");
  var shiptofield = partnerFieldsRender.find(({ id }) => id === "shiptoId");
  var carrierfield = partnerFieldsRender.find(({ id }) => id === "carrierId");
  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Main Data </h2>
            </div>
            <div className="cardBody">
              {mainDataFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Partners</h2>
            </div>
            <div className="cardBody">
              <PartnerField
                field={mergedFieldData(soldtofield)}
                setShowList={setShowList}
                _getPartnerList={_getPartnerList}
                setPartnerFieldName={setPartnerFieldName}
                dropDownApiPartnerType="SOLDTO"

              />
              <PartnerField
                field={mergedFieldData(shiptofield)}
                setShowList={setShowList}
                _getPartnerList={_getPartnerList}
                setPartnerFieldName={setPartnerFieldName}
                dropDownApiPartnerType="SHIPTO"
              />
              <PartnerField
                field={mergedFieldData(driverfield)}
                setShowList={setShowList}
                _getPartnerList={_getPartnerList}
                setPartnerFieldName={setPartnerFieldName}
                dropDownApiPartnerType="DRIVER"
              />
              <PartnerField
                field={mergedFieldData(carrierfield)}
                setShowList={setShowList}
                _getPartnerList={_getPartnerList}
                setPartnerFieldName={setPartnerFieldName}
                dropDownApiPartnerType="CARRIER"
              />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Shipping</h2>
            </div>
            <div className="cardBody">
              {shippingFieldsRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <PartnerList
            _partnerList={_partnerList}
            showPartnerList={showList}
            setShowPartnerList={setShowList}
            partnerFieldName={partnerFieldName}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
}
