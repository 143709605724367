var Implementation = ["implementationType", "implementationUse"];
var BusinessObject = ["implementation", "businessObjectCategory"];
var Activity = ["activityType"];
var Location = [
  "locationType",
  "country",
  "region",
  "company",
  "tankFarm",
  "numberRange",
  "state",
];
var User = ["Language", "station", "userType"];
var Message = ["messageClass", "language"];
var Station = ["location", "unit", "transportationMode", "stationType", "tankFarm"];
var Incoterm = ["titlePassLocation"];
var StationAssigment = ["station", "fieldDataPoint"];
var FieldDataPoint = [
  "fieldDataPointType",
  "measurementType",
  "unit",
  "measurementSource",
];
var Unit = ["unitType"];
var Region = ["country"];
var UnitConversion = ["Unit"];
var PartnerType = ["partnerNumberRange"];
var MaterialType = ["materialNumberRange"];
var DocumentType = ["documentCategory", "businessObject", "numberRange"];
var Feed = ["validationProcedure", "transactionDateDetermination", "source"];
var Source = ["dataTranslationType"];
var ValidationCondition = [
  "initialStatus",
  "sentStatus",
  "errorStatus",
  "openStatus",
];
var Component = ["componentType"];

var Company = ["currency", "region", "country"];

var PartnerLocation = ["partner", "Location"];

var ShipDocEvent = ["Event"];

var CurrentFieldData = ["unit", "status"];

var StationOperation = [
  "station",
  "fieldOperation",
  "fieldProcess",
  "shipDocStatus",
];

var StationProcessing = ["station", "fieldOperation"];

var ShipDocType = ["documentCategory", "businessObject", "shipDocNumberRange"];

var OrderType = [
  "documentCategory",
  "businessObject",
  "orderNumberRange",
  "shipDocType",
];

var StationTerminalSetup = ["station", "fieldProcess", "partner"];

var OrderStatus = ["statusType", "statusUse", "statusClass"];

var ShipDocStatus = ["statusType", "statusUse", "statusClass"];

var Report = ["commGroup"];

var CommGroupAssigment = ["commGroup"];

var TransportationMode = ["modeOfTransportType"];

var Source = ["dataTranslationType"];

var SubLocation = [
  "subLocationType",
  "country",
  "region",
  "company",
  "tankFarm",
  "numberRange",
];

var InvDocType = ["documentCategory", "businessObject", "invDocNumberRange"];

var StageProcedureStep = ["stageProcedure", "fieldOperation"];

var Account = ["currency", "accountType"];

var AccountType = ["accountNumberRange"];

var VehicleType = ["vehicleNumberRange"];

var BillOfMaterialType = ["billOfMaterialNumberRange"];

var InvoiceType = ["documentCategory", "businessObject", "invoiceNumberRange"];

var InvoiceStatus = ["statusType", "statusUse", "statusClass"];

var FinancialDocType = ["financialDocNumberRange"];

var LeaseType = ["leaseNumberRange"];

var LogisticStationType = ["logisticStationNumberRange"];

var LoadArea = ["location"];

var LoadBay = ["loadArea"];

var LoadArm = ["loadBay"];

var DeterLTFMTermSetup = ["location", "transportationMode", "fieldProcess"];

var OutboundType = ["transmissionMethod", "outboundStatus"];

var OutboundStatus = ["statusType", "statusUse", "statusClass"];

var OutboundProcedureDetail = ["outboundProcedure", "outboundType"];

var OutboundProcedure = ["outboundStatus"];

var BackgroundJob = ["commGroup"];

var PhysicalDocType = ["physicalDocNumberRange"];

var TankFarm = ["Location"];

var LabTestType = ["labTestNumberRange"];


export const TableDropDowns = {
  LabTestType,
  PhysicalDocType,
  OutboundProcedure,
  OutboundProcedureDetail,
  OutboundStatus,
  OutboundType,
  LoadArm,
  LoadBay,
  LoadArea,
  LogisticStationType,
  LeaseType,
  FinancialDocType,
  InvoiceStatus,
  InvoiceType,
  BillOfMaterialType,
  AccountType,
  VehicleType,
  Account,
  StageProcedureStep,
  Implementation,
  Feed,
  BusinessObject,
  Activity,
  Location,
  User,
  Message,
  Station,
  StationAssigment,
  FieldDataPoint,
  Unit,
  Region,
  UnitConversion,
  Incoterm,
  PartnerType,
  DocumentType,
  Source,
  ValidationCondition,
  Component,
  Company,
  PartnerLocation,
  ShipDocEvent,
  CurrentFieldData,
  StationOperation,
  StationProcessing,
  ShipDocType,
  OrderType,
  StationTerminalSetup,
  OrderStatus,
  ShipDocStatus,
  Report,
  CommGroupAssigment,
  MaterialType,
  TransportationMode,
  Source,
  SubLocation,
  InvDocType,
  DeterLTFMTermSetup,
  BackgroundJob,
  TankFarm,
};
