import React from "react";
import Modal from "react-bootstrap/Modal";
import { DataGrid } from '@mui/x-data-grid';
import Button from "react-bootstrap/Button";

export default function TestMethodList({
  _testMethodList,
  showTestMethodList,
  setShowTestMethodList,
  testMethodFieldName,
  onChange,
  
}) {


  function handleOnSelect(selectedId) {
    onChange({
      target: { value: selectedId[0], name: testMethodFieldName, type: "text"  },
    });
    setShowTestMethodList(false);
  } 
  debugger;
  return (
    
    <Modal
      show={showTestMethodList}
      size="xl"
      onHide={() => setShowTestMethodList(false)}
      dialogClassName="modal-90w modal-dialog-scrollable"
      aria-labelledby="example-custom-modal-styling-title"
      className="partner-list-modal"
    >
      <Modal.Header
        style={{
          background: "#d4e1f5",
        }}
      >
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{
            fontWeight: 700,
          }}
        >
          List of TestMethods
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {_testMethodList?.length && (
          <div style={{ height: '50vh', width: '100%' }}>
         <DataGrid
         rows={_testMethodList || []}
         columns={[
          { field: "id", headerName: "Test Method ID", width: 250 },
          { field: "testMethodClassId", headerName: "Type", width: 250 },
          { field: "createdBy", headerName: "Created By", width: 350 },
         ]}
         pageSize={10}
         rowsPerPageOptions={[5]}
         onSelectionModelChange={selectedId => handleOnSelect(selectedId)}
       />
       </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="offset-11 col-1">
          <Button
            variant="danger"
            onClick={() => {
              setShowTestMethodList(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
