export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Tank ID",
    key: true,
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    label: "Name",
  },
  {
    id: "code",
    name: "code",
    type: "text",
    label: "Code",
  },
  {
    id: "tankFarmId",
    name: "tankFarmId",
    tag: "select",
    options: dropDowns?.tankFarms || [],
    type: "text ",
    label: "Tank Farm",
  },
  {
    id: "tankGroupId",
    name: "tankGroupId",
    tag: "select",
    options: dropDowns?.tankGroups || [],
    type: "text ",
    label: "Tank Group",
  },
  {
    id: "strappingTableId",
    name: "strappingTableId",
    tag: "select",
    label: "Strapping Table",
    options: dropDowns?.strappingTable || [],
  },
  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    type: "text",
    tag: "enhancedInput",
  },
  {
    id: "locationId",
    name: "locationId",
    tag: "select",
    options: dropDowns?.locations || [],
    type: "text ",
    label: "Location",
  },
  {
    id: "erpCode",
    name: "erpCode",
    label: "ERP Code",
    type: "text",
  },
];

export const unitsArray = ({ dropDowns, dataSource }) => [
  {
    id: "capacity",
    name: "capacity",
    type: "number",
    label: "Capacity",
  },
  {
    id: "capacityUoMId",
    name: "capacityUoMId",
    tag: "select",
    label: "Volume UoM",
    options: dropDowns?.volumeUnits || [],
  },
  {
    id: "volume",
    name: "volume",
    type: "number",
    label: "Volume",
  },
  {
    id: "volumeUoMId",
    name: "volumeUoMId",
    tag: "select",
    label: "Volume UoM",
    options: dropDowns?.volumeUnits || [],
  },
  {
    id: "maxVolume",
    name: "maxVolume",
    type: "number",
    label: "Max Volume",
  },
  {
    id: "maxVolumeUoMId",
    name: "maxVolumeUoMId",
    tag: "select",
    label: "Max Volume UoM",
    options: dropDowns?.volumeUnits || [],
  },
  {
    id: "minVolume",
    name: "minVolume",
    type: "number",
    label: "Min Volume",
  },
  {
    id: "minVolumeUoMId",
    name: "minVolumeUoMId",
    tag: "select",
    label: "Min Volume UoM",
    options: dropDowns?.volumeUnits || [],
  },
];
export const readingsArray = ({ dropDowns }) => [
  {
    id: "temperature",
    name: "temperature",
    label: "Temperature",
    type: "number",
  },
  {
    id: "temperatureUnitId",
    name: "temperatureUnitId",
    label: "Temperature Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.tempUnits || [],
  },
  {
    id: "density",
    name: "density",
    label: "Density",
    type: "number",
  },
  {
    id: "densityUnitId",
    name: "densityUnitId",
    label: "Density Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.weightUnits || [],
  },
  {
    id: "gravity",
    name: "gravity",
    label: "Gravity",
    type: "number",
  },
  {
    id: "specificGravity",
    name: "specificGravity",
    label: "Specific Gravity",
    type: "number",
  },
  {
    id: "lastPhysicalDocId",
    name: "lastPhysicalDocId",
    label: "Last Tank Phyiscal Doc",
    type: "text",
    disabled: true,
  },
 
];
