/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFetch";
import { useMessage } from "../../../../hooks/UseMessage";
import OverviewInput from "../../../../CommonComponents/InitialInput/OverviewInput";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import ListModal from "../../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../../../CommonComponents/CircleButton";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../../CommonComponents/GlobalConfFiles/URLs";
import OverviewButtons from "./Components/OverviewButtons";
import cardlogo from "../../../../Assets/cardLogo.svg"

export default function AdminFunctionOverview(props) {

  let navigate = useNavigate();
  const [accountParam, setAccountParam] = useState();
  const [showAccountsList, setShowAccountsList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  
  const [
    _deleteAccount,
    ,
    _setDeleteAccountData,
    _errorDeleteAccountData,
    _setErrorDeleteAccountData,
    _successDeleteAccountData,
    _setSuccessDeleteAccountData,
    _loadingDeleteAccountData,
  ] = useFetch(GetFullURL("DeleteAccount"), { method: "POST" });

  const [_getAccountsList, _accountsList, , , , , , _loadingAccountsList] =
    useFetch(GetFullURL("GetAllAccountsForDelete"), { method: "POST" });

  const [_accountExist, , , , , , , _loadingAccountExist] = useFetch(GetFullURL("AccountExist"),{ method: "POST" });

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessDeleteAccountData([]);
      
        break;
      case "error":
        _setErorMessages([]);
        _setErrorDeleteAccountData([]);
        
        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading =  _loadingAccountsList  || _loadingAccountExist ;

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../../../Assets/icon/Admin.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Account Admin Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setAccountParam}
                    document={accountParam}
                    accountRadius={true}
                    placeholder={`Enter or Choose Id`}
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of Accounts`}
                    clickHandler={() => {
                      _getAccountsList({},() => {setShowAccountsList(true);});                  
                    }}
                  />
                  <OverviewButtons
                    account={accountParam}
                    navigate={navigate}
                    _accountExist={_accountExist}
                    _createErrorMessage={_createErrorMessage}
                    _deleteAccount={_deleteAccount}
              
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages, ..._errorDeleteAccountData ]}
              successMessages={[..._successDeleteAccountData]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
             <div className="cardAlert">
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setAccountParam}
              dataList={_accountsList}
              showListModal={showAccountsList}
              setShowModal={setShowAccountsList}
              title="List Of Accounts"
              columns={[
                { field: "id", headerName: "Account ID", width: 100 },
                { field: "accountTypeId", headerName: "Account Type", width: 250 },
                { field: "accountName", headerName: "Name", width: 350 },
                ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

