import React, { useMemo } from "react";
import TruckSvg from "../../Assets/SVGs/TruckTanker.svg";
import "./index.scss";

export default function _TruckAnimation({
  compartCount = 0,
  activeIndex = -1,
  fillPerCent = 0,
}) {
  const renderedArray = useMemo(
    () =>
      new Array(compartCount).fill(0).map((elem, index) => ({
        value: index === activeIndex ? fillPerCent : 0,
      })),
    [activeIndex, compartCount, fillPerCent]
  );
  return (
    <div className="w-80 truck-container">
      <img src={TruckSvg} className="w-80 h-auto" />
      <div id="tank">
        {renderedArray.map((section, index) => (
          <div className="compartment" key={index}>
            <div
              className="compartment-content w-100  mt-auto"
              style={{ height: `${section.value}%` }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
