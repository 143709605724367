export const mainArray = ({ shipDoc, tankDD, onChangeItem, onChange, contollerMeasurements, scaleOptions }) => {
  let data = [];
  data = shipDoc?.shippingDocItemsComm ?? [];
  
  return [
    {
      id: "materialDescriptor",
      name: "materialDescriptor",
      type: "text",
      label: "Product",
      dataSource: data[0] || "",
      disabled: true,
    },
    {
      id: "tankId",
      name: "tankId",
      type: "text",
      label: "Tank",
      tag: "select",
      dataSource: data[0] || "",
      options: tankDD,
      onChange: onChangeItem,
    },
    // {
    //   id: "scaleId",
    //   name: "scaleId",
    //   type: "text",
    //   label: "Scale",
    //   tag: "select",
    //   dataSource: shipDoc || {},
    //   options: scaleOptions,
    //   onChange: onChange,
    // },
    // {
    //   id: "controllerStatus",
    //   name: "controllerStatus",
    //   type: "text",
    //   label: "Last Command",
    //   dataSource: contollerMeasurements,
    //   disabled: true,
    // },
    // {
    //   id: "temperature",
    //   name: "temperature",
    //   type: "number",
    //   label: "Temp",
    //   dataSource: contollerMeasurements,
    //   disabled: true,
    // },
    // {
    //   id: "meterReading",
    //   name: "meterReading",
    //   type: "number",
    //   label: "Meter",
    //   dataSource: contollerMeasurements,
    //   disabled: true,
    // },
  ];
};
