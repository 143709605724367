import React, { useContext, useState ,useEffect } from "react";
import UniversalEditableGrid from "../../../../CommonComponents/Grid/EditableGrid/UniversalEditableGrid";
import { itemsGridFields} from "./Fields";
import ItemDetails from "./ItemsDetails/Index";
import { BillOfMaterialContext } from "../Contex";
import Button from "react-bootstrap/Button";
import * as _ from "underscore";

export default function Index() {
  const {
    _billOfMaterialData,
    operation,
    _setBillOfMaterialData,
    dropDowns,
    onChange,
  } = useContext(BillOfMaterialContext);

  const [showItemsDetailsModal, setShowItemsDetailsModal] = useState(false);
  const [itemOperation, setItemOperation] = useState();
  const [item, setItem] = useState({});



  const addNewItemClick = () => {
    let maxItemNumber = 0;
    let itemNumbers = [];

    if (_billOfMaterialData.billOfMaterialItemsComm != null) {
      _billOfMaterialData.billOfMaterialItemsComm.forEach((arrayItem) => {
        itemNumbers.push(arrayItem.itemNumber);
      });
      maxItemNumber = _.max(itemNumbers);
    }

    if (maxItemNumber === -Infinity || maxItemNumber === 0) {
      maxItemNumber = 10;
    } else {
      maxItemNumber = maxItemNumber + 10;
    }

    let newItemObject = {
      itemNumber: maxItemNumber,
    };
    setItemOperation("Create");
    setShowItemsDetailsModal(true);
    setItem({ ...newItemObject });
  };
  //Grid functions handler
  function handleButtonClickGrid(e) {
    let selectedItemNumber = e.target.dataset.id;
    let gridButtonOperation = e.target.dataset.op;
    let result;
    let currentItems = _billOfMaterialData.billOfMaterialItemsComm;

    setItemOperation(gridButtonOperation);
    switch (gridButtonOperation) {
      case "Display":
      case "Change":
        result = _billOfMaterialData.billOfMaterialItemsComm.find(
          ({ itemNumber }) => +itemNumber === +selectedItemNumber
        );
        setItem(result);
        setShowItemsDetailsModal(true);
        break;
      case "Delete":
        currentItems = currentItems.filter(
          (x) => +x.itemNumber !== +selectedItemNumber
        );
        _setBillOfMaterialData((current) => {
          return { ...current, billOfMaterialItemsComm: currentItems };
        });
        break;

      default:
    }
  }
  //Acccpte chnages from item details window
  const handleAccept = () => {
    if (itemOperation === "Create") {
      _setBillOfMaterialData((current) => {
        let items = current.billOfMaterialItemsComm || [];

        items.push(item);
        return { ...current, billOfMaterialItemsComm: items };
      });
    } else {
      _setBillOfMaterialData((current) => {
        let items = current.billOfMaterialItemsComm;

        var index = items.findIndex((x) => x.itemNumber === item.itemNumber);
        if (index >= 0) {
          items[index] = item;
        }
        return { ...current, billOfMaterialItemsComm: items };
      });
    }
  };
  //Update main state, update item data
  const onChangeItem = (e) => {

    let item;
    let fieldTypeItem;
    const {
      target: { value, name },
    } = e;

    item = e.target.dataset.item;
    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeItem = e.target.dataset.type;

    let newValue;
    switch (fieldTypeItem) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    let newItems = _billOfMaterialData.billOfMaterialItemsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.itemNumber == item) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setBillOfMaterialData((prevState) => {
      return { ...prevState, billOfMaterialItemsComm: newItems };
    });

    console.log("ITEMS",_billOfMaterialData.billOfMaterialItemsComm)
  };

  if (showItemsDetailsModal) {
    return (
      <div className="mt-4">
        <ItemDetails
          setShowItemsDetailsModal={setShowItemsDetailsModal}
          itemOperation={itemOperation}
          item={item}
          setItem={setItem}
          handleAccept={handleAccept}
        />
      </div>
    );
  }
  
  return (
    <div>
          <Button
        variant="primary"
        active="true"
        data-op="Create"
        disabled={operation === "Display"}
        onClick={addNewItemClick}
      >
        Add New Item
      </Button>
      <UniversalEditableGrid
        fields={itemsGridFields}
        headerData={_billOfMaterialData}
        itemData={_billOfMaterialData?.billOfMaterialItemsComm ?? []}
        dropDowns={dropDowns}
        operation={operation}
        onChange={onChangeItem}
        handleButtonClickGrid={handleButtonClickGrid}
      />
    </div>
  );
}

