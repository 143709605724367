import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useFetch } from "../../../../../hooks/useFetch";
import { useMessage } from "../../../../../hooks/UseMessage";
import { useUser } from "../../../../../hooks/useUser";
import { useStation } from "../../../../../hooks/UseStation";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../../CommonComponents/GlobalConfFiles/URLs";

import { ReactComponent as TruckandScale } from "../../../../../Assets/SVGs/TruckAndScale.svg";
import { ReactComponent as OverLoadAlram } from "../../../../../Assets/SVGs/Alrams/AnimatedAlram1.svg";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import HeaderV2 from "../../Header/HeaderV2";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { FlipCounter } from "../../../../../CommonComponents/FlipCounter/FlipCounter";
import TrafficLight from "../../../../../CommonComponents/TrafficLights/Components/TrafficLightSet";
import TruckAnimation from "../../../../../CommonComponents/TruckAnimation/index";
import { useParams } from "react-router-dom";
import MTDataInfoSIdeBar from "./MTDataInfoSIdeBar";
function InOutWeightMT(props) {
  const params = useParams();
  const navigate = useNavigate();

  //URL parameters, shipDocParam number and requsted weight type IN or OUT
  const shipDocParam = params.shipDoc;
  const WeightTypeParam = params.weightType;

  const [weight, setWeight] = useState(null);
  const [finishedScaling, setFinishedScaling] = useState(false);
  const [topLog, setTopLog] = useState("");
  const [serviceError, setServiceError] = useState([]);
  const [serviceSuccess, setServiceSuccess] = useState([]);
  const [entryLights, setEntryLights] = useState({
    activeLight: 1,
    blinking: false,
  });
  const [exitLights, setExitLights] = useState({
    activeLight: 0,
    blinking: false,
  });
  const requestedWeightType = useRef();
  //hooks
  const [_user, ,] = useUser();
  const [
    _stationInfo,
    _getTerminalSetup,
    _terminalSetup,
    _getStationOperation,
    _stationOperation,
    _messages,
    _setMessages,
  ] = useStation();
  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
  ] = useMessage();

  let serviceSimulation = false;

  if (
    _user.serviceSimulation === "true" ||
    _user.serviceSimulation === "True"
  ) {
    serviceSimulation = true;
  }

  const [
    _requestWeight,
    _requestWeightData,
    ,
    _requestWeightError,
    _setRequestWeightError,
    _requestWeightSuccess,
    _setRequestWeightSuccess,
    _loadingRequestWeight,
  ] = useFetch(GetServiceFullURL("RequestWeightMT"), {
    method: "POST",
    headers: {},
  });
  const [
    _getMTData,
    _mTData,
    _setMTData,
    _mTDataError,
    _setMTDataError,
    _mTDataSuccess,
    _setMTDataSuccess,
    _loadingGetMTData,
  ] = useFetch(GetServiceFullURL("getCurrentMTData"), {
    method: "POST",
    headers: {},
  });

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    _successShipDoc,
    _setSuccessShipDoc,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" });

  const [
    _saveShipDoc,
    ,
    _setShipDocSaveData,
    _errorSaveShipDocData,
    _setErrorSaveShipDocData,
    _successSaveShipDocData,
    _setSuccessSaveShipDocData,
    _loadingSaveShipDocData,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" });

  function GetMTServiceData() {
    _getMTData({}, (response) => {
      setTopLog(() => {
        return { success: response.success, message: response.message };
      });
      if (response.success) {
        //Set Weight
        setWeight(response.data.currentWeight);
      }
    });
  }
  useEffect(() => {
    _getShipDoc({ data: { Id: shipDocParam } }, ({ data: shipDocData }) => {
      //Set the weight based on if inweight is taken or net, unless being sent bt previous page
      if (WeightTypeParam === "AUTO") {
        shipDocData.inWeightStatus === "X"
          ? (requestedWeightType.current = "OUT")
          : (requestedWeightType.current = "IN");
      } else {
        requestedWeightType.current = WeightTypeParam;
      }
    });
  }, [shipDocParam]);

  useEffect(() => {
    //Setting the service timer
    let mTServiceTimer = null;
    if (serviceSimulation === true) {
      mTServiceTimer = setInterval(GetMTServiceData, 2000);
    } else {
      mTServiceTimer = setInterval(GetMTServiceData, 2000);
    }

    return () => {
      clearInterval(mTServiceTimer);
    };
  }, [serviceSimulation]);

  useEffect(() => {
    if (finishedScaling) {
      setTimeout(() => {
        navigate("/TerminalActivity/EldoCokeScaleStationHome");
      }, 5000);
    }
  }, [finishedScaling]);

  //The service will take the weight and change the flag "inWeightTaken" and "outWeightTaken"
  useEffect(() => {
    let changedShipDocData = _shipDocData;
    //continuously check  weight value in order to auto stop when max weight is reached
    if (_mTData.inWeight > 0 && _mTData.inWeightTaken === true) {
      if (changedShipDocData.id != null) {
        changedShipDocData.inWeight = _mTData.inWeight;
        changedShipDocData.inWeightStatus = "X";
        changedShipDocData.inWeightStDT = moment().format();
        changedShipDocData.inWeightEnDT = moment().format();
        changedShipDocData.statusId = "INWGHTSHDOC";
        //       changedShipDocData.loadinginProcess = true;
        _saveShipDoc(
          { data: changedShipDocData },
          ({ data: newShipDocData }) => {
            _setShipDocSaveData(newShipDocData);
            setFinishedScaling(true);
          }
        );
      }
    }
  }, [_mTData.inWeightTaken]);

  useEffect(() => {
    let changedShipDocData = _shipDocData;
    if (_mTData.outWeight > 0 && _mTData.outWeightTaken === true) {
      if (changedShipDocData.id != null) {
        changedShipDocData.outWeight = _mTData.outWeight;
        changedShipDocData.outWeightStatus = "X";
        changedShipDocData.outWeightStDT = moment().format();
        changedShipDocData.outWeightEnDT = moment().format();
        changedShipDocData.statusId = "OUTWGHTSHDOC";
        changedShipDocData.loadUnloadinProcess = false;
        changedShipDocData.signatureReady = true;
        changedShipDocData.currentStation = _stationInfo.id;
        _saveShipDoc(
          { data: changedShipDocData },
          ({ data: newShipDocData }) => {
            _setShipDocSaveData(newShipDocData);
            setFinishedScaling(true);
          }
        );
      }
    }
  }, [_mTData.outWeightTaken]);

  useEffect(() => {
    if (_mTData.entryGreenLight === "Flashing") {
      setEntryLights({ activeLight: 1, blinking: true });
    } else if (_mTData.entryGreenLight === "On") {
      setEntryLights({ activeLight: 1, blinking: false });
    }

    if (_mTData.entryRedLight === "Flashing") {
      setEntryLights({ activeLight: 0, blinking: true });
    } else if (_mTData.entryRedLight === "On") {
      setEntryLights({ activeLight: 0, blinking: false });
    }
  }, [_mTData.entryGreenLight, _mTData.entryRedLight]);

  useEffect(() => {
    if (_mTData.exitGreenLight === "Flashing") {
      setExitLights({ activeLight: 1, blinking: true });
    }
    if (_mTData.exitGreenLight === "On") {
      setExitLights({ activeLight: 1, blinking: false });
    }

    if (_mTData.exitRedLight === "Flashing") {
      setExitLights({ activeLight: 0, blinking: true });
    }

    if (_mTData.exitRedLight === "On") {
      setExitLights({ activeLight: 0, blinking: false });
    }
  }, [_mTData.exitGreenLight, _mTData.exitRedLight]);

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessShipDoc([]);
        _setSuccessSaveShipDocData([]);
        _setRequestWeightSuccess([]);
        setServiceSuccess([]);
        break;
      case "error":
        _setErrorSaveShipDocData([]);
        _setErorMessages([]);
        _setErrorShipDoc([]);
        _setRequestWeightError([]);
        setServiceError([]);
        break;
      default:
        break;
    }
  }

  const operationLoading =
    _loadingShipDoc || _loadingSaveShipDocData || _loadingRequestWeight;

  const onClickGetWeight = () => {
    if (_shipDocData.id == null) {
      _createErrorMessage("13");
      return;
    }

    //Before stating check if shipDocParam is populated
    if (shipDocParam == null || shipDocParam == "") {
      _createErrorMessage("16");
      return;
    }

    _requestWeight(
      {
        data: {
          weightType: requestedWeightType.current,
        },
      },
      (response) => {
        setTopLog(() => {
          return [...response.message];
        });

        if (response.success) {
          return;
        }
      }
    );
  };

  const calculateFillPercent = () => {
    let loadPercent = 0;
    requestedWeightType.current === "IN"
      ? (loadPercent = 0)
      : (loadPercent = 100);

    return loadPercent.toFixed(2);
  };
  calculateFillPercent();
  let error = "bg-danger";
  let success = "bg-success";
  let errorClass = `w-100 rounded-lg px-5 py-3 ${error} d-flex align-items-center justify-content-center`;
  let successClass = `w-100 rounded-lg px-5 py-1 ${success} d-flex align-items-center justify-content-center`;
  return (
    <>
      <div className="container-fluid p-0">
        {operationLoading && <LoadingScreen />}

        <div className="row px-3">
          <div className="col-12 p-0">
            <HeaderV2 _shipDocData={_shipDocData} weightType={null} />
          </div>
        </div>

        <div className="row px-3">
          <div className="col-12 p-0">
            {topLog.success ? (
              <div className="col-12">
                <div className={successClass}>
                  <span className="text-white"> {topLog.message}</span>
                </div>
              </div>
            ) : (
              <div className="col-12">
                <div className={errorClass}>
                  <span className="text-white"> {topLog.message}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row align-items-center justify-content-around">
          <div className="col-lg-2 col-sm-12">
            <MTDataInfoSIdeBar _mTData={_mTData} />
          </div>

          <div className="col-lg-10 col-sm-12 col-sm-12 d-flex align-items-center justify-content-center">
            <div className="" style={{ flex: 1 }}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="h-50">
                  <TrafficLight
                    enabledIndex={exitLights.activeLight}
                    blinking={exitLights.blinking}
                  />
                </div>
                <div className="mx-3" style={{ width: "85%" }}>
                  <TruckAnimation
                    compartCount={1}
                    activeIndex={0}
                    fillPerCent={calculateFillPercent()}
                  />
                </div>
                <div className="h-50">
                  <TrafficLight
                    enabledIndex={entryLights.activeLight}
                    blinking={entryLights.blinking}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column ms-3">
              <FlipCounter number={weight} className="mb-5" />
              <div className="d-flex" style={{ gap: "1rem" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className="px-5 py-3 mt-5 w-50"
                  onClick={() => {
                    navigate("/TerminalActivity/EldoCokeScaleStationHome");
                  }}
                >
                  Finish
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="px-5 py-3 mt-5 w-50"
                  onClick={onClickGetWeight}
                >
                  Take Weight
                </Button>
              </div>
            </div>
          </div>
        </div>

        <MessageLog
          errorMessages={[
            ..._errorShipDoc,
            ..._errorMessages,
            ..._errorSaveShipDocData,
            ...serviceError,
            ..._requestWeightError,
          ]}
          successMessages={[
            ..._successShipDoc,
            ..._successSaveShipDocData,
            ...serviceSuccess,
            ..._mTDataSuccess,
            ..._requestWeightSuccess,
          ]}
          clearMessages={clearMessages}
        ></MessageLog>
      </div>
    </>
  );
}

export default InOutWeightMT;
