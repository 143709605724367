import React from "react";

import CircularProgress from '@mui/material/CircularProgress';


const SplashScreen = () => {

  return (
    <div>
      <CircularProgress color="primary" size={40} />
    </div>
  );
};

export default SplashScreen;
