/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";
import { useMessage } from "../../../hooks/UseMessage";
import OverviewInput from "../../../CommonComponents/InitialInput/OverviewInput";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import ListModal from "../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../../CommonComponents/CircleButton";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import OverviewButtons from "./Components/OverviewButtons";
import cardlogo from "../../../Assets/cardLogo.svg"

export default function AdminFunctionOverview(props) {

  let navigate = useNavigate();
  const [leaseParam, setLeaseParam] = useState();
  const [showLeasesList, setShowLeasesList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  
  const [
    _deleteLease,
    ,
    _setDeleteLeaseData,
    _errorDeleteLeaseData,
    _setErrorDeleteLeaseData,
    _successDeleteLeaseData,
    _setSuccessDeleteLeaseData,
    _loadingDeleteLeaseData,
  ] = useFetch(GetFullURL("DeleteLease"), { method: "POST" });

  const [_getLeasesList, _leasesList, , , , , , _loadingLeasesList] =
    useFetch(GetFullURL("GetAllLeasesForDelete"), { method: "POST" });

  const [_leaseExist, , , , , , , _loadingLeaseExist] = useFetch(GetFullURL("LeaseExist"),{ method: "POST" });

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessDeleteLeaseData([]);
      
        break;
      case "error":
        _setErorMessages([]);
        _setErrorDeleteLeaseData([]);
        
        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading =  _loadingLeasesList  || _loadingLeaseExist ;

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../../Assets/icon/Admin.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Lease Admin Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setLeaseParam}
                    document={leaseParam}
                    leaseRadius={true}
                    placeholder={`Enter or Choose Id`}
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of Leases`}
                    clickHandler={() => {
                      _getLeasesList({},() => {setShowLeasesList(true);});                  
                    }}
                  />
                  <OverviewButtons
                    lease={leaseParam}
                    navigate={navigate}
                    _leaseExist={_leaseExist}
                    _createErrorMessage={_createErrorMessage}
                    _deleteLease={_deleteLease}
              
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages, ..._errorDeleteLeaseData ]}
              successMessages={[..._successDeleteLeaseData]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
             <div className="cardAlert">
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setLeaseParam}
              dataList={_leasesList}
              showListModal={showLeasesList}
              setShowModal={setShowLeasesList}
              title="List Of Leases"
              columns={[
                { field: "id", headerName: "Lease ID", width: 100 },
                { field: "leaseTypeId", headerName: "Lease Type", width: 250 },
                { field: "logisticStationId", headerName: "Name", width: 350 },
                ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

