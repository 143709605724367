import React, { useContext, useState } from "react";
import UniversalEditableGrid from "../../../../CommonComponents/Grid/EditableGrid/UniversalEditableGrid";
import { OrderContext } from "../Contex";
import { outboundFields } from "./OutboundFields";
import Button from "react-bootstrap/Button";
import * as _ from "underscore";
import OutboundDetails from "./OutboundDetails/Index";

function Outbound(props) {
  const { _orderData, operation, _setOrderData, dropDowns } =
    useContext(OrderContext);
  const [showOutboundDetailsModal, setShowOutboundDetailsModal] =
    useState(false);
  const [outboundOperation, setOutboundOperation] = useState();
  const [outbound, setOutbound] = useState({});

  const addNewOutboundClick = () => {
    let maxOutboundNumber = 0;
    let outboundNumbers = [];

    if (_orderData.outboundsComm != null) {
      _orderData.outboundsComm.forEach((arrayItem) => {
        outboundNumbers.push(arrayItem.outboundNumber);
      });
      maxOutboundNumber = _.max(outboundNumbers);
    }

    if (maxOutboundNumber === -Infinity || maxOutboundNumber === 0) {
      maxOutboundNumber = 10;
    } else {
      maxOutboundNumber = maxOutboundNumber + 10;
    }
    let newOutboundObject;
    if (operation === "Create") {
      newOutboundObject = {
        outboundNumber: maxOutboundNumber,
        documentClassId: "ORDER",
        outboundStatusId: "NEW",
      };
    } else {
      newOutboundObject = {
        outboundNumber: maxOutboundNumber,
      };
    }

    setOutboundOperation("Create");
    setShowOutboundDetailsModal(true);
    setOutbound({ ...newOutboundObject });
  };

  function handleButtonClickGrid(e) {
    debugger;
    let selectedOutboundNumber = e.target.dataset.id;
    let gridButtonOperation = e.target.dataset.op;
    let result;
    let currentOutbounds = _orderData.outboundsComm;

    setOutboundOperation(gridButtonOperation);
    switch (gridButtonOperation) {
      case "Display":
      case "Change":
        result = _orderData.outboundsComm.find(
          ({ outboundNumber }) => +outboundNumber === +selectedOutboundNumber
        );
        setOutbound(result);
        setShowOutboundDetailsModal(true);
        break;
      case "Delete":
        currentOutbounds = currentOutbounds.filter(
          (x) => +x.outboundNumber !== +selectedOutboundNumber
        );
        _setOrderData((current) => {
          return { ...current, outboundsComm: currentOutbounds };
        });
        break;

      default:
    }
  }

  const handleAccept = () => {
    if (outboundOperation === "Create") {
      _setOrderData((current) => {
        let outbounds = current.outboundsComm || [];

        outbounds.push(outbound);
        return { ...current, outboundsComm: outbounds };
      });
    } else {
      _setOrderData((current) => {
        let outbounds = current.outboundsComm;

        var index = outbounds.findIndex(
          (x) => x.outboundNumber === outbound.outboundNumber
        );
        if (index >= 0) {
          outbounds[index] = outbound;
        }
        return { ...current, outboundsComm: outbounds };
      });
    }
  };

  const onChangeOutbound = (e) => {
    let outbound;
    let fieldTypeOutbound;
    const {
      target: { value, name },
    } = e;

    outbound = e.target.dataset.outbound;
    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeOutbound = e.target.dataset.type;

    let newValue;
    switch (fieldTypeOutbound) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    let newOutbounds = _orderData.outboundsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.outboundNumber == outbound) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setOrderData((prevState) => {
      return { ...prevState, outboundsComm: newOutbounds };
    });
  };

  if (showOutboundDetailsModal) {
    return (
      <div className="mt-4">
        <OutboundDetails
          setShowOutboundDetailsModal={setShowOutboundDetailsModal}
          outboundOperation={outboundOperation}
          outbound={outbound}
          setOutbound={setOutbound}
          handleAccept={handleAccept}
        />
      </div>
    );
  }
  return (
    <div>
      <Button
        variant="primary"
        active="true"
        data-op="Create"
        disabled={operation === "Display"}
        onClick={addNewOutboundClick}
      >
        Add New Item
      </Button>

      <UniversalEditableGrid
        fields={outboundFields}
        headerData={_orderData}
        itemData={_orderData?.outboundsComm ?? []}
        dropDowns={dropDowns}
        operation={operation}
        onChange={onChangeOutbound}
        handleButtonClickGrid={handleButtonClickGrid}
        ActionColumn={true}
      />
    </div>
  );
}

export default Outbound;
