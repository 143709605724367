exports.StationProcessing = [
    {
      id: "id",
      name: "id",
      type: "number",
      value: "",
      label: "Id",
      key: "true",
      displayonly: "true",
    },
    {
      id: "documentCategory",
      name: "documentCategory",
      type: "text",
      value: "",
      label: "Document Category",
      key: "true",
      length: 50,
    },
    {
      id: "stationId",
      name: "stationId",
      type: "text",
      value: "",
      label: "Station Id",
      key: "true",
      length: 24,
    },
    {
      id: "fieldOperationId",
      name: "fieldOperationId",
      type: "text",
      value: "",
      label: "Field Operation",
      length: 50,
    },
    {
      id: "fieldOperationSequence",
      name: "fieldOperationSequence",
      type: "number",
      value: "",
      label: "Field Operation Sequence",
      key: "true",
    },
    {
      id: "completionDT",
      name: "completionDT",
      type: "text",
      label: "Completion DT",
      displayonly: "true",
      value: " ",
    },
    {
      id: "complete",
      name: "complete",
      type: "boolean",
      value: "",
      label: "Complete",
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "datetime",
      label: "ModifiedOn",
      displayonly: "true",
      value: " ",
    },
  ];
  