import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { ShipDocContext } from "../Contex";
import { MuiButon } from "../../../../CommonComponents/MuiButton";
import { zip } from "underscore";

export default function ShipDocHeader({ _loadingSaveShipDoc, _saveShipDoc }) {
  const { operation, _setShipDocData, _shipDocData } =
    useContext(ShipDocContext);

  const saveDocument = () => {

    _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
    });
  };

  return (
    <Paper className="py-2 px-2 d-flex align-items-center justify-space-between customPaper">
      <TopPanel />
      <MuiButon
        title="Save"
        loading={_loadingSaveShipDoc}
        onClick={saveDocument}
        disabled={operation === "Display"}
      />
    </Paper>
  );
}
