import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { locationGridFields } from "./Fields";
import { MaterialContext } from "../../context";

function LocationsGrid({ showLocationDetails, handleButtonClickGrid }) {
  const { operation, _materialData } = useContext(MaterialContext);
  const gridData = _materialData.locations ?? [];

  return (
    <div className="row">
      <div className="col-12">
        <div className="selectWrapper">
          {showLocationDetails ? null : (
            <Button
              className="ButtonAdd"
              hidden={operation === "Display"}
              data-backdrop="static"
              data-op="Create"
              onClick={handleButtonClickGrid}
            >
              Add
            </Button>
          )}
        </div>
      </div>
      <div className="col-12">
        <div style={{overflow: 'auto'}}>
          <table className="table table-striped text-nowrap custom-table">
            <thead>
              <tr>
                {locationGridFields.map((field, i) => {
                  return (
                    <th key={i} className="GridColumnHeading">
                      {field.label}
                    </th>
                  );
                })}
                {locationGridFields.length > 0 ? (
                  <th className="GridColumnHeading">Actions</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {gridData.map((dataRecord, i) => {
                return (
                  <tr key={i}>
                    {locationGridFields.map((field, i1) => {
                      return (
                        <td className="GridColumnContent" key={i1}>
                          {dataRecord[field.dataField]}
                        </td>
                      );
                    })}
                    <td style={{display: "flex",alignItems: "center", gap: 10}}>
                      <button
                        type="button"
                        className="ButtonDisplay tblBtn"
                        data-backdrop="static"
                        data-op="Display"
                        data-id={dataRecord["locationId"]}
                        onClick={handleButtonClickGrid}
                      ></button>
                      <button
                        type="button"
                        className="ButtonChange tblBtn"
                        hidden={operation === "Display"}
                        data-backdrop="static"
                        data-op="Change"
                        data-id={dataRecord["locationId"]}
                        onClick={handleButtonClickGrid}
                      ></button>
                      <button
                        type="button"
                        className="ButtonDelete tblBtn"
                        data-op="Delete"
                        data-id={dataRecord["locationId"]}
                        onClick={handleButtonClickGrid}
                        hidden={operation === "Display"}
                      ></button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LocationsGrid;
