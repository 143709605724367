import React, { useState, useEffect } from "react";
import { ReactComponent as SettingsIcon } from "../../../../Assets/NewIconsForCards/SettingsIcon.svg";
import { ReactComponent as LoadWithOrderIcon } from "../../../../Assets/NewIconsForCards/TankerIcon.svg";
import CardV1 from "../../../Components/CardV1";
import { useUser } from "../../../../hooks/useUser";
import { useStation } from "../../../../hooks/UseStation";
import { useMessage } from "../../../../hooks/UseMessage";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
export default function TETerminalHome(props) {
  const [newCards, setNewCards] = useState([]);
  const [_user] = useUser();
  const [_stationInfo, , , , , _stationMessages, _setStationMessages] =
    useStation();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const CardsArray = [
    {
      title: "Checkin Station",
      text: "",
      Icon: LoadWithOrderIcon,
      link: "/TerminalActivity/StartWithOrder",
      roles: ["Admin", "SuperAdmin", "TECLoader", "TEAdmin"],
      stationId: "TULE_CHECKIN",
    },
    {
      title: "Scale Station",
      text: "",
      Icon: LoadWithOrderIcon,
      link: "/TerminalActivity/StartWithOrder",
      roles: ["Admin", "SuperAdmin", "TESLoader", "TEAdmin"],
      stationId: "TULE_SCALE",
    },
    {
      title: "Admin",
      text: "",
      Icon: SettingsIcon,
      link: "/TerminalActivity/TEAdminHome",
      roles: ["Admin", "SuperAdmin"],
    },
  ];
  useEffect(() => {
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card.roles.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        _setStationMessages([]);
        break;
      case "warning":
        break;
      default:
        break;
    }
  }
  return (
    <div className="container-fluid ">
      <div className="mainContainer">
        <div className="overviewCardContainer">
          {newCards.map((record, i) => {
            let newRecord = {
              ...record,
              loggedStationId: _stationInfo.id,
              _createErrorMessage,
            };
            return <CardV1 {...newRecord} key={i} />;
          })}
        </div>
      </div>
      <MessageLog
        errorMessages={[..._stationMessages, ..._errorMessages]}
        successMessages={[]}
        warningMessages={[]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}
