import React from "react";
import Input from "./Input";
import Select from "./Select";
import CheckBox from "./CheckBox";

export default function RenderField({ field, ddvalues }) {
  if (field.type === "boolean") {
    return <CheckBox field={field} />;
  }

  if (ddvalues != null) {
    return <Select field={field} options={ddvalues} />;
  } else {
    return <Input field={field} />;
  }
}
