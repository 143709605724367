exports.ShipDocEvent = [
    {
      id: "id",
      name: "id",
      type: "number",
      value: "",
      label: "Id",
      key: "true",
    },
    {
      id: "shipDocId",
      name: "shipDocId",
      type: "text",
      value: "",
      label: "ShipDocId",
      length: 24,
      ddid: "ShipDocId",
    },
    {
        id: "eventId",
        name: "eventId",
        type: "text",
        value: "",
        label: "EventId",
        length: 24,
        ddid: "EventId",
      },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
  ];
  