export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Logistic Station ID",
    disabled: true,
  },
  {
    id: "name",
    name: "name",
    type: "text",
    label: "Logistic Station Name",
  },
  {
    id: "logisticStationTypeId",
    name: "logisticStationTypeId",
    tag: "select",
    options: dropDowns?.logisticStationTypes || [],
    type: "text",
    label: "Logistic Station Type",
  },
  {
    id: "companyId",
    name: "companyId",
    tag: "select",
    options: dropDowns?.companies || [],
    type: "text",
    label: "Company",
  },
  {
    id: "gatheringUnit",
    name: "gatheringUnit",
    type: "text",
    label: "Gathering Unit",
  },
  {
    id: "tankFarmId",
    name: "tankFarmId",
    type: "text",
    label: "Tank Farm",
    tag: "select",
    options: dropDowns?.tankFarms || [],
  },
];

