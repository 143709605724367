import React, { useState, useEffect } from "react";
import { FinancialDocContext } from "./Contex";
import MessageLog from "../Components/MessageLog";
import HomeTab from "./Main/index";
import Items from "./../FinancialDocMain/Items/Index";
import FinancialDocOperationHeader from "./Header/Index";
import AdminTab from "./Admin/Index";
import PartnerTab from "./PartnerTab/Index";
import { useFetch } from "./../../../hooks/useFetch";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Tabpannel from "../../../Business Processes/Components/TabPannel";
import { useParams } from "react-router-dom";

const UpdatedHomeComponent = () => (
  <React.Fragment>
    <HomeTab />
    <Items />
  </React.Fragment>
);

export default function Index(props) {
  //read URL parameters
  const params = useParams();
  //Partner ID
  let id = params.id;

  //Operation
  let operation = params.op;

  //Make sure that ID is numeric
  const financialDocId = id;
  //Do the following when page loads

  const [value, setValue] = useState(0);

  const [frondendError, setFrontendError] = useState([]);
  const [frontendWarning, setFrontendWarning] = useState([]);

  const [
    _getFinancialDocData,
    _financialDocData,
    _setFinancialDocData,
    _errorFinancialDocData,
    _setErrorFinancialDocData,
    _successFinancialDocData,
    _setSuccessFinancialDocData,
    _loadingFinancialDocData,
  ] = useFetch(GetFullURL("GetFinancialDoc"), { method: "POST" });

  const [
    _getFinancialDocDD,
    _financialDocDD,
    ,
    _errorFinancialDocDD,
    _setErrorFinancialDocDD,
    _successFinancialDocDD,
    _setSuccessFinancialDocDD,
    _loadingtFinancialDocDD,
  ] = useFetch(GetFullURL("FinancialDocDropDowns"), { method: "POST" });

  const [
    _saveFinancialDocData,
    _financialDocSaveData,
    _setFinancialDocSaveData,
    _errorSaveFinancialDocData,
    _setErrorSaveFinancialDocData,
    _successSaveFinancialDocData,
    _setSuccessSaveFinancialDocData,
    _loadingSaveFinancialDocData,
  ] = useFetch(GetFullURL("SaveFinancialDoc"), { method: "POST" });

  const [
    _getPartnerList,
    _partnerList,
    _setPartnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    _successPartnerList,
    _setSuccessPartnerList,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("PartnerList"), { method: "POST" });



  useEffect(() => {
    _getFinancialDocDD();

    if (financialDocId) {
      _getFinancialDocData({ data: { id: financialDocId } });
    }
  }, [financialDocId]);

  const onChange = (e) => {
    let item;
    let fieldTypeItem;
    let fieldType;
    
    const {
      target: { value, name },
    } = e;
    let newValue;
    if (e.target.dataset) {
      item = e.target.dataset.item;
      //Get field type from  from custom attribute, this used for select mainly
      fieldTypeItem = e.target.dataset.type;
    }

    if (fieldTypeItem) {
      fieldType = fieldTypeItem;
    } else {
      fieldType = e.target.type;
    }

    switch (fieldType) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    if (item) {
      _setFinancialDocData((prevState) => {
        let newItems = prevState.orderItemsComm.map((i) => {
          //Did not use === becuase '5' equals to 5
          if (i.itemNumber == item) {
            return { ...i, ...newValueObject };
          }
          return i;
        });
        return { ...prevState, orderItemsComm: newItems };
      });
    } else {
      _setFinancialDocData((prevState) => {
        return { ...prevState, ...newValueObject };
      });
    }
  };

  const handleChange = (event, newValue) => setValue(newValue);



  //Use the context and pass the data, dropdowns
  const contextState = {
    onChange,
    operation,
    dropDowns: _financialDocDD || [],
    financialDocId,
    _financialDocData,
    _setFinancialDocData,
    _saveFinancialDocData,
    _getPartnerList,
    _partnerList,
    setFrontendError,
  };


  const operationLoading =
    _loadingFinancialDocData       ||
    _loadingtFinancialDocDD        ||
    _loadingPartnerList            ||
    _loadingSaveFinancialDocData   ;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveFinancialDocData([]);
        _setSuccessFinancialDocData([]);
        _setSuccessFinancialDocDD([]);
        _setSuccessPartnerList([]);
        break;
      case "error":
        _setErrorFinancialDocData([]);
        _setErrorFinancialDocDD([]);
        _setErrorSaveFinancialDocData([]);
        _setErrorPartnerList([]);
        setFrontendError([]);
        break;
      case "warning":
        setFrontendWarning([]);
      default:
        break;
    }
  }
  return (
    <FinancialDocContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <FinancialDocOperationHeader
        _loadingSaveFinancialDoclData={_loadingSaveFinancialDocData}
      />
      <Tabpannel
        value={value}
        handleChange={handleChange}
        labels={["Home","Items","Partner","Admin","Log"]}
        components={[
          UpdatedHomeComponent,
          Items,
          PartnerTab,
          AdminTab,
          () => (
            <MessageLog
              errorMessages={[
                ..._errorFinancialDocDD,
                ..._errorFinancialDocData,
                ..._errorSaveFinancialDocData,
                ..._errorPartnerList,
              ]}
              successMessages={[
                ..._successFinancialDocDD,
                ..._successFinancialDocData,
                ..._successSaveFinancialDocData,
                ..._successPartnerList,
              ]}
              warningMessages={[...frontendWarning]}
              clearMessages={clearMessages}
            ></MessageLog>
          ),
        ]}
      />
    </FinancialDocContext.Provider>
  );
}


