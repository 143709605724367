import React, { useContext } from "react";
import GridHeader from "../Grid/GridHeader";
import GridBody from "./../Grid/GridBody";
import { orderSummaryGridFields } from "./Fields";
import { LoadingContext } from "../../../Context";
function Index() {
  const { _shipDocData } = useContext(LoadingContext);

  return (
    <div style={{ marginLeft: 10 }}>
      <div className="row mt-4">
        <div className="col-12">
          <div style={{overflow: 'auto'}}>
          <table className="table table-striped text-nowrap custom-table">
            <GridHeader fields={orderSummaryGridFields} />
            <GridBody
              fields={orderSummaryGridFields}
              data={_shipDocData.shippingDocItemsComm || []}
            />
          </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
