import React from "react";
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import ScaleStateMT from "../Business Processes/TerminalOperations/EquipmentState/ScaleState/ScaleStateMT";
import ShipDocRefOrder from "../Business Processes/TerminalOperations/Components/ShipDocRefOrder/ShipDocRefOrder";
//Eldo Screens

//Loading
import MeterLoadingV1 from "../Business Processes/TerminalOperations/LoadingStages/Loading/MeterLoadingV1/MeterLoadingV1";
import ScaleLoadingV1 from "../Business Processes/TerminalOperations/LoadingStages/Loading/ScaleLoadingV1/ScaleLoadingV1";
import ScaleLoadingV2 from "../Business Processes/TerminalOperations/LoadingStages/Loading/ScaleLoadingV2/ScaleLoadingV2";
//Weight Pages
import InOutWeight from "../Business Processes/TerminalOperations/LoadingStages/Weight/InOutWeight/UsingServiceWeight/InOutWeight";
import InOutWeightCrude from "../Business Processes/TerminalOperations/LoadingStages/Weight/InOutWeight/Crude/InOutWeightCrude";
import WeightBasedLoadingMT from "../Business Processes/TerminalOperations/LoadingStages/Loading/WeightBasedLoadingMT/Index";
import InOutWeightMT from "../Business Processes/TerminalOperations/LoadingStages/Weight/MtInOutWeight/InOutWeightMT";
import Signature from "../Business Processes/TerminalOperations/LoadingStages/Signature/Index";
//Order Loading
import StartLoadingWithOrder from "../Business Processes/TerminalOperations/LoadingWithOrder/StartLoadingWithOrder";
import StartLoadingWithCrudeOrder from "../Business Processes/TerminalOperations/LoadingWithOrder/CrudeOrder/StartLoadingWithCrudeOrder";

import CheckinV1 from "../Business Processes/TerminalOperations/LoadingStages/Checkin/CheckinV1/CheckinV1";
import CheckinV2 from "../Business Processes/TerminalOperations/LoadingStages/Checkin/CheckinV2/CheckinV2";
import CheckinV3 from "../Business Processes/TerminalOperations/LoadingStages/Checkin/CheckinV3/CheckinV3";
import CheckinV4 from "../Business Processes/TerminalOperations/LoadingStages/Checkin/CheckinV4/CheckinV4";
import CheckinV5 from "../Business Processes/TerminalOperations/LoadingStages/Checkin/CheckinV5/CheckinV5";
import CheckinV6 from "../Business Processes/TerminalOperations/LoadingStages/Checkin/CheckinV6/CheckinV6";
import CheckinV7 from "../Business Processes/TerminalOperations/LoadingStages/Checkin/CheckinV7/CheckinV7";
import CheckinV9 from "../Business Processes/TerminalOperations/LoadingStages/Checkin/CheckinV9/CheckinV9";
import WXScaleStationHome from "../Home/TerminalOperations/HollyFrontier/WoodsCross/WXScaleStationHome";

import SignatureReady from "../Business Processes/TerminalOperations/LoadingStages/FinalizedLoadSearch/SignatureReady";
import ScaleLoadingV3 from "src/Business Processes/TerminalOperations/LoadingStages/Loading/ScaleLoadingV3/ScaleLoadingV3";
import ManualCompletion from "src/Business Processes/TerminalOperations/LoadingStages/Completion/ManualCompletion";
function TerminalActivityRoutes() {
  return (
    <Routes>
      <Route
        exact
        path="/TerminalActivity/MTScaleState"
        element={
          <RequireAuth>
            <ScaleStateMT />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/ShipDocRefOrder/:orderId/:fieldProcessId"
        element={
          <RequireAuth>
            <ShipDocRefOrder />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/MeterLoadingV1/:shipDocId"
        element={
          <RequireAuth>
            <MeterLoadingV1 />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/ScaleLoadingV1/:shipDocId"
        element={
          <RequireAuth>
            <ScaleLoadingV1 />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/ScaleLoadingV2/:shipDocId"
        element={
          <RequireAuth>
            <ScaleLoadingV2 />
          </RequireAuth>
        }
      />
            <Route
        exact
        path="/TerminalActivity/ScaleLoadingV3/:shipDocId/:scaleId"
        element={
          <RequireAuth>
            <ScaleLoadingV3 />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/LoadingMT/:shipDoc"
        element={
          <RequireAuth>
            <WeightBasedLoadingMT />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/InOutWeightMT/:shipDoc/:weightType"
        element={
          <RequireAuth>
            <InOutWeightMT />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/InOutWeight/:shipDoc/:weightType"
        element={
          <RequireAuth>
            <InOutWeight />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/InOutWeightCrude/:shipDoc/:weightType"
        element={
          <RequireAuth>
            <InOutWeightCrude />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/StartWithOrder"
        element={
          <RequireAuth>
            <StartLoadingWithOrder />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/StartWithCrudeOrder"
        element={
          <RequireAuth>
            <StartLoadingWithCrudeOrder />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="TerminalActivity/CheckinV1/:shipDoc"
        element={
          <RequireAuth>
            <CheckinV1 />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="TerminalActivity/CheckinV5/:shipDoc"
        element={
          <RequireAuth>
            <CheckinV5 />
          </RequireAuth>
        }
      />
            <Route
        exact
        path="TerminalActivity/CheckinV9/:shipDoc"
        element={
          <RequireAuth>
            <CheckinV9 />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="TerminalActivity/CheckinV6/:shipDoc"
        element={
          <RequireAuth>
            <CheckinV6 />
          </RequireAuth>
        }
      />
       <Route
        exact
        path="TerminalActivity/CheckinV7/:shipDoc"
        element={
          <RequireAuth>
            <CheckinV7 />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="TerminalActivity/CheckinV2/:fp"
        element={
          <RequireAuth>
            <CheckinV2 />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="TerminalActivity/CheckinV3/:fp"
        element={
          <RequireAuth>
            <CheckinV3 />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="TerminalActivity/CheckinV4/:shipDoc"
        element={
          <RequireAuth>
            <CheckinV4 />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/ShipDocSignature/:shipDoc"
        element={
          <RequireAuth>
            <Signature />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/SignatureReady"
        element={
          <RequireAuth>
            <SignatureReady />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/WXScaleStationHome"
        element={
          <RequireAuth>
            <WXScaleStationHome />
          </RequireAuth>
        }
      />

<Route
        exact
        path="/TerminalActivity/ManualCompletion/:shipDoc"
        element={
          <RequireAuth>
            <ManualCompletion />
          </RequireAuth>
        }
      />
    </Routes>

    
  );
}

export default TerminalActivityRoutes;
