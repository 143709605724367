import React, { useContext, useState } from "react";
import {partnersArray } from "./Fields";
import { InvoiceContext } from "../Contex";
import PartnerField from "../../../../CommonComponents/EnhancedFields/Partner/PartnerField";
import PartnerList from "../../../../CommonComponents/DropDowns/Partner/PartnerList";

export default function PartnerTab() {
  const {
    onChange,
    operation,
    dropDowns,
    _invoiceData,
    _partnerList,
    _getPartnerList,
  } = useContext(InvoiceContext);


  const [showList, setShowList] = useState(false);
  const [partnerFieldName, setPartnerFieldName] = useState();
  

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _invoiceData, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _invoiceData,
    dropDowns,
  };

  const partnerFieldsRender = partnersArray(fieldsState);

  var soldtofield  = partnerFieldsRender.find(({ id }) => id === "soldtoId");
  var driverfield  = partnerFieldsRender.find(({ id }) => id === "driverId");
  var shiptofield  = partnerFieldsRender.find(({ id }) => id === "shiptoId");
  var carrierfield = partnerFieldsRender.find(({ id }) => id === "carrierId"); 

  return (
   <>
      <div className="row">
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Partners</h2>
            </div>
            <div className="cardBody">
              <PartnerField
                field={mergedFieldData(soldtofield)}
                setShowList={setShowList}
                _getPartnerList={_getPartnerList}
                setPartnerFieldName={setPartnerFieldName}
                dropDownApiPartnerType="SOLDTO"

              />
              <PartnerField
                field={mergedFieldData(shiptofield)}
                setShowList={setShowList}
                _getPartnerList={_getPartnerList}
                setPartnerFieldName={setPartnerFieldName}
                dropDownApiPartnerType="SHIPTO"
              />
              <PartnerField
                field={mergedFieldData(driverfield)}
                setShowList={setShowList}
                _getPartnerList={_getPartnerList}
                setPartnerFieldName={setPartnerFieldName}
                dropDownApiPartnerType="DRIVER"
              />
              <PartnerField
                field={mergedFieldData(carrierfield)}
                setShowList={setShowList}
                _getPartnerList={_getPartnerList}
                setPartnerFieldName={setPartnerFieldName}
                dropDownApiPartnerType="CARRIER"
              />
            </div>
          </div>
      </div>
      </div>
      <div className="row">
      <PartnerList
        _partnerList={_partnerList}
        showPartnerList={showList}
        setShowPartnerList={setShowList}
        partnerFieldName={partnerFieldName}
        onChange={onChange}
      />
      </div>
  </>
   
  );
}
