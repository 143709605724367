import { createSlice } from "@reduxjs/toolkit";

export const dropDownSlice = createSlice({
  name: "shipDocDD",
  initialState: { shipDoc: {} },
  reducers: {
    NewShipDocDDContent: (state, action) => {
      state.shipDoc = {
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { NewShipDocDDContent } = dropDownSlice.actions;

export default dropDownSlice.reducer;
