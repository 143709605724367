import React, { useContext } from "react";
import ListBody from "./ListBody";
import { TableDataContext } from "../Contexts/TableDataContext";
export default function List({ handleButtonClickList, listType }) {
  const { tableFieldsN } = useContext(TableDataContext);

  return (
    <div>
      <table className="table table-striped  text-nowrap custom-table">
        <thead>
          <tr>
            {tableFieldsN.map((field, i) => {
              if (field.hide === "true") {
                return;
              }

              return (
                <th className="GridColumnHeading" key={i}>
                  {field.label}
                </th>
              );
            })}
            {tableFieldsN.length > 0 ? (
              <th className="GridColumnHeading">Actions</th>
            ) : null}
          </tr>
        </thead>

        <ListBody
          handleButtonClickList={handleButtonClickList}
          listType={listType}
        />
      </table>
    </div>
  );
}
