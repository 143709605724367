
export const ValidateShipDocInput = (shipDoc, _createErrorMessage) => {

  if (!shipDoc) {
    _createErrorMessage("44");
    return false;
  }

  return true;
};
