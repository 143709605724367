import React from 'react';
import InfoCardHeader, { InfoCardHeaderProps } from '../InfoCardHeader';
import './card.scss';

export interface CardProps {
  cardHeaderProps: InfoCardHeaderProps;
  cardBody: JSX.Element;
  style?:React.CSSProperties;
}

export function Card({ cardHeaderProps, cardBody, style }: CardProps) {
  return (
    <div className="card" style={style}>
      <InfoCardHeader {...cardHeaderProps} />
      <div className="cardBody">{cardBody}</div>
    </div>
  );
}
export default Card;
