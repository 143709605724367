import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useFetch } from "../../../../hooks/useFetch";
import { useStation } from "../../../../hooks/UseStation";
import { useMessage } from "../../../../hooks/UseMessage";
import { GetFullURL } from "../../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import { LoadingContext } from "../../Context";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import ListModal from "../../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import KeyboardInput from "../../../../CommonComponents/keybaords/keyboardInput/index";
import { useNavigate } from "react-router-dom";
import buttonarrow from "../../../../Assets/arrow.svg";
import moment from "moment";
import { mainArray } from "./HeaderFields";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
function StartLoadingWithCrudeOrder(props) {
  const [pageData, setPageData] = useState({});
  const [showShipDocList, setShowShipDocList] = useState(false);
  const [showLeaseInput, setShowLeaseInput] = useState(false);
  const [loadBayStatus, setLoadBayStatus] = useState([]);
  const navigate = useNavigate();
  const fieldProcessId = useRef();
  const keyboardField = useRef(null);
  //This start loading page is meant to work with  SAP Crude order hence the fieldProcessId is hard-coded to
  // SAP_ORDER
  fieldProcessId.current = "SAP_C_ORDER";

  const [_searchShipDocs, _serachShipDocData, , , , , , _loadingSearchShipDoc] =
    useFetch(GetFullURL("AdvancedShipDocSearch"), { method: "POST" });

  const [
    _getOrderInternalERP,
    _orderInternalERPData,
    _setOrderInternalERPData,
    _orderInternalERPError,
    setOrderInternalERPError,
    ,
    ,
    _loadingInternalERP,
  ] = useFetch(GetFullURL("GetOrderInternalERP"), { method: "POST" }, true);

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
  ] = useMessage();

  const [_stationInfo, , , , ,] = useStation();

  const contextState = {};

  const mainFields = mainArray();

  const mergedFieldData = (field, dataSource) => {
    return { ...field, dataSource };
  };
  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        setOrderInternalERPError([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }
  const getRelevantShipDoc = (e) => {
    const report = e.target.dataset.report;

    _searchShipDocs(
      {
        data: {
          locationId: _stationInfo.locationId,
          searchName: report,
        },
      },
      ({ data }) => {
        if (!data) {
          _createErrorMessage("27");
          return;
        }
        if (data.length === 0) {
          _createErrorMessage("27");
          return;
        }
        setShowShipDocList(true);
      }
    );
  };
  const handleShipDocSelect = (selectedId) => {
    let selectedShipDoc = _serachShipDocData.find((x) => x.id == selectedId);
    if (selectedShipDoc) {
      setPageData({
        order: selectedShipDoc.id,
        lease: selectedShipDoc.leaseId,
      });
    }
  };
  const onChangeKeyboard = (e) => {
    let value = e?.target?.value;
    let name = e?.target?.name;

    const newValueObject = { [name]: value };
    setPageData((current) => {
      return { ...current, ...newValueObject };
    });
  };

  const nextOrder = () => {
    setOrderInternalERPError([]);
    _setOrderInternalERPData({});
    let newOrderNumber = "";
    let newOrderNumberNumberPortion = "";
    if (!pageData.order) {
      _createErrorMessage("2");
      return;
    }

    //Obtain 1st char of order num
    let firstChar = pageData.order.charAt(0);
    let first2Char = pageData.order.substring(0, 2);
    debugger;
    if (
      first2Char === "CM" ||
      first2Char === "cm" ||
      first2Char === "Cm" ||
      first2Char === "cM"
    ) {
      newOrderNumberNumberPortion = pageData.order.substring(2);
      newOrderNumber = `CM${newOrderNumberNumberPortion}`;
    } else {
      if (firstChar === "C" || firstChar === "c") {
        newOrderNumberNumberPortion = pageData.order.substring(1);
        newOrderNumber = `C${newOrderNumberNumberPortion}`;
      } else {
        newOrderNumber = pageData.order;
        debugger;
        const digitCount = pageData.order.toString().length;
        if (digitCount >= 7) {
          newOrderNumber = `C${pageData.order}`;
        } else {
          newOrderNumber = `CM${pageData.order}`;
        }
      }
    }

    _getOrderInternalERP(
      {
        data: {
          id: newOrderNumber,
          locationId: _stationInfo.locationId,
        },
      },
      ({ data }) => {
        if (!data) {
          return;
        }
        setShowLeaseInput(true);
      }
    );
  };

  const nextLease = () => {
    let newOrderNumber = "";
    if (!pageData.lease) {
      _createErrorMessage("74");
      return;
    }

    //Obtain 1st char of order num
    let firstChar = pageData.order.charAt(0);
    if (firstChar === "C" || firstChar === "c") {
      newOrderNumber = pageData.order;
    } else {
      newOrderNumber = `C${pageData.order}`;
    }
    //Make sure order/bol lease matches entered lease
    if (_orderInternalERPData?.leaseId != pageData.lease) {
      _createErrorMessage("75", pageData.order, pageData.lease);
      return;
    } else {
      navigate(
        `/TerminalActivity/ShipDocRefOrder/${newOrderNumber}/${fieldProcessId.current}`
      );
    }
  };
  const backLease = () => {
    setShowLeaseInput(false);
    _setErorMessages([]);
  };

  useEffect(() => {
    //The 1st ShipDoc Search will run at the start of page hence updating the grid, the code on the timer will
    //run after 10s from start and every 10s
    let gridTimer = null;
    _searchShipDocs(
      {
        data: {
          locationId: _stationInfo.locationId,
          searchName: "INPROCESSTERMINAL",
        },
      },
      ({ data }) => {
        const result = data.filter((item) => item.inWeightStatus === "X");

        var loadBayDetails = result.map((item) => {
          if (item.inWeightStatus === "X") {
            return {
              order: item.id,
              status: "In-Weight Taken",
              inWeight: item.inWeight,
              driver: item.driverDescriptor,
              inDate: moment(item.startDT).format("MM-DD-YYYY"),
              inTime: moment(item.startDT).format("hh:mm:ss"),
              lease: item.leaseId,
            };
          } else {
            return null;
          }
        });

        setLoadBayStatus(loadBayDetails);
      }
    );
    gridTimer = setInterval(() => {
      _searchShipDocs(
        {
          data: {
            locationId: _stationInfo.locationId,
            searchName: "INPROCESSTERMINAL",
          },
        },
        ({ data }) => {
          const result = data.filter((item) => item.inWeightStatus === "X");

          var loadBayDetails = result.map((item) => {
            if (item.inWeightStatus === "X") {
              return {
                order: item.id,
                status: "In-Weight Taken",
                inWeight: item.inWeight,
                driver: item.driverDescriptor,
                inDate: moment(item.startDT).format("MM-DD-YYYY"),
                inTime: moment(item.startDT).format("hh:mm:ss"),
                lease: item.leaseId,
              };
            } else {
              return null;
            }
          });

          setLoadBayStatus(loadBayDetails);
        }
      );
    }, 10000);

    return () => {
      clearInterval(gridTimer);
    };
  }, []);

  useEffect(() => {
    if (keyboardField.current) {
      keyboardField.current.focus();
    }
  }, []);
  const handlGridProcessButton = (e) => {
    let orderNumber = e.target.dataset.id;

    navigate(`/TerminalActivity/InOutWeightCrude/${orderNumber}/OUT`);
  };
  const operationLoading = _loadingInternalERP;

  useEffect(() => {
    if (!showLeaseInput) {
      return;
    }
    var leaveTimer = setTimeout(() => {
      backLease();
      _setOrderInternalERPData({});
      setPageData({});
    }, 60000);
    return () => {
      clearTimeout(leaveTimer);
    };
  }, [showLeaseInput]);

  return (
    <div className="container-fluid ">
      <LoadingContext.Provider value={contextState}>
        {operationLoading && <LoadingScreen />}
        {showLeaseInput && (
          <div className="row mt-1">
            <div className="col-6">
              {mainFields?.map((field, index) => (
                <FieldsReducer
                  field={mergedFieldData(field, _orderInternalERPData)}
                  key={index}
                />
              ))}
            </div>
          </div>
        )}

        {showLeaseInput ? (
          <div className="row mt-1">
            <div className="col-6">
              <KeyboardInput
                value={pageData.lease ?? ""}
                placeholder="Lease Number here..."
                onChange={onChangeKeyboard}
                name={"lease"}
                label={"lease"}
                keyboardField={keyboardField}
                defaultKeyboard="number"
              />
            </div>
            <div className="col-3">
              <Button
                className="btn btn-primary"
                style={{
                  width: "15rem",

                  background: "#08bd55",
                  borderColor: "#08bd55",
                  fontSize: "30px",
                }}
                onClick={nextLease}
              >
                Next
                <span className="arrow">
                  <img src={buttonarrow} alt="" />
                </span>
              </Button>
            </div>
            <div className="col-3">
              <Button
                className="btn btn-primary"
                style={{
                  width: "15rem",

                  background: "red",
                  borderColor: "#08bd55",
                  fontSize: "30px",
                }}
                onClick={backLease}
              >
                Back
                <span className="arrow">
                  <img src={buttonarrow} alt="" />
                </span>
              </Button>
            </div>
          </div>
        ) : (
          <div className="row mt-1" style={{ alignItems: "start" }}>
            <div className="col-6">
              <KeyboardInput
                value={pageData.order ?? ""}
                placeholder="Type Order id here..."
                onChange={onChangeKeyboard}
                name={"order"}
                label={"Order"}
                defaultKeyboard="number"
              />
            </div>
            <div className="col-3">
              <Button
                className="btn btn-primary"
                style={{
                  width: "25rem",
                  hight: 70,
                  background: "#08bd55",
                  borderColor: "#08bd55",
                  fontSize: "30px",
                }}
                onClick={nextOrder}
              >
                Next
                <span className="arrow">
                  <img src={buttonarrow} alt="" />
                </span>
              </Button>
            </div>
          </div>
        )}

        {/* <div className="orderBtn">
          <Button
            onClick={getRelevantShipDoc}
            color="primary"
            data-report="CHECKIN"
          >
            Checked-in BOLs
          </Button>
          <Button
            onClick={getRelevantShipDoc}
            color="primary"
            data-report="INWEIGHT"
          >
            In Weight Captured BOLs
          </Button>
        </div> */}

        <div className="row mt-5">
          <div className="col-12">
            <table className="table table-striped table-bordered text-nowrap custom-table">
              <thead>
                <th className="GridColumnHeading">Order</th>
                <th className="GridColumnHeading">Driver</th>
                <th className="GridColumnHeading">Status</th>
                <th className="GridColumnHeading">InWeight</th>
                <th className="GridColumnHeading">Date In</th>
                <th className="GridColumnHeading">Time In</th>
                <th className="GridColumnHeading">Lease</th>
                <th className="GridColumnHeading">Process Out Weight</th>
              </thead>
              <tbody>
                {loadBayStatus?.map((bay, i) => {
                  return (
                    <tr key={i}>
                      <td key={i}>{bay?.order}</td>
                      <td key={i}>{bay?.driver}</td>
                      <td key={i}>{bay?.status}</td>
                      <td key={i}>{bay?.inWeight}</td>
                      <td key={i}>{bay?.inDate}</td>
                      <td key={i}>{bay?.inTime}</td>
                      <td key={i}>{bay?.lease}</td>
                      {bay?.order ? (
                        <td key={i}>
                          <Button
                            type="button"
                            disabled={false}
                            data-id={bay?.order}
                            onClick={handlGridProcessButton}
                            style={{
                              background: "#08bd55",
                              borderColor: "#08bd55",
                              fontSize: "20px",
                            }}
                          >
                            Process Out Weight
                          </Button>
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <MessageLog
          errorMessages={[..._orderInternalERPError, ..._errorMessages]}
          successMessages={[]}
          clearMessages={clearMessages}
        ></MessageLog>

        <ListModal
          setId={handleShipDocSelect}
          dataList={_serachShipDocData}
          showListModal={showShipDocList}
          setShowModal={setShowShipDocList}
          title="List Of BOL for OutWeight"
          columns={[
            { field: "id", headerName: "BOL ID", width: 150 },
            { field: "driverDescriptor", headerName: "Driver", width: 250 },
            {
              field: "materialDescriptor",
              headerName: "Material",
              width: 250,
            },
            { field: "startDT", headerName: "Start DT", width: 250 },
            { field: "inWeight", headerName: "In-Weight", width: 200 },
            { field: "outWeight", headerName: "Out-Weight", width: 200 },
          ]}
        />
      </LoadingContext.Provider>
    </div>
  );
}

export default StartLoadingWithCrudeOrder;
