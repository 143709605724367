export const mainArray = ( {dropDowns}) => [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "Pull Order From SAP",

    },
    {
        id: "locationId",
        name: "locationId",
        type: "text",
        label: "Location Id",
        options: dropDowns?.locations || [],
      tag: "select",
   
    },
  ];

  