export const leftPanelArray = ({
  dataSource,
  materiaDataSource,
  dropDowns,
  onChange,
}) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "BOL Number",
    dataSource,
    disabled: true,
  },
  {
    id: "materialDescriptor",
    name: "materialDescriptor",
    type: "text",
    label: "Material",
    dataSource: materiaDataSource,
    disabled: true,
  },
  {
    id: "weightUnitId",
    name: "weightUnitId",
    type: "text",
    label: "Weight Unit",
    options: dropDowns?.weightUnits || [],
    dataSource,
    disabled: true,
  },
  {
    id: "driverDescriptor",
    name: "driverDescriptor",
    type: "text",
    label: "Driver",
    dataSource,
    onChange,
    disabled: true,
  },
];
export const driverPinArray = ({ dropDowns, dataSource, onChange }) => [
  {
    id: "pin",
    name: "pin",
    type: "number",
    label: "Driver PIN",
    tag: "inputKeyboard",
    placeHolder: "Type Max Driver PIN Here...",
    dataSource,
    onChange,
  },
];
export const weightArray = ({ dropDowns, dataSource, onChange }) => [
  {
    id: "maxWeight",
    name: "maxWeight",
    type: "number",
    label: "Max Weight",
    tag: "inputKeyboard",
    placeHolder: "Type Max Weight Here...",
    dataSource,
    onChange,
  },
];
