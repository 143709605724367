import React from "react";
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import PartnerOverview from "../Home/MasterDataHome/PartnerOverview/PartnerOverview.jsx";
import PartnerMain from "../MasterData/PartnerMaster/PartnerMain";
import PartnerAdminFunctionOverview from "../MasterData/PartnerMaster/PartnerAdminFunctions/AdminFunctionOverview.jsx";
import PartnerGeneral from "../MasterData/PartnerMaster/Index";
import PartnerWorkbench from "../MasterData/PartnerMaster/PartnerWorkbench/PartnerWorkbench";
function PartnerRoutes() {
  return (
    <Routes>
      <Route
        exact
        path="/PartnerOverview"
        element={
          <RequireAuth>
            <PartnerOverview />
          </RequireAuth>
        }
      />
         <Route
        exact
        path="/PartnerWorkbench"
        element={
          <RequireAuth>
            <PartnerWorkbench />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/PartnerGeneralOverview"
        element={
          <RequireAuth>
            <PartnerGeneral partnerType="" />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/PartnerSoldToOverview"
        element={
          <RequireAuth>
            <PartnerGeneral partnerType="SOLDTO" />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/PartnerShipToOverview"
        element={
          <RequireAuth>
            <PartnerGeneral partnerType="SHIPTO" />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/PartnerCarrierOverview"
        element={
          <RequireAuth>
            <PartnerGeneral partnerType="CARRIER" />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/PartnerDriverOverview"
        element={
          <RequireAuth>
            <PartnerGeneral partnerType="DRIVER" />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/PartnerAdminFunctionOverview"
        element={
          <RequireAuth>
            <PartnerAdminFunctionOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Partner/:op"
        element={
          <RequireAuth>
            <PartnerMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/SpecificPartner/:id/:op/:partnerType"
        element={
          <RequireAuth>
            <PartnerMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/SpecificPartner/:op/:partnerType"
        element={
          <RequireAuth>
            <PartnerMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Partner/:id/:op"
        element={
          <RequireAuth>
            <PartnerMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Partner/:id/:op"
        element={
          <RequireAuth>
            <PartnerMain />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default PartnerRoutes;
