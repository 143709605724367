import React from "react";
import Button from '@mui/material/Button';
import { ValidateBillOfMaterialInput } from "./ValidateBillOfMaterialInput";
const btnsArray = [
  { operation: "Display" },
  { operation: "Change" },
  {
    operation: "Create",
    navPath: (navigate) => navigate(`/BillOfMaterial/Create`),
  },
];

export default function OverviewButtons({
  billOfMaterial,
  navigate,
  _getBillOfMaterialExists,
  _createErrorMessage,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ operation, navPath }, i) => (
        <div style={{ width: "48%", marginBottom: 15 }}>
          <Button
            className="btn btn-primary w-100"
            key={operation}
            onClick={() => {
              if (operation === "Create") {
                navPath(navigate);
                return;
              }

              let validationBillOfMaterialResult = ValidateBillOfMaterialInput(
                billOfMaterial,
                _createErrorMessage
              );

              if (!validationBillOfMaterialResult) {
                return;
              }

              _getBillOfMaterialExists(
                { data: { Id: billOfMaterial } },
                ({ data: isExist }) => {
                  if (!isExist) {
                    _createErrorMessage("66");
                    return;
                  }
                  const navigationUrl = `/BillOfMaterial/${billOfMaterial}/${operation}`;
                  navigate(navigationUrl);
                }
              );
            }}
          >
            {operation}
          </Button>
        </div>
      ))}
    </div>
  );
}
