import React, { useState } from "react";
import { mainArray } from "./SidebarFields";
import Card from "src/CommonComponents/Card";
import { FieldsReducer } from "src/CommonComponents/Reducer/Index";
function Sidebar({
  _shipDocDDData,
  onChangeItem,
  onChange,
  _shipDocData,
  _stationInfo,
  contollerMeasurements,
  loading,
  scaleMeasurements,
}) {
  const fieldsState = {
    shipDoc: _shipDocData,
    tankDD: getRelevantTanks(),
    onChangeItem: onChangeItem,
    onChange,
    contollerMeasurements,
    scaleOptions: getScaleOptions(),
  };
  function getRelevantTanks() {
    let tanks = [];
    tanks = _shipDocDDData?.tanks.filter(
      (x) => x.filter1 == _stationInfo.locationId
    );
    return tanks;
  }

  function getScaleOptions() {
    if (_stationInfo.id == "GLND_WEST_RACK") {
      return [
        { value: "Scale_1", label: "Scale 1" },
        { value: "Scale_2", label: "Scale 2" },
      ];
    } else {
      return [
        { value: "Scale_3", label: "Scale 3" },
        { value: "Scale_4", label: "Scale 4" },
      ];
    }
  }
  const mainFields = mainArray(fieldsState);

  function append(field) {
    if (field.id === "scaleId") {
      //Can not change the Scale ID if truck is loading
      if (loading) {
        field.disabled = true;
      }
      //if the current weight more then the in weight, lock the weight field
      if (scaleMeasurements.weight > _shipDocData.inWeight) {
        field.disabled = true;
      }
      //if the in weight has been taken then unlock the scale field
      if (_shipDocData.inWeightStatus == "") {
        field.disabled = false;
      }
    }

    return field;
  }

  return (
    <>
      <Card
        cardHeaderProps={{
          headerLabel: "Main",
        }}
        cardBody={mainFields?.map((field, index) => (
          <FieldsReducer field={append(field)} key={index} />
        ))}
        style={{ width: "400px" }}
      />
    </>
  );
}

export default Sidebar;
