export const adminFieldsArray = ({ dropDowns, dataSource }) => [
  {
    id: "forDelete",
    name: "forDelete",
    type: "checkbox",
    label: "Deletion Flag",
    tag: "checkbox",
  },
  {
    id: "partnerBlockCodeId",
    name: "partnerBlockCodeId",
    type: "text",
    label: "Partner Block Code",
    tag: "select",
    options: dropDowns?.partnerBlockCodes || [],
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayOnly: true,
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "text",
    label: "Created On",
    displayOnly: true,
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayOnly: true,
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "text",
    label: "Modified On",
    displayOnly: true,
  },
];
