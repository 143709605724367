exports.Account = [
    {
      id: "id",
      name: "id",
      type: "text",
      value: "",
      label: "Id",
      length: 24,
      key: "true",
    },
    {
      id: "accountTypeId",
      name: "accountTypeId",
      type: "text",
      value: "",
      label: "Account Type",
      length: 50,
      ddid:"accountTypeId",
    },
    {
        id: "accountCurrency",
        name: "accountCurrency",
        type: "text",
        value: "",
        label: "Currency",
        length: 24,
       ddid:"currencyId",
      },
      {
        id: "accountName",
        name: "accountName",
        type: "text",
        value: "",
        label: "Account Name",
        length: 50,
        
      },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "datetime",
      label: "ModifiedOn",
      displayonly: "true",
      value: " ",
    },
  ];
  