import React, { useState, useEffect } from "react";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import HomeTab from "./Main/index";
import AdminTab from "./Admin/index";
import { LabTestContext } from "./context";
import { useFetch } from "./../../../hooks/useFetch";
import { useMessage } from "./../../../hooks/UseMessage";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen";
import { GetFullURL } from "./../../../CommonComponents/GlobalConfFiles/URLs";
import TabPannel from "../../../Business Processes/Components/TabPannel";
import { useParams } from "react-router-dom";
import LabTestHeader from "./Header/index";

const LabTestMain = (props) => {
  const [value, setValue] = React.useState(0);
  const [operation, setOperation] = useState("");
  const [labTestId, setLabTestId] = useState("");
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const params = useParams();

  const [
    _getLabTestData,
    _labTestData,
    _setLabTestData,
    _errorLabTestData,
    _setErrorLabTestData,
    _successLabTestData,
    _setSuccessLabTestData,
    _loadingLabTestData,
  ] = useFetch(GetFullURL("GetLabTest"), { method: "POST" });

  const [
    _getLabTestDD,
    _labTestDD,
    ,
    _errorLabTestDD,
    _setErrorLabTestDD,
    _successLabTestDD,
    _setSuccessLabTestDD,
    _loadingtLabTestDD,
  ] = useFetch(GetFullURL("LabTestDropdowns"), { method: "POST" });

  const [
    _getLabTestsList,
    _labTestsList,
    _setLabTestList,
    _errorLabTestList,
    _SetErrorLabTestList,
    _successLabTestList,
    _setSuccessLabTestList,
    _loadingLabTestList,
  ] = useFetch(GetFullURL("LabTestList"), { method: "POST" });

  const [
    _saveLabTest,
    ,
    ,
    _errorSaveLabTest,
    _setErrorSaveLabTest,
    _successSaveLabTest,
    _setSuccessSaveLabTest,
    _loadingSaveData,
  ] = useFetch(GetFullURL("SaveLabTest"), { method: "POST" });

  const [
    _getMaterialList,
    _materialList,
    _setMaterialList,
    _errorMaterialList,
    _SetErrorMaterialList,
    _successMaterialList,
    _setSuccessMaterialList,
    _loadingMaterialList,
  ] = useFetch(GetFullURL("MaterialList"), { method: "POST" });

  
  const [
    _getTestMethodsList,
    _testMethodsList,
    _setTestMethodList,
    _errorTestMethodList,
    _SetErrorTestMethodList,
    _successTestMethodList,
    _setSuccessTestMethodList,
    _loadingTestMethodList,
  ] = useFetch(GetFullURL("TestMethodList"), { method: "POST" });

  useEffect(() => {
    if (params.op) {
      setOperation(params.op);
    }

    if (params.id) {
      setLabTestId(params.id);
    }
  }, []);

  useEffect(() => {
    _getLabTestDD();
    _getLabTestsList();
    if (labTestId) _getLabTestData({ data: { id: labTestId } });
  }, [labTestId]);

  const handleChange = (event, newValue) => setValue(newValue);

  const onChange = (e) => {
    const {
      target: { value, name , checked },
    } = e;
    let newValue;

    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = "true") : (newValue = "false");
        break;

      default:
        newValue = value;
        break;
    }

    if (newValue === "SELECT" || newValue === "select") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };

    _setLabTestData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const contextState = {
    _getTestMethodsList,
    _testMethodsList,
    _materialList,
    _getMaterialList,
    onChange,
    operation,
    dropDowns: _labTestDD || [],
    labTestId,
    _labTestData,
    _labTestDD,
    _setLabTestData,
    _labTestsList,
    setOperation,
    setLabTestId,
    _createErrorMessage,
    _getLabTestsList,
    _labTestsList
  };

  const operationLoading =
    _loadingLabTestData ||
    _loadingLabTestList ||
    _loadingtLabTestDD ||
    _loadingSaveData;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveLabTest([]);
        _setSuccessLabTestData([]);
        _setSuccessLabTestDD([]);
        _setSuccessLabTestList([]);
        break;
      case "error":
        _setErrorLabTestData([]);
        _setErrorLabTestDD([]);
        _SetErrorLabTestList([]);
        _setErrorSaveLabTest([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }

  return (
    <LabTestContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <LabTestHeader
        _loadingSaveData={_loadingSaveData}
        _saveLabTest={_saveLabTest}
      />
      <TabPannel
        value={value}
        handleChange={handleChange}
        key="test"
        labels={[
          "Home",
          "Admin",
          "Log",
        ]}
        components={[
          HomeTab,
          AdminTab,
        ]}
      />
      <MessageLog
        errorMessages={[
          ..._errorLabTestDD,
          ..._errorLabTestData,
          ..._errorLabTestList,
          ..._errorSaveLabTest,
          ..._errorMessages,
        ]}
        successMessages={[
          ..._successLabTestDD,
          ..._successLabTestData,
          ..._successLabTestList,
          ..._successSaveLabTest,
        ]}
        clearMessages={clearMessages}
      />
    </LabTestContext.Provider>
  );
};

export default LabTestMain;
