import React, { useState, useEffect } from "react";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../../hooks/useFetch";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { mainArray } from "./Fields";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../../../hooks/UseMessage";
import ShipDocField from "../../../CommonComponents/EnhancedFields/ShipDoc/ShipDocField";
import ShipDocList from "../../../CommonComponents/DropDowns/ShipDoc/ShipDocList";

export default function PrintBOLFromTLOAD(props) {
  let navigate = useNavigate();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [bolPdf, setBolPdf] = useState();
  const [shipDoc, setShipDoc] = useState();
  const [showShipDocList, setShowShipDocList] = useState(false);
  const [shipDocFieldName, setShipDocFieldName] = useState();

  const [_getShipDocList, _shipDocList, , , , , , _loadingShipDocList] =
    useFetch(GetFullURL("ShippingDocList"), { method: "POST" });

  const [
    _printBOL,
    ,
    ,
    _errorPrintBOL,
    _setErrorPrintBOL,
    _successPrintBOL,
    _setSuccessPrintBOL,
    _loadingPrintBOL,
  ] = useFetch(GetFullURL("PrintShippingDocFromTLOAD"), { method: "POST" });

  const operationLoading = _loadingPrintBOL || _loadingShipDocList;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessPrintBOL([]);
        break;
      case "error":
        _setErrorPrintBOL([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setShipDoc((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const fieldsState = {
    dropDowns: [],
  };

  const mainFieldsToRender = mainArray(fieldsState);
  var shipDocField = mainFieldsToRender.find(({ id }) => id === "id");

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: shipDoc, onChange };

    return newFieldObject;
  };

  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <div className="cardHeader">
                  <h2>Print Shipping Document from TLOAD</h2>
                </div>
                <div className="cardBody">
                  <ShipDocField
                    field={mergedFieldData(shipDocField)}
                    setShowShipDocList={setShowShipDocList}
                    setShipDocFieldName={setShipDocFieldName}
                    _getShipDocList={_getShipDocList}
                  />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      style={{
                        width: "32%",
                      }}
                      onClick={() => {
                    if (!shipDoc?.id) {
                          _createErrorMessage("87");
                        } else {
                          _printBOL({
                            data: {
                              Id: shipDoc.id,
                            },
                          });
                        }
                      }}
                    >
                      Print
                    </Button>
                    <Button
                      onClick={() => {
                        let navigationNextUrl = `/TerminalActivity/GLNAdminHome`;
                        navigate(navigationNextUrl);
                      }}
                      style={{
                        width: "32%",
                        background: "#DA1212",
                        borderColor: "#DA1212",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <MessageLog
                errorMessages={[..._errorPrintBOL, ..._errorMessages]}
                successMessages={[..._successPrintBOL]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
        <ShipDocList
          _shipDocList={_shipDocList}
          showShipDocList={showShipDocList}
          setShowShipDocList={setShowShipDocList}
          shipDocFieldName={shipDocFieldName}
          onChange={onChange}
        />
      </section>
    </>
  );
}
