import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { useFetch } from "../../../../../hooks/useFetch";
import { useStation } from "../../../../../hooks/UseStation";
import { useMessage } from "../../../../../hooks/UseMessage";
import { useDD } from "../../../../../hooks/UseDD";
import { useUser } from "../../../../../hooks/useUser";
import { driverPinArray, carrierArray, leftPanelArray } from "./Fields";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../../../CommonComponents/GlobalConfFiles/URLs";
import { useParams } from "react-router-dom";
import { ValidateConfirmChecking, ValidateDriverPIN } from "./ValidateShipDoc";
import ListModal from "../../../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
function CheckinV1(props) {
  //This is a simple checkin, no service interaction just take driver data and save it, it used for crude
  const params = useParams();
  const navigate = useNavigate();
  const shipDocid = params.shipDoc;
  const fieldOperationId = "CHECKIN";
  const [continueChecking, setContinueChecking] = useState(false);
  const [showPartnerList, setShowPartnerList] = useState(false);
  const [filteredPartnerList, setfilteredPartnerList] = useState([]);
  const [keyboardFocusField, setKeyboardFocusField] = useState(null);
  const [terminalSetupCarrier, setTerminalSetupCarrier] = useState([]);
  const [terminalSetupShiptos, setTerminalSetupShiptos] = useState([]);
  const [checkinFinished, setCheckinFinished] = useState(false);
  const bayDropDown = useRef([]);
  const areaDropDown = useRef([]);
  const [
    _getPartnerList,
    _partnerList,
    _setParnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    ,
    ,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("GetPartnerPerPINandType"), { method: "POST" });

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" }, true);

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    ,
    ,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" }, true);

  const [
    _deterSetup,
    _deterSetupData,
    ,
    _errorDeterSetup,
    _setErrorDeterSetup,
    ,
    ,
    _loadingSetupDeter,
  ] = useFetch(GetFullURL("DetermineSetup"), { method: "POST" }, true);

  const [
    _searchWIPShipDocs,
    _serachWIPShipDocData,
    ,
    ,
    ,
    ,
    ,
    _loadingSearchWIPShipDoc,
  ] = useFetch(GetFullURL("AdvancedShipDocSearch"), { method: "POST" });
  const [
    _stationInfo,
    _getTerminalSetup,
    _terminalSetup,
    _getStationOperation,
    _stationOperation,
    _messages,
    _setMessages,
  ] = useStation();

  const [_dd] = useDD();
  const [_user] = useUser();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    ,
    ,
    ,
    _createSuccessMessage,
    _successMessages,
    _setSuccessMessages,
  ] = useMessage();

  useEffect(() => {
    _getStationOperation(fieldOperationId, _shipDocData.fieldProcessId);
  }, [_shipDocData.fieldProcessId]);

  useEffect(() => {
    _getShipDoc({ data: { Id: shipDocid } }, ({ data: shipDocData }) => {
      //If any of checking data has populated then show them

      if (shipDocData.pin) {
        setContinueChecking(true);
      }

      _deterSetup({
        data: {
          LocationId: shipDocData.locationId,
          FieldProcessId: shipDocData.fieldProcessId,
          TransportationModeId: shipDocData.transportationModeId,
          MaterialId: shipDocData.shippingDocItemsComm[0].materialId,
        },
      });
    });

    _searchWIPShipDocs(
      {
        data: {
          locationId: _stationInfo.locationId,
          searchName: "INPROCESSTERMINAL",
        },
      },
      ({ data }) => {}
    );
  }, []);

  useEffect(() => {
    //This use effect is filter the list based on locationId,
    //if the result of filter is empty arrau issue and error message and close partner list modal
    let newPartnerList = [];
    if (!Array.isArray(_partnerList)) {
      return;
    }

    if (_partnerList.length === 0) {
      return;
    }
    // filter the list based on location id and update the state
    newPartnerList = _partnerList.filter(
      (x) => x.locationId === _stationInfo.locationId
    );

    if (newPartnerList.length === 0) {
      _createErrorMessage("18");
    }

    if (newPartnerList.length === 1) {
      _setShipDocData((current) => {
        return {
          ...current,
          driverDescriptor: newPartnerList[0].partnerDescriptor,
          driverId: newPartnerList[0].id,
        };
      });
      setContinueChecking(true);
      return;
    }

    if (newPartnerList.length > 1) {
      setfilteredPartnerList(newPartnerList);
      setShowPartnerList(true);
      return;
    }
  }, [_partnerList]);

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }

    const newValueObject = { [name]: newValue };
    _setShipDocData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  useEffect(() => {
    var allAreas = [];
    var allBays = [];
    allAreas = _dd.shipDoc.loadAreas;
    allBays = _dd.shipDoc.loadBays;

    if (typeof allAreas !== "undefined" && allAreas.length === 0) {
      return;
    }

    if (typeof allBays !== "undefined" && allBays.length === 0) {
      return;
    }

    let relvAreas = allAreas.filter(
      (x) => x.filter1 === _shipDocData.locationId
    );

    if (typeof relvAreas !== "undefined" && relvAreas.length === 0) {
      return;
    }

    let relvBays = allBays.filter((x) => x.filter1 === relvAreas[0].value);

    bayDropDown.current = relvBays;
    areaDropDown.current = relvAreas;

    let relvBaysInt = [];
    relvBays.forEach((element, Index) => {
      let bayId = parseInt(element.value);
      relvBaysInt.push(bayId);
    });

    let relvBaysIntSorted = relvBaysInt.sort(function (a, b) {
      return a - b;
    });

    if (
      typeof _serachWIPShipDocData !== "undefined" &&
      _serachWIPShipDocData.length === 0
    ) {
      _setShipDocData((prevState) => {
        return { ...prevState, loadBayId: relvBaysIntSorted[0].toString() };
      });
      return;
    }

    if (!Array.isArray(_serachWIPShipDocData)) {
      _setShipDocData((prevState) => {
        return { ...prevState, loadBayId: relvBaysIntSorted[0].toString() };
      });
      return;
    }

    let x = 0;
    relvBaysIntSorted.forEach((element) => {
      let shipDoc = _serachWIPShipDocData.find((x) => x.loadBayId == element);

      if (x == 0) {
        if (!shipDoc) {
          x = 1;

          if (!_shipDocData.loadBayId) {
            _setShipDocData((prevState) => {
              return { ...prevState, loadBayId: element };
            });
          }
        }
      }
    });
  }, [_shipDocData.locationId, _serachWIPShipDocData]);

  useEffect(() => {
    //Get shipto and carrier dropdown from terminal setup
    if (!_deterSetupData.setupData?.orderPartnersComm) {
      return;
    }

    _deterSetupData.setupData.partnersInfoComm.forEach((element) => {
      switch (element.partnerTypeId) {
        case "SHIPTO":
          let shiptoDD = {
            value: element.id,
            label: element.partnerDescriptor,
          };
          setTerminalSetupShiptos((current) => {
            return [...current, shiptoDD];
          });
          break;
        case "CARRIER":
          let carrierDD = {
            value: element.id,
            label: element.partnerDescriptor,
          };
          setTerminalSetupCarrier((current) => {
            return [...current, carrierDD];
          });

          break;
        default:
        // code block
      }
    });
  }, [_deterSetupData]);

  useEffect(() => {
    if (!checkinFinished) {
      return;
    }

    let nextUrlattribute1 = "";
    let nextUrlattribute2 = "";
    let nextUrlattribute3 = "";

    if (_stationOperation.nextUrlAttribute1) {
      try {
        nextUrlattribute1 = eval(_stationOperation.nextUrlAttribute1);
      } catch (error) {
        nextUrlattribute1 = _stationOperation.nextUrlAttribute1;
      }
    }

    if (_stationOperation.nextUrlAttribute2) {
      try {
        nextUrlattribute2 = eval(_stationOperation.nextUrlAttribute2);
      } catch (error) {
        nextUrlattribute2 = _stationOperation.nextUrlAttribute2;
      }
    }

    if (_stationOperation.nextUrlAttribute3) {
      try {
        nextUrlattribute3 = eval(_stationOperation.nextUrlAttribute3);
      } catch (error) {
        nextUrlattribute3 = _stationOperation.nextUrlAttribute3;
      }
    }
    _createSuccessMessage("94");
    var leaveTimer = setTimeout(() => {
      navigate(
        `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
      );
    }, 3000);
    return () => {
      clearTimeout(leaveTimer);
    };
  }, [checkinFinished]);

  const fieldsState = {
    dropDowns: _dd.shipDoc,
    trmCarriers: terminalSetupCarrier,
    bays: bayDropDown.current,
  };

  const handleOnKeyboardFocus = (fieldId, event) => {
    //when a keyboard field is clicked, set it in the state becuase we need
    //to only render this field
    if (event === "onFocus") {
      setKeyboardFocusField(fieldId);
    } else {
      //The blur event is triggered not only when a field is unfocused but also when a
      //field is focused it tiggers blur for all other fields, we only need to blur only
      //the relvant field in being unfocused
      if (fieldId === keyboardFocusField) {
        setKeyboardFocusField("");
      }
    }
  };

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: _shipDocData,
      onChange,
      handleOnKeyboardFocus,
      hideKeyboard: _user.disableKeyboard,
    };

    return newFieldObject;
  };

  const leftPanelFields = leftPanelArray(fieldsState);
  const driverFields = driverPinArray(fieldsState);
  const carrierFields = carrierArray(fieldsState);

  const operationLoading =
    _loadingSaveShipDoc ||
    _loadingPartnerList ||
    _loadingShipDoc ||
    _loadingSetupDeter ||
    _loadingSearchWIPShipDoc;

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveShipDoc([]);
        _setSuccessMessages([]);

        break;
      case "error":
        _setErrorSaveShipDoc([]);
        _setMessages([]);
        _setErrorShipDoc([]);
        _setErrorPartnerList([]);
        _setErorMessages([]);

        break;
      case "warning":
        break;
      default:
        break;
    }
  }
  function confirmCheckin() {
    let validationConfirmResult = ValidateConfirmChecking(
      _shipDocData,
      _createErrorMessage
    );

    if (!validationConfirmResult) {
      return;
    }

    if (!_stationOperation.fieldOperationId) {
      _createErrorMessage(
        "76",
        _shipDocData.fieldProcessId,
        fieldOperationId.current
      );
      return;
    }

    if (!_stationOperation.shipDocStatusId) {
      _createErrorMessage("95", fieldOperationId.current);
      return;
    }

    //load start date/end (loadEnDT, loadStDT) is set initially at check-in and finally at signature
    let shipDataforSave = {
      ..._shipDocData,
      checkedStatus: "X",
      checkedStDT: moment().format(),
      checkedEnDT: moment().format(),
      statusId: _stationOperation.shipDocStatusId,
      shipDocDate: moment().format(),
      startDT: moment().format(),
      weightUnitId: "LB",
      terminalSetupId: _deterSetupData.trmSetupId,
      terminalActivityinProcess: _stationOperation.terminalActivityinProcess,
      loadAreaId: areaDropDown.current[0].value,
      currentStation: _stationInfo.id,
    };
    _saveShipDoc({ data: shipDataforSave }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
      setCheckinFinished(true);
    });
  }

  function validatePIN() {
    _setErorMessages([]);
    let pinValidationResult = ValidateDriverPIN(
      _shipDocData,
      _createErrorMessage
    );
    if (!pinValidationResult) {
      return;
    }
    //When pressing the validate pin, clear the driver info
    _setShipDocData((current) => {
      return {
        ...current,
        driverDescriptor: "",
        driverId: "",
      };
    });
    _getPartnerList(
      {
        data: {
          partnerTypeId: "DRIVER",
          Pin: _shipDocData.pin,
        },
      },
      ({ data: driverList }) => {
        if (driverList.length === 0) {
          _createErrorMessage("18");
          return;
        }
      }
    );
  }
  const handelListSelect = (id) => {
    let partner = _partnerList.find((x) => x.id === id);

    if (partner) {
      _setShipDocData((current) => {
        return {
          ...current,
          driverDescriptor: partner.partnerDescriptor,
          driverId: partner.id,
        };
      });
      setContinueChecking(true);
    }
  };

  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-lg-3 col-sm-12 col-xs-12" id="leftPanel">
              <div className="card" style={{ maxWidth: "100%" }}>
                <div className="cardHeader">
                  <h2>Check-in</h2>
                </div>
                <div className="cardBody">
                  <div className="cardInput">
                    {leftPanelFields.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field)}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-sm-12 col-xs-12" id="rightPanel">
              <div className="card card2" style={{ maxWidth: 980, margin: 0 }}>
                <div className="cardBody pt-3">
                  <div className="cardInput cardInput2">
                    {driverFields.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field)}
                        key={index}
                      />
                    ))}
                  </div>
                  <div className="cardButton">
                    <Button
                      className="w-100 mb-3"
                      style={{ maxWidth: "250px" }}
                      onClick={validatePIN}
                    >
                      Validate Driver PIN
                    </Button>
                  </div>
                  {continueChecking && (
                    <div className="cardInput cardInput2">
                      {carrierFields.map((field, index) => {
                        if (keyboardFocusField) {
                          if (keyboardFocusField === field.id) {
                            return (
                              <FieldsReducer
                                field={mergedFieldData(field)}
                                key={index}
                              />
                            );
                          } else {
                            return "";
                          }
                        } else
                          return (
                            <FieldsReducer
                              field={mergedFieldData(field)}
                              key={index}
                            />
                          );
                      })}
                      <Button
                        className="w-auto"
                        style={{
                          maxWidth: "250px",
                          background: "#08bd55",
                          borderColor: "#08bd55",
                        }}
                        onClick={confirmCheckin}
                      >
                        Confim Checkin
                      </Button>
                    </div>
                  )}
                  <div className="cardButton">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px 0px",
                      }}
                    >
                      <Button
                        className="w-auto"
                        style={{
                          background: "#DA1212",
                          borderColor: "#DA1212",
                        }}
                        onClick={() => {
                          navigate(_stationInfo.stationHome);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ListModal
              setId={handelListSelect}
              dataList={filteredPartnerList}
              showListModal={showPartnerList}
              setShowModal={setShowPartnerList}
              title="List of Drivers"
              columns={[
                { field: "id", headerName: "Driver ID", width: 150 },
                {
                  field: "name",
                  headerName: "Driver Name",
                  width: 250,
                },
              ]}
            />
            <div className="col-12 mt-3">
              <MessageLog
                errorMessages={[
                  ..._errorPartnerList,
                  ..._errorSaveShipDoc,
                  ..._errorShipDoc,
                  ..._errorMessages,
                  ..._messages,
                ]}
                successMessages={[..._successSaveShipDoc, ..._successMessages]}
                warningMessages={[]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CheckinV1;
