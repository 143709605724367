import React, { useState, useEffect } from "react";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import HomeTab from "./Main/Index";
import AdminTab from "./Admin/Index";
import ShippingTab from "./Shipping/Index";
import Conversions from "./Conversions/Index";
import QuantityConversion from "./QuantityConversion/Index";
import Locations from "./Locations/Index";
import MateriaHeader from "./Header/index";
import { MaterialContext } from "./context";
import { useFetch } from "./../../../hooks/useFetch";
import { useMessage } from "./../../../hooks/UseMessage";
import { GetFullURL } from "./../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen";
import TabPannel from "../../../CommonComponents/TabPannel/index";
import { useParams } from "react-router-dom";

export default function MaterialMain(props) {
  const [value, setValue] = useState(0);
  const [operation, setOperation] = useState("");
  const [materialId, setMaterialId] = useState("");
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const params = useParams();

  const [
    _getMaterialData,
    _materialData,
    _setMaterialData,
    _errorMaterialData,
    _setErrorMaterialData,
    _successMaterialData,
    _setSuccessMaterialData,
    _loadingMaterialData,
  ] = useFetch(GetFullURL("GetMaterial"), { method: "POST" });
  const [
    _getMaterialDD,
    _materialDD,
    ,
    _errorMaterialDD,
    _setErrorMaterialDD,
    _successMaterialDD,
    _setSuccessMaterialDD,
    _loadingtMaterialDD,
  ] = useFetch(GetFullURL("MaterialDropDowns"), { method: "POST" });
  const [
    _saveMaterial,
    ,
    ,
    _errorSaveMaterial,
    _setErrorSaveMaterial,
    _successSaveMaterial,
    _setSuccessSaveMaterial,
    _loadingSaveMaterial,
  ] = useFetch(GetFullURL("SaveMaterial"), { method: "POST" });

  const [
    _getConversionList,
    _conversionList,
    ,
    _errorConvesrionList,
    _setErrorConvesrionList,
    _successConversionList,
    _setSuccessConversionList,
    _loadingConvesrionList,
  ] = useFetch(GetFullURL("ConversionList"), { method: "POST" });
  useEffect(() => {
    //when componen mounts,set ID and operation
    if (params.op) {
      setOperation(params.op);
    }
    if (params.id) {
      setMaterialId(params.id);
    }
  }, []);

  useEffect(() => {
    _getMaterialDD();
    if (materialId) {
      _getMaterialData({ data: { id: materialId } });
    }
  }, [materialId]);

  const handleChange = (event, newValue) => setValue(newValue);

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;
    let newValue;

    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = "true") : (newValue = "false");
        break;

      default:
        newValue = value;
        break;
    }

    if (newValue === "select" || newValue === "SELECT") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    _setMaterialData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };


  const contextState = {
    onChange,
    operation,
    dropDowns: _materialDD || [],
    materialId,
    _materialData,
    _setMaterialData,
    _conversionList,
    _getConversionList,
    _createErrorMessage,
    setOperation,
  };

  const operationLoading =
    _loadingMaterialData ||
    _loadingtMaterialDD ||
    _loadingConvesrionList ||
    _loadingSaveMaterial;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveMaterial([]);
        _setSuccessMaterialData([]);
        _setSuccessMaterialDD([]);
        _setSuccessConversionList([]);
        break;
      case "error":
        _setErrorMaterialData([]);
        _setErrorMaterialDD([]);
        _setErrorSaveMaterial([]);
        _setErorMessages([]);
        _setErrorConvesrionList([]);
        break;
      default:
        break;
    }
  }

  return (
    <MaterialContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <MateriaHeader
        _loadingSaveMaterialData={_loadingSaveMaterial}
        _saveMaterial={_saveMaterial}
      />
      <TabPannel
        value={value}
        handleChange={handleChange}
        labels={[
          "Home",
          "Shipping",
          "Conversions",
          "Locations",
          "Qunatity Conversion",
          "Admin",
          "Log",
        ]}
        components={[
          HomeTab,
          ShippingTab,
          Conversions,
          Locations,
          QuantityConversion,
          AdminTab,
        ]}
      />
      <MessageLog
        errorMessages={[
          ..._errorMaterialDD,
          ..._errorMaterialData,
          ..._errorSaveMaterial,
          ..._errorMessages,
          ..._errorConvesrionList,
        ]}
        successMessages={[
          ..._successMaterialDD,
          ..._successMaterialData,
          ..._successSaveMaterial,
          ..._successConversionList,
        ]}
        clearMessages={clearMessages}
      ></MessageLog>
    </MaterialContext.Provider>
  );
}
