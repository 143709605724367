import React, { useState, useEffect } from "react";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { mainArray } from "./Fields";
import { useNavigate } from "react-router-dom";
import { FieldsReducer } from "../../CommonComponents/Reducer/Index";
import { ExportToExcel } from "../../utils/exportToExcel";
import { useMessage } from "../../hooks/UseMessage";
import { DataGrid } from "@mui/x-data-grid";

export default function ShipDocReportV1(props) {
  let navigate = useNavigate();
  const [shipDocReportParam, setShipDocReportParam] = useState();
  const [reportshipDocs, setReportshipDocs] = useState([]);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [logView, setLogView] = useState(false);

  const excelReport = reportshipDocs?.map((excelReport) => ({
    BOL: excelReport.id,
    "BOL Date": excelReport.shipDocDate,
    "Start DT": excelReport.startDT,
    "End DT": excelReport.endDT,
    "Ship To": excelReport.shiptoDescriptor,
    "In-Weight": excelReport.inWeight,
    "Out-Weight": excelReport.outWeight,
    "Net-Weight": excelReport.netWeight,
    Pin: excelReport.pin,
    Driver: excelReport.driverDescriptor,
    Carrier: excelReport.carrierDescriptor,
    Vehicle: excelReport.vehicleId,
    Trailer: excelReport.trailerId,
    Material: excelReport.materialDescriptor,
  }));

  let fileName = `DailyReport ${shipDocReportParam?.shipDocDate}`;

  const [
    _requestReport,
    _reportData,
    ,
    _errorReport,
    _setErrorReport,
    _successReport,
    _setSuccessReport,
    _loadingReport,
  ] = useFetch(GetFullURL("DailyShipReport"), { method: "POST" });

  const [
    _getGeneralDD,
    _GeneralDDData,
    ,
    _errorGeneralDD,
    _setErrorGeneralDD,
    _successGeneralDD,
    _setSuccessGeneralDD,
    _loadingGeneralDD,
  ] = useFetch(GetFullURL("GetGeneralDropDown"), { method: "POST" });

  const operationLoading = _loadingReport || _loadingGeneralDD;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessReport([]);
        _setSuccessGeneralDD([]);
        break;
      case "error":
        _setErorMessages([]);
        _setErrorReport([]);
        _setErrorGeneralDD([]);
        break;
      default:
        break;
    }
  }

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setShipDocReportParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  useEffect(() => {
    _getGeneralDD({ data: { locations: true } });
  }, []);

  useEffect(() => {
    if (excelReport.length != 0) {
      setDownloadExcel(true);
    }
  }, [excelReport]);

  const fieldsState = {
    dropDowns: _GeneralDDData || [],
  };

  const mainFieldsToRender = mainArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    return newFieldObject;
  };

  return (
    <>
      <div className="container-fluid">
        {operationLoading && <LoadingScreen />}
        <div className="row">
          <div className="col-4">
            {logView ? (
              ""
            ) : (
              <div>
                <div className="card mb-4">
                  <div className="cardHeader">
                    <h2>Search BOLs</h2>
                  </div>
                  <div className="cardBody">
                    {mainFieldsToRender.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field, shipDocReportParam)}
                        key={index}
                      />
                    ))}
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{
                          width: "32%",
                        }}
                        onClick={() => {
                          if (
                            shipDocReportParam?.fromShipDocDate === null ||
                            shipDocReportParam?.fromShipDocDate === undefined
                          ) {
                            _createErrorMessage("91");
                            return;
                          } else if (
                            shipDocReportParam?.locationId === null ||
                            shipDocReportParam?.locationId === undefined ||
                            shipDocReportParam?.locationId === "select"
                          ) {
                            _createErrorMessage("92");
                            return;
                          } else {
                            _requestReport(
                              {
                                data: {
                                  LocationId: shipDocReportParam.locationId,
                                  FromShipDocDate:
                                    shipDocReportParam.fromShipDocDate,
                                  ToShipDocDate:
                                    shipDocReportParam.toShipDocDate,
                                },
                              },
                              ({ data }) => {
                                if (!data) {
                                  return;
                                }
                                if (data.length != 0) {
                                  setReportshipDocs(data);
                                  setLogView(true);
                                } else {
                                  _createErrorMessage("90");
                                }
                              }
                            );
                          }
                        }}
                      >
                        Run Report
                      </Button>
                      <Button
                        onClick={() => {
                          let navigationNextUrl = `/ReportsHome`;
                          navigate(navigationNextUrl);
                        }}
                        style={{
                          width: "32%",
                          background: "#DA1212",
                          borderColor: "#DA1212",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {logView ? (
            <div className="col-12">
              <div className="mt-0">
                <div
                  className="custom-table w-100"
                  style={{ height: 700, overflow: "auto" }}
                >
                  <DataGrid
                    rows={reportshipDocs || []}
                    columns={[
                      { field: "id", headerName: "BOL", width: 100 },
                      {
                        field: "shipDocDate",
                        headerName: "Date",
                        width: 190,
                      },
                      {
                        field: "startDT",
                        headerName: "Start DT",
                        width: 190,
                      },
                      { field: "endDT", headerName: "End DT", width: 190 },
                      {
                        field: "shiptoDescriptor",
                        headerName: "Ship To",
                        width: 250,
                      },
                      {
                        field: "inWeight",
                        headerName: "In Weight",
                        width: 100,
                      },
                      {
                        field: "netWeight",
                        headerName: "Net Weight",
                        width: 100,
                      },
                      {
                        field: "outWeight",
                        headerName: "Out Weight",
                        width: 100,
                      },
                      { field: "pin", headerName: "Pin", width: 100 },
                      {
                        field: "driverDescriptor",
                        headerName: "Driver",
                        width: 250,
                      },
                      {
                        field: "carrierDescriptor",
                        headerName: "Carrier Descriptor",
                        width: 250,
                      },
                      {
                        field: "vehicleId",
                        headerName: "Vehicle",
                        width: 100,
                      },
                      {
                        field: "trailerId",
                        headerName: "Trailer",
                        width: 100,
                      },
                      {
                        field: "materialDescriptor",
                        headerName: "Material Descriptor",
                        width: 350,
                      },
                    ]}
                    pageSize={100}
                    rowsPerPageOptions={[14]}
                    onSelectionModelChange={""}
                  />
                </div>
              </div>
              <div className="row m-2">
                <Button
                  onClick={() => {
                    setLogView(false);
                    if (
                      shipDocReportParam?.shipDocDate ||
                      shipDocReportParam?.locationId
                    ) {
                      shipDocReportParam.shipDocDate = null;
                      shipDocReportParam.locationId = null;
                    }
                  }}
                  style={{
                    width: "150px",
                    background: "#DA1212",
                    borderColor: "#DA1212",
                    marginRight: 10,
                  }}
                >
                  Reset
                </Button>
                {downloadExcel ? (
                  <ExportToExcel apiData={excelReport} fileName={fileName} />
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-12 mt-4">
            <MessageLog
              errorMessages={[
                ..._errorReport,
                ..._errorGeneralDD,
                ..._errorMessages,
              ]}
              successMessages={[..._successReport, ..._successGeneralDD]}
              clearMessages={clearMessages}
            ></MessageLog>
          </div>
        </div>
      </div>
    </>
  );
}
