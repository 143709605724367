import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useFetch } from "../../../../../hooks/useFetch";
import { useMessage } from "../../../../../hooks/UseMessage";
import { useUser } from "../../../../../hooks/useUser";
import { useStation } from "../../../../../hooks/UseStation";
import { useDD } from "../../../../../hooks/UseDD";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../../CommonComponents/GlobalConfFiles/URLs";
import { ReactComponent as OverLoadAlram } from "../../../../../Assets/SVGs/Alrams/AnimatedAlram1.svg";

import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import HeaderV2 from "../../Header/HeaderV2";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { FlipCounter } from "../../../../../CommonComponents/FlipCounter/FlipCounter";
import TruckAnimation from "../../../../../CommonComponents/TruckAnimation/index";
import { useParams } from "react-router-dom";
import Sidebar from "./SideBar";
function ScaleLoadingV1(props) {
  //This is scale loading page used by glendale when they had on scale per load rack, this prior to april 2023
  const navigate = useNavigate();
  const params = useParams();
  const shipDocId = params.shipDocId;
  const fieldOperation = "SCALE_LOADINGV1";
  const [loading, setLoading] = useState(false);
  const [topLog, setTopLog] = useState({});
  const [issuedAlrams, setIssuedAlrams] = useState([]);

  //state to indicate loading operation is done
  const [loadingFinished, setLoadingFinished] = useState(false);
  const initialMeterLoadedQty = useRef(0);
  const stopStartButton = useRef(null);
  const takeWeightButton = useRef(null);
  const finishButton = useRef(null);
  //needed to use useRef in order to pass the current weight to updateReading API Call
  // the "scaleMeasurements" did not contain the correct value, it only conatins the initial value once the useEffect is run
  const currentScaleReading = useRef(0);

  const [contollerMeasurements, setContollerMeasurements] = useState({
    meterReading: 0,
    meterUnitId: "LB",
  });
  const [scaleMeasurements, setScaleMeasurements] = useState({
    weight: 0,
    weightUnitId: "LB",
  });

  //hooks
  const [_user, ,] = useUser();

  let serviceSimulation = false;

  if (
    _user.serviceSimulation === "true" ||
    _user.serviceSimulation === "True"
  ) {
    serviceSimulation = true;
  }
  const [
    _stationInfo,
    ,
    ,
    _getStationOperation,
    _stationOperation,
    _stationMessages,
    _setStationMessages,
  ] = useStation();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
    _createSuccessMessage,
    _successMessages,
    _setSuccessMessages,
  ] = useMessage();

  let scaleApiInterval = 1000;
  let meterApiInterval = 2000;
  let scaleTestIncrement = 1000;
  let meterTestIncrement = 1000;

  if (_stationInfo.scaleApiInterval > 0) {
    scaleApiInterval = _stationInfo.scaleApiInterval;
  }

  if (_stationInfo.meterApiInterval > 0) {
    meterApiInterval = _stationInfo.meterApiInterval;
  }

  if (_stationInfo.scaleTestIncrement > 0) {
    scaleTestIncrement = _stationInfo.scaleTestIncrement;
  }
  if (_stationInfo.meterTestIncrement > 0) {
    meterTestIncrement = _stationInfo.meterTestIncrement;
  }
  const [_dd] = useDD();

  const [
    _resetController,
    ,
    ,
    _resetControlleError,
    _setResetControlleError,
    ,
    ,
    _loadingResetController,
  ] = useFetch(GetServiceFullURL("ResetController"), {}, true);

  const [
    _getWeight,
    ,
    ,
    _requestWeightError,
    _setRequestWeightError,
    ,
    ,
    _loadingRequestWeight,
  ] = useFetch(GetServiceFullURL("GetWeight"), {}, true);

  const [
    _startLoad,
    _startLoadData,
    _setStartLoadData,
    _startLoadError,
    _setStartLoadError,
    ,
    ,
    _loadingStartLoad,
  ] = useFetch(GetServiceFullURL("StartLoadingController"), {
    method: "GET",
    headers: {},
  });

  const [
    _stopLoad,
    _stopLoadData,
    _setStopLoadData,
    _stopLoadError,
    _setStopLoadError,
    ,
    ,
    _loadingStopLoad,
  ] = useFetch(GetServiceFullURL("StopLoadingController"), {
    method: "GET",
    headers: {},
  });

  const [
    _getControllerData,
    _controllerData,
    _setControllerData,
    _controllerDataError,
    _setControllerDataError,
    _controllerDataSuccess,
    _setControllerDataSuccess,
    ,
  ] = useFetch(
    GetServiceFullURL("GetControllerData"),
    {
      method: "GET",
      headers: {},
    },
    true
  );

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    _successShipDoc,
    _setSuccessShipDoc,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" });

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" }, true);

  const [_updateFieldReadings, , , , , , , ,] = useFetch(
    GetFullURL("UpdateFieldReadings"),
    { method: "POST" }
  );

  function controllerSimulation() {
    if (!serviceSimulation) {
      return;
    }

    setContollerMeasurements((x) => {
      let newQty = x.meterReading + meterTestIncrement;
      return { ...x, meterReading: newQty };
    });
  }
  function scaleSimulation() {
    if (!serviceSimulation) {
      return;
    }

    if (loading) {
      setScaleMeasurements((x) => {
        let newQty = x.weight + scaleTestIncrement;
        return { ...x, weight: newQty };
      });
    } else {
      if (_shipDocData.outWeightStatus === "X") {
        setScaleMeasurements((x) => {
          return { ...x, weight: _shipDocData.outWeight };
        });
      } else if (_shipDocData.intWeightStatus === "X") {
        setScaleMeasurements((x) => {
          return { ...x, weight: _shipDocData.inWeight };
        });
      } else if (scaleMeasurements.weight == 0) {
        setScaleMeasurements((x) => {
          return { ...x, weight: 25000 };
        });
      }
    }
  }

  function GetControllerData() {
    if (serviceSimulation) {
      return;
    }
    _setControllerDataError([]);
    _getControllerData({}, (response) => {
      setTopLog(() => {
        return { success: response.success, message: response.message };
      });
      if (response.success) {
        if (_stationInfo.meterCorrectionFactor > 0) {
          response.data.meterReading =
            response.data.meterReading * _stationInfo.meterCorrectionFactor;
        }
        setContollerMeasurements({ ...response.data });
      }
    });
  }
  function GetWeightData() {
    if (serviceSimulation) {
      return;
    }
    _setRequestWeightError([]);
    _getWeight({}, (response) => {
      setTopLog(() => {
        return { success: response.success, message: response.message };
      });
      if (response.success) {
        setScaleMeasurements({ ...response.data });
      }
    });
  }

  useEffect(() => {
    //on mount read shipDoc and set current operation ID
    _getShipDoc({ data: { Id: shipDocId } }, ({ data: shipDocData }) => {
      _getStationOperation(fieldOperation, shipDocData.fieldProcessId);
      initialMeterLoadedQty.current = shipDocData.currentMeterReading;
    });

    return () => {
      issueStopCommand();
    };
  }, []);

  useEffect(() => {
    //on the mount re-set controller when service mode using the service in order re-set the meter count
    if (!serviceSimulation) {
      _resetController();
    }
  }, [serviceSimulation]);

  useEffect(() => {
    //Setting the max weight from station one time
    if (_shipDocData.cutoffWeight == 0) {
      _setShipDocData((current) => {
        return { ...current, cutoffWeight: parseInt(_stationInfo.maxWeight) };
      });
    }
  });

  useEffect(() => {
    //This useEffect handles the controller data in real and simulation mode
    let contrllerDataTimer = null;

    if (serviceSimulation) {
      contrllerDataTimer = setInterval(() => {
        controllerSimulation();
      }, meterApiInterval);
    } else {
      contrllerDataTimer = setInterval(() => {
        GetControllerData();
      }, meterApiInterval);
    }
    //controller simulation data needs to be stopped if the stop loading
    //button is hit, so if loading = false and simulation mode is true then
    //clear interval
    if (serviceSimulation) {
      if (!loading) {
        clearInterval(contrllerDataTimer);
      }
    }

    return () => {
      clearInterval(contrllerDataTimer);
    };
  }, [loading, serviceSimulation]);

  useEffect(() => {
    //This useEffect handles the scale data
    let scaleServiceTimer = null;

    if (serviceSimulation) {
      scaleServiceTimer = setInterval(() => {
        scaleSimulation();
      }, scaleApiInterval);
    } else {
      scaleServiceTimer = setInterval(() => {
        GetWeightData();
      }, scaleApiInterval);
    }

    // if (serviceSimulation) {
    //   if (!loading) {
    //     clearInterval(scaleServiceTimer);
    //   }
    // }

    return () => {
      clearInterval(scaleServiceTimer);
    };
  }, [
    loading,
    serviceSimulation,
    _shipDocData.outWeightStatus,
    _shipDocData.intWeightStatus,
  ]);

  useEffect(() => {
    currentScaleReading.current = scaleMeasurements.weight;
    calculateFillPercent();
    //check for auto cut-off
    if (scaleMeasurements.weight >= _shipDocData.cutoffWeight) {
      if (loading) {
        stopStartButton.current.click();

        let foundAlram = issuedAlrams.find((x) => x === 1);
        if (!foundAlram) {
          setIssuedAlrams((current) => [...current, 1]);
        }
      }
    }
  }, [scaleMeasurements.weight]);

  useEffect(() => {
    if (!loadingFinished) {
      return;
    }

    //Creating the next URL
    let nextUrlattribute1 = "";
    let nextUrlattribute2 = "";
    let nextUrlattribute3 = "";

    if (_stationOperation.nextUrlAttribute1) {
      try {
        nextUrlattribute1 = eval(_stationOperation.nextUrlAttribute1);
      } catch (error) {
        nextUrlattribute1 = _stationOperation.nextUrlAttribute1;
      }
    }

    if (_stationOperation.nextUrlAttribute2) {
      try {
        nextUrlattribute2 = eval(_stationOperation.nextUrlAttribute2);
      } catch (error) {
        nextUrlattribute2 = _stationOperation.nextUrlAttribute2;
      }
    }

    if (_stationOperation.nextUrlAttribute3) {
      try {
        nextUrlattribute3 = eval(_stationOperation.nextUrlAttribute3);
      } catch (error) {
        nextUrlattribute3 = _stationOperation.current.nextUrlAttribute3;
      }
    }

    navigate(
      `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
    );
  }, [loadingFinished]);

  function UpdateScaleReading() {
    _updateFieldReadings(
      {
        data: {
          ShipDocId: shipDocId,
          ReadingType: "SCALE",
          CurrentReading: currentScaleReading.current,
          ReadingUnitId: scaleMeasurements.weightUnitId,
        },
      },
      () => {}
    );
  }
  useEffect(() => {
    let fieldReadingsTimer = null;

    fieldReadingsTimer = setInterval(() => {
      UpdateScaleReading();
    }, 2000);

    return () => {
      clearInterval(fieldReadingsTimer);
    };
  }, []);

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessShipDoc([]);
        _setSuccessSaveShipDoc([]);
        _setControllerDataSuccess([]);
        _setSuccessMessages([]);
        break;
      case "error":
        _setErrorSaveShipDoc([]);
        _setErorMessages([]);
        _setErrorShipDoc([]);
        _setControllerDataError([]);
        _setStartLoadError([]);
        _setStopLoadError([]);
        _setResetControlleError([]);
        break;
      default:
        break;
    }
  }

  const operationLoading =
    _loadingShipDoc ||
    _loadingSaveShipDoc ||
    _loadingStartLoad ||
    _loadingStopLoad ||
    _loadingResetController;

  function issueStopCommand() {
    if (!serviceSimulation) {
      _setStopLoadError([]);
      _stopLoad({}, (response) => {
        setTopLog(() => {
          return { success: response.success, message: response.message };
        });
        if (response.success) {
          setLoading(!loading);
          return;
        }
      });
    } else {
      setLoading(!loading);
    }
  }

  function issueStartCommand() {
    if (!serviceSimulation) {
      _setStartLoadError([]);
      _startLoad({}, (response) => {
        setTopLog(() => {
          return { success: response.success, message: response.message };
        });

        if (response.success) {
          setLoading(!loading);
          return;
        }
      });
    } else {
      setLoading(!loading);
    }
  }

  const onClickStartStop = () => {
    if (!loading) {
      //When attampting to START
      setIssuedAlrams([]);
      //Before stating check if shipDoc is populated
      if (shipDocId == null || shipDocId == "") {
        _createErrorMessage("16");
        return;
      }
      //Before starting check in cutt-off weight is populated
      if (
        _shipDocData.cutoffWeight == null ||
        _shipDocData.cutoffWeight === 0 ||
        _shipDocData.cutoffWeight == ""
      ) {
        _createErrorMessage("15");
        return;
      }

      if (scaleMeasurements.weight >= _shipDocData.cutoffWeight) {
        _createErrorMessage("85");
        return;
      }

      if (_shipDocData.inWeightStatus === "") {
        _createErrorMessage("71");
        return;
      }

      if (_shipDocData.inWeight <= 10000) {
        _createErrorMessage("128", 10000);
        return true;
      }
      issueStartCommand();
      upDateShipDocStartLoad();
    } else {
      //When attampting to STOP
      issueStopCommand();
      upDateShipDocStopLoad();
    }
  };

  const calculateFillPercent = () => {
    let percentLoaded = 0;
    let plannedQty = 0;
    let loadedQty = 0;
    let currentWeight = 0;

    //if max/cuttoff weight value is 0,  set % to zero because we can not divid by 0
    if (_shipDocData.cutoffWeight === 0 || !_shipDocData.cutoffWeight) {
      return 0;
    }
    if (_shipDocData.inWeight == 0) {
      return percentLoaded.toFixed(2);
    }

    if (loading) {
      currentWeight = scaleMeasurements.weight;
    } else {
      currentWeight = _shipDocData.currentScaleWeight;
    }

    plannedQty = _shipDocData.cutoffWeight - _shipDocData.inWeight;
    loadedQty = currentWeight - _shipDocData.inWeight;

    percentLoaded = (loadedQty * 100) / plannedQty;
    return percentLoaded.toFixed(2);
  };
  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    //Other way of getting value
    //let x = e.target.getAttribute("data-item");
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    //if item is populated then this is an update from item and hence need to search
    //for a particular item and update it

    _setShipDocData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const onChangeItem = (e) => {
    let item;
    let fieldTypeItem;
    const {
      target: { value, name },
    } = e;

    item = e.target.dataset.item;
    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeItem = e.target.dataset.type;

    let newValue;
    switch (fieldTypeItem) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    let newItems = _shipDocData.shippingDocItemsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.itemNumber == item) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setShipDocData((prevState) => {
      return { ...prevState, shippingDocItemsComm: newItems };
    });
  };

  const upDateShipDocStartLoad = () => {
    if (_shipDocData.id != null) {
      _shipDocData.loadStDT = moment().format();
      _shipDocData.loadUnloadinProcess = true;
      _shipDocData.statusId = "LOADING";
      _setErrorSaveShipDoc([]);
      _setSuccessSaveShipDoc([]);
      _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {
        _setShipDocData(newShipDocData);
      });
    }
  };

  const upDateShipDocStopLoad = () => {
    if (_shipDocData.id != null) {
      _shipDocData.LoadEnDT = moment().format();
      _shipDocData.LoadStatus = "X";
      _shipDocData.loadUnloadinProcess = false;
      _shipDocData.statusId = "LOADEDSHDOC";
      _shipDocData.currentMeterReading = contollerMeasurements.meterReading;
      _shipDocData.meterUnitId = contollerMeasurements.meterUnitId;
      _shipDocData.rawTemperature = contollerMeasurements.temperature;

      _shipDocData.currentScaleWeight = scaleMeasurements.weight;
      _shipDocData.scaleUnitId = scaleMeasurements.weightUnitId;
      _shipDocData.printerId = _stationInfo.printerId;

      _shipDocData.shippingDocItemsComm.forEach((item, index) => {
        item.currentMeterReading = contollerMeasurements.meterReading;
        item.meterUnitId = contollerMeasurements.meterUnitId;
      });
      _setErrorSaveShipDoc([]);
      _setSuccessSaveShipDoc([]);
      _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {
        _setShipDocData(newShipDocData);
      });
    }
  };

  const takeWeightButtonStatus = () => {
    // //if loading in process, we can take the weight
    if (loading) {
      return true;
    }
    return false;
  };

  const stopStartButtonStatus = () => {
    //If the inweight is not taken disable the start button

    if (stopStartButton.current?.innerText === "START LOADING") {
      if (_shipDocData.inWeight === 0 || _shipDocData.inWeight === undefined)
        return true;
    }
    return false;
  };

  const updateShipDocInWeight = (shipDocData) => {
    shipDocData.inWeightStatus = "X";
    shipDocData.inWeightStDT = moment().format();
    shipDocData.inWeightEnDT = moment().format();

    shipDocData.inWeight = scaleMeasurements.weight;
    shipDocData.currentScaleWeight = scaleMeasurements.weight;
    shipDocData.scaleUnitId = scaleMeasurements.weightUnitId;
    shipDocData.weightUnitId = "LB";
    shipDocData.currentMeterReading = contollerMeasurements.meterReading;
    shipDocData.meterUnitId = contollerMeasurements.meterUnitId;

    shipDocData.statusId = "INWGHTSHDOC";
    //Update station info
    shipDocData.currentStation = _stationInfo.id;
    shipDocData.loadingStationId = _stationInfo.id;
    shipDocData.scaleStationId = _stationInfo.id;

    shipDocData.fieldDataPointId = "SCALE";
    shipDocData.printerId = _stationInfo.printerId;
    shipDocData.signatureReady = false;

    return shipDocData;
  };

  const updateShipDocOutWeight = (shipDocData) => {
    shipDocData.outWeightStatus = "X";
    shipDocData.outWeightStDT = moment().format();
    shipDocData.outWeightEnDT = moment().format();

    shipDocData.outWeight = scaleMeasurements.weight;
    shipDocData.currentScaleWeight = scaleMeasurements.weight;
    shipDocData.scaleUnitId = scaleMeasurements.weightUnitId;
    shipDocData.weightUnitId = "LB";
    shipDocData.currentMeterReading = contollerMeasurements.meterReading;
    shipDocData.meterUnitId = contollerMeasurements.meterUnitId;

    shipDocData.statusId = "LOADEDSHDOC";
    shipDocData.currentStation = _stationInfo.id;
    shipDocData.loadingStationId = _stationInfo.id;
    shipDocData.scaleStationId = _stationInfo.id;

    shipDocData.fieldDataPointId = "SCALE";

    shipDocData.printerId = _stationInfo.printerId;
    shipDocData.signatureReady = true;
    shipDocData.loadUnloadinProcess = false;
    shipDocData.loadEnDT = moment().format();
    shipDocData.loadStatus = "X";
    shipDocData.rawTemperature = contollerMeasurements.temperature;

    shipDocData.shippingDocItemsComm.forEach((item, index) => {
      item.currentMeterReading = contollerMeasurements.meterReading;
      item.meterUnitId = contollerMeasurements.meterUnitId;
    });
    return shipDocData;
  };

  const takeOutWeight = () => {
    if (
      scaleMeasurements.weight === 0 ||
      scaleMeasurements.weight === undefined
    ) {
      _createErrorMessage("86");
      return;
    }

    if (loading) {
      _createErrorMessage("129");
      return;
    }

    if (scaleMeasurements.weight < 10000) {
      _createErrorMessage("130");
      return;
    }

    let changedShipDocData = _shipDocData;

    if (
      changedShipDocData.inWeight === 0 ||
      changedShipDocData.inWeight === undefined
    ) {
      _createErrorMessage("61");
      return;
    }

    let netWeight = scaleMeasurements.weight - changedShipDocData.inWeight;

    if (netWeight <= 10000) {
      _createErrorMessage("127", netWeight, 10000);
      return;
    }
    changedShipDocData = updateShipDocOutWeight(changedShipDocData);
    issueStopCommand();
    _setErrorSaveShipDoc([]);
    _setSuccessSaveShipDoc([]);
    _saveShipDoc({ data: changedShipDocData }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
      setLoadingFinished(true);
    });
  };
  const takeInWeight = () => {
    if (
      scaleMeasurements.weight === 0 ||
      scaleMeasurements.weight === undefined
    ) {
      _createErrorMessage("86");
      return;
    }

    if (_shipDocData?.outWeight > 0) {
      _createErrorMessage("133");
      return;
    }
    if (loading) {
      _createErrorMessage("129");
      return;
    }

    if (scaleMeasurements.weight < 10000) {
      _createErrorMessage("130");
      return;
    }
    if (scaleMeasurements.weight > 39000) {
      _createErrorMessage("136");
      return;
    }

    if (_shipDocData.loadStDT) {
      _createErrorMessage("134");
      return;
    }
    let changedShipDocData = _shipDocData;

    changedShipDocData = updateShipDocInWeight(changedShipDocData);
    _setErrorSaveShipDoc([]);
    _setSuccessSaveShipDoc([]);
    _saveShipDoc({ data: changedShipDocData }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
    });
  };

  const inweightButtonColor = () => {
    if (_shipDocData.inWeightStatus === "X") {
      return "#DA1212";
    }

    return "#08bd55";
  };
  const outweightButtonColor = () => {
    if (_shipDocData.outWeightStatus === "X") {
      return "#DA1212";
    }
    return "#08bd55";
  };

  const stopStartButtonColor = () => {
    if (loading) {
      return "#ec0505";
    } else {
      return "#08bd55";
    }
  };
  let error = "bg-danger";
  let success = "bg-success";
  let errorClass = `w-100 rounded-lg px-5 py-3 ${error} d-flex align-items-center justify-content-center`;
  let successClass = `w-100 rounded-lg px-5 py-1 ${success} d-flex align-items-center justify-content-center`;
  return (
    <>
      <section>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-12 p-0">
              <HeaderV2 _shipDocData={_shipDocData} weightType={null} />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 p-0">
              {topLog.success ? (
                <div className="col-12">
                  <div className={successClass}>
                    <span className="text-white"> {topLog.message}</span>
                  </div>
                </div>
              ) : (
                <div className="col-12">
                  <div className={errorClass}>
                    <span className="text-white"> {topLog.message}</span>
                  </div>
                </div>
              )}
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-3 col-sm-12 col-xs-12">
              <h3 style={{ fontSize: "40px" }}>Scale Loading</h3>
              <Sidebar
                _shipDocDDData={_dd.shipDoc}
                onChangeItem={onChangeItem}
                onChange={onChange}
                _shipDocData={_shipDocData}
                _stationInfo={_stationInfo}
              />
              {issuedAlrams.map((item, index) => {
                switch (item) {
                  case 1:
                    return (
                      <div className="row d-flex align-items-center justify-content-center">
                        <OverLoadAlram />
                        <h3> OverLoad</h3>
                      </div>
                    );

                  default:
                  // code block
                }
              })}
            </div>

            <div className="col-lg-9 col-sm-12 col-xs-12">
              <div className="" style={{ flex: 1 }}>
                <div className="d-flex align-items-center justify-content-between">
                  <FlipCounter
                    number={scaleMeasurements.weight}
                    className="mb-4"
                    className2="mt-4"
                    backgroundColorClassName="bg-info"
                    //                   title="Scale"
                  />
                  <Button
                    disabled={takeWeightButtonStatus()}
                    className="px-1 py-1 mt-1 w-30"
                    onClick={takeInWeight}
                    ref={takeWeightButton}
                    style={{
                      height: "10rem",
                      fontSize: 20,
                      fontWeight: "bold",
                      background: inweightButtonColor(),
                      borderColor: "#08bd55",
                    }}
                  >
                    Take In Weight
                  </Button>
                  <Button
                    disabled={takeWeightButtonStatus()}
                    className="px-2 py-2 mt-1 w-30"
                    onClick={takeOutWeight}
                    ref={takeWeightButton}
                    style={{
                      height: "10rem",
                      fontSize: 20,
                      fontWeight: "bold",
                      background: outweightButtonColor(),
                      borderColor: "#08bd55",
                    }}
                  >
                    Take Out Weight
                  </Button>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-5">
                  <div className="mx-3" style={{ width: "85%" }}>
                    <TruckAnimation
                      compartCount={1}
                      activeIndex={0}
                      fillPerCent={calculateFillPercent()}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column">
                  <div className="d-flex" style={{ gap: "1rem" }}>
                    <Button
                      className="px-5 py-3 mt-5 w-50"
                      onClick={onClickStartStop}
                      disabled={stopStartButtonStatus()}
                      ref={stopStartButton}
                      style={{
                        fontSize: 50,
                        fontWeight: "bold",
                        background: stopStartButtonColor(),
                        marginLeft: "300px",
                      }}
                    >
                      {loading ? "STOP" : "START"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <MessageLog
            errorMessages={[
              ..._errorShipDoc,
              ..._errorMessages,
              ..._errorSaveShipDoc,
              ..._controllerDataError,
              ..._requestWeightError,
              ..._startLoadError,
              ..._stopLoadError,
              ..._resetControlleError,
            ]}
            successMessages={[
              ..._successShipDoc,
              ..._controllerDataSuccess,
              ..._successMessages,
            ]}
            clearMessages={clearMessages}
          ></MessageLog>
        </div>
      </section>
    </>
  );
}

export default ScaleLoadingV1;
