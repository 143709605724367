exports.TankFarm = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    length: 12,
    key: "true",
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    value: "",
    label: "Universal Descriptor",
    length: 50,
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    value: "",
    label: "Location Id",
    length: 12,
  },
  {
    id: "autoTankDermination",
    name: "autoTankDermination",
    type: "boolean",
    value: "",
    label: "Auto Tank Dermination",

  },
  {
    id: "localText",
    name: "localText",
    type: "text",
    value: "",
    label: "Local Text",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
