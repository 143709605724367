import React, { useContext } from "react";
import { InvoiceContext } from "../InvoiceMain/Contex";

export default function TopPanel() {
  const { operation, invoiceId } = useContext(InvoiceContext);

  var invoiceInfo;
  switch (operation) {
    case "Display":
      invoiceInfo = `${operation} Inventory Document: ${invoiceId}`;
      break;
    case "Change":
      invoiceInfo = `${operation} Inventory Document: ${invoiceId}`;
      break;
    case "Create":
      invoiceInfo = `${operation} New Inventory Document:`;
      break;
    default:
      invoiceInfo = `Inventory Doc Info`;
      break;
  }
  return (
    <h2
      className="heading"
      style={{ marginLeft: 44, position: "relative", top: 8 }}
    >
      {invoiceInfo}
    </h2>
  );
}
