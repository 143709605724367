import React, { useState, useEffect } from "react";
import {
  countryFieldsArray,
  USAFieldsArray,
  internationalFieldsArray,
} from "./fields";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import { useContext } from "react";
import { PartnerContext } from "../context";
import Card from "src/CommonComponents/Card";
export default function AddressTab() {
  const { _partnerData, onChange, operation, dropDowns } =
    useContext(PartnerContext);
  const [countryArray, setCountryArray] = useState(false);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _partnerData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };

  const fieldsState = {
    dataSource: _partnerData,
    dropDowns,
  };

  useEffect(() => {
    if (_partnerData.countryId != "USA" && _partnerData.countryId != "US") {
      setCountryArray(true);
    } else {
      setCountryArray(false);
    }
  }, [_partnerData.countryId]);

  let countryField = countryFieldsArray(fieldsState);
  let USAFields = USAFieldsArray(fieldsState);
  let internationalFields = internationalFieldsArray(fieldsState);
  return (
    <div className="row" style={{ gap: "3rem" }}>
      <Card
        cardHeaderProps={{
          headerLabel: "Country",
        }}
        cardBody={countryField?.map((field, index) => (
          <FieldsReducer field={mergedFieldData(field)} key={index} />
        ))}
        style={{ width: "25rem" }}
      />

      {countryArray ? (
        <Card
          cardHeaderProps={{
            headerLabel: "Address",
          }}
          cardBody={internationalFields?.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
          style={{ width: "25rem" }}
        />
      ) : (
        <Card
          cardHeaderProps={{
            headerLabel: "Address",
          }}
          cardBody={USAFields?.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
          style={{ width: "25rem" }}
        />
      )}
    </div>
  );
}
