import React, { useContext, useState } from "react";
import { mainDataArray ,  enhancedDDInputArray ,quantityArray, dateDataArray} from "./Fields";
import { BillOfMaterialContext } from "../Contex";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import MaterialField from "../../../../CommonComponents/EnhancedFields/Material/MaterialField";
import MaterialList from "../../../../CommonComponents/DropDowns/Material/MaterialList";

export default function MainTab() {
  const {
    onChange,
    operation,
    dropDowns,
    _getMaterialList,
    _materialList,
    _billOfMaterialData,
  } = useContext(BillOfMaterialContext);


  const [showMaterialList, setShowMaterialList] = useState(false);
  const [materialFieldName, setMaterialFieldName] = useState(); 
  const [itemOperation, setItemOperation] = useState();
  const [item, setItem] = useState({});
  
  const onChangeItem = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setItem((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };
  

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _billOfMaterialData, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _billOfMaterialData,
    dropDowns,
  };

  const mainDataFieldsToRender = mainDataArray(fieldsState);
  const quantityFieldsToRender = quantityArray(fieldsState);
  const enhancedDDFieldsToRender = enhancedDDInputArray(fieldsState);
  const dateDataFieldsToRender = dateDataArray(fieldsState);

  var materialField    = enhancedDDFieldsToRender.find(({ id }) => id === "materialId");



  return (
   <>
      <div className="row">
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Main Data</h2>
            </div>
            <div className="cardBody">
              {mainDataFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Material Data </h2>
          </div>
          <div className="cardBody">
            <MaterialField
              field={mergedFieldData(materialField)}
              setShowMaterialList={setShowMaterialList}
              _getMaterialList={_getMaterialList}
              setMaterialFieldName={setMaterialFieldName}
            />
            <div className="cardBody">
            {quantityFieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Date Data</h2>
            </div>
            <div className="cardBody">
              {dateDataFieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
      </div>
      <MaterialList
        _materialList={_materialList}
        showMaterialList={showMaterialList}
        setShowMaterialList={setShowMaterialList}
        onChange={onChangeItem}
      />
      </div>
  </>
   
  );
}
