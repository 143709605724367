import React from "react";
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";
// import Overview from "../CommonComponents/Overview";
// import TransactionMain from "../TransactionMaintenance/UIComponents/TransactionMain";

//Overview Cards
import Home from "../Home/Home";
import SettingsTree from "../Trees/Other/SettingsTree";
import MasterDataHome from "../Home/MasterDataHome/MasterData.jsx";
import DashboardHome from "../Home/DashboardHome/DashboardHome";
import BusinessProcess from "../Home/BusinessProcess/index";
import UserMaintenance from "../System/UserMaintenance/UserMaintenance";
import BasicSystem from "../Home/BasicSystem/BasicSystem";
import ReportsHome from "../Home/Reports/ReportsHome";

import MaterialGeneral from "../MasterData/MaterialMaster/Index";
import MaterialOverview from "../Home/MasterDataHome/MaterialOverview/MaterialOverview.jsx";
import MaterialAdminFunctionOverview from "../MasterData/MaterialMaster/MaterialAdminFunctions/AdminFunctionOverview.jsx";
import MaterialMain from "../MasterData/MaterialMaster/MaterialMain/Index";

import TankGeneral from "../MasterData/TankMaster/Index";
import TankOverview from "../Home/MasterDataHome/TankOverview/TankOverview";
import TankMain from "../MasterData/TankMaster/MainData/Index";
import TankAdminFunctionOverview from "../MasterData/TankMaster/TankAdminFunctions/AdminFunctionOverview.jsx";

import OrderMain from "../Business Processes/Order/OrderMain/Index";
import OrderOverview from "../Business Processes/Order/Index";

import StartTerminalOperation from "../Home/TerminalOperations/StartTerminalOperation";

import StartLoadingWithTerminalSetup from "../Business Processes/TerminalOperations/LoadingWithTerminalSetup/StartLoadingWithTerminalSetup";

import Station from "../Business Processes/TerminalOperations/Station/Station";
// import TankFarmList from "../Dashboards/Tank/Index";
//import TankFarmOrders from "../DashBoards/Tank/TankFarmOrders";

import OrderDash from "../Home/BusinessProcess/OrderDash/OrderDashOverview";

import InvDocOverview from "../Business Processes/InventoryDocument/Index";
import InventoryDocMain from "../Business Processes/InventoryDocument/InventoryDocumentMain/Index";

import OrderSync from "../Business Processes/TerminalOperations/OrderSync/OrderSync";
import GetERPOrder from "../Business Processes/TerminalOperations/ERPOrder/GetERPOrder";
import StartLoading from "../Business Processes/TerminalOperations/StartLoadingWithoutOrder/StartLoading";

import PrintScaleTicket from "../Business Processes/TerminalOperations/Print/PrintScaleTicket";

import SpecialProcesses from "../Home/BusinessProcess/SpecialProcesses/SpecialProcesses";

//Reports Creens
import ShipDocReportV1 from "../Reports/ShipDocReport/ShipDocReportV1";
import AppLog from "../Reports/AppLog/AppLog";
import ShipDocPDF from "../Reports/PDFReports/ShipDoc/ShipDocPDF";
import BackgroundJobLog from "../Reports/BackgroundJob/BackgroundJobLog";
import JobSumLog from "../Reports/Job/JobSumLog";
import Email from "../Reports/Email/Email";

//For Testing
import ELDOScaleOperations from "../Business Processes/TerminalOperations/TerminalSpecificLoading/ELDO/ELDOScaleOperations";
//For Go Live
import ScaleStation from "../Business Processes/TerminalOperations/TerminalSpecificLoading/ELDO/Stations/ScaleStation";
import SignatureStation from "../Business Processes/TerminalOperations/TerminalSpecificLoading/ELDO/Stations/SignatureStation";

//Eldo Screens

//Financial Screens
import FinancialOverview from "../Home/MasterDataHome/FinancialOverview/FinancialOverview";
import AccountOverview from "../Home/MasterDataHome/FinancialOverview/AccountOverview/AccountOverview";
import AccountGeneral from "../MasterData/FinancialMaster/AccountMaster/Index";
import AccountMain from "../MasterData/FinancialMaster/AccountMaster/AccountMain/Index";
import AccountAdmin from "../MasterData/FinancialMaster/AccountMaster/AccountAdminFunctions/AdminFunctionOverview";

//Transportation Screens
import TransportationOverview from "../Home/MasterDataHome/TransportationOverview/TransportationOverview";
import VehicleOverview from "../Home/MasterDataHome/TransportationOverview/VehicleOverview/VehicleOverview";
import VehicleGeneral from "../MasterData/TransportationMaster/VehicleMaster/Index";
import VehicleMain from "../MasterData/TransportationMaster/VehicleMaster/VehicleMain/Index";
import VehicleAdmin from "../MasterData/TransportationMaster/VehicleMaster/VehicleAdminFunctions/AdminFunctionOverview";

//BillOfMaterial Screens
import BillOfMaterialOverview from "../Home/MasterDataHome/BillOfMaterialOverview/BillOfMaterialOverview";
import BillOfMaterialGeneral from "../MasterData/BillOfMaterial/Index";
import BillOfMaterialMain from "../MasterData/BillOfMaterial/BillOfMaterialMain/Index";

//Invoice Screens
import InvoiceOverview from "../Business Processes/Invoice/Index";
import InvoiceMain from "../Business Processes/Invoice/InvoiceMain/Index";
// Importing Screens

//FinancialDoc Screens
import FinancialDocOverview from "../Business Processes/FinancialDoc/Index";
import FinancialDocMain from "../Business Processes/FinancialDoc/FinancialDocMain/Index";

//Workbenchs Screens Overview
import OrderWorkbench from "../Business Processes/Order/OrderWorkbench/OrderWorkbench";

//Logistic Station Screens
import LogisticStationOverview from "../Home/MasterDataHome/LogisticStationOverview/LogisticStationOverview";
import LogisticStationGeneral from "../MasterData/LogisticStationMaster/Index";
import LogisticStationMain from "../MasterData/LogisticStationMaster/LogisticStationMain/Index";
import LogisticStationAdmin from "../MasterData/LogisticStationMaster/LogisticStationAdminFunctions/AdminFunctionOverview";

//Leases Screens
import LeaseOverview from "../Home/MasterDataHome/LeaseOverview/LeaseOverview";
import LeaseGeneral from "../MasterData/LeaseMaster/Index";
import LeaseMain from "../MasterData/LeaseMaster/LeaseMain/Index";
import LeaseAdmin from "../MasterData/LeaseMaster/LeaseAdminFunctions/AdminFunctionOverview";

//WX
import WXScaleState from "../Business Processes/TerminalOperations/EquipmentState/WX/WXScaleState";

//BasicSystem Screen
import ClearCache from "../BasicSystem/Cache/ClearCache";
import GlobalAppData from "../BasicSystem/Global/GlobalAppData";

//LabSystem
import LabSystemHome from "../Home/LabSystem/LabSystemHome";
import TestMethodHome from "../Home/LabSystem/TestMethod/TestMethodHome";
import TestMethodOverview from "../LabSystem/TestMethod/Index";
import TestMethodMain from "../LabSystem/TestMethod/TestMethodMain/index";
import TestMethodAdmin from "../LabSystem/TestMethod/TestMethodAdminFunctions/AdminFunctionOverview";

import LabTestHome from "../Home/LabSystem/LabTest/LabTestHome";
import LabTestOverview from "../LabSystem/LabTest/Index";
import LabTestMain from "../LabSystem/LabTest/LabTestMain/index";
import LabTestAdmin from "../LabSystem/LabTest/LabTestAdminFunctions/AdminFunctionOverview";

//Tank Physical
import TankPhysicalOverview from "../Home/MasterDataHome/TankPhysicalOverview/TankPhysicalOverview";
import TankPhysicalGeneral from "../MasterData/TankPhysicalMaster/Index";
import TankPhysicalMain from "../MasterData/TankPhysicalMaster/TankPhysicalMain/Index";
import TankPhysicalAdmin from "../MasterData/TankPhysicalMaster/TankPhysicalAdminFunctions/AdminFunctionOverview";
import TankPhysicalWorkbench from "../MasterData/TankPhysicalMaster/TankPhysicalWorkbench/TankPhysicalWorkbench";

//Printing
import PrintBOLFromTLOAD from "src/Reports/Printing/PrintFromTLOAD/PrintBOLFromTLOAD";
import PrintBOLFromSAP from "src/Reports/Printing/PrintFromSAP/PrintBOLFromSAP";

function PrivateRoutes() {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Home"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/UserMaintenance"
        element={
          <RequireAuth>
            <UserMaintenance />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/OrderDash"
        element={
          <RequireAuth>
            <OrderDash />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/DashboardHome"
        element={
          <RequireAuth>
            <DashboardHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/BusinessProcess"
        element={
          <RequireAuth>
            <BusinessProcess />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/SpecialProcesses"
        element={
          <RequireAuth>
            <SpecialProcesses />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/MasterDataHome"
        element={
          <RequireAuth>
            <MasterDataHome />
          </RequireAuth>
        }
      />
      {/* <Route
        exact
        path="/TankDashboard"
        element={
          <RequireAuth>
            <TankFarmList />
          </RequireAuth>
        }
      /> */}
      <Route
        exact
        path="/ShipDocReportV1"
        element={
          <RequireAuth>
            <ShipDocReportV1 />
          </RequireAuth>
        }
      />
      {/* <Route
        exact
        path="/TankDashboard/:id"
        element={
          <RequireAuth>
            <TankFarmOrders />
          </RequireAuth>
        }
      /> */}
      <Route
        exact
        path="/MaterialOverview"
        element={
          <RequireAuth>
            <MaterialOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/MaterialGeneral"
        element={
          <RequireAuth>
            <MaterialGeneral />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Material/:op"
        element={
          <RequireAuth>
            <MaterialMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Material/:id/:op"
        element={
          <RequireAuth>
            <MaterialMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/MaterialAdminFunctionOverview"
        element={
          <RequireAuth>
            <MaterialAdminFunctionOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/PrintScaleTicket"
        element={
          <RequireAuth>
            <PrintScaleTicket />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/OrderOverview"
        element={
          <RequireAuth>
            <OrderOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Order/:op"
        element={
          <RequireAuth>
            <OrderMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Order/:id/:op"
        element={
          <RequireAuth>
            <OrderMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/InvDocOverview"
        element={
          <RequireAuth>
            <InvDocOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/InventoryDoc/:op"
        element={
          <RequireAuth>
            <InventoryDocMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/InventoryDoc/:id/:op"
        element={
          <RequireAuth>
            <InventoryDocMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TankOverview"
        element={
          <RequireAuth>
            <TankOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TankGeneral"
        element={
          <RequireAuth>
            <TankGeneral />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Tank/:op"
        element={
          <RequireAuth>
            <TankMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Tank/:id/:op"
        element={
          <RequireAuth>
            <TankMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TankAdminFunctionOverview"
        element={
          <RequireAuth>
            <TankAdminFunctionOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/StartTerminalOperation"
        element={
          <RequireAuth>
            <StartTerminalOperation />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/StartLoading"
        element={
          <RequireAuth>
            <StartLoading />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/StartWithTRMSetup"
        element={
          <RequireAuth>
            <StartLoadingWithTerminalSetup />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ELDOScaleOperations"
        element={
          <RequireAuth>
            <ELDOScaleOperations />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ScaleStation"
        element={
          <RequireAuth>
            <ScaleStation />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/SignatureStation"
        element={
          <RequireAuth>
            <SignatureStation />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ERPOperartions/GetERPOrder"
        element={
          <RequireAuth>
            <GetERPOrder />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ERPOperartions/OrderSync"
        element={
          <RequireAuth>
            <OrderSync />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Station"
        element={
          <RequireAuth>
            <Station />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Settings"
        element={
          <RequireAuth>
            <SettingsTree />
          </RequireAuth>
        }
      />
      //Financial Screens
      <Route
        exact
        path="/FinancialOverview"
        element={
          <RequireAuth>
            <FinancialOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/AccountOverview"
        element={
          <RequireAuth>
            <AccountOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/AccountGeneral"
        element={
          <RequireAuth>
            <AccountGeneral />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/AccountAdmin"
        element={
          <RequireAuth>
            <AccountAdmin />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Account/:op"
        element={
          <RequireAuth>
            <AccountMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Account/:id/:op"
        element={
          <RequireAuth>
            <AccountMain />
          </RequireAuth>
        }
      />
      //Transportation
      <Route
        exact
        path="/TransportationOverview"
        element={
          <RequireAuth>
            <TransportationOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/VehicleOverview"
        element={
          <RequireAuth>
            <VehicleOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/VehicleGeneral"
        element={
          <RequireAuth>
            <VehicleGeneral />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/VehicleAdmin"
        element={
          <RequireAuth>
            <VehicleAdmin />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Vehicle/:op"
        element={
          <RequireAuth>
            <VehicleMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Vehicle/:id/:op"
        element={
          <RequireAuth>
            <VehicleMain />
          </RequireAuth>
        }
      />
      //Bill Of Material
      <Route
        exact
        path="/BillOfMaterialOverview"
        element={
          <RequireAuth>
            <BillOfMaterialOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/BillOfMaterialGeneral"
        element={
          <RequireAuth>
            <BillOfMaterialGeneral />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/BillOfMaterial/:op"
        element={
          <RequireAuth>
            <BillOfMaterialMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/BillOfMaterial/:id/:op"
        element={
          <RequireAuth>
            <BillOfMaterialMain />
          </RequireAuth>
        }
      />
      //Invoice Screens
      <Route
        exact
        path="/InvoiceOverview"
        element={
          <RequireAuth>
            <InvoiceOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Invoice/:op"
        element={
          <RequireAuth>
            <InvoiceMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Invoice/:id/:op"
        element={
          <RequireAuth>
            <InvoiceMain />
          </RequireAuth>
        }
      />
      /FinancialDoc Screens
      <Route
        exact
        path="/FinancialDocOverview"
        element={
          <RequireAuth>
            <FinancialDocOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/FinancialDoc/:op"
        element={
          <RequireAuth>
            <FinancialDocMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/FinancialDoc/:id/:op"
        element={
          <RequireAuth>
            <FinancialDocMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/OrderWorkbench"
        element={
          <RequireAuth>
            <OrderWorkbench />
          </RequireAuth>
        }
      />
      //LogisticStation
      <Route
        exact
        path="/LogisticStationOverview"
        element={
          <RequireAuth>
            <LogisticStationOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LogisticStationGeneral"
        element={
          <RequireAuth>
            <LogisticStationGeneral />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LogisticStationAdmin"
        element={
          <RequireAuth>
            <LogisticStationAdmin />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LogisticStation/:op"
        element={
          <RequireAuth>
            <LogisticStationMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LogisticStation/:id/:op"
        element={
          <RequireAuth>
            <LogisticStationMain />
          </RequireAuth>
        }
      />
      //Lease
      <Route
        exact
        path="/LeaseOverview"
        element={
          <RequireAuth>
            <LeaseOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LeaseGeneral"
        element={
          <RequireAuth>
            <LeaseGeneral />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LeaseAdmin"
        element={
          <RequireAuth>
            <LeaseAdmin />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Lease/:op"
        element={
          <RequireAuth>
            <LeaseMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Lease/:id/:op"
        element={
          <RequireAuth>
            <LeaseMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ReportsHome"
        element={
          <RequireAuth>
            <ReportsHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/BasicSystem"
        element={
          <RequireAuth>
            <BasicSystem />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/AppLog"
        element={
          <RequireAuth>
            <AppLog />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/BackgroundJobLog"
        element={
          <RequireAuth>
            <BackgroundJobLog />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/JobSumLog"
        element={
          <RequireAuth>
            <JobSumLog />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/Email"
        element={
          <RequireAuth>
            <Email />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ShipDocPDF"
        element={
          <RequireAuth>
            <ShipDocPDF />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TerminalActivity/WXScaleState"
        element={
          <RequireAuth>
            <WXScaleState />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/GlobalAppData"
        element={
          <RequireAuth>
            <GlobalAppData />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/ClearCache"
        element={
          <RequireAuth>
            <ClearCache />
          </RequireAuth>
        }
      />
      //LabSystem
      <Route
        exact
        path="/LabSystemHome"
        element={
          <RequireAuth>
            <LabSystemHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TestMethodHome"
        element={
          <RequireAuth>
            <TestMethodHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TestMethodOverview"
        element={
          <RequireAuth>
            <TestMethodOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TestMethodAdmin"
        element={
          <RequireAuth>
            <TestMethodAdmin />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TestMethod/:op"
        element={
          <RequireAuth>
            <TestMethodMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TestMethod/:id/:op"
        element={
          <RequireAuth>
            <TestMethodMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LabTestHome"
        element={
          <RequireAuth>
            <LabTestHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LabTestOverview"
        element={
          <RequireAuth>
            <LabTestOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LabTestAdmin"
        element={
          <RequireAuth>
            <LabTestAdmin />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LabTest/:op"
        element={
          <RequireAuth>
            <LabTestMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/LabTest/:id/:op"
        element={
          <RequireAuth>
            <LabTestMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TankPhysicalOverview"
        element={
          <RequireAuth>
            <TankPhysicalOverview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TankPhysicalGeneral"
        element={
          <RequireAuth>
            <TankPhysicalGeneral />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TankPhysicalAdmin"
        element={
          <RequireAuth>
            <TankPhysicalAdmin />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TankPhysical/:op"
        element={
          <RequireAuth>
            <TankPhysicalMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TankPhysical/:id/:op"
        element={
          <RequireAuth>
            <TankPhysicalMain />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/TankPhysicalWorkbench"
        element={
          <RequireAuth>
            <TankPhysicalWorkbench />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/PrintBOLFromTLOAD"
        element={
          <RequireAuth>
            <PrintBOLFromTLOAD />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/PrintBOLFromSAP"
        element={
          <RequireAuth>
            <PrintBOLFromSAP />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default PrivateRoutes;
