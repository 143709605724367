exports.StageProcedureStep = [
    {
      id: "id",
      name: "id",
      type: "text",
      value: "",
      label: "Id",
      length: 24,
      key: "true",
    },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      value: "",
      label: "Universal Descriptor",
      length: 50,
    },
    {
        id: "stageProcedureId",
        name: "stageProcedureId",
        type: "text",
        value: "",
        label: "Stage Procedure",
        length: 24,
      },
      {
        id: "sequnce",
        name: "sequnce",
        type: "number",
        value: "",
        label: "Sequnce",
      },
      {
        id: "operationFieldId",
        name: "operationFieldId",
        type: "text",
        value: "",
        label: "Operation Field",
        length: 50,
        ddid:"fieldOperationId",
      },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "datetime",
      label: "ModifiedOn",
      displayonly: "true",
      value: " ",
    },
  ];
  