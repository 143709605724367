import React from "react";
import { RadialGauge } from "react-canvas-gauges";
function InfoSideBar({ modbusMeasurements = {} }) {
  return (
    <div>
      <RadialGauge
        units="°F"
        title="Temperature"
        value={modbusMeasurements.temperature}
        minValue={0}
        maxValue={400}
        majorTicks={["0", "100", "200", "300", "400"]}
        minorTicks={4}
      ></RadialGauge>

      <label htmlFor="Density" style={{ fontWeight: 600 }}>
        Density
      </label>

      <input
        placeholder={"Density"}
        type="text"
        disabled={true}
        value={modbusMeasurements.density}
        className="form-control"
        style={{
          borderRadius: 10,
          outline: "none !important",
          height: 50,
          fontSize: 16,
        }}
      />

      <label htmlFor="BSW" style={{ fontWeight: 600 }}>
        BSW
      </label>

      <input
        placeholder={"BSW"}
        type="text"
        disabled={true}
        value={modbusMeasurements.bsw}
        className="form-control"
        style={{
          borderRadius: 10,
          outline: "none !important",
          height: 50,
          fontSize: 16,
        }}
      />
    </div>
  );
}

export default InfoSideBar;
