import React, { useState, useEffect } from "react";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import HomeTab from "./Main/Index";
import AdminTab from "./Admin/Index";
import VehicleHeader from "./Header/index";
import DimensionsTab from "./DimensionsTab/Index";
import { VehicleContext } from "./context";
import { useFetch } from "./../../../../hooks/useFetch";
import { GetFullURL } from "./../../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen";
import TabPannel from "../../../../CommonComponents/TabPannel/index";
import { useParams } from "react-router-dom";

export default function VehicleMain(props) {
  const [value, setValue] = useState(0);
  const [operation, setOperation] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [frontendError, setFrontendError] = useState([]);
  const params = useParams();

  const [
    _getVehicleData,
    _vehicleData,
    _setVehicleData,
    _errorVehicleData,
    _setErrorVehicleData,
    _successVehicleData,
    _setSuccessVehicleData,
    _loadingVehicleData,
  ] = useFetch(GetFullURL("GetVehicle"), { method: "POST" });
  const [
    _getVehicleDD,
    _vehicleDD,
    ,
    _errorVehicleDD,
    _setErrorVehicleDD,
    _successVehicleDD,
    _setSuccessVehicleDD,
    _loadingtVehicleDD,
  ] = useFetch(GetFullURL("VehicleDropDowns"), { method: "POST" });
  const [
    _saveVehicle,
    ,
    ,
    _errorSaveVehicle,
    _setErrorSaveVehicle,
    _successSaveVehicle,
    _setSuccessSaveVehicle,
    _loadingSaveVehicle,
  ] = useFetch(GetFullURL("SaveVehicle"), { method: "POST" });

  useEffect(() => {
    //when componen mounts,set ID and operation
    if (params.op) {
      setOperation(params.op);
    }
    if (params.id) {
      setVehicleId(params.id);
    }
  }, []);

  useEffect(() => {
    _getVehicleDD();
    if (vehicleId) {
      _getVehicleData({ data: { id: vehicleId } });
    }
  }, [vehicleId]);

  const handleChange = (event, newValue) => setValue(newValue);

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;
    let newValue;

    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = "true") : (newValue = "false");
        break;

      default:
        newValue = value;
        break;
    }

    if (newValue === "select" || newValue === "SELECT") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    _setVehicleData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const contextState = {
    onChange,
    operation,
    dropDowns: _vehicleDD || [],
    vehicleId,
    _vehicleData,
    _setVehicleData,
    frontendError,
    setOperation,
    setFrontendError,
  };

  const operationLoading =
    _loadingVehicleData ||
    _loadingtVehicleDD ||
    _loadingSaveVehicle;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveVehicle([]);
        _setSuccessVehicleData([]);
        _setSuccessVehicleDD([]);
        break;
      case "error":
        _setErrorVehicleData([]);
        _setErrorVehicleDD([]);
        _setErrorSaveVehicle([]);
        setFrontendError([]);
        break;
      default:
        break;
    }
  }

  return (
    <VehicleContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <VehicleHeader
        _loadingSaveVehicleData={_loadingSaveVehicle}
        _saveVehicle={_saveVehicle}
      />
      <TabPannel
        value={value}
        handleChange={handleChange}
        labels={[
          "Home",
          "Dimensions",
          "Admin",
          "Log",
        ]}
        components={[
          HomeTab,
          DimensionsTab,
          AdminTab,
          
        ]}
      />
      <MessageLog
        errorMessages={[
          ..._errorVehicleDD,
          ..._errorVehicleData,
          ..._errorSaveVehicle,
          ...frontendError,
        ]}
        successMessages={[
          ..._successVehicleDD,
          ..._successVehicleData,
          ..._successSaveVehicle,
        ]}
        clearMessages={clearMessages}
      ></MessageLog>
    </VehicleContext.Provider>
  );
}
