import React, { useContext } from "react";
import { OrderContext } from "../OrderMain/Contex";

export default function TopPanel() {
  const { operation, orderId } = useContext(OrderContext);

  var orderInfo;
  switch (operation) {
    case "Display":
      orderInfo = `${operation} Order: ${orderId}`;
      break;
    case "Change":
      orderInfo = `${operation} Order: ${orderId}`;
      break;
    case "Create":
      orderInfo = `${operation} New Order:`;
      break;
    default:
      orderInfo = `Order Info`;
      break;
  }
  return (
    <h2
      className="heading"
      style={{ position: "relative", top: 8 }}
    >
      {orderInfo}
    </h2>
  );
}
