import React from "react";
import { useNavigate } from "react-router-dom";

export default function CardV1({
  title,
  text,
  Icon,
  link,
  stationId,
  loggedStationId,
  _createErrorMessage,
}) {
  const navigate = useNavigate();
  return (
    <div
      className="overviewCustomCard"
      onClick={() => {
        if (!stationId) {
          navigate(link);
        } else {
          if (stationId != loggedStationId) {
            _createErrorMessage(93, loggedStationId, stationId);
          } else {
            navigate(link);
          }
        }

        //
      }}
    >
      <div className="mt-5">
        <h5>{title}</h5>
        <p>{text}</p>
      </div>
      <Icon className="cardSvg" />
    </div>
  );
}
