export const itemGridFields = ({ dropDowns, headerData, itemData }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    label: "item Num",
    disabled: true,
  },
  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    tag: "select",
    options: dropDowns?.materials || [],
  },

  {
    id: "quantity",
    name: "quantity",
    label: "Quantity",
  },
  {
    id: "unitId",
    name: "unitId",
    label: "Unit",
    tag: "select",
    type: "text",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "quantity1",
    name: "quantity1",
    label: "Add Quantity ",
  },
  {
    id: "unit1Id",
    name: "unit1Id",
    label: "Unit",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "tankId",
    name: "tankId",
    label: "Tank",
    tag: "select",
    options: tankDropdown(dropDowns, headerData, itemData),
  },
];

const tankDropdown = (dropDowns, headerData, itemData) => {
  //filtering tanks based on item location if available, then header location
  if (itemData?.locationId) {
    let tanks = dropDowns?.tanks?.filter((tank) => {
      return tank?.filter1 === itemData.locationId;
    });
    return tanks;
  } else {
    if (headerData?.locationId) {
      let tanks = dropDowns?.tanks?.filter((tank) => {
        return tank?.filter1 === headerData.locationId;
      });
      return tanks;
    } else return [];
  }
};
