import React from "react";
import Button from '@mui/material/Button';

const btnsArray = [{ operation: "Send ALL" }, { operation: "Cancel" }];

export default function OverviewButtons({ navigate, _sendShipDocsERP }) {
  const onClick = (operation) => {
    if (operation === "Cancel") {
      navigate("/SpecialProcesses");
      return;
    }
    debugger;
    _sendShipDocsERP({}, () => {});
    //_sendShipDocERP({ data: { Id: shippingDoc } }, () => {});
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ operation }, i) => (
        <div style={{ width: "48%", marginBottom: 15 }} key={i}>
          <Button
            className="btn btn-primary w-100"
            key={operation}
            onClick={() => onClick(operation)}
          >
            {operation}
          </Button>
        </div>
      ))}
    </div>
  );
}
