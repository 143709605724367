import React, { useContext } from "react";
import {
  weightArray1,
  weightArray2,
  scaleAndMeterArray,
  tempArray,
} from "./Fields";
import { ShipDocContext } from "../Contex";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import Card from "src/CommonComponents/Card";
export default function WeightMesuarmentTab(props) {
  const { onChange, operation, dropDowns, _shipDocData, _setShipDocData } =
    useContext(ShipDocContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _shipDocData, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _shipDocData,
    dropDowns,
  };

  const weightArray1ToRender = weightArray1(fieldsState);

  const weightArray2ToRender = weightArray2(fieldsState);

  const scaleAndMeterArrayToRender = scaleAndMeterArray(fieldsState);

  const tempArrayToRender = tempArray(fieldsState);

  return (
    <div className="row">
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <Card
          cardHeaderProps={{
            headerLabel: "Weights",
          }}
          cardBody={weightArray1ToRender.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
        />
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <Card
          cardHeaderProps={{
            headerLabel: "Limits",
          }}
          cardBody={weightArray2ToRender.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
        />
      </div>

      <div className="col-lg-3 col-sm-6 col-xs-12">
        <Card
          cardHeaderProps={{
            headerLabel: "Temperature",
          }}
          cardBody={tempArrayToRender.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
        />
      </div>

      <div className="col-lg-3 col-sm-6 col-xs-12">
        <Card
          cardHeaderProps={{
            headerLabel: "Current Readings",
          }}
          cardBody={scaleAndMeterArrayToRender.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
        />
      </div>
    </div>
  );
}
