import React, { useState, useEffect } from "react";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import HomeTab from "./Main/Index";
import AdminTab from "./Admin/Index";
import AddressTab from "./Address/index";
import LeaseHeader from "./Header/index";
import { LeaseContext } from "./context";
import { useFetch } from "../../../hooks/useFetch";
import { GetFullURL } from "../../..//CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen";
import TabPannel from "../../../CommonComponents/TabPannel/index";
import { useParams } from "react-router-dom";

export default function LeaseMain(props) {
  const [value, setValue] = useState(0);
  const [operation, setOperation] = useState("");
  const [leaseId, setLeaseId] = useState("");
  const [frontendError, setFrontendError] = useState([]);
  const params = useParams();

  const [
    _getLeaseData,
    _leaseData,
    _setLeaseData,
    _errorLeaseData,
    _setErrorLeaseData,
    _successLeaseData,
    _setSuccessLeaseData,
    _loadingLeaseData,
  ] = useFetch(GetFullURL("GetLease"), { method: "POST" });
  const [
    _getLeaseDD,
    _leaseDD,
    ,
    _errorLeaseDD,
    _setErrorLeaseDD,
    _successLeaseDD,
    _setSuccessLeaseDD,
    _loadingtLeaseDD,
  ] = useFetch(GetFullURL("LeaseDropDowns"), { method: "POST" });
  const [
    _saveLease,
    ,
    ,
    _errorSaveLease,
    _setErrorSaveLease,
    _successSaveLease,
    _setSuccessSaveLease,
    _loadingSaveLease,
  ] = useFetch(GetFullURL("SaveLease"), { method: "POST" });

  const [
    _getMaterialList,
    _materialList,
    _setMaterialList,
    _errorMaterialList,
    _SetErrorMaterialList,
    _successMaterialList,
    _setSuccessMaterialList,
    _loadingMaterialList,
  ] = useFetch(GetFullURL("MaterialList"), { method: "POST" });

  const [
    _getPartnerList,
    _partnerList,
    _setPartnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    _successPartnerList,
    _setSuccessPartnerList,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("PartnerList"), { method: "POST" });

  const [
    _getLogisticStationList,
    _logisticStationList,
    _setLogisticStationList,
    _errorLogisticStationList,
    _setErrorLogisticStationList,
    _successLogisticStationList,
    _setSuccessLogisticStationList,
    _loadingLogisticStationList,
  ] = useFetch(GetFullURL("LogisticStationList"), { method: "POST" });

  useEffect(() => {
    //when componen mounts,set ID and operation
    if (params.op) {
      setOperation(params.op);
    }
    if (params.id) {
      setLeaseId(params.id);
    }
  }, []);

  useEffect(() => {
    _getLeaseDD();
    if (leaseId) {
      _getLeaseData({ data: { id: leaseId } });
    }
  }, [leaseId]);

  const handleChange = (event, newValue) => setValue(newValue);

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;
    let newValue;

    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = "true") : (newValue = "false");
        break;

      default:
        newValue = value;
        break;
    }

    if (newValue === "select" || newValue === "SELECT") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    _setLeaseData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const contextState = {
    onChange,
    operation,
    dropDowns: _leaseDD || [],
    leaseId,
    _leaseData,
    _setLeaseData,
    frontendError,
    _getMaterialList,
    _getPartnerList,
    _partnerList,
    _materialList,
    _getLogisticStationList,
    _logisticStationList,
    setOperation,
    setFrontendError,
  };

  const operationLoading =
    _loadingLeaseData               ||
    _loadingtLeaseDD                ||
    _loadingMaterialList            ||
    _loadingPartnerList             ||
    _loadingLogisticStationList     ||
    _loadingSaveLease;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveLease([]);
        _setSuccessLeaseData([]);
        _setSuccessMaterialList([]);
        _setSuccessLogisticStationList([]);
        _setSuccessLeaseDD([]);
        _setSuccessPartnerList([]);
        break;
      case "error":
        _setErrorLeaseData([]);
        _setErrorLeaseDD([]);
        _SetErrorMaterialList([]);
        _setErrorSaveLease([]);
        _setErrorPartnerList([]);
        _setErrorLogisticStationList([]);
        setFrontendError([]);
        break;
      default:
        break;
    }
  }

  return (
    <LeaseContext.Provider value={contextState}>
      {operationLoading && <LoadingScreen />}
      <LeaseHeader
        _loadingSaveLeaseData={_loadingSaveLease}
        _saveLease={_saveLease}
      />
      <TabPannel
        value={value}
        handleChange={handleChange}
        labels={[
          "Home",
          "Address",
          "Admin",
          "Log",
        ]}
        components={[
          HomeTab,
          AddressTab,
          AdminTab,
        ]}
      />
      <MessageLog
        errorMessages={[
          ..._errorLeaseDD,
          ..._errorLeaseData,
          ..._errorMaterialList,
          ..._errorSaveLease,
          ..._errorPartnerList,
          ..._errorLogisticStationList,
          ...frontendError,
        ]}
        successMessages={[
          ..._successLeaseDD,
          ..._successLeaseData,
          ..._successMaterialList,
          ..._successLogisticStationList,
          ..._successPartnerList,
          ..._successSaveLease,
        ]}
        clearMessages={clearMessages}
      ></MessageLog>
    </LeaseContext.Provider>
  );
}
