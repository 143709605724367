import React, { useContext } from "react";
import { stationArray, printerArray, loaderArray } from "./Fields";
import { ShipDocContext } from "../Contex";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import Card from "src/CommonComponents/Card";
export default function TerminalTab(props) {
  const { onChange, operation, dropDowns, _shipDocData, _setShipDocData } =
    useContext(ShipDocContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _shipDocData, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _shipDocData,
    dropDowns,
  };

  const stationArrayToRender = stationArray(fieldsState);

  const printerArrayToRender = printerArray(fieldsState);

  const loaderArrayToRender = loaderArray(fieldsState);

  return (
    <div className="row">
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <Card
          cardHeaderProps={{
            headerLabel: "Terminal Activity",
          }}
          cardBody={stationArrayToRender.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
        />
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <Card
          cardHeaderProps={{
            headerLabel: "Print Info",
          }}
          cardBody={printerArrayToRender.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
        />
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <Card
          cardHeaderProps={{
            headerLabel: "Loading",
          }}
          cardBody={loaderArrayToRender.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
        />
      </div>
    </div>
  );
}
