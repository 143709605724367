/** @format */

import React, { useContext, useState } from 'react';
import { mainDataArray, partnersArray, FreightArray } from './Fields';
import { ShipDocContext } from '../Contex';
import { FieldsReducer } from './../../../../CommonComponents/Reducer/Index';
import PartnerField from '../../../../CommonComponents/EnhancedFields/Partner/PartnerField';
import PartnerList from '../../../../CommonComponents/DropDowns/Partner/PartnerList';
import Card from '../../../../CommonComponents/Card';
export default function MainTab(props) {
  const {
    onChange,
    operation,
    dropDowns,
    _shipDocData,
    _partnerList,
    _getPartnerList,
    displayMainTab,
  } = useContext(ShipDocContext);

  const [showList, setShowList] = useState(false);
  const [partnerFieldName, setPartnerFieldName] = useState();

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _shipDocData, onChange };
    switch (operation) {
      case 'Display':
        newFieldObject.disabled = true;
        break;
      case 'Change':
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case 'Create':
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dropDowns,
  };

  const mainToRender = mainDataArray(fieldsState);
  const partnerFieldsRender = partnersArray(fieldsState);
  const freightFieldsRender = FreightArray(fieldsState);

  var soldtofield = partnerFieldsRender.find(({ id }) => id === 'soldtoId');
  var driverfield = partnerFieldsRender.find(({ id }) => id === 'driverId');
  var shiptofield = partnerFieldsRender.find(({ id }) => id === 'shiptoId');
  var carrierfield = partnerFieldsRender.find(({ id }) => id === 'carrierId');
  return (
    <>
      {displayMainTab ? (
        ' '
      ) : (
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-xs-12">
            <Card
              cardHeaderProps={{
                headerLabel: 'Main Date',
              }}
              cardBody={mainToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            />
          </div>
          <div className="col-lg-3 col-sm-6 col-xs-12">
            <Card
              cardHeaderProps={{
                headerLabel: 'Partners',
              }}
              cardBody={
                <React.Fragment>
                  <PartnerField
                    field={mergedFieldData(soldtofield)}
                    setShowList={setShowList}
                    _getPartnerList={_getPartnerList}
                    setPartnerFieldName={setPartnerFieldName}
                    dropDownApiPartnerType="SOLDTO"
                  />
                  <PartnerField
                    field={mergedFieldData(shiptofield)}
                    setShowList={setShowList}
                    _getPartnerList={_getPartnerList}
                    setPartnerFieldName={setPartnerFieldName}
                    dropDownApiPartnerType="SHIPTO"
                  />
                  <PartnerField
                    field={mergedFieldData(driverfield)}
                    setShowList={setShowList}
                    _getPartnerList={_getPartnerList}
                    setPartnerFieldName={setPartnerFieldName}
                    dropDownApiPartnerType="DRIVER"
                  />
                  <PartnerField
                    field={mergedFieldData(carrierfield)}
                    setShowList={setShowList}
                    _getPartnerList={_getPartnerList}
                    setPartnerFieldName={setPartnerFieldName}
                    dropDownApiPartnerType="CARRIER"
                  />
                </React.Fragment>
              }
            />
          </div>
          <div className="col-lg-3 col-sm-6 col-xs-12">
            <Card
              cardHeaderProps={{
                headerLabel: 'Freight Data',
              }}
              cardBody={freightFieldsRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            />
          </div>
        </div>
      )}
      <PartnerList
        _partnerList={_partnerList}
        showPartnerList={showList}
        setShowPartnerList={setShowList}
        partnerFieldName={partnerFieldName}
        onChange={onChange}
      />
    </>
  );
}
