const Error = (arg) => {
  return {
    type: "LOGIN_ERROR",
    payload: arg,
  };
};

const Success = (arg) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: arg,
  };
};
const Log = (arg) => {
  return {
    type: "LOGIN_LOG",
    payload: arg,
  };
};

const ClearLog = (arg) => {
  return {
    type: "CLEAR_LOGIN_LOG",
    payload: arg,
  };
};

const ClearError = (arg) => {
  return {
    type: "CLEAR_LOGIN_ERROR",
    payload: arg,
  };
};

const ClearSuccess = (arg) => {
  return {
    type: "CLEAR_LOGIN_SUCESS",
    payload: arg,
  };
};

export { Error, Success, Log, ClearLog, ClearError, ClearSuccess };
