import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import "./ColumnChart.scss";

export default class ColumnChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: props.name,
          data: props.values,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },

        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
            style: {
              colors: [],
              fontSize: "14.5px",
              fontWeight: 500,
            },
          },
          categories: props.labels,
        
          tickPlacement: "between",
          title: {
            text: props.xaxisName,
            
          },
        },
        yaxis: {
          title: {
            text: props.yaxisName,
            style: {
              fontSize: "18.5px",
              fontWeight: 600,
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
    };
  }
  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
        />
      </div>
    );
  }
}
