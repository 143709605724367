import React, { useContext, useState } from "react";
import UniversalEditableGrid from "../../../../CommonComponents/Grid/EditableGrid/UniversalEditableGrid";
import { ShipDocContext } from "../Contex";
import { outboundFields } from "./OutboundFields";
import Button from "react-bootstrap/Button";
import * as _ from "underscore";
import OutboundDetail from "./OutboundDetails/OutboundDetail";

function Outbound(props) {
  const { _shipDocData, operation, _setShipDocData, dropDowns } =
    useContext(ShipDocContext);
  const [showOutboundDetailsModal, setShowOutboundDetailsModal] =
    useState(false);
  const [outboundOperation, setOutboundOperation] = useState();
  const [outbound, setOutbound] = useState({});

  const addNewOutboundClick = () => {
    //Geting the max item number
    let maxOutboundNumber = 0;
    let outboundNumbers = [];
    if (_shipDocData.outboundsComm != null) {
      _shipDocData.outboundsComm.forEach((arrayItem) => {
        outboundNumbers.push(arrayItem.outboundNumber);
      });
      maxOutboundNumber = _.max(outboundNumbers);
    }

    if (maxOutboundNumber === -Infinity || maxOutboundNumber === 0) {
      //if 1st item
      maxOutboundNumber = 10;
    } else {
      //10 plus last item number
      maxOutboundNumber = maxOutboundNumber + 10;
    }

    let newOutboundObject;
    //Set some default for new outbound, staus new and class to shipDoc
    newOutboundObject = {
      outboundNumber: maxOutboundNumber,
      documentClassId: "SHIPDOC",
      outboundStatusId: "NEW",
    };

    setOutboundOperation("Create");
    setShowOutboundDetailsModal(true);
    setOutbound({ ...newOutboundObject });
  };

  function handleButtonClickGrid(e) {
    let selectedOutboundNumber = e.target.dataset.id;
    let gridButtonOperation = e.target.dataset.op;
    let result;
    let currentOutbounds = _shipDocData.outboundsComm;

    setOutboundOperation(gridButtonOperation);
    switch (gridButtonOperation) {
      case "Display":
      case "Change":
        result = _shipDocData.outboundsComm.find(
          ({ outboundNumber }) => +outboundNumber === +selectedOutboundNumber
        );
        setOutbound(result);
        setShowOutboundDetailsModal(true);
        break;
      case "Delete":
        currentOutbounds = currentOutbounds.filter(
          (x) => +x.outboundNumber !== +selectedOutboundNumber
        );
        _setShipDocData((current) => {
          return { ...current, outboundsComm: currentOutbounds };
        });
        break;

      default:
    }
  }

  const handleAccept = () => {
    if (outboundOperation === "Create") {
      _setShipDocData((current) => {
        let outbounds = current.outboundsComm || [];

        outbounds.push(outbound);
        return { ...current, outboundsComm: outbounds };
      });
    } else {
      _setShipDocData((current) => {
        let outbounds = current.outboundsComm;

        var index = outbounds.findIndex(
          (x) => x.outboundNumber === outbound.outboundNumber
        );
        if (index >= 0) {
          outbounds[index] = outbound;
        }
        return { ...current, outboundsComm: outbounds };
      });
    }
  };

  const onChangeOutbound = (e) => {
    let outbound;
    let fieldTypeOutbound;
    const {
      target: { value, name, item },
    } = e;

    //the param e is not filled when using rainbow date component
    outbound = e?.target?.dataset?.item || item;

    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeOutbound = e?.target?.dataset?.type;

    let newValue;
    switch (fieldTypeOutbound) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    let newOutbounds = _shipDocData.outboundsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.outboundNumber == outbound) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setShipDocData((prevState) => {
      return { ...prevState, outboundsComm: newOutbounds };
    });
  };

  if (showOutboundDetailsModal) {
    return (
      <div className="mt-4">
        <OutboundDetail
          setShowOutboundDetailsModal={setShowOutboundDetailsModal}
          outboundOperation={outboundOperation}
          outbound={outbound}
          setOutbound={setOutbound}
          handleAccept={handleAccept}
        />
      </div>
    );
  }
  return (
    <div>
      <Button
        variant="primary"
        active="true"
        data-op="Create"
        disabled={operation === "Display"}
        onClick={addNewOutboundClick}
        style={{ marginTop: "5px" }}
      >
        Add New Outbound
      </Button>

      <UniversalEditableGrid
        fields={outboundFields}
        headerData={_shipDocData}
        itemData={_shipDocData?.outboundsComm ?? []}
        dropDowns={dropDowns}
        operation={operation}
        onChange={onChangeOutbound}
        handleButtonClickGrid={handleButtonClickGrid}
        ActionColumn={true}
        itemKey="outboundNumber"
      />
    </div>
  );
}

export default Outbound;
