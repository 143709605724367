import React from "react";
import { Routes, Route } from "react-router-dom";

// Importing Screens
import Login from "../Admin/Login/Login.jsx";

function PublicRoutes() {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
    </Routes>
  );
}

export default PublicRoutes;
