import React, { useState, useEffect } from "react";
import { ReactComponent as ReactAdminFunctionCard } from "../../../Assets/NewIconsForCards/UserSettingsIcon.svg";
import { ReactComponent as ReactTankCard } from "../../../Assets/NewIconsForCards/OilTank.svg";
import Card from "../../Components/Card";
import { useUser } from "../../../hooks/useUser";
const CardsArray = [
  {
    title: "Tank General",
    text: "",
    Icon: ReactTankCard,
    link: "/TankGeneral",
    roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
  },
  {
    title: "Admin Function",
    text: "",
    Icon: ReactAdminFunctionCard,
    link: "/TankAdminFunctionOverview",
    roles: ["Admin", "SuperAdmin"],
  },
];

export default function TankOverview(props) {
  const [_user, ,] = useUser();
  const [newCards, setNewCards] = useState([]);
  useEffect(() => {
    if (!_user.roles) {
      return CardsArray;
    }
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card?.roles?.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);

  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {newCards.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
