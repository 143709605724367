export const mainDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    type: "number",
    label: "Item",
    displayOnly: true,
  },
  {
    id: "higherLevelItem",
    name: "higherLevelItem",
    type: "number",
    label: "Higher Level Item",
  },
  {
    id: "itemCategoryId ",
    name: "itemCategoryId ",
    type: "text",
    label: "Item Cat",
  },
];
export const enhancedDDInputArray = ({ dropDowns, dataSource }) => [
  {
    id: "materialId",
    name: "materialId",
    label: "Material",
    type: "text",
    tag: "enhancedInput",
  },
  {
    id: "tankId",
    name: "tankId",
    label: "Tank",
    type: "text",
    tag: "enhancedInput",
  },
];
export const enhancedInputArray = ({ dropDowns, dataSource }) => [

  {
    id: "locationId",
    name: "locationId",
    label: "Origin Location",
    tag: "select",
    options: dropDowns?.locations || [],
  },
];
export const quantityArray = ({ dropDowns, dataSource }) => [
  {
    id: "quantity",
    name: "quantity",
    label: "Quantity",
    type: "number",
  },
  {
    id: "unitId",
    name: "unitId",
    label: "Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
  {
    id: "quantity1",
    name: "quantity1",
    label: "Additional Quantity",
    type: "number",
  },
  {
    id: "unit1Id",
    name: "unit1Id",
    label: "Unit",
    type: "text",
    tag: "select",
    options: dropDowns?.qtyUnits || [],
  },
];

export const destDDArray = ({ dropDowns, dataSource }) => [
  {
    id: "destinationMaterialId",
    name: "destinationMaterialId",
    label: "Destination Material",
    type: "text",
    tag: "enhancedInput",
  },
  {
    id: "destinationTankId",
    name: "destinationTankId",
    label: "Destination Tank",
    type: "text",
    tag: "enhancedInput",
  },
];
export const destArray = ({ dropDowns, dataSource }) => [
  {
    id: "destLocationId",
    name: "destLocationId",
    label: "Destination Location",
    type: "text",
    tag: "select",
    options: dropDowns?.locations || [],
  },
];