/** @format */

import React, { useContext } from "react";
import { adminArray1 } from "./Fields";
import { ShipDocContext } from "../Contex";
import { FieldsReducer } from "./../../../../CommonComponents/Reducer/Index";
import Card from "src/CommonComponents/Card";
export default function MainTab(props) {
  const { onChange, operation, dropDowns, _shipDocData } =
    useContext(ShipDocContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _shipDocData, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dropDowns,
  };

  const adminArray1Render = adminArray1(fieldsState);

  return (
    <div className="row">
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <Card
          cardHeaderProps={{
            headerLabel: "Admin",
          }}
          cardBody={adminArray1Render.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
        />
      </div>
    </div>
  );
}
