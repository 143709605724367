
import React, { useState, useEffect } from "react";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../../hooks/useFetch";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { mainArray,partnerArray } from "./Fields";
import { useNavigate } from "react-router-dom";
import { FieldsReducer } from "../../../CommonComponents/Reducer/Index";
import { DataGrid } from "@mui/x-data-grid";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useMessage } from "../../../hooks/UseMessage";
import PartnerField from "../../../CommonComponents/EnhancedFields/Partner/PartnerField";
import PartnerList from "../../../CommonComponents/DropDowns/Partner/PartnerList";

function OrderWorkbench(props) {
  let navigate = useNavigate();
  const [orderSearchParam, setOrderSearchParam] = useState();
  const [orderSearch, setOrderSearch] = useState([]);
  const [activeTab,setActiveTab] = useState();
  const [searchView, setSearchView] = useState(false);
  const [searchFiltersView, setSearchFiltersView] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [selectionModel, setSelectionModel] = useState([]);
  const [orderButtonChange, setOrderButtonChange] = useState(false);
  const [orderButtonDisplay, setOrderButtonDisplay] = useState(false);
  const [showList, setShowList] = useState(false);
  const [partnerFieldName, setPartnerFieldName] = useState();
  

  const [
    _orderSearch,
    _orderSearchData,
    ,
    _errorOrderSearch,
    _setErrorOrderSearch,
    _successOrderSearch,
    _setSuccessOrderSearch,
    _loadingOrderSearch,
  ] = useFetch(GetFullURL("OrderSearching"), { method: "POST" });

  const [
    _getGeneralDD,
    _GeneralDDData,
    ,
    _errorGeneralDD,
    _setErrorGeneralDD,
    _successGeneralDD,
    _setSuccessGeneralDD,
    _loadingGeneralDD,
  ] = useFetch(GetFullURL("GetGeneralDropDown"), { method: "POST" });

  const [
    _getPartnerList,
    _partnerList,
    _setPartnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    _successPartnerList,
    _setSuccessPartnerList,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("PartnerList"), { method: "POST" });

  const operationLoading = _loadingOrderSearch || _loadingGeneralDD || _loadingPartnerList;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessOrderSearch([]);
        _setSuccessGeneralDD([]);
        _setSuccessPartnerList([]);
        break;
      case "error":
        _setErrorOrderSearch([]);
        _setErrorGeneralDD([]);
        _setErorMessages([]);
        _setErrorPartnerList([]);
        break;
      default:
        break;
    }
  }

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;

    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        //if checkbox then pass the value as true or false assuming that field is defined as bool in backend
        checked ? (newValue = true) : (newValue = false);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    setOrderSearchParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
    
  };

 

  useEffect(() => {
    _getGeneralDD({ data: { locations: true , soldTos: false , shipTos: false } });
  }, []);

  let gridData;
  switch (activeTab) {
    case 1:
      const a = orderSearch.filter((x) => x.statusId === "ERRORDER");
      gridData = a;
      break;
      case 2:
        const b = orderSearch.filter((x) => x.statusId === "NEWORDER");
        gridData = b;
        break;
        case 3:
          const c = orderSearch.filter((x) => x.statusId === "SHDOCCREATED");
          gridData = c;
          break;
          case 4:
            const d = orderSearch.filter((x) => x.statusId === "COMPORDER");
            gridData = d;
            break;
          case 5:
            const e = orderSearch.filter((x)=> x.statusId === null || x.statusId === " " || x.statusId === "" ||
                                         x.statusId !== "COMPORDER" && x.statusId !== "ERRORDER" && x.statusId !== "NEWORDER" && x.statusId !== "SHDOCCREATED");
            gridData = e;
            break;
    default:
      gridData = orderSearch;
      break;
  }

  const fieldsState = {
    dropDowns: _GeneralDDData || [],
  };

  const mainFieldsToRender = mainArray(fieldsState);
  const partnerArrayToRender = partnerArray(fieldsState);
  

  var soldtofield = partnerArrayToRender.find(({ id }) => id === "soldtoId");
  var shiptofield = partnerArrayToRender.find(({ id }) => id === "shiptoId");

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource: orderSearchParam, onChange };

    return newFieldObject;
  };
  const columns = [
    { field: "id", headerName: "Order", width: 100 },
    { field: "statusDescriptor", headerName: "Order Status", width: 250 },
    { field: "orderDate", headerName: "Order Date", width: 190 },
    { field: "plannedShipDT", headerName: "Planned BOL date", width: 190 },
    { field: "plannedLoadDT", headerName: "Planned Load date", width: 190 },
    { field: "shiptoDescriptor", headerName: "Ship To", width: 350,},
    { field: "soldtoDescriptor", headerName: "Sold To", width: 350,},
    { field: "inWeight", headerName: "In-Weight", width: 100,},
    { field: "netWeight", headerName: "Net-Weight", width: 100, },
    { field: "outWeight",headerName: "Out-Weight", width: 100, },
    { field: "pin", headerName: "Driver Pin", width: 100 },
    { field: "driverDescriptor", headerName: "Driver", width: 250,},
    { field: "carrierDescriptor", headerName: "Carrier", width: 250 },
    { field: "vehicleId", headerName: "Vehicle", width: 100 },
    { field: "trailerId", headerName: "Trailer", width: 100 },
    { field: "materialDescriptor", headerName: "Material",  width: 350,},
  ]

  let order;
  useEffect(()=>{
   if(Array.isArray(_orderSearchData)){
    order = _orderSearchData.find(x => x.id === selectionModel[0]);
    }

    if(selectionModel.length > 1){
      setOrderButtonChange(false);
      setOrderButtonDisplay(false)
      _createErrorMessage("100");
      return;
    }

    if(selectionModel[0] != undefined && order?.statusId != "COMPORDER"){
      setOrderButtonChange(true);
      setOrderButtonDisplay(true)
    } else if (selectionModel[0] != undefined && order?.statusId == "COMPORDER"){
      setOrderButtonChange(false);
      setOrderButtonDisplay(true)
    } else {
      setOrderButtonChange(false);
      setOrderButtonDisplay(false)
    }
  },[selectionModel]);

    return (
        <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
          {searchFiltersView ? " " : 
            <div className="col-12">
              <div className="card mb-4">
                <div className="cardHeader">
                  <h2>Orders Workbench</h2>
                </div>
                <div className="cardBody">
                  {mainFieldsToRender.map((field, index) => (
                    <FieldsReducer
                      field={mergedFieldData(field, orderSearchParam)}
                      key={index}
                    />
                  ))}
                             <PartnerField
               field={mergedFieldData(soldtofield)}
               setShowList={setShowList}
               _getPartnerList={_getPartnerList}
               setPartnerFieldName={setPartnerFieldName}
               dropDownApiPartnerType="SOLDTO"
             />
             <PartnerField
               field={mergedFieldData(shiptofield)}
               setShowList={setShowList}
               _getPartnerList={_getPartnerList}
               setPartnerFieldName={setPartnerFieldName}
               dropDownApiPartnerType="SHIPTO"
             />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      style={{
                        width: "32%",
                      }}
                      onClick={() => {
                        if(orderSearchParam?.locationId == 'select'){
                          orderSearchParam.locationId = null;
                        } else if(orderSearchParam?.soldtoId == 'select' ) {
                          orderSearchParam.soldtoId = null;
                        }else if(orderSearchParam?.shiptoId == 'select' ) {
                          orderSearchParam.shiptoId = null;
                        }

                        _orderSearch(
                          {
                            data: {
                              LocationId: orderSearchParam?.locationId,
                              OrderDate: orderSearchParam?.orderDate,
                              SoldtoId: orderSearchParam?.soldtoId,
                              ShiptoId :orderSearchParam?.shiptoId
                            },
                          },
                          ({ data }) => {
                            if (data != null) {
                                if (data.length != 0) {
                                  setOrderSearch(data);
                                  setSearchView(true);
                                  setSearchFiltersView(true);
                                } else {
                                  _createErrorMessage("102");
                                }
                            }
                          }
                        );
                      }}
                    >
                      Execute
                    </Button>
                    <Button
                      onClick={() => {
                        let navigationNextUrl = `/Home`;
                        navigate(navigationNextUrl);
                      }}
                      style={{
                        width: "32%",
                        background: "#DA1212",
                        borderColor: "#DA1212",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
             }
             {searchView ?
            <div>
            <Tabs defaultIndex={0} onSelect={(index) => setActiveTab(index)}>
    <TabList>
      <Tab>All Orders</Tab>
      <Tab>Errors in Order</Tab>
      <Tab>New Order</Tab>
      <Tab>Delivered</Tab>
      <Tab>Complete Order</Tab>
      <Tab>Status is unknown/undefined</Tab>
    </TabList>
    <TabPanel>
    <div
                  className="custom-table w-100"
                  style={{ height: 600, overflow: "auto" }}
                >
                  
                  <DataGrid
                    rows={gridData || []}
                    columns={columns}
                    pageSize={100}
                    checkboxSelection
                    disableSelectionOnClick 
                    rowsPerPageOptions={[14]}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                  />
    
                </div>
    </TabPanel>
    <TabPanel>
    <div
                  className="custom-table w-100"
                  style={{ height: 600, overflow: "auto" }}
                >
                  
                  <DataGrid
                    rows={gridData || []}
                    columns={columns}
                    pageSize={100}
                    checkboxSelection
                    disableSelectionOnClick 
                    rowsPerPageOptions={[14]}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                  />
    
                </div>
    </TabPanel>
    <TabPanel>
    <div
                  className="custom-table w-100"
                  style={{ height: 600, overflow: "auto" }}
                >
                  
                  <DataGrid
                    rows={gridData || []}
                    columns={columns}
                    pageSize={100}
                    checkboxSelection
                    disableSelectionOnClick 
                    rowsPerPageOptions={[14]}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                  />
    
                </div>
    </TabPanel>
    <TabPanel>
    <div
                  className="custom-table w-100"
                  style={{ height: 600, overflow: "auto" }}
                >
                  
                  <DataGrid
                    rows={gridData || []}
                    columns={columns}
                    pageSize={100}
                    checkboxSelection
                    disableSelectionOnClick 
                    rowsPerPageOptions={[14]}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                  />
    
                </div>
    </TabPanel>
    <TabPanel>
    <div
                  className="custom-table w-100"
                  style={{ height: 600, overflow: "auto" }}
                >
                  
                  <DataGrid
                    rows={gridData || []}
                    columns={columns}
                    pageSize={100}
                    checkboxSelection
                    disableSelectionOnClick 
                    rowsPerPageOptions={[14]}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                  />
    
                </div>
    </TabPanel>
    <TabPanel>
    <div
                  className="custom-table w-100"
                  style={{ height: 600, overflow: "auto" }}
                >
                  
                  <DataGrid
                    rows={gridData || []}
                    columns={columns}
                    pageSize={100}
                    checkboxSelection
                    disableSelectionOnClick 
                    rowsPerPageOptions={[14]}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                  />
    
                </div>
    </TabPanel>
  </Tabs>
  <div className="d-flex flex-row">
              <div className="p-2">
             <Button
                    onClick={() => {
                      setSearchView(false);
                      setSearchFiltersView(false);
                      if(orderSearchParam?.locationId ||
                        orderSearchParam?.orderDate ||
                        orderSearchParam?.soldtoId  ||
                        orderSearchParam?.shiptoId ){
                          
                          orderSearchParam.locationId = null;
                          orderSearchParam.orderDate = null;
                          orderSearchParam.soldtoId = null;
                          orderSearchParam.shiptoId = null;
                     }
              

                    }}
                    style={{
                      width: "150px",
                      background: "#DA1212",
                      borderColor: "#DA1212",
                    }}
                  >
                    Reset Filter
              </Button>
              </div>
              <div className="p-2">
              {orderButtonDisplay ?  
                <Button
                    onClick={() => {
                      let id = selectionModel[0];
                      console.log("ID",id);
                      let navigationNextUrl = `/Order/${id}/Display`;
                      window.open(navigationNextUrl);
                    }}
                    style={{
                      width: "150px",
                      background: "#229954",
                      borderColor: "#229954",
                   
                  
                    }}
                  >
                    Display Order
              </Button> 
: " "}
{orderButtonChange ?
                <Button
                onClick={() => {
                  let id = selectionModel[0];
                  console.log("ID",id);
                  let navigationNextUrl = `/Order/${id}/Change`;
                  window.open(navigationNextUrl);
                }}
                style={{
                  width: "150px",
                  background: "#3498DB",
                  borderColor: "#3498DB",
                  marginLeft:"15px"
                 
                }}
              >
                Change Order
          </Button> 

              : " "}
          
              </div>
           </div>
  </div>
            : " "  }
            <div className="col-12 mt-4">
              <MessageLog
                errorMessages={[..._errorOrderSearch, ..._errorGeneralDD,..._errorMessages]}
                successMessages={[..._successOrderSearch, ..._successGeneralDD]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
      <PartnerList
        _partnerList={_partnerList}
        showPartnerList={showList}
        setShowPartnerList={setShowList}
        partnerFieldName={partnerFieldName}
        onChange={onChange}
      />
    </>
    );
}

export default OrderWorkbench;