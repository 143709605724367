export const mainArray = ({ dropDowns }) => [
  {
    id: "partnerRoleId",
    name: "partnerRoleId",
    tag: "select",
    label: "Partner Role",
    type: "text",
    options: dropDowns?.partnerRoles || [],
  },
];

export const partnerArray = () => [
  {
    id: "partnerId",
    name: "partnerId",
    label: "Partner",
    type: "text",
  },
];
