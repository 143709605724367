/** @format */

import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import PartnerAssigmentModal from "./PartnerAssigmentModal";
import { itemArray } from "./ItemGridFields";
import { OrderContext } from "../Contex";
function Index(props) {
  const { operation, _orderData, _setOrderData } = useContext(OrderContext);

  const [showNewPartnerAssignmentModal, setShowNewPartnerAssignmentModal] =
    useState(false);

  const fieldsState = {};
  let itemFieldsRender = itemArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource };

    if (noLable) {
      newFieldObject.noLable = true;
    }

    return newFieldObject;
  };

  //Function to take local data from pop-up and add it to main partner state
  const addPartnertoOrder = (localData) => {
    let currentPartners = [];
    if (_orderData.orderPartnersComm) {
      currentPartners = _orderData.orderPartnersComm;
    }

    currentPartners.push(localData);

    _setOrderData((current) => {
      return { ...current, orderPartnersComm: currentPartners };
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 mb-2">
          <Button
            variant="primary"
            disabled={operation === "Display"}
            onClick={() => {
              setShowNewPartnerAssignmentModal(true);
            }}
          >
            Add New Partner
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div style={{ overflow: "auto" }}>
            <table className="table table-striped table-bordered custom-table">
              <thead>
                <tr>
                  {/* Create Table Headings */}
                  {itemFieldsRender.map((field, i) => {
                    return (
                      <th key={i} className="GridColumnHeading">
                        {field.label}
                      </th>
                    );
                  })}
                  <th className="GridColumnHeading">Actions</th>
                </tr>
              </thead>

              <tbody>
                {_orderData.orderPartnersComm?.map((dataRecord, i) => {
                  return (
                    <tr key={i}>
                      {itemFieldsRender.map((field, i1) => {
                        let augmentedField = mergedFieldData(
                          { ...field, noLable: true },
                          dataRecord,
                          true
                        );

                        return (
                          <td key={i1}>{dataRecord[augmentedField.id]}</td>
                        );
                      })}
                      <td>
                        <Button
                          data-op="Delete"
                          variant="danger"
                          data-id={i}
                          onClick={(e) => {
                            let id = e.target.dataset.id;
                            let op = e.target.dataset.op;
                            let currentPartners = [];

                            if (_orderData.orderPartnersComm) {
                              currentPartners = _orderData.orderPartnersComm;
                            }
                            currentPartners.splice(i, 1);

                            _setOrderData((current) => {
                              return {
                                ...current,
                                orderPartnerComm: currentPartners,
                              };
                            });
                          }}
                        >
                          <i
                            className="bi bi-trash"
                            style={{ color: "white" }}
                          ></i>{" "}
                          Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <PartnerAssigmentModal
        showNewPartnerAssignmentModal={showNewPartnerAssignmentModal}
        setShowNewPartnerAssignmentModal={setShowNewPartnerAssignmentModal}
        addPartnertoOrder={addPartnertoOrder}
      />
    </div>
  );
}

export default Index;
