export const itemArray = ({ dropDowns }) => [
  {
    id: "partnerRoleId",
    name: "partnerRoleId",
    type: "text",
    label: "Partner Role",
  },
  {
    id: "partnerId",
    name: "partnerId",
    type: "text",
    label: "Partner",
  },
];
