import React, { useContext } from "react";
import { PartnerContext } from "../PartnerMain/context";

export default function TopPanel() {
  const { operation, partnerId, partnerType } = useContext(PartnerContext);
  var partnerInfo;
  let partner;

  if (partnerType) {
    partner = partnerType;
  } else {
    partner = "Partner";
  }
  switch (operation) {
    case "Display":
      partnerInfo = `${operation} ${partner} ${partnerId}`;
      break;
    case "Change":
      partnerInfo = `${operation} ${partner}: ${partnerId}`;
      break;
    case "Create":
      partnerInfo = `${operation} New ${partner}:`;
      break;
    default:
      partnerInfo = `${partner} Info`;
      break;
  }
  return (
    <h2 className="heading" style={{ position: "relative", top: 8 }}>
      {partnerInfo}
    </h2>
  );
}
