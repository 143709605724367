exports.OrganizationUnit = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    key: "true",
    length: 12,
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    value: "",
    label: "Universal Descriptor",
    length: 50,
  },
  {
    id: "nameLocalizedText",
    name: "nameLocalizedText",
    type: "text",
    value: "",
    label: "nameLocalizedText",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
  {
    id: "nameResourceKey",
    name: "nameResourceKey",
    type: "text",
    value: "",
    label: "nameResourceKey",
    hide: "true",
    displayonly: "true",
  },
];
