import React from "react";
import Button from '@mui/material/Button';
import { ValidateInvDocInput } from "./ValidateInvDocInput";
const btnsArray = [
  { operation: "Display" },
  { operation: "Change" },
  {
    operation: "Create",
    navPath: (navigate) => navigate(`/InventoryDoc/Create`),
  },
];

export default function OverviewButtons({
  inventoryDoc,
  refDoc,
  navigate,
  _getInventoryDocExists,
  _getOrderExists,
  _createErrorMessage,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ operation, navPath }, i) => (
        <div style={{ width: "48%", marginBottom: 15 }}>
          <Button
            className="btn btn-primary w-100"
            key={operation}
            onClick={() => {
              if (operation === "Create") {
                navPath(navigate);
                return;
              }

              let validationInvDocResult = ValidateInvDocInput(
                inventoryDoc,
                _createErrorMessage
              );

              if (!validationInvDocResult) {
                return;
              }

              _getOrderExists({ data: { Id: refDoc } }, ({ data: isExist }) => {
                if (!isExist) {
                  _createErrorMessage("82");
                  return;
                }
                const navigationUrlCreateRef = `/InventoryDoc/${refDoc}/${operation}`;
                navigate(navigationUrlCreateRef);
              });

              _getInventoryDocExists(
                { data: { Id: inventoryDoc } },
                ({ data: isExist }) => {
                  if (!isExist) {
                    _createErrorMessage("82");
                    return;
                  }
                  const navigationUrl = `/InventoryDoc/${inventoryDoc}/${operation}`;
                  navigate(navigationUrl);
                }
              );
            }}
          >
            {operation}
          </Button>
        </div>
      ))}
    </div>
  );
}
