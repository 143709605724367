/** @format */

import React, { useContext } from "react";
import {
  mainDataArray,
  additionalArray,
  commArray,
  adminArray,
} from "./Fields";
import { ShipDocContext } from "../../Contex";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";

function OutboundDetail({
  setShowOutboundDetailsModal,
  outboundOperation,
  outbound,
  setOutbound,
  handleAccept,
}) {
  const { dropDowns } = useContext(ShipDocContext);

  const onChangeOutbound = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setOutbound((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: outbound,
      onChange: onChangeOutbound,
    };
    switch (outboundOperation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: outbound,
    dropDowns,
  };

  const mainDataArrayToRender = mainDataArray(fieldsState);
  const additionalArrayToRender = additionalArray(fieldsState);
  const commArrayToRender = commArray(fieldsState);
  const adminArrayToRender = adminArray(fieldsState);

  return (
    <div>
      <div className="row">
        <div className="col-lg-3 col-sm-12 col-xs-12">
          <div className="card" style={{ minHeight: 450 }}>
            <div className="cardHeader">
              <h2>Main Data</h2>
            </div>
            <div className="cardBody">
              {mainDataArrayToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 450 }}>
            <div className="cardHeader">
              <h2>Additional Data</h2>
            </div>
            <div className="cardBody">
              {additionalArrayToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 450 }}>
            <div className="cardHeader">
              <h2>Communication Data</h2>
            </div>
            <div className="cardBody">
              {commArrayToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 610 }}>
            <div className="cardHeader">
              <h2>Admin Data</h2>
            </div>
            <div className="cardBody">
              {adminArrayToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="my-4"
        >
          <Button
            className="me-4"
            style={{ background: "#DA1212", borderColor: "#DA1212" }}
            onClick={() => {
              setShowOutboundDetailsModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            active="true"
            onClick={() => {
              handleAccept();
              setShowOutboundDetailsModal(false);
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OutboundDetail;
