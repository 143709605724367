export const locationGridFields = [
  {
    dataField: "locationId",
    label: "Location",
  },

  {
    dataField: "stockLevel",
    label: "Stock",
  },

  {
    dataField: "stockUnit",
    label: "Stock Unit",
  },

  {
    dataField: "standardPrice",
    label: "Standard Price",
  },
  {
    dataField: "movingAveragePrice",
    label: "Moving Average Price",
  },
  {
    dataField: "createdBy",
    label: "Created By",
  },
  {
    dataField: "createdOn",
    label: "Created On",
  },
  {
    dataField: "modifiedBy",
    label: "Modified By",
  },
  {
    dataField: "modifiedOn",
    label: "Modified On",
  },
];
