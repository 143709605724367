var localizedFields = {
  En: {
    implementationTypeId: "Impl Type",
    implementationUseId: "Impl Use",
    initialStatusId: "Initial Status",
    completeStatusId: " Complete Status",
    defaultFeedId: "Default Feed",
    outputControl: "Output Control",
    nameLocalizedText: "Local Name",
    nameResourceKey: "Techincal Key",
    lastUpdatedById: "Last Update ID",
    lastUpdatedOn: "Last Change DT",
  },
};

module.exports = {
  localizedFields,
};
