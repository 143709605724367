import React, { useState, useEffect } from "react";
import { useFetch } from "../../hooks/useFetch";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import TruckAnimation from "../../CommonComponents/TruckAnimation/index";
import { FieldsReducer } from "../../CommonComponents/Reducer/Index";
import { mainArray } from "./Fields";

export default function ActiveShipDocs(props) {
  const [locationField, setLocationField] = useState();

  const [
    _getActiveShipDoc,
    _activeShipDocsData,
    _setActiveShipDocsData,
    _errorActiveShipDocs,
    _setErrorActiveShipDocs,
    ,
    ,
    _loadingActiveShipDocs,
  ] = useFetch(GetFullURL("ActiveShipDocs"), { method: "POST" });

  const [
    _getGeneralDD,
    _GeneralDDData,
    ,
    _errorGeneralDD,
    _setErrorGeneralDD,
    _successGeneralDD,
    _setSuccessGeneralDD,
    _loadingGeneralDD,
  ] = useFetch(GetFullURL("GetGeneralDropDown"), { method: "POST" });

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setLocationField((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  useEffect(() => {
    let activeShipDocsTimer = null;

    activeShipDocsTimer = setInterval(() => {
      _getActiveShipDoc();
    }, 2000);

    return () => {
      clearInterval(activeShipDocsTimer);
    };
  }, []);
  const calculateFillPercent = (_shipDocData) => {
    let percentLoaded = 0;
    let plannedQty = 0;
    let loadedQty = 0;
    let currentScaleWeight = 0;
    let currentMeterReading = 0;
    let currentQty = 0;
    let loadType = "";

    if (_shipDocData.inWeight == 0) {
      return percentLoaded.toFixed(2);
    }

    currentScaleWeight = _shipDocData.currentScaleWeight;
    currentMeterReading = _shipDocData.currentMeterReading;

    if (currentScaleWeight !== 0) {
      loadType = "SCALE";
      currentQty = currentScaleWeight;
    } else {
      loadType = "METER";
      currentQty = currentMeterReading;
    }
    if (currentQty === 0) {
      if (_shipDocData.outWeight > 0) {
        return 100;
      }
      if (_shipDocData.inWeight > 0) {
        return 0;
      }
    }

    plannedQty = _shipDocData.cutoffWeight - _shipDocData.inWeight;
    if (loadType === "SCALE") {
      loadedQty = currentQty - _shipDocData.inWeight;
    } else {
      loadedQty = currentQty;
    }

    percentLoaded = (loadedQty * 100) / plannedQty;
    return percentLoaded.toFixed(2);
  };

  useEffect(() => {
    _getGeneralDD({ data: { locations: true } });
  }, []);

  const fieldsState = {
    dropDowns: _GeneralDDData || [],
  };

  const mainFieldsToRender = mainArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    return newFieldObject;
  };

  let array = [];
  let numberAllActiveLoading;
  if (Array.isArray(_activeShipDocsData)) {
    if (
      locationField?.locationId === null ||
      locationField?.locationId === undefined ||
      locationField?.locationId === "" ||
      locationField?.locationId === "select"
    ) {
      array = _activeShipDocsData;
      numberAllActiveLoading = array.length;
    } else {
      array = _activeShipDocsData.filter(
        (x) => x.locationId === locationField?.locationId
      );
      numberAllActiveLoading = array.length;
    }
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="processTitle">
          <h1>Active Processes:{numberAllActiveLoading}</h1>
          {mainFieldsToRender.map((field, index) => (
            <FieldsReducer
              field={mergedFieldData(field, locationField)}
              key={index}
            />
          ))}
        </div>
        <div className="row">
          {array.map((activeShipDoc) => {
            return (
              <div className="col-lg-4 col-sm-4 col-xs-12">
                <div className="processWrapper">
                  <div className="processBox">
                    <h5>Station: {activeShipDoc.currentStation}</h5>
                    <h5>Order: {activeShipDoc.id}</h5>
                    <p>Location: {activeShipDoc.locationDescriptor}</p>
                    <p>Driver: {activeShipDoc.driverDescriptor}</p>
                    <p>Carrier: {activeShipDoc.carrierDescriptor}</p>
                    <p>In-DT: {activeShipDoc.startDT}</p>
                  </div>

                  <div className="processBox">
                    <p>
                      In-Weight: {activeShipDoc.inWeight}{" "}
                      {activeShipDoc.weightUnitId}{" "}
                    </p>
                    <p>Current Weight: {activeShipDoc.currentScaleWeight}</p>
                    <p>
                      Out-Weight: {activeShipDoc.outWeight}{" "}
                      {activeShipDoc.weightUnitId}
                    </p>
                    <div>
                      <TruckAnimation
                        sectionCount={1}
                        activeIndex={0}
                        fillPerCent={calculateFillPercent(activeShipDoc)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
