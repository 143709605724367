import React, { useState, useEffect, useRef } from "react";
import { useFetch } from "../../../../hooks/useFetch";
import { useStation } from "../../../../hooks/UseStation";
import { useMessage } from "../../../../hooks/UseMessage";
import { useUser } from "../../../../hooks/useUser";
import { GetServiceFullURL } from "../../../../CommonComponents/GlobalConfFiles/URLs";
import { ReactComponent as TruckandScale } from "../../../../Assets/SVGs/TruckAndScale.svg";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../CommonComponents/Messages/MessageLog";
import { LoadingScreen } from "../../../../CommonComponents/LoadingScreen/index";
import { FlipCounter } from "../../../../CommonComponents/FlipCounter/FlipCounter";
import TruckAnimation from "../../../../CommonComponents/TruckAnimation/index";
import InfoSideBar from "./WXInfoSideBar";

function WXScaleState(props) {
  const navigate = useNavigate();
  const [modbusMeasurements, setModbusMeasurements] = useState({});
  const [topLog, setTopLog] = useState("");
  const [serviceError, setServiceError] = useState([]);
  const [serviceSuccess, setServiceSuccess] = useState([]);
  const [scaleName, setScaleName] = useState("SouthScale");
  const requestedWeightType = useRef();
  const [_user, ,] = useUser();
  const serviceSimulation = _user.serviceSimulation;

  const [
    _stationInfo,
    _getTerminalSetup,
    _terminalSetup,
    _getStationOperation,
    _stationOperation,
    _messages,
    _setMessages,
  ] = useStation();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
  ] = useMessage();

  const [
    _getWeight,
    ,
    ,
    _requestWeightError,
    _setRequestWeightError,
    _requestWeightSuccess,
    _setRequestWeightSuccess,
    _loadingRequestWeight,
  ] = useFetch(
    GetServiceFullURL("GetModBusData"),
    {
      method: "POST",
    },
    true
  );

  useEffect(() => {
    let weightService = setInterval(GetWeight, 2000);
    return () => {
      clearInterval(weightService);
    };
  }, []);

  function clearMessages(e) {
    let type = e.target.dataset.type;

    switch (type) {
      case "success":
        _setRequestWeightSuccess([]);
        setServiceSuccess([]);
        break;
      case "error":
        _setRequestWeightError([]);
        setServiceError([]);
        _setErorMessages([]);
        break;
      case "warning":
        _setWarningMessages([]);
        break;
      default:
        break;
    }
  }
  const operationLoading = "";

  const calculateFillPercent = () => {
    let loadPercent = 0;
    if (Object.keys(modbusMeasurements).length === 0) {
      return loadPercent.toFixed(2);
    }

    if (modbusMeasurements.weight > 50000) {
      loadPercent = 100;
      return loadPercent.toFixed(2);
    } else {
      loadPercent = 0;
      return loadPercent.toFixed(2);
    }
  };
  calculateFillPercent();

  const handleChangeScale = (e) => {
    const {
      target: { value, name, id },
    } = e;

    setScaleName(id);
  };
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function GetWeight() {
    let simWeight;
    let simTemp;
    let simBSW;
    let simDensity;
    if (serviceSimulation === "true") {
      switch (requestedWeightType.current) {
        case "IN":
          simWeight = getRandomInt(120000, 125000);
          break;
        case "OUT":
          simWeight = getRandomInt(23000, 25000);
          break;
        default:
          return;
      }
      simTemp = getRandomInt(60, 80);
      simBSW = getRandomInt(1, 10);
      simDensity = getRandomInt(1, 10);
      setModbusMeasurements({
        weight: simWeight,
        temperature: simTemp,
        density: simDensity,
        bsw: simBSW,
      });
      return;
    }

    _setRequestWeightError([]);
    _getWeight({ data: { ScaleId: scaleName } }, (reponse) => {
      if (reponse.success) {
        //Set Weight
        setModbusMeasurements({ ...reponse.data });
      } else {
        setServiceError((current) => {
          return [...current, reponse.message];
        });
        return;
      }
    });
  }

  return (
    <div className="container-fluid p-0">
      {operationLoading && <LoadingScreen />}
      {topLog && (
        <div className="row">
          <div className="w-100 rounded-lg px-5 py-3 bg-danger d-flex align-items-center justify-content-center">
            <span className="text-white"> {topLog}</span>
          </div>
        </div>
      )}

      <div className="row align-items-center justify-content-around">
        <div className="col-2">
          <InfoSideBar modbusMeasurements={modbusMeasurements} />
        </div>
        <div className="col-7 d-flex align-items-center justify-content-center">
          <div className="" style={{ flex: 1 }}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="mx-3" style={{ width: "85%" }}>
                <TruckAnimation
                  compartCount={1}
                  activeIndex={0}
                  fillPerCent={calculateFillPercent()}
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <FlipCounter
                number={modbusMeasurements.weight}
                className="mb-5"
              />
              <div className="d-flex" style={{ gap: "1rem" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className="px-5 py-3 mt-5 w-50"
                  onClick={() => {
                    navigate(`/TerminalActivity/StartWithCrudeOrder`);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="px-5 py-3 mt-5 w-50"
                  onClick={"Reset Service Function"}
                >
                  Reset Service
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 d-flex flex-column justify-content-center">
          <div className="form-check mb-3">
            <input
              className="form-check-input radio-zoom"
              type="radio"
              name="ScaleName"
              id="SouthScale"
              checked={scaleName === "SouthScale"}
              onChange={handleChangeScale}
            />
            <label className="form-check-label" htmlFor="SouthScale">
              South Scale
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input radio-zoom"
              type="radio"
              name="ScaleName"
              id="NorthScale"
              checked={scaleName === "NorthScale"}
              onChange={handleChangeScale}
            />
            <label className="form-check-label" htmlFor="NorthScale">
              North Scale
            </label>
          </div>
        </div>
      </div>
      <MessageLog
        errorMessages={[
          ...serviceError,
          ..._requestWeightError,
          ..._errorMessages,
        ]}
        successMessages={[...serviceSuccess, ..._requestWeightSuccess]}
        warningMessages={[..._warningMessages]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}

export default WXScaleState;
