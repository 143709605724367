export const mainArray = ({ dropDowns }) => [
  {
    id: "syncDate",
    name: "syncDate",
    type: "date",
    label: "SyncData",
  },
  {
    id: "loadDate",
    name: "loadDate",
    type: "date",
    label: "loadDate, Greater Than",
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    label: "Location Id",
    options: dropDowns?.locations || [],
    tag: "select",
  },
];

export const syncResultsArray = () => [
  {
    id: "erpCallResult",
    name: "eRPCallResult",
    type: "text",
    label: "ERP Call Result",
    disabled: true,
  },
  {
    id: "numberOrdersFound",
    name: "numberOrdersFound",
    type: "number",
    label: "Total Number of Order Found",
    disabled: true,
  },
  {
    id: "numberOrdersSync",
    name: "numberOrdersSync",
    type: "number",
    label: "Total Number of Orders Synced",
    disabled: true,
  },
];
