import React, { useContext } from "react";
import { MaterialContext } from "../MaterialMain/context";

export default function TopPanel() {
  const { operation, materialId } = useContext(MaterialContext);

  var materialInfo;
  switch (operation) {
    case "Display":
      materialInfo = `${operation} Material: ${materialId}`;
      break;
    case "Change":
      materialInfo = `${operation} Material ${materialId}`;
      break;
    case "Create":
      materialInfo = `${operation} New Material`;
      break;
    default:
      materialInfo = `Material Info`;
      break;
  }
  return (
    <h2 className="heading" style={{ position: "relative", top: 8 }}>
      {materialInfo}
    </h2>
  );
}
