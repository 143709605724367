import React, { useContext } from "react";
import { LabTestContext } from "../LabTestMain/context"

export default function TopPanel() {
  const { operation, labTestId } = useContext(LabTestContext);

  var labTestInfo;
  switch (operation) {
    case "Display":
      labTestInfo = `${operation} Lab Test: ${labTestId}`;
      break;
    case "Change":
      labTestInfo = `${operation} Lab Test: ${labTestId}`;
      break;
    case "Create":
      labTestInfo = `${operation} New Lab Test:`;
      break;
    default:
      labTestInfo = `Lab Test Info`;
      break;
  }
  return (
    <h2
      className="heading"
      style={{ marginLeft: 44, position: "relative", top: 8 }}
    >
      {labTestInfo}
    </h2>
  );
}
