var Feed = ["DataFormatOptions"];

var Incoterm = ["FreightPayer"];

var Element = ["ElementType"];

var DocumentType = ["DocTypeNumberRangeOptions"];
var ShipDocType = ["DocTypeNumberRangeOptions"];
var OrderType = ["DocTypeNumberRangeOptions"];

var PartnerType = ["SystemPartnerTypeOptions"];
var Location = ["ShipDocPrintOptions"];
var DeterLTFMTermSetup = ["ShipDocPrintOptions"];
var BackgroundJob = ["JobStatus"];

var tableenums = {
  BackgroundJob,
  Feed,
  Incoterm,
  Element,
  PartnerType,
  DocumentType,
  ShipDocType,
  OrderType,
  Location,
  DeterLTFMTermSetup,
};

module.exports = {
  tableenums,
};
