import React, { useState, useEffect } from "react";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import { mainArray } from "./Fields";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { useNavigate } from "react-router-dom";
import { FieldsReducer } from "../../CommonComponents/Reducer/Index";
import { useMessage } from "../../hooks/UseMessage";
import { DataGrid } from "@mui/x-data-grid";

export default function JobSumLog(props) {
  let navigate = useNavigate();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [logData, setLogData] = useState([]);
  const [logParam, setLogParam] = useState();
  const [logView, setLogView] = useState(false);
  const [validateClearLog, setValidateClearLog] = useState(false);

  const [
    _requestLog,
    _logData,
    ,
    _errorLog,
    _setErrorLog,
    _successLog,
    _setSuccessLog,
    _loadingLog,
  ] = useFetch(GetFullURL("JobSumLog"), { method: "POST" });

  const [
    _clearLog,
    ,
    ,
    _errorClearLog,
    _setErrorClearLog,
    _successClearLog,
    _setSuccessClearLog,
    _loadingClearLog,
  ] = useFetch(GetFullURL("ClearJobSumLog"), { method: "POST" });

  const [
    _getGeneralDD,
    _GeneralDDData,
    ,
    _errorGeneralDD,
    _setErrorGeneralDD,
    _successGeneralDD,
    _setSuccessGeneralDD,
    _loadingGeneralDD,
  ] = useFetch(GetFullURL("GetGeneralDropDown"), { method: "POST" });

  const operationLoading = _loadingLog || _loadingGeneralDD || _loadingClearLog;

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        checked ? (newValue = true) : (newValue = false);
        break;

      default:
        newValue = value;
        break;
    }

    if (newValue === "select" || newValue === "SELECT") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    setLogParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessLog([]);
        _setSuccessClearLog([]);
        _setSuccessGeneralDD([]);
        break;
      case "error":
        _setErorMessages([]);
        _setErrorClearLog([]);
        _setErrorLog([]);
        _setErrorGeneralDD([]);
        break;
      default:
        break;
    }
  }

  const fieldsState = {
    dropDowns: _GeneralDDData || [],
  };

  useEffect(() => {
    _getGeneralDD({
      data: { backgroundJobName: true, backgroundJobIdentifier: true },
    });
  }, []);

  const mainFieldsToRender = mainArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    return newFieldObject;
  };

  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-12">
              {logView ? (
                ""
              ) : (
                <div className="card mb-4">
                  <div className="cardHeader">
                    <h2>All Messages</h2>
                  </div>
                  <div className="cardBody">
                    {mainFieldsToRender.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field, logParam)}
                        key={index}
                      />
                    ))}
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{
                          width: "32%",
                        }}
                        on
                        Click={() => {
                          if (
                            !logParam?.jobName ||
                            logParam?.jobName === "select" ||
                            !logParam?.JobIdentifier ||
                            logParam?.JobIdentifier === "select"
                          ) {
                            logParam.jobName = null;
                            logParam.JobIdentifier = null;
                          }
                          _requestLog(
                            {
                              data: {
                                User: logParam?.user,
                                JobName: logParam?.jobName,
                                JobIdentifier: logParam?.jobIdentifier,
                                StartDT: logParam?.startDT,
                                EndDT: logParam?.endDT,
                                JobEndStatus: logParam?.jobEndStatus,
                                JobInProcess: logParam?.jobInProcess,
                              },
                            },
                            ({ data }) => {
                              if (data.length != 0) {
                                setLogData(data);
                                setLogView(true);
                                setValidateClearLog(false);
                              } else {
                                _createErrorMessage("89");
                              }
                            }
                          );
                        }}
                      >
                        Get Log
                      </Button>
                      <Button
                        onClick={() => {
                          let navigationNextUrl = `/ReportsHome`;
                          navigate(navigationNextUrl);
                        }}
                        style={{
                          width: "32%",
                          background: "#DA1212",
                          borderColor: "#DA1212",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {logView ? (
              <div className="col-12">
                <div className="mt-0">
                  <div
                    className="custom-table w-100"
                    style={{ height: 700, overflow: "auto" }}
                  >
                    <DataGrid
                      rows={logData || []}
                      columns={[
                        { field: "id", headerName: "ID", width: 200 },
                        {
                          field: "jobName",
                          headerName: "Job Name",
                          width: 250,
                        },
                        {
                          field: "jobIdentifier",
                          headerName: "Job Identifier",
                          width: 250,
                        },
                        {
                          field: "startDT",
                          headerName: "Job Start DT",
                          width: 200,
                        },
                        {
                          field: "endDT",
                          headerName: "Job End DT",
                          width: 200,
                        },
                        {
                          field: "jobEndStatus",
                          headerName: "Job End Status",
                          width: 250,
                        },
                        {
                          field: "jobInProcess",
                          headerName: "Job In Process",
                          width: 150,
                        },
                        {
                          field: "numberTransactionsProcessed",
                          headerName: "Number Transactions Processed",
                          width: 150,
                        },
                        {
                          field: "messageText1",
                          headerName: "Message Text 1",
                          width: 3000,
                        },
                        {
                          field: "messageText2",
                          headerName: "Message Text 2",
                          width: 3000,
                        },
                        {
                          field: "messageText3",
                          headerName: "Message Text 3",
                          width: 3000,
                        },
                        {
                          field: "messageText4",
                          headerName: "Message Text 4",
                          width: 3000,
                        },
                        {
                          field: "createdBy",
                          headerName: "Created By",
                          width: 200,
                        },
                        {
                          field: "createdOn",
                          headerName: "Created On",
                          width: 200,
                        },
                      ]}
                      pageSize={100}
                      rowsPerPageOptions={[8]}
                      onSelectionModelChange={""}
                    />
                  </div>
                </div>
                <div className="row m-1">
                  <Button
                    onClick={() => {
                      if (
                        logParam?.user ||
                        logParam?.jobName ||
                        logParam?.jobIdentifier ||
                        logParam?.startDT ||
                        logParam?.endDT ||
                        logParam?.jobEndStatus ||
                        logParam?.jobInProcess
                      ) {
                        logParam.user = null;
                        logParam.jobName = null;
                        logParam.jobIdentifier = null;
                        logParam.startDT = null;
                        logParam.endDT = null;
                        logParam.jobEndStatus = 0;
                        logParam.jobInProcess = false;
                      }
                      setLogView(false);
                    }}
                    style={{
                      width: "150px",
                      background: "#DA1212",
                      borderColor: "#DA1212",
                    }}
                  >
                    Reset Filter
                  </Button>
                  <Button
                    onClick={() => {
                      if (
                        logParam?.user ||
                        logParam?.jobName ||
                        logParam?.jobIdentifier ||
                        logParam?.startDT ||
                        logParam?.endDT ||
                        logParam?.jobEndStatus ||
                        logParam?.jobInProcess
                      ) {
                        logParam.user = null;
                        logParam.jobName = null;
                        logParam.jobIdentifier = null;
                        logParam.startDT = null;
                        logParam.endDT = null;
                        logParam.jobEndStatus = 0;
                        logParam.jobInProcess = false;
                      }
                      setLogView(false);
                      setValidateClearLog(true);
                    }}
                    style={{
                      marginLeft: "20px",
                      width: "150px",
                      background: "#DA1212",
                      borderColor: "#DA1212",
                    }}
                  >
                    Clear Log
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
            {validateClearLog ? (
              <div class="d-flex flex-row justify-content-center">
                <div class="p-2">
                  <h2>Are you sure?</h2>
                </div>
                <div class="p-2">
                  <Button
                    onClick={() => {
                      setLogView(false);
                      _clearLog();
                      setValidateClearLog(false);
                    }}
                    style={{
                      marginLeft: "20px",
                      width: "150px",
                      background: "#2ECC71",
                      borderColor: "#2ECC71",
                    }}
                  >
                    Proceed
                  </Button>
                  <Button
                    onClick={() => {
                      setValidateClearLog(false);
                      setLogView(true);
                    }}
                    style={{
                      marginLeft: "20px",
                      width: "150px",
                      background: "#DA1212",
                      borderColor: "#DA1212",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-12 mt-4">
              <MessageLog
                errorMessages={[
                  ..._errorLog,
                  ..._errorMessages,
                  ..._errorClearLog,
                  ..._errorGeneralDD,
                ]}
                successMessages={[
                  ..._successLog,
                  ..._successClearLog,
                  ..._successGeneralDD,
                ]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
