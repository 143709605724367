export const itemGridFields = () => [
  {
    id: "itemNumber",
    name: "itemNumber",
    type: "number",
    label: "Item",
  },
  {
    id: "materialDescriptor",
    name: "materialDescriptor",
    type: "text",
    label: "Material",
  },

  {
    id: "plannedQuantity",
    name: "plannedQuantity",
    type: "number",
    label: "Planned Qty",
  },
  {
    id: "quantity",
    name: "quantity",
    type: "number",
    label: "Loaded Qty",
  },
  {
    id: "unitId",
    name: "unitId",
    type: "text",
    label: "Unit",
  },
];
