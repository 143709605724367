import React, { useState, useEffect } from "react";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../../hooks/useFetch";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { mainArray } from "./Fields";
import { useNavigate } from "react-router-dom";
import { FieldsReducer } from "../../../CommonComponents/Reducer/Index";
import { DataGrid } from "@mui/x-data-grid";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useMessage } from "../../../hooks/UseMessage";

export default function PartnerWorkbench(props) {
  let navigate = useNavigate();
  const [partnerSearchParam, setFoundPartnerListParam] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [showWorkbench, setShowWorkbench] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [selectionModel, setSelectionModel] = useState([]);
  const [partnerActionButtons, setPartnerActionButtons] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState({});
  const [
    _partnerSearch,
    _partnerSearchData,
    ,
    _errorPartnerSearch,
    _setErrorPartnerSearch,
    _successPartnerSearch,
    _setSuccessPartnerSearch,
    _loadingPartnerSearch,
  ] = useFetch(GetFullURL("PartnerSearchForWorkbench"), { method: "POST" });

  const [
    _getGeneralDD,
    _GeneralDDData,
    ,
    _errorGeneralDD,
    _setErrorGeneralDD,
    _successGeneralDD,
    _setSuccessGeneralDD,
    _loadingGeneralDD,
  ] = useFetch(GetFullURL("GetGeneralDropDown"), { method: "POST" });

  const [
    _getPartnerList,
    _partnerList,
    _setPartnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    _successPartnerList,
    _setSuccessPartnerList,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("PartnerList"), { method: "POST" });

  const [
    _forceDeletePartner,
    ,
    _setForceDeletePartner,
    _errorForceDeletePartner,
    _setForceErrorDeletePartner,
    _successForceDeletePartner,
    _setForceSuccessDeletePartner,
    _loadingForceDeletePartner,
  ] = useFetch(GetFullURL("PartnerForceDelete"), { method: "POST" });

  const operationLoading =
    _loadingPartnerSearch ||
    _loadingGeneralDD ||
    _loadingPartnerList ||
    _loadingForceDeletePartner;

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessPartnerSearch([]);
        _setSuccessGeneralDD([]);
        _setSuccessPartnerList([]);
        _setForceSuccessDeletePartner([]);
        break;
      case "error":
        _setErrorPartnerSearch([]);
        _setErrorGeneralDD([]);
        _setErorMessages([]);
        _setErrorPartnerList([]);
        _setForceDeletePartner([]);
        break;
      default:
        break;
    }
  }

  const onChange = (e) => {
    const {
      target: { value, name, checked },
    } = e;

    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        //if checkbox then pass the value as true or false assuming that field is defined as bool in backend
        checked ? (newValue = true) : (newValue = false);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    setFoundPartnerListParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  useEffect(() => {
    _getGeneralDD({
      data: { locations: true, partnerTypes: true, states: true },
    });
  }, []);

  const fieldsState = {
    dropDowns: _GeneralDDData || [],
  };

  const mainFieldsToRender = mainArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource: partnerSearchParam, onChange };

    return newFieldObject;
  };
  const columns = [
    { field: "id", headerName: "Partner Id", width: 100 },
    { field: "name", headerName: "Name", width: 350 },
    { field: "pin", headerName: "Pin", width: 100 },
    { field: "partnerTypeId", headerName: "Type", width: 350 },
    { field: "name1", headerName: "Name 2", width: 350 },
    { field: "contactName", headerName: "Contact Name", width: 350 },
    { field: "locationId", headerName: "Location", width: 350 },
    { field: "emailAddress", headerName: "Email Address", width: 350 },
    { field: "tel1", headerName: "Tel number 1", width: 350 },
    { field: "streetAddress", headerName: "Street Address 1", width: 350 },
    { field: "streetAddress1", headerName: "Street Address 2", width: 350 },
    { field: "city", headerName: "City", width: 350 },
    { field: "stateId", headerName: "State", width: 350 },
    { field: "regionId", headerName: "Region", width: 350 },
    { field: "postalCode", headerName: "Postal Code", width: 350 },
    { field: "countryId", headerName: "Country", width: 350 },
    {
      field: "partnerBlockCodeId",
      headerName: "Partner Block Code",
      width: 100,
    },
  ];

  useEffect(() => {
    if (Array.isArray(_partnerSearchData)) {
      let partner = _partnerSearchData.find((x) => x.id === selectionModel[0]);
      if (partner) {
        setSelectedPartner(partner);
      }
    }

    if (selectionModel[0] != undefined) {
      setPartnerActionButtons(true);
    } else {
      setPartnerActionButtons(false);
    }
  }, [selectionModel]);

  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            {showWorkbench ? (
              " "
            ) : (
              <div className="col-12">
                <div className="card mb-4">
                  <div className="cardHeader">
                    <h2>Partner Workbench</h2>
                  </div>
                  <div className="cardBody">
                    {mainFieldsToRender.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field, partnerSearchParam)}
                        key={index}
                      />
                    ))}

                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{
                          width: "32%",
                        }}
                        onClick={() => {
                          if (
                            partnerSearchParam?.partnerTypeId === "select" ||
                            partnerSearchParam?.locationId === "select" ||
                            partnerSearchParam?.stateId === "select"
                          ) {
                            partnerSearchParam.partnerTypeId = null;
                            partnerSearchParam.locationId = null;
                            partnerSearchParam.stateId = null;
                          }

                          _partnerSearch(
                            {
                              data: {
                                PartnerType: partnerSearchParam?.partnerTypeId,
                                Name: partnerSearchParam?.partnerName,
                                Name1: partnerSearchParam?.partnerName1,
                                Name2: partnerSearchParam?.partnerName2,
                                LocationId: partnerSearchParam?.locationId,
                                Pin: partnerSearchParam?.pin,
                                City: partnerSearchParam?.city,
                                State: partnerSearchParam?.stateId,
                              },
                            },
                            ({ data }) => {
                              if (data != null) {
                                if (data.length != 0) {
                                  setShowWorkbench(true);
                                } else {
                                  _createErrorMessage("34");
                                }
                              }
                            }
                          );
                        }}
                      >
                        Execute
                      </Button>
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        style={{
                          width: "32%",
                          background: "#DA1212",
                          borderColor: "#DA1212",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>

                    <Button
                      onClick={() => {
                        let navigationNextUrl = `/Partner/Create`;
                        window.open(navigationNextUrl);
                      }}
                      style={{
                        width: "150px",
                        background: "#229954",
                        borderColor: "#229954",
                        marginTop: "15px",
                      }}
                    >
                      Create Partner
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {showWorkbench ? (
              <div>
                <Tabs
                  selectedIndex={activeTab}
                  onSelect={(index) => setActiveTab(index)}
                >
                  <TabList>
                    <Tab>All Partners</Tab>
                    <Tab>Carriers</Tab>
                    <Tab>Customers</Tab>
                    <Tab>Drivers</Tab>
                    <Tab>Ship To</Tab>
                    <Tab>Sold To</Tab>
                  </TabList>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 600, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={_partnerSearchData || []}
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onRowSelectionModelChange={(selectedRowIds) => {
                          if (selectedRowIds.length >= 1) {
                            var x =
                              selectedRowIds[
                                selectedRowIds.length - 1
                              ].toString();
                            setSelectionModel(x);
                          } else {
                            setSelectionModel(selectedRowIds);
                          }
                        }}
                        rowSelectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={
                          _partnerSearchData.filter(
                            (x) => x.partnerTypeId === "CARRIER"
                          ) || []
                        }
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onRowSelectionModelChange={(selectedRowIds) => {
                          if (selectedRowIds.length >= 1) {
                            var x =
                              selectedRowIds[
                                selectedRowIds.length - 1
                              ].toString();
                            setSelectionModel(x);
                          } else {
                            setSelectionModel(selectedRowIds);
                          }
                        }}
                        rowSelectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={
                          _partnerSearchData.filter(
                            (x) =>
                              x.partnerTypeId === "SHIPTO" ||
                              x.partnerTypeId === "SOLDTO"
                          ) || []
                        }
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onRowSelectionModelChange={(selectedRowIds) => {
                          if (selectedRowIds.length >= 1) {
                            var x =
                              selectedRowIds[
                                selectedRowIds.length - 1
                              ].toString();
                            setSelectionModel(x);
                          } else {
                            setSelectionModel(selectedRowIds);
                          }
                        }}
                        rowSelectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={
                          _partnerSearchData.filter(
                            (x) => x.partnerTypeId === "DRIVER"
                          ) || []
                        }
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onRowSelectionModelChange={(selectedRowIds) => {
                          if (selectedRowIds.length >= 1) {
                            var x =
                              selectedRowIds[
                                selectedRowIds.length - 1
                              ].toString();
                            setSelectionModel(x);
                          } else {
                            setSelectionModel(selectedRowIds);
                          }
                        }}
                        rowSelectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={
                          _partnerSearchData.filter(
                            (x) => x.partnerTypeId === "SHIPTO"
                          ) || []
                        }
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onRowSelectionModelChange={(selectedRowIds) => {
                          if (selectedRowIds.length >= 1) {
                            var x =
                              selectedRowIds[
                                selectedRowIds.length - 1
                              ].toString();
                            setSelectionModel(x);
                          } else {
                            setSelectionModel(selectedRowIds);
                          }
                        }}
                        rowSelectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      className="custom-table w-100"
                      style={{ height: 650, overflow: "auto" }}
                    >
                      <DataGrid
                        rows={
                          _partnerSearchData.filter(
                            (x) => x.partnerTypeId === "SOLDTO"
                          ) || []
                        }
                        columns={columns}
                        pageSize={100}
                        checkboxSelection
                        disableSelectionOnClick
                        rowsPerPageOptions={[14]}
                        onRowSelectionModelChange={(selectedRowIds) => {
                          if (selectedRowIds.length >= 1) {
                            var x =
                              selectedRowIds[
                                selectedRowIds.length - 1
                              ].toString();
                            setSelectionModel(x);
                          } else {
                            setSelectionModel(selectedRowIds);
                          }
                        }}
                        rowSelectionModel={selectionModel}
                      />
                    </div>
                  </TabPanel>
                </Tabs>
                <div className="d-flex flex-row">
                  <div className="p-2">
                    <Button
                      onClick={() => {
                        if (
                          partnerSearchParam?.partnerTypeId ||
                          partnerSearchParam?.partnerName ||
                          partnerSearchParam?.partnerName1 ||
                          partnerSearchParam?.partnerName2 ||
                          partnerSearchParam?.locationId ||
                          partnerSearchParam?.pin ||
                          partnerSearchParam?.city ||
                          partnerSearchParam?.stateId
                        ) {
                          partnerSearchParam.partnerTypeId = null;
                          partnerSearchParam.partnerName = null;
                          partnerSearchParam.partnerName1 = null;
                          partnerSearchParam.partnerName2 = null;
                          partnerSearchParam.locationId = null;
                          partnerSearchParam.pin = 0;
                          partnerSearchParam.city = null;
                          partnerSearchParam.stateId = null;
                        }

                        setShowWorkbench(false);
                      }}
                      style={{
                        width: "150px",
                        background: "#DA1212",
                        borderColor: "#DA1212",
                      }}
                    >
                      Reset Filter
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          partnerSearchParam?.partnerTypeId === "select" ||
                          partnerSearchParam?.locationId === "select" ||
                          partnerSearchParam?.stateId === "select"
                        ) {
                          partnerSearchParam.partnerTypeId = null;
                          partnerSearchParam.locationId = null;
                          partnerSearchParam.stateId = null;
                        }

                        _partnerSearch(
                          {
                            data: {
                              PartnerType: partnerSearchParam?.partnerTypeId,
                              Name: partnerSearchParam?.partnerName,
                              Name1: partnerSearchParam?.partnerName1,
                              Name2: partnerSearchParam?.partnerName2,
                              LocationId: partnerSearchParam?.locationId,
                              Pin: partnerSearchParam?.pin,
                              City: partnerSearchParam?.city,
                              State: partnerSearchParam?.stateId,
                            },
                          },
                          ({ data }) => {
                            console.log(data);
                            if (data != null) {
                              if (data.length != 0) {
                              } else {
                                _createErrorMessage("111");
                              }
                            }
                          }
                        );
                      }}
                      style={{
                        width: "150px",
                        background: "#3498DB",
                        borderColor: "#3498DB",
                        marginLeft: "10px",
                      }}
                    >
                      Refresh
                    </Button>
                  </div>
                  <div className="p-2">
                    <Button
                      onClick={() => {
                        let navigationNextUrl = `/Partner/Create`;
                        window.open(navigationNextUrl);
                      }}
                      style={{
                        width: "150px",
                        background: "#229954",
                        borderColor: "#229954",
                      }}
                    >
                      Create Partner
                    </Button>

                    {partnerActionButtons ? (
                      <div className="p-2">
                        <Button
                          onClick={() => {
                            debugger;
                            let id = selectionModel;
                            let navigationNextUrl = `/Partner/${id}/Display`;
                            window.open(navigationNextUrl);
                          }}
                          style={{
                            width: "150px",
                            background: "#3498DB",
                            borderColor: "#3498DB",
                          }}
                        >
                          Display Partner
                        </Button>

                        <Button
                          onClick={() => {
                            let id = selectionModel;
                            let navigationNextUrl = `/Partner/${id}/Change`;
                            window.open(navigationNextUrl);
                          }}
                          style={{
                            width: "150px",
                            background: "#3498DB",
                            borderColor: "#3498DB",
                            marginLeft: "15px",
                          }}
                        >
                          Change Partner
                        </Button>

                        {/* <Button
                          onClick={() => {
                            let id = selectionModel[0];
                            _forceDeletePartner({ data: { Id: id } }, () => {
                         

                              _partnerSearch(
                                {
                                  data: {
                                    PartnerType:
                                      partnerSearchParam?.partnerTypeId,
                                    Name: partnerSearchParam?.partnerName,
                                    Name1: partnerSearchParam?.partnerName1,
                                    Name2: partnerSearchParam?.partnerName2,
                                    LocationId: partnerSearchParam?.locationId,
                                    Pin: partnerSearchParam?.pin,
                                    City: partnerSearchParam?.city,
                                    State: partnerSearchParam?.stateId,
                                  },
                                },
                                ({ data }) => {
                                  if (data != null) {
                                    if (data.length != 0) {
                                                                       } else {
                                      _createErrorMessage("111");
                                    }
                                  }
                                }
                              );
                            });
                          }}
                          style={{
                            width: "150px",
                            background: "#DA1212",
                            borderColor: "#DA1212",
                            marginLeft: "15px",
                          }}
                        >
                          Delete Partner
                        </Button> */}
                      </div>
                    ) : (
                      " "
                    )}
                  </div>
                </div>
              </div>
            ) : (
              " "
            )}
            <div className="col-12 mt-4">
              <MessageLog
                errorMessages={[
                  ..._errorPartnerSearch,
                  ..._errorGeneralDD,
                  ..._errorMessages,
                  ..._errorPartnerList,
                  ..._errorForceDeletePartner,
                ]}
                successMessages={[
                  ..._successPartnerSearch,
                  ..._successGeneralDD,
                  ..._successPartnerList,
                  ..._successForceDeletePartner,
                ]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
