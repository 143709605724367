exports.OutboundType = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    length: 12,
    key: "true",
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    value: "",
    label: "Universal Descriptor",
    length: 50,
  },
  {
    id: "initialStatusId",
    name: "initialStatusId",
    type: "text",
    value: "",
    label: "Initial Status Id",
    length: 12,
    ddid:"outboundStatusId",
  },
  {
    id: "sentStatusId",
    name: "sentStatusId",
    type: "text",
    value: "",
    label: "SentStatusId",
    length: 12,
    ddid:"outboundStatusId",
  },
  {
    id: "errorStatusId",
    name: "errorStatusId",
    type: "text",
    value: "",
    label: "Error Status Id",
    length: 12,
    ddid:"outboundStatusId",
  },
  {
    id: "openStatusId",
    name: "openStatusId",
    type: "text",
    value: "",
    label: "Open Status Id",
    length: 12,
    ddid:"outboundStatusId",
  },
  {
    id: "transmissionMethodId",
    name: "transmissionMethodId",
    type: "text",
    value: "",
    label: "Transmission Method Id",
    ddid: "transmissionMethodId",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
