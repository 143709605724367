import axios from "axios";
import { URLs } from "./../../../CommonComponents/GlobalConfFiles/URLs";
import { Log, Error, Success, ClearSuccess } from "./../User/Log";
import { newUserData } from "./AuthActions";
import { ClearError } from "../../Actions/User/Log";
import { LogOut } from "./AuthActions";

const authenticateUser = (login, cb) => {
  //This is the action to read a single partner from
  //backend ie db. 1st Get URLs from config file
  const baseURL = `${URLs.Host}${URLs.System}`;
  var operationType = "Authenticate";
  const fullURL = baseURL + operationType;
  let logObject;
  let errorMessage;
  const api = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });
  return async (dispatch) => {
    dispatch(ClearError());
    //make the call to backend
    api
      .post(fullURL, login)
      .then((response) => {
        const userObj = response.data.data;
        
        //If user object is null, means login failed
        if (userObj == null) {
          cb();
          if (response.data.messages != null) {
            logObject = { Log: [...response.data.messages] };
            dispatch(Log(logObject));
          }
          return;
        }

        dispatch(newUserData(userObj));

        localStorage.setItem("token", userObj.token);
        localStorage.setItem("id", userObj.user.id);
        //if there any messages (array)from the backend then dispatch them as a log
        //they are then arranged into errors and messages by reducer
        cb();
        if (response.data.messages != null) {
          logObject = { Log: [...response.data.messages] };
          dispatch(Log(logObject));
        }
      })
      .catch((e) => {
        cb();
        //If there is no respone then issue a mesage about backend availability
        if (e.response == null) {
          logObject = {
            Error: ["Authentication Endpoint", "No Response from Backend"],
          };
          return dispatch(Error(logObject));
        }
        if (e.message != null) {
          errorMessage = e.message;
          logObject = {
            Error: ["Authentication Endpoint", errorMessage],
          };
          dispatch(Error(logObject));
        }

        if (e.response.data.Message != null) {
          errorMessage = e.response.data.Message;
          logObject = {
            Error: ["Authentication Endpoint", errorMessage],
          };
          dispatch(Error(logObject));
        }
      });
  };
};

const getUser = (cb) => {
  const baseURL = `${URLs.Host}${URLs.System}`;
  var operationType = "Getuser";
  const fullURL = baseURL + operationType;
  let token = localStorage.getItem("token");
  let id = localStorage.getItem("id");

  const api = axios.create({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return async (dispatch) => {
    dispatch(ClearError());
    //make the call to backend
    api
      .post(fullURL, { id })
      .then((response) => {
        const userObj = response.data.data;
        dispatch(
          newUserData({
            user: userObj,
            token,
          })
        );

        cb();
      })
      .catch((e) => {
        cb();
      });
  };
};

const logOutUser = () => {
  let logObject;
  return async (dispatch) => {
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    dispatch(LogOut());
    dispatch(ClearSuccess());
    logObject = { Success: ["User Logged Out Successfully"] };
    dispatch(Success(logObject));
  };
};

export { authenticateUser, getUser, logOutUser };
