import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { TankContext } from "../context";
import { MuiButon } from "../../../../CommonComponents/MuiButton";
import toastr from "toastr";

export default function TankOperationHeader({
  _loadingSaveTankData,
  setErrors,
}) {
  const { operation, _setTankData, _tankData, _saveTank, setOperation } =
    useContext(TankContext);

  const handleSave = () => {
    _saveTank(
      { data: _tankData },
      ({ data: NewTankData, success: callStatus }) => {
        if (callStatus) {
          if (operation === "Create") {
          }
          setOperation("Change");
        }
        _setTankData(NewTankData);
      }
    );
  };

  return (
    <Paper className="py-2 px-2 d-flex align-items-center justify-space-between customPaper">
      <TopPanel />
      <MuiButon
        title="Save"
        loading={_loadingSaveTankData}
        onClick={handleSave}
        disabled={operation === "Display"}
      />
    </Paper>
  );
}
