/** @format */

import React, { useContext } from "react";
import { adminFieldsArray } from "./Fields";
import { MaterialContext } from "../context";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";

export default function AdminTab() {
  const { onChange, operation, dropDowns, _materialData } =
    useContext(MaterialContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _materialData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };

  const fieldsState = {
    dataSource: _materialData,
    dropDowns,
  };

  const fieldsToRender = adminFieldsArray(fieldsState);
  return (
    <div className="row">
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{minHeight: 'auto'}}>
          <div className="cardHeader">
            <h2>Admin Data</h2>
          </div>
          <div className="cardBody">
            {fieldsToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
