export const mainArray = ({ dropDowns }) => [
  {
    id: "jobName",
    name: "jobName",
    type: "text",
    label: "Job Name",
    options: dropDowns?.backgroundJobNames || [],
    tag: "select",
  },
  {
    id: "jobIdentifier",
    name: "jobIdentifier",
    type: "text",
    label: "Job Identifier",
    options: dropDowns?.backgroundJobIdentifiers || [],
    tag: "select",
  },
  {
    id: "jobId",
    name: "jobId",
    type: "number",
    label: "Job Id",
  },
  {
    id: "jobDateTime",
    name: "jobDateTime",
    type: "datetime",
    label: "Job Date & Time",
  },
];
