import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { useFetch } from "../../../../../hooks/useFetch";
import { useStation } from "../../../../../hooks/UseStation";
import { useMessage } from "../../../../../hooks/UseMessage";
import { useDD } from "../../../../../hooks/UseDD";
import { useUser } from "../../../../../hooks/useUser";
import { driverPinArray, carrierArray, leftPanelArray } from "./Fields";
import { FieldsReducer } from "../../../../../CommonComponents/Reducer/Index";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../../../CommonComponents/GlobalConfFiles/URLs";
import { useParams } from "react-router-dom";
import { ValidateConfirmChecking } from "./ValidateShipDoc";
import ListModal from "../../../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
function CheckinV6(props) {
  //This is a simple checkin, no service interaction just take driver data and save it,no driver pin is required,
  //it is used for Tulsa East
  const params = useParams();
  const navigate = useNavigate();
  const shipDocid = params.shipDoc;
  const fieldOperationId = "CHECKIN";
  const [continueChecking, setContinueChecking] = useState(false);
  const [keyboardFocusField, setKeyboardFocusField] = useState(null);
  const [checkinFinished, setCheckinFinished] = useState(false);
  const [terminalSetupCarrier, setTerminalSetupCarrier] = useState([]);
  const [terminalSetupShiptos, setTerminalSetupShiptos] = useState([]);
  const shipDocPrintOptions = useRef("");
  const [
    _getPartnerList,
    _partnerList,
    _setParnerList,
    _errorPartnerList,
    _setErrorPartnerList,
    ,
    ,
    _loadingPartnerList,
  ] = useFetch(GetFullURL("GetPartnerPerPINandType"), { method: "POST" });

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" }, true);

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    ,
    ,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" }, true);

  const [
    _deterSetup,
    _deterSetupData,
    ,
    _errorDeterSetup,
    _setErrorDeterSetup,
    ,
    ,
    _loadingSetupDeter,
  ] = useFetch(GetFullURL("DetermineSetup"), { method: "POST" }, true);

  const [
    _searchWIPShipDocs,
    _serachWIPShipDocData,
    ,
    ,
    ,
    ,
    ,
    _loadingSearchWIPShipDoc,
  ] = useFetch(GetFullURL("AdvancedShipDocSearch"), { method: "POST" });
  const [
    _stationInfo,
    _getTerminalSetup,
    _terminalSetup,
    _getStationOperation,
    _stationOperation,
    _stationMessages,
    _setStationMessages,
  ] = useStation();

  const [_dd] = useDD();
  const [_user] = useUser();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    ,
    ,
    ,
    _createSuccessMessage,
    _successMessages,
    _setSuccessMessages,
  ] = useMessage();

  //  useEffect(() => {}, [_shipDocData.fieldProcessId]);

  useEffect(() => {
    //This is done to get the shipDoc print options
    //this setting can come from location or terminal setup determination
    //preference is given to terminal setup, there are 4 options
    // NoPriting = 1,
    // AlwaysFromTload = 2,
    // FromTloadWhenERPDown = 3,
    //  AlwaysFromERP =4,

    if (typeof _shipDocData === "undefined") {
      return;
    }

    if (typeof _deterSetupData === "undefined") {
      return;
    }

    let locations = _shipDocData?.locationsInfoComm ?? [];

    let location = locations.find((x) => x.id == _shipDocData.locationId);

    let locationPrintOption = location?.shipDocPrintOptions;
    let termSetupPrintOption = _deterSetupData?.shipDocPrintOptions;

    if (termSetupPrintOption) {
      shipDocPrintOptions.current = termSetupPrintOption;
    } else {
      shipDocPrintOptions.current = locationPrintOption;
    }
  }, [_deterSetupData, _shipDocData]);

  useEffect(() => {
    _getShipDoc({ data: { Id: shipDocid } }, ({ data: shipDocData }) => {
      //If any of checking data has populated then show them
      _getStationOperation(fieldOperationId, shipDocData.fieldProcessId);
      _deterSetup({
        data: {
          LocationId: shipDocData.locationId,
          FieldProcessId: shipDocData.fieldProcessId,
          TransportationModeId: shipDocData.transportationModeId,
          MaterialId: shipDocData.shippingDocItemsComm[0].materialId,
        },
      });
    });
  }, []);

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }

    const newValueObject = { [name]: newValue };
    _setShipDocData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  useEffect(() => {
    if (!checkinFinished) {
      return;
    }

    let nextUrlattribute1 = "";
    let nextUrlattribute2 = "";
    let nextUrlattribute3 = "";

    if (_stationOperation.nextUrlAttribute1) {
      try {
        nextUrlattribute1 = eval(_stationOperation.nextUrlAttribute1);
      } catch (error) {
        nextUrlattribute1 = _stationOperation.nextUrlAttribute1;
      }
    }

    if (_stationOperation.nextUrlAttribute2) {
      try {
        nextUrlattribute2 = eval(_stationOperation.nextUrlAttribute2);
      } catch (error) {
        nextUrlattribute2 = _stationOperation.nextUrlAttribute2;
      }
    }

    if (_stationOperation.nextUrlAttribute3) {
      try {
        nextUrlattribute3 = eval(_stationOperation.nextUrlAttribute3);
      } catch (error) {
        nextUrlattribute3 = _stationOperation.nextUrlAttribute3;
      }
    }
    //Removed the wait per Tulsa East Sherwood, not user friendly
    // _createSuccessMessage("94");
    // var leaveTimer = setTimeout(() => {
    //   navigate(
    //     `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
    //   );
    // }, 3000);

    // return () => {
    //   clearTimeout(leaveTimer);
    // };

    navigate(
      `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
    );
  }, [checkinFinished]);

  useEffect(() => {
    //Get shipto and carrier dropdown from terminal setup
    if (!_deterSetupData.setupData?.orderPartnersComm) {
      return;
    }

    _deterSetupData.setupData.partnersInfoComm.forEach((element) => {
      switch (element.partnerTypeId) {
        case "SHIPTO":
          let shiptoDD = {
            value: element.id,
            label: element.partnerDescriptor,
          };
          setTerminalSetupShiptos((current) => {
            return [...current, shiptoDD];
          });
          break;
        case "CARRIER":
          let carrierDD = {
            value: element.id,
            label: element.partnerDescriptor,
          };

          setTerminalSetupCarrier((current) => {
            return [...current, carrierDD];
          });

          break;
        default:
        // code block
      }
    });
  }, [_deterSetupData]);

  const fieldsState = {
    dropDowns: _dd.shipDoc,
    trmCarriers: terminalSetupCarrier,
  };
  const handleOnKeyboardFocus = (fieldId, event) => {
    //when a keyboard field is clicked, set it in the state becuase we need
    //to only render this field
    if (event === "onFocus") {
      setKeyboardFocusField(fieldId);
    } else {
      //The blur event is triggered not only when a field is unfocused but also when a
      //field is focused it tiggers blur for all other fields, we only need to blur only
      //the relvant field in being unfocused
      if (fieldId === keyboardFocusField) {
        setKeyboardFocusField("");
      }
    }
  };

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: _shipDocData,
      onChange,
      handleOnKeyboardFocus,
      hideKeyboard: _user.disableKeyboard,
      defaultKeyboardType: field.defaultKeyboardType,
    };

    return newFieldObject;
  };
  const leftPanelFields = leftPanelArray(fieldsState);
  const driverFields = driverPinArray(fieldsState);
  const carrierFields = carrierArray(fieldsState);

  const operationLoading =
    _loadingSaveShipDoc ||
    _loadingPartnerList ||
    _loadingShipDoc ||
    _loadingSetupDeter;

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessSaveShipDoc([]);
        _setSuccessMessages([]);

        break;
      case "error":
        _setErrorSaveShipDoc([]);
        _setStationMessages([]);
        _setErrorShipDoc([]);
        _setErrorPartnerList([]);
        _setErorMessages([]);

        break;
      case "warning":
        break;
      default:
        break;
    }
  }
  function confirmCheckin() {
    let validationConfirmResult = ValidateConfirmChecking(
      _shipDocData,
      _createErrorMessage
    );

    if (!validationConfirmResult) {
      return;
    }

    if (!_stationOperation.fieldOperationId) {
      _createErrorMessage(
        "76",
        _shipDocData.fieldProcessId,
        fieldOperationId.current
      );
      return;
    }

    if (!_stationOperation.shipDocStatusId) {
      _createErrorMessage("95", fieldOperationId.current);
      return;
    }

    //load start date/end (loadEnDT, loadStDT) is set initially at check-in and finally at signature
    let shipDataforSave = {
      ..._shipDocData,
      checkedStatus: "X",
      checkedStDT: moment().format(),
      checkedEnDT: moment().format(),
      statusId: _stationOperation.shipDocStatusId,
      shipDocDate: moment().format(),
      startDT: moment().format(),
      weightUnitId: "LB",
      terminalSetupId: _deterSetupData.trmSetupId,
      terminalActivityinProcess: _stationOperation.terminalActivityinProcess,
      shipDocPrintOptions: shipDocPrintOptions.current,
      scaleTicketRelevant: true,
      currentStation: _stationInfo.id,
    };
    _saveShipDoc({ data: shipDataforSave }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
      setCheckinFinished(true);
    });
  }

  const confirmButtonStatus = () => {
    return false;
  };
  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-lg-3 col-sm-12 col-xs-12" id="leftPanel">
              <div className="card" style={{ maxWidth: "100%" }}>
                <div className="cardHeader">
                  <h2>Check-in</h2>
                </div>
                <div className="cardBody">
                  <div className="cardInput">
                    {leftPanelFields.map((field, index) => (
                      <FieldsReducer
                        field={mergedFieldData(field)}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-sm-12 col-xs-12" id="rightPanel">
              <div className="card card2" style={{ maxWidth: 980, margin: 0 }}>
                <div className="cardBody pt-3">
                  <div className="cardInput cardInput2">
                    {carrierFields.map((field, index) => {
                      if (keyboardFocusField) {
                        if (keyboardFocusField === field.id) {
                          return (
                            <FieldsReducer
                              field={mergedFieldData(field)}
                              key={index}
                            />
                          );
                        } else {
                          return "";
                        }
                      } else
                        return (
                          <FieldsReducer
                            field={mergedFieldData(field)}
                            key={index}
                          />
                        );
                    })}
                  </div>

                  {/* <div className="cardButton">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px 0px",
                      }}
                    >
                      <Button
                        className="w-auto"
                        style={{
                          background: "#DA1212",
                          borderColor: "#DA1212",
                        }}
                        onClick={() => {
                          navigate(_stationInfo.stationHome);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="d-grid gap-2 col-4 mx-auto mt-3">
              <Button
                className="btn btn-primary"
                style={{
                  hight: "250ev",
                  background: "#08bd55",
                  borderColor: "#08bd55",
                  fontSize: "40px",
                }}
                disabled={confirmButtonStatus()}
                onClick={confirmCheckin}
              >
                Confim Checkin
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <MessageLog
                errorMessages={[
                  ..._errorPartnerList,
                  ..._errorSaveShipDoc,
                  ..._errorShipDoc,
                  ..._errorMessages,
                  ..._stationMessages,
                ]}
                successMessages={[..._successMessages, ..._successSaveShipDoc]}
                warningMessages={[]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CheckinV6;
