import React, { useState, useEffect } from "react";
import { ReactComponent as ReactShipDocCard } from "../../Assets/NewIconsForCards/TankerIcon.svg";
import { ReactComponent as ReactOrderDashCard } from "../../Assets/NewIconsForCards/OrderIcon.svg";
import { ReactComponent as ReactSpecialProcessesIcon } from "../../Assets/NewIconsForCards/SpecialProcessesIcon.svg";
import Card from "../Components/Card";
import { ReactComponent as ReactInvDocIcon } from "../../Assets/NewIconsForCards/InvDocIcon.svg";
import { ReactComponent as InvoicesIcon } from "../../Assets/NewIconsForCards/InvoicesIcon.svg";
import { ReactComponent as FinancialDocIcon } from "../../Assets/NewIconsForCards/FinancialDocIcon.svg";
import { useUser } from "../../hooks/useUser";
const CardsArray = [
  {
    title: "Bill-Of-Lading",
    text: "",
    Icon: ReactShipDocCard,
    link: "/ShipDocDash",
    roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
  },
  {
    title: "Order",
    text: "",
    Icon: ReactOrderDashCard,
    link: "/OrderDash",
    roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
  },
  {
    title: "Special Processes",
    text: "",
    Icon: ReactSpecialProcessesIcon,
    link: "/SpecialProcesses",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Inventory Document",
    text: "",
    Icon: ReactInvDocIcon,
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Invoice",
    text: "",
    Icon: InvoicesIcon,
    link: "/InvoiceOverview",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Financial Document",
    text: "",
    Icon: FinancialDocIcon,
    link: "/FinancialDocOverview",
    roles: ["Admin", "SuperAdmin"],
  },
];

export default function Index() {
  const [_user, ,] = useUser();
  const [newCards, setNewCards] = useState([]);
  useEffect(() => {
    if (!_user.roles) {
      return CardsArray;
    }
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card?.roles?.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);

  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {newCards.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
