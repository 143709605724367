import React, { useState, useEffect } from "react";
import Card from "../Components/Card";
import { ReactComponent as ReactMaterialsCard } from "../../Assets/NewIconsForCards/Materials.svg";
import { ReactComponent as ReactPartnersCard } from "../../Assets/NewIconsForCards/Partners.svg";
import { ReactComponent as ReactOilTankCard } from "../../Assets/NewIconsForCards/OilTanks.svg";
import { ReactComponent as FinancialIcon } from "../../Assets/NewIconsForCards/FinancialIcon.svg";
import { ReactComponent as TransportationIcon } from "../../Assets/NewIconsForCards/TransportationIcon.svg";
import { ReactComponent as BoMsIcon } from "../../Assets/NewIconsForCards/BoMsIcon.svg";
import { ReactComponent as StationsIcon } from "../../Assets/NewIconsForCards/StationsIcon.svg";
import { ReactComponent as LeasesIcon } from "../../Assets/NewIconsForCards/LeasesIcon.svg";
import { ReactComponent as NewTankIcon } from "../../Assets/NewIconsForCards/NewTankIcon.svg";

import { useUser } from "../../hooks/useUser";

const CardsArray = [
  {
    title: "Materials",
    text: "",
    Icon: ReactMaterialsCard,
    link: "/MaterialOverview",
    roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
  },
  {
    title: "Partners",
    text: "",
    Icon: ReactPartnersCard,
    link: "/PartnerOverview",
    roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
  },
  {
    title: "Tanks",
    text: "",
    Icon: ReactOilTankCard,
    link: "/TankOverview",
    roles: ["Admin", "SuperAdmin", "TerminalAdmin"],
  },
  {
    title: "Financial",
    text: "",
    Icon: FinancialIcon,
    link: "/FinancialOverview",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Transportation",
    text: "",
    Icon: TransportationIcon,
    link: "/TransportationOverview",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Bills Of Materials",
    text: "",
    Icon: BoMsIcon,
    link: "/BillOfMaterialOverview",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Logistic Station",
    text: "",
    Icon: StationsIcon,
    link: "/LogisticStationOverview",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Lease",
    text: "",
    Icon: LeasesIcon,
    link: "/LeaseOverview",
    roles: ["Admin", "SuperAdmin"],
  },
  {
    title: "Tank Physical",
    text: "",
    Icon: NewTankIcon,
    link: "/TankPhysicalOverview",
    roles: ["Admin", "SuperAdmin"],
  },
];

export default function MasterData() {
  const [_user, ,] = useUser();
  const [newCards, setNewCards] = useState([]);
  useEffect(() => {
    if (!_user.roles) {
      return CardsArray;
    }
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card?.roles?.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {newCards.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
