export const outboundFields = ({ dropDowns }) => [
    {
      id: "docNumber",
      name: "docNumber",
      type: "text",
      label: "Document Number",
      displayOnly: true,
      disabled: true,
    },
    {
      id: "outboundNumber",
      name: "outboundNumber",
      type: "number",
      label: "Outbound Number",
      displayOnly: true,
      disabled: true,
    },
    {
      id: "docCategory",
      name: "docCategory",
      type: "text",
      label: "Document Category",
      tag: "select",
      options: dropDowns?.docCategories || [],
      displayOnly: true,
      disabled: true,
    },
    {
        id: "outboundTypeId",
        name: "outboundTypeId",
        type: "text",
        label: "Outbound Type",
        tag: "select",
        options: dropDowns?.outboundTypes || [],
      },
  
    {
      id: "email",
      name: "email",
      type: "text",
      label: "Email",
    },
    {
      id: "printerId",
      name: "printerId",
      type: "text",
      label: "Printer",
    },
    {
      id: "faxNumber",
      type: "text",
      name: "faxNumber",
      label: "Fax Number",
    },
    {
        id: "identifier",
        type: "text",
        name: "identifier",
        label: "Identifier",
      },
      {
        id: "identifier1",
        type: "text",
        name: "identifier1",
        label: "Identifier",
      },
      {
        id: "outboundStatusId",
        type: "text",
        name: "outboundStatusId",
        label: "Outbound Status",
        tag: "select",
        options: dropDowns?.outboundStatuses || [],
      },
      {
          id: "completedDT",
          type: "datetime",
          name: "completedDT",
          label: "Completed DT",
        },
  ];