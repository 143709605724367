
export const mainwithTempArray = (dropDowns, carriers) => [

  {
    id: "id",
    name: "id",
    type: "text",
    label: "BOL Number",
    disabled: true,
  },
  {
    id: "leaseId",
    name: "Lease",
    type: "text",
    label: "Lease",
    disabled: true,
  },
  {
    id: "driverDescriptor",
    name: "driverDescriptor",
    label: "Driver",
    disabled: true,
  },
  // {
  //   id: "carrierId",
  //   name: "carrierId",
  //   type: "text",
  //   label: "Carrier",
  //   tag: "select",
  //   options: prepareCarrierDD(carriers, dropDowns) || [],
  // },

  {
    id: "vehicleId",
    name: "vehicleId",
    type: "text",
    label: "Vehicle",
    disabled: true,
  },
  {
    id: "trailerId",
    name: "trailerId",
    type: "text",
    label: "Trailer",
    disabled: true,
  },
];

export const carrierArray = ({ dropDowns, carriers }) => [

];

export const weightArray = () => [
  {
    id: "inWeight",
    name: "inWeight",
    type: "number",
    label: "In Weight",
    tag: "inputKeyboard",
  
  },
  {
    id: "outWeight",
    name: "outWeight",
    type: "number",
    label: "Out Weight",
    tag: "inputKeyboard",
  
  },
  {
    id: "temperature",
    name: "temperature",
    type: "number",
    label: "Temperature",
    tag: "inputKeyboard",
  },  
  {
    id: "netWeight",
    name: "netWeight",
    type: "text",
    label: "Net Weight",
    disabled: true,
  },
];

function prepareCarrierDD(carriers, dropDowns) {
console.log("Carriers", carriers);
  if (typeof carriers !== "undefined" && carriers.length === 0) {
    return dropDowns?.carriers;
  }
  return carriers;
}

function prepareShiptoDD(shiptos, dropDowns) {
  if (typeof shiptos !== "undefined" && shiptos.length === 0) {
    return dropDowns?.shiptos;
  }
  return shiptos;
}
