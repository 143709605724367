var CommonFields = [
  {
    id: "nameResourceKey",
    name: "nameResourceKey",
    type: "text",
    value: "",
    label: "nameResourceKey",
    hide: "true",
    displayonly: "true",
  },
  {
    id: "lastUpdatedById",
    name: "lastUpdatedById",
    type: "text",
    value: "",
    label: "LastUpdatedById",
    displayonly: "true",
  },
  {
    id: "lastUpdatedBy",
    name: "lastUpdatedBy",
    type: "text",
    value: "",
    label: "Last Update By",
    displayonly: "true",
  },
  {
    id: "lastUpdatedOn",
    name: "lastUpdatedOn",
    type: "text",
    value: "",
    label: "Last Update On",
    displayonly: "true",
  },
];

module.exports = {
  CommonFields,
};
