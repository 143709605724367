import React, { useContext, useState } from "react";
import { MaterialContext } from "../context";
import LocationGrid from "./Grid/LocationsGrid";
import LocationDetails from "./LocationDetails/Index";
export default function Locations() {
  const { _materialData, _setMaterialData, _createErrorMessage } =
    useContext(MaterialContext);

  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const [localOperation, setLocalOperation] = useState();
  const [selectedLocation, setSelectedLocation] = useState({});

  function handleButtonClickGrid(e) {
    let selectedLocationId = e.target.dataset.id;
    let gridButtonOperation = e.target.dataset.op;
    let result;

    setLocalOperation(gridButtonOperation);

    switch (gridButtonOperation) {
      case "Display":
      case "Change":
        result = _materialData.locations.find(
          ({ locationId }) => locationId === selectedLocationId
        );
        setSelectedLocation(result);
        setShowLocationDetails(true);
        break;
      case "Delete":
        result = _materialData.locations.find(
          ({ locationId }) => locationId === selectedLocationId
        );
        //Can not delete location there is stock
        if (result.stockLevel > 0) {
          _createErrorMessage("51");
          return;
        }
        result = _materialData.locations.filter(
          (location) => location.locationId !== selectedLocationId
        );
        _setMaterialData((prev) => ({
          ...prev,
          locations: result,
        }));
        break;
      case "Create":
        setSelectedLocation({});
        setShowLocationDetails(true);
        break;
      default:
    }
  }
  if (showLocationDetails) {
    return (
      <div className="container-fluid">
        <LocationDetails
          localOperation={localOperation}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          setShowLocationDetails={setShowLocationDetails}
        />
      </div>
    );
  } else {
    return (
      <div className="container-fluid">
        <LocationGrid
          showLocationDetails={showLocationDetails}
          handleButtonClickGrid={handleButtonClickGrid}
        />
      </div>
    );
  }
}
