import React from "react";
import Modal from "react-bootstrap/Modal";
import { DataGrid } from "@mui/x-data-grid";
import Button from "react-bootstrap/Button";
export default function PartnerList({
  setShowNewPartnerAssignmentModal,
  showNewPartnerAssignmentModal,
  addPartnerRole,
  partnerList,
}) {
  const columns = [
    { field: "id", headerName: "Customer ID", width: 250 },
    { field: "partnerTypeId", headerName: "Type", width: 250 },
    { field: "name", headerName: "Name", width: 350 },
    { field: "city", headerName: "City", width: 200 },
  ];

  function handleOnSelect(selectedIds) {
    setShowNewPartnerAssignmentModal(false);
    addPartnerRole(selectedIds[0]);
  }

  return (
    <Modal
      show={showNewPartnerAssignmentModal}
      size="xl"
      onHide={() => setShowNewPartnerAssignmentModal(false)}
      dialogClassName="modal-90w modal-dialog-scrollable"
      aria-labelledby="example-custom-modal-styling-title"
      className="partnerListModal"
    >
      <Modal.Header
        closeButton
        style={{
          background: "#d4e1f5",
        }}
      >
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{
            fontWeight: 700,
          }}
        >
          Add New Partner Role
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: "50vh", width: "100%" }}>
          {partnerList?.length && (
            <DataGrid
              rows={partnerList || []}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onSelectionModelChange={(selectedId) =>
                handleOnSelect(selectedId)
              }
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="offset-11 col-1">
          <Button
            variant="danger"
            onClick={() => {
              setShowNewPartnerAssignmentModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
