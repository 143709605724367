export const ValidateTankPhysicalAdminFuncInput = (
  tankPhysical,
  _createErrorMessage
) => {
  if (!tankPhysical) {
    _createErrorMessage("110");
    return false;
  }

  return true;
};
