export const mainFieldsArray = ({ dropDowns, dataSource }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Partner ID",
    key: true,
    length: 20,
  },
  {
    id: "pin",
    name: "pin",
    type: "number",
    label: "PIN",
    length: "4",
  },
  {
    id: "code",
    name: "code",
    type: "text",
    label: "Partner Code",
  },
  {
    label: "Partner Type",
    id: "partnerTypeId",
    name: "partnerTypeId",
    tag: "select",
    options: dropDowns?.partnerTypes || [],
    type: "text ",
    editableCreateONLY: true,
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    label: "Location",
    tag: "select",
    options: dropDowns?.locations || [],
  },
];
export const mainDriverFieldsArray = ({ dropDowns, dataSource }) => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Driver ID",
    key: true,
    length: 20,
  },
  {
    id: "pin",
    name: "pin",
    type: "number",
    label: "Driver PIN",
    length: "4",
    required: true,
  },
  {
    label: "Partner Type",
    id: "partnerTypeId",
    name: "partnerTypeId",
    tag: "select",
    options: dropDowns?.partnerTypes || [],
    type: "text ",
    editableCreateONLY: true,
    required: true,
  },
  {
    id: "locationId",
    name: "locationId",
    type: "text",
    label: "Location for Driver",
    tag: "select",
    options: dropDowns?.locations || [],
    required: true,
  },
];

export const otherFieldsArray = ({ dropDowns, dataSource }) => [
  {
    id: "name",
    name: "name",
    label: "Partner Name",
  },
  {
    id: "name1",
    name: "name1",
    label: "Partner Name 1",
  },
  {
    id: "name2",
    name: "name2",
    label: "Partner Name 2",
  },
  {
    id: "externalPartnerCode",
    name: "externalPartnerCode",
    type: "text",
    label: "External Code",
  },
];
export const otherDriverFieldsArray = ({ dropDowns, dataSource }) => [
  {
    id: "name",
    name: "name",
    label: "Driver Name",
    required: true,
  },
];
