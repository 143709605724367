export const convserionDataArray = ({ dropDowns, dataSource }) => [
  {
    id: "defaultTestApiGravity",
    name: "defaultTestApiGravity",
    type: "number",
    label: "Default Test Api Gravity",
  },
  {
    id: "defaultTestSpecificGravity",
    name: "defaultTestSpecificGravity",
    type: "number",
    label: "Default Test Specific Api Gravity",
  },
  {
    id: "defaultTestDensity",
    name: "defaultTestDensity",
    type: "number",
    label: "Default Test Density",
  },
  {
    id: "defaultTestDensityUoM",
    name: "defaultTestDensityUoM",
    type: "string",
    label: "Test Density UoM",
    options: dropDowns?.densityUnits || [],
    tag: "select",
  },
  {
    id: "defaultTestTemp",
    name: "defaultTestTemp",
    type: "number",
    label: "Default Test Temp",
  },
  {
    id: "defaultTestTempUoM",
    name: "defaultTestTempUoM",
    type: "string",
    label: "Test Temp UoM",
    options: dropDowns?.tempUnits || [],
    tag: "select",
  },
];
