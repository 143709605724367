import React, { useState, useEffect } from "react";
import { useUser } from "../../../../hooks/useUser";
import { ReactComponent as ReactSulfurCard } from "../../../../Assets/NewIconsForCards/Sulfur.svg";
import { ReactComponent as ReactCokeCard } from "../../../../Assets/NewIconsForCards/PetroleumCoke.svg";
import { ReactComponent as ReactTruckScaleCard } from "../../../../Assets/NewIconsForCards/TruckScaleIcon.svg";
import Card from "../../../Components/Card";

export default function EldoCokeScaleStationHome(props) {
  const [newCards, setNewCards] = useState([]);
  const [_user, ,] = useUser();
  useEffect(() => {
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card.roles.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);

  const CardsArray = [
    {
      title: "Sulfur",
      text: "",
      Icon: ReactSulfurCard,
      link: "/ELSulfurHome",
      roles: ["Admin", "SuperAdmin", "ELCKSULLoader"],
    },
    {
      title: "Petroleum Coke",
      text: "",
      Icon: ReactCokeCard,
      link: "/TerminalActivity/CheckinV2/COKE_ELDO",
      roles: ["Admin", "SuperAdmin", "ELCKSULLoader"],
    },
    {
      title: "Scale State",
      text: "",
      Icon: ReactTruckScaleCard,
      link: "/TerminalActivity/MTScaleState",
      roles: ["Admin", "SuperAdmin"],
    },
  ];

  return (
    <div className="container-fluid ">
      <div className="mainContainer">
        <div className="overviewCardContainer">
          {newCards.map((record, i) => (
            <Card {...record} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
}
