import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useFetch } from "../../../../../hooks/useFetch";
import { useMessage } from "../../../../../hooks/UseMessage";
import { useUser } from "../../../../../hooks/useUser";
import { useStation } from "../../../../../hooks/UseStation";
import { useDD } from "../../../../../hooks/UseDD";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../../CommonComponents/GlobalConfFiles/URLs";
import { ReactComponent as OverLoadAlram } from "../../../../../Assets/SVGs/Alrams/AnimatedAlram1.svg";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../CommonComponents/Messages/MessageLog";
import HeaderV2 from "../../Header/HeaderV2";
import { LoadingScreen } from "../../../../../CommonComponents/LoadingScreen/index";
import { FlipCounter } from "../../../../../CommonComponents/FlipCounter/FlipCounter";
import TruckAnimation from "../../../../../CommonComponents/TruckAnimation/index";
import { useParams } from "react-router-dom";
import Sidebar from "./SideBar";
import { Col, Row } from "react-bootstrap";
function ScaleLoadingV3(props) {
  //This page was done to use the WebSerial API, it conatins code to read from 2 scales in was done initailly for the glendale additional
  //scale at each load rack in April 2023, but then decided not use the web API and continue using the service because the web serial api is not stable yet
  const navigate = useNavigate();
  const params = useParams();
  const shipDocId = params.shipDocId;
  const scaleId = params.scaleId;
  const fieldOperation = "SCALE_LOADINGV1";
  const [loading, setLoading] = useState(false);
  const [issuedAlrams, setIssuedAlrams] = useState([]);
  const [topLog, setTopLog] = useState({ success: false, message: "" });
  const [topLogScale, setTopLogScale] = useState({
    success: false,
    message: "",
  });
  //state to indicate loading operation is done
  const [loadingFinished, setLoadingFinished] = useState(false);
  const [contollerMeasurements, setContollerMeasurements] = useState({
    meterReading: 0,
    meterUnitId: "LB",
    controllerTemperature: 0,
    bsw: 0,
    density: 0,
    densityUnitId: "",
    lastReadingDT: "",
    message: "",
    meterReading: 0,
    rawData: "",
    temperature: 0,
    temperatureUnitId: "FAH",
    weight: 0,
    weightUnitId: "LB",
    controllerStatus: "",
  });
  const initialMeterLoadedQty = useRef(0);
  const AllReady = useRef(false);
  //needed to use useRef in order to pass the current weight to updateReading API Call
  // the "scaleMeasurements" did not contain the correct value, it only conatins the initial value once the useEffect is run
  const currentScaleReading = useRef(0);
  const currentMeterReading = useRef(0);
  const [scaleMeasurements, setScaleMeasurements] = useState({
    // meterReading: 0,
    // meterUnitId: "LB",
    // controllerTemperature: 0,
    // bsw: 0,
    // density: 0,
    // densityUnitId: "",
    // lastReadingDT: "",
    // message: "",
    // meterReading: 0,
    // rawData: "",
    // temperature: 0,
    // temperatureUnitId: "FAH",
    // weight: 0,
    // weightUnitId: "LB",
    // controllerStatus: "",
  });

  //hooks
  const [_user, ,] = useUser();

  let serviceSimulation = false;

  if (
    _user.serviceSimulation === "true" ||
    _user.serviceSimulation === "True"
  ) {
    serviceSimulation = true;
  }
  const [
    _stationInfo,
    ,
    ,
    _getStationOperation,
    _stationOperation,
    _stationMessages,
    _setStationMessages,
  ] = useStation();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
    _createSuccessMessage,
    _successMessages,
    _setSuccessMessages,
  ] = useMessage();

  let scaleApiInterval = 1000;
  let meterApiInterval = 2000;
  let scaleTestIncrement = 1000;
  let meterTestIncrement = 1000;

  if (_stationInfo.scaleApiInterval > 0) {
    scaleApiInterval = _stationInfo.scaleApiInterval;
  }

  if (_stationInfo.meterApiInterval > 0) {
    meterApiInterval = _stationInfo.meterApiInterval;
  }

  if (_stationInfo.scaleTestIncrement > 0) {
    scaleTestIncrement = _stationInfo.scaleTestIncrement;
  }
  if (_stationInfo.meterTestIncrement > 0) {
    meterTestIncrement = _stationInfo.meterTestIncrement;
  }
  const [_dd] = useDD();
  const [
    _startLoad,
    _startLoadData,
    _setStartLoadData,
    _startLoadError,
    _setStartLoadError,
    ,
    ,
    _loadingStartLoad,
  ] = useFetch(GetServiceFullURL("StartLoadingController"), {
    method: "GET",
    headers: {},
  });

  const [
    _stopLoad,
    _stopLoadData,
    _setStopLoadData,
    _stopLoadError,
    _setStopLoadError,
    ,
    ,
    _loadingStopLoad,
  ] = useFetch(GetServiceFullURL("StopLoadingController"), {
    method: "GET",
    headers: {},
  });

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    _successShipDoc,
    _setSuccessShipDoc,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" });

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDoc,
    _setErrorSaveShipDoc,
    _successSaveShipDoc,
    _setSuccessSaveShipDoc,
    _loadingSaveShipDoc,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" }, true);
  const [
    _resetController,
    ,
    ,
    _resetControlleError,
    _setResetControlleError,
    ,
    ,
    _loadingResetController,
  ] = useFetch(GetServiceFullURL("ResetController"), {}, true);

  const [_updateFieldReadings, , , , , , , ,] = useFetch(
    GetFullURL("UpdateFieldReadings"),
    { method: "POST" }
  );
  const [
    _getControllerData,
    _controllerData,
    _setControllerData,
    _controllerDataError,
    _setControllerDataError,
    _controllerDataSuccess,
    _setControllerDataSuccess,
    ,
  ] = useFetch(
    GetServiceFullURL("GetControllerData"),
    {
      method: "GET",
      headers: {},
    },
    true
  );
  const [
    _getWeight,
    ,
    ,
    _requestWeightError,
    _setRequestWeightError,
    ,
    ,
    _loadingRequestWeight,
  ] = useFetch(GetServiceFullURL("GetWeightFromScales"), {}, true);

  function controllerSimulation() {
    if (!serviceSimulation) {
      return;
    }

    setContollerMeasurements((x) => {
      let newQty = x.meterReading + meterTestIncrement;
      return { ...x, meterReading: newQty };
    });
  }
  function scaleSimulation() {
    if (!serviceSimulation) {
      return;
    }

    if (loading) {
      setScaleMeasurements((x) => {
        let newQty = x.weight + scaleTestIncrement;
        return { ...x, weight: newQty };
      });
    } else {
      if (_shipDocData.outWeightStatus === "X") {
        setScaleMeasurements((x) => {
          return { ...x, weight: _shipDocData.outWeight };
        });
      } else if (_shipDocData.intWeightStatus === "X") {
        setScaleMeasurements((x) => {
          return { ...x, weight: _shipDocData.inWeight };
        });
      } else if (scaleMeasurements.weight == 0) {
        setScaleMeasurements((x) => {
          return { ...x, weight: 25000 };
        });
      }
    }
  }
  function GetControllerData() {
    if (serviceSimulation) {
      return;
    }
    _setControllerDataError([]);
    _getControllerData({}, (response) => {
      setTopLog(() => {
        return { success: response?.success, message: response?.message };
      });
      if (response.success) {
        if (_stationInfo.meterCorrectionFactor > 0) {
          response.data.meterReading =
            response.data.meterReading * _stationInfo.meterCorrectionFactor;
        }
        setContollerMeasurements({ ...response.data });
      }
    });
  }
  function GetWeightData() {
    if (serviceSimulation) {
      return;
    }
    if (!scaleId) {
      return;
    }

    _setRequestWeightError([]);
    _getWeight(
      {},
      (response) => {
        setTopLogScale(() => {
          return { success: response?.success, message: response?.message };
        });
        if (response.success) {
          setScaleMeasurements({ ...response.data });
        }
      },
      "",
      { id: scaleId }
    );
  }
  useEffect(() => {
    //on mount read shipDoc and set current operation ID status based on the current field process id e.g  "SAP_Order" and
    //the specific operation/fields operation in the scaleloading v1
    _getShipDoc({ data: { Id: shipDocId } }, ({ data: shipDocData }) => {
      _getStationOperation(fieldOperation, shipDocData.fieldProcessId);
      initialMeterLoadedQty.current = shipDocData.currentMeterReading;
    });

    //when the component unmounts, issue the stop command
    return () => {
      issueStopCommand();
    };
  }, []);
  ///
  useEffect(() => {
    //on the mount re-set controller when service mode
    if (!serviceSimulation) {
      _resetController({}, () => {
        AllReady.current = true;
      });
    }
  }, [serviceSimulation]);

  useEffect(() => {
    //Setting the max weight from station one time
    if (_shipDocData.cutoffWeight == 0) {
      _setShipDocData((current) => {
        return { ...current, cutoffWeight: parseInt(_stationInfo.maxWeight) };
      });
    }
  });

  useEffect(() => {
    //This useEffect set the timer to read the controller data every few seconds, but
    //in simuklation there is a seperate function that creates the reading
    let contrllerDataTimer = null;

    if (serviceSimulation) {
      contrllerDataTimer = setInterval(() => {
        controllerSimulation();
      }, meterApiInterval);
    } else {
      contrllerDataTimer = setInterval(() => {
        GetControllerData();
      }, meterApiInterval);
    }

    //for controller simulation data needs to be stopped if the STOP loading
    //button is hit, so if loading = false and simulation mode is true then
    //clear interval
    if (serviceSimulation) {
      if (!loading) {
        clearInterval(contrllerDataTimer);
      }
    }

    return () => {
      clearInterval(contrllerDataTimer);
    };
  }, [loading, serviceSimulation]);

  useEffect(() => {
    //This useEffect sets a timer to read the scale data from the service using an API call
    let scaleServiceTimer = null;
    //Set the time for weight service only real mode ie not simulation mode
    if (!serviceSimulation && scaleId) {
      scaleServiceTimer = setInterval(() => {
        GetWeightData();
      }, scaleApiInterval);
    }

    //If the scale ID has not been picked then stop the call to get weight from service or simulation mode
    if (serviceSimulation) {
      clearInterval(scaleServiceTimer);
    }
    if (!scaleId) {
      clearInterval(scaleServiceTimer);
    }
    return () => {
      clearInterval(scaleServiceTimer);
    };
  }, [serviceSimulation, scaleId, , loading]);
  useEffect(() => {
    //This useEffect sets a timer to read the simulation scale data
    let scaleSimulationTimer = null;

    if (serviceSimulation) {
      scaleSimulationTimer = setInterval(() => {
        scaleSimulation();
      }, scaleApiInterval);
    }

    //If the simulatiom mode is false then stop the weight from the simulation service
    if (!serviceSimulation) {
      clearInterval(scaleSimulationTimer);
    }
    return () => {
      clearInterval(scaleSimulationTimer);
    };
  }, [
    serviceSimulation,
    _shipDocData.outWeightStatus,
    _shipDocData.intWeightStatus,
    loading,
  ]);

  useEffect(() => {
    currentScaleReading.current = scaleMeasurements.weight;
    //check for auto cut-off
    if (scaleMeasurements.weight >= _shipDocData.cutoffWeight) {
      issueStopCommand();
      upDateShipDocStopLoad();
      let foundAlram = issuedAlrams.find((x) => x === 1);
      if (!foundAlram) {
        setIssuedAlrams((current) => [...current, 1]);
      }
    }
  }, [scaleMeasurements.weight]);

  useEffect(() => {
    currentMeterReading.current = contollerMeasurements.meterReading;
  }, [contollerMeasurements.meterReading]);

  useEffect(() => {
    if (!loadingFinished) {
      return;
    }

    //Creating the next URL
    let nextUrlattribute1 = "";
    let nextUrlattribute2 = "";
    let nextUrlattribute3 = "";

    if (_stationOperation.nextUrlAttribute1) {
      try {
        nextUrlattribute1 = eval(_stationOperation.nextUrlAttribute1);
      } catch (error) {
        nextUrlattribute1 = _stationOperation.nextUrlAttribute1;
      }
    }

    if (_stationOperation.nextUrlAttribute2) {
      try {
        nextUrlattribute2 = eval(_stationOperation.nextUrlAttribute2);
      } catch (error) {
        nextUrlattribute2 = _stationOperation.nextUrlAttribute2;
      }
    }

    if (_stationOperation.nextUrlAttribute3) {
      try {
        nextUrlattribute3 = eval(_stationOperation.nextUrlAttribute3);
      } catch (error) {
        nextUrlattribute3 = _stationOperation.current.nextUrlAttribute3;
      }
    }

    navigate(
      `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
    );
  }, [loadingFinished]);

  function UpdateScaleReading() {
    //Update the backend with currebt scale reading is greater that ZERO, this should
    //account for checking the com port connection
    if (currentScaleReading.current > 0) {
      _updateFieldReadings(
        {
          data: {
            ShipDocId: shipDocId,
            ReadingType: "SCALE",
            CurrentReading: currentScaleReading.current,
            ReadingUnitId: scaleMeasurements.weightUnitId,
          },
        },
        () => {}
      );
    }
  }

  function UpdateMeterReading() {
    if (currentMeterReading.current > 0) {
      _updateFieldReadings(
        {
          data: {
            ShipDocId: shipDocId,
            ReadingType: "METER",
            CurrentReading: currentMeterReading.current,
            ReadingUnitId: "LB",
          },
        },
        () => {}
      );
    }
  }
  useEffect(() => {
    let fieldScaleReadingsTimer = null;
    let fieldMeterReadingsTimer = null;

    fieldScaleReadingsTimer = setInterval(() => {
      UpdateScaleReading();
    }, 2000);

    fieldMeterReadingsTimer = setInterval(() => {
      UpdateMeterReading();
    }, 2000);
    return () => {
      clearInterval(fieldScaleReadingsTimer);
      clearInterval(fieldMeterReadingsTimer);
    };
  }, []);

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessShipDoc([]);
        _setSuccessSaveShipDoc([]);
        _setSuccessMessages([]);
        break;
      case "error":
        _setErrorSaveShipDoc([]);
        _setErorMessages([]);
        _setErrorShipDoc([]);
        _setStartLoadError([]);
        _setStopLoadError([]);
        break;
      default:
        break;
    }
  }
  //in the check/useEffect for over-loading, we are NOT checking if the system is in loading status ie loading == true like before,
  //so after the weight reaches the max, it will keep issuing the STOP command each time the weight changes as specified in the
  //in the useEffect, the system will issue the stop command and lock the screen via the loader, in this change we are preventing
  //locking the screen when the STOP command is issued
  const operationLoading =
    _loadingShipDoc ||
    //    _loadingSaveShipDoc ||
    _loadingStartLoad;
  //    _loadingStopLoad;

  function issueStopCommand() {
    _setStopLoadError([]);
    //For simulatio  mode just set the loading flag to false
    if (serviceSimulation) {
      setLoading(false);
      return;
    }
    _stopLoad({}, (response) => {
      setTopLog(() => {
        return { success: response.success, message: response.message };
      });
      if (response.success) {
        setLoading(false);
        return;
      }
    });
  }

  function issueStartCommand() {
    _setStartLoadError([]);
    if (serviceSimulation) {
      setLoading(true);
      return;
    }

    _startLoad({}, (response) => {
      setTopLog(() => {
        return { success: response?.success, message: response?.message };
      });
      if (response.success) {
        setLoading(true);
        return;
      }
    });
  }

  const onClickStartStop = () => {
    if (!loading) {
      //When attampting to START
      setIssuedAlrams([]);
      //Before stating check if shipDoc is populated
      if (shipDocId == null || shipDocId == "") {
        _createErrorMessage("16");
        return;
      }

      if (!scaleId) {
        _createErrorMessage("137");
        return;
      }
      //Before starting check in cutt-off weight is populated
      if (
        _shipDocData.cutoffWeight == null ||
        _shipDocData.cutoffWeight === 0 ||
        _shipDocData.cutoffWeight == ""
      ) {
        _createErrorMessage("15");
        return;
      }

      if (scaleMeasurements.weight >= _shipDocData.cutoffWeight) {
        _createErrorMessage("85");
        return;
      }

      if (_shipDocData.inWeightStatus === "") {
        _createErrorMessage("71");
        return;
      }

      if (_shipDocData.inWeight <= 10000) {
        _createErrorMessage("128", 10000);
        return true;
      }
      issueStartCommand();
      upDateShipDocStartLoad();
    } else {
      //When attampting to STOP
      issueStopCommand();
      upDateShipDocStopLoad();
    }
  };

  const calculateFillPercent = () => {
    let percentLoaded = 0;
    let plannedQty = 0;
    let loadedQty = 0;
    let currentWeight = 0;

    //if max/cuttoff weight value is 0,  set loaded percent to zero becuase we can NOT calculate the loaded percentage
    if (_shipDocData.cutoffWeight === 0 || !_shipDocData.cutoffWeight) {
      return 0;
    }

    //if the in weight value is 0, this means we did not starting yet, so loaded percentage to ZERO
    if (_shipDocData.inWeight == 0) {
      return percentLoaded.toFixed(2);
    }

    //if the scale is seding data then use it as the current truck gross weight, if the scale is not sending data
    //or availabe then use the weight stored in the shipDoc header, remember this page updates the weight on the shipDoc header
    //every 2 seconds
    //if check if the scale is working just just check if the current weight is geater than the in-weight
    if (scaleMeasurements.weight > _shipDocData.inWeight) {
      currentWeight = scaleMeasurements.weight;
    } else {
      currentWeight = _shipDocData.currentScaleWeight;
    }

    plannedQty = _shipDocData.cutoffWeight - _shipDocData.inWeight;
    loadedQty = currentWeight - _shipDocData.inWeight;

    percentLoaded = (loadedQty * 100) / plannedQty;
    return percentLoaded.toFixed(2);
  };
  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    //Other way of getting value
    //let x = e.target.getAttribute("data-item");
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    //if item is populated then this is an update from item and hence need to search
    //for a particular item and update it

    _setShipDocData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const onChangeItem = (e) => {
    let item;
    let fieldTypeItem;
    const {
      target: { value, name },
    } = e;

    item = e.target.dataset.item;
    //Get field type from  from custom attribute, this used for select mainly
    fieldTypeItem = e.target.dataset.type;

    let newValue;
    switch (fieldTypeItem) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };

    let newItems = _shipDocData.shippingDocItemsComm.map((i) => {
      //Did not use === becuase '5' equals to 5
      if (i.itemNumber == item) {
        return { ...i, ...newValueObject };
      }
      return i;
    });
    _setShipDocData((prevState) => {
      return { ...prevState, shippingDocItemsComm: newItems };
    });
  };

  const upDateShipDocStartLoad = () => {
    if (_shipDocData.id != null) {
      _shipDocData.loadStDT = moment().format();
      _shipDocData.loadUnloadinProcess = true;
      _shipDocData.statusId = "LOADING";
      _setErrorSaveShipDoc([]);
      _setSuccessSaveShipDoc([]);
      _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {
        _setShipDocData(newShipDocData);
      });
    }
  };

  const upDateShipDocStopLoad = () => {
    if (_shipDocData.id != null) {
      _shipDocData.LoadEnDT = moment().format();
      _shipDocData.LoadStatus = "X";
      _shipDocData.loadUnloadinProcess = false;
      _shipDocData.statusId = "LOADEDSHDOC";
      _shipDocData.scaleId = scaleId;
      _shipDocData.currentMeterReading = currentMeterReading.current;
      _shipDocData.meterUnitId = "LB";
      _shipDocData.rawTemperature = contollerMeasurements.temperature;

      _shipDocData.currentScaleWeight = scaleMeasurements.weight;
      _shipDocData.scaleUnitId = scaleMeasurements.weightUnitId;
      _shipDocData.printerId = _stationInfo.printerId;

      _shipDocData.shippingDocItemsComm.forEach((item, index) => {
        item.currentMeterReading = currentMeterReading.current;
        item.meterUnitId = "LB";
      });
      _setErrorSaveShipDoc([]);
      _setSuccessSaveShipDoc([]);
      _saveShipDoc({ data: _shipDocData }, ({ data: newShipDocData }) => {
        _setShipDocData(newShipDocData);
      });
    }
  };

  const takeWeightButtonStatus = () => {
    // //if loading in process, we can take the weight
    if (loading) {
      return true;
    }
    return false;
  };

  const updateShipDocInWeight = (shipDocData) => {
    shipDocData.inWeightStatus = "X";
    shipDocData.inWeightStDT = moment().format();
    shipDocData.inWeightEnDT = moment().format();
    _shipDocData.scaleId = scaleId;
    shipDocData.inWeight = scaleMeasurements.weight;
    shipDocData.currentScaleWeight = scaleMeasurements.weight;
    shipDocData.scaleUnitId = scaleMeasurements.weightUnitId;
    shipDocData.weightUnitId = "LB";
    shipDocData.currentMeterReading = currentMeterReading.current;
    shipDocData.meterUnitId = "LB";

    shipDocData.statusId = "INWGHTSHDOC";
    //Update station info
    shipDocData.currentStation = _stationInfo.id;
    shipDocData.loadingStationId = _stationInfo.id;
    shipDocData.scaleStationId = _stationInfo.id;

    shipDocData.fieldDataPointId = "SCALE";
    shipDocData.printerId = _stationInfo.printerId;
    shipDocData.signatureReady = false;

    return shipDocData;
  };

  const updateShipDocOutWeight = (shipDocData) => {
    shipDocData.outWeightStatus = "X";
    shipDocData.outWeightStDT = moment().format();
    shipDocData.outWeightEnDT = moment().format();
    _shipDocData.scaleId = scaleId;
    shipDocData.outWeight = scaleMeasurements.weight;
    shipDocData.currentScaleWeight = scaleMeasurements.weight;
    shipDocData.scaleUnitId = scaleMeasurements.weightUnitId;
    shipDocData.weightUnitId = "LB";
    shipDocData.currentMeterReading = currentMeterReading.current;
    shipDocData.meterUnitId = "LB";

    shipDocData.statusId = "LOADEDSHDOC";
    shipDocData.currentStation = _stationInfo.id;
    shipDocData.loadingStationId = _stationInfo.id;
    shipDocData.scaleStationId = _stationInfo.id;

    shipDocData.fieldDataPointId = "SCALE";

    shipDocData.printerId = _stationInfo.printerId;
    shipDocData.signatureReady = true;
    shipDocData.loadUnloadinProcess = false;
    shipDocData.loadEnDT = moment().format();
    shipDocData.loadStatus = "X";
    shipDocData.rawTemperature = contollerMeasurements.temperature;

    shipDocData.shippingDocItemsComm.forEach((item, index) => {
      item.currentMeterReading = currentMeterReading.current;
      item.meterUnitId = "LB";
    });
    return shipDocData;
  };

  const takeOutWeight = () => {
    if (
      scaleMeasurements.weight === 0 ||
      scaleMeasurements.weight === undefined
    ) {
      _createErrorMessage("86");
      return;
    }

    if (loading) {
      _createErrorMessage("129");
      return;
    }

    if (scaleMeasurements.weight < 10000) {
      _createErrorMessage("130");
      return;
    }

    let changedShipDocData = _shipDocData;

    if (
      changedShipDocData.inWeight === 0 ||
      changedShipDocData.inWeight === undefined
    ) {
      _createErrorMessage("61");
      return;
    }

    let netWeight = scaleMeasurements.weight - changedShipDocData.inWeight;

    if (netWeight <= 10000) {
      _createErrorMessage("127", netWeight, 10000);
      return;
    }
    changedShipDocData = updateShipDocOutWeight(changedShipDocData);
    issueStopCommand();
    _setErrorSaveShipDoc([]);
    _setSuccessSaveShipDoc([]);
    _saveShipDoc({ data: changedShipDocData }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
      setLoadingFinished(true);
    });
  };
  const takeInWeight = () => {
    if (
      scaleMeasurements.weight === 0 ||
      scaleMeasurements.weight === undefined
    ) {
      _createErrorMessage("86");
      return;
    }

    if (_shipDocData?.outWeight > 0) {
      _createErrorMessage("133");
      return;
    }
    if (loading) {
      _createErrorMessage("129");
      return;
    }

    if (scaleMeasurements.weight < 10000) {
      _createErrorMessage("130");
      return;
    }
    if (scaleMeasurements.weight > 39000) {
      _createErrorMessage("136");
      return;
    }

    if (_shipDocData.loadStDT) {
      _createErrorMessage("134");
      return;
    }
    let changedShipDocData = _shipDocData;

    changedShipDocData = updateShipDocInWeight(changedShipDocData);
    _setErrorSaveShipDoc([]);
    _setSuccessSaveShipDoc([]);
    _saveShipDoc({ data: changedShipDocData }, ({ data: newShipDocData }) => {
      _setShipDocData(newShipDocData);
    });
  };

  const inweightButtonColor = () => {
    if (_shipDocData.inWeightStatus === "X") {
      return "#DA1212";
    }

    return "#08bd55";
  };
  const outweightButtonColor = () => {
    if (_shipDocData.outWeightStatus === "X") {
      return "#DA1212";
    }
    return "#08bd55";
  };

  const stopStartButtonColor = () => {
    if (loading) {
      return "#ec0505";
    } else {
      return "#08bd55";
    }
  };
  // const handleChangeScale = (e) => {
  //   let newValue;
  //   const {
  //     target: { value, name, id },
  //   } = e;
  //   if (_stationInfo.id == "GLND_EAST_RACK") {
  //     if (id === "Scale_1") {
  //       newValue = "Scale_3";
  //     } else {
  //       newValue = "Scale_4";
  //     }
  //   } else {
  //     newValue = id;
  //   }
  //   setSelectedScale(newValue);
  // };
  // function disableScaleSelection() {
  //   if (loading) {
  //     return true;
  //   }
  //   //if the current weight more then the in weight, lock the weight field
  //   if (scaleMeasurements.weight > _shipDocData.inWeight) {
  //     return true;
  //   }
  //   //if the in weight has been taken then unlock the scale field
  //   if (_shipDocData.inWeightStatus == "") {
  //     return false;
  //   }
  // }
  return (
    <div className="container-fluid">
      {operationLoading && <LoadingScreen />}
      <Row>
        <Col xs={6} style={{ backgroundColor: "gold" }}>
          {topLog.message}
        </Col>
        <Col xs={6} style={{ backgroundColor: "lightblue" }}>
          {topLogScale.message}
        </Col>

        <Row />
      </Row>
      <Row>
        <Col xs={12}>
          <HeaderV2 _shipDocData={_shipDocData} weightType={null} />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <h1>Scale Loading: {scaleId.replace(/^\D+/g, "")}</h1>
          <Sidebar
            _shipDocDDData={_dd.shipDoc}
            onChangeItem={onChangeItem}
            onChange={onChange}
            _shipDocData={_shipDocData}
            _stationInfo={_stationInfo}
            contollerMeasurements={contollerMeasurements}
            loading={loading}
            scaleMeasurements={scaleMeasurements}
          />
          {issuedAlrams.map((item, index) => {
            switch (item) {
              case 1:
                return (
                  <div className="row d-flex align-items-center justify-content-center">
                    <OverLoadAlram />
                    <h3> OverLoad</h3>
                  </div>
                );

              default:
              // code block
            }
          })}
        </Col>
        <Col xs={9}>
          <Row>
            <Col xs={3}></Col>
            {/* <Col xs={4}>
              <div className="justify-content-center">
                <div className="form-check mb-3" style={{ fontSize: "30px" }}>
                  <input
                    className="form-check-input radio-zoom"
                    type="radio"
                    name="ScaleName"
                    id="Scale_1"
                    disabled={disableScaleSelection()}
                    onChange={handleChangeScale}
                    checked={
                      selectedScale === "Scale_1" || selectedScale === "Scale_3"
                    }
                  />
                  <label className="form-check-label" htmlFor="SouthScale">
                    {_stationInfo.id == "GLND_WEST_RACK"
                      ? "Scale 1"
                      : "Scale 3"}
                  </label>
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="justify-content-center">
                <div className="col-4 form-check" style={{ fontSize: "30px" }}>
                  <input
                    className="form-check-input radio-zoom"
                    type="radio"
                    name="ScaleName"
                    disabled={disableScaleSelection()}
                    id="Scale_2"
                    checked={
                      selectedScale === "Scale_2" || selectedScale === "Scale_4"
                    }
                    onChange={handleChangeScale}
                  />
                  <label className="form-check-label" htmlFor="NorthScale">
                    {_stationInfo.id == "GLND_WEST_RACK"
                      ? "Scale 2"
                      : "Scale 4"}
                  </label>
                </div>
              </div>
            </Col> */}
            <Col xs={3}>
              <button
                disabled={takeWeightButtonStatus()}
                onClick={takeInWeight}
                style={{
                  height: "8rem",
                  width: "100%",
                  fontSize: 20,
                  padding: 5,
                  fontWeight: "bold",
                  background: inweightButtonColor(),
                  borderColor: "#08bd55",
                }}
              >
                Take In Weight
              </button>
            </Col>
            <Col xs={3}>
              <button
                disabled={takeWeightButtonStatus()}
                onClick={takeOutWeight}
                style={{
                  height: "8rem",
                  width: "100%",
                  fontSize: 20,
                  padding: 5,
                  fontWeight: "bold",
                  background: outweightButtonColor(),
                  borderColor: "#08bd55",
                }}
              >
                Take Out Weight
              </button>
            </Col>
          </Row>
          <Row style={{ justifyContent: "end" }}>
            <div style={{ width: "75%" }}>
              <TruckAnimation
                compartCount={1}
                activeIndex={0}
                fillPerCent={calculateFillPercent()}
              />
            </div>
          </Row>
          <Row
            style={{
              marginTop: "30px",
            }}
          >
            <Col
              xs={6}
              style={{
                left: "50%",
              }}
            >
              <FlipCounter
                number={scaleMeasurements.weight}
                className="mb-4"
                className2="mt-4"
                backgroundColorClassName="bg-info"
                //                   title="Scale"
              />
            </Col>
            <Col xs={6} style={{}}>
              <button
                onClick={onClickStartStop}
                style={{
                  width: "70%",
                  height: "6rem",
                  fontSize: 50,
                  fontWeight: "bold",
                  background: stopStartButtonColor(),
                }}
              >
                {loading ? "STOP" : "START"}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>

      <MessageLog
        errorMessages={[
          ..._errorShipDoc,
          ..._errorMessages,
          ..._errorSaveShipDoc,
          ..._startLoadError,
          ..._stopLoadError,
        ]}
        successMessages={[..._successShipDoc, ..._successMessages]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}

export default ScaleLoadingV3;
