export const partnersArray = () => [
  {
    id: "shiptoId",
    name: "shiptoId",
    label: "Shipto",
  },
  {
    id: "soldtoId",
    name: "soldtoId",
    label: "Soldto",
  },
  {
    id: "driverId",
    name: "driverId",
    label: "Driver",
  },
  {
    id: "carrierId",
    name: "carrierId",
    label: "Carrier",
  },
];