import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useFetch } from "../../../../../../hooks/useFetch";
import { useStation } from "../../../../../../hooks/UseStation";
import { useMessage } from "../../../../../../hooks/UseMessage";
import { useUser } from "../../../../../../hooks/useUser";
import {
  GetFullURL,
  GetServiceFullURL,
} from "../../../../../../CommonComponents/GlobalConfFiles/URLs";
import { ReactComponent as TruckandScale } from "../../../../../../Assets/SVGs/TruckAndScale.svg";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../../../CommonComponents/Messages/MessageLog";
import HeaderV4 from "../../../Header/HeaderV4";
import { LoadingScreen } from "src/CommonComponents/LoadingScreen/index";
import { FlipCounter } from "../../../../../../CommonComponents/FlipCounter/FlipCounter";
import TruckAnimation from "../../../../../../CommonComponents/TruckAnimation/index";
import { useParams } from "react-router-dom";
import { ValidateTakingWeight } from "../UsingServiceWeight/ValidateShipDoc";

function InOutWeight(props) {
  const params = useParams();
  const navigate = useNavigate();

  //URL parameters, shipDoc number and requsted weight type IN or OUT
  const shipDocParam = params.shipDoc;
  const WeightTypeParam = params.weightType;

  //Service Data is weight
  const [scaleMeasurements, setScaleMeasurements] = useState({
    weight: 0,
    weightUnitId: "LB",
  });

  //Toplog for single message from service
  const [topLog, setTopLog] = useState("");

  //this for message array from service
  const [serviceError, setServiceError] = useState([]);
  const [serviceSuccess, setServiceSuccess] = useState([]);

  //state to indicate scalling operation is done
  const [finishedScaling, setFinishedScaling] = useState(false);

  //the requsted weight type can be supplied by url parameters or it can be
  //determined based on shipDoc data
  const requestedWeightType = useRef();
  const fieldOperationId = useRef();

  //hooks
  const [_user, ,] = useUser();

  let serviceSimulation = false;

  if (
    _user.serviceSimulation === "True" ||
    _user.serviceSimulation === "true"
  ) {
    serviceSimulation = true;
  }

  let scaleApiInterval = 2000;
  const [
    _printScaleTicketLocalService,
    ,
    ,
    _errorPrintSclaleTicketLocalService,
    _setErrorPrintScaleTicketLocalService,
    _successPrintScaleTicketLocalService,
    _setSuccessPrintScaleTicketLocalService,
    _loadingPrintScaleTicketLocalService,
  ] = useFetch(GetServiceFullURL("PrintScaleTicketPDF"), {
    method: "POST",
    headers: {},
  });
  const [
    _stationInfo,
    _getTerminalSetup,
    _terminalSetup,
    _getStationOperation,
    _stationOperation,
    _stationMessages,
    _setStationMessages,
  ] = useStation();

  const [
    _createErrorMessage,
    _errorMessages,
    _setErorMessages,
    _createWarningMessage,
    _warningMessages,
    _setWarningMessages,
  ] = useMessage();

  const [
    _getWeight,
    ,
    ,
    _requestWeightError,
    _setRequestWeightError,
    _requestWeightSuccess,
    _setRequestWeightSuccess,
    _loadingRequestWeight,
  ] = useFetch(
    GetServiceFullURL("GetWeightNew"),
    {
      method: "POST",
    },
    true
  );

  const [
    _getShipDoc,
    _shipDocData,
    _setShipDocData,
    _errorShipDoc,
    _setErrorShipDoc,
    _successShipDoc,
    _setSuccessShipDoc,
    _loadingShipDoc,
  ] = useFetch(GetFullURL("GetShippingDoc"), { method: "POST" }, true);

  const [
    _saveShipDoc,
    ,
    ,
    _errorSaveShipDocData,
    _setErrorSaveShipDocData,
    _successSaveShipDocData,
    _setSuccessSaveShipDocData,
    _loadingSaveShipDocData,
  ] = useFetch(GetFullURL("SaveShippingDoc"), { method: "POST" }, true);

  if (_stationInfo.scaleApiInterval > 0) {
    scaleApiInterval = _stationInfo.scaleApiInterval;
  }

  useEffect(() => {
    //onMount or change of shipDoc parameter read shipDoc
    _getShipDoc({ data: { Id: shipDocParam } }, ({ data: shipDocData }) => {});
  }, [shipDocParam]);

  useEffect(() => {
    //Setting the service timer
    let scaleServiceTimer = null;
    if (serviceSimulation === true) {
      scaleServiceTimer = setInterval(() => {
        scaleSimulation();
      }, scaleApiInterval);
    } else {
      scaleServiceTimer = setInterval(() => {
        GetWeightData();
      }, scaleApiInterval);
    }

    return () => {
      clearInterval(scaleServiceTimer);
    };
  }, [serviceSimulation]);

  useEffect(() => {
    _getStationOperation(fieldOperationId.current, _shipDocData.fieldProcessId);
  }, [fieldOperationId.current, _shipDocData.fieldProcessId]);

  useEffect(() => {
    //This sets the requssted weight type, it be deduced from shipDoc data (Auto)
    //or it can be passed a parameter, also this determines the field operationId
    if (Object.keys(_shipDocData).length === 0) {
      return;
    }

    //Determine the requested weight type, this only relevant if it is auto, if it set as IN or OUT
    //by previous page then we will just take that
    if (WeightTypeParam === "AUTO") {
      _shipDocData.inWeightStatus === "X"
        ? (requestedWeightType.current = "OUT")
        : (requestedWeightType.current = "IN");
    } else {
      requestedWeightType.current = WeightTypeParam;
    }

    //Determine field operarationId based on requsted weight type
    switch (requestedWeightType.current) {
      case "IN":
        fieldOperationId.current = "INWEIGHT";
        break;
      case "OUT":
        fieldOperationId.current = "OUTWEIGHT";
        break;
      default:
        return;
    }

    //warn the user if the requested weight in IN and it has been taken
    if (
      requestedWeightType.current === "IN" &&
      _shipDocData.inWeightStatus === "X"
    ) {
      _createWarningMessage("9");
    }

    //warn the user if the requested weight in OUT and it has been taken
    if (
      requestedWeightType.current === "OUT" &&
      _shipDocData.outWeightStatus === "X"
    ) {
      _createWarningMessage("10");
    }
  }, [_shipDocData.id]);

  useEffect(() => {
    if (!finishedScaling) {
      return;
    }

    let nextUrlattribute1 = "";
    let nextUrlattribute2 = "";
    let nextUrlattribute3 = "";
    if (_stationOperation.nextUrlAttribute1) {
      try {
        let attribute1 = eval(_stationOperation.nextUrlAttribute1);
        nextUrlattribute1 = attribute1;
      } catch (error) {
        nextUrlattribute1 = _stationOperation.nextUrlAttribute1;
      }
    }

    if (_stationOperation.nextUrlAttribute2) {
      try {
        let attribute2 = eval(_stationOperation.nextUrlAttribute2);
        nextUrlattribute2 = attribute2;
      } catch (error) {
        nextUrlattribute2 = _stationOperation.nextUrlAttribute2;
      }
    }

    if (_stationOperation.nextUrlAttribute3) {
      try {
        let attribute3 = eval(_stationOperation.nextUrlAttribute3);
        nextUrlattribute3 = attribute3;
      } catch (error) {
        nextUrlattribute3 = _stationOperation.nextUrlAttribute3;
      }
    }

    //Removing the delay, 7/15/2022 per Sherwood Tulsa East, this was not user friendly
    // var leaveTimer = setTimeout(() => {
    //   navigate(
    //     `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
    //   );
    // }, 3000);
    // return () => {
    //   clearTimeout(leaveTimer);
    // };
    navigate(
      `${_stationOperation.nextUrl}/${nextUrlattribute1}/${nextUrlattribute2}/${nextUrlattribute3}`
    );
  }, [finishedScaling]);

  function clearMessages(e) {
    let type = e.target.dataset.type;

    switch (type) {
      case "success":
        _setSuccessShipDoc([]);
        _setSuccessSaveShipDocData([]);
        _setRequestWeightSuccess([]);
        setServiceSuccess([]);
        break;
      case "error":
        _setErrorSaveShipDocData([]);
        _setErrorShipDoc([]);
        _setRequestWeightError([]);
        setServiceError([]);
        _setErorMessages([]);
        break;
      case "warning":
        _setWarningMessages([]);
        break;
      default:
        break;
    }
  }
  const operationLoading = _loadingShipDoc || _loadingSaveShipDocData;

  const onClickGetWeight = () => {
    let changedShipDocData = _shipDocData;
    let weightValidationResult = ValidateTakingWeight(
      _shipDocData,
      scaleMeasurements.weight,
      requestedWeightType.current,
      _createErrorMessage
    );

    if (!weightValidationResult) {
      return;
    }

    if (!_stationOperation.fieldOperationId) {
      _createErrorMessage(
        "76",
        _shipDocData.fieldProcessId,
        fieldOperationId.current
      );
      return;
    }

    if (!_stationOperation.shipDocStatusId) {
      _createErrorMessage("95", fieldOperationId.current);
      return;
    }

    switch (requestedWeightType.current) {
      case "IN":
        changedShipDocData.inWeight = scaleMeasurements.weight;
        changedShipDocData.weightUnitId = "LB";
        changedShipDocData.inWeightStatus = "X";
        changedShipDocData.inWeightStDT = moment().format();
        changedShipDocData.inWeightEnDT = moment().format();

        changedShipDocData.statusId = _stationOperation.shipDocStatusId;

        changedShipDocData.loadUnloadinProcess =
          _stationOperation.loadUnloadinProcess;

        changedShipDocData.signatureReady = _stationOperation.signatureReady;

        changedShipDocData.terminalActivityinProcess =
          _stationOperation.terminalActivityinProcess;

        changedShipDocData.currentStation = _stationInfo.id;
        _saveShipDoc(
          { data: changedShipDocData },
          ({ data: newShipDocData }) => {
            if (newShipDocData.scaleTicketBytes) {
              if (newShipDocData.scaleTicketRelevant) {
                _printScaleTicketLocalService(
                  {
                    data: {
                      ScaleTicketBytes: newShipDocData.scaleTicketBytes,
                      ShipDoc: newShipDocData.id,
                      NumberPrintScaleTicketCopies: 1,
                    },
                  },
                  (response) => {
                    if (response.success) {
                      setServiceSuccess((current) => {
                        return [...current, response.message];
                      });
                    } else {
                      setServiceError((current) => {
                        return [...current, response.message];
                      });
                    }
                  }
                );
              }
            }

            _setShipDocData(newShipDocData);

            setFinishedScaling(true);
          }
        );
        break;
      case "OUT":
        let netWeight = Math.abs(
          scaleMeasurements.weight - changedShipDocData.inWeight
        );
        changedShipDocData.outWeight = scaleMeasurements.weight;
        changedShipDocData.weightUnitId = "LB";
        changedShipDocData.outWeightStatus = "X";
        changedShipDocData.outWeightStDT = moment().format();
        changedShipDocData.outWeightEnDT = moment().format();
        //Get four 4 things for station operation, shipDoc status, loadunloadinProcess
        //signature ready, terminal activityinporocess
        changedShipDocData.statusId = _stationOperation.shipDocStatusId;

        changedShipDocData.loadUnloadinProcess =
          _stationOperation.loadUnloadinProcess;

        changedShipDocData.signatureReady = _stationOperation.signatureReady;

        changedShipDocData.terminalActivityinProcess =
          _stationOperation.terminalActivityinProcess;

        changedShipDocData.currentStation = _stationInfo.id;

        changedShipDocData.shippingDocItemsComm.forEach((item, index) => {
          item.temperatureUnitId = "FAH";
          if (item.quantity == 0) {
            item.quantity = netWeight;
            item.unitId = "LB";
          }
        });

        _saveShipDoc(
          { data: changedShipDocData },
          ({ data: newShipDocData }) => {
            _setShipDocData(newShipDocData);
            setFinishedScaling(true);
          }
        );
        break;
      default:
        return;
    }
  };

  const calculateFillPercent = () => {
    let loadPercent = 0;
    switch (requestedWeightType.current) {
      case "OUT":
        if (_shipDocData.activityId === "INBOUND") {
          loadPercent = 0;
        } else {
          loadPercent = 100;
        }
        break;
      case "IN":
        if (_shipDocData.activityId === "INBOUND") {
          loadPercent = 100;
        } else {
          loadPercent = 0;
        }

        break;
      default:
        return;
    }

    return loadPercent.toFixed(2);
  };
  calculateFillPercent();

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function GetWeightData() {
    _setRequestWeightError([]);
    _getWeight(
      {
        data: {
          RequstedWeightType: requestedWeightType.current,
        },
      },
      (response) => {
        setTopLog(() => {
          return { success: response.success, message: response.message };
        });
        if (response.success) {
          //Set Weight
          setScaleMeasurements({ ...response.data });
        }
      }
    );
  }
  function scaleSimulation() {
    let minSimWeight = getRandomInt(23000, 25000);
    let MaxSimWeight = getRandomInt(75000, 78000);

    let simWeight;

    switch (requestedWeightType.current) {
      case "OUT":
        if (_shipDocData.activityId === "INBOUND") {
          simWeight = minSimWeight;
        } else {
          simWeight = MaxSimWeight;
        }
        break;
      case "IN":
        if (_shipDocData.activityId === "INBOUND") {
          simWeight = MaxSimWeight;
        } else {
          simWeight = minSimWeight;
        }

        break;
      default:
        return;
    }
    setScaleMeasurements({
      weight: simWeight,
    });
    return;
  }
  let error = "bg-danger";
  let success = "bg-success";
  let errorClass = `w-100 rounded-lg px-5 py-3 ${error} d-flex align-items-center justify-content-center`;
  let successClass = `w-100 rounded-lg px-5 py-1 ${success} d-flex align-items-center justify-content-center`;
  return (
    <div className="container-fluid p-0">
      {operationLoading && <LoadingScreen />}
      <div className="row px-3">
        <div className="col-12 p-0">
          <HeaderV4 _shipDocData={_shipDocData} WeightTypeParam={null} />
        </div>
      </div>

      <div className="row px-3">
        <div className="col-12 p-0">
          {topLog.success ? (
            <div className="col-12">
              <div className={successClass}>
                <span className="text-white"> {topLog.message}</span>
              </div>
            </div>
          ) : (
            <div className="col-12">
              <div className={errorClass}>
                <span className="text-white"> {topLog.message}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row align-items-center justify-content-around">
        <div className="col-8 d-flex align-items-center justify-content-center">
          <div className="" style={{ flex: 1 }}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="mx-3" style={{ width: "85%" }}>
                <TruckAnimation
                  compartCount={1}
                  activeIndex={0}
                  fillPerCent={calculateFillPercent()}
                />
              </div>
              <div className="truckWight" style={{ width: "15%" }}>
                <h2>{requestedWeightType.current} WEIGHT</h2>
              </div>
            </div>
            <div className="d-flex flex-column">
              <FlipCounter
                number={scaleMeasurements.weight}
                className="mb-5"
                className2="mt-4"
                title="Scale"
              />
              <div className="d-flex" style={{ gap: "10rem" }}>
                <button
                  className="px-5 py-3 mt-5"
                  style={{
                    hight: "100ev",
                    background: "red",
                    borderColor: "#08bd55",
                    fontSize: "40px",
                    width: "25%",
                  }}
                  onClick={() => {
                    navigate(`/TerminalActivity/StartWithOrder`);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="px-5 py-3 mt-5"
                  style={{
                    hight: "250ev",
                    background: "#08bd55",
                    borderColor: "#08bd55",
                    fontSize: "40px",
                    width: "50%",
                  }}
                  onClick={onClickGetWeight}
                >
                  Take {WeightTypeParam} Weight
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageLog
        errorMessages={[
          ..._errorShipDoc,
          ..._errorSaveShipDocData,
          ...serviceError,
          ..._requestWeightError,
          ..._errorMessages,
        ]}
        successMessages={[
          ..._successSaveShipDocData,
          ...serviceSuccess,
          ..._requestWeightSuccess,
        ]}
        warningMessages={[..._warningMessages]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}

export default InOutWeight;
