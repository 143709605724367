import React, { useContext } from "react";
import { TestMethodContext } from "../TestMethodMain/context"

export default function TopPanel() {
  const { operation, testMethodId } = useContext(TestMethodContext);

  var testMethodInfo;
  switch (operation) {
    case "Display":
      testMethodInfo = `${operation} Test Method: ${testMethodId}`;
      break;
    case "Change":
      testMethodInfo = `${operation} Test Method: ${testMethodId}`;
      break;
    case "Create":
      testMethodInfo = `${operation} New Test Method:`;
      break;
    default:
      testMethodInfo = `Test Method Info`;
      break;
  }
  return (
    <h2
      className="heading"
      style={{ marginLeft: 44, position: "relative", top: 8 }}
    >
      {testMethodInfo}
    </h2>
  );
}
