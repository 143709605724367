export const mainArray = ( {dropDowns}) => [
    {
      id: "fromShipDocDate",
      name: "fromShipDocDate",
      type: "datetime",
      label: "From ShipDoc Date",

    },
    {
      id: "toShipDocDate",
      name: "toShipDocDate",
      type: "datetime",
      label: "To ShipDoc Date",

    },
    {
        id: "locationId",
        name: "locationId",
        type: "text",
        label: "Location Id",
        options: dropDowns?.locations || [],
      tag: "select",
   
    },
  ];

  