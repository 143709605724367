import React, { useContext, useState } from "react";
import { mainDataArray,timeDataArray,valuesArray, enhancedDDInputArray } from "./fields";
import { LabTestContext } from "../context";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import TestMethodField from "../../../../CommonComponents/EnhancedFields/TestMethod/TestMethodField";
import TestMethodList from "../../../../CommonComponents/DropDowns/TestMethod/TestMethodList";
import MaterialField from "../../../../CommonComponents/EnhancedFields/Material/MaterialField";
import MaterialList from "../../../../CommonComponents/DropDowns/Material/MaterialList";

export default function HomeTab() {
  const {
    _labTestData,
    onChange,
    operation,
    dropDowns,
    labTestType,
    _getTestMethodsList,
    _testMethodsList,
    _materialList,
    _getMaterialList,
  } = useContext(LabTestContext);

  const [showTestMethodList, setShowTestMethodList] = useState(false);
  const [testMethodFieldName, setTestMethodFieldName] = useState();
  const [showMaterialList, setShowMaterialList] = useState(false);
  const [materialFieldName, setMaterialFieldName] = useState();

  const mergedFieldData = (field) => {
    let newFieldObject = {
      ...field,
      dataSource: _labTestData,
      onChange,
      disabled: field.displayOnly,
    };

    if (labTestType) {
      if (field.id === "labTestTypeId") {
        newFieldObject.disabled = true;
      }
    }

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        newFieldObject.disabled =
          newFieldObject.key ||
          newFieldObject.displayOnly ||
          newFieldObject.editableCreateONLY;
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };

  const fieldsState = {
    dataSource: _labTestData,
    onChange,
    dropDowns,
  };

  const mainDataArrayToRender = mainDataArray(fieldsState);
  const timeDataArrayToRender = timeDataArray(fieldsState);
  const valuesArrayToRender = valuesArray(fieldsState);
  const enhancedDDFieldsToRender = enhancedDDInputArray(fieldsState);

  var testMethodField = enhancedDDFieldsToRender.find(
    ({ id }) => id === "testMethodId"
  );
  var materialField = enhancedDDFieldsToRender.find(
    ({ id }) => id === "materialId"
  );

  return (
    <div className="row">
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: "auto" }}>
          <div className="cardHeader">
            <h2>Main Data</h2>
          </div>
          <div className="cardBody">
            {mainDataArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: "auto" }}>
          <div className="cardHeader">
            <h2>Time Data</h2>
          </div>
          <div className="cardBody">
            {timeDataArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: "auto" }}>
          <div className="cardHeader">
            <h2>Values</h2>
          </div>
          <div className="cardBody">
            {valuesArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: "auto" }}>
          <div className="cardHeader">
            <h2>Add</h2>
          </div>
          <div className="cardBody">
            <TestMethodField
              field={mergedFieldData(testMethodField)}
              setShowTestMethodList={setShowTestMethodList}
              _getTestMethodsList={_getTestMethodsList}
              setTestMethodFieldName={setTestMethodFieldName}
            />
            <MaterialField
              field={mergedFieldData(materialField)}
              setShowMaterialList={setShowMaterialList}
              setMaterialFieldName={setMaterialFieldName}
              _getMaterialList={_getMaterialList}
            />
          </div>
        </div>
      </div>
      <TestMethodList
        _testMethodList={_testMethodsList}
        showTestMethodList={showTestMethodList}
        setShowTestMethodList={setShowTestMethodList}
        testMethodFieldName={testMethodFieldName}
        onChange={onChange}
      />
      <MaterialList
        _materialList={_materialList}
        showMaterialList={showMaterialList}
        setShowMaterialList={setShowMaterialList}
        materialFieldName={materialFieldName}
        onChange={onChange}
      />
    </div>
  );
}
