var languages = [
  { key: "EN", Lable: "English" },
  { key: "FR", Lable: "French" },
  { key: "RU", Lable: "Russian" },
  { key: "AR", Lable: "Arabic" },
];

module.exports = {
  languages,
};
