export const itemArray = ({ dropDowns, headerData, itemData }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    type: "number",
    label: "Item",
    disabled: true,
  },
  {
    id: "materialDescriptor",
    name: "materialDescriptor",
    type: "text",
    label: "Material",
    disabled: true,
  },
  {
    id: "tankId",
    name: "tankId",
    type: "text",
    label: "Tank",
    tag: "select",
    options: tankDropdown(dropDowns, headerData, itemData),
  },
  {
    id: "weight",
    name: "weight",
    type: "number",
    label: "Weight",
    disabled: true,
  },
  {
    id: "weightUnitId",
    name: "weightUnitId",
    type: "text",
    label: "Unit",
    tag: "select",
    disabled: true,
    options: dropDowns?.qtyUnits || [],
  },
];
export const itemArrayV1 = ({ dropDowns, headerData, itemData }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    type: "number",
    label: "Item",
    disabled: true,
  },
  {
    id: "materialDescriptor",
    name: "materialDescriptor",
    type: "text",
    label: "Material",
    disabled: true,
  },
  {
    id: "weight",
    name: "weight",
    type: "number",
    label: "Weight",
    disabled: true,
  },
  {
    id: "weightUnitId",
    name: "weightUnitId",
    type: "text",
    label: "Unit",
    tag: "select",
    disabled: true,
    options: dropDowns?.qtyUnits || [],
  },
];
export const itemSulfurArray = ({ dropDowns }) => [
  {
    id: "itemNumber",
    name: "itemNumber",
    type: "number",
    label: "Item",
    disabled: true,
  },
  {
    id: "materialDescriptor",
    name: "materialDescriptor",
    type: "text",
    label: "Material",
    disabled: true,
  },
  {
    id: "weight",
    name: "weight",
    type: "number",
    label: "Weight",
    disabled: true,
  },
  {
    id: "weightUnitId",
    name: "weightUnitId",
    type: "text",
    label: "Unit",
    tag: "select",
    disabled: true,
    options: dropDowns?.qtyUnits || [],
  },
];
const tankDropdown = (dropDowns, headerData, itemData) => {
  debugger;

  //filtering tanks based on header tank farm, then item location if available, then header location
  if (headerData?.tankFarmId) {
    let tanks = dropDowns?.tanks?.filter((tank) => {
      return tank?.filter2 === headerData?.tankFarmId;
    });
    return tanks;
  } 


  if (itemData?.locationId) {
    let tanks = dropDowns?.tanks?.filter((tank) => {
      return tank?.filter1 === itemData.locationId;
    });
    return tanks;
  } 

    if (headerData?.locationId) {
      let tanks = dropDowns?.tanks?.filter((tank) => {
        return tank?.filter1 === headerData.locationId;
      });
      return tanks;
    } else return [];
  
};
