import React, { useContext, useState } from "react";
import {partnersArray} from "./Fields";
import { FinancialDocContext } from "../Contex";
import PartnerField from "../../../../CommonComponents/EnhancedFields/Partner/PartnerField";
import PartnerList from "../../../../CommonComponents/DropDowns/Partner/PartnerList";

export default function PartnerTab() {
  const {
    onChange,
    operation,
    dropDowns,
    _financialDocData,
    _partnerList,
    _getPartnerList,
  } = useContext(FinancialDocContext);


  const [showList, setShowList] = useState(false);
  const [partnerFieldName, setPartnerFieldName] = useState();
  

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _financialDocData, onChange };
    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _financialDocData,
    dropDowns,
  };

  const partnerFieldsRender = partnersArray(fieldsState);


  return (
   <>
      <div className="row">
      <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card" style={{ minHeight: 480 }}>
            <div className="cardHeader">
              <h2>Partners</h2>
            </div>
            <div className="cardBody">
              <PartnerField
                field={partnerFieldsRender}
                setShowList={setShowList}
                _getPartnerList={_getPartnerList}
                setPartnerFieldName={setPartnerFieldName}
              />
            </div>
          </div>
      </div>
      </div>
      <div className="row">
      <PartnerList
        _partnerList={_partnerList}
        showPartnerList={showList}
        setShowPartnerList={setShowList}
        partnerFieldName={partnerFieldName}
        onChange={onChange}
      />
      </div>
  </>
   
  );
}
