import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetFullURL } from "./../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import OverviewInput from "../../CommonComponents/InitialInput/OverviewInput";
import OverviewButtons from "./Components/OverviewButtons";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import ListModal from "../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../CommonComponents/CircleButton";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import cardlogo from "../../Assets/cardLogo.svg";
import { useMessage } from "../../hooks/UseMessage";

function LabTestOverview({ labTestType }) {
  let navigate = useNavigate();

  const [labTest, setLabTest] = useState();
  const [showLabTestList, setShowLabTestList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [_getLabTestsList, _labTestList, , , , , , _loadingtLabTestList] =
    useFetch(GetFullURL("LabTestList"), { method: "POST" });

  const [_getLabTestExists, , , , , , , _loadingtLabTestExist] = useFetch(
    GetFullURL("LabTestExists"),
    { method: "POST" }
  );

  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        break;
      case "error":
        _setErorMessages([]);
        break;
      case "warning":
      default:
        break;
    }
  }

  let buttonText;
  const operationLoading = _loadingtLabTestExist || _loadingtLabTestList;
  if (labTestType) {
    buttonText = `List of ${labTestType}s`;
  } else {
    buttonText = `List of Lab Test`;
  }

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                  <img src={require("../../Assets/icon/partner.png")} className="img-fluid cardLogo" alt="" />
                  </span>
                  Lab Test Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setLabTest}
                    document={labTest}
                    placeholder={`Lab Test Id here`}
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={buttonText}
                    clickHandler={() => {
                      _getLabTestsList(
                        {},
                        () => {
                          setShowLabTestList(true);
                        }
                      );
                    }}
                  />
                  <OverviewButtons
                    labTest={labTest}
                    navigate={navigate}
                    _getLabTestExists={_getLabTestExists}
                    _createErrorMessage={_createErrorMessage}
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages]}
              successMessages={[]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
            <div className="cardAlert"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setLabTest}
              dataList={_labTestList}
              showListModal={showLabTestList}
              setShowModal={setShowLabTestList}
              title="List Of LabTests"
              columns={[
                { field: "id", headerName: "Lab Test Id", width: 250 },
                { field: "productGroup", headerName: "ProductGroup", width: 250 },
                { field: "labTestTypeId", headerName: "Lab Test Type", width: 250 },
                { field: "testDate", headerName: "Test Date", width: 350 },
                { field: "testMethodId", headerName: "Test Method", width: 350 },
              ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default LabTestOverview;
