/** @format */

import React, { useContext, useState } from "react";
import { mainArray, partnerArray } from "./Fields";
import { OrderContext } from "../../Contex";
import { FieldsReducer } from "./../../../../../CommonComponents/Reducer/Index";
import PartnerField from "../../../../../CommonComponents/EnhancedFields/Partner/PartnerField";
import PartnerList from "../../../../../CommonComponents/DropDowns/Partner/PartnerList";
import Button from "react-bootstrap/Button";
import { ValidateAssigment } from "./ValidateAssigment";

function Index({ setShowNewPartnerAssignmentModal, addPartnertoOrder }) {
  const {
    dropDowns,
    _partnerList,
    _getPartnerList,
    _createErrorMessage,
    _orderData,
  } = useContext(OrderContext);

  const [showList, setShowList] = useState(false);
  const [partnerFieldName, setPartnerFieldName] = useState();
  const [localData, setLocalData] = useState({});

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: localData, onChange };

    return newFieldObject;
  };
  const fieldsState = {
    dropDowns,
  };
  const onChange = (e) => {
    let customfieldType;

    let fieldType;
    const {
      target: { value, name },
    } = e;

    let newValue;
    if (e.target.dataset) {
      customfieldType = e.target.dataset.type;
    }

    if (customfieldType) {
      fieldType = customfieldType;
    } else {
      fieldType = e.target.type;
    }

    switch (fieldType) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }

    if (newValue === "SELECT" || newValue === "select") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };

    setLocalData((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  const mainFieldRender = mainArray(fieldsState);
  const partnerFieldsRender = partnerArray(fieldsState);

  var partnerfield = partnerFieldsRender.find(({ id }) => id === "partnerId");

  return (
    <div>
      <div className="modelCard">
        <div className="cardBody">
          {mainFieldRender.map((field, index) => (
            <FieldsReducer field={mergedFieldData(field)} key={index} />
          ))}
        </div>
        <div className="cardInput">
          <PartnerField
            field={mergedFieldData(partnerfield)}
            setShowList={setShowList}
            _getPartnerList={_getPartnerList}
            setPartnerFieldName={setPartnerFieldName}
            dropDownApiPartnerType={localData.partnerRoleId || ""}
          />
        </div>
        <div
          className="customButton"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button
              variant="primary"
              onClick={() => {
                let validationAssigmentResult = ValidateAssigment(
                  localData,
                  _createErrorMessage
                );

                if (!validationAssigmentResult) {
                  setShowNewPartnerAssignmentModal(false);
                  return;
                }

                if (typeof _orderData?.orderPartnersComm === "undefined") {
                  addPartnertoOrder(localData);
                  setShowNewPartnerAssignmentModal(false);
                  return;
                }

                //check for duplicate
                const result = _orderData?.orderPartnersComm.find(
                  (x) =>
                    x.partnerRoleId === localData.partnerRoleId &&
                    x.partnerId === localData.partnerId
                );
                if (result) {
                  _createErrorMessage("32");
                } else {
                  addPartnertoOrder(localData);
                  setShowNewPartnerAssignmentModal(false);
                }
              }}
            >
              Add New Partner
            </Button>
          </div>
          <div>
            <Button
              variant="danger"
              style={{ background: "#DA1212", borderColor: "#DA1212" }}
              onClick={() => {
                setShowNewPartnerAssignmentModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>

      <PartnerList
        _partnerList={_partnerList}
        showPartnerList={showList}
        setShowPartnerList={setShowList}
        partnerFieldName={partnerFieldName}
        onChange={onChange}
      />
    </div>
  );
}

export default Index;
