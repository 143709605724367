exports.Source = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    key: "true",
    length: 12,
  },
  {
    id: "universalDescriptor",
    name: "universalDescriptor",
    type: "text",
    value: "",
    label: "Universal Descriptor",
    key: "true",
    length: 50,
  },
  {
    id: "dataTranslationTypeId",
    name: "dataTranslationTypeId",
    type: "text",
    value: "",
    label: "Data Translation Type",
    length: 12,
    ddid:"dataTranslationTypeId"
  },
  {
    id: "postingDateRule",
    name: "postingDateRule",
    type: "text",
    value: "",
    label: "PostingDateRule",
    length: 50,
  },
  {
    id: "StaticPostingDate",
    name: "StaticPostingDate",
    type: "date",
    value: "",
    label: "Static Posting Date",
  },
  {
    id: "maxTransactionAge",
    name: "maxTransactionAge",
    type: "number",
    value: "",
    label: "MaxTransactionAge",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
