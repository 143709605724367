import React, { useState, useEffect } from "react";
import { ReactComponent as ReactTerminalsCard } from "../../Assets/NewIconsForCards/Terminals.svg";
import Card from "../Components/Card";
import { useUser } from "../../hooks/useUser";

const CardsArray = [
  {
    title: "El Dorado Terminal",
    text: "",
    Icon: ReactTerminalsCard,
    link: "/TerminalActivity/EldoTerminalHome",
    roles: ["Admin", "SuperAdmin", "ELLoader"],
  },
  {
    title: "Tulsa East Terminal",
    text: "",
    Icon: ReactTerminalsCard,
    link: "/TerminalActivity/TETerminalHome",
    roles: ["Admin", "SuperAdmin", "TELoader"],
  },
  {
    title: "Woods Cross Terminal",
    text: "",
    Icon: ReactTerminalsCard,
    link: "/TerminalActivity/WXTerminalHome",
    roles: ["Admin", "SuperAdmin", "WXLoader"],
  },
  {
    title: "Glendale Terminal",
    text: "",
    Icon: ReactTerminalsCard,
    link: "/TerminalActivity/GLNTerminalHome",
    roles: ["Admin", "SuperAdmin", "GLNLoader"],
  },
];

export default function TerminalsHome() {
  const [newCards, setNewCards] = useState([]);
  const [_user, ,] = useUser();
  useEffect(() => {
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card.roles.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = _user.roles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {newCards.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
