import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TreeMenu from "react-simple-tree-menu";
import TableMaintenanceGenerator from "../../Admin/TableMaintenance/TableMaintenanceGenerator";
import { logOutUser } from "../../StateMangement/Actions/User/User";
import "../../../node_modules/react-simple-tree-menu/dist/main.css";



export default function SettingsTree() {
  const [table, setTable] = useState();
  const [tableType, setTableType] = useState();
  const dispatch = useDispatch();
  const treeData = {
    "first-level-node-1": {
      // key
      label: "Admin",
      index: 0, // decide the rendering order on the same level
      nodes: {
        "second-level-node-1": {
          label: "Jobs",
          index: 0,
          nodes: {
            "third-level-node-1": {
              label: "Background Jobs",
              table: "BackgroundJob",
              type: "System",
              index: 0,
            },
          },
        },
        "second-level-node-2": {
          label: "Basic System",
          index: 1,
          nodes: {
            "third-level-node-1": {
              label: "Global",
              table: "Global",
              type: "Config",
              index: 0,
            },
          },
        },
      },
    },
    "first-level-node-2": {
      // key
      label: "System Setting",
      index: 1, // decide the rendering order on the same level
      nodes: {
        "second-level-node-1": {
          label: "Basic Setting",
          index: 0,
          nodes: {
            "third-level-node-1": {
              label: "ImplementationUse",
              table: "ImplementationUse",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Implementation Type",
              table: "ImplementationType",
              type: "Config",
              index: 1,
            },
            "third-level-node-3": {
              label: "Implementation",
              table: "Implementation",
              type: "Config",
              index: 2,
            },
            "third-level-node-4": {
              label: "Feed",
              table: "Feed",
              type: "Config",
              index: 3,
            },
            "third-level-node-5": {
              label: "Biz Object",
              table: "BusinessObject",
              type: "Config",
              index: 4,
            },
            "third-level-node-6": {
              label: "Activity",
              table: "Activity",
              type: "Config",
              index: 5,
            },
            "third-level-node-7": {
              label: "Activity Type",
              table: "ActivityType",
              type: "Config",
              index: 6,
            },
            "third-level-node-8": {
              label: "TASGlobal",
              table: "TASGlobal",
              type: "Config",
              index: 7,
            },
          },
        },
        // "second-level-node-2": {
        //   label: "UI Settings",
        //   index: 1,
        //   nodes: {
        //     "third-level-node-1": {
        //       label: "Element",
        //       table: "Element",
        //       type: "UI_Config",
        //       index: 0,
        //     },
        //     "third-level-node-2": {
        //       label: "Component",
        //       table: "Component",
        //       type: "UI_Config",
        //       index: 1,
        //     },
        //     "third-level-node-3": {
        //       label: "View",
        //       table: "View",
        //       type: "UI_Config",
        //       index: 2,
        //     },
        //     "third-level-node-4": {
        //       label: "Component-Element",
        //       table: "Activity",
        //       type: "UI_Config",
        //       index: 3,
        //     },
        //     "third-level-node-5": {
        //       label: "View-Components",
        //       table: "ActivityType",
        //       type: "UI_Config",
        //       index: 4,
        //     },
        //   },
        // },
        "second-level-node-2": {
          label: "Admin",
          index: 1,
          nodes: {
            "third-level-node-1": {
              label: "User",
              table: "User",
              type: "System",
              index: 0,
            },
            "third-level-node-2": {
              label: "User Type",
              table: "UserType",
              type: "Config",
              index: 1,
            },
          },
        },
        "second-level-node-3": {
          label: "Internal Settings",
          index: 2,
          nodes: {
            "third-level-node-1": {
              label: "MessageClass",
              table: "MessageClass",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Message",
              table: "Message",
              type: "Config",
              index: 1,
            },
            "third-level-node-3": {
              label: "Number Ranges",
              table: "NumberRange",
              type: "Config",
              index: 2,
            },
            "third-level-node-4": {
              label: "ShipDoc Number Range",
              table: "ShipDocNumberRange",
              type: "Config",
              index: 3,
            },
            "third-level-node-5": {
              label: "Partner Number Range",
              table: "PartnerNumberRange",
              type: "Config",
              index: 4,
            },
            "third-level-node-6": {
              label: "Order Number Range",
              table: "OrderNumberRange",
              type: "Config",
              index: 5,
            },
            "third-level-node-7": {
              label: "Material Number Range",
              table: "MaterialNumberRange",
              type: "Config",
              index: 6,
            },
            "third-level-node-8": {
              label: "Inv Doc Number Range",
              table: "InvDocNumberRange",
              type: "Config",
              index: 7,
            },
            "third-level-node-9": {
              label: "Account Number Range",
              table: "AccountNumberRange",
              type: "Config",
              index: 8,
            },
            "third-level-node-10": {
              label: "Vehicle Number Range",
              table: "VehicleNumberRange",
              type: "Config",
              index: 9,
            },
            "third-level-node-11": {
              label: "Bill Of Material Number Range",
              table: "BillOfMaterialNumberRange",
              type: "Config",
              index: 10,
            },
            "third-level-node-12": {
              label: "Invoice Number Range",
              table: "InvoiceNumberRange",
              type: "Config",
              index: 11,
            },
            "third-level-node-13": {
              label: "Financial Document Number Range",
              table: "FinancialDocNumberRange",
              type: "Config",
              index: 12,
            },
            "third-level-node-14": {
              label: "Lease Number Range",
              table: "LeaseNumberRange",
              type: "Config",
              index: 13,
            },
            "third-level-node-15": {
              label: "Logistic Station Number Range",
              table: "LogisticStationNumberRange",
              type: "Config",
              index: 14,
            },
          },
        },
      },
    },
    "first-level-node-3": {
      label: "Global Settings",
      index: 2,
      nodes: {
        "second-level-node-1": {
          label: "Currency",
          table: "Currency",
          type: "Config",
          index: 0,
        },

        "second-level-node-2": {
          label: "Languages",
          table: "Language",
          type: "Config",
          index: 1,
        },
        "second-level-node-3": {
          label: "Unit Types",
          table: "UnitType",
          type: "Config",
          index: 2,
        },
        "second-level-node-4": {
          label: "ISO Units",
          table: "IsoUnit",
          type: "Config",
          index: 3,
        },
        "second-level-node-5": {
          label: "Unit",
          table: "Unit",
          type: "Config",
          index: 4,
        },
        "second-level-node-6": {
          label: "Status Type",
          table: "StatusType",
          type: "Config",
          index: 5,
        },
        "second-level-node-7": {
          label: "Status Use",
          table: "StatusUse",
          type: "Config",
          index: 6,
        },
        "second-level-node-8": {
          label: "Status Class",
          table: "StatusClass",
          type: "Config",
          index: 7,
        },
        "second-level-node-9": {
          label: "Implementation",
          table: "Implementation",
          type: "Config",
          index: 8,
        },
        "second-level-node-10": {
          label: "Implementation Type",
          table: "ImplementationType",
          type: "Config",
          index: 9,
        },
        "second-level-node-11": {
          label: "Implementation Use",
          table: "ImplementationUse",
          type: "Config",
          index: 10,
        },
        "second-level-node-12": {
          label: "Feed",
          table: "Feed",
          type: "Config",
          index: 11,
        },
        "second-level-node-13": {
          label: "Source",
          table: "Source",
          type: "Config",
          index: 12,
        },
        "second-level-node-14": {
          label: "Validation Conditions",
          table: "ValidationCondition",
          type: "Config",
          index: 13,
        },
        "second-level-node-15": {
          label: "Organization Units",
          table: "OrganizationUnit",
          type: "Config",
          index: 14,
        },
        "second-level-node-20": {
          label: "Documents",
          index: 1,
          nodes: {
            "third-level-node-1": {
              label: "Business Object Category",
              table: "BusinessObjectCategory",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Business Object",
              table: "BusinessObject",
              type: "Config",
              index: 1,
            },
            "third-level-node-3": {
              label: "Document Category",
              table: "DocumentCategory",
              type: "Config",
              index: 2,
            },
            "third-level-node-4": {
              label: "Document Type",
              table: "DocumentType",
              type: "Config",
              index: 3,
            },
            "third-level-node-5": {
              label: "Document Class",
              table: "DocumentClass",
              type: "Config",
              index: 4,
            },
          },
        },
        "second-level-node-21": {
          label: "Move Type",
          table: "MoveType",
          type: "Config",
          index: 20,
        },
        "second-level-node-22": {
          label: "Transaction Category",
          table: "TransactionCategory",
          type: "Config",
          index: 21,
        },
        "second-level-node-23": {
          label: "Transaction DateDetermination",
          table: "TransactionDateDetermination",
          type: "Config",
          index: 22,
        },
        "second-level-node-24": {
          label: "Data Origin",
          table: "DataOrigin",
          type: "Config",
          index: 23,
        },
        "second-level-node-25": {
          label: "Printer",
          table: "Printer",
          type: "Master",
          index: 24,
        },
        "second-level-node-26": {
          label: "Event",
          table: "Event",
          type: "Config",
          index: 25,
        },
        "second-level-node-27": {
          label: "BusinessObject Category",
          table: "BusinessObjectCategory",
          type: "Config",
          index: 26,
        },
        "second-level-node-28": {
          label: "Dangerous Goods Class",
          table: "DangerousGoodsClass",
          type: "Config",
          index: 27,
        },
        "second-level-node-29": {
          label: "Reports",
          index: 28,
          nodes: {
            "third-level-node-1": {
              label: "Report",
              table: "Report",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Comm Group Assigment",
              table: "CommGroupAssigment",
              type: "Config",
              index: 1,
            },
            "third-level-node-3": {
              label: "Comm Group",
              table: "CommGroup",
              type: "Config",
              index: 2,
            },
          },
        },
        "second-level-node-30": {
          label: "Data Translation Types",
          table: "DataTranslationType",
          type: "Config",
          index: 29,
        },
        "second-level-node-31": {
          label: "Data Format",
          table: "DataFormat",
          type: "Config",
          index: 30,
        },
        "second-level-node-32": {
          label: "Stage Procedure",
          table: "StageProcedure",
          type: "Config",
          index: 31,
        },
        "second-level-node-33": {
          label: "Stage Procedure Step",
          table: "StageProcedureStep",
          type: "Config",
          index: 32,
        },
        "second-level-node-34": {
          label: "Financial",
          index: 33,
          nodes: {
            "third-level-node-1": {
              label: "Account Types",
              table: "AccountType",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Financial Document Type",
              table: "FinancialDocType",
              type: "Config",
              index: 1,
            },
          },
        },
        "second-level-node-35": {
          label: "Bill Of Material Type",
          table: "BillOfMaterialType",
          type: "Config",
          index: 34,
        },
        "second-level-node-36": {
          label: "Load Bay",
          table: "LoadBay",
          type: "Config",
          index: 35,
        },
        "second-level-node-37": {
          label: "Load Area",
          table: "LoadArea",
          type: "Config",
          index: 36,
        },
        "second-level-node-38": {
          label: "Load Arm",
          table: "LoadArm",
          type: "Config",
          index: 37,
        },
        "second-level-node-39": {
          label: "Global",
          table: "Global",
          type: "Config",
          index: 38,
        },
        "second-level-node-40": {
          label: "Status Managment",
          index: 39,
          nodes: {
            "third-level-node-1": {
              label: "Shipping Document Statuses",
              index: 0,
              nodes: {
                "fourth-level-node-1": {
                  label: "Shipping Document Status",
                  table: "ShipDocStatus",
                  type: "Config",
                  index: 0,
                },
                "fourth-level-node-2": {
                  label: "Shipping Document Void Status",
                  table: "ShipDocVoidStatus",
                  type: "Config",
                  index: 1,
                },
              },
            },
            "thrid-level-node-2": {
              label: "Order Statuses",
              index: 1,
              nodes: {
                "fourth-level-node-1": {
                  label: "Order Status",
                  table: "OrderStatus",
                  type: "Config",
                  index: 0,
                },
                "fourth-level-node-2": {
                  label: "Order Void Status",
                  table: "OrderVoidStatus",
                  type: "Config",
                  index: 1,
                },
              },
            },
            "thrid-level-node-3": {
              label: "Invoice Statuses",
              index: 2,
              nodes: {
                "fourth-level-node-1": {
                  label: "Invoice Status",
                  table: "InvoiceStatus",
                  type: "Config",
                  index: 0,
                },
                "fourth-level-node-2": {
                  label: "Invoice Void Status",
                  table: "InvoiceVoidStatus",
                  type: "Config",
                  index: 1,
                },
              },
            },
            "thrid-level-node-4": {
              label: "Outbound Statuses",
              index: 2,
              nodes: {
                "fourth-level-node-1": {
                  label: "Outbound Status",
                  table: "OutboundStatus",
                  type: "Config",
                  index: 0,
                },
              },
            },
          },
        },
        "second-level-node-41": {
          label: "Outbound",
          index: 40,
          nodes: {
            "third-level-node-1": {
              label: "Transmission Method",
              table: "TransmissionMethod",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Outbound Type",
              table: "OutboundType",
              type: "Config",
              index: 1,
            },
            "third-level-node-3": {
              label: "Outbound Procedure",
              table: "OutboundProcedure",
              type: "Config",
              index: 2,
            },
            "third-level-node-4": {
              label: "Outbound Procedure Detail",
              table: "OutboundProcedureDetail",
              type: "Config",
              index: 3,
            },
          },
        },
        "second-level-node-42": {
          label: "Geography",
          index: 41,
          nodes: {
            "third-level-node-1": {
              label: "Country",
              table: "Country",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "States",
              table: "State",
              type: "Config",
              index: 1,
            },

            "second-level-node-3": {
              label: "Regions",
              table: "Region",
              type: "Config",
              index: 2,
            },
          },
        },
        "second-level-node-43": {
          label: "Physical Doc",
          index: 42,
          nodes: {
            "third-level-node-1": {
              label: "Physical Doc Type",
              table: "PhysicalDocType",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Physical Doc Number Range",
              table: "PhysicalDocNumberRange",
              type: "Config",
              index: 1,
            },
          },
        },
      },
    },
    "first-level-node-4": {
      label: "Logistics",
      index: 3,
      nodes: {
        "second-level-node-1": {
          label: "Organizational Strucure",
          index: 0,
        },
        "second-level-node-2": {
          label: "Material Master",
          index: 0,
          nodes: {
            "third-level-node-1": {
              label: "Material Type",
              table: "MaterialType",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Material Group",
              table: "MaterialGroup",
              type: "Config",
              index: 0,
            },
          },
        },
        "second-level-node-3": {
          label: "Transportation",
          index: 1,
          nodes: {
            "third-level-node-1": {
              label: "Incoterms",
              table: "Incoterm",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Mode of Transport",
              table: "TransportationMode",
              type: "Config",
              index: 1,
            },
            "third-level-node-3": {
              label: "Mode of Transport Types",
              table: "ModeOfTransportType",
              type: "Config",
              index: 2,
            },
            "third-level-node-4": {
              label: "Vehicle Type",
              table: "VehicleType",
              type: "Config",
              index: 3,
            },
            "third-level-node-5": {
              label: "Home Base",
              table: "HomeBase",
              type: "Config",
              index: 4,
            },
            "third-level-node-6": {
              label: "Fleet",
              table: "Fleet",
              type: "Config",
              index: 5,
            },
          },
        },
        "second-level-node-4": {
          label: "Partner",
          index: 4,
          nodes: {
            "third-level-node-1": {
              label: "Partner Role",
              table: "PartnerRole",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Partner Type",
              table: "PartnerType",
              type: "Config",
              index: 0,
            },
          },
        },
        "second-level-node-5": {
          label: "Storage",
          index: 5,
          nodes: {
            "third-level-node-4": {
              label: "Tank Farm",
              table: "TankFarm",
              type: "Config",
              index: 0,
            },
            "third-level-node-5": {
              label: "Tank Group",
              table: "TankGroup",
              type: "Config",
              index: 0,
            },
          },
        },
        "second-level-node-6": {
          label: "ShipDoc",
          index: 6,
          nodes: {
            "third-level-node-1": {
              label: "Shipment Document Type",
              table: "ShipDocType",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "ShipDoc Status",
              table: "ShipDocStatus",
              type: "Config",
              index: 1,
            },
            "third-level-node-3": {
              label: "Ship Doc Void Status",
              table: "ShipDocVoidStatus",
              type: "Config",
              index: 2,
            },
          },
        },
        "second-level-node-7": {
          label: "Order",
          index: 7,
          nodes: {
            "third-level-node-1": {
              label: "Order Type",
              table: "OrderType",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Order Status",
              table: "OrderStatus",
              type: "Config",
              index: 1,
            },
            "third-level-node-3": {
              label: "Order Void Status",
              table: "OrderVoidStatus",
              type: "Config",
              index: 2,
            },
          },
        },
        "second-level-node-8": {
          label: "Partner",
          index: 8,
          nodes: {
            "third-level-node-1": {
              label: "Partner Block Code",
              table: "PartnerBlockCode",
              type: "Config",
              index: 0,
            },
          },
        },
        "second-level-node-9": {
          label: "Inventory Document",
          index: 9,
          nodes: {
            "third-level-node-1": {
              label: "Inv Doc Type",
              table: "InvDocType",
              type: "Config",
              index: 0,
            },
          },
        },
        "second-level-node-9": {
          label: "Invoice",
          index: 9,
          nodes: {
            "third-level-node-1": {
              label: "Invoice Type",
              table: "InvoiceType",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Invoice Status",
              table: "InvoiceStatus",
              type: "Config",
              index: 1,
            },
            "third-level-node-3": {
              label: "Invoice Void Status",
              table: "InvoiceVoidStatus",
              type: "Config",
              index: 2,
            },
          },
        },
      },
    },
    "first-level-node-5": {
      label: "Organizational Strucure",
      index: 4,
      nodes: {
        "second-level-node-1": {
          label: "Global",
          index: 0,
          nodes: {
            "third-level-node-1": {
              label: "Company",
              table: "Company",
              type: "Config",
              index: 0,
            },
          },
        },

        "second-level-node-2": {
          label: "Logistics",
          index: 1,
          nodes: {
            "third-level-node-1": {
              label: "Location Type",
              table: "LocationType",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Location",
              table: "Location",
              type: "Config",
              index: 1,
            },
            "third-level-node-3": {
              label: "Sub Location Type",
              table: "SubLocationType",
              type: "Config",
              index: 2,
            },
            "third-level-node-4": {
              label: "Sub Location",
              table: "SubLocation",
              type: "Config",
              index: 3,
            },
          },
        },
      },
    },
    "first-level-node-6": {
      label: "Field Data",
      index: 5,
      nodes: {
        "second-level-node-1": {
          label: "Field Data Point Types",
          table: "FieldDataPointType",
          type: "Config",
          index: 0,
        },
        "second-level-node-2": {
          label: "MeasurementTypes",
          table: "MeasurementType",
          type: "Config",
          index: 1,
        },
        "second-level-node-3": {
          label: "Measurement Sources",
          table: "MeasurementSource",
          type: "Config",
          index: 2,
        },
        "second-level-node-4": {
          label: "Field Data Points",
          table: "FieldDataPoint",
          type: "Config",
          index: 3,
        },
        "second-level-node-5": {
          label: "Stations",
          table: "Station",
          type: "Config",
          index: 4,
        },
        "second-level-node-6": {
          label: "Station Assigment",
          table: "StationAssigment",
          type: "Config",
          index: 5,
        },
        "second-level-node-7": {
          label: "Field Operation",
          table: "FieldOperation",
          type: "Config",
          index: 6,
        },
        "second-level-node-8": {
          label: "Station Operations",
          table: "StationOperation",
          type: "Config",
          index: 7,
        },
        "second-level-node-9": {
          label: "Field Process",
          table: "FieldProcess",
          type: "Config",
          index: 8,
        },
        "second-level-node-10": {
          label: "Station Type",
          table: "StationType",
          type: "Config",
          index: 9,
        },
      },

      "second-level-node-2": {
        label: "Logistics",
        index: 1,
        nodes: {
          "third-level-node-2": {
            label: "Plant",
            table: "Plant",
            type: "Config",
            index: 1,
          },
        },
      },
    },
    "first-level-node-7": {
      label: "Master Data",
      index: 6,
      nodes: {
        "second-level-node-1": {
          label: "Unit Converion",
          table: "UnitConversion",
          type: "Master",
          index: 0,
        },
        "second-level-node-2": {
          label: "Partner Location",
          table: "PartnerLocation",
          type: "Master",
          index: 1,
        },
        "second-level-node-3": {
          label: "Ship Doc Events",
          table: "ShipDocEvent",
          type: "Master",
          index: 2,
        },
        "second-level-node-4": {
          label: "Current Field Data",
          table: "CurrentFieldData",
          type: "Trans",
          index: 3,
        },
        "second-level-node-5": {
          label: "Station Processing",
          table: "StationProcessing",
          type: "Trans",
          index: 4,
        },
        "second-level-node-6": {
          label: "StationTerminalSetups",
          table: "StationTerminalSetup",
          type: "Master",
          index: 5,
        },
        "second-level-node-7": {
          label: "Station",
          index: 6,
          nodes: {
            "third-level-node-1": {
              label: "Logistic Station Type",
              table: "LogisticStationType",
              type: "Config",
              index: 0,
            },
          },
        },
        "second-level-node-8": {
          label: "Lease",
          index: 7,
          nodes: {
            "third-level-node-1": {
              label: "Lease Type",
              table: "LeaseType",
              type: "Config",
              index: 0,
            },
          },
        },
        "second-level-node-9": {
          label: "Term Setup Det",
          index: 8,
          nodes: {
            "third-level-node-1": {
              label: "Deter Based Loc, MoT, Fp, Mat",
              table: "DeterLTFMTermSetup",
              type: "Master",
              index: 0,
            },
          },
        },
      },
    },
    "first-level-node-8": {
      label: "Lab System",
      index: 7,
      nodes: {
        "second-level-node-1": {
          label: "Test Method",
          index: 0,
          nodes: {
            "third-level-node-1": {
              label: "Test Method Class",
              table: "TestMethodClass",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Test Method Source",
              table: "TestMethodSource",
              type: "Config",
              index: 1,
            },
          },
        },
        "second-level-node-2": {
          label: "Product",
          index: 1,
          nodes: {
            "third-level-node-1": {
              label: "Product Group",
              table: "ProductGroup",
              type: "Config",
              index: 0,
            },
          },
        },
        "second-level-node-3": {
          label: "Lab Test",
          index: 2,
          nodes: {
            "third-level-node-1": {
              label: "Lab Test Type",
              table: "LabTestType",
              type: "Config",
              index: 0,
            },
            "third-level-node-2": {
              label: "Lab Test Number Range",
              table: "LabTestNumberRange",
              type: "Config",
              index: 1,
            },
          },
        },
      },
    },
  };

  const logOut = () => {
    dispatch(logOutUser());
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <div>
        <TreeMenu
          data={treeData}
          onClickItem={({ key, label, ...props }) => {
            if (props.table != null) {
              setTable(props.table);
              setTableType(props.type);
            }
          }}
        />
        <div >
          <button  onClick={logOut}>
            Logout
          </button>
        </div>
      </div>
      <div style={{ flex: 1, overflowX: "auto" }}>
        {table != null ? (
          <TableMaintenanceGenerator
            componentName={table}
            typeType={tableType}
          />
        ) : null}
      </div>
    </div>
  );
}
