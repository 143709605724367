const TERM_OPERATORS = ['=', '>', '<', '!=', '<=', '>=', 'in', 'not in', 'like'];
const DOMAIN_OPERATORS = ['&', '|', '!'];

export type TermOperator = '=' | '>' | '<' | '!=' | '<=' | '>=' | 'in' | 'not in' | 'like';
export type DomainOperator = '&' | '|' | '!';
export type Operator = TermOperator & DomainOperator;

// looking for: all users where (age > 30, and balance > 1000) OR (age <= 30, and balance > 5000)
//"age > 30" is Term
// if more than one term with a Term Operartor then it is domain

// Term => (column termoperator value) => the term represented as array with a fix length = 3 (0: column, 1: operator, 2: value)
// Domain => (Term DomainOperator Term, ...)

type TermElementTypes = string | number | Date | null | boolean | number[] | string[] | Date[] ;
//When defing an array, the generic <ermElementTypes> specidies all the possible types in the array
//The you can define the specific array index type via the syntax "0: string;" as long it part of generic specification above

export interface Term extends Array<TermElementTypes> { 0: string; 1: TermOperator; 2: TermElementTypes; }
export interface Domain extends Array<DomainOperator | Domain | Term> { }

/*
const t1: Term = ['username', 'like', '%khaled'];
const t2: Term = ['age', '>', 30];
const t3: Term = ['birthday', 'in', [new Date('1/1/1990'), new Date('02/02/1990')]];

const domainWithSingleTerm: Domain = [t1]; // minimum represetntation for a search domain

// A & B & (C | D)  <=/=> A & B & (C | D)
// & A B & (C | D)
// & & A B (C | D)
// & & A B | C D

const domain1: Domain = ['&', '&', ['username', 'like', '%khaled'], ['age', '>', 30], '|', ['birthday', 'in', ['1/1/1990', '02/02/1990']], ['age', '>', 10]];
const domainwithDefaultOperators: Domain = [['username', 'like', '%khaled'], ['age', '>', 30], ['birthday', 'in', ['1/1/1990', '02/02/1990']], ['age', '>', 10]];

//  ['username', 'like', '%khaled'] AND ['age', '>', 30] AND (['birthday', 'in', ['1/1/1990', '02/02/1990']] OR ['age], '>', 10])
const domain2: Domain = [t2, '|', '!', t3];

const domain3: Domain = [domain1, '|', domain2];

const str: string = toJson(domain3);
*/
interface IFilterElement
{
    type: 'operator' | 'term' | 'value' | 'domain';
    value: IFilterTerm | Operator | IFilterElement | IFilterTermValue | Array<IFilter>;
}

interface IFilterTerm
{
    left: string;
    operator: TermOperator;
    right: IFilterTermValue
}

interface IFilterTermValue
{
    type: string;
    value: TermElementTypes;
}

interface IFilter {
    type: string;
    value: Array<IFilter | IFilterElement >
}

interface INormalization {
    type: string;
    value: TermElementTypes;
}

interface IValueNormalization extends INormalization {
    valueType: string;
}

interface ITermNormalization {
    type: string;
    left: string;
    operator: TermOperator;
    right: IValueNormalization
}

export function parse(domain: Domain): void {
    throw new Error('Not Implemented!');
}

export function toJson(element: Domain): string {
    return JSON.stringify(toFilter(element));
}

export function toFilter(domain: Domain): IFilter
{
    const result: IFilter =
        {
            type: 'domain',
            value: []
        } as IFilter;

    for (const element of domain) {
        if (typeof element === 'string' && isDomainOperator(element)) {
            result.value.push({ type: 'operator', value: element as Operator } as IFilterElement);
            continue;
        }

        if (isTerm(element)) {
            result.value.push({
                type: 'term',
                value: {
                    left: element[0],
                    operator: element[1],
                    right: {
                        type: element[2] instanceof Date ? "date" : typeof element[1],
                        value: element[2] instanceof Date ? element[2].toISOString() : element[2]
                    } as IFilterTermValue
                } as IFilterTerm
            } as IFilterElement);
            continue;
        }

        if (isDomain(element)) {
            result.value.push(toFilter(element));
            continue;
        }

        throw new Error('invalid domain!');
    }

    return result;
}

// const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

// function isIsoDate(value: string) {
//     return isoDateRegex.exec(value);
// }

export function normalize(domain: Domain): Object[] {
    const result: Object[] = [];
    for (const element of domain) {
        if (typeof element === 'string' && isDomainOperator(element)) {
            result.push(normalizeOperator(element));
            continue;
        }

        if (isTerm(element)) {
            result.push(normalizeTerm(element as Term));
            continue;
        }

        if (isDomain(element)) {
            result.push(normalize(element));
            continue;
        }

        throw new Error('invalid domain!');
    }

    return result;
}

function isTerm(object: unknown): object is Term {
    const term: Term | null = object as Term;
    const array: Array<TermElementTypes> | null = object as Array<TermElementTypes>;
    return term != null && (array != null && array.length == 3 && isTermOperator(term[1]));
}

function isTermOperator(operator: unknown): operator is TermOperator {
    return typeof operator === 'string' && TERM_OPERATORS.includes(operator);
}

function isDomainOperator(operator: unknown): operator is DomainOperator {
    return typeof operator === 'string' && DOMAIN_OPERATORS.includes(operator);
}

function isDomain(object: unknown): object is Domain {
    const domain: Domain | null = object as Domain;
    return domain != null && Array.isArray(object) && domain.every((element: any) => isDomainOperator(element) || isTerm(element) || isDomain(element));
}

function normalizeTerm(term: Term): ITermNormalization {
    const normalizedTerm: ITermNormalization =
    {
        type: 'Term',
        left: term[0],
        operator: term[1],
        right: {
            type: 'termRight',
            valueType: typeof term[1],
            value: term[2]
        }
    };

    return normalizedTerm;
}

function normalizeOperator(operator: DomainOperator | TermOperator): INormalization
{
    return { type: 'operator', value: operator };
}



// user (username, age, password, balance, birthday)

// looking for: all users where (age > 30, and balance [>, =, <, ...] 1000) [or, and][(not)] (age <= 30, and balance > 5000)


