import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { LeaseContext } from "../context";
import { MuiButon } from "../../../../CommonComponents/MuiButton";

export default function LeaseOperationHeader({
  _loadingSaveLease,
  _saveLease,
}) {
  const { operation, _setLeaseData, _leaseData, setOperation } =
    useContext(LeaseContext);

  const handleSave = () => {
    _saveLease(
      { data: _leaseData },
      ({ data: NewLeaseData, success: callStatus }) => {
        if (callStatus) {
          if (operation === "Create") {
          }
          setOperation("Change");
        }
        _setLeaseData(NewLeaseData);
      }
    );
  };

  return (
    <Paper className="py-2 px-2 d-flex align-items-center justify-space-between customPaper">
      <TopPanel />
      <MuiButon
        title="Save"
        loading={_loadingSaveLease}
        onClick={handleSave}
        disabled={operation === "Display"}
      />
    </Paper>
  );
}
