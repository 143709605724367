export const mainArray = ( {dropDowns}) => [
    {
        id: "origin",
        name: "origin",
        type: "text",
        label: "Origin ID",
    },
    {
        id: "errorSource",
        name: "errorSource",
        type: "text",
        label: "ErrorSource",
    },
    {
        id: "docNumber",
        name: "docNumber",
        type: "text",
        label: "Document Number",
    },
    {
        id: "docCategory",
        name: "docCategory",
        type: "text",
        label: "Document Category",
    },
    {
        id: "messageNumber",
        name: "messageNumber",
        type: "number",
        label: "Message Number",
    },
    {
        id: "messageText",
        name: "messageText",
        type: "text",
        label: "Message Text",
    },
    {
        id: "jobId",
        name: "jobId",
        type: "number",
        label: "Job Id",
    },
    {
        id: "user",
        name: "user",
        type: "text",
        label: "User ID",
    },
    {
      id: "dateTime",
      name: "dateTime",
      type: "datetime",
      label: "Date & Time",
  },
  ];

  