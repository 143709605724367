import React, { useState, useEffect } from "react";
import { ReactComponent as ReactLoadWithOrderCard } from "../../Assets/NewIconsForCards/LoadWithOrder.svg";
import { ReactComponent as ReactLoadWithoutOrderCard } from "../../Assets/NewIconsForCards/LoadWithoutOrder.svg";
import Card from "../Components/Card";
import { useSelector } from "react-redux";

const CardsArray = [
  {
    title: "Load With Order",
    text: "",
    Icon: ReactLoadWithOrderCard,
    link: "/StartWithOrder",
    roles: ["Admin", "SuperAdmin", "Loader"],
  },
  {
    title: "Load Without Order",
    text: "",
    Icon: ReactLoadWithoutOrderCard,
    link: "/ELDOScaleOperations",
    roles: ["Admin", "SuperAdmin", "Loader"],
  },
];

export default function StartTerminalOperation() {
  const [newCards, setNewCards] = useState([]);
  const userRoles = useSelector((state) => state.User.user.roles);
  useEffect(() => {
    let newCardArray = CardsArray.filter((card) => {
      //the find method returns the whole value based on true or false
      let foundRole = card.roles.find((role) => {
        //loop at each card roles, then check if role is in user role, return boolean from includes
        let foundinUserRoles = userRoles.includes(role);
        //This is boolean is for find on line above
        return foundinUserRoles;
      });

      if (foundRole) {
        return card;
      }
    });

    setNewCards(newCardArray);
  }, []);
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {newCards.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
