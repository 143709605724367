/** @format */

import React, { useContext } from "react";
import { adminFieldsArray } from "./Fields";
import { TankContext } from "../context";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";

export default function AdminTab() {
  const { onChange, operation, dropDowns, _tankData } = useContext(TankContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _tankData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };

  const fieldsState = {
    dataSource: _tankData,
    dropDowns,
  };

  const fieldsToRender = adminFieldsArray(fieldsState);
  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="card">
            <div className="cardHeader">
              <h2>Admin Data </h2>
            </div>
            <div className="cardBody">
              {fieldsToRender.map((field, index) => (
                <FieldsReducer field={mergedFieldData(field)} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
