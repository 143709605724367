/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";
import OverviewInput from "../../../CommonComponents/InitialInput/OverviewInput";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import ListModal from "../../../CommonComponents/Modal/DropDownModal/DropDownListMUI";
import CircleButton from "../../../CommonComponents/CircleButton";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { GetFullURL } from "../../../CommonComponents/GlobalConfFiles/URLs";
import OverviewButtons from "./Components/OverviewButtons";
import cardlogo from "../../../Assets/cardLogo.svg";
import { useMessage } from "../../../hooks/UseMessage";


export default function AdminFunctionOverview(props) {

  let navigate = useNavigate();
  const [partnerParam, setPartnerParam] = useState();
  const [showPartnerList, setShowPartnerList] = useState(false);
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();

  const [
    _deletePartner,
    ,
    _setDeletePartnerData,
    _errorDeletePartnerData,
    _setErrorDeletePartnerData,
    _successDeletePartnerData,
    _setSuccessDeletePartnerData,
    _loadingDeletePartnerData,
  ] = useFetch(GetFullURL("DeletePartner"), { method: "POST" });

  const [_getPartnersList, _partnerList, , , , , , _loadingtPartnerList] =
    useFetch(GetFullURL("GetAllPartnersForDelete"), { method: "POST" });

  const [_partnerExistSoft, , , , , , , _loadingPartnerExist] = useFetch(GetFullURL("PartnerExistSoft"),{ method: "POST" });


  function clearMessages(e) {
    e.stopPropagation();
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessDeletePartnerData([]);
        break;
      case "error":
        _setErorMessages([]);
        _setErrorDeletePartnerData([]);
        break;
      case "warning":
      default:
        break;
    }
  }
  const operationLoading =  _loadingtPartnerList  || _loadingPartnerExist;

  return (
    <section className="heroCard heroPartner">
      {operationLoading && <LoadingScreen />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="cardHeader">
                <h2>
                  <span>
                    <img src={cardlogo} alt="" />
                  </span>
                  Partner Admin Overview
                </h2>
              </div>
              <div className="cardBody">
                <div className="cardInput">
                  <OverviewInput
                    setDocument={setPartnerParam}
                    document={partnerParam}
                    partnerRadius={true}
                    placeholder={`Enter or Choose Id`}
                  />
                </div>
                <div className="cardButton">
                  <CircleButton
                    buttonText={`List of Partners`}
                    clickHandler={() => {
                      _getPartnersList({},() => {setShowPartnerList(true);});                  
                    }}
                  />
                  <OverviewButtons
                    partner={partnerParam}
                    navigate={navigate}
                    _partnerExistSoft={_partnerExistSoft}
                    _createErrorMessage={_createErrorMessage}
                    _deletePartner={_deletePartner}
                  />
                </div>
              </div>
            </div>
            <MessageLog
              errorMessages={[..._errorMessages, ..._errorDeletePartnerData]}
              successMessages={[..._successDeletePartnerData]}
              warningMessages={[]}
              clearMessages={clearMessages}
            ></MessageLog>
             <div className="cardAlert">
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListModal
              setId={setPartnerParam}
              dataList={_partnerList}
              showListModal={showPartnerList}
              setShowModal={setShowPartnerList}
              title="List Of Partners"
              columns={[
                { field: 'id', headerName: 'Partner ID', width: 150 },
                { field: 'partnerTypeId', headerName: 'Partner Type', width: 150 },
                { field: 'name', headerName: 'Partner Name', width: 150 },
                { field: 'city', headerName: 'Partner City', width: 150 },
                ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

