import React from "react";
import InfoBlock from "../../../../CommonComponents/InfoBlock";

function HeaderV4({ _shipDocData }) {
  return (
    <div
      style={{
        padding: "0.5rem 1rem",
        backgroundColor: "#fff",
        boxShadow: "rgba(100 ,100 ,111 ,20%) 0px 7px 29px 0px",
        marginBottom: ".5rem",
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      <div
        className="d-flex justify-content-between"
        style={{ maxWidth: "100%", overflow: "auto", whiteSpace: "nowrap" }}
      >
        <InfoBlock title="BOL" value={_shipDocData.id} />
        <InfoBlock title="In Weight" value={_shipDocData.inWeight} />
        <InfoBlock title="Out Weight" value={_shipDocData.outWeight} />
        <InfoBlock title="Driver" value={_shipDocData.driverDescriptor} />
        <InfoBlock title="Pin" value={_shipDocData.pin} />
      </div>
    </div>
  );
}

export default HeaderV4;
