import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import TopPanel from "../../Components/TopPanel";
import { TankPhysicalContext } from "../context";
import { MuiButon } from "../../../../CommonComponents/MuiButton";

export default function TankPhysicalOperationHeader({
  _loadingSaveTankPhysical,
  _saveTankPhysical,
}) {
  const {
    operation,
    _setTankPhysicalData,
    _tankPhysicalData,
    setOperation,
    _createErrorMessage,
  } = useContext(TankPhysicalContext);

  const handleSave = () => {
    if (
      _tankPhysicalData.tankId === null ||
      _tankPhysicalData.tankId === undefined ||
      _tankPhysicalData.tankId === "" ||
      _tankPhysicalData.tankId === " " ||
      _tankPhysicalData.tankId === "select"
    ) {
      _createErrorMessage("54");
      return;
    }

    if (
      _tankPhysicalData.date === null ||
      _tankPhysicalData.date === undefined
    ) {
      _createErrorMessage("117");
      return;
    }

    if (
      _tankPhysicalData.locationId === null ||
      _tankPhysicalData.locationId === undefined
    ) {
      _createErrorMessage("117");
      return;
    }
    if (
      _tankPhysicalData.materialId === null ||
      _tankPhysicalData.materialId === undefined
    ) {
      _createErrorMessage("35");
      return;
    }
    if (_tankPhysicalData.temperature && !_tankPhysicalData.temperatureUnitId) {
      _createErrorMessage("113");
      return;
    }

    if (_tankPhysicalData.density && !_tankPhysicalData.densityUnitId) {
      _createErrorMessage("114");
      return;
    }

    if (
      _tankPhysicalData.physicalInventoryQty &&
      !_tankPhysicalData.physicalInventoryUnitId
    ) {
      _createErrorMessage("115");
      return;
    }

    if (
      !_tankPhysicalData.physicalInventoryQty &&
      !_tankPhysicalData.density &&
      !_tankPhysicalData.temperature &&
      !_tankPhysicalData.gravity
    ) {
      _createErrorMessage("119");
      return;
    }

    _saveTankPhysical(
      { data: _tankPhysicalData },
      ({ data: NewTankPhysicalData, success: callStatus }) => {
        if (callStatus) {
          if (operation === "Create") {
          }
          setOperation("Change");
        }
        _setTankPhysicalData(NewTankPhysicalData);
      }
    );
  };

  return (
    <Paper className="py-2 px-2 d-flex align-items-center justify-space-between customPaper">
      <TopPanel />
      <MuiButon
        title="Save"
        loading={_loadingSaveTankPhysical}
        onClick={handleSave}
        disabled={operation === "Display"}
      />
    </Paper>
  );
}
