export const addressFieldsArray = ({ dropDowns, dataSource }) => [
  {
    id: "streetAddress",
    name: "streetAddress",
    type: "text",
    label: "Street Address",
  },
  {
    id: "streetAddress1",
    name: "streetAddress1",
    type: "text",
    label: "Street Address 1",
  },
  {
    id: "city",
    name: "city",
    type: "text",
    label: "City",
  },
  {
    id: "countryId",
    name: "countryId",
    type: "text",
    label: "Country",
    tag: "select",
    options: dropDowns?.countries || [],
  },
  {
    id: "regionId",
    name: "state",
    type: "text",
    label: "State",
    tag: "select",
    options:
      dropDowns?.regions?.filter(
        (option) => option?.filter1 === dataSource?.["countryId"]
      ) || [],
  },
  {
    id: "postalCode",
    name: "postalCode",
    type: "text",
    label: "Postal Code",
  },
  {
    id: "splcCode",
    name: "splcCode",
    type: "text",
    label: "Splc Code",
  },
];
