/** @format */

import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/useFetch";
import Button from "react-bootstrap/Button";
import { GetFullURL } from "./../../../CommonComponents/GlobalConfFiles/URLs";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import { mainArray, syncResultsArray } from "./Fields";
import { FieldsReducer } from "../../../CommonComponents/Reducer/Index";
import { gridArray } from "./GridField";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";
import { ValidateOrderSyncInput } from "./ValidateOrderSyncInput";
import { useMessage } from "../../../hooks/UseMessage";
function OrderSync(props) {
  const [syncOrderParam, setSyncOrderParam] = useState();
  let navigate = useNavigate();

  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [
    _startOrderSync,
    _startOrderSyncData,
    ,
    _errorStartOrderSync,
    _setErrorStartOrderSync,
    _successStartOrderSync,
    _setSuccessStartOrderSync,
    _loadingStartOrderSync,
  ] = useFetch(GetFullURL("StartOrderSync"), { method: "POST" }, true);

  const [
    _getGeneralDD,
    _GeneralDDData,
    ,
    _errorGeneralDD,
    _setErrorGeneralDD,
    _successGeneralDD,
    _setSuccessGeneralDD,
    _loadingGeneralDD,
  ] = useFetch(GetFullURL("GetGeneralDropDown"), { method: "POST" });

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessStartOrderSync([]);
        _setSuccessGeneralDD([]);
        break;
      case "error":
        _setErrorStartOrderSync([]);
        _setErrorGeneralDD([]);
        _setErorMessages([]);
        break;
      default:
        break;
    }
  }

  const gridFieldsRender = gridArray();
  const operationLoading = _loadingStartOrderSync || _loadingGeneralDD;

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    //If the value is select, clear value
    if (value === "SELECT" || value === "select") {
      newValue = "";
    }
    const newValueObject = { [name]: newValue };
    setSyncOrderParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  useEffect(() => {
    _getGeneralDD({ data: { locations: true } }, ({ data }) => {});
  }, []);

  const fieldsState = {
    dropDowns: _GeneralDDData || [],
  };

  const mainFieldsToRender = mainArray(fieldsState);
  const syncResultsFieldsToRender = syncResultsArray();

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    return newFieldObject;
  };

  return (
    <>
      <section className="heroCard heroPartner h-auto">
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <div className="card">
                <div className="cardHeader">
                  <h2>Sync Order SAP</h2>
                </div>
                <div className="cardBody">
                  {mainFieldsToRender.map((field, index) => (
                    <FieldsReducer
                      field={mergedFieldData(field, syncOrderParam)}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <div className="card">
                <div className="cardHeader">
                  <h2>Order Sync Results</h2>
                </div>
                <div className="cardBody">
                  {syncResultsFieldsToRender.map((field, index) => (
                    <FieldsReducer
                      field={mergedFieldData(field, _startOrderSyncData)}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              style={{
                display: "flex",
                margin: "20px 0",
                marginRight: "10px",
              }}
            >
              <Button
                onClick={() => {
                  let validationResult = ValidateOrderSyncInput(
                    syncOrderParam,
                    _createErrorMessage
                  );

                  if (!validationResult) {
                    return;
                  }
                  _startOrderSync(
                    {
                      data: {
                        syncDate: syncOrderParam.syncDate,
                        loadDate: syncOrderParam.loadDate,
                        LocationId: syncOrderParam.locationId,
                      },
                    },
                    () => {}
                  );
                }}
              >
                Get Order From SAP
              </Button>

              <Button
                onClick={() => {
                  let navigationNextUrl = `/Home`;
                  navigate(navigationNextUrl);
                }}
                style={{
                  marginLeft: 10,
                  background: "#DA1212",
                  borderColor: "#DA1212",
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div style={{ maxWidth: "100%", overflow: "auto" }}>
                <table className="table table-striped table-bordered text-nowrap custom-table">
                  <thead>
                    <tr>
                      {/* Create Table Headings */}
                      {gridFieldsRender.map((field, i) => {
                        return <th key={i}>{field.label}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {_startOrderSyncData.ordersSyncComm?.map(
                      (dataRecord, i) => {
                        return (
                          <tr key={i}>
                            {gridFieldsRender.map((field, i1) => {
                              let augmentedField = mergedFieldData(
                                field,
                                dataRecord,
                                true
                              );
                              return (
                                <td key={i1}>
                                  <FieldsReducer
                                    field={augmentedField}
                                    key={i}
                                  ></FieldsReducer>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <MessageLog
            errorMessages={[
              ..._errorStartOrderSync,
              ..._errorGeneralDD,
              ..._errorMessages,
            ]}
            successMessages={[..._successStartOrderSync, ..._successGeneralDD]}
            clearMessages={clearMessages}
          ></MessageLog>
        </div>
      </section>
    </>
  );
}

export default OrderSync;
