export const mainArray = ({ shipDoc, tankDD, onChangeItem, onChange, controllerData }) => {
  let data = [];
  data = shipDoc?.shippingDocItemsComm ?? [];

  return [
    {
      id: "materialDescriptor",
      name: "materialDescriptor",
      type: "text",
      label: "Product",
      dataSource: data[0] || "",
      disabled: true,
    },
    {
      id: "tankId",
      name: "tankId",
      type: "text",
      label: "Tank",
      tag: "select",
      dataSource: data[0] || "",
      options: tankDD,
      onChange: onChangeItem,
    },
    {
      id: "scaleId",
      name: "scaleId",
      type: "text",
      label: "Scale",
      tag: "select",
      dataSource: shipDoc || {},
      options: [{value:"RightScale", label:"Right Scale"},{value:"LeftScale", label:"Left Scale"}],
      onChange: onChange,
    },
    {
      id: "A10",
      name: "A10",
      type: "text",
      label: "Last Command",
      dataSource: controllerData,
      disabled: true,
    },
    {
      id: "A4",
      name: "A4",
      type: "number",
      label: "Temp",
      dataSource: controllerData,
      disabled: true,
    },
    {
      id: "A0",
      name: "A0",
      type: "number",
      label: "Meter",
      dataSource: controllerData,
      disabled: true,
    },
  ];
};
