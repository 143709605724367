export const conversionGridFields = [
  {
    dataField: "unitConversionId",
    label: "Unit Conversion Id",
  },

  {
    dataField: "nameLocalizedText",
    label: "Name",
  },

  {
    dataField: "fromUnit",
    label: "From Unit",
  },

  {
    dataField: "toUnit",
    label: "To Unit",
  },

  {
    dataField: "factor",
    label: "Factor",
  },

  {
    dataField: "numerator",
    label: "Numerator",
  },
  {
    dataField: "denominator",
    label: "Denominator",
  },

  {
    dataField: "createdBy",
    label: "Created By",
  },
  {
    dataField: "createdOn",
    label: "Created On",
  },
  {
    dataField: "modifiedBy",
    label: "Modified By",
  },
  {
    dataField: "modifiedOn",
    label: "Modified On",
  },
  {
    dataField: "recordActivity",
    label: "RecordActivity",
  },
];
