import React, { useContext } from "react";
import { BillOfMaterialContext } from "../BillOfMaterialMain/Contex";

export default function TopPanel() {
  const { operation, billOfMaterialId } = useContext(BillOfMaterialContext);

  var billOfMaterialInfo;
  switch (operation) {
    case "Display":
      billOfMaterialInfo = `${operation} Bill Of Material: ${billOfMaterialId}`;
      break;
    case "Change":
      billOfMaterialInfo = `${operation} Bill Of Material: ${billOfMaterialId}`;
      break;
    case "Create":
      billOfMaterialInfo = `${operation} New Bill Of Material:`;
      break;
    default:
      billOfMaterialInfo = `Bill Of Material Info`;
      break;
  }
  return (
    <h2
      className="heading"
      style={{ marginLeft: 44, position: "relative", top: 8 }}
    >
      {billOfMaterialInfo}
    </h2>
  );
}
