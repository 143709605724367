export const mainDataArray = () => [
  {
    id: "referenceDoc",
    name: "referenceDoc",
    type: "text",
    label: "Order Num",
  },
  {
    id: "shipDocDate",
    name: "shipDocDate",
    type: "text",
    label: "Date",
  },
  {
    id: "purchaseOrder",
    name: "purchaseOrder",
    type: "text",
    label: "Purchase Order",
  },
];
export const carrierArray = () => [
  {
    id: "carrierDescriptor",
    name: "carrierDescriptor",
    type: "text",
    label: "Carrier",
  },
  {
    id: "shortText",
    name: "shortText",
    type: "text",
    label: "shortText",
  },
];
export const destinationArray = () => [
  {
    id: "soldtoDescriptor",
    name: "soldtoDescriptor",
    type: "text",
    label: "Soldto",
  },
  {
    id: "shiptoDescriptor",
    name: "shiptoDescriptor",
    type: "text",
    label: "Shipto",
  },
];
