import React from "react";
import GridAction from "./GridAction";
export default function GridBody({
  fields,
  data,
  ActionColumn,
  handleButtonClickGrid,
}) {
  return (
    <tbody>
      {data.map((dataRecord, i) => {
        return (
          <tr key={i}>
            {fields.map((field, i1) => {
              return <td key={i1}>{dataRecord[field.id]}</td>;
            })}
            {ActionColumn && (
              <td offset-2>
                <GridAction
                  handleButtonClickGrid={handleButtonClickGrid}
                  operation="Display"
                  dataRecord={dataRecord}
                />
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  );
}
