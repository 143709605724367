import React, { useState , useEffect } from "react";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import { mainArray } from "./Fields";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { useNavigate } from "react-router-dom";
import { FieldsReducer } from "../../CommonComponents/Reducer/Index";
import { useMessage } from "../../hooks/UseMessage";
import Pluralize from 'pluralize';



export default function ClearCahe(props) {
  let navigate = useNavigate();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [clearCacheParam, setClearCacheParam] = useState();
 


  const [
    _clearCache,
    ,
    ,
    _errorClearCache,
    _setErrorClearCache,
    _successClearCache,
    _setSuccessClearCache,
    _loadingClearCache,
  ] = useFetch(GetFullURL("ClearCahce"), { method: "POST" });

  const [
    _getGeneralDD,
    _GeneralDDData,
    ,
    _errorGeneralDD,
    _setErrorGeneralDD,
    _successGeneralDD,
    _setSuccessGeneralDD,
    _loadingGeneralDD,
  ] = useFetch(GetFullURL("GetGeneralDropDown"), { method: "POST" });

  const operationLoading = _loadingClearCache ;

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setClearCacheParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessClearCache([]);
        _setSuccessGeneralDD([]);
        break;
      case "error":
        _setErorMessages([]);
        _setErrorGeneralDD([]);
        _setErrorClearCache([]);
        break;
      default:
        break;
    }
  }


  useEffect(() => {
    _getGeneralDD({ data: { tablesInCache: true } });
  
  }, []);

  const fieldsState = {
   
    dropDowns: _GeneralDDData|| [],
  };

  const mainFieldsToRender = mainArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    return newFieldObject;
  };



  let trimedTableName;
  let pluralizedTableName;

  if(clearCacheParam?.tableName && clearCacheParam?.tableName != 'select'){
    trimedTableName =  clearCacheParam?.tableName.replaceAll(' ','');
    pluralizedTableName = Pluralize(trimedTableName);
}
  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <div className="cardHeader">
                  <h2>Cache Clear</h2>
                </div>
                <div className="cardBody">
                {mainFieldsToRender.map((field, index) => (
                    <FieldsReducer
                      field={mergedFieldData(field, clearCacheParam)}
                      key={index}
                    />
                  ))}
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      style={{
                        width: "32%",
                      }}
                      onClick={() => {
                        if(clearCacheParam?.tableName == "select"){
                          clearCacheParam.tableName = null;
                        }
                        _clearCache(
                            {
                              data: {
                                Id: pluralizedTableName,
                              },
                            },
                          );
                      }}
                    >
                      Clear Cache
                    </Button>
                    <Button
                      onClick={() => {
                        let navigationNextUrl = `/BasicSystem`;
                        navigate(navigationNextUrl);
                      }}
                      style={{
                        width: "32%",
                        background: "#DA1212",
                        borderColor: "#DA1212",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <MessageLog
                errorMessages={[..._errorClearCache,..._errorMessages, ..._errorGeneralDD]}
                successMessages={[..._successClearCache,..._successGeneralDD]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
