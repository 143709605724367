export const mainArray = () => [
  {
    id: "id",
    name: "id",
    type: "text",
    label: "Order Num",
    disabled: true,
  },
  {
    id: "shipDocDateFormatted",
    name: "shipDocDateFormatted",
    type: "text",
    label: "Date",
    disabled: true,
  },
  {
    id: "driverDescriptor",
    name: "driverDescriptor",
    type: "text",
    label: "Driver",
    disabled: true,
  },
];
export const carrierArray = () => [
  {
    id: "carrierDescriptor",
    name: "carrierDescriptor",
    type: "text",
    label: "Carrier",
    disabled: true,
  },
  {
    id: "vehicleId",
    name: "vehicleId",
    type: "text",
    label: "Vehicle",
    disabled: true,
  },
  {
    id: "trailerId",
    name: "trailerId",
    type: "text",
    label: "Trailer",
    disabled: true,
  },
];
export const destinationArray = () => [
  {
    id: "purchaseOrder",
    name: "purchaseOrder",
    type: "text",
    label: "Purchase Order",
    disabled: true,
  },
  {
    id: "shiptoDescriptor",
    name: "shiptoDescriptor",
    type: "text",
    label: "Shipto",
    disabled: true,
  },
  {
    id: "shiptoName2",
    name: "shiptoName2",
    type: "text",
    label: "Shipto Name2",
    disabled: true,
  },
];
export const crudeArray = () => [
  {
    id: "ref1",
    name: "ref1",
    type: "text",
    label: "Lease Info",
    disabled: true,
  },

];
