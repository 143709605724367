export const mainArray = ({ shipDoc, tankDD, onChangeItem }) => {
  let data = [];
  data = shipDoc?.shippingDocItemsComm ?? [];

  return [
    {
      id: "materialDescriptor",
      name: "materialDescriptor",
      type: "text",
      label: "Product",
      dataSource: data[0] || "",
      disabled: true,
    },
    {
      id: "tankId",
      name: "tankId",
      type: "text",
      label: "Tank",
      tag: "select",
      dataSource: data[0] || "",
      options: tankDD,
      onChange: onChangeItem,
    },
  ];
};
