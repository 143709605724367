import React from "react";
import Button from '@mui/material/Button';
import { ValidateAccountInput } from "./../ValidateAccountInput";
const btnsArray = [
  { buttonOperation: "Display" },
  { buttonOperation: "Change" },
  { buttonOperation: "Create" },
];

export default function OverviewButtons({
  account,
  navigate,
  _getAccountExists,
  _createErrorMessage,
}) {
  const onClick = (buttonOperation, noValidation, navPath) => {
    let navigationUrl;
    if (buttonOperation === "Create") {
      navigationUrl = `/Account/Create`;
      navigate(navigationUrl);
      return;
    }

    let validationAccountResult = ValidateAccountInput(
      account,
      _createErrorMessage
    );

    if (!validationAccountResult) {
      return;
    }

    _getAccountExists({ data: { Id: account } }, ({ data: isExist }) => {
      if (!isExist) {
        _createErrorMessage("59");
        return;
      }
      const navigationUrl = `/Account/${account}/${buttonOperation}`;
      navigate(navigationUrl);
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ buttonOperation, noValidation, navPath }, i) => (
        <div style={{ width: "48%", marginBottom: 15 }} key={i}>
          <Button
            className="btn btn-primary w-100"
            onClick={() => onClick(buttonOperation, noValidation, navPath)}
          >
            {buttonOperation}
          </Button>
        </div>
      ))}
    </div>
  );
}
