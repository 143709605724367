
export const ValidateTestMethodAdminFuncInput = (testMethod, _createErrorMessage) => {

  if (!testMethod) {
    _createErrorMessage("106");
    return false;
  }

  return true;
};
