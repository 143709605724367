exports.User = [
  {
    id: "id",
    name: "id",
    type: "text",
    value: "",
    label: "Id",
    length: 50,
    key: "true",
  },
  {
    id: "firstName",
    name: "firstName",
    type: "text",
    value: "",
    label: "First Name",
    length: 50,
  },
  {
    id: "lastName",
    name: "lastName",
    type: "text",
    value: "",
    label: "Last Name",
    length: 50,
  },
  {
    id: "password",
    name: "password",
    type: "text",
    value: "",
    label: "Password",
    length: 50,
  },
  {
    id: "preferredLanguage",
    name: "preferredLanguage",
    type: "text",
    value: "",
    label: "PreferredLanguage",
    length: 2,
    ddid: "LanguageId",
  },
  {
    id: "userTypeId",
    name: "userTypeId",
    type: "text",
    value: "",
    label: "User Type",
    ddid: "userTypeId",
  },
  {
    id: "defaultUrl",
    name: "defaultUrl",
    type: "text",
    value: "",
    label: "Default Url",
    length: 200,
  },
  {
    id: "stationId",
    name: "stationId",
    type: "text",
    label: "Station",
  },
  {
    id: "userTypeId",
    name: "userTypeId",
    type: "text",
    value: "",
    label: "UserTypeId",
  },
  {
    id: "defaultUrl",
    name: "defaultUrl",
    type: "text",
    value: "",
    label: "Default Url",
  },
  {
    id: "serviceSimulation",
    name: "service Simulation",
    type: "boolean",
    value: "",
    label: "Service Simulation",
  },
  {
    id: "qisableKeyboard",
    name: "disableKeyboard",
    type: "boolean",
    value: "",
    label: "Disable Keyboard",
  },
  {
    id: "active",
    name: "active",
    type: "boolean",
    label: "Active",
  },
  {
    id: "validFromDT",
    name: "validFromDT",
    type: "date",
    label: "Valid From",
    value: "",
  },
  {
    id: "validToDT",
    name: "validToDT",
    type: "date",
    label: "Valid To",
    value: " ",
  },
  {
    id: "lastLoginDT",
    name: "lastLoginDT",
    type: "datetime",
    label: "Last Login",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    displayonly: "true",
    value: " ",
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "ModifiedOn",
    displayonly: "true",
    value: " ",
  },
];
