import React from "react";
import { useNavigate } from "react-router-dom";

export default function Card({ title, text, Icon, link }) {
  const navigate = useNavigate();
  return (
    <div
      className="overviewCustomCard"
      onClick={() => {
        navigate(link);
      }}
    >
      <div className="mt-5">
        <h5>{title}</h5>
        <p>{text}</p>
      </div>
      <Icon className="cardSvg" />
    </div>
  );
}
