import React, { useState } from "react";
import { GetFullURL } from "../../CommonComponents/GlobalConfFiles/URLs";
import { useFetch } from "../../hooks/useFetch";
import { mainArray } from "./Fields";
import { LoadingScreen } from "../../CommonComponents/LoadingScreen/index";
import Button from "react-bootstrap/Button";
import MessageLog from "../../CommonComponents/Messages/MessageLog";
import { useNavigate } from "react-router-dom";
import { FieldsReducer } from "../../CommonComponents/Reducer/Index";
import { useMessage } from "../../hooks/UseMessage";



export default function Email(props) {
  let navigate = useNavigate();
  const [_createErrorMessage, _errorMessages, _setErorMessages] = useMessage();
  const [emailParam, setEmailParam] = useState();
 


  const [
    _emailShipDoc,
    ,
    ,
    _errorEmailShipDoc,
    _setErrorEmailShipDoc,
    _successEmailShipDoc,
    _setSuccessEmailShipDoc,
    _loadingEmailShipDoc,
  ] = useFetch(GetFullURL("EmailShipDoc"), { method: "POST" });

  const operationLoading = _loadingEmailShipDoc ;

  const onChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValue;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }
    const newValueObject = { [name]: newValue };
    setEmailParam((prevState) => {
      return { ...prevState, ...newValueObject };
    });
  };

  function clearMessages(e) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessEmailShipDoc([]);
        break;
      case "error":
        _setErorMessages([]);
        _setErrorEmailShipDoc([]);
        break;
      default:
        break;
    }
  }

  const fieldsState = {
    dropDowns: [],
  };

  const mainFieldsToRender = mainArray(fieldsState);

  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    return newFieldObject;
  };
  
  return (
    <>
      <section className="heroCard heroPartner" style={{ height: "auto" }}>
        <div className="container-fluid">
          {operationLoading && <LoadingScreen />}
          
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <div className="cardHeader">
                  <h2>Email BOL</h2>
                </div>
                <div className="cardBody">
                {mainFieldsToRender.map((field, index) => (
                    <FieldsReducer
                      field={mergedFieldData(field, emailParam)}
                      key={index}
                    />
                  ))}
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      style={{
                        width: "32%",
                      }}
                      onClick={() => {
                        if(emailParam?.id === null || emailParam?.id === undefined){
                            _createErrorMessage("87");
                            return;
                        }else if(emailParam?.email === null || emailParam?.email === undefined){
                            _createErrorMessage("88");
                            return;
                        }else{
                            _emailShipDoc(
                                {
                                  data: {
                                    Id: emailParam?.id,
                                    Email:   emailParam?.email,
                                  },
                                },
                              );
                        }
                      }}
                    >
                      Send Email
                    </Button>
                    <Button
                      onClick={() => {
                        let navigationNextUrl = `/ReportsHome`;
                        navigate(navigationNextUrl);
                      }}
                      style={{
                        width: "32%",
                        background: "#DA1212",
                        borderColor: "#DA1212",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <MessageLog
                errorMessages={[..._errorEmailShipDoc,..._errorMessages]}
                successMessages={[..._successEmailShipDoc]}
                clearMessages={clearMessages}
              ></MessageLog>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
