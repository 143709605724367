
import React, { useState, useEffect } from "react";

function MTDataInfoSIdeBar({ _mTData }) {

let nonveyorRuning ;
let modeofScale;
let scaleMoting;
let scaleStatuses;
let inWeightTaken;
let outWeightTaken;
let plcReady;
let maxWeightCutoffOccured;

if (_mTData.isConveyorRuning){
  nonveyorRuning = "Conveyor Running"
}else{nonveyorRuning = "Conveyor Not Running"}

if (_mTData.scaleMode == "ScaleMode"){
  modeofScale = "Scale Mode"
}else{modeofScale = "Load Mode"}

if (_mTData.scaleMotion){
  scaleMoting = "Scale NOT In Motion"
}else{scaleMoting = "Scale In Motion"}

switch(_mTData.scaleStatus) {
  case "JustSensedWeight":

    scaleStatuses = "Just Sensed Weight";

    break;
  case "BusyLoading":

    scaleStatuses = "Busy Loading";

    break;
  case "BusyNotLoadingAfterOutWeight":

    scaleStatuses = "Busy NOT Loading After Out-Weight";

    break;
  case "BusyNotLoadingAfterMaxCuttoff":

    scaleStatuses = "Busy NOT Loading After Max-Weight Cutt-off";

    break;
  case "BusyCaptureOutWeight":

    scaleStatuses = "Busy Capture Out-Weight";

    break;
  case "Free":

    scaleStatuses = "Free";

    break;
  default:
    
}

if (_mTData.inWeight){
  inWeightTaken = "In-Weight Has Been Taken"
}else{inWeightTaken = "In-Weight NOT Taken"}

if (_mTData.outWeight){
  outWeightTaken = "Out-Weight Has Been Taken"
}else{outWeightTaken = "Out-Weight NOT Taken"}

if (_mTData.isReadyPLC){
  plcReady = "PLC is Ready"
}else{plcReady = "PLC is Ready"}

if (_mTData.maxWeightCutoffOccured){
  maxWeightCutoffOccured = "Max-Weight Cut-off Occured"
}else{maxWeightCutoffOccured = "Max-Weight Cut-off NOT Occured"}

  return (
    <>
      <div className="heroCard mb-3" style={{ padding: 0, height: "auto" }}>
        <div className="card">
          <div className="cardBody" style={{marginTop: 20}}>
            <div className="cardInput">
            
              <input
                type="boolean"
                disabled={true}
                value={nonveyorRuning}
                className="form-control"
                style={{
                  borderRadius: 8,
                  outline: "none !important",
                  height: 70,
                  fontSize: 16,
                }}
              />
            
              <input
                type="boolean"
                disabled={true}
                value={modeofScale || ""}
                className="form-control"
                style={{
                  borderRadius: 10,
                  outline: "none !important",
                  height: 70,
                  fontSize: 16,
                  marginTop: 10,
                }}
              />
           
              <input
                type="boolean"
                disabled={true}
                value={scaleMoting || ""}
                className="form-control"
                style={{
                  borderRadius: 10,
                  outline: "none !important",
                  height: 70,
                  fontSize: 16,
                  marginTop: 10,
                }}
              />


              <input
                type="text"
                disabled={true}
                value={scaleStatuses || ""}
                className="form-control"
                style={{
                  borderRadius: 10,
                  outline: "none !important",
                  height: 70,
                  fontSize: 16,
                  marginTop: 10,
                }}
              />

             
              <input
                type="boolean"
                disabled={true}
                value={inWeightTaken || ""}
                className="form-control"
                style={{
                  borderRadius: 10,
                  outline: "none !important",
                  height: 70,
                  fontSize: 16,
                  marginTop: 10,
                }}
              />
           
              <input
                type="boolean"
                disabled={true}
                value={outWeightTaken || ""}
                className="form-control"
                style={{
                  borderRadius: 10,
                  outline: "none !important",
                  height: 70,
                  fontSize: 16,
                  marginTop: 10,
                }}
              />
           
              <input
                type="boolean"
                disabled={true}
                value={plcReady || ""}
                className="form-control"
                style={{
                  borderRadius: 10,
                  outline: "none !important",
                  height: 70,
                  fontSize: 16,
                  marginTop: 10,
                }}
              />
              <input
                type="boolean"
                disabled={true}
                value={maxWeightCutoffOccured || ""}
                className="form-control"
                style={{
                  borderRadius: 10,
                  outline: "none !important",
                  height: 70,
                  fontSize: 16,
                  marginTop: 10,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MTDataInfoSIdeBar;
