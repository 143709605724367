exports.Component = [
  {
    id: "id",
    name: "id",
    type: "number",
    value: "",
    label: "Id",
    key: "true",
  },
  {
    id: "key",
    name: "key",
    type: "string",
    value: "",
    label: "Key",
    key: "true",
  },
  {
    id: "nameLocalizedText",
    name: "nameLocalizedText",
    type: "text",
    value: "",
    label: "nameLocalizedText",
  },
  {
    id: "componentType",
    name: "componentType",
    type: "number",
    value: "",
    label: "Component Type",
    ddid: "componentTypeId",
  },

  {
    id: "isEnabled",
    name: "isEnabled",
    type: "boolean",
    value: "",
    label: "Enabled",
  },
];
