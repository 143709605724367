
export const ValidateLeaseInput = (lease, _createErrorMessage) => {

  if (!lease) {
    _createErrorMessage("79");
    return false;
  }

  return true;
};
  