export const mainArray = ( {dropDowns}) => [
    {
        id: "locationId",
        name: "locationId",
        type: "text",
        label: "Locations",
        tag:"select",
        options: dropDowns?.locations || [],
    },

  ];
