import React from "react";
import Button from '@mui/material/Button';
import { ValidateLogisticStationInput } from "./../ValidateLogisticStationInput";
const btnsArray = [
  { buttonOperation: "Display" },
  { buttonOperation: "Change" },
  { buttonOperation: "Create" },
];

export default function OverviewButtons({
  logisticStation,
  navigate,
  _getLogisticStationExists,
  _createErrorMessage,
}) {
  const onClick = (buttonOperation, noValidation, navPath) => {
    //If create button is pressed then just proceed to route
    let navigationUrl;
    if (buttonOperation === "Create") {
      navigationUrl = `/LogisticStation/Create`;
      navigate(navigationUrl);
      return;
    }

    let validationLSResult = ValidateLogisticStationInput(
      logisticStation,
      _createErrorMessage
    );

    if (!validationLSResult) {
      return;
    }

    _getLogisticStationExists(
      { data: { Id: logisticStation } },
      ({ data: isExist }) => {
        if (!isExist) {
          _createErrorMessage("78");
          return;
        }
        const navigationUrl = `/LogisticStation/${logisticStation}/${buttonOperation}`;
        navigate(navigationUrl);
      }
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="customButton"
    >
      {btnsArray.map(({ buttonOperation, noValidation, navPath }, i) => (
        <div style={{ width: "48%", marginBottom: 15 }} key={i}>
          <Button
            className="btn btn-primary w-100"
            onClick={() => onClick(buttonOperation, noValidation, navPath)}
          >
            {buttonOperation}
          </Button>
        </div>
      ))}
    </div>
  );
}
