
export const ValidateLabTestAdminFuncInput = (labTest, _createErrorMessage) => {

  if (!labTest) {
    _createErrorMessage("108");
    return false;
  }

  return true;
};
