import React from "react";
import "./index.scss";

export default function InfoBlock({ title, value }) {
  return (
    <div className="info-block-wrapper">
      <span className="info-title">{title}: </span>
      <span className="info-value">{value}</span>
    </div>
  );
}
