import React, { useContext } from "react";
import { adminFieldsArray } from "./fields";
import { TestMethodContext } from "../context";
import { FieldsReducer } from "./../../../../CommonComponents/Reducer/Index";

export default function AdminTab() {
  const { _testMethodData, onChange, operation, dropDowns } =
    useContext(TestMethodContext);

  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _testMethodData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };

  const fieldsState = {
    dataSource: _testMethodData,
    dropDowns,
  };

  const fieldsToRender = adminFieldsArray(fieldsState);
  return (
    <div className="row">
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: "auto" }}>
          <div className="cardHeader">
            <h2>Admin Data</h2>
          </div>
          <div className="cardBody">
            {fieldsToRender.map((field) => (
              <FieldsReducer field={mergedFieldData(field)} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
