
export const adminDataArray = ({ dropDowns }) => [
  {
    id: "createdBy",
    name: "createdBy",
    type: "text",
    label: "Created By",
    disabled: true,
  },
  {
    id: "createdOn",
    name: "createdOn",
    type: "datetime",
    label: "Created On",
    disabled: true,
  },
  {
    id: "modifiedBy",
    name: "modifiedBy",
    type: "text",
    label: "Modified By",
    disabled: true,
  },
  {
    id: "modifiedOn",
    name: "modifiedOn",
    type: "datetime",
    label: "Modified On",
    disabled: true,
  },
];


