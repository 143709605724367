import React, { useState, useEffect } from "react";
import InfoBlock from "../../../../CommonComponents/InfoBlock";

function HeaderV3({ stationId }) {
  useEffect(() => {}, []);

  return (
    <div
      style={{
        padding: "0.5rem 1rem",
        backgroundColor: "#fff",
        boxShadow: "rgba(100 ,100 ,111 ,20%) 0px 7px 29px 0px",
        marginBottom: "2rem",
      }}
    >
      <div className="row justify-content-around flex-wrap">
        <InfoBlock title="Station" value={stationId} />
      </div>
    </div>
  );
}

export default HeaderV3;
