import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { TableDataContext } from "../Contexts/TableDataContext";
export default function CheckBox({ field }) {
  const { tableOperationN, tableRecordN, setTableRecordN } =
    useContext(TableDataContext);

  function disable(op) {
    if (field.displayonly === "true") {
      return true;
    }
    if (op === "dis") {
      return true;
    }
    return false;
  }

  const value = tableRecordN[field.id];

  return (
    <div>
      <Form.Check
        type="checkbox"
        id={field.id}
        name={field.name}
        checked={value}
        label={field.label}
        disabled={disable(tableOperationN)}
        onChange={(e) => {
          let newVal;
          let field = e.target.id;
          let val = e.target.checked;
          switch (val) {
            case "on":
              newVal = true;
              break;
            case "off":
              newVal = false;
              break;
            default:
              newVal = val;
              break;
          }
          let valueObject = { [field]: newVal };
          setTableRecordN((current) => {
            return { ...current, ...valueObject };
          });
        }}
      />
    </div>
  );
}
