import React, { useContext, useState, useEffect, useRef } from "react";
import { mainDataArray, invArray, readingsArray } from "./Fields";
import { TankPhysicalContext } from "../context";
import { FieldsReducer } from "../../../../CommonComponents/Reducer/Index";
import MaterialField from "../../../../CommonComponents/EnhancedFields/Material/MaterialField";
import MaterialList from "../../../../CommonComponents/DropDowns/Material/MaterialList";
import TankField from "../../../../CommonComponents/EnhancedFields/Tank/TankField";
import TankList from "../../../../CommonComponents/DropDowns/Tank/TankList";

export default function MainTab() {
  const {
    onChange,
    operation,
    dropDowns,
    _tankPhysicalData,
    _getMaterialList,
    _materialList,
    _getTankList,
    _tankList,
  } = useContext(TankPhysicalContext);

  const [showMaterialList, setShowMaterialList] = useState(false);
  const [showTankList, setShowTankList] = useState(false);
  const [materialFieldName, setMaterialFieldName] = useState();
  const [tankFieldName, setTankFieldName] = useState();
  const [filteredTankList, setFilteredTankList] = useState([]);
  const mergedFieldData = (field) => {
    let newFieldObject = { ...field, dataSource: _tankPhysicalData, onChange };

    switch (operation) {
      case "Display":
        newFieldObject.disabled = true;
        break;
      case "Change":
        if (newFieldObject.key === true) {
          newFieldObject.disabled = true;
        }
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      case "Create":
        if (newFieldObject.displayOnly === true) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }

    return newFieldObject;
  };
  const fieldsState = {
    dataSource: _tankPhysicalData,
    dropDowns,
    location: _tankPhysicalData.locationId,
  };
  useEffect(() => {
    if (!_tankList) {
      return;
    }
    if (Object.keys(_tankList).length === 0) {
      return;
    }

    if (_tankPhysicalData.locationId) {
      setFilteredTankList((current) => {
        return _tankList.filter(
          (x) => x.locationId === _tankPhysicalData.locationId
        );
      });
    } else {
      setFilteredTankList(_tankList);
    }
  }, [_tankPhysicalData.locationId, _tankList]);

  const mainDataArrayToRender = mainDataArray(fieldsState);
  const invArrayToRender = invArray(fieldsState);
  const readingsArrayToRender = readingsArray(fieldsState);

  var materialField = invArrayToRender.find(({ id }) => id === "materialId");
  var tankField = mainDataArrayToRender.find(({ id }) => id === "tankId");

  return (
    <div className="row">
      <div className="col-xl-3 col-lg-3 col-sm-6 col-xs-12">
        <div className="card">
          <div className="cardHeader">
            <h2>Main Data </h2>
          </div>
          <div className="cardBody">
            {mainDataArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
            <TankField
              field={mergedFieldData(tankField)}
              setShowList={setShowTankList}
              setTankFieldName={setTankFieldName}
              _getTankList={_getTankList}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 450 }}>
          <div className="cardHeader">
            <h2>Inventory Data</h2>
          </div>
          <div className="cardBody">
            {invArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
            <MaterialField
              field={mergedFieldData(materialField)}
              setShowMaterialList={setShowMaterialList}
              setMaterialFieldName={setMaterialFieldName}
              _getMaterialList={_getMaterialList}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <div className="card" style={{ minHeight: 610 }}>
          <div className="cardHeader">
            <h2>Readings Data</h2>
          </div>
          <div className="cardBody">
            {readingsArrayToRender.map((field, index) => (
              <FieldsReducer field={mergedFieldData(field)} key={index} />
            ))}
          </div>
        </div>
      </div>
      <MaterialList
        _materialList={_materialList}
        showMaterialList={showMaterialList}
        setShowMaterialList={setShowMaterialList}
        materialFieldName={materialFieldName}
        onChange={onChange}
      />
      <TankList
        _tankList={filteredTankList}
        showTankList={showTankList}
        setShowTankList={setShowTankList}
        tankFieldName={tankFieldName}
        onChange={onChange}
      />
    </div>
  );
}
