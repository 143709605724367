exports.CurrentFieldData = [
    {
      id: "id",
      name: "id",
      type: "number",
      value: "",
      label: "Id",
      key: "true",
     
    },
    {
      id: "shipDocId",
      name: "shipDocId",
      type: "text",
      value: "",
      label: "ShipDocId",
      key: "true",
      length: 24,
    },
    {
      id: "currentWeight",
      name: "currentWeight",
      type: "number",
      value: "",
      label: "Current Weight",

    },
    {
      id: "unitWeight",
      name: "unitWeight",
      type: "text",
      value: "",
      label: "Weight Unit",
      length: 3,
      ddid:"unitId",
    },
    {
      id: "currentVolume",
      name: "currentVolume",
      type: "number",
      value: "",
      label: "Current Volume",
    },
    {
     id: "unitVolume",
     name: "unitVolume",
     type: "text",
     value: "",
     label: "Unit Volume",
     length: 3,
     ddid:"unitId",
    },
    {
      id: "inWeight",
      name: "inWeight",
      type: "number",
      value: "",
      label: "In Weight",
    },
    {
      id: "outWeight",
      name: "outWeight",
      type: "number",
      value: "",
      label: "Out Weight",
    },
    {
      id: "midWeight",
      name: "midWeight",
      type: "number",
      value: "",
      label: "Mid Weight",
    },
    {
      id: "maxWeight",
      name: "maxWeight",
      type: "number",
      value: "",
      label: "Max Weight",
    },
    {
      id: "inWeightTaken",
      name: "inWeightTaken",
      type: "boolean",
      value: "",
      label: "In Weight Taken",
    },
    {
      id: "outWeightTaken",
      name: "outWeightTaken",
      type: "boolean",
      value: "",
      label: "Out Weight Taken",
    },
    {
      id: "scaleMotion",
      name: "scaleMotion",
      type: "boolean",
      value: "",
      label: "Scale Motion",
    },
    {
      id: "scaleStatus",
      name: "scaleStatus",
      type: "text",
      value: "",
      label: "scaleStatus",
      key: "true",
      length: 24,
      ddid:"statusId"
    },
    {
      id: "isConveyorRuning",
      name: "isConveyorRuning",
      type: "boolean",
      value: "",
      label: "Conveyor Runing",
    },
    {
      id: "tLOADInControl",
      name: "tLOADInControl",
      type: "boolean",
      value: "",
      label: "TLOAD In Control",
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "datetime",
      label: "Created On",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      displayonly: "true",
      value: " ",
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "datetime",
      label: "ModifiedOn",
      displayonly: "true",
      value: " ",
    },
  ];
  