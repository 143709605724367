import React, { useContext } from "react";
import ConfButtons from "./ConfButtons";
import { TableDataContext } from "../Contexts/TableDataContext";
export default function ListBody({ handleButtonClickList, listType }) {
  const { tableFieldsN, tableRecordsN } = useContext(TableDataContext);

  return (
    <tbody>
      {tableRecordsN.map(function (tableRecord, i) {
        return (
          <tr key={i}>
            {tableFieldsN.map((field, i) => {
              if (field.hide === "true") {
                return;
              }

              let fieldValue = tableRecord[field.name];
              if (fieldValue === true) {
                fieldValue = "True";
              }
              return (
                <td className="GridColumnContent" key={i}>
                  {listType === true || listType == null ? null : fieldValue}
                </td>
              );
            })}

            {tableRecord != null ? (
              <ConfButtons
                tableRecord={tableRecord}
                handleButtonClickList={handleButtonClickList}
              />
            ) : null}
          </tr>
        );
      })}
    </tbody>
  );
}
