import React, { useRef } from "react";
import { Delete } from "@mui/icons-material";
import Button from "react-bootstrap/Button";

export default function DeleteBtnAction({
  dataRecord,
  handleButtonClickGrid,
  operation,
}) {
  const deleteBtnRef = useRef(null);
  return (
    <Button
      type="button"
      data-op="del"
      // className="btn-danger"
      style={{background: '#dc3545', borderColor: '#dc3545'}}
      data-id={dataRecord["assignmentId"]}
      onClick={handleButtonClickGrid}
      disabled={operation === "Display"}
      ref={deleteBtnRef}
    >
      <Delete
        style={{ color: "white" }}
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          deleteBtnRef.current.click();
        }}
      />
    </Button>
  );
}
