import React, { useContext } from "react";
import { TableDataContext } from "../Contexts/TableDataContext";
export default function Select({ field, options, operation, renderLable }) {
  const { tableOperationN, tableRecordN, setTableRecordN } =
    useContext(TableDataContext);
  let value = tableRecordN[field.id];
  function disable(op) {
    if (field.displayonly === "true") {
      return true;
    }
    //if the field is key then disable the field in the change mode
    if (op === "cha" && field.key === "true") {
      return true;
    }
    //if the operation is change and the field can not be changed in the change mode then disable
    if (op === "cha" && field.changedisplayonly === "true") {
      return true;
    }

    if (op === "dis") {
      return true;
    }
    return false;
  }

  return (
    <div className="mb-3">
      {/*adding logic to render the lable becuase inside a list there is no need for a lable*/}
      {renderLable === true || renderLable == null ? (
        <label htmlFor={field.id}>{field.label}</label>
      ) : null}

      <select
        type={field.type}
        className="form-control"
        id={field.id}
        aria-describedby="emailHelp"
        placeholder="Enter key"
        disabled={disable(tableOperationN)}
        onChange={(e) => {
          let newValue;
          let fieldId = e.target.id;
          let val = e.target.value;

          // field type and format
          if (field.type === "number") {
            if (val === "SELECT" || val === "select") {
              newValue = 0;
            } else newValue = Number(val);
          } else {
            if (val === "SELECT" || val === "select") {
              newValue = "";
            } else {
              newValue = val;
            }
          }

          let valueObject = { [fieldId]: newValue };
          setTableRecordN((current) => {
            return { ...current, ...valueObject };
          });
        }}
        value={value}
        defaultValue={"select"}
      >
        <option value="select">--- SELECT ---</option>
        {options.map((item, i) => {
 
          return (
            <option key={i} value={item.id || item.value}>
              {item.nameLocalizedText || item.text || item.id}
            </option>
          );
        })}
      </select>
    </div>
  );
}
