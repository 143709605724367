import React from "react";
export default function BPartnerSelect({
  operation,
  options,
  setPartnerRole,
  partnerRole,
}) {
  function updateState(e) {
    let newValue;
    let val = e.target.value;
    val === "select" ? (newValue = "") : (newValue = val);
    setPartnerRole(newValue);
  }

  return (
    <div>
      <label className="col-sm-4 col-form-label" htmlFor="Role">
        Partner Role
      </label>
      <select
        type={"text"}
        className="form-control col-sm-4"
        id="Role"
        aria-describedby="emailHelp"
        placeholder="Enter key"
        disabled={operation === "Display"}
        onChange={updateState}
        value={partnerRole || "select"}
      >
        <option value="select">--- SELECT ---</option>
        {options
          ? options.map((item, i) => {
              return (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              );
            })
          : null}
      </select>
    </div>
  );
}
