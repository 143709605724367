export const shippingFieldsArray = ({ dropDowns, dataSource }) => [
    {
      id: "maxLoadQty",
      name: "maxLoadQty",
      type: "number",
      label: "Max Load Qty",
    },
    {
      label: "Max Load Qty Unit Id",
      id: "maxLoadQtyUnitId",
      name: "maxLoadQtyUnitId",
      tag: "select",
      options: dropDowns?.units || [],
      type: "text ",
    },
  ];
  