import React from "react";
import GridAction from "./GridAction";
import { FieldsReducer } from "../../Reducer/Index";
export default function GridBody({
  fields,
  headerData,
  itemData,
  handleButtonClickGrid,
  dropDowns,
  operation,
  onChange,
  ActionColumn,
  itemKey,
}) {
  const fieldsState = {
    headerData,
    itemData,
    dropDowns,
  };

  let enhancedFieldList = fields(fieldsState);
  const mergedFieldData = (field, dataSource, noLable) => {
    let newFieldObject = { ...field, dataSource, onChange };

    if (noLable) {
      newFieldObject.noLable = true;
    }

    if (operation === "Display") {
      newFieldObject.disabled = "true";
    }

    return newFieldObject;
  };
  return (
    <tbody>
      {itemData.map((dataRecord, i) => {
        return (
          <tr key={i}>
            {enhancedFieldList.map((field, i1) => {
              let augmentedField = mergedFieldData(
                { ...field, noLable: true, itemKey },
                dataRecord,
                true
              );
              return (
                <td key={i1}>
                  <FieldsReducer field={augmentedField} key={i}></FieldsReducer>
                </td>
              );
            })}
            {ActionColumn && (
              <td offset-2>
                <GridAction
                  handleButtonClickGrid={handleButtonClickGrid}
                  operation={operation}
                  dataRecord={dataRecord}
                  itemKey={itemKey}
                />
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  );
}
