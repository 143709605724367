import React from "react";
import Card from "../../Components/Card";
import { useNavigate } from "react-router-dom";
import { ReactComponent as VehicleIcon } from "../../../Assets/NewIconsForCards/VehicleIcon.svg";



const Arr = [
  {
    title: "Vehicle",
    text: "",
    Icon: VehicleIcon,
    link: "/VehicleOverview",
  },
];

export default function TransportationOverview() {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <div className="overviewCardContainer">
        {Arr.map((record, i) => (
          <Card {...record} key={i} />
        ))}
      </div>
    </div>
  );
}
