import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useFetch } from "./../../../hooks/useFetch";
import { URLs } from "./../../../CommonComponents/GlobalConfFiles/URLs";
import StationSelect from "./StationSelect";
import Button from '@mui/material/Button';
import Modal from "react-bootstrap/Modal";
import { LoadingScreen } from "../../../CommonComponents/LoadingScreen/index";
import MessageLog from "../../../CommonComponents/Messages/MessageLog";

function Station() {
  const dispatch = useDispatch();
  const allStation = `${URLs.Host}${URLs.Config}GetAllStations`;
  const [station, setStation] = useState();
  const [cookie, setCookie] = useState();
  const [message, setMessage] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);

  const [
    _getAllStationData,
    _AllstationData,
    _setAllStationData,
    _errorAllStationData,
    _setErrorAllStationData,
    _successAllStationData,
    _setSuccessAllStationData,
    _loadingAllStationData,
  ] = useFetch(allStation, { method: "POST" });

  useEffect(() => {
    let storedCookie = Cookies.get("station");
    _getAllStationData();
    setCookie(storedCookie);
  }, []);

  const handleClose = () => setShowMessageModal(false);

  const saveCookieHandler = () => {
    if (!station) {
      setMessage("Select a Station ID");
      setShowMessageModal(true);
    } else {
      Cookies.set("station", station);
      setCookie(station);
    }
  };

  const removeCookieHandler = () => {
    Cookies.remove("station");
    setCookie("");
  };

  const clearMessages = (e) => {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":
        _setSuccessAllStationData([]);
        break;
      case "error":
        _setErrorAllStationData([]);

        break;
      default:
        break;
    }
  };

  let allStations = [];
  if (Array.isArray(_AllstationData)) {
    allStations = _AllstationData;
  }

  let storedStationName = "";
  const result = allStations.find(({ id }) => id === cookie);
  storedStationName = result?.nameLocalizedText;

  if (!storedStationName) {
    storedStationName = "No Cookie";
  }
  const operationLoading = _loadingAllStationData;

  return (
    <div>
      <div
        className="custom-card-container"
        style={{
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {operationLoading && <LoadingScreen />}
        <div
          className="p-4 custom-card"
          style={{
            margin: "10px auto",
          }}
        >
          <h2 className="parterOverview">Station Cookie</h2>
          <h5 className="cookie">
            Current Cookie:-{" "}
            <span>
              {cookie} ({storedStationName})
            </span>
          </h5>
          <StationSelect
            operation="Change"
            options={_AllstationData.length > 0 ? _AllstationData : []}
            station={station}
            setStation={setStation}
          />

          <div className="terminalBtnContainer">
            <Button
              color="primary"
              variant="outlined"
              onClick={saveCookieHandler}
            >
              Save Cookie
            </Button>
            <Button color="secondary" onClick={removeCookieHandler}>
              {" "}
              Remove Cookie
            </Button>
          </div>
        </div>

        <div
          className="row"
          style={{
            maxWidth: 500,
            width: "90%",
          }}
        ></div>
        <Modal show={showMessageModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Station Cookie</Modal.Title>
          </Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <MessageLog
        errorMessages={[..._errorAllStationData]}
        successMessages={[..._successAllStationData]}
        clearMessages={clearMessages}
      ></MessageLog>
    </div>
  );
}

export default Station;
