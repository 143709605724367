import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import { MaterialContext } from "../context";
import ConversionListDropDown from "../../Components/ConversionListDropDown";
import ConversionsGrid from "./Grid/ConversionsGrid";

function UnitConversionsGrid() {
  const {
    operation,
    materialId,
    _materialData,
    _setMaterialData,
    _conversionList,
    _getConversionList,
  } = useContext(MaterialContext);

  const gridData = _materialData.conversions || [];
  const [showConversionList, setShowConversionList] = useState(false);

  function gridButtonsHandle(e) {
    let id = e.target.dataset.id;
    let op = e.target.dataset.op;
    switch (op) {
      case "Delete":
        _setMaterialData((current) => {
          let currentConversion = current.conversions;

          var index = currentConversion.findIndex(
            (x) => x.unitConversionId === id
          );
          //remove item by index
          if (index >= 0) {
            currentConversion.splice(index, 1);
          }

          return { ...current, conversions: currentConversion };
        });
        break;
      default:
        break;
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="selectWrapper">
          <Button
            className="ButtonAdd"
            hidden={operation === "Display"}
            onClick={() => {
              _getConversionList({ data: null }, () => {});
              setShowConversionList(true);
            }}
          >
            Create
          </Button>
        </div>
      </div>
      <ConversionsGrid
        gridData={gridData}
        operation={operation}
        gridButtonsHandle={gridButtonsHandle}
      />

      <div>
        <ConversionListDropDown
          currentMaterialConversion={gridData}
          materialId={materialId}
          _conversionList={_conversionList}
          showConversionList={showConversionList}
          setShowConversionList={setShowConversionList}
        />
      </div>
    </div>
  );
}

export default UnitConversionsGrid;
